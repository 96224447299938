import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './AddIncomePaymentItemModal.types';
import AddIncomePaymentItemModal from './AddIncomePaymentItemModal';
import { IRootState } from '../../../../../../common/types';
import { getProperties, resetProperties } from '../../../../../../store/property/actions';
import { getShareholders, resetShareholders } from '../../../../../../store/shareholder/actions';
import { getPaymentItems, resetPaymentItems } from '../../../../../../store/paymentItem/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { propertyList } = state.property;
    const { shareholderList } = state.shareholder;
    const { paymentItemList } = state.paymentItem;

    return {
        properties: propertyList.data,
        propertiesLoading: propertyList.loading,
        shareholders: shareholderList.data,
        shareholdersLoading: shareholderList.loading,
        paymentItems: paymentItemList.data,
        paymentItemsLoading: paymentItemList.loading,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getProperties,
            resetProperties,
            getShareholders,
            resetShareholders,
            getPaymentItems,
            resetPaymentItems,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddIncomePaymentItemModal);
