import * as React from 'react';
import { IProps } from './UserDetail.types';
import { SectionContainer, DisplayItem } from '../../../../components';
import { paths } from '../../../../routes';

class UserDetail extends React.Component<IProps> {
    componentDidMount() {
        this.props.getUser(this.props.match.params['id']);
    }

    componentWillUnmount() {
        this.props.resetUser();
    }

    renderContent = () => {
        const { user } = this.props;

        return (
            <>
                <DisplayItem label="Usuario" value={user.username} />
                <DisplayItem label="Nombre" value={user.firstname} />
                <DisplayItem label="Apellidos" value={user.lastname} />
                <DisplayItem label="Email" value={user.email} />
                <DisplayItem label="DNI" value={user.dni} />
                <DisplayItem label="Teléfono" value={user.phone} />
                <DisplayItem label="Dirección" value={user.address} />
                <DisplayItem label="Rol" value={user.role.name} />
            </>
        );
    };

    render() {
        const { user, loading, error } = this.props;

        return (
            <SectionContainer
                title="Detalle Usuario"
                onBack={() => this.props.history.push(paths.ADMIN_USER_LIST)}
                loading={loading || !user}
                error={error}
                content={this.renderContent}
            />
        );
    }
}

export default UserDetail;
