import React, { FC, useRef } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal } from 'antd';
import { InputGroupField, SelectGroupField } from '../../../../../../components';
import { messages, expenseTypes } from '../../../../../../common/constants';
import { IExpenseDetail, ICurrency } from '../../../../../../common/types/entities';

interface IOwnProps {
    currencies: ICurrency[];
    currenciesLoading: boolean;
    activeItem: IExpenseDetail;
    isOpen: boolean;
    onSubmit: (values: IAddEditExpenseDetailValues) => void;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    typeId: number;
}

export interface IAddEditExpenseDetailValues {
    unitPrice: number;
    currency: { id: number | string };
    quantity: number;
    comment: string;
}

export type IProps = IOwnProps;

export const AddEditModal: FC<IProps> = props => {
    let form = useRef<Formik<IAddEditExpenseDetailValues>>(null);

    const onSubmit = (
        values: IAddEditExpenseDetailValues,
        actions: FormikActions<IAddEditExpenseDetailValues>,
    ) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = e => {
        form.current.resetForm();
        props.onCancel(e);
    };

    const validate = (values: IAddEditExpenseDetailValues) => {
        let errors = { unitPrice: null, quantity: null, currency: null };

        if (!values.unitPrice) errors.unitPrice = messages.MANDATORY_FIELD;
        if (!values.currency.id) errors.currency = messages.MANDATORY_FIELD;
        if (props.typeId == expenseTypes.GOODS && !values.quantity)
            errors.quantity = messages.MANDATORY_FIELD;

        if (props.typeId == expenseTypes.GOODS && values.quantity < 1)
            errors.quantity = 'Valor debe ser mayor a 0';
        if (values.unitPrice < 1) errors.unitPrice = 'Valor debe ser mayor a 0';

        if (!errors.unitPrice && !errors.quantity) errors = null;

        return errors;
    };

    const onOk = () => {
        form.current.submitForm();
    };

    const renderFormik = () => {
        if (!props.isOpen) return null;

        return (
            <Formik
                initialValues={{
                    unitPrice: props.activeItem ? props.activeItem.unitPrice : null,
                    currency:
                        props.activeItem && props.activeItem.currency
                            ? { id: props.activeItem.currency.id }
                            : null,
                    quantity: props.activeItem ? props.activeItem.quantity : null,
                    comment: props.activeItem ? props.activeItem.comment : null,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={(formikBag: FormikProps<IAddEditExpenseDetailValues>) => (
                    <Form>
                        <InputGroupField
                            name="unitPrice"
                            label="Precio Unitario"
                            normalize="numbersWithDecimals"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        <SelectGroupField
                            name="currency.id"
                            label="Moneda"
                            displayText="code"
                            data={props.currencies}
                            loading={props.currenciesLoading}
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        {props.typeId == expenseTypes.GOODS && (
                            <InputGroupField
                                name="quantity"
                                label="Cantidad"
                                normalize="number"
                                required
                                lxs={24}
                                lsm={24}
                                lmd={24}
                                llg={24}
                                lxl={6}
                                lxxl={6}
                                imd={24}
                                ixl={18}
                                ilg={24}
                            />
                        )}
                        <InputGroupField
                            name="comment"
                            label="Comentario"
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            title={'Detalle de ' + (props.typeId == expenseTypes.GOODS ? 'Bien' : 'Servicio')}
            visible={props.isOpen}
            okText="Guardar"
            onOk={onOk}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
        >
            {renderFormik()}
        </Modal>
    );
};
