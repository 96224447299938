import React, { FC } from 'react';
import { Input } from 'antd';
import { Field, FieldProps } from 'formik';
import { getPropertyByKeyPath } from '../../../common/utils';
import { helper } from '../../../common/helpers';
import { InputProps } from 'antd/lib/input';
import { FieldMessage } from '../..';

interface INormalize {
    normalize?: 'number' | 'numbersWithTwoDecimals' | 'numbersWithDecimals';
    decimals?: number;
}
export type IInputFieldProps = InputProps & INormalize;

const InputField: FC<IInputFieldProps> = ({
    name,
    onChange,
    onBlur,
    className,
    normalize,
    decimals,
    ...restProps
}) => {
    return (
        <Field name={name}>
            {({
                field: { value, onChange: onValueChange, onBlur: onBlurValue },
                form,
            }: FieldProps) => {
                const error = getPropertyByKeyPath(form.errors, name);
                const hasError = error != undefined;
                return (
                    <>
                        <Input
                            name={name}
                            value={value}
                            onChange={e => {
                                if (e.target.value.length > 0) {
                                    if (
                                        normalize === 'number' &&
                                        !helper.onlyNumbers(e.target.value)
                                    )
                                        return;
                                    if (
                                        normalize === 'numbersWithDecimals' &&
                                        !helper.onlyNumbersWithDecimals(e.target.value)
                                    )
                                        return;
                                    if (
                                        normalize === 'numbersWithTwoDecimals' &&
                                        !helper.onlyNumbersWithTwoDecimals(e.target.value)
                                    )
                                        return;
                                }
                                onValueChange(e);
                                onChange && onChange(e);
                            }}
                            onBlur={event => {
                                onBlurValue(event);
                                onBlur && onBlur(event);
                            }}
                            className={`form-input ${hasError ? 'has-error' : ''} ${className}`}
                            {...restProps}
                        />
                        <FieldMessage show={hasError} message={error} />
                    </>
                );
            }}
        </Field>
    );
};

export default InputField;
