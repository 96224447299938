import * as React from 'react';
import { FormikActions, FormikProps } from 'formik';
import { CustomForm, InputGroupField, SelectGroupField } from '../../../../../../components';
import { IPaymentItem, ITax, IPaymentItemType } from '../../../../../../common/types/entities';
import { messages } from '../../../../../../common/constants';
import { isEmpty, isNumeric } from 'validator';
import { formatHelper } from '../../../../../../common/helpers';

interface IPaymentItemForm {
    description: string;
    referentialPrice: string;
    sunatCode: string;
    tax: { id: number | string };
    type: { id: number | string };
}

interface IProps {
    initialValues: IPaymentItem;
    taxes: ITax[];
    taxesLoading: boolean;
    paymentItemTypes: IPaymentItemType[];
    paymentItemTypesLoading: boolean;
}

export default class PaymentItemForm extends CustomForm<IPaymentItemForm, IProps> {
    initialValues = (props: IProps): IPaymentItemForm => {
        const { initialValues } = props;
        return {
            description: initialValues.description || '',
            referentialPrice:
                initialValues.referentialPrice != null
                    ? String(initialValues.referentialPrice)
                    : '',
            sunatCode: initialValues.sunatCode || '',
            tax: { id: initialValues.tax ? initialValues.tax.id : null },
            type: { id: initialValues.type ? initialValues.type.id : null },
        };
    };

    validate = (values: IPaymentItemForm) => {
        const errors = {} as Partial<IPaymentItemForm>;

        if (isEmpty(values.description)) errors.description = messages.MANDATORY_FIELD;

        if (!isEmpty(values.referentialPrice) && !isNumeric(values.referentialPrice))
            errors.referentialPrice = 'El precio debe ser un número';

        if (!values.tax.id) errors.tax = { id: messages.MANDATORY_FIELD };
        if (!values.type.id) errors.type = { id: messages.MANDATORY_FIELD };

        return errors;
    };

    onSubmit = (values: IPaymentItemForm, actions: FormikActions<IPaymentItemForm>) => {
        if (values.sunatCode == '') values.sunatCode = null;
        if (values.referentialPrice == '') values.referentialPrice = null;

        this.props.onSubmit(values);
        actions.setSubmitting(false);
    };

    formBody = (formikBag: FormikProps<IPaymentItemForm>) => {
        const { paymentItemTypes, paymentItemTypesLoading, taxes, taxesLoading } = this.props;

        return (
            <div>
                <InputGroupField name="description" label="Descripción" required />
                <InputGroupField name="referentialPrice" label="Precio Referencial" />
                <InputGroupField name="sunatCode" label="Código Sunat" />
                <SelectGroupField
                    name="tax.id"
                    label="Impuesto"
                    data={taxes}
                    displayText={(tax: ITax) => formatHelper.getTaxWithPercentaje(tax)}
                    loading={taxesLoading}
                    required
                />
                <SelectGroupField
                    name="type.id"
                    label="Tipo"
                    data={paymentItemTypes}
                    displayText="description"
                    loading={paymentItemTypesLoading}
                    required
                />
            </div>
        );
    };
}
