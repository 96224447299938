import React, { FunctionComponent, useState, useEffect } from 'react';
import { IProps } from './PropertyDetail.types';
import { SectionContainer, DisplayItem, DataTable } from '../../../../components';
import { paths } from '../../../../routes';
import { Divider, Row, Col, Input, Upload } from 'antd';
import { columns } from './misc/columns';
import { helper } from '../../../../common/helpers';

const PropertyDetail: FunctionComponent<IProps> = props => {
    const propertyId = props.match.params['id'];

    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        props.getProperty(propertyId);

        return () => {
            props.resetProperty();
            props.resetPropertyShareholders();
        };
    }, []);

    useEffect(() => {
        performGetPropertyShareholders();
    }, [page]);

    const refreshPropertyShareholders = () =>
        page === 1 ? performGetPropertyShareholders() : setPage(1);

    const performGetPropertyShareholders = () =>
        props.getPropertyShareholders(propertyId, filter, page);

    const onPageChange = (page: number) => setPage(page);

    const renderContent = () => {
        const { property } = props;

        return (
            <>
                <DisplayItem label="Código" value={property.code} />
                <DisplayItem label="Acciones" value={property.shares} />
                <DisplayItem label="Descripción" value={property.description} />
                <DisplayItem label="Precio Referencial" value={property.referentialPrice} />
                <DisplayItem label="Área" value={property.area} />
                <DisplayItem label="Zona" value={property.zone ? property.zone.name : ''} />
                <DisplayItem label="Giro" value={property.subzone ? property.subzone.name : ''} />
                <Divider orientation="left" className="mt-4">
                    Accionistas
                </Divider>
                <Row className="mb-3">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={filter}
                            onChange={e => setFilter(e.target.value)}
                            onSearch={refreshPropertyShareholders}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            data={props.shareholders}
                            columns={columns()}
                            pageSize={props.shareholdersPageSize}
                            total={props.shareholdersTotal}
                            currentPage={page}
                            onPageChange={onPageChange}
                            loading={props.shareholdersLoading}
                        />
                    </Col>
                </Row>
                <Divider orientation="left" className="mt-5">
                    Archivos
                </Divider>
                <Row>
                    <Col lg={12} md={12} xs={24} sm={24}>
                        <Upload
                            disabled={true}
                            defaultFileList={helper.getFileList(property.attachedFiles)}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <SectionContainer
            title="Detalle Puesto"
            onBack={() => props.history.push(paths.ADMIN_PROPERTY_LIST)}
            loading={props.loading || !props.property}
            error={props.error}
            content={renderContent}
        />
    );
};

export default PropertyDetail;
