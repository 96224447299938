import { IObjectState, IFileResult } from '../../common/types';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import {
    incomeDetailedCompletedReportExcelCF,
    incomeDetailedPendingPaymentReportExcelCF,
    incomeDetailedPendingReportExcelCF,
    incomeDetailedAnulledReportExcelCF,
} from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    incomeDetailedPendingReportExcel: IObjectState<IFileResult>;
    incomeDetailedPendingPaymentReportExcel: IObjectState<IFileResult>;
    incomeDetailedCompletedReportExcel: IObjectState<IFileResult>;
    incomeDetailedAnulledReportExcel: IObjectState<IFileResult>;
}

export const initialState: IState = {
    incomeDetailedPendingReportExcel: { value: undefined, loading: false, error: undefined },
    incomeDetailedPendingPaymentReportExcel: { value: undefined, loading: false, error: undefined },
    incomeDetailedCompletedReportExcel: { value: undefined, loading: false, error: undefined },
    incomeDetailedAnulledReportExcel: { value: undefined, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL]:
        incomeDetailedCompletedReportExcelCF.request,
    [actionTypes.GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_SUCCESS]:
        incomeDetailedCompletedReportExcelCF.success,
    [actionTypes.GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_FAILURE]:
        incomeDetailedCompletedReportExcelCF.failure,
    [actionTypes.GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_RESET]:
        incomeDetailedCompletedReportExcelCF.reset,

    [actionTypes.GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL]:
        incomeDetailedPendingPaymentReportExcelCF.request,
    [actionTypes.GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_SUCCESS]:
        incomeDetailedPendingPaymentReportExcelCF.success,
    [actionTypes.GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_FAILURE]:
        incomeDetailedPendingPaymentReportExcelCF.failure,
    [actionTypes.GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_RESET]:
        incomeDetailedPendingPaymentReportExcelCF.reset,

    [actionTypes.GET_INCOME_DETAILED_PENDING_REPORT_EXCEL]:
        incomeDetailedPendingReportExcelCF.request,
    [actionTypes.GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_SUCCESS]:
        incomeDetailedPendingReportExcelCF.success,
    [actionTypes.GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_FAILURE]:
        incomeDetailedPendingReportExcelCF.failure,
    [actionTypes.GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_RESET]:
        incomeDetailedPendingReportExcelCF.reset,

    [actionTypes.GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL]:
        incomeDetailedAnulledReportExcelCF.request,
    [actionTypes.GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_SUCCESS]:
        incomeDetailedAnulledReportExcelCF.success,
    [actionTypes.GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_FAILURE]:
        incomeDetailedAnulledReportExcelCF.failure,
    [actionTypes.GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_RESET]:
        incomeDetailedAnulledReportExcelCF.reset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
