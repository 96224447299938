import React, { FunctionComponent } from 'react';
import { Tabs, Icon } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { SectionContainer } from '../../../../components';
import { IncomeListByProperties, IncomeListByShareholders } from './components';

const IncomeList: FunctionComponent<RouteComponentProps> = props => {
    return (
        <SectionContainer title="Ingresos" icon="clock-circle">
            <Tabs>
                <Tabs.TabPane
                    tab={
                        <span>
                            <Icon type="environment" />
                            Puestos
                        </span>
                    }
                    key="1"
                >
                    <IncomeListByProperties {...props} />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        <span>
                            <Icon type="user" />
                            Accionistas
                        </span>
                    }
                    key="2"
                >
                    <IncomeListByShareholders {...props} />
                </Tabs.TabPane>
            </Tabs>
        </SectionContainer>
    );
};

export default IncomeList;
