import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem, TableRowOptionsDropdown, PBR } from '../../../../../../components';
import { IPaymentItem } from '../../../../../../common/types/entities';
import { formatHelper } from '../../../../../../common/helpers';
import { permissions } from '../../../../../../common/constants';

export const columns = (
    onClickEdit: (id: number) => void,
    onClickDelete: (id: number) => void,
): ColumnProps<IPaymentItem>[] => [
    {
        title: 'Descripción',
        dataIndex: 'description',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Precio Referencial',
        dataIndex: 'referentialPrice',
        className: 'd-none d-md-table-cell',
        render: (referentialPrice: number) => <span>{formatHelper.toMoney(referentialPrice)}</span>,
    },
    {
        title: 'Código Sunat',
        dataIndex: 'sunatCode',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Impuesto',
        dataIndex: 'tax.description',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Tipo',
        dataIndex: 'type.description',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Puesto',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IPaymentItem) => (
            <>
                <TableCellItem label="Descripción" value={item.description} />
                <TableCellItem
                    label="Precio Referencial"
                    value={item.referentialPrice}
                    type="money"
                />
                <TableCellItem label="Código Sunat" value={item.sunatCode} />
                <TableCellItem label="Impuesto" value={item.tax ? item.tax.description : ''} />
                <TableCellItem label="Tipo" value={item.type ? item.type.description : ''} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IPaymentItem) => (
            <PBR permission={permissions.paymentItem.WRITE_PAYMENT_ITEMS}>
                <TableRowOptionsDropdown
                    onClickEdit={() => onClickEdit(item.id)}
                    onClickDelete={() => onClickDelete(item.id)}
                />
            </PBR>
        ),
    },
];
