import React from 'react';
import { FormikActions } from 'formik';
import {
    CustomForm,
    DisplayItem,
    DataTable,
    SelectGroupField,
    DatePickerGroupField,
} from '../../../../../components';
import {
    IPaymentDetail,
    IProvider,
    IInvoice,
    IBankAccount,
    IPaymentType,
    ICurrency,
} from '../../../../../common/types/entities';
import { Divider, Row, Col, Button, message, Upload, Icon } from 'antd';
import { messages, currencyTypes, currencies } from '../../../../../common/constants';
import { columns, expandedRowRender } from './columns';
import { formatHelper } from '../../../../../common/helpers';

interface IOwnProps {
    providers: IProvider[];
    providersLoading: boolean;
    getInvoicesByProviderDetails: (id: number) => void;
    invoicesByProviderDetails: IInvoice[];
    invoicesByProviderDetailsLoading: boolean;
    bankAccounts: IBankAccount[];
    bankAccountsLoading: boolean;
    paymentTypes: IPaymentType[];
    paymentTypesLoading: boolean;
}

interface IOwnState {
    paymentDetailsToEdit: IPaymentDetail[];
    currency: ICurrency;
    providerSelected: number;
    files: (File | Blob)[];
}

type IProps = IOwnProps;

export interface IPaymentFormValues {
    paymentDate: Date | string;
    paymentType: { id: number | string };
    bankAccount: { id: number | string };
    provider: { id: number | string };
    files: (File | Blob)[];
}

export default class IPaymentForm extends CustomForm<IPaymentFormValues, IProps> {
    state: IOwnState = {
        paymentDetailsToEdit: [],
        currency: null,
        providerSelected: null,
        files: [],
    };

    addOrUpdatePaymentDetail = (paymentPrice: number, invoiceId: number, paymentRest: number) => {
        let paymentDetail: IPaymentDetail = {
            totalPrice: paymentPrice,
            invoice: { id: invoiceId, paymentRest },
            currency: this.state.currency,
        };
        let paymentDetailsToEdit = this.state.paymentDetailsToEdit;

        const index = paymentDetailsToEdit.findIndex(e => e.invoice.id == paymentDetail.invoice.id);

        if (index == -1) paymentDetailsToEdit.push(paymentDetail);
        else paymentDetailsToEdit[index] = paymentDetail;

        this.setState({ paymentDetailsToEdit });
    };

    onSelectBankAccount = (id: number) => {
        let bankAccountSelected = this.props.bankAccounts.find(e => e.id == id);

        this.setState({ currency: bankAccountSelected.currency, paymentDetailsToEdit: [] });
    };

    initialValues = (): IPaymentFormValues => {
        return {
            paymentDate: undefined,
            paymentType: { id: undefined },
            bankAccount: { id: undefined },
            provider: { id: undefined },
            files: [],
        };
    };

    onChangeProvider = value => {
        this.setState({ providerSelected: value });
        this.props.getInvoicesByProviderDetails(value);
    };

    getTotalPayment = (): string => {
        let totalInvoice = 0;

        for (const item of this.state.paymentDetailsToEdit) {
            totalInvoice += item.totalPrice;
        }

        return formatHelper.toMoney(Number(totalInvoice));
    };

    onSubmit = (values: IPaymentFormValues, actions: FormikActions<IPaymentFormValues>) => {
        const paymentDetails = this.state.paymentDetailsToEdit.filter(e => e.totalPrice != 0);

        // @ts-ignore
        values.currency = this.state.currency;
        if (paymentDetails.length === 0) {
            message.error('Se debe agregar por lo menos un detalle de pago');
            return;
        }

        const files = this.state.files;
        if (files.length > 0) {
            values.files = files;
        }

        const finalValues: any = {
            ...values,
            paymentDetails,
        };

        this.props.onSubmit(finalValues);
        actions.setSubmitting(false);
    };

    validate = (values: IPaymentFormValues) => {
        const errors: Partial<IPaymentFormValues> = {};

        if (!values.paymentDate) errors.paymentDate = messages.MANDATORY_FIELD;
        if (!values.paymentType) errors.paymentType.id = messages.MANDATORY_FIELD;
        if (!values.bankAccount) errors.bankAccount.id = messages.MANDATORY_FIELD;
        if (!values.provider.id || values.provider.id == 0)
            errors.provider = { id: messages.MANDATORY_FIELD };

        return errors;
    };

    onCancel = () => this.props.onCancel();

    getSelectedCurrencyCode = (withBrackets = false) => {
        const { currency } = this.state;
        if (!currency) return '';

        if (currency.id == currencyTypes.PEN) return withBrackets ? '(PEN)' : 'PEN';
        else if (currency.id == currencyTypes.USD) return withBrackets ? '(USD)' : 'USD';
        else return '';
    };

    formBody = () => {
        const {
            providers,
            providersLoading,
            invoicesByProviderDetails,
            invoicesByProviderDetailsLoading,
            bankAccounts,
            bankAccountsLoading,
            paymentTypes,
            paymentTypesLoading,
        } = this.props;

        return (
            <>
                <SelectGroupField
                    name="provider.id"
                    label="Proveedor"
                    data={providers}
                    displayText={(item: IProvider) => formatHelper.getProviderDescription(item)}
                    required
                    loading={providersLoading}
                    onChange={this.onChangeProvider}
                />
                <SelectGroupField
                    name="bankAccount.id"
                    label="Cuenta Bancaria"
                    data={bankAccounts}
                    displayText={(item: IBankAccount) =>
                        item.bank.name + ' - Nº. Cuenta: ' + item.number
                    }
                    onChange={e => this.onSelectBankAccount(Number(e))}
                    loading={bankAccountsLoading}
                    required
                />
                <DisplayItem
                    label="Moneda de Cuenta Bancaria"
                    value={this.state.currency ? this.state.currency.code : ''}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <SelectGroupField
                    name="paymentType.id"
                    label="Tipo de Pago"
                    data={paymentTypes}
                    displayText="description"
                    loading={paymentTypesLoading}
                    required
                />
                <DatePickerGroupField name="paymentDate" label="Fecha de Pago" required />
                <DisplayItem
                    label="Pago Total"
                    vStyles={{ fontWeight: 'bold' }}
                    value={formatHelper.toMoney(
                        Number(this.getTotalPayment()),
                        this.getSelectedCurrencyCode(),
                    )}
                    // value={this.getTotalPayment()}
                />
                <DataTable
                    data={invoicesByProviderDetails}
                    columns={columns(
                        this.addOrUpdatePaymentDetail,
                        this.state.paymentDetailsToEdit,
                        this.state.currency,
                    )}
                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                    loading={invoicesByProviderDetailsLoading}
                    expandedRowRender={record => expandedRowRender(record)}
                />
                <br />

                <Divider orientation="left" className="mt-5">
                    Archivos
                </Divider>
                <Row>
                    <Col lg={12} md={12} xs={24} sm={24}>
                        <Upload
                            accept=".pdf, .csv, .xlsx, .xls, .doc, .docx, .png, .jpg"
                            onChange={info => {
                                this.setState({
                                    files: info.fileList.map(item => item['originFileObj']),
                                });
                            }}
                            // stange behavior.... https://stackoverflow.com/a/56125617
                            beforeUpload={file => false}
                        >
                            <Button>
                                <Icon type="upload" /> Subir Archivos
                            </Button>
                        </Upload>
                    </Col>
                </Row>
            </>
        );
    };
}
