const actionTypes = {
    GET_PAYMENT_ITEMS: 'paymentItem/GET_PAYMENT_ITEMS',
    GET_PAYMENT_ITEMS_SUCCESS: 'paymentItem/GET_PAYMENT_ITEMS_SUCCESS',
    GET_PAYMENT_ITEMS_FAILURE: 'paymentItem/GET_PAYMENT_ITEMS_FAILURE',
    GET_PAYMENT_ITEMS_RESET: 'paymentItem/GET_PAYMENT_ITEMS_RESET',

    GET_PAYMENT_ITEM: 'paymentItem/GET_PAYMENT_ITEM',
    GET_PAYMENT_ITEM_SUCCESS: 'paymentItem/GET_PAYMENT_ITEM_SUCCESS',
    GET_PAYMENT_ITEM_FAILURE: 'paymentItem/GET_PAYMENT_ITEM_FAILURE',
    GET_PAYMENT_ITEM_RESET: 'paymentItem/GET_PAYMENT_ITEM_RESET',

    CREATE_PAYMENT_ITEM: 'paymentItem/CREATE_PAYMENT_ITEM',
    CREATE_PAYMENT_ITEM_SUCCESS: 'paymentItem/CREATE_PAYMENT_ITEM_SUCCESS',
    CREATE_PAYMENT_ITEM_FAILURE: 'paymentItem/CREATE_PAYMENT_ITEM_FAILURE',
    CREATE_PAYMENT_ITEM_RESET: 'paymentItem/CREATE_PAYMENT_ITEM_RESET',

    UPDATE_PAYMENT_ITEM: 'paymentItem/UPDATE_PAYMENT_ITEM',
    UPDATE_PAYMENT_ITEM_SUCCESS: 'paymentItem/UPDATE_PAYMENT_ITEM_SUCCESS',
    UPDATE_PAYMENT_ITEM_FAILURE: 'paymentItem/UPDATE_PAYMENT_ITEM_FAILURE',
    UPDATE_PAYMENT_ITEM_RESET: 'paymentItem/UPDATE_PAYMENT_ITEM_RESET',

    DEACTIVATE_PAYMENT_ITEM: 'paymentItem/DEACTIVATE_PAYMENT_ITEM',
    DEACTIVATE_PAYMENT_ITEM_SUCCESS: 'paymentItem/DEACTIVATE_PAYMENT_ITEM_SUCCESS',
    DEACTIVATE_PAYMENT_ITEM_FAILURE: 'paymentItem/DEACTIVATE_PAYMENT_ITEM_FAILURE',
    DEACTIVATE_PAYMENT_ITEM_RESET: 'paymentItem/DEACTIVATE_PAYMENT_ITEM_RESET',

    GET_PAYMENT_ITEM_TYPES: 'paymentItem/GET_PAYMENT_ITEM_TYPES',
    GET_PAYMENT_ITEM_TYPES_SUCCESS: 'paymentItem/GET_PAYMENT_ITEM_TYPES_SUCCESS',
    GET_PAYMENT_ITEM_TYPES_FAILURE: 'paymentItem/GET_PAYMENT_ITEM_TYPES_FAILURE',
    GET_PAYMENT_ITEM_TYPES_RESET: 'paymentItem/GET_PAYMENT_ITEM_TYPES_RESET',

    CREATE_PAYMENT_ITEM_TYPE: 'paymentItem/CREATE_PAYMENT_ITEM_TYPE',
    CREATE_PAYMENT_ITEM_TYPE_SUCCESS: 'paymentItem/CREATE_PAYMENT_ITEM_TYPE_SUCCESS',
    CREATE_PAYMENT_ITEM_TYPE_FAILURE: 'paymentItem/CREATE_PAYMENT_ITEM_TYPE_FAILURE',
    CREATE_PAYMENT_ITEM_TYPE_RESET: 'paymentItem/CREATE_PAYMENT_ITEM_TYPE_RESET',

    UPDATE_PAYMENT_ITEM_TYPE: 'paymentItem/UPDATE_PAYMENT_ITEM_TYPE',
    UPDATE_PAYMENT_ITEM_TYPE_SUCCESS: 'paymentItem/UPDATE_PAYMENT_ITEM_TYPE_SUCCESS',
    UPDATE_PAYMENT_ITEM_TYPE_FAILURE: 'paymentItem/UPDATE_PAYMENT_ITEM_TYPE_FAILURE',
    UPDATE_PAYMENT_ITEM_TYPE_RESET: 'paymentItem/UPDATE_PAYMENT_ITEM_TYPE_RESET',

    DEACTIVATE_PAYMENT_ITEM_TYPE: 'paymentItem/DEACTIVATE_PAYMENT_ITEM_TYPE',
    DEACTIVATE_PAYMENT_ITEM_TYPE_SUCCESS: 'paymentItem/DEACTIVATE_PAYMENT_ITEM_TYPE_SUCCESS',
    DEACTIVATE_PAYMENT_ITEM_TYPE_FAILURE: 'paymentItem/DEACTIVATE_PAYMENT_ITEM_TYPE_FAILURE',
    DEACTIVATE_PAYMENT_ITEM_TYPE_RESET: 'paymentItem/DEACTIVATE_PAYMENT_ITEM_TYPE_RESET',
};

export default actionTypes;
