import {
    IListStatePaginated,
    IObjectState,
    IProcessState,
    IListState,
    ICompletePaymentData,
} from '../../common/types';
import {
    IIncome,
    IReceiptType,
    IPaymentType,
    IIncomeType,
    IIncomeState,
    IElectronicBilling,
    IProperty,
    IIncomePaymentItem,
    IShareholder,
} from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    incomeByPropertiesList: IListStatePaginated<IIncome>;
    incomeByShareholdersList: IListStatePaginated<IIncome>;
    pendingListByProperties: IListStatePaginated<IProperty>;
    pendingListByShareholders: IListStatePaginated<IShareholder>;
    createIncomePropertyPaymentItem: IProcessState;
    createIncomeShareholderPaymentItem: IProcessState;
    activeIncome: IObjectState<IIncome>;
    createIncome: IObjectState<IIncome>;
    updateIncome: IProcessState;
    completePayment: IObjectState<IElectronicBilling | IIncome>;
    deactivateIncome: IProcessState;
    deleteIncome: IProcessState;
    incomeTypeList: IListState<IIncomeType>;
    incomeStateList: IListState<IIncomeState>;
    paymentTypeList: IListState<IPaymentType>;
    receiptTypeList: IListState<IReceiptType>;
    bulkLoadIncomePaymentItemsByProperties: IProcessState;
    bulkLoadIncomePaymentItemsByShareholders: IProcessState;
    propertyIncomePaymentItemList: IListState<IIncomePaymentItem>;
    shareholderIncomePaymentItemList: IListState<IIncomePaymentItem>;
    deleteIncomePaymentItem: IProcessState;
    cancelIncome: IProcessState;
    completePaymentData: ICompletePaymentData;
    condoneIncomePaymentItem: IProcessState;
    verifyPrintIncome: IProcessState;
    completePendingIncome: IProcessState;
}

export const initialState: IState = {
    incomeByPropertiesList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    incomeByShareholdersList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    pendingListByProperties: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    pendingListByShareholders: {
        data: [],
        total: 0,
        pageSize: 0,
        loading: false,
        error: undefined,
    },
    createIncomePropertyPaymentItem: { success: false, loading: false, error: undefined },
    createIncomeShareholderPaymentItem: { success: false, loading: false, error: undefined },
    activeIncome: { value: undefined, loading: false, error: undefined },
    createIncome: { value: undefined, loading: false, error: undefined },
    updateIncome: { success: false, loading: false, error: undefined },
    completePayment: { value: undefined, loading: false, error: undefined },
    deactivateIncome: { success: false, loading: false, error: undefined },
    deleteIncome: { success: false, loading: false, error: undefined },
    incomeTypeList: { data: [], loading: false, error: undefined },
    incomeStateList: { data: [], loading: false, error: undefined },
    paymentTypeList: { data: [], loading: false, error: undefined },
    receiptTypeList: { data: [], loading: false, error: undefined },
    bulkLoadIncomePaymentItemsByProperties: { success: false, loading: false, error: undefined },
    bulkLoadIncomePaymentItemsByShareholders: { success: false, loading: false, error: undefined },
    propertyIncomePaymentItemList: { data: [], loading: false, error: undefined },
    shareholderIncomePaymentItemList: { data: [], loading: false, error: undefined },
    deleteIncomePaymentItem: { success: false, loading: false, error: undefined },
    cancelIncome: { success: false, loading: false, error: undefined },
    completePaymentData: undefined,
    condoneIncomePaymentItem: { success: false, loading: false, error: undefined },
    verifyPrintIncome: { success: false, loading: false, error: undefined },
    completePendingIncome: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_INCOMES_BY_PROPERTIES]: caseFunctions.getIncomesByProperties,
    [actionTypes.GET_INCOMES_BY_PROPERTIES_SUCCESS]: caseFunctions.getIncomesByPropertiesSuccess,
    [actionTypes.GET_INCOMES_BY_PROPERTIES_FAILURE]: caseFunctions.getIncomesByPropertiesFailure,
    [actionTypes.GET_INCOMES_BY_PROPERTIES_RESET]: caseFunctions.getIncomesByPropertiesReset,

    [actionTypes.GET_INCOMES_BY_SHAREHOLDERS]: caseFunctions.getIncomesByShareholders,
    [actionTypes.GET_INCOMES_BY_SHAREHOLDERS_SUCCESS]:
        caseFunctions.getIncomesByShareholdersSuccess,
    [actionTypes.GET_INCOMES_BY_SHAREHOLDERS_FAILURE]:
        caseFunctions.getIncomesByShareholdersFailure,
    [actionTypes.GET_INCOMES_BY_SHAREHOLDERS_RESET]: caseFunctions.getIncomesByShareholdersReset,

    [actionTypes.GET_PENDINGS_BY_PROPERTIES]: caseFunctions.getPendingsByProperties,
    [actionTypes.GET_PENDINGS_BY_PROPERTIES_SUCCESS]: caseFunctions.getPendingsByPropertiesSuccess,
    [actionTypes.GET_PENDINGS_BY_PROPERTIES_FAILURE]: caseFunctions.getPendingsByPropertiesFailure,
    [actionTypes.GET_PENDINGS_BY_PROPERTIES_RESET]: caseFunctions.getPendingsByPropertiesReset,

    [actionTypes.GET_PENDINGS_BY_SHAREHOLDERS]: caseFunctions.getPendingsByShareholders,
    [actionTypes.GET_PENDINGS_BY_SHAREHOLDERS_SUCCESS]:
        caseFunctions.getPendingsByShareholdersSuccess,
    [actionTypes.GET_PENDINGS_BY_SHAREHOLDERS_FAILURE]:
        caseFunctions.getPendingsByShareholdersFailure,
    [actionTypes.GET_PENDINGS_BY_SHAREHOLDERS_RESET]: caseFunctions.getPendingsByShareholdersReset,

    [actionTypes.CREATE_INCOME_PROPERTY_PAYMENT_ITEM]:
        caseFunctions.createIncomePropertyPaymentItem,
    [actionTypes.CREATE_INCOME_PROPERTY_PAYMENT_ITEM_SUCCESS]:
        caseFunctions.createIncomePropertyPaymentItemSuccess,
    [actionTypes.CREATE_INCOME_PROPERTY_PAYMENT_ITEM_FAILURE]:
        caseFunctions.createIncomePropertyPaymentItemFailure,
    [actionTypes.CREATE_INCOME_PROPERTY_PAYMENT_ITEM_RESET]:
        caseFunctions.createIncomePropertyPaymentItemReset,

    [actionTypes.CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM]:
        caseFunctions.createIncomeShareholderPaymentItem,
    [actionTypes.CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_SUCCESS]:
        caseFunctions.createIncomeShareholderPaymentItemSuccess,
    [actionTypes.CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_FAILURE]:
        caseFunctions.createIncomeShareholderPaymentItemFailure,
    [actionTypes.CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_RESET]:
        caseFunctions.createIncomeShareholderPaymentItemReset,

    [actionTypes.GET_INCOME]: caseFunctions.getIncome,
    [actionTypes.GET_INCOME_SUCCESS]: caseFunctions.getIncomeSuccess,
    [actionTypes.GET_INCOME_FAILURE]: caseFunctions.getIncomeFailure,
    [actionTypes.GET_INCOME_RESET]: caseFunctions.getIncomeReset,

    [actionTypes.CREATE_INCOME]: caseFunctions.createIncome,
    [actionTypes.CREATE_INCOME_SUCCESS]: caseFunctions.createIncomeSuccess,
    [actionTypes.CREATE_INCOME_FAILURE]: caseFunctions.createIncomeFailure,
    [actionTypes.CREATE_INCOME_RESET]: caseFunctions.createIncomeReset,

    [actionTypes.UPDATE_INCOME]: caseFunctions.updateIncome,
    [actionTypes.UPDATE_INCOME_SUCCESS]: caseFunctions.updateIncomeSuccess,
    [actionTypes.UPDATE_INCOME_FAILURE]: caseFunctions.updateIncomeFailure,
    [actionTypes.UPDATE_INCOME_RESET]: caseFunctions.updateIncomeReset,

    [actionTypes.COMPLETE_PAYMENT]: caseFunctions.completePayment,
    [actionTypes.COMPLETE_PAYMENT_SUCCESS]: caseFunctions.completePaymentSuccess,
    [actionTypes.COMPLETE_PAYMENT_FAILURE]: caseFunctions.completePaymentFailure,
    [actionTypes.COMPLETE_PAYMENT_RESET]: caseFunctions.completePaymentReset,

    [actionTypes.DEACTIVATE_INCOME]: caseFunctions.deactivateIncome,
    [actionTypes.DEACTIVATE_INCOME_SUCCESS]: caseFunctions.deactivateIncomeSuccess,
    [actionTypes.DEACTIVATE_INCOME_FAILURE]: caseFunctions.deactivateIncomeFailure,
    [actionTypes.DEACTIVATE_INCOME_RESET]: caseFunctions.deactivateIncomeReset,

    [actionTypes.DELETE_INCOME]: caseFunctions.deleteIncome,
    [actionTypes.DELETE_INCOME_SUCCESS]: caseFunctions.deleteIncomeSuccess,
    [actionTypes.DELETE_INCOME_FAILURE]: caseFunctions.deleteIncomeFailure,
    [actionTypes.DELETE_INCOME_RESET]: caseFunctions.deleteIncomeReset,

    [actionTypes.GET_INCOME_TYPES]: caseFunctions.getIncomeTypes,
    [actionTypes.GET_INCOME_TYPES_SUCCESS]: caseFunctions.getIncomeTypesSuccess,
    [actionTypes.GET_INCOME_TYPES_FAILURE]: caseFunctions.getIncomeTypesFailure,
    [actionTypes.GET_INCOME_TYPES_RESET]: caseFunctions.getIncomeTypesReset,

    [actionTypes.GET_INCOME_STATES]: caseFunctions.getIncomeStates,
    [actionTypes.GET_INCOME_STATES_SUCCESS]: caseFunctions.getIncomeStatesSuccess,
    [actionTypes.GET_INCOME_STATES_FAILURE]: caseFunctions.getIncomeStatesFailure,
    [actionTypes.GET_INCOME_STATES_RESET]: caseFunctions.getIncomeStatesReset,

    [actionTypes.GET_PAYMENT_TYPES]: caseFunctions.getPaymentTypes,
    [actionTypes.GET_PAYMENT_TYPES_SUCCESS]: caseFunctions.getPaymentTypesSuccess,
    [actionTypes.GET_PAYMENT_TYPES_FAILURE]: caseFunctions.getPaymentTypesFailure,
    [actionTypes.GET_PAYMENT_TYPES_RESET]: caseFunctions.getPaymentTypesReset,

    [actionTypes.GET_RECEIPT_TYPES]: caseFunctions.getReceiptTypes,
    [actionTypes.GET_RECEIPT_TYPES_SUCCESS]: caseFunctions.getReceiptTypesSuccess,
    [actionTypes.GET_RECEIPT_TYPES_FAILURE]: caseFunctions.getReceiptTypesFailure,
    [actionTypes.GET_RECEIPT_TYPES_RESET]: caseFunctions.getReceiptTypesReset,

    [actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES]:
        caseFunctions.bulkLoadIncomePaymentItemsByProperties,
    [actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_SUCCESS]:
        caseFunctions.bulkLoadIncomePaymentItemsByPropertiesSuccess,
    [actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_FAILURE]:
        caseFunctions.bulkLoadIncomePaymentItemsByPropertiesFailure,
    [actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_RESET]:
        caseFunctions.bulkLoadIncomePaymentItemsByPropertiesReset,

    [actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS]:
        caseFunctions.bulkLoadIncomePaymentItemsByShareholders,
    [actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_SUCCESS]:
        caseFunctions.bulkLoadIncomePaymentItemsByShareholdersSuccess,
    [actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_FAILURE]:
        caseFunctions.bulkLoadIncomePaymentItemsByShareholdersFailure,
    [actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_RESET]:
        caseFunctions.bulkLoadIncomePaymentItemsByShareholdersReset,

    [actionTypes.GET_PROPERTY_INCOME_PAYMENT_ITEMS]: caseFunctions.getPropertyIncomePaymentItems,
    [actionTypes.GET_PROPERTY_INCOME_PAYMENT_ITEMS_SUCCESS]:
        caseFunctions.getPropertyIncomePaymentItemsSuccess,
    [actionTypes.GET_PROPERTY_INCOME_PAYMENT_ITEMS_FAILURE]:
        caseFunctions.getPropertyIncomePaymentItemsFailure,
    [actionTypes.GET_PROPERTY_INCOME_PAYMENT_ITEMS_RESET]:
        caseFunctions.getPropertyIncomePaymentItemsReset,

    [actionTypes.GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS]:
        caseFunctions.getShareholderIncomePaymentItems,
    [actionTypes.GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_SUCCESS]:
        caseFunctions.getShareholderIncomePaymentItemsSuccess,
    [actionTypes.GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_FAILURE]:
        caseFunctions.getShareholderIncomePaymentItemsFailure,
    [actionTypes.GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_RESET]:
        caseFunctions.getShareholderIncomePaymentItemsReset,

    [actionTypes.DELETE_INCOME_PAYMENT_ITEM]: caseFunctions.deleteIncomePaymentItem,
    [actionTypes.DELETE_INCOME_PAYMENT_ITEM_SUCCESS]: caseFunctions.deleteIncomePaymentItemSuccess,
    [actionTypes.DELETE_INCOME_PAYMENT_ITEM_FAILURE]: caseFunctions.deleteIncomePaymentItemFailure,
    [actionTypes.DELETE_INCOME_PAYMENT_ITEM_RESET]: caseFunctions.deleteIncomePaymentItemReset,

    [actionTypes.CANCEL_INCOME]: caseFunctions.cancelIncome,
    [actionTypes.CANCEL_INCOME_SUCCESS]: caseFunctions.cancelIncomeSuccess,
    [actionTypes.CANCEL_INCOME_FAILURE]: caseFunctions.cancelIncomeFailure,
    [actionTypes.CANCEL_INCOME_RESET]: caseFunctions.cancelIncomeReset,

    [actionTypes.SET_COMPLETE_PAYMENT_DATA]: caseFunctions.setCompletePaymentData,
    [actionTypes.SET_COMPLETE_PAYMENT_DATA_RESET]: caseFunctions.setCompletePaymentDataReset,

    [actionTypes.CONDONE_INCOME_PAYMENT_ITEM]: caseFunctions.condoneIncomePaymentItem,
    [actionTypes.CONDONE_INCOME_PAYMENT_ITEM_SUCCESS]:
        caseFunctions.condoneIncomePaymentItemSuccess,
    [actionTypes.CONDONE_INCOME_PAYMENT_ITEM_FAILURE]:
        caseFunctions.condoneIncomePaymentItemFailure,
    [actionTypes.CONDONE_INCOME_PAYMENT_ITEM_RESET]: caseFunctions.condoneIncomePaymentItemReset,

    [actionTypes.VERIFY_PRINT_INCOME]: caseFunctions.verifyPrintIncome,
    [actionTypes.VERIFY_PRINT_INCOME_SUCCESS]: caseFunctions.verifyPrintIncomeSuccess,
    [actionTypes.VERIFY_PRINT_INCOME_FAILURE]: caseFunctions.verifyPrintIncomeFailure,
    [actionTypes.VERIFY_PRINT_INCOME_RESET]: caseFunctions.verifyPrintIncomeReset,

    [actionTypes.COMPLETE_PENDING_INCOME]: caseFunctions.completePendingIncome,
    [actionTypes.COMPLETE_PENDING_INCOME_SUCCESS]: caseFunctions.completePendingIncomeSuccess,
    [actionTypes.COMPLETE_PENDING_INCOME_FAILURE]: caseFunctions.completePendingIncomeFailure,
    [actionTypes.COMPLETE_PENDING_INCOME_RESET]: caseFunctions.completePendingIncomeReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
