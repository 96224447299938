import { IListStatePaginated, IObjectState, IProcessState } from '../../common/types';
import { IProvider } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    providerList: IListStatePaginated<IProvider>;
    activeProvider: IObjectState<IProvider>;
    createProvider: IProcessState;
    updateProvider: IProcessState;
    deactivateProvider: IProcessState;
}

export const initialState: IState = {
    providerList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activeProvider: { value: undefined, loading: false, error: undefined },
    createProvider: { success: false, loading: false, error: undefined },
    updateProvider: { success: false, loading: false, error: undefined },
    deactivateProvider: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_PROVIDERS]: caseFunctions.getProviders,
    [actionTypes.GET_PROVIDERS_SUCCESS]: caseFunctions.getProvidersSuccess,
    [actionTypes.GET_PROVIDERS_FAILURE]: caseFunctions.getProvidersFailure,
    [actionTypes.GET_PROVIDERS_RESET]: caseFunctions.resetGetProviders,

    [actionTypes.GET_PROVIDER]: caseFunctions.getProvider,
    [actionTypes.GET_PROVIDER_SUCCESS]: caseFunctions.getProviderSuccess,
    [actionTypes.GET_PROVIDER_FAILURE]: caseFunctions.getProviderFailure,
    [actionTypes.GET_PROVIDER_RESET]: caseFunctions.getProviderReset,

    [actionTypes.CREATE_PROVIDER]: caseFunctions.createProvider,
    [actionTypes.CREATE_PROVIDER_SUCCESS]: caseFunctions.createProviderSuccess,
    [actionTypes.CREATE_PROVIDER_FAILURE]: caseFunctions.createProviderFailure,
    [actionTypes.CREATE_PROVIDER_RESET]: caseFunctions.createProviderReset,

    [actionTypes.UPDATE_PROVIDER]: caseFunctions.updateProvider,
    [actionTypes.UPDATE_PROVIDER_SUCCESS]: caseFunctions.updateProviderSuccess,
    [actionTypes.UPDATE_PROVIDER_FAILURE]: caseFunctions.updateProviderFailure,
    [actionTypes.UPDATE_PROVIDER_RESET]: caseFunctions.updateProviderReset,

    [actionTypes.DEACTIVATE_PROVIDER]: caseFunctions.deactivateProvider,
    [actionTypes.DEACTIVATE_PROVIDER_SUCCESS]: caseFunctions.deactivateProviderSuccess,
    [actionTypes.DEACTIVATE_PROVIDER_FAILURE]: caseFunctions.deactivateProviderFailure,
    [actionTypes.DEACTIVATE_PROVIDER_RESET]: caseFunctions.deactivateProviderReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
