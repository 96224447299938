import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../components';
import { IPettyCash } from '../../../../common/types/entities';
import { Button } from 'antd';
import { formatHelper } from '../../../../common/helpers';

export const columns = (onClickDetail: (id: number) => void): ColumnProps<IPettyCash>[] => [
    {
        title: 'Correlativo',
        dataIndex: 'correlative',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Monto Inicial',
        dataIndex: 'startingAmount',
        className: 'd-none d-md-table-cell',
        render: (startingAmount: number) => <span>{formatHelper.toMoney(startingAmount)}</span>,
    },
    {
        title: 'Monto Actual',
        dataIndex: 'currentAmount',
        className: 'd-none d-md-table-cell',
        render: (currentAmount: number) => <span>{formatHelper.toMoney(currentAmount)}</span>,
    },
    {
        title: 'Fecha Apertura',
        dataIndex: 'createdAt',
        className: 'd-none d-md-table-cell',
        render: (createdAt: Date) => <span>{formatHelper.toDate(createdAt)}</span>,
    },
    {
        title: 'Activo',
        dataIndex: 'active',
        className: 'd-none d-md-table-cell',
        render: (active: boolean) => <span>{active ? 'Sí' : 'No'}</span>,
    },
    {
        key: 'xs',
        title: 'Caja Chica',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IPettyCash) => (
            <>
                <TableCellItem label="Correlativo" value={item.correlative} />
                <TableCellItem label="Monto Inicial" value={item.startingAmount} type="money" />
                <TableCellItem label="Monto Actual" value={item.currentAmount} type="money" />
                <TableCellItem label="Fecha Apertura" value={item.createdAt} type="date" />
                <TableCellItem label="Activo" value={item.active ? 'Sí' : 'No'} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IPettyCash) => {
            return (
                <Button
                    type="primary"
                    shape="round"
                    icon="eye"
                    onClick={() => onClickDetail(item.id)}
                />
            );
        },
    },
];
