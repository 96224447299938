import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './PaymentItemTypeList.types';
import ZoneList from './PaymentItemTypeList';
import { IRootState } from '../../../../../common/types';
import {
    getPaymentItemTypes,
    resetPaymentItemTypes,
    createPaymentItemType,
    resetCreatePaymentItemType,
    updatePaymentItemType,
    resetUpdatePaymentItemType,
    deactivatePaymentItemType,
    resetDeactivatePaymentItemType,
} from '../../../../../store/paymentItem/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const {
        paymentItemTypeList,
        createPaymentItemType,
        updatePaymentItemType,
        deactivatePaymentItemType,
    } = state.paymentItem;

    return {
        paymentItemTypes: paymentItemTypeList.data,
        total: paymentItemTypeList.total,
        pageSize: paymentItemTypeList.pageSize,
        loading: paymentItemTypeList.loading,
        error: paymentItemTypeList.error,
        createSuccess: createPaymentItemType.success,
        createLoading: createPaymentItemType.loading,
        createError: createPaymentItemType.error,
        updateSuccess: updatePaymentItemType.success,
        updateLoading: updatePaymentItemType.loading,
        updateError: updatePaymentItemType.error,
        deactivateLoading: deactivatePaymentItemType.loading,
        deactivateSuccess: deactivatePaymentItemType.success,
        deactivateError: deactivatePaymentItemType.error,
        permissions: state.auth.loggedInUser.value.permissions,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getPaymentItemTypes,
            resetPaymentItemTypes,
            createPaymentItemType,
            resetCreatePaymentItemType,
            updatePaymentItemType,
            resetUpdatePaymentItemType,
            deactivatePaymentItemType,
            resetDeactivatePaymentItemType,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoneList);
