import React from 'react';
import { Button, Row, Col, Input, message, Modal } from 'antd';
import { columns } from './misc/columns';
import { IProps, IState } from './ExpenseList.types';
import { SectionContainer, DataTable, PBR } from '../../../../components';
import { paths } from '../../../../routes';
import { helper } from '../../../../common/helpers';
import { permissions } from '../../../../common/constants';

class ExpenseList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
    };

    componentDidMount() {
        this.performGetExpenses();
    }

    componentWillUnmount() {
        this.props.resetExpenses();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkExpenseDeactivate(prevProps);
    }

    async checkExpenseDeactivate(prevProps: IProps) {
        if (!prevProps.deleteSuccess && this.props.deleteSuccess) {
            message.success('Egreso eliminado');
            await this.setState({ page: 1 });
            this.performGetExpenses();
        } else if (!prevProps.deleteError && this.props.deleteError)
            message.error(this.props.deleteError);
    }

    performGetExpenses() {
        const { filter, page } = this.state;
        this.props.getExpenses(filter, page);
    }

    async filterExpenses() {
        await this.setState({ page: 1 });
        this.performGetExpenses();
    }

    performDeleteExpense = (id: number) => {
        const { deleteExpense } = this.props;

        Modal.confirm({
            title: '¿Deseas eliminar el egreso?',
            content: 'Eliminar egreso',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteExpense(id);
            },
            onCancel() {},
        });
    };

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetExpenses();
    };

    onClickDetail = (id: number) =>
        this.props.history.push(helper.setPathParams(paths.ADMIN_EXPENSE_DETAIL, { id }));

    onClickEdit = (id: number) =>
        this.props.history.push(helper.setPathParams(paths.ADMIN_EXPENSE_REQUERIMENT_EDIT, { id }));

    onClickAnswer = (id: number) =>
        this.props.history.push(helper.setPathParams(paths.ADMIN_EXPENSE_ANSWER, { id }));

    onClickLogistic = (id: number) =>
        this.props.history.push(helper.setPathParams(paths.ADMIN_EXPENSE_LOGISTIC, { id }));

    onClickRequestOrder = (id: number) =>
        this.props.history.push(helper.setPathParams(paths.ADMIN_EXPENSE_REQUEST_ORDER, { id }));

    onClickAccordance = (id: number) =>
        this.props.history.push(helper.setPathParams(paths.ADMIN_EXPENSE_ACCORDANCE, { id }));

    onClickInvoice = (id: number) =>
        this.props.history.push(helper.setPathParams(paths.ADMIN_EXPENSE_INVOICE, { id }));

    onClickPayment = (id: number) =>
        this.props.history.push(helper.setPathParams(paths.ADMIN_EXPENSE_PAYMENT, { id }));

    // onClickComplete = (id: number) =>
    //     this.props.history.push(helper.setPathParams(paths.ADMIN_EXPENSE_COMPLETE, { id }));

    render() {
        const { loading, expenses, pageSize, total } = this.props;

        return (
            <SectionContainer title="Egresos" icon="arrow-down">
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={(e) => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterExpenses()}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.expense.WRITE_REQUERIMENTS}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() =>
                                    this.props.history.push(paths.ADMIN_EXPENSE_REQUERIMENT_CREATE)
                                }
                            >
                                Nuevo Requerimiento
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={expenses}
                            columns={columns(
                                this.onClickDetail,
                                this.onClickEdit,
                                this.performDeleteExpense,
                                this.onClickAnswer,
                                this.onClickLogistic,
                                this.onClickRequestOrder,
                                this.onClickAccordance,
                                this.onClickInvoice,
                                this.onClickPayment,
                                this.props.permissions,
                            )}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
            </SectionContainer>
        );
    }
}

export default ExpenseList;
