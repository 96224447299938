import React, { FunctionComponent, useRef, useEffect } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal, Radio } from 'antd';
import { isEmpty, isNumeric } from 'validator';
import {
    InputGroupField,
    SelectGroupField,
    ImageUploadGroupField,
    RadioGroupField,
} from '../../../../../../components';
import { messages, paymentItemTypes, currencies } from '../../../../../../common/constants';
import { IProps, IAddIncomePaymetItemForm } from './AddIncomePaymentItemModal.types';
import { formatHelper } from '../../../../../../common/helpers';

const AddIncomePaymentItemModal: FunctionComponent<IProps> = props => {
    let form = useRef<Formik<IAddIncomePaymetItemForm>>(null);

    useEffect(() => {
        if (props.isOpen == false) return;

        props.getPaymentItems();
        return () => {
            props.resetPaymentItems();
        };
    }, [props.isOpen]);

    const onSubmit = (
        values: IAddIncomePaymetItemForm,
        actions: FormikActions<IAddIncomePaymetItemForm>,
    ) => {
        const paymentItem = props.paymentItems.find(x => x.id == values.paymentItem.id);
        if (paymentItem.type.id != paymentItemTypes.PENALTY_FEE) values.image = [];
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = e => {
        form.current.resetForm();
        props.onCancel(e);
    };

    const validate = (values: IAddIncomePaymetItemForm) => {
        let errors: Partial<IAddIncomePaymetItemForm> = {};

        if (!values.paymentItem.id) errors.paymentItem = { id: messages.MANDATORY_FIELD };

        if (isEmpty(values.price)) errors.price = messages.MANDATORY_FIELD;
        else if (!isNumeric(values.price)) errors.price = 'El precio debe ser un número';
        else if (Number(values.price) <= 0) errors.price = 'El precio debe ser mayor a cero';

        return errors;
    };

    const onOk = () => {
        form.current.submitForm();
    };

    const isPenaltyFee = (id: number) => {
        const paymentItem = props.paymentItems.find(x => x.id == id);
        if (!paymentItem) return false;
        return paymentItem.type.id == paymentItemTypes.PENALTY_FEE;
    };

    const renderFormik = () => {
        if (!props.isOpen) return null;

        const { paymentItems, paymentItemsLoading } = props;

        return (
            <Formik
                initialValues={{
                    paymentItem: { id: undefined },
                    currency: { id: currencies.PEN },
                    price: '',
                    comment: '',
                    image: [],
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={({ values }: FormikProps<IAddIncomePaymetItemForm>) => (
                    <Form>
                        <SelectGroupField
                            name="paymentItem.id"
                            label="Concepto"
                            data={paymentItems}
                            displayText={item => formatHelper.getPaymentItemWithType(item)}
                            loading={paymentItemsLoading}
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        <InputGroupField
                            name="price"
                            label="Precio"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        <RadioGroupField
                            name="currency.id"
                            label="Moneda"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        >
                            <Radio.Button value={currencies.PEN}>PEN</Radio.Button>
                            <Radio.Button value={currencies.USD}>USD</Radio.Button>
                        </RadioGroupField>
                        <InputGroupField
                            name="comment"
                            label="Comentario"
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        {isPenaltyFee(Number(values.paymentItem.id)) && (
                            <ImageUploadGroupField
                                name="image"
                                label="Imagen"
                                lxs={24}
                                lsm={24}
                                lmd={24}
                                llg={24}
                                lxl={6}
                                lxxl={6}
                                imd={24}
                                ixl={18}
                                ilg={24}
                            />
                        )}
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            title={'Agregar Concepto de Pago'}
            visible={props.isOpen}
            okText="Guardar"
            onOk={onOk}
            okButtonProps={{ loading: props.submitting }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.submitting }}
        >
            {renderFormik()}
        </Modal>
    );
};

export default AddIncomePaymentItemModal;
