import React, { FC } from 'react';
import { Row, Col } from 'antd';

interface IOwnProps {
    inputType?: 'text' | 'textarea';
    label: string;
    required?: boolean;
    hidden?: boolean;
    noRow?: boolean;
    lxs?: number;
    lsm?: number;
    lmd?: number;
    llg?: number;
    lxl?: number;
    lxxl?: number;
    ixs?: number;
    ism?: number;
    imd?: number;
    ilg?: number;
    ixl?: number;
    ixxl?: number;
}

export type IGroupFieldWrapperProps = IOwnProps;

const GroupFieldWrapper: FC<IGroupFieldWrapperProps> = ({
    label,
    required,
    hidden,
    noRow,
    lxs,
    lsm,
    lmd,
    llg,
    lxl,
    lxxl,
    ixs,
    ism,
    imd,
    ilg,
    ixl,
    ixxl,
    children,
}) => {
    const renderContent = () => (
        <>
            <Col xs={lxs} sm={lsm} md={lmd} lg={llg} xl={lxl} xxl={lxxl} className="mb-1 mb-sm-0">
                <span
                    className={`form-label mr-2 ${required ? 'required' : ''}`}
                >{`${label}:`}</span>
            </Col>
            <Col xs={ixs} sm={ism} md={imd} lg={ilg} xl={ixl} xxl={ixxl}>
                {children}
            </Col>
        </>
    );

    if (hidden) return null;
    return noRow ? renderContent() : <Row className="mb-4">{renderContent()}</Row>;
};

GroupFieldWrapper.defaultProps = {
    noRow: false,
    hidden: false,
    lxs: 24,
    lsm: 5,
    lmd: 4,
    llg: 3,
    lxl: 2,
    ixs: 24,
    imd: 9,
    ilg: 7,
    ixl: 6,
};

export default GroupFieldWrapper;
