import React from 'react';
import { Button, Row, Col, Select, message, Input } from 'antd';
import { IProps, IState } from './InvoiceList.types';
import { SectionContainer, DataTable } from '../../../../components';
import { paths } from '../../../../routes';
import { helper, formatHelper } from '../../../../common/helpers';
import { columns } from './misc/columns';
import { IInvoice } from '../../../../common/types/entities';
import DeactivateInvoiceModal, {
    IDeactivateInvoiceForm,
} from './components/DeactivateInvoiceModal';

export default class InvoiceList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        providerId: null,
        modalDeactivateInvoiceVisible: false,
        activeIdToDeactivate: null,
    };

    componentDidMount() {
        this.props.getProviders();
        this.performGetInvoices();
    }

    componentWillUnmount() {
        this.props.resetProviders();
        this.props.resetInvoicesByProvider();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkInvoiceDeactivate(prevProps);
    }

    performGetInvoices() {
        const { providerId, filter, page } = this.state;
        this.props.getInvoicesByProvider(providerId, filter, page);
    }

    async filterInvoices() {
        await this.setState({ page: 1 });
        this.performGetInvoices();
    }

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetInvoices();
    };

    onStateChange = async (providerId: number) => {
        await this.setState({ page: 1, providerId });
        this.performGetInvoices();
    };

    async checkInvoiceDeactivate(prevProps: IProps) {
        if (
            !prevProps.deactivateInvoiceProviderSuccess &&
            this.props.deactivateInvoiceProviderSuccess
        ) {
            message.success('Factura anulada');
            await this.setState({ page: 1 });
            this.toggleModalDeactivateInvoiceVisible();

            const { providerId, filter, page } = this.state;
            this.props.getInvoicesByProvider(providerId, filter, page);
        } else if (
            !prevProps.deactivateInvoiceProviderError &&
            this.props.deactivateInvoiceProviderError
        )
            message.error(this.props.deactivateInvoiceProviderError);
    }

    onChangeProvider = async (providerId: number) => {
        await this.setState({ page: 1, providerId });
        this.performGetInvoices();
    };

    detailInvoice = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_INVOICE_DETAIL, { id }));
    };

    toggleModalDeactivateInvoiceVisible = () => {
        this.setState({ modalDeactivateInvoiceVisible: !this.state.modalDeactivateInvoiceVisible });
    };

    deactivateInvoice = (id: number) => {
        this.toggleModalDeactivateInvoiceVisible();
        this.setState({ activeIdToDeactivate: id });
    };

    onClickDeactivateInvoice = (values: IDeactivateInvoiceForm) => {
        const invoice: IInvoice = {
            reasonToDelete: values.reasonToDeactivate,
        };

        this.props.deactivateInvoiceProvider(this.state.activeIdToDeactivate, invoice);
    };

    render() {
        const {
            providers,
            providersLoading,
            invoicesByProvider,
            invoicesByProviderLoading,
            pageSize,
            total,
        } = this.props;

        return (
            <>
                <SectionContainer title="Facturas" icon="audit">
                    <Row className="mb-4">
                        <Col md={12} lg={6}>
                            <Input.Search
                                placeholder="Buscar"
                                enterButton
                                value={this.state.filter}
                                onChange={e => this.setState({ filter: e.target.value })}
                                onSearch={() => this.filterInvoices()}
                            />
                        </Col>
                        <Col className="ml-md-3 ml-lg-4" md={11} lg={6}>
                            <Row>
                                <Col className="mt-2 mt-md-1" md={5} lg={6}>
                                    Proveedor:
                                </Col>
                                <Col md={18} lg={18}>
                                    {providers && (
                                        <Select
                                            className="w-100"
                                            value={this.state.providerId}
                                            onChange={this.onChangeProvider}
                                            loading={providersLoading}
                                            allowClear={true}
                                        >
                                            {providers.map((provider, index) => (
                                                <Select.Option key={index} value={provider.id}>
                                                    {formatHelper.getProviderDescription(provider)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() => this.props.history.push(paths.ADMIN_INVOICE_CREATE)}
                            >
                                Nueva Factura
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTable
                                data={invoicesByProvider}
                                columns={columns(this.detailInvoice, this.deactivateInvoice)}
                                pageSize={pageSize}
                                total={total}
                                currentPage={this.state.page}
                                loading={invoicesByProviderLoading}
                            />
                        </Col>
                    </Row>
                </SectionContainer>
                <DeactivateInvoiceModal
                    isOpen={this.state.modalDeactivateInvoiceVisible}
                    onSubmit={this.onClickDeactivateInvoice}
                    onCancel={() => this.setState({ modalDeactivateInvoiceVisible: false })}
                    initialValues={null}
                    submitting={this.props.deactivateInvoiceProviderLoading}
                    title="Anular Factura"
                />
            </>
        );
    }
}
