const actionTypes = {
    GET_CONFIGURES: 'configure/GET_CONFIGURES',
    GET_CONFIGURES_SUCCESS: 'configure/GET_CONFIGURES_SUCCESS',
    GET_CONFIGURES_FAILURE: 'configure/GET_CONFIGURES_FAILURE',
    GET_CONFIGURES_RESET: 'configure/GET_CONFIGURES_RESET',

    UPDATE_CONFIGURES: 'configure/UPDATE_CONFIGURES',
    UPDATE_CONFIGURES_SUCCESS: 'configure/UPDATE_CONFIGURES_SUCCESS',
    UPDATE_CONFIGURES_FAILURE: 'configure/UPDATE_CONFIGURES_FAILURE',
    UPDATE_CONFIGURES_RESET: 'configure/UPDATE_CONFIGURES_FAILURE',
};

export default actionTypes;
