import { IListStatePaginated } from '../../common/types';
import { ICurrency } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    currencyList: IListStatePaginated<ICurrency>;
}

export const initialState: IState = {
    currencyList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_CURRENCIES]: caseFunctions.getCurrencies,
    [actionTypes.GET_CURRENCIES_SUCCESS]: caseFunctions.getCurrenciesSuccess,
    [actionTypes.GET_CURRENCIES_FAILURE]: caseFunctions.getCurrenciesFailure,
    [actionTypes.GET_CURRENCIES_RESET]: caseFunctions.getCurrenciesReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
