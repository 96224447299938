import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../../components';
import { IIncomePaymentItem } from '../../../../../../common/types/entities';
import { formatHelper } from '../../../../../../common/helpers';
import { Button } from 'antd';

export const columns = (
    onClickDelete: (item: IIncomePaymentItem) => void,
): ColumnProps<IIncomePaymentItem>[] => [
    {
        title: 'Concepto',
        dataIndex: 'paymentItem',
        className: 'd-none d-md-table-cell',
        render: paymentItem => <span>{formatHelper.getPaymentItemWithType(paymentItem)}</span>,
    },
    {
        title: 'Precio',
        dataIndex: '',
        className: 'd-none d-md-table-cell',
        render: (item: IIncomePaymentItem) => (
            <span>{`${item.currency.symbol} ${formatHelper.toMoney(item.price)}`}</span>
        ),
    },
    {
        title: 'Comentario',
        dataIndex: 'comment',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Concepto de Pago',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IIncomePaymentItem) => (
            <>
                <TableCellItem
                    label="Concepto"
                    value={formatHelper.getPaymentItemWithType(item.paymentItem)}
                />
                <TableCellItem label="Precio" value={item.price} />
                <TableCellItem label="Comentario" value={item.comment} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IIncomePaymentItem) => (
            <Button icon="delete" type="primary" onClick={() => onClickDelete(item)} />
        ),
    },
];
