import { IListStatePaginated, IObjectState, IProcessState, IListState } from '../../common/types';
import { ICustomer, IProperty } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    customerList: IListStatePaginated<ICustomer>;
    activeCustomer: IObjectState<ICustomer>;
    createCustomer: IProcessState;
    updateCustomer: IProcessState;
    deactivateCustomer: IProcessState;
    propertyList: IListState<IProperty>;
}

export const initialState: IState = {
    customerList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activeCustomer: { value: undefined, loading: false, error: undefined },
    createCustomer: { success: false, loading: false, error: undefined },
    updateCustomer: { success: false, loading: false, error: undefined },
    deactivateCustomer: { success: false, loading: false, error: undefined },
    propertyList: { data: [], loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_CUSTOMERS]: caseFunctions.getCustomers,
    [actionTypes.GET_CUSTOMERS_SUCCESS]: caseFunctions.getCustomersSuccess,
    [actionTypes.GET_CUSTOMERS_FAILURE]: caseFunctions.getCustomersFailure,
    [actionTypes.GET_CUSTOMERS_RESET]: caseFunctions.resetGetCustomers,

    [actionTypes.GET_CUSTOMER]: caseFunctions.getCustomer,
    [actionTypes.GET_CUSTOMER_SUCCESS]: caseFunctions.getCustomerSuccess,
    [actionTypes.GET_CUSTOMER_FAILURE]: caseFunctions.getCustomerFailure,
    [actionTypes.GET_CUSTOMER_RESET]: caseFunctions.getCustomerReset,

    [actionTypes.CREATE_CUSTOMER]: caseFunctions.createCustomer,
    [actionTypes.CREATE_CUSTOMER_SUCCESS]: caseFunctions.createCustomerSuccess,
    [actionTypes.CREATE_CUSTOMER_FAILURE]: caseFunctions.createCustomerFailure,
    [actionTypes.CREATE_CUSTOMER_RESET]: caseFunctions.createCustomerReset,

    [actionTypes.UPDATE_CUSTOMER]: caseFunctions.updateCustomer,
    [actionTypes.UPDATE_CUSTOMER_SUCCESS]: caseFunctions.updateCustomerSuccess,
    [actionTypes.UPDATE_CUSTOMER_FAILURE]: caseFunctions.updateCustomerFailure,
    [actionTypes.UPDATE_CUSTOMER_RESET]: caseFunctions.updateCustomerReset,

    [actionTypes.DEACTIVATE_CUSTOMER]: caseFunctions.deactivateCustomer,
    [actionTypes.DEACTIVATE_CUSTOMER_SUCCESS]: caseFunctions.deactivateCustomerSuccess,
    [actionTypes.DEACTIVATE_CUSTOMER_FAILURE]: caseFunctions.deactivateCustomerFailure,
    [actionTypes.DEACTIVATE_CUSTOMER_RESET]: caseFunctions.deactivateCustomerReset,

    [actionTypes.GET_CUSTOMER_PROPERTIES]: caseFunctions.getProperties,
    [actionTypes.GET_CUSTOMER_PROPERTIES_SUCCESS]: caseFunctions.getPropertiesSuccess,
    [actionTypes.GET_CUSTOMER_PROPERTIES_FAILURE]: caseFunctions.getPropertiesFailure,
    [actionTypes.GET_CUSTOMER_PROPERTIES_RESET]: caseFunctions.getPropertiesReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
