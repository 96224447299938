import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';
import { IRequestOrder } from '../common/types/entities'

const { REQUEST_ORDER_URL } = apiUrls;

export function getAll(filter: string, page: number) {
  const params = { filter, page };

  return APIManager.get(REQUEST_ORDER_URL, { params });
}

export function get(id: number) {
  const url = `${REQUEST_ORDER_URL}/${id}`;
  return APIManager.get<IRequestOrder>(url)
}

export function getRequestOdersByExpense(id: number) {
  const url = `${REQUEST_ORDER_URL}/expense/${id}`
  return APIManager.get(url)
}

export function create(data: IRequestOrder) {
  return data.files && data.files.length > 0
    ? APIManager.postWithFiles(REQUEST_ORDER_URL, { data })
    : APIManager.post(REQUEST_ORDER_URL, { data })
}

export function getExpenseDetails(id: number) {
  const url = `${REQUEST_ORDER_URL}/${id}/details`;
  return APIManager.get(url);
}

export function update(id: number, data: any) {
  const url = `${REQUEST_ORDER_URL}/${id}`;
  return APIManager.put(url, { data })
}

export function accordingRequestOrder(requestOrderId: number, data: any) {
  const url = `${REQUEST_ORDER_URL}/${requestOrderId}/according`;
  return APIManager.put(url, { data });
}

export function deactivate(id: number) {
  const url = `${REQUEST_ORDER_URL}/${id}/deactivate`
  return APIManager.put(url);
}
