import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { commonRequestService } from '../../service';

export function getRucInfo(ruc: string): IAPIMiddleware {
    return {
        callAPI: () => commonRequestService.getRucInfo(ruc),
        types: [
            actionTypes.GET_RUC_INFO,
            actionTypes.GET_RUC_INFO_SUCCESS,
            actionTypes.GET_RUC_INFO_FAILURE,
        ],
    };
}

export function resetRucInfo() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_RUC_INFO_RESET,
        });
    };
}
