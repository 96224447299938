import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    getIncomeTypes,
    resetIncomeTypes,
    getPaymentTypes,
    resetPaymentTypes,
    getReceiptTypes,
    resetReceiptTypes,
    completeIncomePayment,
    resetCompletePaymentData,
} from '../../../../store/income/actions';
import { getCurrencies, resetCurrencies } from '../../../../store/currency/actions';
import { getBankAccounts, resetBankAccounts } from '../../../../store/bankAccount/actions';
import { IStoreState } from './IncomeCompletePayment.types';
import IncomeCompletePayment from './IncomeCompletePayment';
import { IRootState } from '../../../../common/types';
import { IPaymentType } from '../../../../common/types/entities';
import { paymentTypes } from '../../../../common/constants';

const selectPamentTypes = (data: IPaymentType[]) => {
    if (!data || data.length == 0) return [];
    return data.filter(item => item.id != paymentTypes.CHECK);
};

function mapStateToProps(state: IRootState): IStoreState {
    const {
        incomeTypeList,
        paymentTypeList,
        receiptTypeList,
        completePayment,
        completePaymentData,
    } = state.income;
    const { currencyList } = state.currency;
    const { bankAccountList } = state.bankAccount;

    return {
        completePaymentData,
        incomeTypes: incomeTypeList.data,
        incomeTypesLoading: incomeTypeList.loading,
        paymentTypes: selectPamentTypes(paymentTypeList.data),
        paymentTypesLoading: paymentTypeList.loading,
        receiptTypes: receiptTypeList.data,
        receiptTypesLoading: receiptTypeList.loading,
        currencies: currencyList.data,
        currenciesLoading: currencyList.loading,
        bankAccounts: bankAccountList.data,
        bankAccountsLoading: bankAccountList.loading,
        completePaymentPayload: completePayment.value,
        completePaymentLoading: completePayment.loading,
        completePaymentError: completePayment.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getIncomeTypes,
            resetIncomeTypes,
            getPaymentTypes,
            resetPaymentTypes,
            getReceiptTypes,
            resetReceiptTypes,
            getCurrencies,
            resetCurrencies,
            getBankAccounts,
            resetBankAccounts,
            completeIncomePayment,
            resetCompletePaymentData,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomeCompletePayment);
