import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
    objectStateLoading,
    objectStateSuccess,
    objectStateFailure,
    processStateLoading,
    processStateSuccess,
    processStateFailure,
} from '../../common/helpers/reducer';

//#region GET ZONES
export function getSubzones(state: IState): IState {
    return {
        ...state,
        subzoneList: listStatePaginatedLoading(state.subzoneList),
    };
}

export function getSubzonesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        subzoneList: listStatePaginatedSuccess(action.payload),
    };
}

export function getSubzonesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        subzoneList: listStatePaginatedFailure(initialState.subzoneList, action.payload.error),
    };
}

export function getSubzonesReset(state: IState): IState {
    return {
        ...state,
        subzoneList: initialState.subzoneList,
    };
}
//#endregion

//#region GET ZONE
export function getSubzone(state: IState): IState {
    return {
        ...state,
        activeSubzone: objectStateLoading(),
    };
}

export function getSubzoneSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activeSubzone: objectStateSuccess(action.payload),
    };
}

export function getSubzoneFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activeSubzone: objectStateFailure(action.payload.error),
    };
}

export function getSubzoneReset(state: IState): IState {
    return {
        ...state,
        activeSubzone: initialState.activeSubzone,
    };
}
//#endregion

//#region CREATE CUSTOMER
export function createSubzone(state: IState): IState {
    return {
        ...state,
        createSubzone: processStateLoading(),
    };
}

export function createSubzoneSuccess(state: IState): IState {
    return {
        ...state,
        createSubzone: processStateSuccess(),
    };
}

export function createSubzoneFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createSubzone: processStateFailure(action.payload.error),
    };
}

export function createSubzoneReset(state: IState): IState {
    return {
        ...state,
        createSubzone: initialState.createSubzone,
    };
}
//#endregion

//#region UPDATE CUSTOMER
export function updateSubzone(state: IState): IState {
    return {
        ...state,
        updateSubzone: processStateLoading(),
    };
}

export function updateSubzoneSuccess(state: IState): IState {
    return {
        ...state,
        updateSubzone: processStateSuccess(),
    };
}

export function updateSubzoneFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateSubzone: processStateFailure(action.payload.error),
    };
}

export function updateSubzoneReset(state: IState): IState {
    return {
        ...state,
        updateSubzone: initialState.updateSubzone,
    };
}
//#endregion

//#region DEACTIVATE CUSTOMER
export function deactivateSubzone(state: IState): IState {
    return {
        ...state,
        deactivateSubzone: processStateLoading(),
    };
}

export function deactivateSubzoneSuccess(state: IState): IState {
    return {
        ...state,
        deactivateSubzone: processStateSuccess(),
    };
}

export function deactivateSubzoneFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateSubzone: processStateFailure(action.payload.error),
    };
}

export function deactivateSubzoneReset(state: IState): IState {
    return {
        ...state,
        deactivateSubzone: initialState.deactivateSubzone,
    };
}
//#endregion

//#region GET SUBZONE PROPERTIES
export function getSubzoneProperties(state: IState): IState {
    return {
        ...state,
        propertyList: listStatePaginatedLoading(state.propertyList),
    };
}

export function getSubzonePropertiesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        propertyList: listStatePaginatedSuccess(action.payload),
    };
}

export function getSubzonePropertiesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        propertyList: listStatePaginatedFailure(initialState.propertyList, action.payload.error),
    };
}

export function getSubzonePropertiesReset(state: IState): IState {
    return {
        ...state,
        propertyList: initialState.propertyList,
    };
}
//#endregion
