import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
    objectStateLoading,
    objectStateSuccess,
    objectStateFailure,
    processStateLoading,
    processStateSuccess,
    processStateFailure,
} from '../../common/helpers/reducer';

//#region GET BANKS
export function getBanks(state: IState): IState {
    return {
        ...state,
        bankList: listStatePaginatedLoading(state.bankList),
    };
}

export function getBanksSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        bankList: listStatePaginatedSuccess(action.payload),
    };
}

export function getBanksFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        bankList: listStatePaginatedFailure(initialState.bankList, action.payload.error),
    };
}

export function getBanksReset(state: IState): IState {
    return {
        ...state,
        bankList: initialState.bankList,
    };
}
//#endregion

//#region GET BANK
export function getBank(state: IState): IState {
    return {
        ...state,
        activeBank: objectStateLoading(),
    };
}

export function getBankSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activeBank: objectStateSuccess(action.payload),
    };
}

export function getBankFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activeBank: objectStateFailure(action.payload.error),
    };
}

export function getBankReset(state: IState): IState {
    return {
        ...state,
        activeBank: initialState.activeBank,
    };
}
//#endregion

//#region CREATE BANK
export function createBank(state: IState): IState {
    return {
        ...state,
        createBank: processStateLoading(),
    };
}

export function createBankSuccess(state: IState): IState {
    return {
        ...state,
        createBank: processStateSuccess(),
    };
}

export function createBankFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createBank: processStateFailure(action.payload.error),
    };
}

export function createBankReset(state: IState): IState {
    return {
        ...state,
        createBank: initialState.createBank,
    };
}
//#endregion

//#region UPDATE BANK
export function updateBank(state: IState): IState {
    return {
        ...state,
        updateBank: processStateLoading(),
    };
}

export function updateBankSuccess(state: IState): IState {
    return {
        ...state,
        updateBank: processStateSuccess(),
    };
}

export function updateBankFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateBank: processStateFailure(action.payload.error),
    };
}

export function updateBankReset(state: IState): IState {
    return {
        ...state,
        updateBank: initialState.updateBank,
    };
}
//#endregion

//#region DEACTIVATE BANK
export function deactivateBank(state: IState): IState {
    return {
        ...state,
        deactivateBank: processStateLoading(),
    };
}

export function deactivateBankSuccess(state: IState): IState {
    return {
        ...state,
        deactivateBank: processStateSuccess(),
    };
}

export function deactivateBankFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateBank: processStateFailure(action.payload.error),
    };
}

export function deactivateBankReset(state: IState): IState {
    return {
        ...state,
        deactivateBank: initialState.deactivateBank,
    };
}
//#endregion
