import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import {
    getProperty,
    resetProperty,
    getPropertyShareholders,
    resetPropertyShareholders,
} from '../../../../store/property/actions';
import { IStoreState } from './PropertyDetail.types';
import PropertyDetail from './PropertyDetail';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeProperty, shareholderList } = state.property;

    return {
        property: activeProperty.value,
        loading: activeProperty.loading,
        error: activeProperty.error,
        shareholders: shareholderList.data,
        shareholdersTotal: shareholderList.total,
        shareholdersPageSize: shareholderList.pageSize,
        shareholdersLoading: shareholderList.loading,
        shareholdersError: shareholderList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getProperty,
            resetProperty,
            getPropertyShareholders,
            resetPropertyShareholders,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PropertyDetail);
