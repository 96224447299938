import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './BankList.types';
import BankList from './BankList';
import { IRootState } from '../../../../common/types';
import {
    getBanks,
    resetBanks,
    deactivateBank,
    createBank,
    updateBank,
} from '../../../../store/bank/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { bankList, deactivateBank, createBank, updateBank } = state.bank;

    return {
        banks: bankList.data,
        total: bankList.total,
        pageSize: bankList.pageSize,
        loading: bankList.loading,
        error: bankList.error,
        createSuccess: createBank.success,
        createLoading: createBank.loading,
        createError: createBank.error,
        updateSuccess: updateBank.success,
        updateLoading: updateBank.loading,
        updateError: updateBank.error,
        deactivateLoading: deactivateBank.loading,
        deactivateSuccess: deactivateBank.success,
        deactivateError: deactivateBank.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getBanks,
            resetBanks,
            deactivateBank,
            createBank,
            updateBank,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BankList);
