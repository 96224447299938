import React from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Icon, Button, Row, Col, Card, Form, Alert, Layout } from 'antd';
import { IProps } from './Login.types';
import { Link } from 'react-router-dom';
import { InputField, InputPasswordField } from '../../../components';
import { messages } from '../../../common/constants';
import { paths } from '../../../routes';

interface IForm {
    username: string;
    password: string;
}

export default class View extends React.Component<IProps> {
    componentDidMount() {
        if (this.props.authenticated === true) this.props.history.push('/');
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkLogin(prevProps);
    }

    checkLogin(prevProps: IProps) {
        if (!prevProps.authenticated && this.props.authenticated) this.props.history.push('/');
    }

    handleSubmit = (values: IForm, { setSubmitting }: FormikActions<IForm>) => {
        this.props.login(values.username, values.password);
        setSubmitting(false);
    };

    validate = (values: IForm) => {
        const errors: Partial<IForm> = {};
        if (!values.username) errors.username = messages.MANDATORY_FIELD;
        if (!values.password) errors.password = messages.MANDATORY_FIELD;
        return errors;
    };

    public render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Card
                    style={{
                        width: 350,
                        marginTop: '30vh',
                        marginBottom: '10vh',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <Formik
                        initialValues={{ username: '', password: '' }}
                        validate={this.validate}
                        onSubmit={this.handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {({ handleSubmit }: FormikProps<IForm>) => (
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-4">
                                    <Col className="text-center">
                                        <h1>Unicachi</h1>
                                    </Col>
                                </Row>
                                {this.props.error && (
                                    <Row className="mb-3">
                                        <Col>
                                            <Alert
                                                message={this.props.error}
                                                type="error"
                                                showIcon
                                                closable
                                            />
                                        </Col>
                                    </Row>
                                )}
                                <Row className="mb-4">
                                    <Col>
                                        <InputField
                                            name="username"
                                            placeholder="Usuario"
                                            prefix={
                                                <Icon
                                                    type="user"
                                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <InputPasswordField
                                            name="password"
                                            placeholder="Contraseña"
                                            prefix={
                                                <Icon
                                                    type="lock"
                                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="float-right">
                                        <Link to={paths.FORGOT_PASSWORD}>Olvidé mi contraseña</Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="w-100"
                                            loading={this.props.loading}
                                        >
                                            Iniciar Sesión
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </Layout>
        );
    }
}
