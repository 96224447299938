import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './UserList.types';
import UserList from './UserList';
import { IRootState } from '../../../../common/types';
import { getUsers, resetUsers, deactivateUser } from '../../../../store/user/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { userList, deactivateUser } = state.user;
    const { loggedInUser } = state.auth;

    return {
        users: userList.data,
        total: userList.total,
        pageSize: userList.pageSize,
        loading: userList.loading,
        error: userList.error,
        deactivateLoading: deactivateUser.loading,
        deactivateSuccess: deactivateUser.success,
        deactivateError: deactivateUser.error,
        permissions: loggedInUser.value.permissions,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getUsers,
            resetUsers,
            deactivateUser,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
