import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../components';
import { IShareholderProperty, ICoOwner } from '../../../../../common/types/entities';

export const propertiesColumns = (): ColumnProps<IShareholderProperty>[] => [
    {
        title: 'Código',
        dataIndex: 'property.code',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Zona',
        dataIndex: 'property.zone.name',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Giro',
        dataIndex: 'property.subzone.name',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Acciones',
        dataIndex: 'shares',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Puesto',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IShareholderProperty) => (
            <>
                <TableCellItem label="Código" value={item.property.code} />
                <TableCellItem
                    label="Zona"
                    value={item.property.zone ? item.property.zone.name : ''}
                />
                <TableCellItem
                    label="Giro"
                    value={item.property.subzone ? item.property.subzone.name : ''}
                />
                <TableCellItem label="Acciones" value={item.shares} />
            </>
        ),
    },
];

export const coOwnersColumns = (): ColumnProps<ICoOwner>[] => [
    {
        title: 'Nombre',
        dataIndex: 'firstname',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Apellidos',
        dataIndex: 'lastname',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'DNI',
        dataIndex: 'dni',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Puesto',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: ICoOwner) => (
            <>
                <TableCellItem label="Nombre" value={item.firstname} />
                <TableCellItem label="Apellidos" value={item.lastname} />
                <TableCellItem label="DNI" value={item.dni} />
            </>
        ),
    },
];
