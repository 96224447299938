import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';

const { BANK_ACCOUNT_URL, BANK_ACCOUNT_TRANSACTION_URL } = apiUrls;

export function getAll(bankAccountTypeId: number, filter: string, page: number) {
    const params = { bankAccountTypeId, filter, page };

    return APIManager.get(BANK_ACCOUNT_URL, { params });
}

export function getAllByCurrency(currencyId: number) {
    const params = { currencyId };

    return APIManager.get(`${BANK_ACCOUNT_URL}/by-currency`, { params });
}

export function getTransactions(bankAccountId: number, filter: string, page: number) {
    const params = { bankAccountId, filter, page },
        url = `${BANK_ACCOUNT_TRANSACTION_URL}`;

    return APIManager.get(url, { params });
}

export function get(id: number) {
    const url = `${BANK_ACCOUNT_URL}/${id}`;
    return APIManager.get(url);
}

export function createTransaction(id: number, data: any) {
    const url = `${BANK_ACCOUNT_TRANSACTION_URL}/bank-accounts/${id}`;
    return APIManager.post(url, { data });
}

export function create(data: any) {
    return APIManager.post(BANK_ACCOUNT_URL, { data });
}

export function update(id: number, data: any) {
    const url = `${BANK_ACCOUNT_URL}/${id}`;
    return APIManager.put(url, { data });
}

export function deactivate(id: number) {
    const url = `${BANK_ACCOUNT_URL}/${id}/deactivate`;
    return APIManager.put(url);
}

export function getTypes() {
    const url = `${BANK_ACCOUNT_URL}/types`;
    return APIManager.get(url);
}
