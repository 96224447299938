import React from 'react';
import { IProps } from './InvoiceDetail.types';
import { SectionContainer, DisplayItem, DataTable } from '../../../../components';
import { Divider, Tabs, Modal, message, Row, Col, Upload, Button, Icon } from 'antd';
import { formatHelper, helper } from '../../../../common/helpers';
import { InvoiceDetailCols } from './misc/columns';
import { currencyTypes } from '../../../../common/constants';

class ExpenseDetail extends React.Component<IProps> {
    invoiceId = this.props.match.params['id'];

    componentDidMount() {
        this.props.getInvoice(this.invoiceId);
    }

    getProvider = () => {
        const { invoice } = this.props;

        if (invoice.provider) return formatHelper.getProviderDescription(invoice.provider);
        else if (invoice.requestOrder && invoice.requestOrder.provider)
            return formatHelper.getProviderDescription(invoice.requestOrder.provider);

        return '';
    };

    renderContent = () => {
        const { invoice } = this.props;

        return (
            <>
                <DisplayItem label="Código" value={invoice.code} />
                <DisplayItem label="Proveedor" value={this.getProvider()} />
                <DisplayItem
                    label="Fecha de Emisión"
                    value={formatHelper.toDate(invoice.emissionDate)}
                />
                <DisplayItem label="Pagado" value={invoice.paid ? 'Sí' : 'No'} />
                <DisplayItem
                    label="Fecha de Vencimiento"
                    value={formatHelper.toDate(invoice.dueDate)}
                />
                <DisplayItem
                    label="Precio Total"
                    value={formatHelper.toMoney(
                        Number(invoice.totalPrice),
                        invoice.currency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                    )}
                />
                <DisplayItem
                    label="Factura Restante"
                    value={formatHelper.toMoney(
                        Number(invoice.paymentRest),
                        invoice.currency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                    )}
                />
                {invoice.reasonToDelete ? (
                    <>
                        <DisplayItem label="Anulado" value={'Sí'} />
                        <DisplayItem label="Razón" value={invoice.reasonToDelete} />
                    </>
                ) : (
                    <DisplayItem label="Anulado" value={'No'} />
                )}
                <DataTable
                    data={invoice.invoiceItems}
                    columns={InvoiceDetailCols()}
                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                />
                <Divider orientation="left" className="mt-5">
                    Archivos
                </Divider>
                <Row>
                    <Col lg={12} md={12} xs={24} sm={24}>
                        <Upload
                            disabled={true}
                            defaultFileList={helper.getFileList(invoice.attachedFiles)}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    render() {
        const { invoice, loading, error } = this.props;

        return (
            <SectionContainer
                title="Detalle De Factura"
                onBack={() => this.props.history.goBack()}
                loading={loading || !invoice}
                error={error}
                content={this.renderContent}
            />
        );
    }
}

export default ExpenseDetail;
