const actionTypes = {
    GET_CUSTOMERS: 'customer/GET_CUSTOMERS',
    GET_CUSTOMERS_SUCCESS: 'customer/GET_CUSTOMERS_SUCCESS',
    GET_CUSTOMERS_FAILURE: 'customer/GET_CUSTOMERS_FAILURE',
    GET_CUSTOMERS_RESET: 'customer/GET_CUSTOMERS_RESET',

    GET_CUSTOMER: 'customer/GET_CUSTOMER',
    GET_CUSTOMER_SUCCESS: 'customer/GET_CUSTOMER_SUCCESS',
    GET_CUSTOMER_FAILURE: 'customer/GET_CUSTOMER_FAILURE',
    GET_CUSTOMER_RESET: 'customer/GET_CUSTOMER_RESET',

    CREATE_CUSTOMER: 'customer/CREATE_CUSTOMER',
    CREATE_CUSTOMER_SUCCESS: 'customer/CREATE_CUSTOMER_SUCCESS',
    CREATE_CUSTOMER_FAILURE: 'customer/CREATE_CUSTOMER_FAILURE',
    CREATE_CUSTOMER_RESET: 'customer/CREATE_CUSTOMER_RESET',

    UPDATE_CUSTOMER: 'customer/UPDATE_CUSTOMER',
    UPDATE_CUSTOMER_SUCCESS: 'customer/UPDATE_CUSTOMER_SUCCESS',
    UPDATE_CUSTOMER_FAILURE: 'customer/UPDATE_CUSTOMER_FAILURE',
    UPDATE_CUSTOMER_RESET: 'customer/UPDATE_CUSTOMER_RESET',

    DEACTIVATE_CUSTOMER: 'customer/DEACTIVATE_CUSTOMER',
    DEACTIVATE_CUSTOMER_SUCCESS: 'customer/DEACTIVATE_CUSTOMER_SUCCESS',
    DEACTIVATE_CUSTOMER_FAILURE: 'customer/DEACTIVATE_CUSTOMER_FAILURE',
    DEACTIVATE_CUSTOMER_RESET: 'customer/DEACTIVATE_CUSTOMER_RESET',

    GET_CUSTOMER_PROPERTIES: 'customer/GET_CUSTOMER_PROPERTIES',
    GET_CUSTOMER_PROPERTIES_SUCCESS: 'customer/GET_CUSTOMER_PROPERTIES_SUCCESS',
    GET_CUSTOMER_PROPERTIES_FAILURE: 'customer/GET_CUSTOMER_PROPERTIES_FAILURE',
    GET_CUSTOMER_PROPERTIES_RESET: 'customer/GET_CUSTOMER_PROPERTIES_RESET',
};

export default actionTypes;
