import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './PropertyList.types';
import PropertyList from './PropertyList';
import { IRootState } from '../../../../common/types';
import {
    getProperties,
    resetProperties,
    deactivateProperty,
} from '../../../../store/property/actions';
import { getZones, resetZones } from '../../../../store/zone/actions';
import { getSubzones, resetSubzones } from '../../../../store/subzone/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { propertyList, deactivateProperty } = state.property;
    const { loggedInUser } = state.auth;
    const { zoneList } = state.zone;
    const { subzoneList } = state.subzone;

    return {
        zones: zoneList.data,
        zonesLoading: zoneList.loading,
        subzones: subzoneList.data,
        subzonesLoading: subzoneList.loading,
        properties: propertyList.data,
        total: propertyList.total,
        pageSize: propertyList.pageSize,
        loading: propertyList.loading,
        error: propertyList.error,
        deactivateLoading: deactivateProperty.loading,
        deactivateSuccess: deactivateProperty.success,
        deactivateError: deactivateProperty.error,
        permissions: loggedInUser.value.permissions,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getZones,
            resetZones,
            getSubzones,
            resetSubzones,
            getProperties,
            resetProperties,
            deactivateProperty,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyList);
