import React from 'react';
import { IProps } from './ExpensePaymentAddEdit.types';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { IPaymentFormValues } from './components/paymentForm/PaymentForm';
import PaymentForm from './components/paymentForm';
import { IPaymentDetail, IPayment, IRequestOrder } from '../../../../common/types/entities';
import { message, Divider, Button, Row, Col, Select, Upload, Icon } from 'antd';
import { paths } from '../../../../routes';
import { paymentDetailColumns, expandedRowRender } from './misc/columnsPayment';
import {
    SectionContainer,
    DisplayItem,
    DataTable,
    GroupFieldWrapper,
} from '../../../../components';
import { formatHelper } from '../../../../common/helpers';
import { expenseTypes, currencyTypes } from '../../../../common/constants';
import nanoid from 'nanoid';

interface IState {
    paymentDetailsToEdit: IPaymentDetail[];
    requestOrderSelected: IRequestOrder;
    files: File[];
    exchangeRate: number;
    showExchangeRate: boolean;
}

export default class ExpensePaymentAddEdit extends React.Component<IProps> {
    private expenseId = this.props.match.params['id'];
    private formPayment: Formik<IPaymentFormValues> = null;

    state: IState = {
        paymentDetailsToEdit: [],
        requestOrderSelected: null,
        files: [],
        exchangeRate: null,
        showExchangeRate: null,
    };

    componentDidMount() {
        this.props.getExpense(this.expenseId);
        this.props.getRequestOdersByExpense(this.expenseId);
        this.props.getBankAccounts();
        this.props.getPaymentTypes();
    }

    componentWillUnmount() {
        this.props.resetExpense();
        this.props.resetRequestOrdersByExpense();
        this.props.resetInvoicesbyRequestOrderDetails();
        this.props.resetBankAccounts();
        this.props.resetPaymentTypes();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkExpensePayment(prevProps);
    }

    setExchangeRate = exchangeRate => {
        let newPaymentDetailsToEdit = this.state.paymentDetailsToEdit;
        newPaymentDetailsToEdit.forEach(
            (part, index, arr): any =>
                (arr[index].totalPrice = arr[index].totalPriceWithoutExchangeRate * exchangeRate),
        );
        this.setState({ exchangeRate, newPaymentDetailsToEdit });
    };

    setShowExchangeRate = (value: boolean) => this.setState({ showExchangeRate: value });

    resetPaymentDetailsToEdit = () => this.setState({ paymentDetailsToEdit: [] });

    addOrUpdatePaymentDetailExchangeRate = (totalPriceWithoutExchangeRate: number, item: any) => {
        const exchangeRate = this.state.exchangeRate || 0;
        const totalPrice =
            this.state.requestOrderSelected.currency.id == currencyTypes.PEN
                ? totalPriceWithoutExchangeRate * exchangeRate
                : totalPriceWithoutExchangeRate / exchangeRate;

        let paymentDetail: IPaymentDetail = {
            totalPriceWithoutExchangeRate: totalPriceWithoutExchangeRate,
            totalPrice,
            invoice: {
                id: item.id,
                code: item.code,
                paymentRest: item.paymentRest,
            },
            currency: this.state.requestOrderSelected.currency,
        };

        let paymentDetailsToEdit = this.state.paymentDetailsToEdit;

        const index = paymentDetailsToEdit.findIndex(e => e.invoice.id == paymentDetail.invoice.id);

        if (index == -1) paymentDetailsToEdit.push(paymentDetail);
        else paymentDetailsToEdit[index] = paymentDetail;

        this.setState({ paymentDetailsToEdit });
    };

    addOrUpdatePaymentDetail = (paymentPrice: number, item: any) => {
        const exchangeRate = this.state.exchangeRate || 0;
        const totalPriceWithoutExchangeRate =
            this.state.requestOrderSelected.currency.id == currencyTypes.PEN
                ? paymentPrice / exchangeRate
                : paymentPrice * exchangeRate;

        let paymentDetail: IPaymentDetail = {
            totalPrice: paymentPrice,
            totalPriceWithoutExchangeRate,
            invoice: {
                id: item.id,
                code: item.code,
                paymentRest: item.paymentRest,
            },
            currency: this.state.requestOrderSelected.currency,
        };
        let paymentDetailsToEdit = this.state.paymentDetailsToEdit;

        const index = paymentDetailsToEdit.findIndex(e => e.invoice.id == paymentDetail.invoice.id);

        if (index == -1) paymentDetailsToEdit.push(paymentDetail);
        else paymentDetailsToEdit[index] = paymentDetail;

        this.setState({ paymentDetailsToEdit });
    };

    checkExpensePayment(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.completePaymentSuccess && props.completePaymentSuccess) {
            message.success('Pago registrado');
            this.props.history.push(paths.ADMIN_EXPENSE_LIST);
        } else if (!prevProps.completePaymentError && props.completePaymentError)
            message.error(props.completePaymentError);
    }

    onChangeRequestOrder = value => {
        const requestOrderSelected = this.props.requestOrdersByExpense.find(i => i.id == value);
        this.setState({ requestOrderSelected });
        this.props.getInvoicesByRequestOrderDetails(value);
    };

    onSubmitPayment = (values: IPaymentFormValues) => {
        let paymentToSubmit: IPayment = {
            paymentDate: new Date(values.paymentDate),
            bankAccount: { id: Number(values.bankAccount) },
            requestOrder: this.state.requestOrderSelected,
            currency: this.state.requestOrderSelected.currency,
            paymentType: { id: Number(values.paymentType) },
            exchangeRate: this.state.exchangeRate,
            paymentDetails: this.state.paymentDetailsToEdit.filter(e => e.totalPrice != 0),
        };

        if (paymentToSubmit.paymentDetails.length == 0) {
            message.error('Complete al menos un item de pago');
            return;
        }

        for (const paymentDetail of paymentToSubmit.paymentDetails) {
            if (paymentDetail.totalPrice > paymentDetail.invoice.paymentRest) {
                message.error(
                    'El Pago excede el monto restante de la factura con código: ' +
                        paymentDetail.invoice.code,
                );
                return;
            }
        }

        const files = this.state.files;
        if (files.length > 0) paymentToSubmit.files = files;

        this.props.completePayment(paymentToSubmit);
    };

    renderContent = () => {
        const {
            activeExpense,
            activeExpenseLoading,
            requestOrdersByExpense,
            requestOrdersByExpenseLoading,
            invoicesByRequestOrder,
            invoicesByRequestOrderLoading,
            bankAccounts,
            bankAccountsLoading,
            paymentTypes,
            paymentTypesLoading,
            completePaymentLoading,
        } = this.props;

        return (
            <>
                <DisplayItem
                    label="Código"
                    value={activeExpense.code}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Estado"
                    value={activeExpense.state.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Información</Divider>
                <DisplayItem
                    label="Solicitante"
                    value={formatHelper.getUserFullname(activeExpense.requestUser)}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <GroupFieldWrapper label="Órdenes de Compra">
                    <Select
                        style={{ width: '100%' }}
                        onChange={this.onChangeRequestOrder}
                        value={
                            this.state.requestOrderSelected
                                ? this.state.requestOrderSelected.id
                                : null
                        }
                        loading={requestOrdersByExpenseLoading}
                    >
                        {requestOrdersByExpense.map(i => (
                            <Select.Option key={nanoid()} value={i.id}>
                                Orden N - {i.order}
                            </Select.Option>
                        ))}
                    </Select>
                </GroupFieldWrapper>
                {this.state.requestOrderSelected && (
                    <>
                        <DisplayItem
                            label="Proveedor"
                            value={this.state.requestOrderSelected.provider.businessName}
                            lmd={4}
                            llg={3}
                            lxl={2}
                            lxxl={2}
                        />
                        <DisplayItem
                            label="Monto Total de Orden de Compra"
                            vStyles={{ fontWeight: 'bold' }}
                            value={formatHelper.toMoney(
                                this.state.requestOrderSelected.totalPrice,
                                this.state.requestOrderSelected.currency.id == currencyTypes.PEN
                                    ? 'PEN'
                                    : 'USD',
                            )}
                            lmd={4}
                            llg={3}
                            lxl={2}
                            lxxl={2}
                        />
                        <DisplayItem
                            label="Monto Restante"
                            vStyles={{ fontWeight: 'bold' }}
                            value={formatHelper.toMoney(
                                this.state.requestOrderSelected.remainingPayment,
                                this.state.requestOrderSelected.currency.id == currencyTypes.PEN
                                    ? 'PEN'
                                    : 'USD',
                            )}
                            lmd={4}
                            llg={3}
                            lxl={2}
                            lxxl={2}
                        />
                    </>
                )}
                <Divider orientation="left">Datos de Pago</Divider>
                {this.state.requestOrderSelected && this.state.requestOrderSelected.accordance && (
                    <PaymentForm
                        onSubmit={this.onSubmitPayment}
                        disabled={activeExpenseLoading}
                        hideFooter
                        formRef={ref => (this.formPayment = ref)}
                        bankAccounts={bankAccounts}
                        bankAccountsLoading={bankAccountsLoading}
                        paymentTypes={paymentTypes}
                        paymentTypesLoading={paymentTypesLoading}
                        requestOrderSelected={this.state.requestOrderSelected}
                        setExchangeRate={this.setExchangeRate}
                        exchangeRate={this.state.exchangeRate}
                        setShowExchangeRate={this.setShowExchangeRate}
                        showExchangeRate={this.state.showExchangeRate}
                        resetPaymentDetailsToEdit={this.resetPaymentDetailsToEdit}
                    />
                )}
                {this.state.requestOrderSelected ? (
                    this.state.requestOrderSelected.accordance ? (
                        <>
                            <Divider orientation="left" className="mt-5">
                                Facturas
                            </Divider>
                            {this.state.showExchangeRate && !this.state.exchangeRate && (
                                <span>
                                    <b>Complete el tipo de cambio.</b>
                                </span>
                            )}
                            <DataTable
                                data={invoicesByRequestOrder}
                                columns={paymentDetailColumns(
                                    this.addOrUpdatePaymentDetailExchangeRate,
                                    this.addOrUpdatePaymentDetail,
                                    this.state.paymentDetailsToEdit,
                                    this.state.requestOrderSelected.currency,
                                    this.state.showExchangeRate,
                                    this.state.exchangeRate,
                                )}
                                rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                                loading={invoicesByRequestOrderLoading}
                                expandedRowRender={record => expandedRowRender(record)}
                            />
                            <Divider orientation="left" className="mt-5">
                                Archivos
                            </Divider>
                            <Row>
                                <Col lg={12} md={12} xs={24} sm={24}>
                                    <Upload
                                        accept=".pdf, .csv, .xlsx, .xls, .doc, .docx, .png, .jpg"
                                        onChange={info => {
                                            this.setState({
                                                files: info.fileList.map(
                                                    item => item['originFileObj'],
                                                ),
                                            });
                                        }}
                                        // stange behavior.... https://stackoverflow.com/a/56125617
                                        beforeUpload={() => false}
                                    >
                                        <Button>
                                            <Icon type="upload" /> Subir Archivos
                                        </Button>
                                    </Upload>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <Button
                                        type="primary"
                                        icon="dollar"
                                        onClick={() => this.formPayment.submitForm()}
                                        loading={completePaymentLoading}
                                    >
                                        Registrar Pago
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <p>
                            Porfavor completa la conformidad de la orden de compra en el siguiente
                            enlace:{' '}
                            <Link to={`conformidad`}>Completar conformidad de orden de compra</Link>
                        </p>
                    )
                ) : (
                    <></>
                )}
            </>
        );
    };

    render() {
        const { activeExpense, activeExpenseLoading, activeExpenseError } = this.props;

        return (
            <SectionContainer
                title={'Ingreso de Pago'}
                onBack={() => this.props.history.push(paths.ADMIN_EXPENSE_LIST)}
                loading={activeExpenseLoading || !activeExpense}
                error={activeExpenseError}
                content={this.renderContent}
            />
        );
    }
}
