import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { currencyService } from '../../service';

export function getCurrencies(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => currencyService.getAll(filter, page),
        types: [
            actionTypes.GET_CURRENCIES,
            actionTypes.GET_CURRENCIES_SUCCESS,
            actionTypes.GET_CURRENCIES_FAILURE,
        ],
    };
}

export function resetCurrencies() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_CURRENCIES_RESET,
        });
    };
}
