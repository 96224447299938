import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './BankAccountList.types';
import BankAccountList from './BankAccountList';
import { IRootState } from '../../../../common/types';
import {
    getBankAccounts,
    resetBankAccounts,
    deactivateBankAccount,
} from '../../../../store/bankAccount/actions';
import { getBankAccountTypes, resetBankAccountTypes } from '../../../../store/bankAccount/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { bankAccountList, deactivateBankAccount, bankAccountTypeList } = state.bankAccount;

    return {
        bankAccountTypes: bankAccountTypeList.data,
        bankAccountTypesLoading: bankAccountTypeList.loading,
        bankAccounts: bankAccountList.data,
        total: bankAccountList.total,
        pageSize: bankAccountList.pageSize,
        loading: bankAccountList.loading,
        error: bankAccountList.error,
        deactivateLoading: deactivateBankAccount.loading,
        deactivateSuccess: deactivateBankAccount.success,
        deactivateError: deactivateBankAccount.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getBankAccounts,
            resetBankAccounts,
            getBankAccountTypes,
            resetBankAccountTypes,
            deactivateBankAccount,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountList);
