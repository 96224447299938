import React, { useState, useEffect } from 'react';
import { Modal, Upload, Icon, Alert } from 'antd';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';

interface IOwnProps {
    title: string;
    isOpen: boolean;
    onSubmit: (files: File[]) => void;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    multipleSelection?: boolean;
    multipleFiles?: boolean;
    loading?: boolean;
}

type IProps = IOwnProps;

const FileUploadModal: React.FC<IProps> = props => {
    const [error, setError] = useState<string>(null);
    const [selectedFileList, setSelectedFileList] = useState<UploadFile[]>([]);

    useEffect(() => {
        if (!props.isOpen) return;

        return () => {
            setError(null);
            setSelectedFileList([]);
        };
    }, [props.isOpen]);

    const dummyRequest = option => {
        setTimeout(() => {
            option.onSuccess('ok');
        }, 0);
    };

    const handleChange = (info: UploadChangeParam<UploadFile>) => {
        switch (info.file.status) {
            case 'uploading':
                onFileUploading(info);
                break;
            case 'done':
                onFileDone(info);
                break;
            case 'removed':
                onFileRemove(info);
                break;
        }
    };

    const onFileUploading = (info: UploadChangeParam<UploadFile>) => {
        let files = [...selectedFileList];
        if (props.multipleFiles) files.push(info.file);
        else files = [info.file];
        setSelectedFileList(files);
    };

    const onFileDone = (info: UploadChangeParam<UploadFile>) => {
        let files = [...selectedFileList];
        if (!props.multipleFiles) files = [info.file];
        setSelectedFileList(files);
    };

    const onFileRemove = (info: UploadChangeParam<UploadFile>) => {
        if (props.multipleFiles) {
            const filtered = selectedFileList.filter(x => x.uid != info.file.uid);
            setSelectedFileList(filtered);
        } else {
            setSelectedFileList([]);
        }
    };

    const handleOnCancel = e => {
        props.onCancel(e);
    };

    const onOk = () => {
        if (selectedFileList.length == 0) {
            setError('No has seleccionado ningún archivo');
            return;
        }

        setError(null);

        const finalFiles = [];

        for (const selectedFile of selectedFileList) {
            finalFiles.push(selectedFile.originFileObj);
        }

        props.onSubmit(finalFiles as any);
    };

    return (
        <Modal
            title={props.title}
            visible={props.isOpen}
            okText="Subir"
            onOk={onOk}
            okButtonProps={{ loading: props.loading }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.loading }}
        >
            {error && <Alert message={error} type="error" showIcon closable className="mb-3" />}
            <Upload.Dragger
                accept=".xlsx, .xls" // TODO: segementar en una propiedad
                fileList={selectedFileList}
                onChange={handleChange}
                customRequest={dummyRequest}
                multiple={props.multipleSelection}
                disabled={props.loading}
            >
                <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                    {props.multipleFiles
                        ? 'Click o arrastra los archivos a esta zona para subirlos'
                        : 'Click o arrastra un archivo a esta zona para subirlo'}
                </p>
            </Upload.Dragger>
        </Modal>
    );
};

FileUploadModal.defaultProps = {
    multipleSelection: false,
    multipleFiles: false,
    loading: false,
};

export default FileUploadModal;
