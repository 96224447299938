import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { IBankAccountTransaction, IUser } from '../../../../../common/types/entities';
import { formatHelper } from '../../../../../common/helpers';
import { bankAccountTransactionTypes } from '../../../../../common/constants';
import { Button } from 'antd';
import { TableCellItem } from '../../../../../components';

export const columns = (
    onClickDetail: (item: IBankAccountTransaction) => void,
): ColumnProps<IBankAccountTransaction>[] => {
    return [
        {
            title: 'Tipo',
            dataIndex: 'type.description',
            className: 'd-none d-md-table-cell',
        },
        {
            title: 'Monto',
            dataIndex: '',
            className: 'd-none d-md-table-cell',
            render: (item: IBankAccountTransaction) => {
                let className = '';

                if (
                    item.type.id == bankAccountTransactionTypes.INCOME ||
                    item.type.id == bankAccountTransactionTypes.VOID_PAYMENT ||
                    item.type.id == bankAccountTransactionTypes.DEPOSIT
                ) {
                    className = 'c-green';
                } else if (
                    item.type.id == bankAccountTransactionTypes.PAYMENT ||
                    item.type.id == bankAccountTransactionTypes.WITHDRAW ||
                    item.type.id == bankAccountTransactionTypes.PETTY_CASH_OPENING
                ) {
                    className = 'c-red';
                }

                return <span className={className}>{formatHelper.toMoney(item.amount)}</span>;
            },
        },
        {
            title: 'Fecha de Movimiento',
            dataIndex: 'createdAt',
            className: 'd-none d-md-table-cell',
            render: (createdAt: Date) => <span>{formatHelper.toDate(createdAt)}</span>,
        },
        {
            title: 'Usuario Registro',
            dataIndex: 'userRegistration',
            className: 'd-none d-md-table-cell',
            render: (userRegistration: IUser) => (
                <span>{formatHelper.getUserFullname(userRegistration)}</span>
            ),
        },
        {
            key: 'xs',
            title: 'Usuario',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (item: IBankAccountTransaction) => {
                let amountClassName = '';

                if (
                    item.type.id == bankAccountTransactionTypes.INCOME ||
                    item.type.id == bankAccountTransactionTypes.VOID_PAYMENT ||
                    item.type.id == bankAccountTransactionTypes.DEPOSIT
                ) {
                    amountClassName = 'c-green';
                } else if (
                    item.type.id == bankAccountTransactionTypes.PAYMENT ||
                    item.type.id == bankAccountTransactionTypes.WITHDRAW ||
                    item.type.id == bankAccountTransactionTypes.PETTY_CASH_OPENING
                ) {
                    amountClassName = 'c-red';
                }

                return (
                    <>
                        <TableCellItem label="Tipo" value={item.type.description} />
                        <TableCellItem
                            label="Monto"
                            value={formatHelper.toMoney(item.amount)}
                            valueClassname={amountClassName}
                        />
                        <TableCellItem
                            label="Fecha de Movimiento"
                            value={formatHelper.toDate(item.createdAt)}
                        />
                        <TableCellItem
                            label="Usuario Registro"
                            value={formatHelper.getUserFullname(item.userRegistration)}
                        />
                    </>
                );
            },
        },
        {
            key: 'options',
            dataIndex: '',
            render: (item: IBankAccountTransaction) => {
                return item.type.id != bankAccountTransactionTypes.READJUSTMENT &&
                    item.type.id != bankAccountTransactionTypes.DEPOSIT &&
                    item.type.id != bankAccountTransactionTypes.WITHDRAW &&
                    item.type.id != bankAccountTransactionTypes.PETTY_CASH_OPENING ? (
                    <Button
                        type="primary"
                        shape="round"
                        icon="eye"
                        onClick={() => onClickDetail(item)}
                    />
                ) : null;
            },
        },
    ];
};
