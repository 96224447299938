import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import {
    TableCellItem,
    TableRowOptionsDropdown,
    TableRowOptionItem,
} from '../../../../../components';
import { IExpense, IUser } from '../../../../../common/types/entities';
import { formatHelper } from '../../../../../common/helpers';
import { expenseStates, permissions } from '../../../../../common/constants';

export const columns = (
    onClickDetail: (id: number) => void,
    onClickEdit: (id: number) => void,
    onClickDelete: (id: number) => void,
    onClickAnswer: (id: number) => void,
    onClickLogistic: (id: number) => void,
    onClickRequestOrder: (id: number) => void,
    onClickAccordance: (id: number) => void,
    onClickInvoice: (id: number) => void,
    onClickPayment: (id: number) => void,
    permissionCodes: string[],
): ColumnProps<IExpense>[] => [
    {
        title: 'Código',
        dataIndex: 'code',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Responsable',
        dataIndex: 'requestUser',
        className: 'd-none d-md-table-cell',
        render: (requestUser: IUser) => requestUser.firstname + ' ' + requestUser.lastname,
    },
    {
        title: 'Tipo',
        dataIndex: 'type.description',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Precio Total (PEN)',
        dataIndex: 'totalPrice',
        className: 'd-none d-md-table-cell',
        render: (totalPrice: number) => <span>{formatHelper.toMoney(totalPrice, 'PEN')}</span>,
    },
    {
        title: 'Precio Total (USD)',
        dataIndex: 'totalPriceUSD',
        className: 'd-none d-md-table-cell',
        render: (totalPriceUSD: number) => (
            <span>{formatHelper.toMoney(totalPriceUSD, 'USD')}</span>
        ),
    },
    {
        title: 'Estado',
        dataIndex: 'state.description',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Egresos',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IExpense) => (
            <>
                <TableCellItem label="Código" value={item.code} />
                <TableCellItem
                    label="Responsable"
                    value={item.requestUser.firstname + ' ' + item.requestUser.lastname}
                />
                <TableCellItem label="Tipo" value={item.type.description} />
                <TableCellItem label="Precio Total (PEN)" value={item.totalPrice} type="money" />
                <TableCellItem label="Precio Total (USD)" value={item.totalPriceUSD} type="money" />
                <TableCellItem label="Estado" value={item.state.description} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IExpense) => {
            const { id: stateId } = item.state;
            const pending = stateId === expenseStates.PENDING;

            return (
                <TableRowOptionsDropdown
                    onClickDetail={() => onClickDetail(item.id)}
                    detailPermission={permissions.expense.READ_EXPENSE_DETAIL}
                    onClickEdit={pending ? () => onClickEdit(item.id) : null}
                    editPermission={permissions.expense.WRITE_REQUERIMENTS}
                    onClickDelete={pending ? () => onClickDelete(item.id) : null}
                    deletePermission={permissions.expense.WRITE_REQUERIMENTS}
                >
                    {(pending || stateId === expenseStates.REJECTED) && (
                        <TableRowOptionItem
                            label="Logística"
                            icon="deployment-unit"
                            onClick={() => onClickLogistic(item.id)}
                            permission={permissions.expense.WRITE_LOGISTIC_PROCESS}
                        />
                    )}
                    {stateId === expenseStates.LOGISTIC && (
                        <TableRowOptionItem
                            label="Aprobar/Rechazar"
                            icon="arrow-right"
                            onClick={() => onClickAnswer(item.id)}
                            permission={permissions.expense.WRITE_RESPONSE_PROCESS}
                        />
                    )}
                    {(stateId === expenseStates.ACCEPTED ||
                        stateId === expenseStates.REQUESTED) && (
                        <TableRowOptionItem
                            label="Solicitar orden de compra"
                            icon="container"
                            onClick={() => onClickRequestOrder(item.id)}
                            permission={permissions.expense.WRITE_ORDERS}
                        />
                    )}
                    {stateId === expenseStates.REQUESTED && (
                        <TableRowOptionItem
                            label="Ingreso de Factura"
                            icon="audit"
                            onClick={() => onClickInvoice(item.id)}
                            permission={permissions.expense.WRITE_INVOICES}
                        />
                    )}
                    {stateId === expenseStates.REQUESTED && (
                        <TableRowOptionItem
                            label="Conformidad en Orden de Compra"
                            icon="check-circle"
                            onClick={() => onClickAccordance(item.id)}
                            permission={permissions.expense.WRITE_ACCORDANCE_PROCESS}
                        />
                    )}
                    {stateId === expenseStates.REQUESTED && (
                        <TableRowOptionItem
                            label="Registro de Pagos"
                            icon="dollar"
                            onClick={() => onClickPayment(item.id)}
                            permission={permissions.expense.WRITE_PAYMENTS}
                        />
                    )}
                </TableRowOptionsDropdown>
            );
        },
    },
];
