import React, { FC } from 'react';
import { Table } from 'antd';
import { PaginationConfig, TableProps } from 'antd/lib/table';

interface IOwnProps<T> {
    data: T[];
    pageSize?: number;
    total?: number;
    currentPage?: number;
    loading?: boolean;
    onPageChange?: (page: number, pageSize?: number) => void;
}

type IProps<T> = IOwnProps<T> & TableProps<T>;

const DataTable: FC<IProps<any>> = props => {
    const {
        pagination,
        dataSource,
        pageSize,
        total,
        currentPage,
        onPageChange,
        loading,
        ...restProps
    } = props;

    const handlePageChange = (page: number, pageSize?: number) => {
        if (!onPageChange) return;
        onPageChange(page, pageSize);
    };

    const showTotal = () => {
        if (total == null) return null;
        return <span>{`${total} items`}</span>;
    };

    const getPaginationProps = (): false | PaginationConfig => {
        if (!pageSize) return false;

        return {
            pageSize: pageSize,
            total: total,
            current: currentPage,
            onChange: handlePageChange,
            showTotal,
        };
    };

    return (
        <Table
            dataSource={props.data}
            pagination={getPaginationProps()}
            loading={{ spinning: loading, delay: 500 }}
            {...restProps}
        />
    );
};

DataTable.defaultProps = {
    size: 'small',
    loading: false,
    rowKey: 'id',
};

export default DataTable;
