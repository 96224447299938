import { connect } from 'react-redux';
import PBR from './PBR';
import { IRootState } from '../../common/types';
import { IStoreState } from './PBR.types';

function mapStateToProps(state: IRootState): IStoreState {
    return {
        permissions: state.auth.loggedInUser.value.permissions,
    };
}

/**Permission Based Renderer */
const PBRContainer = connect(mapStateToProps)(PBR);
export default PBRContainer;
