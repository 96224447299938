import React from 'react';
import Table, { ColumnProps } from 'antd/lib/table';
import {
    IPaymentDetail,
    IInvoice,
    IInvoiceItem,
    ICurrency,
} from '../../../../../common/types/entities';
import nanoid from 'nanoid';
import { Input } from 'antd';
import { formatHelper, helper } from '../../../../../common/helpers';
import { currencyTypes } from '../../../../../common/constants';

export const expandedRowRender = (record: IInvoice) => {
    const columns: ColumnProps<IInvoiceItem>[] = [
        {
            key: nanoid(),
            title: 'Nombre',
            dataIndex: 'name',
            className: 'd-none d-md-table-cell',
        },
        {
            key: nanoid(),
            title: 'Precio Unitario',
            // dataIndex: 'unitPrice',
            className: 'd-none d-md-table-cell',
            render: (invoiceItem: IInvoiceItem) => (
                <span>
                    {invoiceItem.unitPrice
                        ? invoiceItem.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(invoiceItem.unitPrice, 'PEN')
                            : formatHelper.toMoney(invoiceItem.unitPrice, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            key: nanoid(),
            title: 'Cantidad',
            dataIndex: 'quantity',
            className: 'd-none d-md-table-cell',
        },
        {
            key: nanoid(),
            title: 'Precio Total',
            // dataIndex: 'totalPrice',
            className: 'd-none d-md-table-cell',
            render: (invoiceItem: IInvoiceItem) => (
                <span>
                    {invoiceItem.totalPrice
                        ? invoiceItem.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(invoiceItem.totalPrice, 'PEN')
                            : formatHelper.toMoney(invoiceItem.totalPrice, 'USD')
                        : ''}
                </span>
            ),
        },
    ];

    return <Table columns={columns} dataSource={record.invoiceItems} pagination={false} />;
};

export const paymentDetailColumns = (
    addOrUpdatePaymentDetailExchangeRate: (
        totalPriceWithoutExchangeRate: number,
        item: any,
    ) => void,
    addOrUpdatePaymentDetail: (paymentPrice: number, item: any) => void,
    paymentDetails: IPaymentDetail[],
    currentCurrency: ICurrency,
    showExchangeRate: boolean,
    exchangeRate: number,
): ColumnProps<any>[] => {
    const totalPriceWithoutExchangeRate: any =
        paymentDetails.length > 0
            ? paymentDetails
                .map(item => item.totalPriceWithoutExchangeRate)
                .reduce((prev, next) => Number(prev) + Number(next), 0)
            : 0;
    const totalPrice: any =
        paymentDetails.length > 0
            ? paymentDetails
                .map(item => item.totalPrice)
                .reduce((prev, next) => Number(prev) + Number(next), 0)
            : 0;

    let cols: any = [
        {
            key: nanoid(),
            title: 'Código de Factura',
            dataIndex: 'code',
            // className: 'd-none d-md-table-cell',
        },
        {
            key: nanoid(),
            title: 'Fecha de Emisión',
            dataIndex: 'emissionDate',
            // className: 'd-none d-md-table-cell',
            render: (emissionDate: any) => <span>{formatHelper.toDate(emissionDate)}</span>,
        },
        {
            key: nanoid(),
            title: 'Fecha de Vencimiento',
            dataIndex: 'dueDate',
            // className: 'd-none d-md-table-cell',
            render: (dueDate: any) => <span>{formatHelper.toDate(dueDate)}</span>,
        },
        {
            title: 'Precio Total',
            key: nanoid(),
            // className: 'd-none d-md-table-cell',
            render: (item: IInvoice) => (
                <span>
                    {item.totalPrice
                        ? item.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(item.totalPrice, 'PEN')
                            : formatHelper.toMoney(item.totalPrice, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            title: 'Monto Restante',
            key: nanoid(),
            // className: 'd-none d-md-table-cell',
            render: (item: IInvoice) => (
                <span>
                    {item.paymentRest
                        ? item.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(item.paymentRest, 'PEN')
                            : formatHelper.toMoney(item.paymentRest, 'USD')
                        : ''}
                </span>
            ),
        },
    ];

    if (showExchangeRate != null) {
        if (showExchangeRate)
            cols = [
                ...cols,
                {
                    title:
                        'Monto en ' +
                        (currentCurrency.id != currencyTypes.PEN ? 'Soles: ' : 'Dolares: ') +
                        formatHelper.toMoney(
                            totalPriceWithoutExchangeRate,
                            currentCurrency.id != currencyTypes.PEN ? 'PEN' : 'USD',
                        ),
                    dataIndex: '',
                    className: 'bg-th-header-cs',
                    render: (item: any) => {
                        let obj =
                            paymentDetails.length > 0 &&
                            paymentDetails.find(obj => obj.invoice.id === item.id);
                        return (
                            <Input
                                value={obj ? obj.totalPriceWithoutExchangeRate : null}
                                disabled={!exchangeRate || item.paid || !item.active}
                                onChange={e => {
                                    // @ts-ignore
                                    addOrUpdatePaymentDetailExchangeRate(e.target.value, item);
                                }}
                            />
                        );
                    },
                },
                {
                    title:
                        'Monto en ' +
                        (currentCurrency.id == currencyTypes.PEN ? 'Soles: ' : 'Dolares: ') +
                        formatHelper.toMoney(
                            totalPrice,
                            currentCurrency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                        ),
                    dataIndex: '',
                    className: 'bg-th-header-cs',
                    render: (item: any) => {
                        let obj =
                            paymentDetails.length > 0 &&
                            paymentDetails.find(obj => obj.invoice.id === item.id);

                        return (
                            <Input
                                //@ts-ignore
                                value={obj ? obj.totalPrice : null}
                                disabled={!exchangeRate || item.paid || !item.active}
                                onChange={e => {
                                    // @ts-ignore
                                    addOrUpdatePaymentDetail(e.target.value, item);
                                }}
                            />
                        );
                    },
                },
            ];
        else
            cols = [
                ...cols,
                {
                    title:
                        'Pago Total: ' +
                        formatHelper.toMoney(
                            totalPrice,
                            currentCurrency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                        ),
                    dataIndex: '',
                    className: 'bg-th-header-cs',
                    render: (item: any) => {
                        let obj =
                            paymentDetails.length > 0 &&
                            paymentDetails.find(obj => obj.invoice.id === item.id);
                        return (
                            <Input
                                // @ts-ignore
                                value={obj ? obj.totalPrice : null}
                                disabled={item.paid || !item.active}
                                onChange={e => {
                                    let val = e.target.value;
                                    if (val.length > 0) {
                                        if (val > item.paymentRest) return;
                                        else if (!helper.onlyNumbersWithDecimals(val)) return;
                                    }
                                    // @ts-ignore
                                    addOrUpdatePaymentDetail(val, item);
                                }}
                            />
                        );
                    },
                },
            ];
    }

    return cols;
};
