import * as React from 'react';
import { FormikActions, FormikProps } from 'formik';
import {
    CustomForm,
    InputGroupField,
    SelectGroupField,
    InputPasswordGroupField,
} from '../../../../../../components';
import { IRole, IUser } from '../../../../../../common/types/entities';
import { messages } from '../../../../../../common/constants';
import { isEmpty, isEmail } from 'validator';

interface IUserForm {
    username: string;
    firstname: string;
    lastname: string;
    dni: string;
    phone: string;
    address: string;
    password: string;
    confirmPassword: string;
    email: string;
    role: IRole;
}

interface IProps {
    initialValues: IUser;
    roles: IRole[];
}

export default class UserForm extends CustomForm<IUserForm, IProps> {
    initialValues = (props: IProps) => {
        const { initialValues } = props;
        return {
            username: initialValues.username || '',
            firstname: initialValues.firstname || '',
            lastname: initialValues.lastname || '',
            dni: initialValues.dni || '',
            phone: initialValues.phone || '',
            address: initialValues.address || '',
            email: initialValues.email || '',
            password: initialValues.password || '',
            confirmPassword: '',
            role: initialValues.role || { id: '' },
        } as IUserForm;
    };

    validate = (values: IUserForm) => {
        const errors = {} as Partial<IUserForm>;

        if (isEmpty(values.username)) errors.username = messages.MANDATORY_FIELD;
        if (isEmpty(values.firstname)) errors.firstname = messages.MANDATORY_FIELD;
        if (isEmpty(values.lastname)) errors.lastname = messages.MANDATORY_FIELD;
        if (!isEmpty(values.dni) && values.dni.length !== 8) errors.dni = 'DNI inválido';

        if (isEmpty(values.email)) errors.email = messages.MANDATORY_FIELD;
        else if (!isEmail(values.email)) errors.email = 'El valor ingresado no es un correo';

        if (values.role.id == 0 || !values.role.id) errors.role = { id: messages.MANDATORY_FIELD };

        if (!this.props.editing) {
            if (isEmpty(values.password)) errors.password = messages.MANDATORY_FIELD;
            if (isEmpty(values.confirmPassword)) errors.confirmPassword = messages.MANDATORY_FIELD;

            if (values.password && values.confirmPassword)
                if (values.password !== values.confirmPassword)
                    errors.confirmPassword = 'Las contraseñas no coinciden';
        }

        return errors;
    };

    onSubmit = (values: IUserForm, actions: FormikActions<IUserForm>) => {
        this.props.onSubmit(values);
        actions.setSubmitting(false);
    };

    formBody = (formikBag: FormikProps<IUserForm>) => {
        const { roles, editing } = this.props;

        return (
            <div>
                <InputGroupField name="username" label="Usuario" lxl={3} required />
                <InputGroupField name="firstname" label="Nombre" lxl={3} required />
                <InputGroupField name="lastname" label="Apellidos" lxl={3} required />
                <InputGroupField name="dni" label="DNI" lxl={3} maxLength={8} />
                <InputGroupField name="phone" label="Teléfono" lxl={3} />
                <InputGroupField name="address" label="Dirección" lxl={3} />
                <InputGroupField name="email" label="Email" lxl={3} required />
                <InputPasswordGroupField
                    name="password"
                    label="Contraseña"
                    lxl={3}
                    required={!editing}
                />
                <InputPasswordGroupField
                    name="confirmPassword"
                    label="Confirmar contraseña"
                    lxl={3}
                    required={!editing}
                />
                <SelectGroupField
                    name="role.id"
                    label="Rol"
                    data={roles}
                    lxl={3}
                    displayText="name"
                    required
                />
            </div>
        );
    };
}
