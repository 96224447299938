import React from 'react';
import { Button, Row, Col, Input, message } from 'antd';
import { columns } from './misc/columns';
import { IProps, IState } from './IncomePendingListByShareholders.types';
import { DataTable, FileUploadModal, PBR } from '../../../../../../components';
import { paths } from '../../../../../../routes';
import { helper } from '../../../../../../common/helpers';
import { AddIncomePaymentItemModal } from '..';
import { IAddIncomePaymetItemForm } from '../addIncomePaymentItemModal/AddIncomePaymentItemModal.types';
import { permissions } from '../../../../../../common/constants';

export default class IncomePendingListByShareholders extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        fileUpdloadOpen: false,
        addIncomePaymentModalOpen: false,
    };

    componentDidMount() {
        this.performGetIncomePendingsByShareholders();
    }

    componentWillUnmount() {
        this.props.resetIncomePendingsByShareholders();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkBulkLoad(prevProps);
        this.checkCreateIncomePatmentItem(prevProps);
    }

    async checkCreateIncomePatmentItem(prevProps: IProps) {
        const { props } = this;
        if (!prevProps.createIncomePaymentItemSuccess && props.createIncomePaymentItemSuccess) {
            message.success('Ingreso pendiente creado');
            await this.setState({ page: 1, addIncomePaymentModalOpen: false });
            this.performGetIncomePendingsByShareholders();
        } else if (!prevProps.createIncomePaymentItemError && props.createIncomePaymentItemError)
            message.error(props.createIncomePaymentItemError);
    }

    async checkBulkLoad(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.bulkLoadSuccess && props.bulkLoadSuccess) {
            message.success('Carga masiva realizada con éxito');
            await this.setState({ page: 1, fileUpdloadOpen: false });
            this.performGetIncomePendingsByShareholders();
        } else if (!prevProps.bulkLoadError && props.bulkLoadError)
            message.error(props.bulkLoadError);
    }

    performGetIncomePendingsByShareholders() {
        const { filter, page } = this.state;
        this.props.getIncomePendingsByShareholders(filter, page);
    }

    async filterIncomes() {
        await this.setState({ page: 1 });
        this.performGetIncomePendingsByShareholders();
    }

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetIncomePendingsByShareholders();
    };

    onClickDetail = (id: number) => {
        this.props.history.push(
            helper.setPathParams(paths.ADMIN_INCOME_PENDING_DETAIL_BY_SHAREHOLDER, { id }),
        );
    };

    performBulkLoad = (files: File[]) => {
        this.props.bulkLoadIncomePaymentItemsByShareholders(files[0]);
    };

    performCreateIncomePaymentItem = (values: IAddIncomePaymetItemForm) => {
        const image = values.image && values.image.length > 0 && values.image[0];
        this.props.createIncomeShareholderPaymentItem(values, image);
    };

    render() {
        const { loading, shareholders, pageSize, total } = this.props;

        return (
            <>
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={e => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterIncomes()}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.income.CREATE_INCOME_PAYMENT_ITEMS}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <PBR permission={permissions.income.BULK_LOAD_INCOMES}>
                                <Button
                                    icon="upload"
                                    type="primary"
                                    onClick={() => this.setState({ fileUpdloadOpen: true })}
                                >
                                    Carga Masiva
                                </Button>
                            </PBR>
                            <Button
                                className="ml-2"
                                icon="plus"
                                type="primary"
                                onClick={() => this.setState({ addIncomePaymentModalOpen: true })}
                            >
                                Nuevo
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={shareholders}
                            columns={columns(this.onClickDetail)}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.income.BULK_LOAD_INCOMES}>
                    <FileUploadModal
                        title="Carga masiva de ingresos pendientes para accionistas"
                        isOpen={this.state.fileUpdloadOpen}
                        onSubmit={this.performBulkLoad}
                        onCancel={() => this.setState({ fileUpdloadOpen: false })}
                        loading={this.props.bulkLoadLoading}
                    />
                </PBR>
                <PBR permission={permissions.income.CREATE_INCOME_PAYMENT_ITEMS}>
                    <AddIncomePaymentItemModal
                        type="shareholder"
                        isOpen={this.state.addIncomePaymentModalOpen}
                        onCancel={() => this.setState({ addIncomePaymentModalOpen: false })}
                        onSubmit={this.performCreateIncomePaymentItem}
                        submitting={this.props.createIncomePaymentItemLoading}
                    />
                </PBR>
            </>
        );
    }
}
