import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    getBankAccount,
    resetBankAccount,
    getBankAccountTypes,
    resetBankAccountTypes,
    createBankAccount,
    updateBankAccount,
} from '../../../../store/bankAccount/actions';
import { getCurrencies, resetCurrencies } from '../../../../store/currency/actions';
import { getBanks, resetBanks } from '../../../../store/bank/actions';
import { IStoreState } from './BankAccountAddEdit.types';
import BankAccountAddEdit from './BankAccountAddEdit';
import { IRootState } from '../../../../common/types';

function mapStateToProps(state: IRootState): IStoreState {
    const {
        activeBankAccount,
        updateBankAccount,
        createBankAccount,
        bankAccountTypeList,
    } = state.bankAccount;

    const { bankList } = state.bank;
    const { currencyList } = state.currency;

    return {
        activeBankAccount: activeBankAccount.value,
        activeBankAccountLoading: activeBankAccount.loading,
        activeBankAccountError: activeBankAccount.error,
        banks: bankList.data,
        banksLoading: bankList.loading,
        currencies: currencyList.data,
        currenciesLoading: currencyList.loading,
        bankAccountTypes: bankAccountTypeList.data,
        bankAccountTypesLoading: bankAccountTypeList.loading,
        createSuccess: createBankAccount.success,
        createLoading: createBankAccount.loading,
        createError: createBankAccount.error,
        updateSuccess: updateBankAccount.success,
        updateLoading: updateBankAccount.loading,
        updateError: updateBankAccount.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getBankAccount,
            resetBankAccount,
            getBankAccountTypes,
            resetBankAccountTypes,
            createBankAccount,
            updateBankAccount,
            getCurrencies,
            resetCurrencies,
            getBanks,
            resetBanks,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BankAccountAddEdit);
