import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './BankAccountTransactionList.types';
import BankAccountTransaction from './BankAccountTransactionList';
import { IRootState } from '../../../../common/types';
import {
    getBankAccount,
    resetBankAccount,
    getBankAccountTransactions,
    resetBankAccountTransactions,
    createBankAccountTransaction,
    createReadjustBankAccountTransaction,
} from '../../../../store/bankAccount/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const {
        bankAccountTransactionList,
        activeBankAccount,
        createBankAccountTransaction,
    } = state.bankAccount;

    return {
        createBankAccountTransactionSuccess: createBankAccountTransaction.success,
        createBankAccountTransactionLoading: createBankAccountTransaction.loading,
        createBankAccountTransactionError: createBankAccountTransaction.error,
        bankAccount: activeBankAccount.value,
        loading: activeBankAccount.loading,
        error: activeBankAccount.error,
        bankAccountTransaction: bankAccountTransactionList.data,
        total: bankAccountTransactionList.total,
        pageSize: bankAccountTransactionList.pageSize,
        bankAccountTransactionLoading: bankAccountTransactionList.loading,
        bankAccountTransactionError: bankAccountTransactionList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            createBankAccountTransaction,
            createReadjustBankAccountTransaction,
            getBankAccount,
            resetBankAccount,
            getBankAccountTransactions,
            resetBankAccountTransactions,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountTransaction);
