import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem, TableRowOptionsDropdown } from '../../../../components';
import { IReceiptNumeration } from '../../../../common/types/entities';
import { permissions } from '../../../../common/constants';
import { Button } from 'antd';

export const columns = (
    onClickEdit: (item: IReceiptNumeration) => void,
): ColumnProps<IReceiptNumeration>[] => [
    {
        title: 'Serie',
        dataIndex: 'serie',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Correlativo',
        dataIndex: 'correlative',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Tipo',
        dataIndex: 'receiptType.description',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Serie',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IReceiptNumeration) => (
            <>
                <TableCellItem label="Serie" value={item.serie} />
                <TableCellItem label="Correlativo" value={item.correlative} />
                <TableCellItem
                    label="Tipo"
                    value={item.receiptType ? item.receiptType.description : ''}
                />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IReceiptNumeration) => (
            <Button icon="edit" type="primary" shape="round" onClick={() => onClickEdit(item)} />
        ),
    },
];
