import React from 'react';
import { Button, Row, Col, Input, message, Modal, Select } from 'antd';
import { columns } from './misc/columns';
import { IProps, IState } from './PropertyList.types';
import { SectionContainer, DataTable, PBR } from '../../../../components';
import { paths } from '../../../../routes';
import { helper } from '../../../../common/helpers';
import { permissions } from '../../../../common/constants';

export default class PropertyList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        zoneId: undefined,
        subZoneId: undefined,
    };

    componentDidMount() {
        this.performGetProperties();
        // this.props.getZones();
        // this.props.getSubzones();
    }

    componentWillUnmount() {
        this.props.resetProperties();
        // this.props.resetZones();
        // this.props.resetSubzones();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkDeactivateProperty(prevProps);
    }

    async checkDeactivateProperty(prevProps: IProps) {
        if (!prevProps.deactivateSuccess && this.props.deactivateSuccess) {
            message.success('Puesto eliminado');
            await this.setState({ page: 1 });
            this.performGetProperties();
        } else if (!prevProps.deactivateError && this.props.deactivateError)
            message.error(this.props.deactivateError);
    }

    performGetProperties() {
        const { filter, page, zoneId, subZoneId } = this.state;
        this.props.getProperties(filter, page, zoneId, subZoneId);
    }

    async filterProperties() {
        await this.setState({ page: 1 });
        this.performGetProperties();
    }

    perfromDeactivateProperties = (id: number) => {
        const { deactivateProperty } = this.props;

        Modal.confirm({
            title: '¿Deseas eliminar el puesto?',
            content: 'Eliminar puesto',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deactivateProperty(id);
            },
            onCancel() {},
        });
    };

    onChangeZone = async (zoneId: number) => {
        await this.setState({ page: 1, zoneId });
        this.performGetProperties();
    };

    onChangeSubzone = async (subZoneId: number) => {
        await this.setState({ page: 1, subZoneId });
        this.performGetProperties();
    };

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetProperties();
    };

    onClickDetail = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_PROPERTY_DETAIL, { id }));
    };

    onClickProperties = (id: number) =>
        this.props.history.push(
            helper.setPathParams(paths.ADMIN_PROPERTY_SHAREHOLDER_LIST, { id }),
        );

    onClickEdit = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_PROPERTY_EDIT, { id }));
    };

    render() {
        const {
            zones,
            zonesLoading,
            subzones,
            subzonesLoading,
            loading,
            properties,
            pageSize,
            total,
        } = this.props;

        return (
            <SectionContainer title="Puestos" icon="environment">
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={e => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterProperties()}
                        />
                    </Col>
                    {/* <Col className="ml-md-3 ml-lg-4" md={11} lg={6}>
                        <Row>
                            <Col className="mt-2 mt-md-1" md={5} lg={6}>
                                Zona:
                            </Col>
                            <Col md={18} lg={18}>
                                {zones && (
                                    <Select
                                        className="w-100"
                                        value={this.state.zoneId}
                                        onChange={this.onChangeZone}
                                        loading={zonesLoading}
                                        allowClear={true}
                                    >
                                        {zones.map(i => (
                                            <Select.Option key={nanoid()} value={i.id}>
                                                {i.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="ml-md-3 ml-lg-4" md={11} lg={6}>
                        <Row>
                            <Col className="mt-2 mt-md-1" md={5} lg={6}>
                                Giro:
                            </Col>
                            <Col md={18} lg={18}>
                                {subzones && (
                                    <Select
                                        className="w-100"
                                        value={this.state.subZoneId}
                                        onChange={this.onChangeSubzone}
                                        loading={subzonesLoading}
                                        allowClear={true}
                                    >
                                        {subzones.map(i => (
                                            <Select.Option key={nanoid()} value={i.id}>
                                                {i.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Col>
                        </Row>
                    </Col> */}
                </Row>
                <PBR permission={permissions.property.WRITE_PROPERTIES}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() => this.props.history.push(paths.ADMIN_PROPERTY_CREATE)}
                            >
                                Nuevo
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={properties}
                            columns={columns(
                                this.onClickDetail,
                                this.onClickEdit,
                                this.onClickProperties,
                                this.perfromDeactivateProperties,
                                this.props.permissions,
                            )}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
            </SectionContainer>
        );
    }
}
