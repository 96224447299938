import { combineReducers, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import APIMiddleware from '../common/middlewares/APIMiddleware';

import authReducer from './auth/reducer';
import userReducer from './user/reducer';
import customerReducer from './customer/reducer';
import providerReducer from './provider/reducer';
import zoneReducer from './zone/reducer';
import propertyReducer from './property/reducer';
import bankReducer from './bank/reducer';
import bankAccountReducer from './bankAccount/reducer';
import currencyReducer from './currency/reducer';
import incomeReducer from './income/reducer';
import paymentItemReducer from './paymentItem/reducer';
import commonRequestReducer from './commonRequest/reducer';
import subzoneReducer from './subzone/reducer';
import shareholderReducer from './shareholder/reducer';
import expenseReducer from './expense/reducer';
import invoiceReducer from './invoice/reducer';
import paymentReducer from './payment/reducer';
import requestOrderReducer from './requestOrder/reducer';
import taxReducer from './tax/reducer';
import reportReducer from './report/reducer';
import configureReducer from './configure/reducer';
import pettyCashReducer from './pettyCash/reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    customer: customerReducer,
    provider: providerReducer,
    zone: zoneReducer,
    property: propertyReducer,
    bank: bankReducer,
    currency: currencyReducer,
    bankAccount: bankAccountReducer,
    income: incomeReducer,
    paymentItem: paymentItemReducer,
    commonRequest: commonRequestReducer,
    subzone: subzoneReducer,
    shareholder: shareholderReducer,
    expense: expenseReducer,
    invoice: invoiceReducer,
    payment: paymentReducer,
    requestOrder: requestOrderReducer,
    tax: taxReducer,
    report: reportReducer,
    configure: configureReducer,
    pettyCash: pettyCashReducer,
});

const createStoreWithMiddleware = applyMiddleware(thunk, APIMiddleware)(createStore);
const store = createStoreWithMiddleware(rootReducer);

export default store;
