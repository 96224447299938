import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';

const { USER_URL } = apiUrls;

export function getAll(filter: string, page: number) {
    const params = { filter, page };

    return APIManager.get(USER_URL, { params });
}

export function getUsersByRolId(id: number) {
    const url = `${USER_URL}/roles/${id}`;
    return APIManager.get(url);
}

export function get(id: number) {
    const url = `${USER_URL}/${id}`;
    return APIManager.get(url);
}

export function create(data: any) {
    return APIManager.post(USER_URL, { data });
}

export function update(id: number, data: any) {
    const url = `${USER_URL}/${id}`;
    return APIManager.put(url, { data });
}

export function deactivate(id: number) {
    const url = `${USER_URL}/${id}/deactivate`;
    return APIManager.put(url);
}

export function getRoles() {
    const url = `${USER_URL}/roles/all`;
    return APIManager.get(url);
}

export function updateAuthorizationToken() {
    const url = `${USER_URL}/authorization-token`;
    return APIManager.put(url);
}
