import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';

export function login(username: string, password: string) {
    const data = {
        password,
        username,
    };

    const url = `${apiUrls.AUTH_URL}/login`;

    return APIManager.post(url, { data });
}

export function getLoggedInUser() {
    const url = `${apiUrls.AUTH_URL}/me`;
    return APIManager.get(url);
}

export function forgotPassword(email: string) {
    const data = {
        email,
    };

    const url = `${apiUrls.AUTH_URL}/forgot-password`;

    return APIManager.post(url, { data });
}

export function changePassword(currentPassword, newPassword, confirmNewPassword: string) {
    const data = {
        currentPassword,
        newPassword,
        confirmNewPassword,
    };

    const url = `${apiUrls.AUTH_URL}/change-password`;

    return APIManager.post(url, { data });
}

export function resetPassword(password: string, confirmPassword: string, passwordToken: string) {
    const data = {
        password,
        confirmPassword,
    };

    const url = `${apiUrls.AUTH_URL}/reset-password/${passwordToken}`;
    return APIManager.put(url, { data });
}
