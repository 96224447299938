import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import {
    getShareholder,
    resetShareholder,
    getCoOwners,
    resetCoOwners,
    createCoOwner,
    resetCreateCoOwner,
    updateCoOwner,
    resetUpdateCoOwner,
    deactivateCoOwner,
    resetDeactivateCoOwner,
} from '../../../../store/shareholder/actions';
import { IStoreState } from './ShareholderCoOwnerList.types';
import ShareholderCoOwnerList from './ShareholderCoOwnerList';

function mapStateToProps(state: IRootState): IStoreState {
    const {
        activeShareholder,
        coOwnerList,
        createCoOwner,
        updateCoOwner,
        deactivateCoOwner,
    } = state.shareholder;

    return {
        shareholder: activeShareholder.value,
        loading: activeShareholder.loading,
        error: activeShareholder.error,
        coOwners: coOwnerList.data,
        coOwnersLoading: coOwnerList.loading,
        createCoOwnerSuccess: createCoOwner.success,
        createCoOwnerLoading: createCoOwner.loading,
        createCoOwnerError: createCoOwner.error,
        updateCoOwnerSuccess: updateCoOwner.success,
        updateCoOwnerLoading: updateCoOwner.loading,
        updateCoOwnerError: updateCoOwner.error,
        deactivateCoOwnerSuccess: deactivateCoOwner.success,
        deactivateCoOwnerLoading: deactivateCoOwner.loading,
        deactivateCoOwnerError: deactivateCoOwner.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getShareholder,
            resetShareholder,
            getCoOwners,
            resetCoOwners,
            createCoOwner,
            resetCreateCoOwner,
            updateCoOwner,
            resetUpdateCoOwner,
            deactivateCoOwner,
            resetDeactivateCoOwner,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareholderCoOwnerList);
