import { Dispatch } from 'redux'
import actionTypes from './actionTypes'
import { IAPIMiddleware } from '../../common/types'
import { invoiceService } from '../../service'
import { IInvoice, IPayment } from '../../common/types/entities'
import { GET_ALL_ITEMS } from '../../common/constants'

export function getInvoicesByRequestOrder(id: number): IAPIMiddleware {
  return {
    callAPI: () => invoiceService.getInvoicesByRequestOrder(id),
    types: [
      actionTypes.GET_INVOICES_BY_REQUEST_ORDER,
      actionTypes.GET_INVOICES_BY_REQUEST_ORDER_SUCCESS,
      actionTypes.GET_INVOICES_BY_REQUEST_ORDER_FAILURE,
    ],
  };
}

export function getInvoicesByRequestOrderDetails(id: number): IAPIMiddleware {
  return {
    callAPI: () => invoiceService.getInvoicesByRequestOrderDetails(id),
    types: [
      actionTypes.GET_INVOICES_BY_REQUEST_ORDER_DETAILS,
      actionTypes.GET_INVOICES_BY_REQUEST_ORDER_DETAILS_SUCCESS,
      actionTypes.GET_INVOICES_BY_REQUEST_ORDER_DETAILS_FAILURE,
    ],
  };
}

export function getInvoicesByProvider(
  providerId: number,
  filter = '',
  page = GET_ALL_ITEMS,
): IAPIMiddleware {
  return {
    callAPI: () => invoiceService.getInvoicesByProvider(providerId, filter, page),
    types: [
      actionTypes.GET_INVOICES_BY_PROVIDER,
      actionTypes.GET_INVOICES_BY_PROVIDER_SUCCESS,
      actionTypes.GET_INVOICES_BY_PROVIDER_FAILURE,
    ]
  }
}

export function getInvoicesByProviderDetails(id: number): IAPIMiddleware {
  return {
    callAPI: () => invoiceService.getInvoicesByProviderDetails(id),
    types: [
      actionTypes.GET_INVOICES_BY_PROVIDER_DETAILS,
      actionTypes.GET_INVOICES_BY_PROVIDER_DETAILS_SUCCESS,
      actionTypes.GET_INVOICES_BY_PROVIDER_DETAILS_FAILURE,
    ]
  }
}

export function resetInvoicesByProvider() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.GET_INVOICES_BY_PROVIDER_RESET,
    })
  }
}

export function resetInvoicesByProviderDetails() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.GET_INVOICES_BY_PROVIDER_DETAILS_RESET,
    })
  }
}

export function getInvoice(id: number): IAPIMiddleware {
  return {
    callAPI: () => invoiceService.getInvoice(id),
    types: [
      actionTypes.GET_INVOICE,
      actionTypes.GET_INVOICE_SUCCESS,
      actionTypes.GET_INVOICE_FAILURE
    ]
  }
}

export function resetInvoicesbyRequestOrder() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.GET_INVOICES_BY_REQUEST_ORDER_RESET,
    });
  };
}

export function resetInvoicesbyRequestOrderDetails() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.GET_INVOICES_BY_REQUEST_ORDER_DETAILS_RESET,
    });
  };
}

export function completeInvoice(invoice: IInvoice, payment?: IPayment): IAPIMiddleware {
  return {
    callAPI: () => invoiceService.completeInvoice(invoice, payment),
    types: [
      actionTypes.COMPLETE_INVOICE,
      actionTypes.COMPLETE_INVOICE_SUCCESS,
      actionTypes.COMPLETE_INVOICE_FAILURE,
    ],
  };
}

export function completeInvoiceProvider(invoice: IInvoice, payment?: IPayment): IAPIMiddleware {
  return {
    callAPI: () => invoiceService.completeInvoiceProvider(invoice, payment),
    types: [
      actionTypes.COMPLETE_INVOICE_PROVIDER,
      actionTypes.COMPLETE_INVOICE_PROVIDER_SUCCESS,
      actionTypes.COMPLETE_INVOICE_PROVIDER_FAILURE
    ]
  }
}

export function deactivateInvoice(id: number, invoice: IInvoice): IAPIMiddleware {
  return {
    callAPI: () => invoiceService.deactivateInvoice(id, invoice),
    types: [
      actionTypes.DEACTIVATE_INVOICE,
      actionTypes.DEACTIVATE_INVOICE_SUCCESS,
      actionTypes.DEACTIVATE_INVOICE_FAILURE,
    ]
  }
}

export function deactivateInvoiceProvider(id: number, invoice: IInvoice): IAPIMiddleware {
  return {
    callAPI: () => invoiceService.deactivateInvoiceProvider(id, invoice),
    types: [
      actionTypes.DEACTIVATE_INVOICE_PROVIDER,
      actionTypes.DEACTIVATE_INVOICE_PROVIDER_SUCCESS,
      actionTypes.DEACTIVATE_INVOICE_PROVIDER_FAILURE,
    ]
  }
}

export function resetDeactivateInvoice() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.DEACTIVATE_INVOICE_RESET
    })
  }
}
