import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableRowOptionsDropdown, PBR } from '../../../../../components';
import { IUser, IZone } from '../../../../../common/types/entities';
import { permissions } from '../../../../../common/constants';
import { Button } from 'antd';

export const columns = (
    onClickEdit: (zone: IZone) => void,
    onClickDetail: (id: number) => void,
    onClickDelete: (id: number) => void,
    permissionCodes: string[],
): ColumnProps<IUser>[] => [
    {
        title: 'Nombre',
        dataIndex: 'name',
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IZone) => {
            if (permissionCodes.includes(permissions.property.WRITE_SUBZONES)) {
                return (
                    <TableRowOptionsDropdown
                        onClickEdit={() => onClickEdit(item)}
                        onClickDetail={() => onClickDetail(item.id)}
                        onClickDelete={() => onClickDelete(item.id)}
                    />
                );
            } else if (permissionCodes.includes(permissions.property.READ_SUBZONES)) {
                return (
                    <Button
                        type="primary"
                        shape="round"
                        icon="eye"
                        onClick={() => onClickDetail(item.id)}
                    />
                );
            }
        },
    },
];
