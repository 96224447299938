import { IObjectState } from '../../common/types';
import { IRucInfo } from '../../common/types';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    rucInfo: IObjectState<IRucInfo>;
}

export const initialState: IState = {
    rucInfo: { value: undefined, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_RUC_INFO]: caseFunctions.getRucInfo,
    [actionTypes.GET_RUC_INFO_SUCCESS]: caseFunctions.getRucInfoSuccess,
    [actionTypes.GET_RUC_INFO_FAILURE]: caseFunctions.getRucInfoFailure,
    [actionTypes.GET_RUC_INFO_RESET]: caseFunctions.getRucInfoReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
