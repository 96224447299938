const actionTypes = {
    GET_PROPERTIES: 'property/GET_PROPERTIES',
    GET_PROPERTIES_SUCCESS: 'property/GET_PROPERTIES_SUCCESS',
    GET_PROPERTIES_FAILURE: 'property/GET_PROPERTIES_FAILURE',
    GET_PROPERTIES_RESET: 'property/GET_PROPERTIES_RESET',

    GET_PROPERTY: 'property/GET_PROPERTY',
    GET_PROPERTY_SUCCESS: 'property/GET_PROPERTY_SUCCESS',
    GET_PROPERTY_FAILURE: 'property/GET_PROPERTY_FAILURE',
    GET_PROPERTY_RESET: 'property/GET_PROPERTY_RESET',

    CREATE_PROPERTY: 'property/CREATE_PROPERTY',
    CREATE_PROPERTY_SUCCESS: 'property/CREATE_PROPERTY_SUCCESS',
    CREATE_PROPERTY_FAILURE: 'property/CREATE_PROPERTY_FAILURE',
    CREATE_PROPERTY_RESET: 'property/CREATE_PROPERTY_RESET',

    UPDATE_PROPERTY: 'property/UPDATE_PROPERTY',
    UPDATE_PROPERTY_SUCCESS: 'property/UPDATE_PROPERTY_SUCCESS',
    UPDATE_PROPERTY_FAILURE: 'property/UPDATE_PROPERTY_FAILURE',
    UPDATE_PROPERTY_RESET: 'property/UPDATE_PROPERTY_RESET',

    DEACTIVATE_PROPERTY: 'property/DEACTIVATE_PROPERTY',
    DEACTIVATE_PROPERTY_SUCCESS: 'property/DEACTIVATE_PROPERTY_SUCCESS',
    DEACTIVATE_PROPERTY_FAILURE: 'property/DEACTIVATE_PROPERTY_FAILURE',
    DEACTIVATE_PROPERTY_RESET: 'property/DEACTIVATE_PROPERTY_RESET',

    GET_PROPERTY_SHAREHOLDERS: 'property/GET_PROPERTY_SHAREHOLDERS',
    GET_PROPERTY_SHAREHOLDERS_SUCCESS: 'property/GET_PROPERTY_SHAREHOLDERS_SUCCESS',
    GET_PROPERTY_SHAREHOLDERS_FAILURE: 'property/GET_PROPERTY_SHAREHOLDERS_FAILURE',
    GET_PROPERTY_SHAREHOLDERS_RESET: 'property/GET_PROPERTY_SHAREHOLDERS_RESET',

    CREATE_PROPERTY_SHAREHOLDER: 'property/CREATE_PROPERTY_SHAREHOLDER',
    CREATE_PROPERTY_SHAREHOLDER_SUCCESS: 'property/CREATE_PROPERTY_SHAREHOLDER_SUCCESS',
    CREATE_PROPERTY_SHAREHOLDER_FAILURE: 'property/CREATE_PROPERTY_SHAREHOLDER_FAILURE',
    CREATE_PROPERTY_SHAREHOLDER_RESET: 'property/CREATE_PROPERTY_SHAREHOLDER_RESET',

    UPDATE_PROPERTY_SHAREHOLDER: 'property/UPDATE_PROPERTY_SHAREHOLDER',
    UPDATE_PROPERTY_SHAREHOLDER_SUCCESS: 'property/UPDATE_PROPERTY_SHAREHOLDER_SUCCESS',
    UPDATE_PROPERTY_SHAREHOLDER_FAILURE: 'property/UPDATE_PROPERTY_SHAREHOLDER_FAILURE',
    UPDATE_PROPERTY_SHAREHOLDER_RESET: 'property/UPDATE_PROPERTY_SHAREHOLDER_RESET',

    GET_AVAILABLE_SHAREHOLDERS: 'property/GET_AVAILABLE_SHAREHOLDERS',
    GET_AVAILABLE_SHAREHOLDERS_SUCCESS: 'property/GET_AVAILABLE_SHAREHOLDERS_SUCCESS',
    GET_AVAILABLE_SHAREHOLDERS_FAILURE: 'property/GET_AVAILABLE_SHAREHOLDERS_FAILURE',
    GET_AVAILABLE_SHAREHOLDERS_RESET: 'property/GET_AVAILABLE_SHAREHOLDERS_RESET',

    DELETE_PROPERTY_SHAREHOLDER: 'property/DELETE_PROPERTY_SHAREHOLDER',
    DELETE_PROPERTY_SHAREHOLDER_SUCCESS: 'property/DELETE_PROPERTY_SHAREHOLDER_SUCCESS',
    DELETE_PROPERTY_SHAREHOLDER_FAILURE: 'property/DELETE_PROPERTY_SHAREHOLDER_FAILURE',
    DELETE_PROPERTY_SHAREHOLDER_RESET: 'property/DELETE_PROPERTY_SHAREHOLDER_RESET',
};

export default actionTypes;
