import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './ExpenseLogisticAddEdit.types';
import ExpenseLogisticAddEdit from './ExpenseLogisticAddEdit';
import { IRootState } from '../../../../common/types';
import {
    getExpense,
    getExpenseDetails,
    resetExpense,
    resetExpenseDetails,
    completeLogistic,
} from '../../../../store/expense/actions';
import { getCurrencies, resetCurrencies } from '../../../../store/currency/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeExpense, expenseDetailList, completeLogistic } = state.expense,
        { currencyList } = state.currency;

    return {
        activeExpense: activeExpense.value,
        activeExpenseLoading: activeExpense.loading,
        activeExpenseError: activeExpense.error,
        currencies: currencyList.data,
        currenciesLoading: currencyList.loading,
        expenseDetails: expenseDetailList.data,
        expenseDetailsLoading: expenseDetailList.loading,
        completeLogisticSuccess: completeLogistic.success,
        completeLogisticLoading: completeLogistic.loading,
        completeLogisticError: completeLogistic.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getExpense,
            getExpenseDetails,
            completeLogistic,
            getCurrencies,
            resetCurrencies,
            resetExpense,
            resetExpenseDetails,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseLogisticAddEdit);
