const actionTypes = {
    GET_EXPENSES: 'expense/GET_EXPENSES',
    GET_EXPENSES_SUCCESS: 'expense/GET_EXPENSES_SUCCESS',
    GET_EXPENSES_FAILURE: 'expense/GET_EXPENSES_FAILURE',
    GET_EXPENSES_RESET: 'expense/GET_EXPENSES_RESET',

    GET_EXPENSE: 'expense/GET_EXPENSE',
    GET_EXPENSE_SUCCESS: 'expense/GET_EXPENSE_SUCCESS',
    GET_EXPENSE_FAILURE: 'expense/GET_EXPENSE_FAILURE',
    GET_EXPENSE_RESET: 'expense/GET_EXPENSE_RESET',

    GET_EXPENSE_DETAILS: 'expense/GET_EXPENSE_DETAILS',
    GET_EXPENSE_DETAILS_SUCCESS: 'expense/GET_EXPENSE_DETAILS_SUCCESS',
    GET_EXPENSE_DETAILS_FAILURE: 'expense/GET_EXPENSE_DETAILS_FAILURE',
    GET_EXPENSE_DETAILS_RESET: 'expense/GET_EXPENSE_DETAILS_RESET',

    CREATE_EXPENSE: 'expense/CREATE_EXPENSE',
    CREATE_EXPENSE_SUCCESS: 'expense/CREATE_EXPENSE_SUCCESS',
    CREATE_EXPENSE_FAILURE: 'expense/CREATE_EXPENSE_FAILURE',
    CREATE_EXPENSE_RESET: 'expense/CREATE_EXPENSE_RESET',

    UPDATE_EXPENSE: 'expense/UPDATE_EXPENSE',
    UPDATE_EXPENSE_SUCCESS: 'expense/UPDATE_EXPENSE_SUCCESS',
    UPDATE_EXPENSE_FAILURE: 'expense/UPDATE_EXPENSE_FAILURE',
    UPDATE_EXPENSE_RESET: 'expense/UPDATE_EXPENSE_RESET',

    COMPLETE_LOGISTIC: 'expense/COMPLETE_LOGISTIC',
    COMPLETE_LOGISTIC_SUCCESS: 'expense/COMPLETE_LOGISTIC_SUCCESS',
    COMPLETE_LOGISTIC_FAILURE: 'expense/COMPLETE_LOGISTIC_FAILURE',

    DEACTIVATE_EXPENSE: 'expense/DEACTIVATE_EXPENSE',
    DEACTIVATE_EXPENSE_SUCCESS: 'expense/DEACTIVATE_EXPENSE_SUCCESS',
    DEACTIVATE_EXPENSE_FAILURE: 'expense/DEACTIVATE_EXPENSE_FAILURE',
    DEACTIVATE_EXPENSE_RESET: 'expense/DEACTIVATE_EXPENSE_RESET',

    GET_EXPENSE_TYPES: 'expense/GET_EXPENSE_TYPES',
    GET_EXPENSE_TYPES_SUCCESS: 'expense/GET_EXPENSE_TYPES_SUCCESS',
    GET_EXPENSE_TYPES_FAILURE: 'expense/GET_EXPENSE_TYPES_FAILURE',
    GET_EXPENSE_TYPES_RESET: 'expense/GET_EXPENSE_TYPES_RESET',

    DELETE_EXPENSE: 'expense/DELETE_EXPENSE',
    DELETE_EXPENSE_SUCCESS: 'expense/DELETE_EXPENSE_SUCCESS',
    DELETE_EXPENSE_FAILURE: 'expense/DELETE_EXPENSE_FAILURE',
    DELETE_EXPENSE_RESET: 'expense/DELETE_EXPENSE_RESET',

    DELETE_EXPENSE_DETAIL: 'expense/DELETE_EXPENSE_DETAIL',
    DELETE_EXPENSE_DETAIL_SUCCESS: 'expense/DELETE_EXPENSE_DETAIL_SUCCESS',
    DELETE_EXPENSE_DETAIL_FAILURE: 'expense/DELETE_EXPENSE_DETAIL_FAILURE',
    DELETE_EXPENSE_DETAIL_RESET: 'expense/DELETE_EXPENSE_DETAIL_RESET',

    ANSWER_EXPENSE: 'expense/ANSWER_EXPENSE',
    ANSWER_EXPENSE_SUCCESS: 'expense/ANSWER_EXPENSE_SUCCESS',
    ANSWER_EXPENSE_FAILURE: 'expense/ANSWER_EXPENSE_FAILURE',
    ANSWER_EXPENSE_RESET: 'expense/ANSWER_EXPENSE_RESET',

    COMPLETE_EXPENSE: 'expense/COMPLETE_EXPENSE',
    COMPLETE_EXPENSE_SUCCESS: 'expense/COMPLETE_EXPENSE_SUCCESS',
    COMPLETE_EXPENSE_FAILURE: 'expense/COMPLETE_EXPENSE_FAILURE',
    COMPLETE_EXPENSE_RESET: 'expense/COMPLETE_EXPENSE_RESET',
};

export default actionTypes;
