import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem, TableRowOptionsDropdown } from '../../../../../components';
import { ICoOwner } from '../../../../../common/types/entities';

export const columns = (
    onClickEdit: (item: ICoOwner) => void,
    onClickDelete: (id: number) => void,
): ColumnProps<ICoOwner>[] => [
    {
        title: 'Nombre',
        dataIndex: 'firstname',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Apellidos',
        dataIndex: 'lastname',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'DNI',
        dataIndex: 'dni',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Puesto',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: ICoOwner) => (
            <>
                <TableCellItem label="Nombre" value={item.firstname} />
                <TableCellItem label="Apellidos" value={item.lastname} />
                <TableCellItem label="DNI" value={item.dni} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: ICoOwner) => (
            <TableRowOptionsDropdown
                onClickEdit={() => onClickEdit(item)}
                onClickDelete={() => onClickDelete(item.id)}
            />
        ),
    },
];
