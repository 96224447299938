import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem, PBR } from '../../../../../../../components';
import { IProperty, IShareholder } from '../../../../../../../common/types/entities';
import { Button } from 'antd';
import { formatHelper } from '../../../../../../../common/helpers';
import { permissions } from '../../../../../../../common/constants';

export const columns = (onClickDetail: (id: number) => void): ColumnProps<IShareholder>[] => [
    {
        title: 'Código',
        dataIndex: 'code',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Nombre Completo',
        dataIndex: '',
        className: 'd-none d-md-table-cell',
        render: (item: IShareholder) => <span>{formatHelper.getShareholderFullname(item)}</span>,
    },
    {
        title: 'Precio Total (PEN)',
        dataIndex: 'totalPaymentItemPricePEN',
        className: 'd-none d-md-table-cell',
        render: (totalPaymentItemPricePEN: number) => (
            <span>{formatHelper.toMoney(totalPaymentItemPricePEN)}</span>
        ),
    },
    {
        title: 'Precio Total (USD)',
        dataIndex: 'totalPaymentItemPriceUSD',
        className: 'd-none d-md-table-cell',
        render: (totalPaymentItemPriceUSD: number) => (
            <span>{formatHelper.toMoney(totalPaymentItemPriceUSD)}</span>
        ),
    },
    {
        title: 'Cantidad de Conceptos',
        dataIndex: 'totalPaymentItems',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Ingreso',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IShareholder) => (
            <>
                <TableCellItem label="Código" value={item.code} />
                <TableCellItem
                    label="Nombre Completo"
                    value={formatHelper.getShareholderFullname(item)}
                />
                <TableCellItem
                    label="Precio Total (PEN)"
                    value={item.totalPaymentItemPricePEN}
                    type="money"
                />
                <TableCellItem
                    label="Precio Total (USD)"
                    value={item.totalPaymentItemPriceUSD}
                    type="money"
                />
                <TableCellItem label="Cantidad de Conceptos" value={item.totalPaymentItems} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IShareholder) => {
            return (
                <PBR permission={permissions.income.READ_PENDING_INCOMES}>
                    <Button
                        type="primary"
                        shape="round"
                        icon="eye"
                        onClick={() => onClickDetail(item.id)}
                    />
                </PBR>
            );
        },
    },
];
