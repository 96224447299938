import React, { FunctionComponent } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { paths } from './paths';
import { PrivateRoute, MainLayout } from '../components';
import {
    Page404,
    Page403,
    Login,
    ForgotPassword,
    ResetPassword,
    UserProfile,
    UserList,
    UserDetail,
    UserAddEdit,
    // CustomerList,
    // CustomerDetail,
    // CustomerAddEdit,
    ZoneList,
    ZoneDetail,
    SubzoneList,
    SubzoneDetail,
    PropertyList,
    PropertyAddEdit,
    PropertyDetail,
    BankList,
    BankAccountList,
    BankAccountDetail,
    BankAccountAddEdit,
    BankAccountTransactionList,
    ProviderList,
    ProviderDetail,
    ProviderAddEdit,
    IncomePendingList,
    IncomePendingDetailByProperty,
    IncomePendingDetailByShareholder,
    IncomeList,
    IncomeAdd,
    IncomeDetail,
    IncomeCompletePayment,
    PaymentItemList,
    PaymentItemAddEdit,
    PaymentItemTypeList,
    ShareholderList,
    ShareholderDetail,
    ShareholderAddEdit,
    PropertyShareholderList,
    ShareholderCoOwnerList,
    ExpenseList,
    ExpenseDetail,
    ExpenseRequirementAddEdit,
    ExpenseLogisticAddEdit,
    ExpenseAnswerAddEdit,
    ExpenseRequestOrderAddEdit,
    ExpenseAccordanceAddEdit,
    ExpenseInvoiceAddEdit,
    ExpensePaymentAddEdit,
    InvoiceList,
    InvoiceCreate,
    InvoiceDetail,
    PaymentList,
    PaymentCreate,
    PaymentDetail,
    ReportHome,
    IncomeDetailedPendingReport,
    IncomeDetailedPendingPaymentReport,
    IncomeDetailedCompletedReport,
    IncomeDetailedAnulledReport,
    ConfigureList,
    PettyCashList,
    PettyCashDetail,
} from '../scenes';
import { permissions, roles } from '../common/constants';
import { IUser } from '../common/types/entities';

type IProps = {
    loggedInUser: IUser;
};

const Routes: FunctionComponent<IProps> = ({ loggedInUser }) => {
    const getInitialRoute = (): string => {
        const roleId = loggedInUser ? Number(loggedInUser.role.id) : 0;

        if (roleId == roles.EXPENSES_OPERATOR || roleId == roles.LOGISTICS_OPERATOR)
            return paths.ADMIN_EXPENSE_LIST;
        else if (roleId == roles.MANAGEMENT_ASSISTANT) return paths.ADMIN_PROPERTY_LIST;
        else if (roleId == roles.SCALE_OPERATOR) return paths.ADMIN_INCOME_CREATE;
        else if (roleId == roles.PARKING_OPERATOR) return paths.ADMIN_INCOME_CREATE;
        else return paths.ADMIN_INCOME_PENDING_LIST;
    };

    return (
        <BrowserRouter>
            <Switch>
                <Redirect exact from="/" to={getInitialRoute()} />
                <Route exact path={paths.LOGIN} component={Login} />
                <Route exact path={paths.FORGOT_PASSWORD} component={ForgotPassword} />
                <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
                <PrivateRoute
                    exact
                    path={paths.USER_PROFILE}
                    component={UserProfile}
                    layout={MainLayout}
                />

                <PrivateRoute
                    exact
                    path={paths.ADMIN_USER_LIST}
                    component={UserList}
                    layout={MainLayout}
                    permission={permissions.user.READ_USERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_USER_DETAIL}
                    component={UserDetail}
                    layout={MainLayout}
                    permission={permissions.user.READ_USERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_USER_CREATE}
                    component={UserAddEdit}
                    layout={MainLayout}
                    permission={permissions.user.WRITE_USERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_USER_EDIT}
                    component={UserAddEdit}
                    layout={MainLayout}
                    permission={permissions.user.WRITE_USERS}
                />

                {/* <PrivateRoute
                    exact
                    path={paths.ADMIN_CUSTOMER_LIST}
                    component={CustomerList}
                    layout={MainLayout}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_CUSTOMER_DETAIL}
                    component={CustomerDetail}
                    layout={MainLayout}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_CUSTOMER_CREATE}
                    component={CustomerAddEdit}
                    layout={MainLayout}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_CUSTOMER_EDIT}
                    component={CustomerAddEdit}
                    layout={MainLayout}
                /> */}

                <PrivateRoute
                    exact
                    path={paths.ADMIN_ZONE_LIST}
                    component={ZoneList}
                    layout={MainLayout}
                    permission={permissions.property.READ_ZONES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_ZONE_DETAIL}
                    component={ZoneDetail}
                    layout={MainLayout}
                    permission={permissions.property.READ_ZONES}
                />

                <PrivateRoute
                    exact
                    path={paths.ADMIN_SUBZONE_LIST}
                    component={SubzoneList}
                    layout={MainLayout}
                    permission={permissions.property.READ_SUBZONES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_SUBZONE_DETAIL}
                    component={SubzoneDetail}
                    layout={MainLayout}
                    permission={permissions.property.READ_SUBZONES}
                />

                <PrivateRoute
                    exact
                    path={paths.ADMIN_PROPERTY_LIST}
                    component={PropertyList}
                    layout={MainLayout}
                    permission={permissions.property.READ_PROPERTIES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PROPERTY_DETAIL}
                    component={PropertyDetail}
                    layout={MainLayout}
                    permission={permissions.property.READ_PROPERTY_DETAIL}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PROPERTY_CREATE}
                    component={PropertyAddEdit}
                    layout={MainLayout}
                    permission={permissions.property.WRITE_PROPERTIES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PROPERTY_EDIT}
                    component={PropertyAddEdit}
                    layout={MainLayout}
                    permission={permissions.property.WRITE_PROPERTIES}
                />

                <PrivateRoute
                    exact
                    path={paths.ADMIN_BANK_LIST}
                    component={BankList}
                    layout={MainLayout}
                    permission={permissions.bank.READ_BANKS}
                />

                <PrivateRoute
                    exact
                    path={paths.ADMIN_BANK_ACCOUNT_LIST}
                    component={BankAccountList}
                    layout={MainLayout}
                    permission={permissions.bankAccount.READ_BANK_ACCOUNTS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_BANK_ACCOUNT_DETAIL}
                    component={BankAccountDetail}
                    layout={MainLayout}
                    permission={permissions.bankAccount.READ_BANK_ACCOUNT_DETAIL}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_BANK_ACCOUNT_CREATE}
                    component={BankAccountAddEdit}
                    layout={MainLayout}
                    permission={permissions.bankAccount.WRITE_BANK_ACCOUNTS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_BANK_ACCOUNT_EDIT}
                    component={BankAccountAddEdit}
                    layout={MainLayout}
                    permission={permissions.bankAccount.WRITE_BANK_ACCOUNTS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_BANK_ACCOUNT_TRANSACTION}
                    component={BankAccountTransactionList}
                    layout={MainLayout}
                    permission={permissions.bankAccount.READ_BANK_ACCOUNT_MOVEMENTS}
                />

                <PrivateRoute
                    exact
                    path={paths.ADMIN_PROVIDER_LIST}
                    component={ProviderList}
                    layout={MainLayout}
                    permission={permissions.provider.READ_PROVIDERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PROVIDER_DETAIL}
                    component={ProviderDetail}
                    layout={MainLayout}
                    permission={permissions.provider.READ_PROVIDERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PROVIDER_CREATE}
                    component={ProviderAddEdit}
                    layout={MainLayout}
                    permission={permissions.provider.WRITE_PROVIDERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PROVIDER_EDIT}
                    component={ProviderAddEdit}
                    layout={MainLayout}
                    permission={permissions.provider.WRITE_PROVIDERS}
                />

                <PrivateRoute
                    exact
                    path={paths.ADMIN_INCOME_PENDING_LIST}
                    component={IncomePendingList}
                    layout={MainLayout}
                    permission={permissions.income.READ_PENDING_INCOMES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_INCOME_PENDING_DETAIL_BY_PROPERTY}
                    component={IncomePendingDetailByProperty}
                    layout={MainLayout}
                    permission={permissions.income.READ_PENDING_INCOMES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_INCOME_PENDING_DETAIL_BY_SHAREHOLDER}
                    component={IncomePendingDetailByShareholder}
                    layout={MainLayout}
                    permission={permissions.income.READ_PENDING_INCOMES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_INCOME_COMPLETE_LIST}
                    component={IncomeList}
                    layout={MainLayout}
                    permission={permissions.income.READ_COMPLETED_INCOMES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_INCOME_DETAIL}
                    component={IncomeDetail}
                    layout={MainLayout}
                    permission={permissions.income.READ_COMPLETED_INCOME_DETAIL}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_INCOME_CREATE}
                    component={IncomeAdd}
                    layout={MainLayout}
                    permission={permissions.income.COMPLETE_INCOMES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_INCOME_COMPLETE_PAYMENT}
                    component={IncomeCompletePayment}
                    layout={MainLayout}
                    permission={permissions.income.COMPLETE_INCOMES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PAYMENT_ITEM_LIST}
                    component={PaymentItemList}
                    layout={MainLayout}
                    permission={permissions.paymentItem.READ_PAYMENT_ITEMS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PAYMENT_ITEM_CREATE}
                    component={PaymentItemAddEdit}
                    layout={MainLayout}
                    permission={permissions.paymentItem.WRITE_PAYMENT_ITEMS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PAYMENT_ITEM_EDIT}
                    component={PaymentItemAddEdit}
                    layout={MainLayout}
                    permission={permissions.paymentItem.WRITE_PAYMENT_ITEMS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PAYMENT_ITEM_TYPE_LIST}
                    component={PaymentItemTypeList}
                    layout={MainLayout}
                    permission={permissions.paymentItemType.READ_PAYMENT_ITEM_TYPES}
                />

                <PrivateRoute
                    exact
                    path={paths.ADMIN_EXPENSE_LIST}
                    component={ExpenseList}
                    layout={MainLayout}
                    permission={permissions.expense.READ_EXPENSES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_EXPENSE_REQUERIMENT_CREATE}
                    component={ExpenseRequirementAddEdit}
                    layout={MainLayout}
                    permission={permissions.expense.WRITE_REQUERIMENTS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_EXPENSE_REQUERIMENT_EDIT}
                    component={ExpenseRequirementAddEdit}
                    layout={MainLayout}
                    permission={permissions.expense.WRITE_REQUERIMENTS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_EXPENSE_LOGISTIC}
                    component={ExpenseLogisticAddEdit}
                    layout={MainLayout}
                    permission={permissions.expense.WRITE_LOGISTIC_PROCESS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_EXPENSE_ANSWER}
                    component={ExpenseAnswerAddEdit}
                    layout={MainLayout}
                    permission={permissions.expense.WRITE_RESPONSE_PROCESS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_EXPENSE_REQUEST_ORDER}
                    component={ExpenseRequestOrderAddEdit}
                    layout={MainLayout}
                    permission={permissions.expense.WRITE_ORDERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_EXPENSE_ACCORDANCE}
                    component={ExpenseAccordanceAddEdit}
                    layout={MainLayout}
                    permission={permissions.expense.WRITE_ACCORDANCE_PROCESS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_EXPENSE_INVOICE}
                    component={ExpenseInvoiceAddEdit}
                    layout={MainLayout}
                    permission={permissions.expense.WRITE_INVOICES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_EXPENSE_PAYMENT}
                    component={ExpensePaymentAddEdit}
                    layout={MainLayout}
                    permission={permissions.expense.WRITE_PAYMENTS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PAYMENT_LIST}
                    component={PaymentList}
                    layout={MainLayout}
                    permission={permissions.expense.READ_PAYMENTS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PAYMENT_DETAIL}
                    component={PaymentDetail}
                    layout={MainLayout}
                    permission={permissions.expense.READ_PAYMENTS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PAYMENT_CREATE}
                    component={PaymentCreate}
                    layout={MainLayout}
                    permission={permissions.expense.WRITE_PAYMENTS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_EXPENSE_DETAIL}
                    component={ExpenseDetail}
                    layout={MainLayout}
                    permission={permissions.expense.READ_EXPENSE_DETAIL}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_INVOICE_LIST}
                    component={InvoiceList}
                    layout={MainLayout}
                    permission={permissions.expense.READ_INVOICES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_INVOICE_DETAIL}
                    component={InvoiceDetail}
                    layout={MainLayout}
                    permission={permissions.expense.READ_INVOICES}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_INVOICE_CREATE}
                    component={InvoiceCreate}
                    layout={MainLayout}
                    permission={permissions.expense.WRITE_INVOICES}
                />

                <PrivateRoute
                    exact
                    path={paths.ADMIN_SHAREHOLDER_LIST}
                    component={ShareholderList}
                    layout={MainLayout}
                    permission={permissions.shareholder.READ_SHAREHOLDERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_SHAREHOLDER_DETAIL}
                    component={ShareholderDetail}
                    layout={MainLayout}
                    permission={permissions.shareholder.READ_SHAREHOLDERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_SHAREHOLDER_CREATE}
                    component={ShareholderAddEdit}
                    layout={MainLayout}
                    permission={permissions.shareholder.WRITE_SHAREHOLDERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_SHAREHOLDER_EDIT}
                    component={ShareholderAddEdit}
                    layout={MainLayout}
                    permission={permissions.shareholder.WRITE_SHAREHOLDERS}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PROPERTY_SHAREHOLDER_LIST}
                    component={PropertyShareholderList}
                    layout={MainLayout}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_SHAREHOLDER_COOWNER_LIST}
                    component={ShareholderCoOwnerList}
                    layout={MainLayout}
                />

                <PrivateRoute
                    exact
                    path={paths.REPORT_HOME}
                    component={ReportHome}
                    layout={MainLayout}
                    permission={permissions.report.READ_REPORTS}
                />
                <PrivateRoute
                    exact
                    path={paths.REPORT_INCOME_DETAILED_PENDING}
                    component={IncomeDetailedPendingReport}
                    layout={MainLayout}
                    permission={permissions.report.READ_INCOME_DETAILED_PENDING_REPORT}
                />
                <PrivateRoute
                    exact
                    path={paths.REPORT_INCOME_DETAILED_COMPLETED}
                    component={IncomeDetailedCompletedReport}
                    layout={MainLayout}
                    permission={permissions.report.READ_INCOME_DETAILED_COMPLETED_REPORT}
                />
                <PrivateRoute
                    exact
                    path={paths.REPORT_INCOME_DETAILED_ANULLED}
                    component={IncomeDetailedAnulledReport}
                    layout={MainLayout}
                    permission={permissions.report.READ_INCOME_DETAILED_ANULLED_REPORT}
                />
                <PrivateRoute
                    exact
                    path={paths.REPORT_INCOME_DETAILED_PENDING_PAYMENT}
                    component={IncomeDetailedPendingPaymentReport}
                    layout={MainLayout}
                    permission={permissions.report.READ_INCOME_DETAILED_PENDING_PAYMENT_REPORT}
                />

                <PrivateRoute
                    exact
                    path={paths.ADMIN_PETTY_CASH_LIST}
                    component={PettyCashList}
                    layout={MainLayout}
                    permission={permissions.pettyCash.READ_PETTY_CASH}
                />
                <PrivateRoute
                    exact
                    path={paths.ADMIN_PETTY_CASH_DETAIL}
                    component={PettyCashDetail}
                    layout={MainLayout}
                    permission={permissions.pettyCash.READ_PETTY_CASH}
                />

                <PrivateRoute
                    exact
                    path={paths.CONFIGURE_HOME}
                    component={ConfigureList}
                    layout={MainLayout}
                />

                <Route exact path="/403" component={Page403} />
                <Route component={Page404} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
