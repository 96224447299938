import React from 'react';
import { IProps } from './ExpenseRequestOrderAddEdit.types';
import { message, Divider, Row, Col, Button, Upload, Icon } from 'antd';
import { paths } from '../../../../routes';
import { SectionContainer, DisplayItem, DataTable } from '../../../../components';
import { expenseTypes } from '../../../../common/constants';
import { formatHelper } from '../../../../common/helpers';
import RequestOrderForm from './components/requestOrderForm';
import { IRequestOrderFormValues } from './components/requestOrderForm/RequestOrderForm';
import { Formik } from 'formik';
import { Columns } from './misc/columns';
import {
    IRequestOrderDetail,
    IRequestOrder,
    IExpenseDetail,
} from '../../../../common/types/entities';

interface IState {
    currencySelected: number;
    requestOrderDetailsToEdit: IRequestOrderDetail[];
    files: File[];
}

export default class ExpenseRequestOrderAddEdit extends React.Component<IProps> {
    private expenseId = this.props.match.params['id'];
    private form: Formik<IRequestOrderFormValues> = null;

    state: IState = {
        currencySelected: null,
        requestOrderDetailsToEdit: [],
        files: [],
    };

    componentDidMount() {
        this.props.getExpense(this.expenseId);
        this.props.getExpenseDetails(this.expenseId);
        this.props.getProviders();
        this.props.getCurrencies();
    }

    componentWillUnmount() {
        this.props.resetExpense();
        this.props.resetExpenseDetails();
        this.props.resetProviders();
        this.props.resetCurrencies();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkCreateRequestOrder(prevProps);
    }

    getExpenseStateId = (): number => {
        const { activeExpense } = this.props;
        return activeExpense ? activeExpense.state.id : 0;
    };

    setCurrencySelected = (currencyId: number) => {
        this.setState({ currencySelected: currencyId, requestOrderDetailsToEdit: [] });
    };

    addOrUpdateRequestOrderDetail = (
        expenseDetail: IExpenseDetail,
        quantity: number,
        totalPrice: number,
    ) => {
        let requestOrderDetail: IRequestOrderDetail = {
                name: expenseDetail.name,
                unitPrice: expenseDetail.unitPrice,
                quantity,
                totalPrice,
                comment: expenseDetail.comment,
                expenseDetail: {
                    id: expenseDetail.id,
                    totalQty: expenseDetail.totalQty || 0,
                },
            },
            requestOrderDetailsToEdit = this.state.requestOrderDetailsToEdit;

        const index = requestOrderDetailsToEdit.findIndex(
            e => e.expenseDetail.id == expenseDetail.id,
        );
        if (index == -1) requestOrderDetailsToEdit.push(requestOrderDetail);
        else requestOrderDetailsToEdit[index] = requestOrderDetail;

        this.setState({ requestOrderDetailsToEdit });
    };

    checkCreateRequestOrder(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.createRequestOrderSuccess && props.createRequestOrderSuccess) {
            message.success('Solicitud de compra completada');
            this.props.history.push(paths.ADMIN_EXPENSE_LIST);
        } else if (!prevProps.createRequestOrderError && props.createRequestOrderError)
            message.error(props.createRequestOrderError);
    }

    onSubmitRequestOrder = (values: IRequestOrderFormValues) => {
        const requestOrder: IRequestOrder = {
            comment: values.comment,
            startDate: values.startDate.toString(),
            endDate: values.endDate.toString(),
            provider: { id: Number(values.provider.id) },
            currency: { id: Number(values.currency.id) },
            expense: { id: Number(this.expenseId) },
            requestOrderDetails: this.state.requestOrderDetailsToEdit,
        };

        const files = this.state.files;
        if (files.length > 0) requestOrder.files = files;

        this.props.createRequestOrder(requestOrder);
    };

    renderContent = () => {
        const {
                activeExpense,
                activeExpenseLoading,
                expenseDetails,
                expenseDetailsLoading,
                providers,
                providersLoading,
                createRequestOrderLoading,
                currencies,
                currenciesLoading,
            } = this.props,
            stateId = activeExpense.state.id,
            isGoodsType = activeExpense.type.id == expenseTypes.GOODS;

        return (
            <>
                <DisplayItem
                    label="Código"
                    value={activeExpense.code}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Estado"
                    value={activeExpense.state.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Responsables</Divider>
                <DisplayItem
                    label="Solicitante"
                    value={formatHelper.getUserFullname(activeExpense.requestUser)}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Respuesta"
                    value={formatHelper.getUserFullname(activeExpense.responseUser)}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Información</Divider>
                <DisplayItem
                    label="Tipo"
                    value={activeExpense.type.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Motivo"
                    value={activeExpense.reason}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <RequestOrderForm
                    onSubmit={this.onSubmitRequestOrder}
                    disabled={activeExpenseLoading}
                    hideFooter
                    stateId={stateId}
                    formRef={ref => (this.form = ref)}
                    providers={providers}
                    providersLoading={providersLoading}
                    currencies={currencies}
                    currenciesLoading={currenciesLoading}
                    setCurrencySelected={this.setCurrencySelected}
                />
                <DisplayItem
                    label="Precio Total (PEN)"
                    type="money"
                    value={activeExpense.totalPrice}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Precio Total (USD)"
                    type="money"
                    value={activeExpense.totalPriceUSD}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left" className="mt-5">
                    {isGoodsType ? 'Bienes' : 'Servicios'}
                </Divider>
                <DataTable
                    data={expenseDetails}
                    columns={Columns(
                        this.addOrUpdateRequestOrderDetail,
                        this.state.requestOrderDetailsToEdit,
                        this.state.currencySelected,
                    )}
                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                    loading={expenseDetailsLoading}
                />
                <Divider orientation="left" className="mt-5">
                    Archivos
                </Divider>
                <Row>
                    <Col lg={12} md={12} xs={24} sm={24}>
                        <Upload
                            accept=".pdf, .csv, .xlsx, .xls, .doc, .docx, .png, .jpg"
                            onChange={info => {
                                this.setState({
                                    files: info.fileList.map(item => item['originFileObj']),
                                });
                            }}
                            // stange behavior.... https://stackoverflow.com/a/56125617
                            beforeUpload={() => false}
                        >
                            <Button>
                                <Icon type="upload" /> Subir Archivos
                            </Button>
                        </Upload>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Button
                            type="primary"
                            icon="schedule"
                            onClick={() => this.form.submitForm()}
                            loading={createRequestOrderLoading}
                        >
                            Solicitar orden de compra
                        </Button>
                    </Col>
                </Row>
            </>
        );
    };

    render() {
        const { activeExpense, activeExpenseLoading, activeExpenseError } = this.props;

        return (
            <SectionContainer
                title={'Solicitar orden de compra'}
                onBack={() => this.props.history.push(paths.ADMIN_EXPENSE_LIST)}
                loading={activeExpenseLoading || !activeExpense}
                error={activeExpenseError}
                content={this.renderContent}
            />
        );
    }
}
