import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    processStateLoading,
    processStateSuccess,
    processStateFailure,
} from '../../common/helpers/reducer';

//#region SET USERS
export function getUsers(state: IState): IState {
    return {
        ...state,
        userList: { ...state.userList, loading: true, error: null },
    };
}

export function getUsersSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        userList: {
            data: action.payload.data,
            total: action.payload.total,
            pageSize: action.payload.pageSize,
            loading: false,
            error: null,
        },
    };
}

export function getUsersFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        userList: {
            ...initialState.userList,
            error: action.payload.error,
        },
    };
}

export function getUsersReset(state: IState): IState {
    return {
        ...state,
        userList: initialState.userList,
    };
}
//#endregion

//#region GET USER
export function getUser(state: IState): IState {
    return {
        ...state,
        activeUser: { value: undefined, loading: true, error: undefined },
    };
}

export function getUserSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activeUser: { value: action.payload, loading: false, error: undefined },
    };
}

export function getUserFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activeUser: { value: undefined, loading: false, error: action.payload.error },
    };
}

export function getUserReset(state: IState): IState {
    return {
        ...state,
        activeUser: initialState.activeUser,
    };
}
//#endregion

//#region CREATE USER
export function createUser(state: IState): IState {
    return {
        ...state,
        createUser: { success: false, loading: true, error: undefined },
    };
}

export function createUserSuccess(state: IState): IState {
    return {
        ...state,
        createUser: { success: true, loading: false, error: undefined },
    };
}

export function createUserFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createUser: { success: false, loading: false, error: action.payload.error },
    };
}

export function createUserReset(state: IState): IState {
    return {
        ...state,
        createUser: initialState.createUser,
    };
}
//#endregion

//#region UPDATE USER
export function updateUser(state: IState): IState {
    return {
        ...state,
        updateUser: { success: false, loading: true, error: undefined },
    };
}

export function updateUserSuccess(state: IState): IState {
    return {
        ...state,
        updateUser: { success: true, loading: false, error: undefined },
    };
}

export function updateUserFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateUser: { success: false, loading: false, error: action.payload.error },
    };
}

export function updateUserReset(state: IState): IState {
    return {
        ...state,
        updateUser: initialState.updateUser,
    };
}
//#endregion

//#region DEACTIVATE USER
export function deactivateUser(state: IState): IState {
    return {
        ...state,
        deactivateUser: { success: false, loading: true, error: undefined },
    };
}

export function deactivateUserSuccess(state: IState): IState {
    return {
        ...state,
        deactivateUser: { success: true, loading: false, error: undefined },
    };
}

export function deactivateUserFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateUser: { success: false, loading: false, error: action.payload.error },
    };
}

export function deactivateUserReset(state: IState): IState {
    return {
        ...state,
        deactivateUser: initialState.deactivateUser,
    };
}
//#endregion

//#region SET ROLES
export function getRoles(state: IState): IState {
    return {
        ...state,
        roleList: { data: [], loading: true, error: undefined },
    };
}

export function getRolesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        roleList: { data: action.payload.data, loading: false, error: undefined },
    };
}

export function getRolesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        roleList: { data: undefined, loading: false, error: action.payload.error },
    };
}

export function getRolesReset(state: IState): IState {
    return {
        ...state,
        roleList: initialState.roleList,
    };
}
//#endregion

//#region UPDATE AUTHORIZATION TOKEN
export function updateAuthorizationToken(state: IState): IState {
    return {
        ...state,
        updateAuthorizationToken: processStateLoading(),
    };
}

export function updateAuthorizationTokenSuccess(state: IState): IState {
    return {
        ...state,
        updateAuthorizationToken: processStateSuccess(),
    };
}

export function updateAuthorizationTokenFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateAuthorizationToken: processStateFailure(action.payload.error),
    };
}

export function updateAuthorizationTokenReset(state: IState): IState {
    return {
        ...state,
        updateAuthorizationToken: initialState.updateAuthorizationToken,
    };
}
//#endregion
