const actionTypes = {
  GET_PROVIDERS: 'provider/GET_PROVIDERS',
  GET_PROVIDERS_SUCCESS: 'provider/GET_PROVIDERS_SUCCESS',
  GET_PROVIDERS_FAILURE: 'provider/GET_PROVIDERS_FAILURE',
  GET_PROVIDERS_RESET: 'provider/GET_PROVIDERS_RESET',

  GET_PROVIDER: 'provider/GET_PROVIDER',
  GET_PROVIDER_SUCCESS: 'provider/GET_PROVIDER_SUCCESS',
  GET_PROVIDER_FAILURE: 'provider/GET_PROVIDER_FAILURE',
  GET_PROVIDER_RESET: 'provider/GET_PROVIDER_RESET',

  CREATE_PROVIDER: 'provider/CREATE_PROVIDER',
  CREATE_PROVIDER_SUCCESS: 'provider/CREATE_PROVIDER_SUCCESS',
  CREATE_PROVIDER_FAILURE: 'provider/CREATE_PROVIDER_FAILURE',
  CREATE_PROVIDER_RESET: 'provider/CREATE_PROVIDER_RESET',

  UPDATE_PROVIDER: 'provider/UPDATE_PROVIDER',
  UPDATE_PROVIDER_SUCCESS: 'provider/UPDATE_PROVIDER_SUCCESS',
  UPDATE_PROVIDER_FAILURE: 'provider/UPDATE_PROVIDER_FAILURE',
  UPDATE_PROVIDER_RESET: 'provider/UPDATE_PROVIDER_RESET',

  DEACTIVATE_PROVIDER: 'provider/DEACTIVATE_PROVIDER',
  DEACTIVATE_PROVIDER_SUCCESS: 'provider/DEACTIVATE_PROVIDER_SUCCESS',
  DEACTIVATE_PROVIDER_FAILURE: 'provider/DEACTIVATE_PROVIDER_FAILURE',
  DEACTIVATE_PROVIDER_RESET: 'provider/DEACTIVATE_PROVIDER_RESET',
};

export default actionTypes;