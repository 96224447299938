import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './IncomeListByProperties.types';
import IncomeListByProperties from './IncomeListByProperties';
import { IRootState } from '../../../../../../common/types';
import {
    getIncomesByProperties,
    resetIncomesByProperties,
    getIncomeStates,
    resetIncomeStates,
} from '../../../../../../store/income/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { incomeByPropertiesList, incomeStateList } = state.income;

    return {
        incomes: incomeByPropertiesList.data,
        total: incomeByPropertiesList.total,
        pageSize: incomeByPropertiesList.pageSize,
        loading: incomeByPropertiesList.loading,
        error: incomeByPropertiesList.error,
        incomeStates: incomeStateList.data,
        incomeStatesLoading: incomeStateList.loading,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getIncomesByProperties,
            resetIncomesByProperties,
            getIncomeStates,
            resetIncomeStates,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomeListByProperties);
