import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import ExpenseAccordanceAddEdit from './ExpenseAccordanceAddEdit';
import {
  resetExpense,
  getExpense,
} from '../../../../store/expense/actions';
import { accordingRequestOrder } from '../../../../store/requestOrder/actions';
import {
  getRequestOdersByExpense,
  resetRequestOrdersByExpense,
} from '../../../../store/requestOrder/actions'
import { IRootState } from '../../../../common/types';
import { IStoreState } from './ExpenseAccordanceAddEdit.types';

function mapStateToProps(state: IRootState): IStoreState {
  const
    {
      activeExpense,
    } = state.expense,
    {
      accordingRequestOrder,
      requestOrderByExpenseList,
    } = state.requestOrder;

  return {
    activeExpense: activeExpense.value,
    activeExpenseLoading: activeExpense.loading,
    activeExpenseError: activeExpense.error,
    accordingRequestOrderSuccess: accordingRequestOrder.success,
    accordingRequestOrderLoading: accordingRequestOrder.loading,
    accordingRequestOrderError: accordingRequestOrder.error,
    requestOrdersByExpense: requestOrderByExpenseList.data,
    requestOrdersByExpenseLoading: requestOrderByExpenseList.loading,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    getExpense,
    getRequestOdersByExpense,
    accordingRequestOrder,
    resetExpense,
    resetRequestOrdersByExpense,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseAccordanceAddEdit)