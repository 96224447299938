import * as React from 'react';
import { FormikActions } from 'formik';
import { CustomForm, InputGroupField } from '../../../../../../components';
import { IProvider } from '../../../../../../common/types/entities';
import { messages } from '../../../../../../common/constants';
import { isEmpty } from 'validator';
import { Divider, Icon, message } from 'antd';
import { getRucInfo, resetRucInfo } from '../../../../../../store/commonRequest/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IRootState, IRucInfo } from '../../../../../../common/types';

export interface IDispatchProps {
    getRucInfo: (ruc: string) => void;
    resetRucInfo: () => void;
}

export interface IStoreState {
    rucInfo: IRucInfo;
    rucInfoLoading: boolean;
    rucInfoError?: string;
}
interface IOwnProps {
    initialValues: IProvider;
}

type IProps = IOwnProps & IDispatchProps & IStoreState;

class ProviderForm extends CustomForm<IProvider, IProps> {
    initialValues = (props: IProps) => {
        const { initialValues: init = {} } = props;
        return {
            dni: init.dni || '',
            ce: init.ce || '',
            ruc: init.ruc || '',
            businessName: init.businessName || '',
            email: init.email || '',
            phone: init.phone || '',
            address: init.address || '',
        } as IProvider;
    };

    componentDidUpdate(prevProps: IProps) {
        this.checkRucInfo(prevProps);
    }

    checkRucInfo(prevProps: IProps) {
        const { props } = this;
        if (prevProps.rucInfo != props.rucInfo && props.rucInfo != null) {
            this.form.setFieldValue('businessName', props.rucInfo.businessName);
            this.form.setFieldValue('address', props.rucInfo.fullAddress);
        } else if (!prevProps.rucInfoError && props.rucInfoError) message.error(props.rucInfoError);
    }

    validate = (values: IProvider) => {
        const errors = {} as Partial<IProvider>;

        if (isEmpty(values.ruc)) errors.ruc = messages.MANDATORY_FIELD;

        if (!isEmpty(values.ruc) && values.ruc.length !== 11) errors.ruc = 'RUC inválido';

        return errors;
    };

    onSubmit = (values: IProvider, actions: FormikActions<IProvider>) => {
        values.dni = '';
        values.ce = '';

        this.props.onSubmit(values);
        actions.setSubmitting(false);
    };

    onCancel = () => {
        this.props.onCancel();
    };

    personDocIsRequired(dni: string, ce: string, ptp: string) {
        return isEmpty(dni) && isEmpty(ce) && isEmpty(ptp);
    }

    getRucInfo = () => {
        const { rucInfoLoading, getRucInfo } = this.props;
        if (rucInfoLoading || !this.form.state.values.ruc) return;
        getRucInfo(this.form.state.values.ruc);
    };

    formBody = () => (
        <div>
            <InputGroupField
                name="ruc"
                label="RUC"
                maxLength={11}
                required={true}
                suffix={
                    <>
                        <Divider type="vertical" />
                        <Icon
                            type={this.props.rucInfoLoading ? 'loading' : 'search'}
                            style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
                            onClick={this.getRucInfo}
                        />
                    </>
                }
            />
            <InputGroupField name="businessName" label="Razón Social" />
            <InputGroupField name="email" label="Email" />
            <InputGroupField name="phone" label="Teléfono" />
            <InputGroupField name="address" label="Dirección" />
        </div>
    );
}

function mapStateToProps(state: IRootState): IStoreState {
    const { rucInfo } = state.commonRequest;

    return {
        rucInfo: rucInfo.value,
        rucInfoLoading: rucInfo.loading,
        rucInfoError: rucInfo.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getRucInfo,
            resetRucInfo,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderForm);
