import React from 'react';
import { FormikActions } from 'formik';
import {
    CustomForm,
    InputGroupField,
    DatePickerGroupField,
    SwitchGroupField,
    SelectGroupField,
    DisplayItem,
} from '../../../../../../components';
import { messages, currencyTypes } from '../../../../../../common/constants';
import moment from 'moment';
import {
    IBankAccount,
    IPaymentType,
    ICurrency,
    IRequestOrder,
} from '../../../../../../common/types/entities';

interface IOwnProps {
    paymentTypes: IPaymentType[];
    paymentTypesLoading: boolean;
    bankAccounts: IBankAccount[];
    bankAccountsLoading: boolean;
    bankAccountCurrency: ICurrency;
    setBankAccountCurrency: (value: any) => void;
    requestOrderSelected: IRequestOrder;
    setExchangeRate: (value: any) => void;
    resetItems: () => void;
}

interface IOwnState {
    paid: boolean;
}

type IProps = IOwnProps;

export interface IInvoiceFormValues {
    paymentDate?: Date | string;
    paymentType?: IPaymentType | string;
    bankAccount?: IBankAccount | string;
    code: string;
    emissionDate: Date | string;
    dueDate: Date | string;
    paid: boolean;
    exchangeRate: number | string;
}

export default class InvoiceForm extends CustomForm<IInvoiceFormValues, IProps> {
    state: IOwnState = {
        paid: false,
    };

    initialValues = (): IInvoiceFormValues => {
        return {
            paymentDate: undefined,
            paymentType: undefined,
            bankAccount: undefined,
            code: undefined,
            emissionDate: undefined,
            dueDate: undefined,
            paid: false,
            exchangeRate: undefined,
        };
    };

    onSubmit = (values: IInvoiceFormValues, actions: FormikActions<IInvoiceFormValues>) => {
        this.props.onSubmit(values);
        actions.setSubmitting(false);
    };

    validate = (values: IInvoiceFormValues) => {
        const errors: Partial<IInvoiceFormValues> = {};
        const { bankAccountCurrency, requestOrderSelected } = this.props;

        if (!values.code) errors.code = messages.MANDATORY_FIELD;
        if (!values.emissionDate) errors.emissionDate = messages.MANDATORY_FIELD;
        if (!values.paid) {
            if (!values.dueDate) errors.dueDate = messages.MANDATORY_FIELD;
            if (moment(values.emissionDate) > moment(values.dueDate))
                errors.emissionDate =
                    'La Fecha de emisión no puede ser menor a la Fecha de vencimiento';
        } else {
            if (!values.paymentDate) errors.paymentDate = messages.MANDATORY_FIELD;
            if (!values.paymentType) errors.paymentType = messages.MANDATORY_FIELD;
            if (!values.bankAccount) errors.bankAccount = messages.MANDATORY_FIELD;
            if (!values.exchangeRate && bankAccountCurrency.id != requestOrderSelected.currency.id)
                errors.exchangeRate = messages.MANDATORY_FIELD;
        }

        return errors;
    };

    onCancel = () => this.props.onCancel();

    formBody = () => {
        const {
            bankAccounts,
            bankAccountsLoading,
            paymentTypes,
            paymentTypesLoading,
            bankAccountCurrency,
            setBankAccountCurrency,
            requestOrderSelected,
            setExchangeRate,
            resetItems,
        } = this.props;

        return (
            <>
                <InputGroupField name="code" label="Código" required />
                <DatePickerGroupField name="emissionDate" label="Fecha de emisión" required />
                <SwitchGroupField
                    name="paid"
                    label="Pagado"
                    onChange={(checked, event) => {
                        this.setState({ paid: !this.state.paid });
                    }}
                />
                {!this.state.paid ? (
                    <DatePickerGroupField
                        name="dueDate"
                        label="Fecha de vencimiento"
                        required={!this.state.paid}
                    />
                ) : (
                    <>
                        <SelectGroupField
                            name="bankAccount"
                            label="Cuenta Bancaria"
                            data={bankAccounts}
                            displayText={(item: IBankAccount) =>
                                item.bank.name + ' - Nº ' + item.number
                            }
                            loading={bankAccountsLoading}
                            onChange={(e: any) => {
                                setBankAccountCurrency(e);
                                resetItems();
                            }}
                            required
                        />
                        <DisplayItem
                            label="Moneda de la Cuenta Bancaria"
                            value={
                                bankAccountCurrency
                                    ? bankAccountCurrency.id == currencyTypes.PEN
                                        ? 'PEN'
                                        : 'USD'
                                    : ''
                            }
                            lmd={4}
                            llg={3}
                            lxl={2}
                            lxxl={2}
                        />
                        {bankAccountCurrency &&
                            requestOrderSelected &&
                            bankAccountCurrency.id != requestOrderSelected.currency.id && (
                                <InputGroupField
                                    name="exchangeRate"
                                    label="Tipo de Cambio"
                                    onChange={(e: any) => setExchangeRate(e.target.value)}
                                    required
                                />
                            )}
                        <SelectGroupField
                            name="paymentType"
                            label="Tipo de Pago"
                            data={paymentTypes}
                            displayText="description"
                            loading={paymentTypesLoading}
                            required
                        />
                        <DatePickerGroupField name="paymentDate" label="Fecha de Pago" required />
                    </>
                )}
            </>
        );
    };
}
