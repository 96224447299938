import React, { FC } from 'react';
import { GroupFieldWrapper } from '../..';
import RadioField from './RadioField';
import { IGroupFieldWrapperProps } from '../groupFieldWrapper/GroupFieldWrapper';
import { IRadioFieldProps } from './RadioField';

type IProps = IRadioFieldProps & IGroupFieldWrapperProps;

const InputGroupField: FC<IProps> = ({
    inputType,
    label,
    required,
    noRow,
    hidden,
    lxs,
    lsm,
    lmd,
    llg,
    lxl,
    lxxl,
    ixs,
    ism,
    imd,
    ilg,
    ixl,
    ixxl,
    ...rest
}) => {
    return (
        <GroupFieldWrapper
            label={label}
            noRow={noRow}
            required={required}
            hidden={hidden}
            lxs={lxs}
            lsm={lsm}
            lmd={lmd}
            llg={llg}
            lxl={lxl}
            lxxl={lxxl}
            ixs={ixs}
            ism={ism}
            imd={imd}
            ilg={ilg}
            ixl={ixl}
            ixxl={ixxl}
        >
            <RadioField {...rest} />
        </GroupFieldWrapper>
    );
};

export default InputGroupField;
