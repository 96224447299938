import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types'
import { IRequestOrder } from '../../common/types/entities/requestOrder';
import { requestOrderService } from '../../service';

export function getRequestOdersByExpense(id: number): IAPIMiddleware {
  return {
    callAPI: () => requestOrderService.getRequestOdersByExpense(id),
    types: [
      actionTypes.GET_REQUEST_ORDERS_BY_EXPENSE,
      actionTypes.GET_REQUEST_ORDERS_BY_EXPENSE_SUCCESS,
      actionTypes.GET_REQUEST_ORDERS_BY_EXPENSE_FAILURE,
    ]
  }
}

export function resetRequestOrdersByExpense() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.GET_REQUEST_ORDERS_BY_EXPENSE_RESET
    })
  }
}

export function createRequestOrder(data: IRequestOrder): IAPIMiddleware {
  return {
    callAPI: () => requestOrderService.create(data),
    types: [
      actionTypes.CREATE_REQUEST_ORDER,
      actionTypes.CREATE_REQUEST_ORDER_SUCCESS,
      actionTypes.CREATE_REQUEST_ORDER_FAILURE,
    ],
  }
}

export function accordingRequestOrder(requestOrderId: number, data: any): IAPIMiddleware {
  return {
    callAPI: () => requestOrderService.accordingRequestOrder(requestOrderId, data),
    types: [
      actionTypes.ACCORDING_REQUEST_ORDER,
      actionTypes.ACCORDING_REQUEST_ORDER_SUCCESS,
      actionTypes.ACCORDING_REQUEST_ORDER_FAILURE,
    ],
  };
}

export function resetCreateRequestOrder() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.CREATE_REQUEST_ORDER_RESET
    })
  }
}

export function getRequestOrderDetails(id: number): IAPIMiddleware {
  return {
    callAPI: () => requestOrderService.getExpenseDetails(id),
    types: [
      actionTypes.GET_REQUEST_ORDER_DETAILS,
      actionTypes.GET_REQUEST_ORDER_DETAILS_SUCCESS,
      actionTypes.GET_REQUEST_ORDER_DETAILS_FAILURE,
    ],
  };
}

export function resetRequestOrderDetails() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.GET_REQUEST_ORDER_DETAILS_RESET
    })
  }
}