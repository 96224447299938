import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { pettyCashService } from '../../service';
import { IPettyCash } from '../../common/types/entities';

export function getPettyCashList(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => pettyCashService.getAll(filter, page),
        types: [
            actionTypes.GET_PETTY_CASH_LIST,
            actionTypes.GET_PETTY_CASH_LIST_SUCCESS,
            actionTypes.GET_PETTY_CASH_LIST_FAILURE,
        ],
    };
}

export function resetPettyCashList() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PETTY_CASH_LIST_RESET,
        });
    };
}

export function getPettyCash(id: number): IAPIMiddleware {
    return {
        callAPI: () => pettyCashService.get(id),
        types: [
            actionTypes.GET_PETTY_CASH,
            actionTypes.GET_PETTY_CASH_SUCCESS,
            actionTypes.GET_PETTY_CASH_FAILURE,
        ],
    };
}

export function resetPettyCash() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PETTY_CASH_RESET,
        });
    };
}

export function getActivePettyCash(): IAPIMiddleware {
    return {
        callAPI: () => pettyCashService.getActive(),
        types: [
            actionTypes.GET_PETTY_CASH_TO_CLOSE,
            actionTypes.GET_PETTY_CASH_TO_CLOSE_SUCCESS,
            actionTypes.GET_PETTY_CASH_TO_CLOSE_FAILURE,
        ],
    };
}

export function resetActivePettyCash() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PETTY_CASH_TO_CLOSE_RESET,
        });
    };
}

export function createPettyCash(data: IPettyCash): IAPIMiddleware {
    return {
        callAPI: () => pettyCashService.create(data),
        types: [
            actionTypes.CREATE_PETTY_CASH,
            actionTypes.CREATE_PETTY_CASH_SUCCESS,
            actionTypes.CREATE_PETTY_CASH_FAILURE,
        ],
    };
}

export function resetCreatePettyCash() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_PETTY_CASH_RESET,
        });
    };
}

export function getPettyCashMovements(
    id: number,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => pettyCashService.getMovements(id, filter, page),
        types: [
            actionTypes.GET_PETTY_CASH_MOVEMENTS,
            actionTypes.GET_PETTY_CASH_MOVEMENTS_SUCCESS,
            actionTypes.GET_PETTY_CASH_MOVEMENTS_FAILURE,
        ],
    };
}

export function resetPettyCashMovements() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PETTY_CASH_MOVEMENTS_RESET,
        });
    };
}

export function getPettyCashMovementItems(
    movementId: number,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => pettyCashService.getMovementItems(movementId, filter, page),
        types: [
            actionTypes.GET_PETTY_CASH_MOVEMENTS_ITEMS,
            actionTypes.GET_PETTY_CASH_MOVEMENTS_ITEMS_SUCCESS,
            actionTypes.GET_PETTY_CASH_MOVEMENTS_ITEMS_FAILURE,
        ],
    };
}

export function resetPettyCashMovementItems() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PETTY_CASH_MOVEMENTS_ITEMS_RESET,
        });
    };
}

export function createPettyCashMovement(id: number, data: any): IAPIMiddleware {
    return {
        callAPI: () => pettyCashService.createMovement(id, data),
        types: [
            actionTypes.CREATE_PETTY_CASH_MOVEMENT,
            actionTypes.CREATE_PETTY_CASH_MOVEMENT_SUCCESS,
            actionTypes.CREATE_PETTY_CASH_MOVEMENT_FAILURE,
        ],
    };
}

export function resetCreatePettyCashMovement() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_PETTY_CASH_MOVEMENT_RESET,
        });
    };
}

export function deletePettyCashMovement(pettyCashId: number, movementId: number): IAPIMiddleware {
    return {
        callAPI: () => pettyCashService.deleteMovement(pettyCashId, movementId),
        types: [
            actionTypes.DELETE_PETTY_CASH_MOVEMENT,
            actionTypes.DELETE_PETTY_CASH_MOVEMENT_SUCCESS,
            actionTypes.DELETE_PETTY_CASH_MOVEMENT_FAILURE,
        ],
    };
}

export function resetDeletePettyCashMovement() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_PETTY_CASH_MOVEMENT_RESET,
        });
    };
}
