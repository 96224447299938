import React, { FC, useRef, useEffect, useState } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal } from 'antd';
import { isEmpty, isNumeric } from 'validator';
import { InputGroupField, DisplayItem } from '../../../../../../components';
import { messages } from '../../../../../../common/constants';

export interface IPettyCashMovementItemAddForm {
    description: string;
    quantity: string | number;
    unitPrice: string | number;
    totalPrice?: number;
}

type IProps = {
    isOpen: boolean;
    onSubmit: (values: IPettyCashMovementItemAddForm) => void;
    disabled?: boolean;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    submitting?: boolean;
};

const sharedFieldProps = {
    lxs: 24,
    lsm: 24,
    lmd: 24,
    llg: 24,
    lxl: 8,
    lxxl: 6,
    imd: 24,
    ixl: 16,
    ilg: 24,
};

const PettyCashMovementItemAddModal: FC<IProps> = (props) => {
    let form = useRef<Formik<IPettyCashMovementItemAddForm>>(null);

    const [totalPrice, setTotalPrice] = useState(0);

    const onSubmit = (
        values: IPettyCashMovementItemAddForm,
        actions: FormikActions<IPettyCashMovementItemAddForm>,
    ) => {
        values.quantity = Number(values.quantity);
        values.unitPrice = Number(values.unitPrice);
        values.totalPrice = totalPrice;

        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = (e) => {
        form.current.resetForm();
        props.onCancel(e);
    };

    useEffect(() => {
        if (!props.isOpen) return;

        return () => setTotalPrice(0);
    }, [props.isOpen]);

    const onChangeQuantity = (e) => {
        const quantity = Number(e.target.value);
        const unitPrice = Number(form.current.state.values.unitPrice);
        setTotalPrice(quantity * unitPrice);
    };

    const onChangeUnitPrice = (e) => {
        const unitPrice = Number(e.target.value);
        const quantity = Number(form.current.state.values.quantity);
        setTotalPrice(quantity * unitPrice);
    };

    const validate = (values: IPettyCashMovementItemAddForm) => {
        let errors: Partial<IPettyCashMovementItemAddForm> = {};

        if (isEmpty(values.description)) errors.description = messages.MANDATORY_FIELD;
        if (isEmpty(values.quantity as string)) errors.quantity = messages.MANDATORY_FIELD;
        else if (!isNumeric(values.quantity as string))
            errors.quantity = 'La cantidad debe ser un número';
        else if (Number(values.quantity) < 1)
            errors.quantity = 'La cantidad tiene que ser mayor a cero';

        if (isEmpty(values.unitPrice as string)) errors.unitPrice = messages.MANDATORY_FIELD;
        else if (!isNumeric(values.unitPrice as string))
            errors.unitPrice = 'El precio debe ser un número';
        else if (Number(values.unitPrice) <= 0)
            errors.quantity = 'El precio tiene que ser mayor a cero';

        return errors;
    };

    const onOk = () => {
        form.current.submitForm();
    };

    const renderForm = () => {
        if (!props.isOpen) return null;

        return (
            <Formik
                initialValues={{
                    description: '',
                    quantity: '1',
                    unitPrice: '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={({ values }: FormikProps<IPettyCashMovementItemAddForm>) => (
                    <Form>
                        <InputGroupField
                            name="description"
                            label="Descripción"
                            required
                            {...sharedFieldProps}
                        />
                        <InputGroupField
                            name="quantity"
                            label="Cantidad"
                            normalize="number"
                            required
                            onChange={onChangeQuantity}
                            {...sharedFieldProps}
                        />
                        <InputGroupField
                            name="unitPrice"
                            label="Precio Unitario"
                            normalize="numbersWithTwoDecimals"
                            required
                            onChange={onChangeUnitPrice}
                            {...sharedFieldProps}
                        />
                        <DisplayItem
                            label="Precio Total"
                            value={totalPrice}
                            type="money"
                            {...sharedFieldProps}
                        />
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            title="Agregar Item"
            visible={props.isOpen}
            okText="Guardar"
            onOk={onOk}
            okButtonProps={{ loading: props.submitting }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.submitting }}
            maskClosable={false}
        >
            {renderForm()}
        </Modal>
    );
};

export default PettyCashMovementItemAddModal;
