import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import PettyCashMovementItemListModal from './PettyCashMovementItemListModal.view';
import { IRootState } from '../../../../../../common/types';
import {
    getPettyCashMovementItems,
    resetPettyCashMovementItems,
} from '../../../../../../store/pettyCash/actions';
import { IStoreState } from './PettyCashMovementItemListModal.types';

function mapStateToProps(state: IRootState): IStoreState {
    const { pettyCashMovementItemList } = state.pettyCash;

    return {
        items: pettyCashMovementItemList.data,
        loading: pettyCashMovementItemList.loading,
        error: pettyCashMovementItemList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getPettyCashMovementItems,
            resetPettyCashMovementItems,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PettyCashMovementItemListModal);
