import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import {
    getPettyCash,
    resetPettyCash,
    getPettyCashMovements,
    resetPettyCashMovements,
    createPettyCashMovement,
    deletePettyCashMovement,
} from '../../../../store/pettyCash/actions';
import { IStoreState } from './PettyCashDetail.types';
import PettyCashDetail from './PettyCashDetail.view';

function mapStateToProps(state: IRootState): IStoreState {
    const {
        activePettyCash,
        pettyCashMovementList,
        createPettyCashMovement,
        deletePettyCashMovement,
    } = state.pettyCash;

    return {
        pettyCash: activePettyCash.value,
        loading: activePettyCash.loading,
        error: activePettyCash.error,
        movements: pettyCashMovementList.data,
        movementsPageSize: pettyCashMovementList.pageSize,
        movementsTotal: pettyCashMovementList.total,
        movementsLoading: pettyCashMovementList.loading,
        movementsError: pettyCashMovementList.error,
        createMovementLoading: createPettyCashMovement.loading,
        createMovementSuccess: createPettyCashMovement.success,
        createMovementError: createPettyCashMovement.error,
        deleteMovementSuccess: deletePettyCashMovement.success,
        deleteMovementLoading: deletePettyCashMovement.loading,
        deleteMovementError: deletePettyCashMovement.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getPettyCash,
            resetPettyCash,
            getPettyCashMovements,
            resetPettyCashMovements,
            createPettyCashMovement,
            deletePettyCashMovement,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PettyCashDetail);
