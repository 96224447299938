import React from 'react';
import { Button, Row, Col, message, Modal, Select, Input } from 'antd';
import { IProps, IState } from './PaymentList.types';
import { SectionContainer, DataTable } from '../../../../components';
import { paths } from '../../../../routes';
import { columns } from './misc/columns';
import { helper, formatHelper } from '../../../../common/helpers';
import { IPayment } from '../../../../common/types/entities';
import DeactivatePaymentModal, {
    IDeactivatePaymentForm,
} from './components/DeactivatePaymentModal';

export default class ExpenseRequestOrderAddEdit extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        providerId: null,
        modalDeactivatePaymentVisible: false,
        activeIdToDeactivate: null,
    };

    componentDidMount() {
        this.props.getProviders();
        this.performGetPayments();
    }

    componentWillUnmount() {
        this.props.resetProviders();
        this.props.resetPaymentsByProvider();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkPaymentDeactivate(prevProps);
    }

    performGetPayments() {
        const { providerId, filter, page } = this.state;
        this.props.getPaymentsByProvider(providerId, filter, page);
    }

    async filterPayments() {
        await this.setState({ page: 1 });
        this.performGetPayments();
    }

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetPayments();
    };

    async checkPaymentDeactivate(prevProps: IProps) {
        if (!prevProps.deactivatePaymentSuccess && this.props.deactivatePaymentSuccess) {
            message.success('Pago anulado');
            await this.setState({ page: 1 });
            this.toggleModalDeactivatePaymentVisible();

            const { providerId, filter, page } = this.state;
            this.props.getPaymentsByProvider(providerId, filter, page);
        } else if (!prevProps.deactivatePaymentError && this.props.deactivatePaymentError)
            message.error(this.props.deactivatePaymentError);
    }

    onChangeProvider = async (providerId: number) => {
        await this.setState({ page: 1, providerId });
        this.performGetPayments();
    };

    detailPayment = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_PAYMENT_DETAIL, { id }));
    };

    toggleModalDeactivatePaymentVisible = () => {
        this.setState({ modalDeactivatePaymentVisible: !this.state.modalDeactivatePaymentVisible });
    };

    deactivatePayment = (id: number) => {
        this.toggleModalDeactivatePaymentVisible();
        this.setState({ activeIdToDeactivate: id });
    };

    onClickDeactivatePayment = (values: IDeactivatePaymentForm) => {
        const payment: IPayment = {
            reasonToDelete: values.reasonToDeactivate,
        };

        this.props.deactivatePayment(this.state.activeIdToDeactivate, payment);
    };

    render() {
        const {
            providers,
            providersLoading,
            paymentsByProvider,
            paymentsByProviderLoading,
            pageSize,
            total,
        } = this.props;

        return (
            <>
                <SectionContainer title="Pagos" icon="dollar">
                    <Row className="mb-4">
                        <Col md={12} lg={6}>
                            <Input.Search
                                placeholder="Buscar"
                                enterButton
                                value={this.state.filter}
                                onChange={(e) => this.setState({ filter: e.target.value })}
                                onSearch={() => this.filterPayments()}
                            />
                        </Col>
                        <Col className="ml-md-3 ml-lg-4" md={11} lg={6}>
                            <Row>
                                <Col className="mt-2 mt-md-1" md={5} lg={6}>
                                    Proveedor:
                                </Col>
                                <Col md={18} lg={18}>
                                    {providers && (
                                        <Select
                                            className="w-100"
                                            value={this.state.providerId}
                                            onChange={this.onChangeProvider}
                                            loading={providersLoading}
                                            allowClear={true}
                                        >
                                            {providers.map((provider, index) => (
                                                <Select.Option key={index} value={provider.id}>
                                                    {formatHelper.getProviderDescription(provider)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() => this.props.history.push(paths.ADMIN_PAYMENT_CREATE)}
                            >
                                Nuevo Pago
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTable
                                data={paymentsByProvider}
                                columns={columns(this.detailPayment, this.deactivatePayment)}
                                pageSize={pageSize}
                                total={total}
                                currentPage={this.state.page}
                                loading={paymentsByProviderLoading}
                            />
                        </Col>
                    </Row>
                </SectionContainer>
                <DeactivatePaymentModal
                    isOpen={this.state.modalDeactivatePaymentVisible}
                    onSubmit={this.onClickDeactivatePayment}
                    onCancel={() => this.setState({ modalDeactivatePaymentVisible: false })}
                    initialValues={null}
                    submitting={this.props.deactivatePaymentLoading}
                    title="Anular Factura"
                />
            </>
        );
    }
}
