import React from 'react';
import { SectionContainer } from '../../../../components';
import { IProps } from './IncomeCompletePayment.types';
import { message } from 'antd';
import { paths } from '../../../../routes';
import { IncomeCompletePaymentForm } from './components';
import { helper, printHelper } from '../../../../common/helpers';

export default class IncomeCompletePayment extends React.Component<IProps> {
    componentDidMount() {
        if (!this.props.completePaymentData) this.goBack();
        // this.props.getIncomeTypes();
        this.props.getPaymentTypes();
        this.props.getReceiptTypes();
        // this.props.getCurrencies();
        this.props.getBankAccounts();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkCompletePayment(prevProps);
    }

    componentWillUnmount() {
        // this.props.resetIncomeTypes();
        this.props.resetPaymentTypes();
        this.props.resetReceiptTypes();
        // this.props.resetCurrencies();
        this.props.resetBankAccounts();
        this.props.resetCompletePaymentData();
    }

    checkCompletePayment(prevProps: IProps) {
        const { props } = this;
        if (!prevProps.completePaymentPayload && props.completePaymentPayload) {
            message.success('Pago de ingreso completado');

            const { completePaymentPayload } = props;

            if (!completePaymentPayload.electronicBillingCode) {
                printHelper.printVoucherSales(completePaymentPayload);
            } else {
                printHelper.printElectronicBilling(completePaymentPayload);
            }

            this.props.history.push(paths.ADMIN_INCOME_PENDING_LIST);
        } else if (!prevProps.completePaymentError && props.completePaymentError)
            message.error(this.props.completePaymentError);
    }

    goBack = () => {
        if (!this.props.completePaymentData) {
            this.props.history.push(paths.ADMIN_INCOME_PENDING_LIST);
            return;
        }

        const { property, shareholder } = this.props.completePaymentData;

        if (property) {
            this.props.history.push(
                helper.setPathParams(paths.ADMIN_INCOME_PENDING_DETAIL_BY_PROPERTY, {
                    id: property.id,
                }),
            );
        } else if (shareholder) {
            this.props.history.push(
                helper.setPathParams(paths.ADMIN_INCOME_PENDING_DETAIL_BY_SHAREHOLDER, {
                    id: shareholder.id,
                }),
            );
        }
    };

    onSubmit = (values: any) => {
        const { completePaymentData } = this.props;

        const data = {
            incomePaymentItems: completePaymentData.incomePaymentItems,
            property: completePaymentData.property ? { id: completePaymentData.property.id } : null,
            shareholder: completePaymentData.shareholder
                ? { id: completePaymentData.shareholder.id }
                : null,
            currency: this.props.completePaymentData.currency,
            ...values,
        };

        this.props.completeIncomePayment(data);
    };

    renderForm = () => {
        const {
            completePaymentData,
            incomeTypes,
            incomeTypesLoading,
            paymentTypes,
            paymentTypesLoading,
            receiptTypes,
            receiptTypesLoading,
            currencies,
            currenciesLoading,
            completePaymentLoading,
            bankAccounts,
            bankAccountsLoading,
        } = this.props;

        return (
            <IncomeCompletePaymentForm
                completePaymentData={completePaymentData}
                incomeTypes={incomeTypes}
                incomeTypesLoading={incomeTypesLoading}
                paymentTypes={paymentTypes}
                paymentTypesLoading={paymentTypesLoading}
                receiptTypes={receiptTypes}
                receiptTypesLoading={receiptTypesLoading}
                currencies={currencies}
                currenciesLoading={currenciesLoading}
                bankAccounts={bankAccounts}
                bankAccountsLoading={bankAccountsLoading}
                onSubmit={this.onSubmit}
                submitting={completePaymentLoading}
                submitButtonText="Completar Pago"
            />
        );
    };

    render() {
        return (
            <SectionContainer
                title="Completar Pago de Ingreso"
                onBack={this.goBack}
                content={this.renderForm}
            />
        );
    }
}
