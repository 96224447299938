import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';

const { INCOME_URL } = apiUrls;

export function getAllByProperties(stateId: number, filter: string, page: number) {
    const url = `${INCOME_URL}/properties`;
    const params = { stateId, filter, page };

    return APIManager.get(url, { params });
}

export function getAllByShareholders(stateId: number, filter: string, page: number) {
    const url = `${INCOME_URL}/shareholders`;
    const params = { stateId, filter, page };

    return APIManager.get(url, { params });
}

export function getPendingsByProperties(filter: string, page: number) {
    const url = `${INCOME_URL}/pendings/properties`;
    const params = { filter, page };
    return APIManager.get(url, { params });
}

export function getPendingsByShareholders(filter: string, page: number) {
    const url = `${INCOME_URL}/pendings/shareholders`;
    const params = { filter, page };
    return APIManager.get(url, { params });
}

export function createIncomePaymentItem(data: any) {
    const url = `${INCOME_URL}/payment-items`;
    return APIManager.post(url, { data });
}

export function get(id: number) {
    const url = `${INCOME_URL}/${id}`;
    return APIManager.get(url);
}

export function create(data: any) {
    return APIManager.post(INCOME_URL, { data });
}

export function update(id: number, data: any) {
    const url = `${INCOME_URL}/${id}`;
    return APIManager.put(url, { data });
}

export function completePayment(data: any) {
    const url = `${INCOME_URL}/complete-payment`;
    return APIManager.put(url, { data });
}

export function cancelIncome(id: number, data: any) {
    const url = `${INCOME_URL}/${id}/cancel`;
    return APIManager.put(url, { data });
}

export function deactivate(id: number) {
    const url = `${INCOME_URL}/${id}/deactivate`;
    return APIManager.put(url);
}

export function deleteIncome(id: number) {
    const url = `${INCOME_URL}/${id}`;
    return APIManager.delete(url);
}

export function getTypes() {
    const url = `${INCOME_URL}/types`;
    return APIManager.get(url);
}

export function getStates() {
    const url = `${INCOME_URL}/states`;
    return APIManager.get(url);
}

export function getPaymentTypes() {
    const url = `${INCOME_URL}/payment-types`;
    return APIManager.get(url);
}

export function getReceiptTypes() {
    const url = `${INCOME_URL}/receipt-types`;
    return APIManager.get(url);
}

export function bulkLoadPaymentItemsByProperties(file: any) {
    const data = new FormData();
    data.append('file', file);

    const url = `${INCOME_URL}/payment-items/properties/bulk-load`;
    return APIManager.post(url, { data });
}

export function bulkLoadPaymentItemsByShareholders(file: any) {
    const data = new FormData();
    data.append('file', file);

    const url = `${INCOME_URL}/payment-items/shareholders/bulk-load`;
    return APIManager.post(url, { data });
}

export function getPropertyIncomePaymentItems(propertyId: number, filter: string) {
    const url = `${INCOME_URL}/properties/${propertyId}/payment-items`;
    return APIManager.get(url, { params: { filter } });
}

export function getShareholderIncomePaymentItems(shareholderId: number, filter: string) {
    const url = `${INCOME_URL}/shareholders/${shareholderId}/payment-items`;
    return APIManager.get(url, { params: { filter } });
}

export function deleteIncomePaymentItem(id: number) {
    const url = `${INCOME_URL}/payment-items/${id}`;
    return APIManager.delete(url);
}

export function condoneIncomePaymentItem(id: number, data: any) {
    const url = `${INCOME_URL}/payment-items/${id}/condone`;
    return APIManager.put(url, { data });
}

export function verifyPrintIncome(id: number) {
    const url = `${INCOME_URL}/${id}/verify-print`;
    return APIManager.post(url);
}

export function completePendingIncome(id: number) {
    const url = `${INCOME_URL}/${id}/complete-pending`;
    return APIManager.put(url);
}
