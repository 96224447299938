import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';

const { PROPERTY_URL } = apiUrls;

export function getAll(filter: string, page: number, zoneId: number, subzoneId: number) {
    const params = { filter, page, zoneId, subzoneId };

    return APIManager.get(PROPERTY_URL, { params });
}

export function get(id: number) {
    const url = `${PROPERTY_URL}/${id}`;
    return APIManager.get(url);
}

export function create(data: any) {
    return data.attachedFiles && data.attachedFiles.length > 0
        ? APIManager.postWithFiles(PROPERTY_URL, { data })
        : APIManager.post(PROPERTY_URL, { data });
}

export function update(id: number, data: any) {
    const url = `${PROPERTY_URL}/${id}`;
    return data.attachedFiles && data.attachedFiles.length > 0
        ? APIManager.putWithFiles(url, { data })
        : APIManager.put(url, { data });
}

export function deactivate(id: number) {
    const url = `${PROPERTY_URL}/${id}/deactivate`;
    return APIManager.put(url);
}

export function getShareholders(id: number, filter: string, page: number) {
    const params = { filter, page };
    const url = `${PROPERTY_URL}/${id}/shareholders`;

    return APIManager.get(url, { params });
}

export function createPropertyShareholder(id: number, data: any) {
    const url = `${PROPERTY_URL}/${id}/shareholders`;
    return APIManager.post(url, { data });
}

export function updatePropertyShareholder(id: number, data: any) {
    const url = `${PROPERTY_URL}/${id}/shareholders`;
    return APIManager.put(url, { data });
}

export function getAvailableShareholders(id: number) {
    const url = `${PROPERTY_URL}/${id}/available-shareholders`;
    return APIManager.get(url);
}

export function deletePropertyShareholder(shareholderPropertyId: number) {
    const url = `${PROPERTY_URL}/shareholders/${shareholderPropertyId}`;
    return APIManager.delete(url);
}
