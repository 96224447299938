import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
    objectStateLoading,
    objectStateSuccess,
    objectStateFailure,
    processStateLoading,
    processStateSuccess,
    processStateFailure,
} from '../../common/helpers/reducer';

//#region GET PETTY CASH LIST
export function getPettyCashList(state: IState): IState {
    return {
        ...state,
        pettyCashList: listStatePaginatedLoading(state.pettyCashList),
    };
}

export function getPettyCashListSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        pettyCashList: listStatePaginatedSuccess(action.payload),
    };
}

export function getPettyCashListFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        pettyCashList: listStatePaginatedFailure(initialState.pettyCashList, action.payload.error),
    };
}

export function getPettyCashListReset(state: IState): IState {
    return {
        ...state,
        pettyCashList: initialState.pettyCashList,
    };
}
//#endregion

//#region GET PETTY CASH
export function getPettyCash(state: IState): IState {
    return {
        ...state,
        activePettyCash: objectStateLoading(),
    };
}

export function getPettyCashSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activePettyCash: objectStateSuccess(action.payload),
    };
}

export function getPettyCashFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activePettyCash: objectStateFailure(action.payload.error),
    };
}

export function getPettyCashReset(state: IState): IState {
    return {
        ...state,
        activePettyCash: initialState.activePettyCash,
    };
}
//#endregion

//#region GET PETTY CASH
export function getPettyCashToClose(state: IState): IState {
    return {
        ...state,
        activePettyCashToClose: objectStateLoading(),
    };
}

export function getPettyCashToCloseSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activePettyCashToClose: objectStateSuccess(action.payload),
    };
}

export function getPettyCashToCloseFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activePettyCashToClose: objectStateFailure(action.payload.error),
    };
}

export function getPettyCashToCloseReset(state: IState): IState {
    return {
        ...state,
        activePettyCashToClose: initialState.activePettyCashToClose,
    };
}
//#endregion

//#region CREATE PETTY CASH
export function createPettyCash(state: IState): IState {
    return {
        ...state,
        createPettyCash: processStateLoading(),
    };
}

export function createPettyCashSuccess(state: IState): IState {
    return {
        ...state,
        createPettyCash: processStateSuccess(),
    };
}

export function createPettyCashFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createPettyCash: processStateFailure(action.payload.error),
    };
}

export function createPettyCashReset(state: IState): IState {
    return {
        ...state,
        createPettyCash: initialState.createPettyCash,
    };
}
//#endregion

//#region GET PETTY CASH MOVEMENT LIST
export function getPettyCashMovements(state: IState): IState {
    return {
        ...state,
        pettyCashMovementList: listStatePaginatedLoading(state.pettyCashMovementList),
    };
}

export function getPettyCashMovementsSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        pettyCashMovementList: listStatePaginatedSuccess(action.payload),
    };
}

export function getPettyCashMovementsFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        pettyCashMovementList: listStatePaginatedFailure(
            initialState.pettyCashMovementList,
            action.payload.error,
        ),
    };
}

export function getPettyCashMovementsReset(state: IState): IState {
    return {
        ...state,
        pettyCashMovementList: initialState.pettyCashMovementList,
    };
}
//#endregion

//#region GET PETTY CASH MOVEMENT ITEM LIST
export function getPettyCashMovementItems(state: IState): IState {
    return {
        ...state,
        pettyCashMovementItemList: listStatePaginatedLoading(state.pettyCashMovementItemList),
    };
}

export function getPettyCashMovementItemsSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        pettyCashMovementItemList: listStatePaginatedSuccess(action.payload),
    };
}

export function getPettyCashMovementItemsFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        pettyCashMovementItemList: listStatePaginatedFailure(
            initialState.pettyCashMovementItemList,
            action.payload.error,
        ),
    };
}

export function getPettyCashMovementItemsReset(state: IState): IState {
    return {
        ...state,
        pettyCashMovementItemList: initialState.pettyCashMovementItemList,
    };
}
//#endregion

//#region CREATE PETTY CASH MOVEMENT
export function createPettyCashMovement(state: IState): IState {
    return {
        ...state,
        createPettyCashMovement: processStateLoading(),
    };
}

export function createPettyCashMovementSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activePettyCash: {
            ...state.activePettyCash,
            value: { ...state.activePettyCash.value, currentAmount: action.payload.currentAmount },
        },
        createPettyCashMovement: processStateSuccess(),
    };
}

export function createPettyCashMovementFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createPettyCashMovement: processStateFailure(action.payload.error),
    };
}

export function createPettyCashMovementReset(state: IState): IState {
    return {
        ...state,
        createPettyCashMovement: initialState.createPettyCashMovement,
    };
}
//#endregion

//#region DELETE PETTY CASH MOVEMENT
export function deletePettyCashMovement(state: IState): IState {
    return {
        ...state,
        deletePettyCashMovement: processStateLoading(),
    };
}

export function deletePettyCashMovementSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activePettyCash: {
            ...state.activePettyCash,
            value: { ...state.activePettyCash.value, currentAmount: action.payload.currentAmount },
        },
        deletePettyCashMovement: processStateSuccess(),
    };
}

export function deletePettyCashMovementFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deletePettyCashMovement: processStateFailure(action.payload.error),
    };
}

export function deletePettyCashMovementReset(state: IState): IState {
    return {
        ...state,
        deletePettyCashMovement: initialState.deletePettyCashMovement,
    };
}
//#endregion
