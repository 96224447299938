import React from 'react';
import { IProps } from './ExpenseAccordanceAddEdit.types';
import { SectionContainer, DisplayItem, DataTable } from '../../../../components';
import { paths } from '../../../../routes';
import { expenseTypes } from '../../../../common/constants';
import { Divider, message } from 'antd';
import { formatHelper } from '../../../../common/helpers';
import { columns } from './misc/columns';
import { IRequestOrder } from '../../../../common/types/entities';

interface IState {
    requestOrders: IRequestOrder[];
    requestOrderToEdit: IRequestOrder;
}

export default class ExpenseAccordanceAddEdit extends React.Component<IProps> {
    private expenseId = this.props.match.params['id'];

    state: IState = {
        requestOrders: [],
        requestOrderToEdit: null,
    };

    componentDidMount() {
        this.props.getExpense(this.expenseId);
        this.props.getRequestOdersByExpense(this.expenseId);
    }

    componentWillUnmount() {
        this.props.resetExpense();
        this.props.resetRequestOrdersByExpense();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkActiveExpense(prevProps);
        this.checkAccordingRequestOrder(prevProps);
    }

    checkActiveExpense(prevProps: IProps) {
        const { props } = this;

        if (this.state.requestOrders.length === 0 && props.requestOrdersByExpense.length !== 0)
            this.setState({ requestOrders: props.requestOrdersByExpense });
    }

    checkAccordingRequestOrder(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.accordingRequestOrderSuccess && props.accordingRequestOrderSuccess) {
            let requestOrders = this.state.requestOrders;
            for (let i = 0; i < requestOrders.length; i++) {
                if (requestOrders[i].id == this.state.requestOrderToEdit.id) {
                    requestOrders[i].accordance = this.state.requestOrderToEdit.accordance;
                    break;
                }
            }
            this.setState({ requestOrders });
            message.success('Conformidad de Orden de Compra actualizado');
        } else if (!prevProps.accordingRequestOrderError && props.accordingRequestOrderError)
            message.error(props.accordingRequestOrderError);
    }

    performAccordingRequestOrder = (id: number, response: boolean) => {
        const requestOrderToEdit: IRequestOrder = {
            id,
            accordance: response,
        };
        this.setState({ requestOrderToEdit });
        this.props.accordingRequestOrder(id, { response });
    };

    renderContent = () => {
        const { activeExpense, requestOrdersByExpense, requestOrdersByExpenseLoading } = this.props,
            isGoodsType = activeExpense.type.id == expenseTypes.GOODS;

        return (
            <>
                <DisplayItem
                    label="Código"
                    value={activeExpense.code}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />

                <DisplayItem
                    label="Estado"
                    value={activeExpense.state.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Responsables</Divider>
                <DisplayItem
                    label="Solicitante"
                    value={formatHelper.getUserFullname(activeExpense.requestUser)}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Respuesta"
                    value={formatHelper.getUserFullname(activeExpense.responseUser)}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Información</Divider>
                <DisplayItem
                    label="Tipo"
                    value={activeExpense.type.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Motivo"
                    value={activeExpense.reason}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Precio Total (PEN)"
                    type="money"
                    value={activeExpense.totalPrice}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Precio Total (USD)"
                    type="money"
                    value={activeExpense.totalPriceUSD}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left" className="mt-5">
                    Órdenes de Compra
                </Divider>
                <DataTable
                    data={requestOrdersByExpense}
                    columns={columns(
                        this.performAccordingRequestOrder,
                        this.props.accordingRequestOrderLoading,
                    )}
                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                    loading={requestOrdersByExpenseLoading}
                />
            </>
        );
    };

    render() {
        const { activeExpense, activeExpenseLoading, activeExpenseError } = this.props;

        return (
            <SectionContainer
                title={'Conformidad de Órdenes de Compra'}
                onBack={() => this.props.history.push(paths.ADMIN_EXPENSE_LIST)}
                loading={activeExpenseLoading || !activeExpense}
                error={activeExpenseError}
                content={this.renderContent}
            />
        );
    }
}
