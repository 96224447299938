import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Icon, Avatar, Dropdown, Menu, Divider } from 'antd';
import { IUser } from '../../../../common/types/entities';
import { IRootState } from '../../../../common/types';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logout } from '../../../../store/auth/actions';
import { paths } from '../../../../routes';
const { Header } = Layout;

interface IOwnProps {
    onClickMenu: () => void;
    collapsed?: boolean;
}

interface IStateProps {
    user: IUser;
}

interface IDispatchProps {
    logout: () => void;
}

type IProps = IOwnProps & RouteComponentProps<any> & IStateProps & IDispatchProps;

const MainHeader: React.FC<IProps> = ({ collapsed, onClickMenu, logout, user, history }) => {
    const onClickLogout = () => {
        logout();
    };

    const renderUserMenu = () => (
        <Menu>
            <Menu.Item disabled style={{ cursor: 'default', color: 'rgba(0, 0, 0, 1)' }}>
                <div className="text-center">
                    <span className="p-3 font-w-600">{`${user.firstname || ''} ${user.lastname ||
                        ''}`}</span>
                    <br />
                    <span className="p-3">{user.username}</span>
                </div>
                <Divider className="my-1" />
            </Menu.Item>
            <Menu.Item onClick={() => history.push(paths.USER_PROFILE)}>
                <Icon type="user" />
                Mi Perfil
            </Menu.Item>
            <Menu.Item onClick={onClickLogout}>
                <Icon type="logout" />
                Cerrar Sesión
            </Menu.Item>
        </Menu>
    );

    return (
        <Header style={{ background: '#fff', padding: 0 }}>
            <Icon
                className="sidebar-trigger"
                type={collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={onClickMenu}
            />
            <Dropdown trigger={['click']} overlay={renderUserMenu}>
                <div style={{ float: 'right', marginRight: 25 }}>
                    <Avatar icon="user" style={{ cursor: 'pointer' }}></Avatar>
                </div>
            </Dropdown>
        </Header>
    );
};

function mapStateToProps(state: IRootState): IStateProps {
    return {
        user: state.auth.loggedInUser.value,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({ logout }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(MainHeader));
