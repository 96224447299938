import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { IInvoice } from '../../../../../common/types/entities';
import { TableCellItem, TableRowOptionsDropdown } from '../../../../../components';
import { formatHelper } from '../../../../../common/helpers';
import { currencyTypes, permissions } from '../../../../../common/constants';

export const invoiceColumns = (
    onClickDetail: (id: number) => void,
    deactivateInvoiceOrPayment: (id: number, type: string) => void,
    currencyId: number,
): ColumnProps<IInvoice>[] => {
    return [
        {
            title: 'Código',
            dataIndex: 'code',
            className: 'd-none d-md-table-cell',
        },
        {
            title: 'Pagado',
            dataIndex: 'paid',
            className: 'd-none d-md-table-cell',
            render: (paid: boolean) => <span>{paid ? 'Sí' : 'No'}</span>,
        },
        {
            title: 'Fecha de Emisión',
            dataIndex: 'emissionDate',
            className: 'd-none d-md-table-cell',
            render: (emissionDate: any) => <span>{formatHelper.toDate(emissionDate)}</span>,
        },
        {
            title: 'Fecha de Vencimiento',
            dataIndex: 'dueDate',
            className: 'd-none d-md-table-cell',
            render: (dueDate: any) => <span>{formatHelper.toDate(dueDate)}</span>,
        },
        {
            title: 'Precio Total',
            className: 'd-none d-md-table-cell',
            render: (item: IInvoice) => (
                <span>
                    {item.totalPrice
                        ? currencyId == currencyTypes.PEN
                            ? formatHelper.toMoney(item.totalPrice, 'PEN')
                            : formatHelper.toMoney(item.totalPrice, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            title: 'Monto Restante',
            className: 'd-none d-md-table-cell',
            render: (item: IInvoice) => (
                <span>
                    {item.totalPrice
                        ? currencyId == currencyTypes.PEN
                            ? formatHelper.toMoney(item.paymentRest, 'PEN')
                            : formatHelper.toMoney(item.paymentRest, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            title: 'Motivo de Anulación',
            dataIndex: 'reasonToDelete',
            className: 'd-none d-md-table-cell',
        },
        {
            key: 'xs',
            title: 'Factura',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (item: IInvoice) => (
                <>
                    <TableCellItem label="Código" value={item.code} />
                    <TableCellItem label="Pagado" value={item.paid ? 'Sí' : 'No'} />
                    <TableCellItem label="Fecha de Emisión" value={item.emissionDate} type="date" />
                    <TableCellItem label="Fecha de Vencimiento" value={item.dueDate} type="date" />
                    <TableCellItem
                        label="Precio Total"
                        value={
                            item.totalPrice
                                ? currencyId == currencyTypes.PEN
                                    ? formatHelper.toMoney(item.totalPrice, 'PEN')
                                    : formatHelper.toMoney(item.totalPrice, 'USD')
                                : ''
                        }
                    />
                    <TableCellItem
                        label="Monto Restante"
                        value={
                            item.paymentRest
                                ? currencyId == currencyTypes.PEN
                                    ? formatHelper.toMoney(item.paymentRest, 'PEN')
                                    : formatHelper.toMoney(item.paymentRest, 'USD')
                                : ''
                        }
                    />
                    <TableCellItem label="Motivo de Anulación" value={item.reasonToDelete} />
                </>
            ),
        },
        {
            key: 'options',
            dataIndex: '',
            render: (item: IInvoice) => (
                <TableRowOptionsDropdown
                    onClickDetail={() => onClickDetail(item.id)}
                    onClickCancel={
                        item.active ? () => deactivateInvoiceOrPayment(item.id, 'invoice') : null
                    }
                    cancelPermission={permissions.expense.WRITE_INVOICES}
                />
            ),
        },
    ];
};
