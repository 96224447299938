import React, { FC, useRef, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal } from 'antd';
import { isEmpty, isNumeric } from 'validator';
import { InputGroupField, SelectGroupField, DisplayItem } from '../../../../../components';
import { messages, currencies } from '../../../../../common/constants';
import { IBankAccount, IPettyCash } from '../../../../../common/types/entities';
import { getBankAccounts, resetBankAccounts } from '../../../../../store/bankAccount/actions';
import { getActivePettyCash, resetActivePettyCash } from '../../../../../store/pettyCash/actions';
import { IRootState } from '../../../../../common/types';
import { connect } from 'react-redux';

type IOwnProps = {
    isOpen: boolean;
    onSubmit: (values: IPettyCashAddForm) => void;
    disabled?: boolean;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    submitting?: boolean;
};

type IStoreState = {
    bankAccounts: IBankAccount[];
    bankAccountsLoading: boolean;
    activePettyCash: IPettyCash;
};

type IDispatchProps = {
    getBankAccounts: () => void;
    resetBankAccounts: () => void;
    getActivePettyCash: () => void;
    resetActivePettyCash: () => void;
};

export interface IPettyCashAddForm {
    accumulatedAmount: number;
    correlative: string;
    checkNumber: string;
    bankAccount: { id: number | string };
    startingAmount: string | number;
    pettyCashToClose: { id: number | string };
}

type IProps = IOwnProps & IStoreState & IDispatchProps;

const sharedFieldProps = {
    lxs: 24,
    lsm: 24,
    lmd: 24,
    llg: 24,
    lxl: 8,
    lxxl: 6,
    imd: 24,
    ixl: 16,
    ilg: 24,
};

const PettyCashAddModal: FC<IProps> = (props) => {
    let form = useRef<Formik<IPettyCashAddForm>>(null);

    const onSubmit = (values: IPettyCashAddForm, actions: FormikActions<IPettyCashAddForm>) => {
        values.startingAmount = Number(values.startingAmount);

        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = (e) => {
        form.current.resetForm();
        props.onCancel(e);
    };

    useEffect(() => {
        if (!props.isOpen) return;

        props.getBankAccounts();
        props.getActivePettyCash();
        return () => {
            props.resetBankAccounts();
            props.resetActivePettyCash();
        };
    }, [props.isOpen]);

    useEffect(() => {
        if (!props.activePettyCash || !props.activePettyCash.id) return;

        form.current.setFieldValue('pettyCashToClose.id', props.activePettyCash.id);
        form.current.setFieldValue('accumulatedAmount', props.activePettyCash.currentAmount);
    }, [props.activePettyCash]);

    const validate = (values: IPettyCashAddForm) => {
        let errors: Partial<IPettyCashAddForm> = {};

        if (isEmpty(values.correlative)) errors.correlative = messages.MANDATORY_FIELD;
        if (isEmpty(values.checkNumber)) errors.checkNumber = messages.MANDATORY_FIELD;
        if (!values.bankAccount || !values.bankAccount.id)
            errors.bankAccount = { id: messages.MANDATORY_FIELD };
        if (isEmpty(values.startingAmount as string))
            errors.startingAmount = messages.MANDATORY_FIELD;
        else if (!isNumeric(values.startingAmount as string))
            errors.startingAmount = 'Se requiere un valor númerico';
        else if (Number(values.startingAmount) < Number(values.accumulatedAmount))
            errors.startingAmount = 'El monto inicial no puede ser menor al acumulado';

        return errors;
    };

    const onOk = () => {
        form.current.submitForm();
    };

    const renderFormik = () => {
        if (!props.isOpen) return null;

        return (
            <Formik
                initialValues={{
                    pettyCashToClose: { id: null },
                    accumulatedAmount: 0,
                    correlative: '',
                    checkNumber: '',
                    bankAccount: { id: null },
                    startingAmount: '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={({ values }: FormikProps<IPettyCashAddForm>) => (
                    <Form>
                        {props.activePettyCash && props.activePettyCash.id && (
                            <DisplayItem
                                label="Caja chica a cerrar"
                                value={props.activePettyCash.correlative}
                                {...sharedFieldProps}
                            />
                        )}

                        <DisplayItem
                            label="Monto Acumulado (PEN)"
                            value={values.accumulatedAmount}
                            type="money"
                            {...sharedFieldProps}
                        />
                        <InputGroupField
                            name="correlative"
                            label="Correlativo"
                            required
                            {...sharedFieldProps}
                        />
                        <InputGroupField
                            name="checkNumber"
                            label="Número de Cheque"
                            required
                            {...sharedFieldProps}
                        />
                        <SelectGroupField
                            name="bankAccount.id"
                            label="Cuenta Bancaria"
                            data={props.bankAccounts}
                            displayText={(item: IBankAccount) =>
                                item.bank.name + ' - Nº: ' + item.number
                            }
                            required
                            loading={props.bankAccountsLoading}
                            {...sharedFieldProps}
                        />
                        <InputGroupField
                            name="startingAmount"
                            label="Monto Inicial (PEN)"
                            required
                            normalize="numbersWithTwoDecimals"
                            {...sharedFieldProps}
                        />
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            title="Aperturar Caja Chica"
            visible={props.isOpen}
            okText="Guardar"
            onOk={onOk}
            okButtonProps={{ loading: props.submitting }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.submitting }}
            maskClosable={false}
        >
            {renderFormik()}
        </Modal>
    );
};

const selectBankAccounts = (bankAccounts: IBankAccount[]): IBankAccount[] => {
    if (!bankAccounts) return [];
    return bankAccounts.filter((x) => x.currency.id === currencies.PEN);
};

function mapStateToProps(state: IRootState): IStoreState {
    const { bankAccountList } = state.bankAccount;

    return {
        bankAccounts: selectBankAccounts(bankAccountList.data),
        bankAccountsLoading: bankAccountList.loading,
        activePettyCash: state.pettyCash.activePettyCashToClose.value,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getBankAccounts,
            resetBankAccounts,
            getActivePettyCash,
            resetActivePettyCash,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PettyCashAddModal);
