import { Formik, FormikActions, Form, FormikProps } from 'formik';
import * as React from 'react';
import { IProps } from './ResetPassword.types';
import { message, Layout, Card, Row, Col, Button, Icon, Alert } from 'antd';
import { paths } from '../../../routes';
import { InputPasswordField } from '../../../components';
import { messages } from '../../../common/constants';
import { isEmpty } from 'validator';

interface IForm {
    password: string;
    confirmPassword: string;
}

export default class View extends React.Component<IProps> {
    componentDidMount() {
        if (!this.props.match.params['passwordToken']) this.goToLogin();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkResetPassword(prevProps);
    }

    checkResetPassword(prevProps: IProps) {
        if (!prevProps.success && this.props.success) {
            message.success('Contraseña restablecida satisfactoriamente');
            this.goToLogin();
        }
    }

    goToLogin = () => {
        this.props.history.push(paths.LOGIN);
    };

    validate = (values: IForm) => {
        const errors: Partial<IForm> = {};

        if (isEmpty(values.password)) errors.password = messages.MANDATORY_FIELD;
        if (isEmpty(values.confirmPassword)) errors.confirmPassword = messages.MANDATORY_FIELD;
        if (
            values.confirmPassword &&
            values.confirmPassword &&
            values.password !== values.confirmPassword
        ) {
            errors.confirmPassword = 'Las contraseñas no coinciden';
        }

        return errors;
    };

    handleSubmit = (values: IForm, { setSubmitting }: FormikActions<IForm>) => {
        this.props.resetPassword(
            values.password,
            values.confirmPassword,
            this.props.match.params['passwordToken'],
        );
        setSubmitting(false);
    };

    public render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Card
                    style={{
                        width: 350,
                        marginTop: '30vh',
                        marginBottom: '10vh',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <Formik
                        initialValues={{ password: '', confirmPassword: '' }}
                        validate={this.validate}
                        onSubmit={this.handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {(formikBag: FormikProps<IForm>) => (
                            <Form>
                                <Row className="mb-1">
                                    <Col>
                                        <h1>Restablecer contraseña</h1>
                                        <p className="text-muted">
                                            Ingresa y confirma tu nueva contraseña
                                        </p>
                                    </Col>
                                </Row>
                                {this.props.error && (
                                    <Row className="mb-3">
                                        <Col>
                                            <Alert
                                                message={this.props.error}
                                                type="error"
                                                showIcon
                                                closable
                                            />
                                        </Col>
                                    </Row>
                                )}
                                <Row className="mb-4">
                                    <InputPasswordField
                                        name="password"
                                        placeholder="Nueva contraseña"
                                        prefix={
                                            <Icon
                                                type="lock"
                                                style={{ color: 'rgba(0,0,0,.25)' }}
                                            />
                                        }
                                    />
                                </Row>
                                <Row className="mb-4">
                                    <InputPasswordField
                                        name="confirmPassword"
                                        placeholder="Confirmar nueva contraseña"
                                        prefix={
                                            <Icon
                                                type="lock"
                                                style={{ color: 'rgba(0,0,0,.25)' }}
                                            />
                                        }
                                    />
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="mr-2"
                                            loading={this.props.loading}
                                        >
                                            Enviar
                                        </Button>
                                        <Button
                                            disabled={this.props.loading}
                                            onClick={this.goToLogin}
                                        >
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </Layout>
        );
    }
}
