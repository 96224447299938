import { IListStatePaginated, IObjectState, IProcessState, IListState } from '../../common/types';
import { IProperty, IShareholder } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    propertyList: IListStatePaginated<IProperty>;
    activeProperty: IObjectState<IProperty>;
    createPropertyShareholder: IProcessState;
    updatePropertyShareholder: IProcessState;
    createProperty: IProcessState;
    availableShareholderList: IListState<IProperty>;
    deletePropertyShareholder: IProcessState;
    updateProperty: IProcessState;
    deactivateProperty: IProcessState;
    shareholderList: IListStatePaginated<IShareholder>;
}

export const initialState: IState = {
    propertyList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activeProperty: { value: undefined, loading: false, error: undefined },
    createPropertyShareholder: { success: false, loading: false, error: undefined },
    updatePropertyShareholder: { success: false, loading: false, error: undefined },
    createProperty: { success: false, loading: false, error: undefined },
    availableShareholderList: { data: [], loading: false, error: undefined },
    deletePropertyShareholder: { success: false, loading: false, error: undefined },
    updateProperty: { success: false, loading: false, error: undefined },
    deactivateProperty: { success: false, loading: false, error: undefined },
    shareholderList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_PROPERTIES]: caseFunctions.getProperties,
    [actionTypes.GET_PROPERTIES_SUCCESS]: caseFunctions.getPropertiesSuccess,
    [actionTypes.GET_PROPERTIES_FAILURE]: caseFunctions.getPropertiesFailure,
    [actionTypes.GET_PROPERTIES_RESET]: caseFunctions.getPropertiesReset,

    [actionTypes.GET_PROPERTY]: caseFunctions.getProperty,
    [actionTypes.GET_PROPERTY_SUCCESS]: caseFunctions.getPropertySuccess,
    [actionTypes.GET_PROPERTY_FAILURE]: caseFunctions.getPropertyFailure,
    [actionTypes.GET_PROPERTY_RESET]: caseFunctions.getPropertyReset,

    [actionTypes.CREATE_PROPERTY]: caseFunctions.createProperty,
    [actionTypes.CREATE_PROPERTY_SUCCESS]: caseFunctions.createPropertySuccess,
    [actionTypes.CREATE_PROPERTY_FAILURE]: caseFunctions.createPropertyFailure,
    [actionTypes.CREATE_PROPERTY_RESET]: caseFunctions.createPropertyReset,

    [actionTypes.UPDATE_PROPERTY]: caseFunctions.updateProperty,
    [actionTypes.UPDATE_PROPERTY_SUCCESS]: caseFunctions.updatePropertySuccess,
    [actionTypes.UPDATE_PROPERTY_FAILURE]: caseFunctions.updatePropertyFailure,
    [actionTypes.UPDATE_PROPERTY_RESET]: caseFunctions.updatePropertyReset,

    [actionTypes.DEACTIVATE_PROPERTY]: caseFunctions.deactivateProperty,
    [actionTypes.DEACTIVATE_PROPERTY_SUCCESS]: caseFunctions.deactivatePropertySuccess,
    [actionTypes.DEACTIVATE_PROPERTY_FAILURE]: caseFunctions.deactivatePropertyFailure,
    [actionTypes.DEACTIVATE_PROPERTY_RESET]: caseFunctions.deactivatePropertyReset,

    [actionTypes.GET_PROPERTY_SHAREHOLDERS]: caseFunctions.getPropertyShareholders,
    [actionTypes.GET_PROPERTY_SHAREHOLDERS_SUCCESS]: caseFunctions.getPropertyShareholdersSuccess,
    [actionTypes.GET_PROPERTY_SHAREHOLDERS_FAILURE]: caseFunctions.getPropertyShareholdersFailure,
    [actionTypes.GET_PROPERTY_SHAREHOLDERS_RESET]: caseFunctions.getPropertyShareholdersReset,

    [actionTypes.CREATE_PROPERTY_SHAREHOLDER]: caseFunctions.createPropertyShareholder,
    [actionTypes.CREATE_PROPERTY_SHAREHOLDER_SUCCESS]:
        caseFunctions.createPropertyShareholderSuccess,
    [actionTypes.CREATE_PROPERTY_SHAREHOLDER_FAILURE]:
        caseFunctions.createPropertyShareholderFailure,
    [actionTypes.CREATE_PROPERTY_SHAREHOLDER_RESET]: caseFunctions.createPropertyShareholderReset,

    [actionTypes.UPDATE_PROPERTY_SHAREHOLDER]: caseFunctions.updatePropertyShareholder,
    [actionTypes.UPDATE_PROPERTY_SHAREHOLDER_SUCCESS]:
        caseFunctions.updatePropertyShareholderSuccess,
    [actionTypes.UPDATE_PROPERTY_SHAREHOLDER_FAILURE]:
        caseFunctions.updatePropertyShareholderFailure,
    [actionTypes.UPDATE_PROPERTY_SHAREHOLDER_RESET]: caseFunctions.updatePropertyShareholderReset,

    [actionTypes.GET_AVAILABLE_SHAREHOLDERS]: caseFunctions.getAvailableShareholders,
    [actionTypes.GET_AVAILABLE_SHAREHOLDERS_SUCCESS]: caseFunctions.getAvailableShareholdersSuccess,
    [actionTypes.GET_AVAILABLE_SHAREHOLDERS_FAILURE]: caseFunctions.getAvailableShareholdersFailure,
    [actionTypes.GET_AVAILABLE_SHAREHOLDERS_RESET]: caseFunctions.getAvailableShareholdersReset,

    [actionTypes.DELETE_PROPERTY_SHAREHOLDER]: caseFunctions.deletePropertyShareholder,
    [actionTypes.DELETE_PROPERTY_SHAREHOLDER_SUCCESS]:
        caseFunctions.deletePropertyShareholderSuccess,
    [actionTypes.DELETE_PROPERTY_SHAREHOLDER_FAILURE]:
        caseFunctions.deletePropertyShareholderFailure,
    [actionTypes.DELETE_PROPERTY_SHAREHOLDER_RESET]: caseFunctions.deletePropertyShareholderReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
