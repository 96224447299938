import React, { FunctionComponent, useEffect, useState } from 'react';
import { IProps } from './ShareholderDetail.types';
import { SectionContainer, DisplayItem, DataTable } from '../../../../components';
import { paths } from '../../../../routes';
import { Divider, Row, Col, Input, Tabs, Icon } from 'antd';
import { propertiesColumns, coOwnersColumns } from './misc/columns';

const ShareholderDetail: FunctionComponent<IProps> = ({
    shareholder,
    loading,
    error,
    shareholderProperties,
    shareholderPropertiesLoading,
    shareholderPropertiesError,
    shareholderPropertiesTotal,
    shareholderPropertiesPageZise,
    ...props
}) => {
    const shareholderId = props.match.params['id'];
    const TAB_PROPERTIES = '1';
    const TAB_COOWNERS = '2';

    const [propertiesFilter, setPropertiesFilter] = useState('');
    const [page, setPage] = useState(1);
    const [coOwnersFilter, setCoOwnersFilter] = useState('');

    useEffect(() => {
        props.getShareholder(shareholderId);
        performGetCoOwners();

        return () => {
            props.resetShareholder();
            props.resetShareholderProperties();
            props.resetCoOwners();
        };
    }, []);

    useEffect(() => {
        performGetShareholderProperties();
    }, [page]);

    const refreshShareholderProperties = () =>
        page === 1 ? performGetShareholderProperties() : setPage(1);

    const performGetShareholderProperties = () =>
        props.getShareholderProperties(shareholderId, propertiesFilter, page);

    const onPageChange = (page: number) => setPage(page);

    const performGetCoOwners = () => props.getCoOwners(shareholderId, coOwnersFilter);

    const renderPropertiesTabContent = () => (
        <>
            <Row className="mb-3">
                <Col md={12} lg={6}>
                    <Input.Search
                        placeholder="Buscar"
                        enterButton
                        value={propertiesFilter}
                        onChange={e => setPropertiesFilter(e.target.value)}
                        onSearch={refreshShareholderProperties}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable
                        data={shareholderProperties}
                        columns={propertiesColumns()}
                        pageSize={shareholderPropertiesPageZise}
                        total={shareholderPropertiesTotal}
                        currentPage={page}
                        onPageChange={onPageChange}
                        loading={shareholderPropertiesLoading}
                    />
                </Col>
            </Row>
        </>
    );

    const renderCoOwnsersTabContent = () => (
        <>
            <Row className="mb-3">
                <Col md={12} lg={6}>
                    <Input.Search
                        placeholder="Buscar"
                        enterButton
                        value={coOwnersFilter}
                        onChange={e => setCoOwnersFilter(e.target.value)}
                        onSearch={performGetCoOwners}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable
                        data={props.coOwners}
                        columns={coOwnersColumns()}
                        loading={props.coOwnersLoading}
                    />
                </Col>
            </Row>
        </>
    );

    const renderContent = () => {
        return (
            <>
                <DisplayItem label="Código" value={shareholder.code} />
                <DisplayItem label="Nombre" value={shareholder.firstname} />
                <DisplayItem label="Apellidos" value={shareholder.lastname} />
                <DisplayItem label="DNI" value={shareholder.dni} />
                <DisplayItem label="CE" value={shareholder.ce} />
                <DisplayItem label="RUC" value={shareholder.ruc} />
                <DisplayItem label="Razón Social" value={shareholder.businessName} />
                <DisplayItem label="Email" value={shareholder.email} />
                <DisplayItem label="Teléfono" value={shareholder.phone} />
                <DisplayItem label="Dirección" value={shareholder.address} />
                <DisplayItem label="Acciones" value={shareholder.totalShares} />
                <Tabs defaultActiveKey={TAB_PROPERTIES} className="mt-4">
                    <Tabs.TabPane
                        tab={
                            <span>
                                <Icon type="environment" />
                                Puestos
                            </span>
                        }
                        key={TAB_PROPERTIES}
                    >
                        {renderPropertiesTabContent()}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <span>
                                <Icon type="user" />
                                Co-propietarios
                            </span>
                        }
                        key={TAB_COOWNERS}
                    >
                        {renderCoOwnsersTabContent()}
                    </Tabs.TabPane>
                </Tabs>
            </>
        );
    };

    return (
        <SectionContainer
            title="Detalle Accionista"
            onBack={() => props.history.push(paths.ADMIN_SHAREHOLDER_LIST)}
            loading={loading || !shareholder}
            error={error}
            content={renderContent}
        />
    );
};

export default ShareholderDetail;
