import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';

export function loginUser(state: IState): IState {
    return {
        ...state,
        loggedInUser: { value: undefined, loading: true, error: undefined },
    };
}

export function loginUserSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        authenticated: true,
        loggedInUser: { value: action.payload.user, loading: false, error: undefined },
    };
}

export function loginUserFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        authenticated: false,
        loggedInUser: { value: undefined, loading: false, error: action.payload.error },
    };
}

export function verifyLoggedInUser(state: IState): IState {
    return {
        ...state,
        authenticated: undefined,
        verifyLoggedInUser: { success: false, loading: true, error: undefined },
    };
}

export function verifyLoggedInUserSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        authenticated: true,
        verifyLoggedInUser: { success: true, loading: false, error: undefined },
        loggedInUser: { value: action.payload, loading: false, error: undefined },
    };
}

export function verifyLoggedInUserFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        authenticated: false,
        verifyLoggedInUser: { success: false, loading: false, error: action.payload.error },
    };
}

export function logoutUser(): IState {
    return { ...initialState, authenticated: false };
}

export function forgotPassword(state: IState): IState {
    return {
        ...state,
        forgotPassword: { success: false, loading: true, error: undefined },
    };
}

export function forgotPasswordSuccess(state: IState): IState {
    return {
        ...state,
        forgotPassword: { success: true, loading: false, error: undefined },
    };
}

export function forgotPasswordFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        forgotPassword: { success: false, loading: false, error: action.payload.error },
    };
}

export function forgotPasswordReset(state: IState): IState {
    return {
        ...state,
        forgotPassword: initialState.forgotPassword,
    };
}

export function resetPassword(state: IState): IState {
    return {
        ...state,
        resetPassword: { success: false, loading: true, error: undefined },
    };
}

export function changePassword(state: IState): IState {
    return {
        ...state,
        changePassword: { success: false, loading: true, error: undefined },
    };
}

export function changePasswordSuccess(state: IState): IState {
    return {
        ...state,
        changePassword: { success: true, loading: false, error: undefined },
    };
}

export function changePasswordFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        changePassword: { success: false, loading: false, error: action.payload.error },
    };
}

export function resetPasswordSuccess(state: IState): IState {
    return {
        ...state,
        resetPassword: { success: true, loading: false, error: undefined },
    };
}

export function resetPasswordFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        resetPassword: { success: false, loading: false, error: action.payload.error },
    };
}

export function resetPasswordReset(state: IState): IState {
    return {
        ...state,
        resetPassword: initialState.resetPassword,
    };
}

export function updateAuthorizationTokenSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        loggedInUser: {
            ...state.loggedInUser,
            value: {
                ...state.loggedInUser.value,
                authorizationToken: action.payload.authorizationToken,
            },
        },
    };
}
