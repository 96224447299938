import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './SubzoneDetail.types';
import SubzoneDetail from './SubzoneDetail';
import { IRootState } from '../../../../common/types';
import {
    getSubzone,
    resetSubzone,
    getSubzoneProperties,
    resetSubzoneProperties,
} from '../../../../store/subzone/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeSubzone, propertyList } = state.subzone;

    return {
        subzone: activeSubzone.value,
        loading: activeSubzone.loading,
        error: activeSubzone.error,
        properties: propertyList.data,
        propertiesTotal: propertyList.total,
        propertiesPageSize: propertyList.pageSize,
        propertiesLoading: propertyList.loading,
        propertiesError: propertyList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getSubzone,
            resetSubzone,
            getSubzoneProperties,
            resetSubzoneProperties,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SubzoneDetail);
