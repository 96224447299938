import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { reportService } from '../../service';

export function getIncomeDetailedPendingReportExcel(
    paymentItemIds: string[],
    propertyIds: string[],
    shareholderIds: string[],
): IAPIMiddleware {
    return {
        callAPI: () =>
            reportService.getIncomeDetailedPendingReportExcel({
                paymentItemIds: paymentItemIds.map(Number),
                propertyIds: propertyIds.map(Number),
                shareholderIds: shareholderIds.map(Number),
            }),
        types: [
            actionTypes.GET_INCOME_DETAILED_PENDING_REPORT_EXCEL,
            actionTypes.GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_SUCCESS,
            actionTypes.GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_FAILURE,
        ],
    };
}

export function resetIncomeDetailedPendingReportExcel() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_RESET,
        });
    };
}

export function getIncomeDetailedPendingPaymentReportExcel(
    startDate: Date,
    endDate: Date,
    paymentItemIds: string[],
    propertyIds: string[],
    shareholderIds: string[],
): IAPIMiddleware {
    return {
        callAPI: () =>
            reportService.getIncomeDetailedPendingPaymentReportExcel({
                startDate,
                endDate,
                paymentItemIds: paymentItemIds.map(Number),
                propertyIds: propertyIds.map(Number),
                shareholderIds: shareholderIds.map(Number),
            }),
        types: [
            actionTypes.GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL,
            actionTypes.GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_SUCCESS,
            actionTypes.GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_FAILURE,
        ],
    };
}

export function resetIncomeDetailedPendingPaymentReportExcel() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_RESET,
        });
    };
}

export function getIncomeDetailedCompletedReportExcel(
    startDate: Date,
    endDate: Date,
    paymentItemIds: string[],
    propertyIds: string[],
    shareholderIds: string[],
): IAPIMiddleware {
    return {
        callAPI: () =>
            reportService.getIncomeDetailedCompletedReportExcel({
                startDate,
                endDate,
                paymentItemIds: paymentItemIds.map(Number),
                propertyIds: propertyIds.map(Number),
                shareholderIds: shareholderIds.map(Number),
            }),
        types: [
            actionTypes.GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL,
            actionTypes.GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_SUCCESS,
            actionTypes.GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_FAILURE,
        ],
    };
}

export function resetIncomeDetailedCompletedReportExcel() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_RESET,
        });
    };
}

export function getIncomeDetailedAnulledReportExcel(
    startDate: Date,
    endDate: Date,
    paymentItemIds: string[],
    propertyIds: string[],
    shareholderIds: string[],
): IAPIMiddleware {
    return {
        callAPI: () =>
            reportService.getIncomeDetailedAnulledReportExcel({
                startDate,
                endDate,
                paymentItemIds: paymentItemIds.map(Number),
                propertyIds: propertyIds.map(Number),
                shareholderIds: shareholderIds.map(Number),
            }),
        types: [
            actionTypes.GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL,
            actionTypes.GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_SUCCESS,
            actionTypes.GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_FAILURE,
        ],
    };
}

export function resetIncomeDetailedAnulledReportExcel() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_RESET,
        });
    };
}
