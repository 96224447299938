import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './IncomePendingDetailByShareholder.types';
import IncomePendingDetailByShareholder from './IncomePendingDetailByShareholder';
import { IRootState } from '../../../../common/types';
import {
    getShareholderIncomePaymentItems,
    resetShareholderIncomePaymentItems,
    createIncomeShareholderPaymentItem,
    resetCreateIncomeShareholderPaymentItem,
    deleteIncomePaymentItem,
    resetDeleteIncomePaymentItem,
    setCompletePaymentData,
    condoneIncomePaymentItem,
    resetCondoneIncomePaymentItem,
} from '../../../../store/income/actions';
import { getShareholder, resetShareholder } from '../../../../store/shareholder/actions';
import { IIncomePaymentItem } from '../../../../common/types/entities';
import { currencies } from '../../../../common/constants';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeShareholder } = state.shareholder;
    const { loggedInUser } = state.auth;

    const {
        shareholderIncomePaymentItemList,
        createIncomeShareholderPaymentItem,
        deleteIncomePaymentItem,
        condoneIncomePaymentItem,
    } = state.income;

    return {
        shareholder: activeShareholder.value,
        error: activeShareholder.error,
        loading: activeShareholder.loading,
        totalPricePEN: selectTotalPricePEN(shareholderIncomePaymentItemList.data),
        totalPriceUSD: selectTotalPriceUSD(shareholderIncomePaymentItemList.data),
        incomePaymentItems: shareholderIncomePaymentItemList.data,
        incomePaymentItemsLoading: shareholderIncomePaymentItemList.loading,
        createIncomePaymentItemSuccess: createIncomeShareholderPaymentItem.success,
        createIncomePaymentItemLoading: createIncomeShareholderPaymentItem.loading,
        createIncomePaymentItemError: createIncomeShareholderPaymentItem.error,
        deleteIncomePaymentItemSuccess: deleteIncomePaymentItem.success,
        deleteIncomePaymentItemLoading: deleteIncomePaymentItem.loading,
        deleteIncomePaymentItemError: deleteIncomePaymentItem.error,
        condoneSuccess: condoneIncomePaymentItem.success,
        condoneLoading: condoneIncomePaymentItem.loading,
        condoneError: condoneIncomePaymentItem.error,
        loggedInUser: loggedInUser.value,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getShareholder,
            resetShareholder,
            getShareholderIncomePaymentItems,
            resetShareholderIncomePaymentItems,
            createIncomeShareholderPaymentItem,
            resetCreateIncomeShareholderPaymentItem,
            deleteIncomePaymentItem,
            resetDeleteIncomePaymentItem,
            setCompletePaymentData,
            condoneIncomePaymentItem,
            resetCondoneIncomePaymentItem,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomePendingDetailByShareholder);

const selectTotalPricePEN = (data: IIncomePaymentItem[]): number => {
    let totalPrice = 0;
    for (const item of data) {
        if (item.currency.id == currencies.PEN) totalPrice += item.editedPrice || item.price;
    }
    return totalPrice;
};

const selectTotalPriceUSD = (data: IIncomePaymentItem[]): number => {
    let totalPrice = 0;
    for (const item of data) {
        if (item.currency.id == currencies.USD) totalPrice += item.editedPrice || item.price;
    }
    return totalPrice;
};
