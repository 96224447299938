import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { IExpenseDetail, IRequestOrderDetail } from '../../../../../common/types/entities';
import { Input } from 'antd';
import { formatHelper, helper } from '../../../../../common/helpers';
import nanoid from 'nanoid';
import { currencyTypes } from '../../../../../common/constants';

export const Columns = (
    addOrUpdateRequestOrderDetail: (
        expenseDetail: IExpenseDetail,
        quantity: number,
        total: number,
    ) => void,
    requestOrderDetails: IRequestOrderDetail[],
    currencyId: number,
): ColumnProps<any>[] => {
    let expenseDetailCols: ColumnProps<IExpenseDetail>[] = [
        {
            key: nanoid(),
            title: 'Nombre',
            dataIndex: 'name',
            className: 'd-none d-md-table-cell',
        },
        {
            key: nanoid(),
            title: 'Precio Unitario',
            className: 'd-none d-md-table-cell',
            render: (expenseDetail: IExpenseDetail) => (
                <span>
                    {expenseDetail.unitPrice
                        ? expenseDetail.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(expenseDetail.unitPrice, 'PEN')
                            : formatHelper.toMoney(expenseDetail.unitPrice, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            key: nanoid(),
            title: 'Cantidad',
            dataIndex: 'quantity',
            className: 'd-none d-md-table-cell',
        },
        {
            key: nanoid(),
            title: 'Cantidad Restante',
            dataIndex: '',
            className: 'd-none d-md-table-cell',
            render: (item: IExpenseDetail) => <span>{item.quantity - item.totalQty}</span>,
        },
        {
            key: nanoid(),
            title: 'Precio Total',
            className: 'd-none d-md-table-cell',
            render: (expenseDetail: IExpenseDetail) => (
                <span>
                    {expenseDetail.totalPrice
                        ? expenseDetail.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(expenseDetail.totalPrice, 'PEN')
                            : formatHelper.toMoney(expenseDetail.totalPrice, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            key: nanoid(),
            title: 'Comentario',
            dataIndex: 'comment',
            className: 'd-none d-md-table-cell break-row',
        },
    ];

    // TODO xs, keys...

    let requestOrderDetailCols: ColumnProps<IRequestOrderDetail>[] = [
        {
            title: 'Cantidad',
            dataIndex: '',
            className: 'd-none d-md-table-cell',
            render: (item: IExpenseDetail) => {
                let obj =
                    requestOrderDetails.length > 0 &&
                    requestOrderDetails.find(obj => obj.expenseDetail.id === item.id);

                return (
                    <Input
                        disabled={currencyId == null || currencyId != item.currency.id}
                        value={obj ? obj.quantity : 0}
                        onChange={e => {
                            if (e.target.value.length > 0 && !helper.onlyNumbers(e.target.value))
                                return;

                            addOrUpdateRequestOrderDetail(
                                item,
                                Number(e.target.value),
                                Number(item.unitPrice * Number(e.target.value)),
                            );
                        }}
                    />
                );
            },
        },
        {
            key: nanoid(),
            title: 'Precio Total',
            dataIndex: null,
            className: 'd-none d-md-table-cell',
            render: (item: IExpenseDetail) => {
                let requestOrderDetail = requestOrderDetails.find(
                    obj => obj.expenseDetail.id === item.id,
                );
                return (
                    <span>
                        {formatHelper.toMoney(
                            requestOrderDetail ? item.unitPrice * requestOrderDetail.quantity : 0,
                            item.currency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                        )}
                    </span>
                );
            },
        },
    ];

    const totalPrice =
        requestOrderDetails.length > 0
            ? requestOrderDetails
                  .map(item => item.totalPrice)
                  .reduce((prev, next) => prev + next, 0)
            : 0;

    return [
        {
            title: 'Detalle',
            children: [
                {
                    title: 'Detalle de Pedido',
                    className: 'bg-th-header',
                    colSpan: 0,
                    children: expenseDetailCols,
                },
                {
                    title:
                        'Detalle de Solicitud de Compra, Total: ' +
                        formatHelper.toMoney(totalPrice),
                    className: 'bg-th-header',
                    children: requestOrderDetailCols,
                },
            ],
        },
    ];
};
