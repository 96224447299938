import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../components';
import { IProperty } from '../../../../../common/types/entities';

export const columns = (): ColumnProps<IProperty>[] => [
    {
        title: 'Código',
        dataIndex: 'code',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Zona',
        dataIndex: 'zone.name',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Acciones',
        dataIndex: 'shares',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Puesto',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IProperty) => (
            <>
                <TableCellItem label="Código" value={item.code} />
                <TableCellItem label="Zona" value={item.zone ? item.zone.name : ''} />
                <TableCellItem label="Acciones" value={item.shares} />
            </>
        ),
    },
];
