import React, { FunctionComponent, useEffect } from 'react';
import { SectionContainer } from '../../../../components';
import { IProps } from './ShareholderAddEdit.types';
import ShareholderForm from './component/shareholderForm';
import { message } from 'antd';
import { paths } from '../../../../routes';

const ShareholderAddEdit: FunctionComponent<IProps> = props => {
    const shareholderId = props.match.params['id'];

    useEffect(() => {
        if (shareholderId) props.getShareholder(shareholderId);
        return () => props.resetShareholder();
    }, []);

    useEffect(() => {
        if (props.createSuccess) {
            message.success('Accionista creado');
            props.resetCreateShareholder();
            goToShareholderList();
        }

        if (props.createError) {
            message.error(props.createError);
            props.resetCreateShareholder();
        }
    }, [props.createSuccess, props.createError]);

    useEffect(() => {
        if (props.updateSuccess) {
            message.success('Accionista actualizado');
            props.resetUpdateShareholder();
            goToShareholderList();
        }

        if (props.updateError) {
            message.error(props.updateError);
            props.resetUpdateShareholder();
        }
    }, [props.updateSuccess, props.updateError]);

    const onSubmit = (values: any) => {
        if (shareholderId) props.updateShareholder(shareholderId, values);
        else props.createShareholder(values);
    };

    const goToShareholderList = () => props.history.push(paths.ADMIN_SHAREHOLDER_LIST);

    const renderForm = () => {
        return (
            <ShareholderForm
                initialValues={props.activeShareholder || {}}
                onSubmit={onSubmit}
                disabled={props.activeShareholderLoading}
                submitting={props.createLoading || props.updateLoading}
            />
        );
    };

    return (
        <SectionContainer
            title={shareholderId ? 'Editar Accionista' : 'Nuevo Accionista'}
            onBack={goToShareholderList}
            loading={props.activeShareholderLoading}
            error={props.activeShareholderError}
            content={renderForm}
        />
    );
};

export default ShareholderAddEdit;
