import { paths } from '../../../../routes';
import { permissions } from '../../../../common/constants';

export type IListReportItem = {
    title: string;
    path: string;
    permission?: string;
};

export const incomeItems = (): IListReportItem[] => [
    {
        title: 'Reporte detallado pendientes',
        path: paths.REPORT_INCOME_DETAILED_PENDING,
        permission: permissions.report.READ_INCOME_DETAILED_PENDING_REPORT,
    },
    {
        title: 'Reporte detallado pendientes de pago',
        path: paths.REPORT_INCOME_DETAILED_PENDING_PAYMENT,
        permission: permissions.report.READ_INCOME_DETAILED_PENDING_PAYMENT_REPORT,
    },
    {
        title: 'Reporte detallado completados',
        path: paths.REPORT_INCOME_DETAILED_COMPLETED,
        permission: permissions.report.READ_INCOME_DETAILED_COMPLETED_REPORT,
    },
    {
        title: 'Reporte detallado anulados',
        path: paths.REPORT_INCOME_DETAILED_ANULLED,
        permission: permissions.report.READ_INCOME_DETAILED_ANULLED_REPORT,
    },
];
