import * as React from 'react';
import { IProps } from './ProviderDetail.types';
import { SectionContainer, DisplayItem } from '../../../../components';
import { paths } from '../../../../routes';

class ProviderDetail extends React.Component<IProps> {
    providerID = this.props.match.params['id'];

    componentDidMount() {
        this.props.getProvider(this.providerID);
    }

    componentWillUnmount() {
        this.props.resetProvider();
    }

    renderContent = () => {
        const { provider } = this.props;

        return (
            <>
                <DisplayItem label="RUC" value={provider.ruc} />
                <DisplayItem label="Razón Social" value={provider.businessName} />
                <DisplayItem label="Email" value={provider.email} />
                <DisplayItem label="Teléfono" value={provider.phone} />
                <DisplayItem label="Dirección" value={provider.address} />
            </>
        );
    };

    render() {
        const { provider, loading, error } = this.props;

        return (
            <SectionContainer
                title="Detalle Proveedor"
                onBack={() => this.props.history.push(paths.ADMIN_PROVIDER_LIST)}
                loading={loading || !provider}
                error={error}
                content={this.renderContent}
            />
        );
    }
}

export default ProviderDetail;
