import React from 'react';
import Table, { ColumnProps } from 'antd/lib/table';
import {
    IPaymentDetail,
    IInvoice,
    IInvoiceItem,
    ICurrency,
} from '../../../../../common/types/entities';
import { Input } from 'antd';
import { formatHelper, helper } from '../../../../../common/helpers';
import { currencyTypes } from '../../../../../common/constants';

export const expandedRowRender = (record: IInvoice) => {
    const columns: ColumnProps<IInvoiceItem>[] = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            //  className: 'd-none d-md-table-cell'
        },
        {
            title: 'Precio Unitario',
            // className: 'd-none d-md-table-cell'
            render: (item: any) => (
                <span>{formatHelper.toMoney(item.unitPrice, item.currency.code)}</span>
            ),
        },
        {
            title: 'Cantidad',
            dataIndex: 'quantity',
            // className: 'd-none d-md-table-cell'
        },
        {
            title: 'Precio Total',
            // className: 'd-none d-md-table-cell'
            render: (item: any) => (
                <span>{formatHelper.toMoney(item.totalPrice, item.currency.code)}</span>
            ),
        },
    ];

    return <Table columns={columns} dataSource={record.invoiceItems} pagination={false} />;
};

// TODO xs

export const columns = (
    addOrUpdatePaymentDetail: (
        paymentPrice: number,
        invoiceId: number,
        paymentRest: number,
    ) => void,
    paymentDetails: IPaymentDetail[],
    currency: ICurrency,
): ColumnProps<any>[] => {
    const totalPrice =
        paymentDetails.length > 0
            ? paymentDetails
                .map(item => item.totalPrice)
                .reduce((prev, next) => Number(prev) + Number(next), 0)
            : 0;

    return [
        {
            title: 'Código de Factura',
            dataIndex: 'code',
            // className: 'd-none d-md-table-cell',
        },
        {
            title: 'Fecha de Emisión',
            dataIndex: 'emissionDate',
            // className: 'd-none d-md-table-cell',
            render: (emissionDate: any) => <span>{formatHelper.toDate(emissionDate)}</span>,
        },
        {
            title: 'Fecha de Vencimiento',
            dataIndex: 'dueDate',
            // className: 'd-none d-md-table-cell',
            render: (dueDate: any) => <span>{formatHelper.toDate(dueDate)}</span>,
        },
        {
            title: 'Precio Total',
            // className: 'd-none d-md-table-cell',
            render: (item: any) => (
                <span>{formatHelper.toMoney(item.totalPrice, item.currency.code)}</span>
            ),
        },
        {
            title: 'Monto Restante',
            // className: 'd-none d-md-table-cell',
            render: (item: any) => (
                <span>{formatHelper.toMoney(item.paymentRest, item.currency.code)}</span>
            ),
        },
        {
            title:
                'Pago Total: ' +
                formatHelper.toMoney(
                    Number(totalPrice),
                    currency ? (currency.id == currencyTypes.PEN ? 'PEN' : 'USD') : null,
                ),
            dataIndex: '',
            // className: 'd-none d-md-table-cell bg-th-header-cs',
            className: 'bg-th-header-cs',
            render: (item: any) => {
                const obj =
                    paymentDetails.length > 0 &&
                    paymentDetails.find(obj => obj.invoice.id === item.id);
                const disabledInput = currency
                    ? currency.id == item.currency.id
                        ? false
                        : true
                    : true;

                return (
                    <Input
                        // @ts-ignore
                        value={obj ? obj.totalPrice : null}
                        disabled={item.paid || !item.active || disabledInput}
                        onChange={e => {
                            let val = e.target.value;
                            if (val.length > 0) {
                                if (val > item.paymentRest) return;
                                else if (!helper.onlyNumbersWithDecimals(val)) return;
                            }
                            // @ts-ignore
                            addOrUpdatePaymentDetail(val, item.id, item.paymentRest);
                        }}
                    />
                );
            },
        },
    ];
};
