import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    getProperty,
    resetProperty,
    createProperty,
    updateProperty,
} from '../../../../store/property/actions';
import { getZones, resetZones } from '../../../../store/zone/actions';
import { getSubzones, resetSubzones } from '../../../../store/subzone/actions';
import { IStoreState } from './PropertyAddEdit.types';
import PropertyAddEdit from './PropertyAddEdit';
import { IRootState } from '../../../../common/types';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeProperty, createProperty, updateProperty } = state.property;
    const { zoneList } = state.zone;
    const { subzoneList } = state.subzone;

    return {
        activeProperty: activeProperty.value,
        activePropertyLoading: activeProperty.loading,
        activePropertyError: activeProperty.error,
        zones: zoneList.data,
        zonesLoading: zoneList.loading,
        subzones: subzoneList.data,
        subzonesLoading: subzoneList.loading,
        createSuccess: createProperty.success,
        createLoading: createProperty.loading,
        createError: createProperty.error,
        updateSuccess: updateProperty.success,
        updateLoading: updateProperty.loading,
        updateError: updateProperty.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getProperty,
            resetProperty,
            createProperty,
            updateProperty,
            getZones,
            resetZones,
            getSubzones,
            resetSubzones,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PropertyAddEdit);
