import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
    objectStateLoading,
    objectStateSuccess,
    objectStateFailure,
    processStateLoading,
    processStateSuccess,
    processStateFailure,
    listStateLoading,
    listStateSuccess,
    listStateFailure,
} from '../../common/helpers/reducer';

//#region GET BANK_ACCOUNTs
export function getBankAccounts(state: IState): IState {
    return {
        ...state,
        bankAccountList: listStatePaginatedLoading(state.bankAccountList),
    };
}

export function getBankAccountsSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        bankAccountList: listStatePaginatedSuccess(action.payload),
    };
}

export function getBankAccountsFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        bankAccountList: listStatePaginatedFailure(
            initialState.bankAccountList,
            action.payload.error,
        ),
    };
}

export function getBankAccountsReset(state: IState): IState {
    return {
        ...state,
        bankAccountList: initialState.bankAccountList,
    };
}
//#endregion

//#region CREATE BANK ACCOUNT TRANSACTION
export function createBankAccountTransaction(state: IState): IState {
    return {
        ...state,
        createBankAccountTransaction: processStateLoading(),
    };
}

export function createBankAccountTransactionSuccess(state: IState): IState {
    return {
        ...state,
        createBankAccountTransaction: processStateSuccess(),
    };
}

export function createBankAccountTransactionFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createBankAccountTransaction: processStateFailure(action.payload.error),
    };
}

export function createBankAccountTransactionReset(state: IState): IState {
    return {
        ...state,
        createBankAccountTransaction: initialState.createBankAccountTransaction,
    };
}
//#endregion

//#region GET BANK_ACCOUNTS BY CURRENCY
export function getBankAccountsByCurrency(state: IState): IState {
    return {
        ...state,
        bankAccountByCurrencyList: listStateLoading(),
    };
}

export function getBankAccountsByCurrencySuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        bankAccountByCurrencyList: listStateSuccess(action.payload.data),
    };
}

export function getBankAccountsByCurrencyFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        bankAccountByCurrencyList: listStateFailure(action.payload.error),
    };
}

export function getBankAccountsByCurrencyReset(state: IState): IState {
    return {
        ...state,
        bankAccountByCurrencyList: initialState.bankAccountByCurrencyList,
    };
}
//#endregion

//#region GET BANK ACCOUNT TRANSACTIONS
export function getBankAccountTransactions(state: IState): IState {
    return {
        ...state,
        bankAccountTransactionList: listStatePaginatedLoading(state.bankAccountTransactionList),
    };
}
export function getBankAccountTransactionsSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        bankAccountTransactionList: listStatePaginatedSuccess(action.payload),
    };
}
export function getBankAccountTransactionsFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        bankAccountTransactionList: listStatePaginatedFailure(
            initialState.bankAccountTransactionList,
            action.payload.error,
        ),
    };
}
export function getBankAccountTransactionsReset(state: IState): IState {
    return {
        ...state,
        bankAccountTransactionList: initialState.bankAccountTransactionList,
    };
}
//#endregion

//#region GET BANK_ACCOUNT
export function getBankAccount(state: IState): IState {
    return {
        ...state,
        activeBankAccount: objectStateLoading(),
    };
}

export function getBankAccountSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activeBankAccount: objectStateSuccess(action.payload),
    };
}

export function getBankAccountFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activeBankAccount: objectStateFailure(action.payload.error),
    };
}

export function getBankAccountReset(state: IState): IState {
    return {
        ...state,
        activeBankAccount: initialState.activeBankAccount,
    };
}
//#endregion

//#region CREATE BANK_ACCOUNT
export function createBankAccount(state: IState): IState {
    return {
        ...state,
        createBankAccount: processStateLoading(),
    };
}

export function createBankAccountSuccess(state: IState): IState {
    return {
        ...state,
        createBankAccount: processStateSuccess(),
    };
}

export function createBankAccountFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createBankAccount: processStateFailure(action.payload.error),
    };
}

export function createBankAccountReset(state: IState): IState {
    return {
        ...state,
        createBankAccount: initialState.createBankAccount,
    };
}
//#endregion

//#region UPDATE BANK_ACCOUNT
export function updateBankAccount(state: IState): IState {
    return {
        ...state,
        updateBankAccount: processStateLoading(),
    };
}

export function updateBankAccountSuccess(state: IState): IState {
    return {
        ...state,
        updateBankAccount: processStateSuccess(),
    };
}

export function updateBankAccountFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateBankAccount: processStateFailure(action.payload.error),
    };
}

export function updateBankAccountReset(state: IState): IState {
    return {
        ...state,
        updateBankAccount: initialState.updateBankAccount,
    };
}
//#endregion

//#region DEACTIVATE BANK_ACCOUNT
export function deactivateBankAccount(state: IState): IState {
    return {
        ...state,
        deactivateBankAccount: processStateLoading(),
    };
}

export function deactivateBankAccountSuccess(state: IState): IState {
    return {
        ...state,
        deactivateBankAccount: processStateSuccess(),
    };
}

export function deactivateBankAccountFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateBankAccount: processStateFailure(action.payload.error),
    };
}

export function deactivateBankAccountReset(state: IState): IState {
    return {
        ...state,
        deactivateBankAccount: initialState.deactivateBankAccount,
    };
}
//#endregion

//#region GET BANK ACCOUNT TYPES
export function getBankAccountTypes(state: IState): IState {
    return {
        ...state,
        bankAccountTypeList: listStateLoading(),
    };
}

export function getBankAccountTypesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        bankAccountTypeList: listStateSuccess(action.payload.data),
    };
}

export function getBankAccountTypesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        bankAccountTypeList: listStateFailure(action.payload.error),
    };
}

export function getBankAccountTypesReset(state: IState): IState {
    return {
        ...state,
        bankAccountTypeList: initialState.bankAccountTypeList,
    };
}
//#endregion
