import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './IncomePendingListByShareholders.types';
import PendingIncomeByPropertiesList from './IncomePendingListByShareholders';
import { IRootState } from '../../../../../../common/types';
import {
    getIncomePendingsByShareholders,
    resetIncomePendingsByShareholders,
    createIncomeShareholderPaymentItem,
    resetCreateIncomeShareholderPaymentItem,
    bulkLoadIncomePaymentItemsByShareholders,
} from '../../../../../../store/income/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const {
        pendingListByShareholders,
        deleteIncome,
        bulkLoadIncomePaymentItemsByShareholders,
        createIncomeShareholderPaymentItem,
    } = state.income;

    return {
        shareholders: pendingListByShareholders.data,
        total: pendingListByShareholders.total,
        pageSize: pendingListByShareholders.pageSize,
        loading: pendingListByShareholders.loading,
        error: pendingListByShareholders.error,
        createIncomePaymentItemSuccess: createIncomeShareholderPaymentItem.success,
        createIncomePaymentItemLoading: createIncomeShareholderPaymentItem.loading,
        createIncomePaymentItemError: createIncomeShareholderPaymentItem.error,
        deleteLoading: deleteIncome.loading,
        deleteSuccess: deleteIncome.success,
        deleteError: deleteIncome.error,
        bulkLoadSuccess: bulkLoadIncomePaymentItemsByShareholders.success,
        bulkLoadLoading: bulkLoadIncomePaymentItemsByShareholders.loading,
        bulkLoadError: bulkLoadIncomePaymentItemsByShareholders.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getIncomePendingsByShareholders,
            resetIncomePendingsByShareholders,
            createIncomeShareholderPaymentItem,
            resetCreateIncomeShareholderPaymentItem,
            bulkLoadIncomePaymentItemsByShareholders,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingIncomeByPropertiesList);
