export const paths = {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/recuperar-contrasena',
    RESET_PASSWORD: '/restablecer-contrasena/:passwordToken',
    USER_PROFILE: '/mi-perfil',

    ADMIN_USER_LIST: '/administracion/usuarios',
    ADMIN_USER_DETAIL: '/administracion/usuarios/:id/detalle',
    ADMIN_USER_CREATE: '/administracion/usuarios/nuevo',
    ADMIN_USER_EDIT: '/administracion/usuarios/:id/editar',

    ADMIN_CUSTOMER_LIST: '/administracion/comerciantes',
    ADMIN_CUSTOMER_DETAIL: '/administracion/comerciantes/:id/detalle',
    ADMIN_CUSTOMER_CREATE: '/administracion/comerciantes/nuevo',
    ADMIN_CUSTOMER_EDIT: '/administracion/comerciantes/:id/editar',

    ADMIN_ZONE_LIST: '/administracion/zonas',
    ADMIN_ZONE_DETAIL: '/administracion/zonas/:id/detalle',

    ADMIN_SUBZONE_LIST: '/administracion/giros',
    ADMIN_SUBZONE_DETAIL: '/administracion/giros/:id/detalle',

    ADMIN_PROPERTY_LIST: '/administracion/puestos',
    ADMIN_PROPERTY_DETAIL: '/administracion/puestos/:id/detalle',
    ADMIN_PROPERTY_CREATE: '/administracion/puestos/nuevo',
    ADMIN_PROPERTY_SHAREHOLDER_LIST: '/administracion/puestos/:id/accionistas',
    ADMIN_PROPERTY_EDIT: '/administracion/puestos/:id/editar',

    ADMIN_BANK_LIST: '/administracion/bancos',

    ADMIN_BANK_ACCOUNT_LIST: '/administracion/cuentas-bancarias',
    ADMIN_BANK_ACCOUNT_DETAIL: '/administracion/cuentas-bancarias/:id/detalle',
    ADMIN_BANK_ACCOUNT_CREATE: '/administracion/cuentas-bancarias/nuevo',
    ADMIN_BANK_ACCOUNT_EDIT: '/administracion/cuentas-bancarias/:id/editar',

    ADMIN_BANK_ACCOUNT_TRANSACTION: '/administracion/cuentas-bancarias/:id/movimientos',

    ADMIN_PROVIDER_LIST: '/administracion/proveedores',
    ADMIN_PROVIDER_DETAIL: '/administracion/proveedores/:id/detalle',
    ADMIN_PROVIDER_CREATE: '/administracion/proveedores/nuevo',
    ADMIN_PROVIDER_EDIT: '/administracion/proveedores/:id/editar',

    ADMIN_INCOME_PENDING_LIST: '/administracion/ingresos/pendientes',
    ADMIN_INCOME_PENDING_DETAIL_BY_PROPERTY:
        '/administracion/ingresos/pendientes/puestos/:id/detalle',
    ADMIN_INCOME_PENDING_DETAIL_BY_SHAREHOLDER:
        '/administracion/ingresos/pendientes/accionistas/:id/detalle',
    ADMIN_INCOME_COMPLETE_LIST: '/administracion/ingresos/completados',
    ADMIN_INCOME_DETAIL: '/administracion/ingresos/completados/:id/detalle',
    ADMIN_INCOME_CREATE: '/administracion/ingresos/nuevo',
    ADMIN_INCOME_COMPLETE_PAYMENT: '/administracion/ingresos/pendientes/completar-pago',

    ADMIN_PAYMENT_ITEM_LIST: '/administracion/ingresos/conceptos-de-pago',
    ADMIN_PAYMENT_ITEM_CREATE: '/administracion/ingresos/conceptos-de-pago/nuevo',
    ADMIN_PAYMENT_ITEM_EDIT: '/administracion/ingresos/conceptos-de-pago/:id/editar',
    ADMIN_PAYMENT_ITEM_TYPE_LIST: '/administracion/ingresos/tipos-concepto-de-pago',

    ADMIN_SHAREHOLDER_LIST: '/administracion/accionistas',
    ADMIN_SHAREHOLDER_DETAIL: '/administracion/accionistas/:id/detalle',
    ADMIN_SHAREHOLDER_CREATE: '/administracion/accionistas/nuevo',
    ADMIN_SHAREHOLDER_EDIT: '/administracion/accionistas/:id/editar',
    ADMIN_SHAREHOLDER_COOWNER_LIST: '/administracion/accionistas/:id/co-propietarios',

    ADMIN_INVOICE_LIST: '/administracion/egresos/facturas',
    ADMIN_INVOICE_CREATE: '/administracion/egresos/facturas/nuevo',
    ADMIN_INVOICE_DETAIL: '/administracion/egresos/facturas/:id/detalle',

    ADMIN_PAYMENT_LIST: '/administracion/egresos/pagos',
    ADMIN_PAYMENT_CREATE: '/administracion/egresos/pagos/nuevo',
    ADMIN_PAYMENT_DETAIL: '/administracion/egresos/pagos/:id/detalle',

    ADMIN_PETTY_CASH_LIST: '/administracion/egresos/caja-chica',
    ADMIN_PETTY_CASH_DETAIL: '/administracion/egresos/caja-chica/:id/detalle',

    ADMIN_EXPENSE_LIST: '/administracion/egresos/requerimientos',
    ADMIN_EXPENSE_REQUERIMENT_CREATE: '/administracion/egresos/requerimientos/nuevo',
    ADMIN_EXPENSE_REQUERIMENT_EDIT: '/administracion/egresos/requerimientos/:id/editar',
    ADMIN_EXPENSE_LOGISTIC: '/administracion/egresos/requerimientos/:id/logistic',
    ADMIN_EXPENSE_ANSWER: '/administracion/egresos/requerimientos/:id/responder',
    ADMIN_EXPENSE_REQUEST_ORDER: '/administracion/egresos/requerimientos/:id/solicitar-orden',
    ADMIN_EXPENSE_ACCORDANCE: '/administracion/egresos/requerimientos/:id/conformidad',
    ADMIN_EXPENSE_INVOICE: '/administracion/egresos/requerimientos/:id/registro-factura',
    ADMIN_EXPENSE_PAYMENT: '/administracion/egresos/requerimientos/:id/registro-pago',
    ADMIN_EXPENSE_DETAIL: '/administracion/egresos/requerimientos/:id/detalle',
    ADMIN_EXPENSE_EDIT: '/administracion/egresos/requerimientos/:id/editar',

    REPORT_HOME: '/reportes',
    REPORT_INCOME_DETAILED_PENDING: '/reportes/ingresos/detallado-pendientes',
    REPORT_INCOME_DETAILED_PENDING_PAYMENT: '/reportes/ingresos/detallado-pendientes-de-pago',
    REPORT_INCOME_DETAILED_COMPLETED: '/reportes/ingresos/detallado-completados',
    REPORT_INCOME_DETAILED_ANULLED: '/reportes/ingresos/detallado-anulados',

    CONFIGURE_HOME: '/configuracion',
};
