import { IListStatePaginated, IObjectState, IProcessState, IListState } from '../../common/types';
import { IShareholder, IShareholderProperty, ICoOwner } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    shareholderList: IListStatePaginated<IShareholder>;
    activeShareholder: IObjectState<IShareholder>;
    createShareholder: IProcessState;
    updateShareholder: IProcessState;
    deactivateShareholder: IProcessState;
    shareholderPropertyList: IListStatePaginated<IShareholderProperty>;
    coOwnerList: IListState<ICoOwner>;
    createCoOwner: IProcessState;
    updateCoOwner: IProcessState;
    deactivateCoOwner: IProcessState;
}

export const initialState: IState = {
    shareholderList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activeShareholder: { value: undefined, loading: false, error: undefined },
    createShareholder: { success: false, loading: false, error: undefined },
    updateShareholder: { success: false, loading: false, error: undefined },
    deactivateShareholder: { success: false, loading: false, error: undefined },
    shareholderPropertyList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    coOwnerList: { data: [], loading: false, error: undefined },
    createCoOwner: { success: false, loading: false, error: undefined },
    updateCoOwner: { success: false, loading: false, error: undefined },
    deactivateCoOwner: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_SHAREHOLDERS]: caseFunctions.getShareholders,
    [actionTypes.GET_SHAREHOLDERS_SUCCESS]: caseFunctions.getShareholdersSuccess,
    [actionTypes.GET_SHAREHOLDERS_FAILURE]: caseFunctions.getShareholdersFailure,
    [actionTypes.GET_SHAREHOLDERS_RESET]: caseFunctions.getShareholdersReset,

    [actionTypes.GET_SHAREHOLDER]: caseFunctions.getShareholder,
    [actionTypes.GET_SHAREHOLDER_SUCCESS]: caseFunctions.getShareholderSuccess,
    [actionTypes.GET_SHAREHOLDER_FAILURE]: caseFunctions.getShareholderFailure,
    [actionTypes.GET_SHAREHOLDER_RESET]: caseFunctions.getShareholderReset,

    [actionTypes.CREATE_SHAREHOLDER]: caseFunctions.createShareholder,
    [actionTypes.CREATE_SHAREHOLDER_SUCCESS]: caseFunctions.createShareholderSuccess,
    [actionTypes.CREATE_SHAREHOLDER_FAILURE]: caseFunctions.createShareholderFailure,
    [actionTypes.CREATE_SHAREHOLDER_RESET]: caseFunctions.createShareholderReset,

    [actionTypes.UPDATE_SHAREHOLDER]: caseFunctions.updateShareholder,
    [actionTypes.UPDATE_SHAREHOLDER_SUCCESS]: caseFunctions.updateShareholderSuccess,
    [actionTypes.UPDATE_SHAREHOLDER_FAILURE]: caseFunctions.updateShareholderFailure,
    [actionTypes.UPDATE_SHAREHOLDER_RESET]: caseFunctions.updateShareholderReset,

    [actionTypes.DEACTIVATE_SHAREHOLDER]: caseFunctions.deactivateShareholder,
    [actionTypes.DEACTIVATE_SHAREHOLDER_SUCCESS]: caseFunctions.deactivateShareholderSuccess,
    [actionTypes.DEACTIVATE_SHAREHOLDER_FAILURE]: caseFunctions.deactivateShareholderFailure,
    [actionTypes.DEACTIVATE_SHAREHOLDER_RESET]: caseFunctions.deactivateShareholderReset,

    [actionTypes.GET_SHAREHOLDER_PROPERTIES]: caseFunctions.getProperties,
    [actionTypes.GET_SHAREHOLDER_PROPERTIES_SUCCESS]: caseFunctions.getPropertiesSuccess,
    [actionTypes.GET_SHAREHOLDER_PROPERTIES_FAILURE]: caseFunctions.getPropertiesFailure,
    [actionTypes.GET_SHAREHOLDER_PROPERTIES_RESET]: caseFunctions.getPropertiesReset,

    [actionTypes.GET_SHAREHOLDER_COOWNERS]: caseFunctions.getCoOwners,
    [actionTypes.GET_SHAREHOLDER_COOWNERS_SUCCESS]: caseFunctions.getCoOwnersSuccess,
    [actionTypes.GET_SHAREHOLDER_COOWNERS_FAILURE]: caseFunctions.getCoOwnersFailure,
    [actionTypes.GET_SHAREHOLDER_COOWNERS_RESET]: caseFunctions.getCoOwnersReset,

    [actionTypes.CREATE_SHAREHOLDER_COOWNER]: caseFunctions.createCoOwner,
    [actionTypes.CREATE_SHAREHOLDER_COOWNER_SUCCESS]: caseFunctions.createCoOwnerSuccess,
    [actionTypes.CREATE_SHAREHOLDER_COOWNER_FAILURE]: caseFunctions.createCoOwnerFailure,
    [actionTypes.CREATE_SHAREHOLDER_COOWNER_RESET]: caseFunctions.createCoOwnerReset,

    [actionTypes.UPDATE_SHAREHOLDER_COOWNER]: caseFunctions.updateCoOwner,
    [actionTypes.UPDATE_SHAREHOLDER_COOWNER_SUCCESS]: caseFunctions.updateCoOwnerSuccess,
    [actionTypes.UPDATE_SHAREHOLDER_COOWNER_FAILURE]: caseFunctions.updateCoOwnerFailure,
    [actionTypes.UPDATE_SHAREHOLDER_COOWNER_RESET]: caseFunctions.updateCoOwnerReset,

    [actionTypes.DEACTIVATE_SHAREHOLDER_COOWNER]: caseFunctions.deactivateCoOwner,
    [actionTypes.DEACTIVATE_SHAREHOLDER_COOWNER_SUCCESS]: caseFunctions.deactivateCoOwnerSuccess,
    [actionTypes.DEACTIVATE_SHAREHOLDER_COOWNER_FAILURE]: caseFunctions.deactivateCoOwnerFailure,
    [actionTypes.DEACTIVATE_SHAREHOLDER_COOWNER_RESET]: caseFunctions.deactivateCoOwnerReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
