import React, { FC, useEffect } from 'react';
import { Modal, Row, Col, Alert } from 'antd';
import { DataTable } from '../../../../../../components';
import { columns } from './columns';
import { IProps } from './PettyCashMovementItemListModal.types';

const PettyCashMovementItemListModal: FC<IProps> = (props) => {
    useEffect(() => {
        if (!props.isOpen) return;

        props.getPettyCashMovementItems(props.movementId);

        return () => {
            props.resetPettyCashMovementItems();
        };
    }, [props.isOpen]);

    const renderContent = () => {
        if (!props.isOpen) return null;

        if (props.error) return <Alert type="error" message={props.error} />;

        return (
            <Row>
                <Col>
                    <DataTable data={props.items} columns={columns()} loading={props.loading} />
                </Col>
            </Row>
        );
    };

    return (
        <Modal
            title="Items"
            visible={props.isOpen}
            onCancel={props.onCancel}
            maskClosable={false}
            footer={null}
            width={650}
        >
            {renderContent()}
        </Modal>
    );
};

export default PettyCashMovementItemListModal;
