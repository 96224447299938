import React, { FunctionComponent } from 'react';
import { IProps } from './PBR.types';

/**Permission Based Renderer */
const PBR: FunctionComponent<IProps> = ({ permission, permissions, children }) => {
    const renderContent = () => {
        if (permissions.includes(permission)) return <>{children}</>;
        return <></>;
    };

    return renderContent();
};

export default PBR;
