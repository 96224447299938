import React, { FC, useRef, useEffect } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal, Radio } from 'antd';
import { isEmpty, isNumeric } from 'validator';
import {
    messages,
    paymentItemTypes,
    roles,
    paymentItems,
    currencies,
} from '../../../../../../common/constants';
import { getPaymentItems, resetPaymentItems } from '../../../../../../store/paymentItem/actions';
import {
    InputGroupField,
    SelectGroupField,
    ImageUploadGroupField,
    RadioGroupField,
} from '../../../../../../components';
import { IRootState } from '../../../../../../common/types';
import {
    IIncomePaymentItem,
    IPaymentItem,
    IUser,
    ICurrency,
} from '../../../../../../common/types/entities';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { formatHelper } from '../../../../../../common/helpers';

interface IOwnProps {
    isOpen: boolean;
    onSubmit: (values: IAddEditZoneValues) => void;
    disabled?: boolean;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    submitting?: boolean;
    incomePaymentItems: IIncomePaymentItem[];
    loggedInUser: IUser;
    currencies: ICurrency[];
    currency: ICurrency;
}

export interface IAddEditZoneValues {
    paymentItem: { id: number | string };
    currency: { id: number | string };
    price: string;
    comment: string;
    image: any[];
}

interface IStoreState {
    paymentItems: IPaymentItem[];
    paymentItemsLoading: boolean;
}

interface IDispatchProps {
    getPaymentItems: () => void;
    resetPaymentItems: () => void;
}

export type IProps = IOwnProps & IStoreState & IDispatchProps;

const IncomePropertyModalForm: FC<IProps> = props => {
    let form = useRef<Formik<IAddEditZoneValues>>(null);

    useEffect(() => {
        if (props.isOpen == false) return;

        props.getPaymentItems();
        return () => props.resetPaymentItems();
    }, [props.isOpen]);

    const onSubmit = (values: IAddEditZoneValues, actions: FormikActions<IAddEditZoneValues>) => {
        const paymentItem = props.paymentItems.find(x => x.id == values.paymentItem.id);
        if (paymentItem.type.id != paymentItemTypes.PENALTY_FEE) values.image = [];
        values.paymentItem = paymentItem as any;
        values.currency = props.currencies.find(x => x.id == values.currency.id) as any;

        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = e => {
        form.current.resetForm();
        props.onCancel(e);
    };

    const validate = (values: IAddEditZoneValues) => {
        let errors: Partial<IAddEditZoneValues> = {};

        if (!values.paymentItem || !values.paymentItem.id)
            errors.paymentItem = { id: messages.MANDATORY_FIELD };
        if (isEmpty(values.price)) errors.price = messages.MANDATORY_FIELD;
        else if (!isNumeric(values.price)) errors.price = 'El precio debe ser un número';
        else if (Number(values.price) <= 0) errors.price = 'El precio debe ser mayor a cero';

        return errors;
    };

    const onOk = () => form.current.submitForm();

    // const onChangePaymentItem = (value: any) => {
    //     if (!value) form.current.setFieldValue('price', '');

    //     const paymentItem = props.paymentItems.find(x => x.id == value);
    //     if (!paymentItem) return;

    //     form.current.setFieldValue('price', paymentItem.referentialPrice);
    // };

    const isPenaltyFee = (id: number) => {
        const paymentItem = props.paymentItems.find(x => x.id == id);
        if (!paymentItem) return false;
        return paymentItem.type.id == paymentItemTypes.PENALTY_FEE;
    };

    const renderCurrencyRadioButtons = () => {
        const { currency } = props;

        if (!currency) {
            return (
                <>
                    <Radio.Button value={currencies.PEN}>PEN</Radio.Button>
                    <Radio.Button value={currencies.USD}>USD</Radio.Button>
                </>
            );
        } else if (currency.id == currencies.PEN) {
            return <Radio.Button value={currencies.PEN}>PEN</Radio.Button>;
        } else if (currency.id == currencies.USD) {
            return <Radio.Button value={currencies.USD}>USD</Radio.Button>;
        }
    };

    const renderFormik = () => {
        if (!props.isOpen) return null;

        const isScaleOperator = props.loggedInUser.role.id == roles.SCALE_OPERATOR;

        return (
            <Formik
                initialValues={{
                    paymentItem: { id: isScaleOperator ? paymentItems.ELECTRONIC_SCALE : null },
                    currency: { id: props.currency ? props.currency.id : currencies.PEN },
                    price: '',
                    comment: '',
                    image: [],
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={({ values }: FormikProps<IAddEditZoneValues>) => (
                    <Form>
                        <SelectGroupField
                            name="paymentItem.id"
                            label="Concepto"
                            displayText={item => formatHelper.getPaymentItemWithType(item)}
                            required
                            data={props.paymentItems}
                            loading={props.paymentItemsLoading}
                            // onChange={onChangePaymentItem}
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        <InputGroupField
                            name="price"
                            label="Precio"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        <RadioGroupField
                            name="currency.id"
                            label="Moneda"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        >
                            {renderCurrencyRadioButtons()}
                        </RadioGroupField>
                        <InputGroupField
                            name="comment"
                            label="Comentario"
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        {isPenaltyFee(Number(values.paymentItem.id)) && (
                            <ImageUploadGroupField
                                name="image"
                                label="Imagen"
                                lxs={24}
                                lsm={24}
                                lmd={24}
                                llg={24}
                                lxl={6}
                                lxxl={6}
                                imd={24}
                                ixl={18}
                                ilg={24}
                            />
                        )}
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            title="Agregar Concepto de Pago"
            visible={props.isOpen}
            okText="Guardar"
            onOk={onOk}
            okButtonProps={{ loading: props.submitting }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.submitting }}
        >
            {renderFormik()}
        </Modal>
    );
};

function mapStateToProps(state: IRootState): IStoreState {
    const { paymentItemList } = state.paymentItem;

    return {
        paymentItems: paymentItemList.data,
        paymentItemsLoading: paymentItemList.loading,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getPaymentItems,
            resetPaymentItems,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomePropertyModalForm);
