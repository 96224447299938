import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../../../components';
import { IIncome } from '../../../../../../../common/types/entities';
import { formatHelper } from '../../../../../../../common/helpers';
import { incomeStates, receiptTypes } from '../../../../../../../common/constants';
import { Button } from 'antd';

export const columns = (onClickDetail: (id: number) => void): ColumnProps<IIncome>[] => [
    {
        title: 'Código',
        dataIndex: '',
        className: 'd-none d-lg-table-cell',
        render: (item: IIncome) =>
            item.electronicBillingCode ? (
                <span>{item.electronicBillingCode}</span>
            ) : (
                <span>{item.code}</span>
            ),
    },
    {
        title: 'Accionista',
        dataIndex: 'shareholder',
        className: 'd-none d-lg-table-cell',
        render: formatHelper.getShareholderFullname,
    },
    {
        title: 'Cliente',
        dataIndex: '',
        className: 'd-none d-lg-table-cell',
        render: (item: IIncome) => {
            if (item.receiptType.id == receiptTypes.INVOICE) {
                return <span>{item.businessName}</span>;
            } else if (
                item.receiptType.id == receiptTypes.BILL ||
                item.receiptType.id == receiptTypes.VOUCHER
            ) {
                return <span>{item.customerFullname}</span>;
            }

            return <span></span>;
        },
    },
    {
        title: 'Documento',
        dataIndex: '',
        className: 'd-none d-lg-table-cell',
        render: (item: IIncome) => {
            if (item.receiptType.id == receiptTypes.INVOICE) {
                return <span>{item.ruc}</span>;
            } else if (item.receiptType.id == receiptTypes.BILL) {
                return <span>{item.dni}</span>;
            }

            return <span></span>;
        },
    },
    {
        title: 'Tipo de Recibo',
        dataIndex: 'receiptType.description',
        className: 'd-none d-lg-table-cell',
    },
    {
        title: 'Precio Total',
        dataIndex: '',
        className: 'd-none d-lg-table-cell',
        render: (item: IIncome) => (
            <span>{`${item.currency.symbol} ${formatHelper.toMoney(item.totalPrice)}`}</span>
        ),
    },
    {
        title: 'Estado',
        dataIndex: 'state.description',
        className: 'd-none d-lg-table-cell',
    },
    {
        title: 'Fecha y Hora',
        dataIndex: 'paymentDate',
        className: 'd-none d-lg-table-cell',
        render: (paymentDate: Date) => <span>{formatHelper.toDateTime(paymentDate)}</span>,
    },
    {
        key: 'xs',
        title: 'Ingreso',
        dataIndex: '',
        className: 'd-table-cell d-lg-none',
        render: (item: IIncome) => {
            let customer = '';
            let document = '';

            if (item.receiptType.id == receiptTypes.INVOICE) {
                customer = item.businessName;
                document = item.ruc;
            } else if (item.receiptType.id == receiptTypes.BILL) {
                customer = item.customerFullname;
                document = item.dni;
            } else if (item.receiptType.id == receiptTypes.VOUCHER) {
                customer = item.customerFullname;
            }

            return (
                <>
                    <TableCellItem
                        label="Código"
                        value={item.electronicBillingCode ? item.electronicBillingCode : item.code}
                    />
                    <TableCellItem
                        label="Accionista"
                        value={formatHelper.getShareholderFullname(item.shareholder)}
                    />
                    <TableCellItem label="Cliente" value={customer} />
                    <TableCellItem label="Documento" value={document} />
                    <TableCellItem label="Tipo de Recibo" value={item.receiptType.description} />
                    <TableCellItem
                        label="Precio Total"
                        value={`${item.currency.symbol} ${formatHelper.toMoney(item.totalPrice)}`}
                    />
                    <TableCellItem label="Estado" value={item.state.description} />
                    <TableCellItem label="Fecha y Hora" value={item.paymentDate} type="datetime" />
                </>
            );
        },
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IIncome) => {
            return (
                <Button
                    type="primary"
                    shape="round"
                    icon="eye"
                    onClick={() => onClickDetail(item.id)}
                />
            );
        },
    },
];
