import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem, TableRowOptionsDropdown } from '../../../../../components';
import { IProvider } from '../../../../../common/types/entities';
import { permissions } from '../../../../../common/constants';
import { Button } from 'antd';

export const columns = (
    onClickDetail: (id: number) => void,
    onClickEdit: (id: number) => void,
    onClickDelete: (id: number) => void,
    permissionCodes: string[],
): ColumnProps<IProvider>[] => [
    {
        title: 'Proveedor',
        dataIndex: '',
        className: 'd-none d-md-table-cell',
        render: (item: IProvider) => {
            return `${item.businessName || ''} ${item.ruc || item.dni || item.ce || ''}`;
        },
    },
    {
        title: 'Correo',
        dataIndex: 'email',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Teléfono',
        dataIndex: 'phone',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Proveedor',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IProvider) => (
            <>
                <TableCellItem label="Razón Social" value={item.businessName} />
                <TableCellItem label="RUC" value={item.ruc} />
                <TableCellItem label="Teléfono" value={item.phone} />
                <TableCellItem label="DNI" value={item.dni} />
                <TableCellItem label="Correo" value={item.email} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IProvider) => {
            if (permissionCodes.includes(permissions.provider.WRITE_PROVIDERS)) {
                return (
                    <TableRowOptionsDropdown
                        onClickDetail={() => onClickDetail(item.id)}
                        onClickEdit={() => onClickEdit(item.id)}
                        onClickDelete={() => onClickDelete(item.id)}
                    />
                );
            } else if (permissionCodes.includes(permissions.provider.READ_PROVIDERS)) {
                return (
                    <Button
                        type="primary"
                        shape="round"
                        icon="eye"
                        onClick={() => onClickDetail(item.id)}
                    />
                );
            }
        },
    },
];
