import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import nanoid from 'nanoid';
import { IRequestOrder } from '../../../../../common/types/entities';
import { Checkbox } from 'antd';
import { TableCellItem } from '../../../../../components';
import { currencyTypes } from '../../../../../common/constants';
import { formatHelper } from '../../../../../common/helpers';

export const columns = (
    onClickCheck: (id: number, value: boolean) => void,
    disableChecks: boolean,
): ColumnProps<IRequestOrder>[] => {
    return [
        {
            key: nanoid(),
            title: 'Orden',
            dataIndex: 'order',
            className: 'd-none d-md-table-cell',
        },
        {
            key: nanoid(),
            title: 'Fecha de Inicio',
            dataIndex: 'startDate',
            className: 'd-none d-md-table-cell',
        },
        {
            key: nanoid(),
            title: 'Fecha de Entrega',
            dataIndex: 'endDate',
            className: 'd-none d-md-table-cell',
        },
        {
            key: nanoid(),
            title: 'Precio Total',
            className: 'd-none d-md-table-cell',
            render: (requestOrder: IRequestOrder) => (
                <span>
                    {requestOrder.totalPrice
                        ? requestOrder.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(requestOrder.totalPrice, 'PEN')
                            : formatHelper.toMoney(requestOrder.totalPrice, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            key: nanoid(),
            title: 'Pago Restante',
            className: 'd-none d-md-table-cell',
            render: (requestOrder: IRequestOrder) => (
                <span>
                    {requestOrder.remainingPayment
                        ? requestOrder.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(requestOrder.remainingPayment, 'PEN')
                            : formatHelper.toMoney(requestOrder.remainingPayment, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            key: nanoid(),
            title: 'Comentario',
            dataIndex: 'comment',
            className: 'd-none d-md-table-cell',
        },
        {
            key: 'xs',
            title: 'Orden de Compra',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (item: IRequestOrder) => (
                <>
                    <TableCellItem label="Orden" value={item.order} />
                    <TableCellItem label="Fecha de Inicio" value={item.startDate} type="date" />
                    <TableCellItem label="Fecha de Entrega" value={item.endDate} type="date" />
                    <TableCellItem label="Precio Total" value={item.totalPrice} type="money" />
                    <TableCellItem
                        label="Pago Restante"
                        value={item.remainingPayment}
                        type="money"
                    />
                    <TableCellItem label="Comentario" value={item.comment} />
                </>
            ),
        },
        {
            title: 'Conformidad',
            render: (item: IRequestOrder) => (
                <div>
                    <Checkbox
                        checked={item.accordance}
                        onChange={e => onClickCheck(item.id, e.target.checked)}
                        disabled={disableChecks}
                    />
                </div>
            ),
        },
    ];
};
