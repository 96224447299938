import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { expenseService } from '../../service';
import { IExpense } from '../../common/types/entities';

export function getExpenses(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => expenseService.getAll(filter, page),
        types: [
            actionTypes.GET_EXPENSES,
            actionTypes.GET_EXPENSES_SUCCESS,
            actionTypes.GET_EXPENSES_FAILURE,
        ],
    };
}

export function resetExpenses() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_EXPENSES_RESET,
        });
    };
}

export function getExpense(id: number): IAPIMiddleware {
    return {
        callAPI: () => expenseService.get(id),
        types: [
            actionTypes.GET_EXPENSE,
            actionTypes.GET_EXPENSE_SUCCESS,
            actionTypes.GET_EXPENSE_FAILURE,
        ],
    };
}

export function resetExpense() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_EXPENSE_RESET,
        });
    };
}

export function getExpenseDetails(id: number): IAPIMiddleware {
    return {
        callAPI: () => expenseService.getExpenseDetails(id),
        types: [
            actionTypes.GET_EXPENSE_DETAILS,
            actionTypes.GET_EXPENSE_DETAILS_SUCCESS,
            actionTypes.GET_EXPENSE_DETAILS_FAILURE,
        ],
    };
}

export function getExpenseDetailsLogistic(id: number): IAPIMiddleware {
    return {
        callAPI: () => expenseService.getExpenseDetailsLogistic(id),
        types: [
            actionTypes.GET_EXPENSE_DETAILS,
            actionTypes.GET_EXPENSE_DETAILS_SUCCESS,
            actionTypes.GET_EXPENSE_DETAILS_FAILURE,
        ]
    }
}

export function resetExpenseDetails() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_EXPENSE_DETAILS_RESET,
        });
    };
}

export function createExpense(data: IExpense): IAPIMiddleware {
    return {
        callAPI: () => expenseService.create(data),
        types: [
            actionTypes.CREATE_EXPENSE,
            actionTypes.CREATE_EXPENSE_SUCCESS,
            actionTypes.CREATE_EXPENSE_FAILURE,
        ],
    };
}

export function updateExpense(id: number, data: IExpense): IAPIMiddleware {
    return {
        callAPI: () => expenseService.update(id, data),
        types: [
            actionTypes.UPDATE_EXPENSE,
            actionTypes.UPDATE_EXPENSE_SUCCESS,
            actionTypes.UPDATE_EXPENSE_FAILURE,
        ],
    };
}

export function completeLogistic(id: number, data: IExpense): IAPIMiddleware {
    return {
        callAPI: () => expenseService.completeLogistic(id, data),
        types: [
            actionTypes.COMPLETE_LOGISTIC,
            actionTypes.COMPLETE_LOGISTIC_SUCCESS,
            actionTypes.COMPLETE_LOGISTIC_FAILURE,
        ],
    };
}

export function deactivateExpense(id: number): IAPIMiddleware {
    return {
        callAPI: () => expenseService.deactivate(id),
        types: [
            actionTypes.DEACTIVATE_EXPENSE,
            actionTypes.DEACTIVATE_EXPENSE_SUCCESS,
            actionTypes.DEACTIVATE_EXPENSE_FAILURE,
        ],
    };
}

export function resetDeactivateExpense() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DEACTIVATE_EXPENSE_RESET
        })
    }
}

export function getExpenseTypes(): IAPIMiddleware {
    return {
        callAPI: () => expenseService.getTypes(),
        types: [
            actionTypes.GET_EXPENSE_TYPES,
            actionTypes.GET_EXPENSE_TYPES_SUCCESS,
            actionTypes.GET_EXPENSE_TYPES_FAILURE,
        ],
    };
}

export function resetExpenseTypes() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_EXPENSE_TYPES_RESET,
        });
    };
}

export function deleteExpense(id: number): IAPIMiddleware {
    return {
        callAPI: () => expenseService.deleteExpense(id),
        types: [
            actionTypes.DELETE_EXPENSE,
            actionTypes.DELETE_EXPENSE_SUCCESS,
            actionTypes.DELETE_EXPENSE_FAILURE,
        ],
    };
}

export function resetDeleteExpense() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_EXPENSE_RESET,
        });
    };
}

export function deleteExpenseDetail(id: number): IAPIMiddleware {
    return {
        callAPI: () => expenseService.deleteExpenseDetail(id),
        types: [
            actionTypes.DELETE_EXPENSE_DETAIL,
            actionTypes.DELETE_EXPENSE_DETAIL_SUCCESS,
            actionTypes.DELETE_EXPENSE_DETAIL_FAILURE,
        ],
    };
}

export function resetDeleteExpenseDetail() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_EXPENSE_DETAIL_RESET,
        });
    };
}

export function answerExpense(id: number, data: any): IAPIMiddleware {
    return {
        callAPI: () => expenseService.answerExpense(id, data),
        types: [
            actionTypes.ANSWER_EXPENSE,
            actionTypes.ANSWER_EXPENSE_SUCCESS,
            actionTypes.ANSWER_EXPENSE_FAILURE,
        ],
    };
}

export function resetAnswerExpense() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ANSWER_EXPENSE_RESET,
        });
    };
}

export function completeExpense(id: number, data: IExpense): IAPIMiddleware {
    return {
        callAPI: () => expenseService.completeExpense(id, data),
        types: [
            actionTypes.COMPLETE_EXPENSE,
            actionTypes.COMPLETE_EXPENSE_SUCCESS,
            actionTypes.COMPLETE_EXPENSE_FAILURE,
        ],
    };
}

export function resetCompleteExpense() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.COMPLETE_EXPENSE_RESET,
        });
    };
}
