const actionTypes = {
  GET_REQUEST_ORDERS_BY_EXPENSE: 'expense/GET_REQUEST_ORDERS_BY_EXPENSE',
  GET_REQUEST_ORDERS_BY_EXPENSE_SUCCESS: 'expense/GET_REQUEST_ORDERS_BY_EXPENSE_SUCCESS',
  GET_REQUEST_ORDERS_BY_EXPENSE_FAILURE: 'expense/GET_REQUEST_ORDERS_BY_EXPENSE_FAILURE',
  GET_REQUEST_ORDERS_BY_EXPENSE_RESET: 'expense/GET_REQUEST_ORDERS_BY_EXPENSE_RESET',

  CREATE_REQUEST_ORDER: 'request_order/GET_REQUEST_ORDER',
  CREATE_REQUEST_ORDER_SUCCESS: 'request_order/GET_REQUEST_ORDER_SUCCESS',
  CREATE_REQUEST_ORDER_FAILURE: 'request_order/GET_REQUEST_ORDER_FAILURE',
  CREATE_REQUEST_ORDER_RESET: 'request_order/GET_REQUEST_ORDER_RESET',

  ACCORDING_REQUEST_ORDER: 'expense/ACCORDING_REQUEST_ORDER',
  ACCORDING_REQUEST_ORDER_SUCCESS: 'expense/ACCORDING_REQUEST_ORDER_SUCCESS',
  ACCORDING_REQUEST_ORDER_FAILURE: 'expense/ACCORDING_REQUEST_ORDER_FAILURE',

  GET_REQUEST_ORDER_DETAILS: 'expense/GET_REQUEST_ORDER_DETAILS',
  GET_REQUEST_ORDER_DETAILS_SUCCESS: 'expense/GET_REQUEST_ORDER_DETAILS_SUCCESS',
  GET_REQUEST_ORDER_DETAILS_FAILURE: 'expense/GET_REQUEST_ORDER_DETAILS_FAILURE',
  GET_REQUEST_ORDER_DETAILS_RESET: 'expense/GET_REQUEST_ORDER_DETAILS_RESET',
}

export default actionTypes;