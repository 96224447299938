import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { bankService } from '../../service';
import { IBank } from '../../common/types/entities';

export function getBanks(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => bankService.getAll(filter, page),
        types: [
            actionTypes.GET_BANKS,
            actionTypes.GET_BANKS_SUCCESS,
            actionTypes.GET_BANKS_FAILURE,
        ],
    };
}

export function resetBanks() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_BANKS_RESET,
        });
    };
}

export function resetBank() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_BANK_RESET,
        });
    };
}

export function createBank(data: IBank): IAPIMiddleware {
    return {
        callAPI: () => bankService.create(data),
        types: [
            actionTypes.CREATE_BANK,
            actionTypes.CREATE_BANK_SUCCESS,
            actionTypes.CREATE_BANK_FAILURE,
        ],
    };
}

export function updateBank(id: number, data: IBank): IAPIMiddleware {
    return {
        callAPI: () => bankService.update(id, data),
        types: [
            actionTypes.UPDATE_BANK,
            actionTypes.UPDATE_BANK_SUCCESS,
            actionTypes.UPDATE_BANK_FAILURE,
        ],
    };
}

export function deactivateBank(id: number): IAPIMiddleware {
    return {
        callAPI: () => bankService.deactivate(id),
        types: [
            actionTypes.DEACTIVATE_BANK,
            actionTypes.DEACTIVATE_BANK_SUCCESS,
            actionTypes.DEACTIVATE_BANK_FAILURE,
        ],
    };
}
