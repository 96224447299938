import React from 'react';
import { Row, Col, Input, Select } from 'antd';
import { columns } from './misc/columns';
import { IProps, IState } from './IncomeListByShareholders.types';
import { DataTable } from '../../../../../../components';
import { paths } from '../../../../../../routes';
import { helper } from '../../../../../../common/helpers';
import { incomeStates } from '../../../../../../common/constants';

export default class IncomeListByShareholders extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        stateId: incomeStates.COMPLETED,
        page: 1,
    };

    componentDidMount() {
        this.props.getIncomeStates();
        this.performGetIncomes();
    }

    componentWillUnmount() {
        this.props.resetIncomeStates();
        this.props.resetIncomesByShareholders();
    }

    performGetIncomes() {
        const { stateId, filter, page } = this.state;
        this.props.getIncomesByShareholders(stateId, filter, page);
    }

    async filterIncomes() {
        await this.setState({ page: 1 });
        this.performGetIncomes();
    }

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetIncomes();
    };

    onStateChange = async (stateId: number) => {
        await this.setState({ page: 1, stateId });
        this.performGetIncomes();
    };

    onClickDetail = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_INCOME_DETAIL, { id }));
    };

    render() {
        const {
            loading,
            incomes,
            pageSize,
            total,
            incomeStates: states,
            incomeStatesLoading,
        } = this.props;

        return (
            <>
                <Row className="mb-4">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={e => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterIncomes()}
                        />
                    </Col>
                    <Col className="ml-md-3 ml-lg-4" md={11} lg={6}>
                        <Row>
                            <Col className="mt-2 mt-md-1" md={5} lg={6}>
                                Estado:
                            </Col>
                            <Col md={18} lg={18}>
                                {states && (
                                    <Select
                                        className="w-100"
                                        value={this.state.stateId}
                                        onChange={this.onStateChange}
                                        loading={incomeStatesLoading}
                                    >
                                        <Select.Option key={0} value={0}>
                                            {'(TODOS)'}
                                        </Select.Option>
                                        {states.map(item => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.description}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            data={incomes}
                            columns={columns(this.onClickDetail)}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}
