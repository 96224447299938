import React, { FC, useRef } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal } from 'antd';
import { isEmpty, isNumeric, isInt } from 'validator';
import { InputGroupField } from '../../../../../../components';
import { messages, expenseTypes } from '../../../../../../common/constants';
import { IExpenseDetail } from '../../../../../../common/types/entities';

interface IOwnProps {
    isOpen: boolean;
    onSubmit: (values: IExpenseDetail) => void;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    typeId: number;
}

export interface IAddEditExpenseDetailValues {
    name: string;
}

export type IProps = IOwnProps;

const AddEditModal: FC<IProps> = props => {
    let form = useRef<Formik<IAddEditExpenseDetailValues>>(null);

    const onSubmit = (values: any, actions: FormikActions<IAddEditExpenseDetailValues>) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = e => {
        if (form && form.current) form.current.resetForm();
        props.onCancel(e);
    };

    const validate = (values: IAddEditExpenseDetailValues) => {
        const errors: Partial<IAddEditExpenseDetailValues> = {};
        if (isEmpty(values.name)) errors.name = messages.MANDATORY_FIELD;

        return errors;
    };

    const onOk = () => {
        if (form && form.current) form.current.submitForm();
    };

    const renderFormik = () => {
        return props.isOpen
            ? <Formik
                initialValues={{
                    name: '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={(formikBag: FormikProps<IAddEditExpenseDetailValues>) => (
                    <Form>
                        <InputGroupField
                            name="name"
                            label="Nombre"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                    </Form>
                )}
            />
            : null
    };

    return <Modal
        title={props.typeId == expenseTypes.GOODS ? 'Agregar Bien' : 'Agregar Servicio'}
        visible={props.isOpen}
        okText="Guardar"
        onOk={onOk}
        cancelText="Cancelar"
        onCancel={handleOnCancel}
    >
        {renderFormik()}
    </Modal>
};

export default AddEditModal;
