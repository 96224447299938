import * as React from 'react';
import { FormikActions, FormikProps } from 'formik';
import { CustomForm, InputGroupField } from '../../../../../../components';
import { IShareholder } from '../../../../../../common/types/entities';
import { messages } from '../../../../../../common/constants';
import { isEmpty, isEmail, isNumeric } from 'validator';

interface IProps {
    initialValues: IShareholder;
}

interface IShareholderForm {
    code?: string;
    firstname?: string;
    lastname?: string;
    dni?: string;
    ce?: string;
    ruc?: string;
    businessName?: string;
    email?: string;
    phone?: string;
    address?: string;
}

export default class ShareholderForm extends CustomForm<IShareholderForm, IProps> {
    initialValues = (props: IProps): IShareholderForm => {
        const { initialValues } = props;

        return {
            code: initialValues.code || '',
            firstname: initialValues.firstname || '',
            lastname: initialValues.lastname || '',
            dni: initialValues.dni || '',
            ce: initialValues.ce || '',
            ruc: initialValues.ruc || '',
            businessName: initialValues.businessName || '',
            email: initialValues.email || '',
            phone: initialValues.phone || '',
            address: initialValues.address || '',
        };
    };

    validate = (values: IShareholderForm) => {
        const errors: Partial<IShareholderForm> = {};

        if (isEmpty(values.code)) errors.code = messages.MANDATORY_FIELD;
        if (isEmpty(values.firstname)) errors.firstname = messages.MANDATORY_FIELD;
        if (isEmpty(values.lastname)) errors.lastname = messages.MANDATORY_FIELD;

        if (!isEmpty(values.dni) && (values.dni.length !== 8 || !isNumeric(values.dni)))
            errors.dni = 'DNI inválido';
        if (!isEmpty(values.email) && !isEmail(values.email))
            errors.email = 'El valor ingresado no es un correo';

        return errors;
    };

    onSubmit = (values: IShareholderForm, actions: FormikActions<IShareholderForm>) => {
        this.props.onSubmit(values);
        actions.setSubmitting(false);
    };

    personDocIsRequired(dni: string, ce: string, ptp: string) {
        return isEmpty(dni) && isEmpty(ce) && isEmpty(ptp);
    }

    formBody = (formikBag: FormikProps<IShareholderForm>) => {
        return (
            <>
                <InputGroupField name="code" label="Código" required />
                <InputGroupField name="firstname" label="Nombre" required />
                <InputGroupField name="lastname" label="Apeliidos" required />
                <InputGroupField name="dni" label="DNI" maxLength={8} />
                <InputGroupField name="ce" label="CE" />
                <InputGroupField name="ruc" label="RUC" maxLength={11} />
                <InputGroupField name="businessName" label="Razón Social" />
                <InputGroupField name="email" label="Email" />
                <InputGroupField name="phone" label="Teléfono" />
                <InputGroupField name="address" label="Dirección" />
            </>
        );
    };
}
