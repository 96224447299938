import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es-us';
import { Result } from 'antd';
import Routes from '../../routes';
import { IRootState } from '../../common/types';
import { verifyLoggedInUser } from '../../store/auth/actions';
import { Page500 } from '../../scenes';
import { IUser } from '../../common/types/entities';

interface IState {
    hasError: boolean;
}

interface IDispatchProps {
    verifyLoggedInUser: () => void;
}

interface IStoreState {
    authenticated?: boolean;
    error?: string;
    loggedInUser: IUser;
}

type IProps = IStoreState & IDispatchProps;

class Setup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidMount() {
        moment.locale('es');
        this.props.verifyLoggedInUser();
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({ hasError: true });
    }

    renderError() {
        return <Result status="error" title="Error" subTitle="Ha ocurrido un error inesperado" />;
    }

    render() {
        if (this.state.hasError) this.renderError();
        else if (this.props.authenticated === undefined) return <div />;
        else if (this.props.error) return <Page500 />;

        return <Routes loggedInUser={this.props.loggedInUser} />;
    }
}

function mapStateToProps(state: IRootState): IStoreState {
    return {
        authenticated: state.auth.authenticated,
        loggedInUser: state.auth.loggedInUser.value,
        error: state.auth.verifyLoggedInUser.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            verifyLoggedInUser,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
