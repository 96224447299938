const actionTypes = {
    GET_BANKS: 'bank/GET_BANKS',
    GET_BANKS_SUCCESS: 'bank/GET_BANKS_SUCCESS',
    GET_BANKS_FAILURE: 'bank/GET_BANKS_FAILURE',
    GET_BANKS_RESET: 'bank/GET_BANKS_RESET',

    GET_BANK: 'bank/GET_BANK',
    GET_BANK_SUCCESS: 'bank/GET_BANK_SUCCESS',
    GET_BANK_FAILURE: 'bank/GET_BANK_FAILURE',
    GET_BANK_RESET: 'bank/GET_BANK_RESET',

    CREATE_BANK: 'bank/CREATE_BANK',
    CREATE_BANK_SUCCESS: 'bank/CREATE_BANK_SUCCESS',
    CREATE_BANK_FAILURE: 'bank/CREATE_BANK_FAILURE',
    CREATE_BANK_RESET: 'bank/CREATE_BANK_RESET',

    UPDATE_BANK: 'bank/UPDATE_BANK',
    UPDATE_BANK_SUCCESS: 'bank/UPDATE_BANK_SUCCESS',
    UPDATE_BANK_FAILURE: 'bank/UPDATE_BANK_FAILURE',
    UPDATE_BANK_RESET: 'bank/UPDATE_BANK_RESET',

    DEACTIVATE_BANK: 'bank/DEACTIVATE_BANK',
    DEACTIVATE_BANK_SUCCESS: 'bank/DEACTIVATE_BANK_SUCCESS',
    DEACTIVATE_BANK_FAILURE: 'bank/DEACTIVATE_BANK_FAILURE',
    DEACTIVATE_BANK_RESET: 'bank/DEACTIVATE_BANK_RESET',
};

export default actionTypes;
