import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../components';
import { IExpenseDetail } from '../../../../../common/types/entities';
import { formatHelper } from '../../../../../common/helpers';
import { currencyTypes } from '../../../../../common/constants';

export const goodsColumns = (): ColumnProps<IExpenseDetail>[] => {
    return [
        {
            title: 'Nombre',
            dataIndex: 'name',
            className: 'd-none d-md-table-cell',
        },
        {
            title: 'Precio Unitario',
            className: 'd-none d-md-table-cell',
            render: (expenseDetail: IExpenseDetail) => (
                <span>
                    {expenseDetail.unitPrice
                        ? expenseDetail.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(expenseDetail.unitPrice, 'PEN')
                            : formatHelper.toMoney(expenseDetail.unitPrice, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            title: 'Cantidad',
            dataIndex: 'quantity',
            className: 'd-none d-md-table-cell',
        },
        {
            title: 'Precio Total',
            className: 'd-none d-md-table-cell',
            render: (expenseDetail: IExpenseDetail) => (
                <span>
                    {expenseDetail.totalPrice
                        ? expenseDetail.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(expenseDetail.totalPrice, 'PEN')
                            : formatHelper.toMoney(expenseDetail.totalPrice, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            title: 'Comentario',
            dataIndex: 'comment',
            className: 'd-none d-md-table-cell',
        },
        {
            key: 'xs',
            title: 'Egreso',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (item: IExpenseDetail) => (
                <>
                    <TableCellItem label="Nombre" value={item.name} />
                    <TableCellItem
                        label="Precio Unitario"
                        value={
                            item.unitPrice
                                ? item.currency.id == currencyTypes.PEN
                                    ? formatHelper.toMoney(item.unitPrice, 'PEN')
                                    : formatHelper.toMoney(item.unitPrice, 'USD')
                                : ''
                        }
                    />
                    <TableCellItem label="Cantidad" value={item.quantity} />
                    <TableCellItem
                        label="Precio Total"
                        value={
                            item.totalPrice
                                ? item.currency.id == currencyTypes.PEN
                                    ? formatHelper.toMoney(item.totalPrice, 'PEN')
                                    : formatHelper.toMoney(item.totalPrice, 'USD')
                                : ''
                        }
                    />
                    <TableCellItem label="Comentario" value={item.comment} />
                </>
            ),
        },
    ];
};

export const servicesColumns = (): ColumnProps<IExpenseDetail>[] => {
    return [
        {
            title: 'Nombre',
            dataIndex: 'name',
            className: 'd-none d-md-table-cell',
        },
        {
            title: 'Precio',
            className: 'd-none d-md-table-cell',
            render: (expenseDetail: IExpenseDetail) => (
                <span>
                    {expenseDetail.unitPrice
                        ? expenseDetail.currency.id == currencyTypes.PEN
                            ? formatHelper.toMoney(expenseDetail.unitPrice, 'PEN')
                            : formatHelper.toMoney(expenseDetail.unitPrice, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            title: 'Comentario',
            dataIndex: 'comment',
            className: 'd-none d-md-table-cell',
        },
        {
            key: 'xs',
            title: 'Egreso',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (item: IExpenseDetail) => (
                <>
                    <TableCellItem label="Nombre" value={item.name} />
                    <TableCellItem
                        label="Precio Unitario"
                        value={
                            item.unitPrice
                                ? item.currency.id == currencyTypes.PEN
                                    ? formatHelper.toMoney(item.unitPrice, 'PEN')
                                    : formatHelper.toMoney(item.unitPrice, 'USD')
                                : ''
                        }
                    />
                    <TableCellItem label="Comentario" value={item.comment} />
                </>
            ),
        },
    ];
};
