import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
    objectStateLoading,
    objectStateSuccess,
    objectStateFailure,
    processStateLoading,
    processStateSuccess,
    processStateFailure,
} from '../../common/helpers/reducer';

//#region GET ZONES
export function getZones(state: IState): IState {
    return {
        ...state,
        zoneList: listStatePaginatedLoading(state.zoneList),
    };
}

export function getZonesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        zoneList: listStatePaginatedSuccess(action.payload),
    };
}

export function getZonesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        zoneList: listStatePaginatedFailure(initialState.zoneList, action.payload.error),
    };
}

export function getZonesReset(state: IState): IState {
    return {
        ...state,
        zoneList: initialState.zoneList,
    };
}
//#endregion

//#region GET ZONE
export function getZone(state: IState): IState {
    return {
        ...state,
        activeZone: objectStateLoading(),
    };
}

export function getZoneSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activeZone: objectStateSuccess(action.payload),
    };
}

export function getZoneFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activeZone: objectStateFailure(action.payload.error),
    };
}

export function getZoneReset(state: IState): IState {
    return {
        ...state,
        activeZone: initialState.activeZone,
    };
}
//#endregion

//#region CREATE CUSTOMER
export function createZone(state: IState): IState {
    return {
        ...state,
        createZone: processStateLoading(),
    };
}

export function createZoneSuccess(state: IState): IState {
    return {
        ...state,
        createZone: processStateSuccess(),
    };
}

export function createZoneFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createZone: processStateFailure(action.payload.error),
    };
}

export function createZoneReset(state: IState): IState {
    return {
        ...state,
        createZone: initialState.createZone,
    };
}
//#endregion

//#region UPDATE CUSTOMER
export function updateZone(state: IState): IState {
    return {
        ...state,
        updateZone: processStateLoading(),
    };
}

export function updateZoneSuccess(state: IState): IState {
    return {
        ...state,
        updateZone: processStateSuccess(),
    };
}

export function updateZoneFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateZone: processStateFailure(action.payload.error),
    };
}

export function updateZoneReset(state: IState): IState {
    return {
        ...state,
        updateZone: initialState.updateZone,
    };
}
//#endregion

//#region DEACTIVATE CUSTOMER
export function deactivateZone(state: IState): IState {
    return {
        ...state,
        deactivateZone: processStateLoading(),
    };
}

export function deactivateZoneSuccess(state: IState): IState {
    return {
        ...state,
        deactivateZone: processStateSuccess(),
    };
}

export function deactivateZoneFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateZone: processStateFailure(action.payload.error),
    };
}

export function deactivateZoneReset(state: IState): IState {
    return {
        ...state,
        deactivateZone: initialState.deactivateZone,
    };
}
//#endregion

//#region GET ZONE PROPERTIES
export function getZoneProperties(state: IState): IState {
    return {
        ...state,
        propertyList: listStatePaginatedLoading(state.propertyList),
    };
}

export function getZonePropertiesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        propertyList: listStatePaginatedSuccess(action.payload),
    };
}

export function getZonePropertiesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        propertyList: listStatePaginatedFailure(initialState.propertyList, action.payload.error),
    };
}

export function getZonePropertiesReset(state: IState): IState {
    return {
        ...state,
        propertyList: initialState.propertyList,
    };
}
//#endregion
