import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { getPayment } from '../../../../store/payment/actions'
import { IStoreState } from './PaymentDetail.types'
import { IRootState } from '../../../../common/types'
import PaymentDetail from './PaymentDetail'

function mapStateToProps(state: IRootState): IStoreState {
  const {
    activePayment,
  } = state.payment;

  return {
    payment: activePayment.value,
    loading: activePayment.loading,
    error: activePayment.error,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    getPayment,
  }, dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentDetail)