const actionTypes = {
    GET_INCOMES_BY_PROPERTIES: 'income/GET_INCOMES_BY_PROPERTIES',
    GET_INCOMES_BY_PROPERTIES_SUCCESS: 'income/GET_INCOMES_BY_PROPERTIES_SUCCESS',
    GET_INCOMES_BY_PROPERTIES_FAILURE: 'income/GET_INCOMES_BY_PROPERTIES_FAILURE',
    GET_INCOMES_BY_PROPERTIES_RESET: 'income/GET_INCOMES_BY_PROPERTIES_RESET',

    GET_INCOMES_BY_SHAREHOLDERS: 'income/GET_INCOMES_BY_SHAREHOLDERS',
    GET_INCOMES_BY_SHAREHOLDERS_SUCCESS: 'income/GET_INCOMES_BY_SHAREHOLDERS_SUCCESS',
    GET_INCOMES_BY_SHAREHOLDERS_FAILURE: 'income/GET_INCOMES_BY_SHAREHOLDERS_FAILURE',
    GET_INCOMES_BY_SHAREHOLDERS_RESET: 'income/GET_INCOMES_BY_SHAREHOLDERS_RESET',

    GET_PENDINGS_BY_PROPERTIES: 'income/GET_PENDINGS_BY_PROPERTIES',
    GET_PENDINGS_BY_PROPERTIES_SUCCESS: 'income/GET_PENDINGS_BY_PROPERTIES_SUCCESS',
    GET_PENDINGS_BY_PROPERTIES_FAILURE: 'income/GET_PENDINGS_BY_PROPERTIES_FAILURE',
    GET_PENDINGS_BY_PROPERTIES_RESET: 'income/GET_PENDINGS_BY_PROPERTIES_RESET',

    GET_PENDINGS_BY_SHAREHOLDERS: 'income/GET_PENDINGS_BY_SHAREHOLDERS',
    GET_PENDINGS_BY_SHAREHOLDERS_SUCCESS: 'income/GET_PENDINGS_BY_SHAREHOLDERS_SUCCESS',
    GET_PENDINGS_BY_SHAREHOLDERS_FAILURE: 'income/GET_PENDINGS_BY_SHAREHOLDERS_FAILURE',
    GET_PENDINGS_BY_SHAREHOLDERS_RESET: 'income/GET_PENDINGS_BY_SHAREHOLDERS_RESET',

    CREATE_INCOME_PROPERTY_PAYMENT_ITEM: 'income/CREATE_INCOME_PROPERTY_PAYMENT_ITEM',
    CREATE_INCOME_PROPERTY_PAYMENT_ITEM_SUCCESS:
        'income/CREATE_INCOME_PROPERTY_PAYMENT_ITEM_SUCCESS',
    CREATE_INCOME_PROPERTY_PAYMENT_ITEM_FAILURE:
        'income/CREATE_INCOME_PROPERTY_PAYMENT_ITEM_FAILURE',
    CREATE_INCOME_PROPERTY_PAYMENT_ITEM_RESET: 'income/CREATE_INCOME_PROPERTY_PAYMENT_ITEM_RESET',

    CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM: 'income/CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM',
    CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_SUCCESS:
        'income/CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_SUCCESS',
    CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_FAILURE:
        'income/CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_FAILURE',
    CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_RESET:
        'income/CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_RESET',

    GET_INCOME: 'income/GET_INCOME',
    GET_INCOME_SUCCESS: 'income/GET_INCOME_SUCCESS',
    GET_INCOME_FAILURE: 'income/GET_INCOME_FAILURE',
    GET_INCOME_RESET: 'income/GET_INCOME_RESET',

    CREATE_INCOME: 'income/CREATE_INCOME',
    CREATE_INCOME_SUCCESS: 'income/CREATE_INCOME_SUCCESS',
    CREATE_INCOME_FAILURE: 'income/CREATE_INCOME_FAILURE',
    CREATE_INCOME_RESET: 'income/CREATE_INCOME_RESET',

    UPDATE_INCOME: 'income/UPDATE_INCOME',
    UPDATE_INCOME_SUCCESS: 'income/UPDATE_INCOME_SUCCESS',
    UPDATE_INCOME_FAILURE: 'income/UPDATE_INCOME_FAILURE',
    UPDATE_INCOME_RESET: 'income/UPDATE_INCOME_RESET',

    COMPLETE_PAYMENT: 'income/COMPLETE_PAYMENT',
    COMPLETE_PAYMENT_SUCCESS: 'income/COMPLETE_PAYMENT_SUCCESS',
    COMPLETE_PAYMENT_FAILURE: 'income/COMPLETE_PAYMENT_FAILURE',
    COMPLETE_PAYMENT_RESET: 'income/COMPLETE_PAYMENT_RESET',

    DEACTIVATE_INCOME: 'income/DEACTIVATE_INCOME',
    DEACTIVATE_INCOME_SUCCESS: 'income/DEACTIVATE_INCOME_SUCCESS',
    DEACTIVATE_INCOME_FAILURE: 'income/DEACTIVATE_INCOME_FAILURE',
    DEACTIVATE_INCOME_RESET: 'income/DEACTIVATE_INCOME_RESET',

    DELETE_INCOME: 'income/DELETE_INCOME',
    DELETE_INCOME_SUCCESS: 'income/DELETE_INCOME_SUCCESS',
    DELETE_INCOME_FAILURE: 'income/DELETE_INCOME_FAILURE',
    DELETE_INCOME_RESET: 'income/DELETE_INCOME_RESET',

    GET_INCOME_TYPES: 'income/GET_INCOME_TYPES',
    GET_INCOME_TYPES_SUCCESS: 'income/GET_INCOME_TYPES_SUCCESS',
    GET_INCOME_TYPES_FAILURE: 'income/GET_INCOME_TYPES_FAILURE',
    GET_INCOME_TYPES_RESET: 'income/GET_INCOME_TYPES_RESET',

    GET_INCOME_STATES: 'income/GET_INCOME_STATES',
    GET_INCOME_STATES_SUCCESS: 'income/GET_INCOME_STATES_SUCCESS',
    GET_INCOME_STATES_FAILURE: 'income/GET_INCOME_STATES_FAILURE',
    GET_INCOME_STATES_RESET: 'income/GET_INCOME_STATES_RESET',

    GET_PAYMENT_TYPES: 'income/GET_PAYMENT_TYPES',
    GET_PAYMENT_TYPES_SUCCESS: 'income/GET_PAYMENT_TYPES_SUCCESS',
    GET_PAYMENT_TYPES_FAILURE: 'income/GET_PAYMENT_TYPES_FAILURE',
    GET_PAYMENT_TYPES_RESET: 'income/GET_PAYMENT_TYPES_RESET',

    GET_RECEIPT_TYPES: 'income/GET_RECEIPT_TYPES',
    GET_RECEIPT_TYPES_SUCCESS: 'income/GET_RECEIPT_TYPES_SUCCESS',
    GET_RECEIPT_TYPES_FAILURE: 'income/GET_RECEIPT_TYPES_FAILURE',
    GET_RECEIPT_TYPES_RESET: 'income/GET_RECEIPT_TYPES_RESET',

    BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES:
        'income/BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES',
    BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_SUCCESS:
        'income/BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_SUCCESS',
    BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_FAILURE:
        'income/BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_FAILURE',
    BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_RESET:
        'income/BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_RESET',

    BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS:
        'income/BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS',
    BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_SUCCESS:
        'income/BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_SUCCESS',
    BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_FAILURE:
        'income/BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_FAILURE',
    BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_RESET:
        'income/BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_RESET',

    GET_PROPERTY_INCOME_PAYMENT_ITEMS: 'income/GET_PROPERTY_INCOME_PAYMENT_ITEMS',
    GET_PROPERTY_INCOME_PAYMENT_ITEMS_SUCCESS: 'income/GET_PROPERTY_INCOME_PAYMENT_ITEMS_SUCCESS',
    GET_PROPERTY_INCOME_PAYMENT_ITEMS_FAILURE: 'income/GET_PROPERTY_INCOME_PAYMENT_ITEMS_FAILURE',
    GET_PROPERTY_INCOME_PAYMENT_ITEMS_RESET: 'income/GET_PROPERTY_INCOME_PAYMENT_ITEMS_RESET',

    GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS: 'income/GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS',
    GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_SUCCESS:
        'income/GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_SUCCESS',
    GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_FAILURE:
        'income/GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_FAILURE',
    GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_RESET: 'income/GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_RESET',

    DELETE_INCOME_PAYMENT_ITEM: 'income/DELETE_INCOME_PAYMENT_ITEM',
    DELETE_INCOME_PAYMENT_ITEM_SUCCESS: 'income/DELETE_INCOME_PAYMENT_ITEM_SUCCESS',
    DELETE_INCOME_PAYMENT_ITEM_FAILURE: 'income/DELETE_INCOME_PAYMENT_ITEM_FAILURE',
    DELETE_INCOME_PAYMENT_ITEM_RESET: 'income/DELETE_INCOME_PAYMENT_ITEM_RESET',

    CANCEL_INCOME: 'income/CANCEL_INCOME',
    CANCEL_INCOME_SUCCESS: 'income/CANCEL_INCOME_SUCCESS',
    CANCEL_INCOME_FAILURE: 'income/CANCEL_INCOME_FAILURE',
    CANCEL_INCOME_RESET: 'income/CANCEL_INCOME_RESET',

    SET_COMPLETE_PAYMENT_DATA: 'income/SET_COMPLETE_PAYMENT_DATA',
    SET_COMPLETE_PAYMENT_DATA_RESET: 'income/SET_COMPLETE_PAYMENT_DATA_RESET',

    CONDONE_INCOME_PAYMENT_ITEM: 'income/CONDONE_INCOME_PAYMENT_ITEM',
    CONDONE_INCOME_PAYMENT_ITEM_SUCCESS: 'income/CONDONE_INCOME_PAYMENT_ITEM_SUCCESS',
    CONDONE_INCOME_PAYMENT_ITEM_FAILURE: 'income/CONDONE_INCOME_PAYMENT_ITEM_FAILURE',
    CONDONE_INCOME_PAYMENT_ITEM_RESET: 'income/CONDONE_INCOME_PAYMENT_ITEM_RESET',

    VERIFY_PRINT_INCOME: 'income/VERIFY_PRINT_INCOME',
    VERIFY_PRINT_INCOME_SUCCESS: 'income/VERIFY_PRINT_INCOME_SUCCESS',
    VERIFY_PRINT_INCOME_FAILURE: 'income/VERIFY_PRINT_INCOME_FAILURE',
    VERIFY_PRINT_INCOME_RESET: 'income/VERIFY_PRINT_INCOME_RESET',

    COMPLETE_PENDING_INCOME: 'income/COMPLETE_PENDING_INCOME',
    COMPLETE_PENDING_INCOME_SUCCESS: 'income/COMPLETE_PENDING_INCOME_SUCCESS',
    COMPLETE_PENDING_INCOME_FAILURE: 'income/COMPLETE_PENDING_INCOME_FAILURE',
    COMPLETE_PENDING_INCOME_RESET: 'income/COMPLETE_PENDING_INCOME_RESET',
};

export default actionTypes;
