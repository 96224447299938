import { IState, initialState } from './reducer'
import { IAction } from '../../common/types'
import { listStateFailure, listStateLoading, listStateSuccess, processStateLoading, processStateSuccess, processStateFailure } from '../../common/helpers/reducer';

//#region GET Request Order BY EXPENSE
export function getRequestOrdersByExpense(state: IState): IState {
  return {
    ...state,
    requestOrderByExpenseList: listStateLoading()
  };
}

export function getRequestOrdersByExpenseSuccess(state: IState, action: IAction): IState {
  return {
    ...state,
    requestOrderByExpenseList: listStateSuccess(action.payload.data),
  };
}

export function getRequestOrdersByExpenseFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    requestOrderByExpenseList: listStateFailure(action.payload.error),
  };
}

export function getRequestOrdersByExpenseReset(state: IState): IState {
  return {
    ...state,
    requestOrderByExpenseList: initialState.requestOrderByExpenseList,
  };
}
//#endregion

//#region create request order
export function createRequestOrder(state: IState): IState {
  return {
    ...state,
    newRequestOrder: { success: false, loading: true, error: undefined }
  }
}

export function createRequestOrderSuccess(state: IState): IState {
  return {
    ...state,
    newRequestOrder: { success: true, loading: false, error: undefined },
  }
}

export function createRequestOrderFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    newRequestOrder: { success: false, loading: false, error: action.payload.error }
  }
}

export function createRequestOrderReset(state: IState): IState {
  return {
    ...state,
    newRequestOrder: initialState.newRequestOrder
  }
}
//#endregion request order

//#region ACCORDING REQUEST ORDER
export function accordingRequestOrder(state: IState): IState {
  return {
    ...state,
    accordingRequestOrder: processStateLoading(),
  };
}

export function accordingRequestOrderSuccess(state: IState): IState {
  return {
    ...state,
    accordingRequestOrder: processStateSuccess(),
  };
}

export function accordingRequestOrderFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    accordingRequestOrder: processStateFailure(action.payload.error),
  };
}
//#endregion

//#region GET Request Order Details
export function getRequestOrderDetails(state: IState): IState {
  return {
    ...state,
    requestOrderDetailList: listStateLoading(),
  };
}

export function getRequestOrderDetailsSuccess(state: IState, action: IAction): IState {
  return {
    ...state,
    requestOrderDetailList: listStateSuccess(action.payload.data),
  };
}

export function getRequestOrderDetailsFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    requestOrderDetailList: listStateFailure(action.payload.error),
  };
}

export function getRequestOrderDetailsReset(state: IState): IState {
  return {
    ...state,
    requestOrderDetailList: initialState.requestOrderDetailList,
  };
}
//#endregion