import React from 'react';
import { IProps } from './PaymentCreate.types';
import { message } from 'antd';
import { paths } from '../../../../routes';
import { SectionContainer } from '../../../../components';
import PaymentForm, { IPaymentFormValues } from './components/PaymentForm';
import { Formik } from 'formik';

export default class IPaymentCreate extends React.Component<IProps> {
    private form: Formik<IPaymentFormValues> = null;

    componentDidMount() {
        this.props.getProviders();
        this.props.getBankAccounts();
        this.props.getPaymentTypes();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkPaymentCreation(prevProps);
    }

    componentWillUnmount() {
        this.props.resetProviders();
        this.props.resetPaymentsByProvider();
        this.props.resetBankAccounts();
        this.props.resetPaymentTypes();
        this.props.resetInvoicesByProviderDetails();
    }

    checkPaymentCreation(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.completePaymentProviderSuccess && props.completePaymentProviderSuccess) {
            message.success('Pago registrado');
            this.props.history.push(paths.ADMIN_PAYMENT_LIST);
        } else if (!prevProps.completePaymentProviderError && props.completePaymentProviderError)
            message.error(props.completePaymentProviderError);
    }

    onSubmit = (values: any) => this.props.completePaymentProvider(values);

    onCancel = () => this.props.history.push(paths.ADMIN_PAYMENT_LIST);

    renderForm = () => {
        const {
            providers,
            providersLoading,
            completePaymentProviderLoading,
            getInvoicesByProviderDetails,
            invoicesByProviderDetails,
            invoicesByProviderDetailsLoading,
            bankAccounts,
            bankAccountsLoading,
            paymentTypes,
            paymentTypesLoading,
        } = this.props;

        return (
            <PaymentForm
                onSubmit={this.onSubmit}
                formRef={ref => (this.form = ref)}
                submitting={completePaymentProviderLoading}
                providers={providers}
                providersLoading={providersLoading}
                getInvoicesByProviderDetails={getInvoicesByProviderDetails}
                invoicesByProviderDetails={invoicesByProviderDetails}
                invoicesByProviderDetailsLoading={invoicesByProviderDetailsLoading}
                bankAccounts={bankAccounts}
                bankAccountsLoading={bankAccountsLoading}
                paymentTypes={paymentTypes}
                paymentTypesLoading={paymentTypesLoading}
            />
        );
    };

    render() {
        return (
            <SectionContainer
                title={'Nuevo Pago'}
                onBack={() => this.props.history.push(paths.ADMIN_PAYMENT_LIST)}
                content={this.renderForm}
            />
        );
    }
}
