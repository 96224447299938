import React from 'react';
import { IIncomePaymentItem } from '../types/entities';
import { discountTypes } from '../constants';
import { formatHelper } from '.';

export function incomePaymentItemPrice(incomePaymentItem: IIncomePaymentItem): JSX.Element {
    const { condonedDiscountType, totalCodoned } = incomePaymentItem;

    if (condonedDiscountType && totalCodoned) {
        const symbol = condonedDiscountType.id == discountTypes.PERCENT ? '%' : '';

        return (
            <>
                <div>
                    <span className="line-through mr-2">
                        {`${incomePaymentItem.currency.symbol} ${formatHelper.toMoney(
                            incomePaymentItem.price,
                        )}`}
                    </span>
                    <span>
                        ({`${formatHelper.toMoney(incomePaymentItem.totalCodoned)}${symbol}`})
                    </span>
                </div>
                {incomePaymentItem.condoneComment && (
                    <div>
                        <span style={{ fontSize: 12 }}>{incomePaymentItem.condoneComment}</span>
                    </div>
                )}
                <span>{`${incomePaymentItem.currency.symbol} ${formatHelper.toMoney(
                    incomePaymentItem.editedPrice,
                )}`}</span>
            </>
        );
    }

    return (
        <span>{`${incomePaymentItem.currency.symbol} ${formatHelper.toMoney(
            incomePaymentItem.price,
        )}`}</span>
    );
}
