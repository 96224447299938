import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';

const { SHAREHOLDER_URL } = apiUrls;

export function getAll(filter: string, page: number) {
    const params = { filter, page };

    return APIManager.get(SHAREHOLDER_URL, { params });
}

export function get(id: number) {
    const url = `${SHAREHOLDER_URL}/${id}`;
    return APIManager.get(url);
}

export function create(data: any) {
    return APIManager.post(SHAREHOLDER_URL, { data });
}

export function update(id: number, data: any) {
    const url = `${SHAREHOLDER_URL}/${id}`;
    return APIManager.put(url, { data });
}

export function deactivate(id: number) {
    const url = `${SHAREHOLDER_URL}/${id}/deactivate`;
    return APIManager.put(url);
}

export function getProperties(id: number, filter: string, page: number) {
    const url = `${SHAREHOLDER_URL}/${id}/properties`;
    const params = { filter, page };

    return APIManager.get(url, { params });
}

export function getCoOwners(id: number, filter: string) {
    const url = `${SHAREHOLDER_URL}/${id}/co-owners`;
    return APIManager.get(url, { params: { filter } });
}

export function createCoOwner(id: number, data: any) {
    const url = `${SHAREHOLDER_URL}/${id}/co-owners`;
    return APIManager.post(url, { data });
}

export function updateCoOwner(coOwnerId: number, data: any) {
    const url = `${SHAREHOLDER_URL}/co-owners/${coOwnerId}`;
    return APIManager.put(url, { data });
}

export function deactivateCoOwner(coOwnerId: number) {
    const url = `${SHAREHOLDER_URL}/co-owners/${coOwnerId}/deactivate`;
    return APIManager.put(url);
}
