import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { resetPassword } from '../../../store/auth/actions';
import ResetPassword from './ResetPassword';
import { IStoreState } from './ResetPassword.types';
import { IRootState } from '../../../common/types';

export function mapStateToProps(state: IRootState): IStoreState {
    return {
        success: state.auth.resetPassword.success,
        loading: state.auth.resetPassword.loading,
        error: state.auth.resetPassword.error,
    };
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            resetPassword,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResetPassword);
