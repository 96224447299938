import React, { FC, useRef } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal } from 'antd';
import { isEmpty } from 'validator';
import { InputGroupField, DisplayItem } from '../../../../components';
import { messages } from '../../../../common/constants';
import { IReceiptNumeration } from '../../../../common/types/entities';

interface IOwnProps {
  isOpen: boolean;
  onSubmit: (values: IReceiptNumerationEditForm) => void;
  disabled?: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  initialValues?: IReceiptNumeration;
  submitting?: boolean;
}

export interface IReceiptNumerationEditForm {
  correlative: number | string;
}

export type IProps = IOwnProps;

const ReceiptNumerationEditModal: FC<IProps> = props => {
  let form = useRef<Formik<IReceiptNumerationEditForm>>(null);

  const onSubmit = (
    values: IReceiptNumerationEditForm,
    actions: FormikActions<IReceiptNumerationEditForm>
  ) => {
    props.onSubmit(values);
    actions.setSubmitting(false);
  };

  const handleOnCancel = e => {
    form.current.resetForm();
    props.onCancel(e);
  };

  const validate = (values: IReceiptNumerationEditForm) => {
    let errors: Partial<IReceiptNumerationEditForm> = {};

    if (isEmpty(values.correlative.toString())) errors.correlative = messages.MANDATORY_FIELD;

    return errors;
  };

  const onOk = () => {
    form.current.submitForm();
  };

  const renderFormik = () => {
    if (!props.isOpen) return null;

    const init = props.initialValues || {};

    return (
      <Formik
        initialValues={{
          correlative: init.correlative,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={validate}
        onSubmit={onSubmit}
        ref={form}
        render={(formikBag: FormikProps<IReceiptNumerationEditForm>) => (
          <Form>
            <DisplayItem
              label="Serie"
              value={init ? init.serie : ''}
              lxs={24}
              lsm={24}
              lmd={24}
              llg={24}
              lxl={6}
              lxxl={6}
              vmd={24}
              vxl={18}
              vlg={24}
            />
            <InputGroupField
              name="correlative"
              label="Correlativo"
              normalize='number'
              required
              lxs={24}
              lsm={24}
              lmd={24}
              llg={24}
              lxl={6}
              lxxl={6}
              imd={24}
              ixl={18}
              ilg={24}
            />
          </Form>
        )}
      />
    );
  };

  return (
    <Modal
      destroyOnClose={true}
      title={props.initialValues ? 'Editar Correlativo' : ''}
      visible={props.isOpen}
      okText="Guardar"
      onOk={onOk}
      okButtonProps={{ loading: props.submitting }}
      cancelText="Cancelar"
      onCancel={handleOnCancel}
      cancelButtonProps={{ disabled: props.submitting }}
    >
      {renderFormik()}
    </Modal>
  );
};

export default ReceiptNumerationEditModal;
