const actionTypes = {
    GET_PETTY_CASH_LIST: 'pettyCash/GET_PETTY_CASH_LIST',
    GET_PETTY_CASH_LIST_SUCCESS: 'pettyCash/GET_PETTY_CASH_LIST_SUCCESS',
    GET_PETTY_CASH_LIST_FAILURE: 'pettyCash/GET_PETTY_CASH_LIST_FAILURE',
    GET_PETTY_CASH_LIST_RESET: 'pettyCash/GET_PETTY_CASH_LIST_RESET',

    GET_PETTY_CASH: 'pettyCash/GET_PETTY_CASH',
    GET_PETTY_CASH_SUCCESS: 'pettyCash/GET_PETTY_CASH_SUCCESS',
    GET_PETTY_CASH_FAILURE: 'pettyCash/GET_PETTY_CASH_FAILURE',
    GET_PETTY_CASH_RESET: 'pettyCash/GET_PETTY_CASH_RESET',

    GET_PETTY_CASH_TO_CLOSE: 'pettyCash/GET_PETTY_CASH_TO_CLOSE',
    GET_PETTY_CASH_TO_CLOSE_SUCCESS: 'pettyCash/GET_PETTY_CASH_TO_CLOSE_SUCCESS',
    GET_PETTY_CASH_TO_CLOSE_FAILURE: 'pettyCash/GET_PETTY_CASH_TO_CLOSE_FAILURE',
    GET_PETTY_CASH_TO_CLOSE_RESET: 'pettyCash/GET_PETTY_CASH_TO_CLOSE_RESET',

    CREATE_PETTY_CASH: 'pettyCash/CREATE_PETTY_CASH',
    CREATE_PETTY_CASH_SUCCESS: 'pettyCash/CREATE_PETTY_CASH_SUCCESS',
    CREATE_PETTY_CASH_FAILURE: 'pettyCash/CREATE_PETTY_CASH_FAILURE',
    CREATE_PETTY_CASH_RESET: 'pettyCash/CREATE_PETTY_CASH_RESET',

    GET_PETTY_CASH_MOVEMENTS: 'pettyCash/GET_PETTY_CASH_MOVEMENTS',
    GET_PETTY_CASH_MOVEMENTS_SUCCESS: 'pettyCash/GET_PETTY_CASH_MOVEMENTS_SUCCESS',
    GET_PETTY_CASH_MOVEMENTS_FAILURE: 'pettyCash/GET_PETTY_CASH_MOVEMENTS_FAILURE',
    GET_PETTY_CASH_MOVEMENTS_RESET: 'pettyCash/GET_PETTY_CASH_MOVEMENTS_RESET',

    GET_PETTY_CASH_MOVEMENTS_ITEMS: 'pettyCash/GET_PETTY_CASH_MOVEMENTS_ITEMS',
    GET_PETTY_CASH_MOVEMENTS_ITEMS_SUCCESS: 'pettyCash/GET_PETTY_CASH_MOVEMENTS_ITEMS_SUCCESS',
    GET_PETTY_CASH_MOVEMENTS_ITEMS_FAILURE: 'pettyCash/GET_PETTY_CASH_MOVEMENTS_ITEMS_FAILURE',
    GET_PETTY_CASH_MOVEMENTS_ITEMS_RESET: 'pettyCash/GET_PETTY_CASH_MOVEMENTS_ITEMS_RESET',

    CREATE_PETTY_CASH_MOVEMENT: 'pettyCash/CREATE_PETTY_CASH_MOVEMENT',
    CREATE_PETTY_CASH_MOVEMENT_SUCCESS: 'pettyCash/CREATE_PETTY_CASH_MOVEMENT_SUCCESS',
    CREATE_PETTY_CASH_MOVEMENT_FAILURE: 'pettyCash/CREATE_PETTY_CASH_MOVEMENT_FAILURE',
    CREATE_PETTY_CASH_MOVEMENT_RESET: 'pettyCash/CREATE_PETTY_CASH_MOVEMENT_RESET',

    DELETE_PETTY_CASH_MOVEMENT: 'pettyCash/DELETE_PETTY_CASH_MOVEMENT',
    DELETE_PETTY_CASH_MOVEMENT_SUCCESS: 'pettyCash/DELETE_PETTY_CASH_MOVEMENT_SUCCESS',
    DELETE_PETTY_CASH_MOVEMENT_FAILURE: 'pettyCash/DELETE_PETTY_CASH_MOVEMENT_FAILURE',
    DELETE_PETTY_CASH_MOVEMENT_RESET: 'pettyCash/DELETE_PETTY_CASH_MOVEMENT_RESET',
};

export default actionTypes;
