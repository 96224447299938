import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button, Row, Col, Input, message, Modal } from 'antd';
import { columns } from './misc/columns';
import { IProps } from './PropertyShareholderList.types';
import { SectionContainer, DataTable } from '../../../../components';
import { paths } from '../../../../routes';
import { PropertyShareholderAddModal } from './components';
import { IShareholderProperty } from '../../../../common/types/entities';

const PropertyShareholderList: FunctionComponent<IProps> = props => {
    const propertyId = props.match.params['id'];

    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(1);
    const [showWarnMessage, setShowWarnMessage] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [propertyShareholderToEdit, setPropertyShareholderToEdit] = useState(null);

    useEffect(() => {
        props.getProperty(propertyId);

        return () => {
            props.resetProperty();
            props.resetPropertyShareholders();
        };
    }, []);

    useEffect(() => {
        if (props.property) {
            setShowWarnMessage(props.property.area <= 13 && props.shareholders.length > 0);
        }
    }, [props.property, props.shareholders]);

    useEffect(() => {
        if (props.deletePropertyShareholderSuccess) {
            message.success('Accionista eliminado del puesto');
            refreshShareholderProperties();
            props.resetDeletePropertyShareholder();
        }
        if (props.deletePropertyShareholderError) {
            message.error(props.deletePropertyShareholderError);
            props.resetDeletePropertyShareholder();
        }
    }, [props.deletePropertyShareholderSuccess, props.deletePropertyShareholderError]);

    useEffect(() => {
        if (props.updatePropertyShareholderSuccess) {
            message.success('Accionista actualizado en el puesto');
            setModalOpen(false);
            refreshShareholderProperties();
            props.resetUpdatePropertyShareholder();
        }
        if (props.updatePropertyShareholderError) {
            message.error(props.updatePropertyShareholderError);
            props.resetUpdatePropertyShareholder();
        }
    }, [props.updatePropertyShareholderSuccess, props.updatePropertyShareholderError]);

    useEffect(() => {
        if (props.createPropertyShareholderSuccess) {
            message.success('Accionista agregado al puesto');
            setModalOpen(false);
            refreshShareholderProperties();
            props.resetCreatePropertyShareholder();
        }
        if (props.createPropertyShareholderError) {
            message.error(props.createPropertyShareholderError);
            props.resetCreatePropertyShareholder();
        }
    }, [props.createPropertyShareholderSuccess, props.createPropertyShareholderError]);

    useEffect(() => {
        performGetPropertyShareholders();
    }, [page]);

    const refreshShareholderProperties = () =>
        page === 1 ? performGetPropertyShareholders() : setPage(1);

    const performGetPropertyShareholders = () =>
        props.getPropertyShareholders(propertyId, filter, page);

    const onSearch = () => refreshShareholderProperties();

    const onPageChange = (page: number) => setPage(page);

    const onClickEdit = (item: IShareholderProperty) => {
        setPropertyShareholderToEdit(item);
        setModalOpen(true);
    };

    const onClickDelete = (id: number) => {
        const { deletePropertyShareholder } = props;

        Modal.confirm({
            title: '¿Deseas remover al accionista del puesto?',
            content: 'Eliminar accionista de puesto',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deletePropertyShareholder(id);
            },
            onCancel() {},
        });
    };

    const handleSubmit = (values: IShareholderProperty) => {
        if (propertyShareholderToEdit) {
            props.updatePropertyShareholder(propertyShareholderToEdit.id, values);
            return;
        }
        props.createPropertyShareholder(propertyId, values);
    };

    return (
        <SectionContainer
            loading={props.loading}
            title={props.property && `Accionistas del puesto ${props.property.code}`}
            icon="environment"
            onBack={() => props.history.push(paths.ADMIN_PROPERTY_LIST)}
        >
            <Row className="mb-2">
                <Col md={12} lg={6}>
                    <Input.Search
                        placeholder="Buscar"
                        enterButton
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                        onSearch={onSearch}
                    />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="float-right">
                    <Button icon="plus" type="primary" onClick={() => setModalOpen(true)}>
                        Agregar
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable
                        data={props.shareholders}
                        columns={columns(onClickEdit, onClickDelete)}
                        pageSize={props.shareholdersPageSize}
                        total={props.shareholdersTotal}
                        currentPage={page}
                        onPageChange={onPageChange}
                        loading={props.shareholdersLoading}
                    />
                </Col>
            </Row>
            <PropertyShareholderAddModal
                isOpen={modalOpen}
                property={props.property}
                onCancel={() => {
                    setPropertyShareholderToEdit(null);
                    setModalOpen(false);
                }}
                onSubmit={handleSubmit}
                initialValues={propertyShareholderToEdit}
                submitting={
                    props.createPropertyShareholderLoading || props.updatePropertyShareholderLoading
                }
                showWarnMessage={showWarnMessage}
            />
        </SectionContainer>
    );
};

export default PropertyShareholderList;
