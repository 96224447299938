import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../components';
import { IExpenseDetail } from '../../../../../common/types/entities';
import { formatHelper } from '../../../../../common/helpers';
import { expenseStates, currencyTypes } from '../../../../../common/constants';

export const goodsColumns = (
    stateId: number,
    currencyId?: number,
): ColumnProps<IExpenseDetail>[] => {
    let cols: ColumnProps<IExpenseDetail>[] = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            className: 'd-none d-md-table-cell',
        },
    ];

    if (stateId != expenseStates.PENDING)
        cols = [
            ...cols,
            {
                title: 'Precio Unitario',
                className: 'd-none d-md-table-cell',
                render: (expenseDetail: IExpenseDetail) => (
                    <span>
                        {expenseDetail.unitPrice
                            ? currencyId
                                ? formatHelper.toMoney(
                                      expenseDetail.unitPrice,
                                      currencyId == currencyTypes.PEN ? 'PEN' : 'USD',
                                  )
                                : formatHelper.toMoney(
                                      expenseDetail.unitPrice,
                                      expenseDetail.currency.code,
                                  )
                            : ''}
                    </span>
                ),
            },
            {
                title: 'Cantidad',
                dataIndex: 'quantity',
                className: 'd-none d-md-table-cell',
            },
            {
                title: 'Precio Total',
                className: 'd-none d-md-table-cell',
                render: (expenseDetail: IExpenseDetail) => (
                    <span>
                        {expenseDetail.totalPrice
                            ? currencyId
                                ? formatHelper.toMoney(
                                      expenseDetail.totalPrice,
                                      currencyId == currencyTypes.PEN ? 'PEN' : 'USD',
                                  )
                                : formatHelper.toMoney(
                                      expenseDetail.totalPrice,
                                      expenseDetail.currency.code,
                                  )
                            : ''}
                    </span>
                ),
            },
            {
                title: 'Comentario',
                dataIndex: 'comment',
                className: 'd-none d-md-table-cell',
            },
        ];

    cols = [
        ...cols,
        {
            key: 'xs',
            title: 'Detalle de Orden de Compra',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (expenseDetail: IExpenseDetail) => (
                <>
                    <TableCellItem label="Nombre" value={expenseDetail.name} />
                    {stateId != expenseStates.PENDING && (
                        <>
                            <TableCellItem
                                label="Precio Unitario"
                                value={
                                    expenseDetail.unitPrice
                                        ? currencyId
                                            ? formatHelper.toMoney(
                                                  expenseDetail.unitPrice,
                                                  currencyId == currencyTypes.PEN ? 'PEN' : 'USD',
                                              )
                                            : formatHelper.toMoney(
                                                  expenseDetail.unitPrice,
                                                  expenseDetail.currency.code,
                                              )
                                        : ''
                                }
                            />
                            <TableCellItem label="Cantidad" value={expenseDetail.quantity} />
                            <TableCellItem
                                label="Precio Total"
                                value={
                                    expenseDetail.totalPrice
                                        ? currencyId
                                            ? formatHelper.toMoney(
                                                  expenseDetail.totalPrice,
                                                  currencyId == currencyTypes.PEN ? 'PEN' : 'USD',
                                              )
                                            : formatHelper.toMoney(
                                                  expenseDetail.totalPrice,
                                                  expenseDetail.currency.code,
                                              )
                                        : ''
                                }
                            />
                            <TableCellItem label="Comentario" value={expenseDetail.comment} />
                        </>
                    )}
                </>
            ),
        },
    ];

    return cols;
};

export const servicesColumns = (
    stateId: number,
    currencyId?: number,
): ColumnProps<IExpenseDetail>[] => {
    let cols: ColumnProps<IExpenseDetail>[] = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            className: 'd-none d-md-table-cell',
        },
    ];

    if (stateId != expenseStates.PENDING)
        cols = [
            ...cols,
            {
                title: 'Precio Unitario',
                className: 'd-none d-md-table-cell',
                render: (expenseDetail: IExpenseDetail) => (
                    <span>
                        {expenseDetail.unitPrice
                            ? currencyId
                                ? formatHelper.toMoney(
                                      expenseDetail.unitPrice,
                                      currencyId == currencyTypes.PEN ? 'PEN' : 'USD',
                                  )
                                : formatHelper.toMoney(
                                      expenseDetail.unitPrice,
                                      expenseDetail.currency.code,
                                  )
                            : ''}
                    </span>
                ),
            },
            {
                title: 'Comentario',
                dataIndex: 'comment',
                className: 'd-none d-md-table-cell',
            },
        ];

    cols = [
        ...cols,
        {
            key: 'xs',
            title: 'Detalle de Orden de Compra',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (expenseDetail: IExpenseDetail) => (
                <>
                    <TableCellItem label="Nombre" value={expenseDetail.name} />
                    {stateId != expenseStates.PENDING && (
                        <>
                            <TableCellItem
                                label="Precio Unitario"
                                value={
                                    expenseDetail.unitPrice
                                        ? currencyId
                                            ? formatHelper.toMoney(
                                                  expenseDetail.unitPrice,
                                                  currencyId == currencyTypes.PEN ? 'PEN' : 'USD',
                                              )
                                            : formatHelper.toMoney(
                                                  expenseDetail.unitPrice,
                                                  expenseDetail.currency.code,
                                              )
                                        : ''
                                }
                            />
                            <TableCellItem label="Comentario" value={expenseDetail.comment} />
                        </>
                    )}
                </>
            ),
        },
    ];

    return cols;
};
