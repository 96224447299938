import { apiUrls } from '../common/constants'
import { APIManager } from '../common/utils'
import { IInvoice, IPayment } from '../common/types/entities'

const { INVOICE_URL } = apiUrls

export function getInvoice(id: number) {
  const url = `${INVOICE_URL}/${id}`;
  return APIManager.get(url);
}

export function getInvoicesByRequestOrder(id: number) {
  const url = `${INVOICE_URL}/requestOrder/${id}`;
  return APIManager.get(url);
}

export function getInvoicesByRequestOrderDetails(id: number) {
  const url = `${INVOICE_URL}/requestOrder-details/${id}`;
  return APIManager.get(url);
}

export function getInvoicesByProvider(providerId: number, filter: string, page: number) {
  const
    params = { providerId, filter, page },
    url = `${INVOICE_URL}/list/provider`;

  return APIManager.get(url, { params });
}

export function getInvoicesByProviderDetails(id: number) {
  const url = `${INVOICE_URL}/provider-details/${id}`
  return APIManager.get(url);
}

export function completeInvoice(invoice: IInvoice, payment?: IPayment) {
  const url = `${INVOICE_URL}`;
  return invoice.files && invoice.files.length > 0
    ? APIManager.postWithFiles(url, { data: { invoice, payment } })
    : APIManager.post(url, { data: { invoice, payment } })
}

export function completeInvoiceProvider(invoice: IInvoice, payment?: IPayment) {
  const url = `${INVOICE_URL}/provider`
  return invoice.files && invoice.files.length > 0
    ? APIManager.postWithFiles(url, { data: { invoice, payment } })
    : APIManager.post(url, { data: { invoice, payment } })
}

export function deactivateInvoice(id: number, data: IInvoice) {
  const url = `${INVOICE_URL}/${id}/deactivate`;
  return APIManager.put(url, { data });
}

export function deactivateInvoiceProvider(id: number, data: IInvoice) {
  const url = `${INVOICE_URL}/provider/${id}/deactivate`;
  return APIManager.put(url, { data });
}

