import React, { FunctionComponent, useState, useEffect } from 'react';
import { SectionContainer } from '../../../../components';
import { Row, Col, Select, Button, message } from 'antd';
import { paths } from '../../../../routes';
import { IProps } from './IncomeDetailedPendingReport.types';
import { fileHelper, formatHelper } from '../../../../common/helpers';

const IncomeDetailedPendingReport: FunctionComponent<IProps> = props => {
    const [selectedConcepts, setSelectedConcepts] = useState<string[]>([]);
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
    const [selectedShareholders, setSelectedShareholders] = useState<string[]>([]);

    useEffect(() => {
        props.getPaymentItems();
        props.getProperties();
        props.getShareholders();

        return () => {
            props.resetPaymentItems();
            props.resetProperties();
            props.resetShareholders();
        };
    }, []);

    useEffect(() => {
        if (props.incomeDetailedReportExcel) {
            const { blob, filename } = props.incomeDetailedReportExcel;
            fileHelper.downloadBlob(blob, filename);
            props.resetIncomeDetailedPendingReportExcel();
        }

        if (props.incomeDetailedReportExcelError) {
            message.error(props.incomeDetailedReportExcelError);
            props.resetIncomeDetailedPendingReportExcel();
        }
    }, [props.incomeDetailedReportExcel, props.incomeDetailedReportExcelError]);

    const onPaymentItemsChange = value => {
        setSelectedConcepts(value);
    };

    const onPropertiesChange = value => {
        setSelectedProperties(value);
    };

    const onShareholdersChange = value => {
        setSelectedShareholders(value);
    };

    const onPressDownload = () => {
        props.getIncomeDetailedPendingReportExcel(
            selectedConcepts,
            selectedProperties,
            selectedShareholders,
        );
    };

    return (
        <SectionContainer
            title="Reporte de Ingresos Pendientes Detallado"
            onBack={() => props.history.push(paths.REPORT_HOME)}
        >
            <Row>
                <Col md={16} lg={13} xl={9} className="mr-lg-3 mb-2">
                    <Row>
                        <Col lg={5}>
                            <span>Puestos:</span>
                        </Col>
                        <Col lg={19}>
                            <Select
                                mode="multiple"
                                value={selectedProperties}
                                className="w-100"
                                onChange={onPropertiesChange}
                                allowClear={true}
                                loading={props.paymentItemsLoading}
                            >
                                {props.properties.map(item => (
                                    <Select.Option key={item.id}>{item.code}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Col>
                <Col md={16} lg={13} xl={9} className="mr-lg-3 mb-2">
                    <Row>
                        <Col lg={5}>
                            <span>Accionistas:</span>
                        </Col>
                        <Col lg={19}>
                            <Select
                                mode="multiple"
                                value={selectedShareholders}
                                className="w-100"
                                onChange={onShareholdersChange}
                                allowClear={true}
                                loading={props.shareholdersLoading}
                            >
                                {props.shareholders.map(item => (
                                    <Select.Option key={item.id}>
                                        {formatHelper.getShareholderFullnameWithCode(item)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={16} lg={13} xl={9} className="mr-lg-3 mb-2">
                    <Row>
                        <Col lg={5}>
                            <span>Conceptos:</span>
                        </Col>
                        <Col lg={19}>
                            <Select
                                mode="multiple"
                                value={selectedConcepts}
                                className="w-100"
                                onChange={onPaymentItemsChange}
                                allowClear={true}
                                loading={props.paymentItemsLoading}
                            >
                                {props.paymentItems.map(item => (
                                    <Select.Option key={item.id}>
                                        {formatHelper.getPaymentItemWithType(item)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={24} lg={1} xl={1}>
                    <Button
                        icon="download"
                        type="primary"
                        onClick={onPressDownload}
                        loading={props.incomeDetailedReportExcelLoading}
                    >
                        Descargar
                    </Button>
                </Col>
            </Row>
        </SectionContainer>
    );
};

export default IncomeDetailedPendingReport;
