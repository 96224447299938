import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import {
    TableCellItem,
    TableRowOptionsDropdown,
    TableRowOptionItem,
    PBR,
} from '../../../../../components';
import { IShareholder } from '../../../../../common/types/entities';
import { formatHelper } from '../../../../../common/helpers';
import { permissions } from '../../../../../common/constants';

export const columns = (
    onClickDetail: (id: number) => void,
    onClickEdit: (id: number) => void,
    onClickCoOwners: (id: number) => void,
    onClickDelete: (id: number) => void,
): ColumnProps<IShareholder>[] => [
    {
        title: 'Código',
        dataIndex: 'code',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Accionista',
        dataIndex: '',
        className: 'd-none d-md-table-cell',
        render: formatHelper.getShareholderFullname,
    },
    {
        title: 'DNI',
        dataIndex: 'dni',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'RUC',
        dataIndex: 'ruc',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Razón Social',
        dataIndex: 'businessName',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Accionista',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IShareholder) => (
            <>
                <TableCellItem label="Código" value={item.code} />
                <TableCellItem label="Nombre" value={item.firstname} />
                <TableCellItem label="Apellidos" value={item.lastname} />
                <TableCellItem label="DNI" value={item.dni} />
                <TableCellItem label="RUC" value={item.ruc} />
                <TableCellItem label="Razón Social" value={item.businessName} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IShareholder) => (
            <PBR permission={permissions.shareholder.WRITE_SHAREHOLDERS}>
                <TableRowOptionsDropdown
                    onClickDetail={() => onClickDetail(item.id)}
                    onClickEdit={() => onClickEdit(item.id)}
                    onClickDelete={() => onClickDelete(item.id)}
                >
                    <TableRowOptionItem
                        label="Co-propietarios"
                        icon="user"
                        onClick={() => onClickCoOwners(item.id)}
                    />
                </TableRowOptionsDropdown>
            </PBR>
        ),
    },
];
