import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './IncomeDetailedPendingPaymentReport.types';
import IncomeDetailedAnulledReport from './IncomeDetailedPendingPaymentReport';
import { IRootState } from '../../../../common/types';
import { getPaymentItems, resetPaymentItems } from '../../../../store/paymentItem/actions';
import { getProperties, resetProperties } from '../../../../store/property/actions';
import { getShareholders, resetShareholders } from '../../../../store/shareholder/actions';
import {
    getIncomeDetailedPendingPaymentReportExcel,
    resetIncomeDetailedPendingPaymentReportExcel,
} from '../../../../store/report/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { paymentItemList } = state.paymentItem;
    const { propertyList } = state.property;
    const { shareholderList } = state.shareholder;
    const { incomeDetailedPendingPaymentReportExcel } = state.report;

    return {
        paymentItems: paymentItemList.data,
        paymentItemsLoading: paymentItemList.loading,
        paymentItemsError: paymentItemList.error,
        properties: propertyList.data,
        propertiesLoading: propertyList.loading,
        propertiesError: propertyList.error,
        shareholders: shareholderList.data,
        shareholdersLoading: shareholderList.loading,
        shareholdersError: shareholderList.error,
        incomeDetailedReportExcel: incomeDetailedPendingPaymentReportExcel.value,
        incomeDetailedReportExcelLoading: incomeDetailedPendingPaymentReportExcel.loading,
        incomeDetailedReportExcelError: incomeDetailedPendingPaymentReportExcel.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getPaymentItems,
            resetPaymentItems,
            getIncomeDetailedPendingPaymentReportExcel,
            resetIncomeDetailedPendingPaymentReportExcel,
            getProperties,
            resetProperties,
            getShareholders,
            resetShareholders,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomeDetailedAnulledReport);
