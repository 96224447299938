import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    getPaymentItem,
    resetPaymentItem,
    createPaymentItem,
    updatePaymentItem,
    getPaymentItemTypes,
    resetPaymentItemTypes,
} from '../../../../../store/paymentItem/actions';
import { getTaxes, resetTaxes } from '../../../../../store/tax/actions';
import { IStoreState } from './PaymentItemAddEdit.types';
import PaymentItemAddEdit from './PaymentItemAddEdit';
import { IRootState } from '../../../../../common/types';

function mapStateToProps(state: IRootState): IStoreState {
    const {
        activePaymentItem,
        updatePaymentItem,
        createPaymentItem,
        paymentItemTypeList,
    } = state.paymentItem;

    const { taxList } = state.tax;

    return {
        paymentItemTypes: paymentItemTypeList.data,
        paymentItemTypesLoading: paymentItemTypeList.loading,
        taxes: taxList.data,
        taxesLoading: taxList.loading,
        activePaymentItem: activePaymentItem.value,
        activePaymentItemLoading: activePaymentItem.loading,
        activePaymentItemError: activePaymentItem.error,
        createSuccess: createPaymentItem.success,
        createLoading: createPaymentItem.loading,
        createError: createPaymentItem.error,
        updateSuccess: updatePaymentItem.success,
        updateLoading: updatePaymentItem.loading,
        updateError: updatePaymentItem.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getPaymentItem,
            resetPaymentItem,
            createPaymentItem,
            updatePaymentItem,
            getPaymentItemTypes,
            resetPaymentItemTypes,
            getTaxes,
            resetTaxes,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentItemAddEdit);
