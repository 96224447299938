export const genericCustomer = {
    FULLNAME: 'Cliente Genérico',
    DNI: '99999999',
};

export const customerDocuments = {
    NOT_DOMICILIED: '0', // SI usas esto el igv debe ser de tipo exportacion 16
    DNI: '1',
    CE: '4',
    RUC: '6',
    PASSPORT: '7',
    SUNDRY: '-',
};
