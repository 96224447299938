import React from 'react';
import { SectionContainer } from '../../../../components';
import { IProps } from './UserAddEdit.types';
import UserForm from './component/UserForm';
import { message } from 'antd';
import { paths } from '../../../../routes';

export default class UserAdd extends React.Component<IProps> {
    private userId = this.props.match.params['id'];

    componentDidMount() {
        this.props.getRoles();
        if (this.userId) this.props.getUser(this.userId);
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkUserUpdate(prevProps);
        this.checkUserCreation(prevProps);
    }

    componentWillUnmount() {
        this.props.resetUser();
    }

    checkUserCreation(prevProps: IProps) {
        if (!prevProps.createSuccess && this.props.createSuccess) {
            message.success('Usuario creado');
            this.props.history.push(paths.ADMIN_USER_LIST);
        } else if (!prevProps.createError && this.props.createError)
            message.error(this.props.createError);
    }

    checkUserUpdate(prevProps: IProps) {
        if (!prevProps.updateSuccess && this.props.updateSuccess) {
            message.success('Usuario actualizado');
            this.props.history.push(paths.ADMIN_USER_LIST);
        } else if (!prevProps.updateError && this.props.updateError)
            message.error(this.props.updateError);
    }

    onSubmit = (values: any) => {
        if (this.userId) return this.props.updateUser(this.userId, values);
        return this.props.createUser(values);
    };

    renderForm = () => {
        const { activeUser, activeUserLoading, createLoading, updateLoading } = this.props;

        return (
            <UserForm
                initialValues={activeUser || {}}
                roles={this.props.roles}
                onSubmit={this.onSubmit}
                disabled={activeUserLoading}
                submitting={createLoading || updateLoading}
                editing={this.userId != undefined}
            />
        );
    };

    render() {
        const { activeUserLoading, activeUserError } = this.props;

        return (
            <SectionContainer
                title={this.userId ? 'Editar Usuario' : 'Nuevo Usuario'}
                onBack={() => this.props.history.push(paths.ADMIN_USER_LIST)}
                loading={activeUserLoading}
                error={activeUserError}
                content={this.renderForm}
            />
        );
    }
}
