import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
    objectStateLoading,
    objectStateSuccess,
    objectStateFailure,
    processStateLoading,
    processStateSuccess,
    processStateFailure,
} from '../../common/helpers/reducer';

//#region GET PAYMENT ITEMS
export function getPaymentItems(state: IState): IState {
    return {
        ...state,
        paymentItemList: listStatePaginatedLoading(state.paymentItemList),
    };
}

export function getPaymentItemsSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        paymentItemList: listStatePaginatedSuccess(action.payload),
    };
}

export function getPaymentItemsFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        paymentItemList: listStatePaginatedFailure(
            initialState.paymentItemList,
            action.payload.error,
        ),
    };
}

export function getPaymentItemsReset(state: IState): IState {
    return {
        ...state,
        paymentItemList: initialState.paymentItemList,
    };
}
//#endregion

//#region GET PAYMENT ITEM
export function getPaymentItem(state: IState): IState {
    return {
        ...state,
        activePaymentItem: objectStateLoading(),
    };
}

export function getPaymentItemSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activePaymentItem: objectStateSuccess(action.payload),
    };
}

export function getPaymentItemFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activePaymentItem: objectStateFailure(action.payload.error),
    };
}

export function getPaymentItemReset(state: IState): IState {
    return {
        ...state,
        activePaymentItem: initialState.activePaymentItem,
    };
}
//#endregion

//#region CREATE PAYMENT ITEM
export function createPaymentItem(state: IState): IState {
    return {
        ...state,
        createPaymentItem: processStateLoading(),
    };
}

export function createPaymentItemSuccess(state: IState): IState {
    return {
        ...state,
        createPaymentItem: processStateSuccess(),
    };
}

export function createPaymentItemFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createPaymentItem: processStateFailure(action.payload.error),
    };
}

export function createPaymentItemReset(state: IState): IState {
    return {
        ...state,
        createPaymentItem: initialState.createPaymentItem,
    };
}
//#endregion

//#region UPDATE PAYMENT ITEM
export function updatePaymentItem(state: IState): IState {
    return {
        ...state,
        updatePaymentItem: processStateLoading(),
    };
}

export function updatePaymentItemSuccess(state: IState): IState {
    return {
        ...state,
        updatePaymentItem: processStateSuccess(),
    };
}

export function updatePaymentItemFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updatePaymentItem: processStateFailure(action.payload.error),
    };
}

export function updatePaymentItemReset(state: IState): IState {
    return {
        ...state,
        updatePaymentItem: initialState.updatePaymentItem,
    };
}
//#endregion

//#region DEACTIVATE PAYMENT ITEM
export function deactivatePaymentItem(state: IState): IState {
    return {
        ...state,
        deactivatePaymentItem: processStateLoading(),
    };
}

export function deactivatePaymentItemSuccess(state: IState): IState {
    return {
        ...state,
        deactivatePaymentItem: processStateSuccess(),
    };
}

export function deactivatePaymentItemFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivatePaymentItem: processStateFailure(action.payload.error),
    };
}

export function deactivatePaymentItemReset(state: IState): IState {
    return {
        ...state,
        deactivatePaymentItem: initialState.deactivatePaymentItem,
    };
}
//#endregion

//#region GET PAYMENT ITEM TYPES
export function getPaymentItemTypes(state: IState): IState {
    return {
        ...state,
        paymentItemTypeList: listStatePaginatedLoading(state.paymentItemTypeList),
    };
}

export function getPaymentItemTypesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        paymentItemTypeList: listStatePaginatedSuccess(action.payload),
    };
}

export function getPaymentItemTypesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        paymentItemTypeList: listStatePaginatedFailure(
            initialState.paymentItemTypeList,
            action.payload.error,
        ),
    };
}

export function getPaymentItemTypesReset(state: IState): IState {
    return {
        ...state,
        paymentItemTypeList: initialState.paymentItemTypeList,
    };
}
//#endregion

//#region CREATE PAYMENT ITEM TYPE
export function createPaymentItemType(state: IState): IState {
    return {
        ...state,
        createPaymentItemType: processStateLoading(),
    };
}

export function createPaymentItemTypeSuccess(state: IState): IState {
    return {
        ...state,
        createPaymentItemType: processStateSuccess(),
    };
}

export function createPaymentItemTypeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createPaymentItemType: processStateFailure(action.payload.error),
    };
}

export function createPaymentItemTypeReset(state: IState): IState {
    return {
        ...state,
        createPaymentItemType: initialState.createPaymentItemType,
    };
}
//#endregion

//#region UPDATE PAYMENT ITEM TYPE
export function updatePaymentItemType(state: IState): IState {
    return {
        ...state,
        updatePaymentItemType: processStateLoading(),
    };
}

export function updatePaymentItemTypeSuccess(state: IState): IState {
    return {
        ...state,
        updatePaymentItemType: processStateSuccess(),
    };
}

export function updatePaymentItemTypeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updatePaymentItemType: processStateFailure(action.payload.error),
    };
}

export function updatePaymentItemTypeReset(state: IState): IState {
    return {
        ...state,
        updatePaymentItemType: initialState.updatePaymentItemType,
    };
}
//#endregion

//#region DEACTIVATE PAYMENT ITEM TYPE
export function deactivatePaymentItemType(state: IState): IState {
    return {
        ...state,
        deactivatePaymentItemType: processStateLoading(),
    };
}

export function deactivatePaymentItemTypeSuccess(state: IState): IState {
    return {
        ...state,
        deactivatePaymentItemType: processStateSuccess(),
    };
}

export function deactivatePaymentItemTypeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivatePaymentItemType: processStateFailure(action.payload.error),
    };
}

export function deactivatePaymentItemTypeReset(state: IState): IState {
    return {
        ...state,
        deactivatePaymentItemType: initialState.deactivatePaymentItemType,
    };
}
//#endregion
