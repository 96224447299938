import { IListStatePaginated, IProcessState } from '../../common/types';
import { IReceiptNumeration } from '../../common/types/entities';
import actionTypes from './actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    configureList: IListStatePaginated<IReceiptNumeration>;
    updateConfigure: IProcessState;
}

export const initialState: IState = {
    configureList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    updateConfigure: { success: false, loading: false, error: undefined }
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_CONFIGURES]: caseFunctions.getConfigures,
    [actionTypes.GET_CONFIGURES_SUCCESS]: caseFunctions.getConfiguresSuccess,
    [actionTypes.GET_CONFIGURES_FAILURE]: caseFunctions.getConfiguresFailure,
    [actionTypes.GET_CONFIGURES_RESET]: caseFunctions.resetGetConfigures,

    [actionTypes.UPDATE_CONFIGURES]: caseFunctions.updateConfigure,
    [actionTypes.UPDATE_CONFIGURES_SUCCESS]: caseFunctions.updateConfigureSuccess,
    [actionTypes.UPDATE_CONFIGURES_FAILURE]: caseFunctions.updateConfigureFailure,
    [actionTypes.UPDATE_CONFIGURES_RESET]: caseFunctions.updateConfigureReset,
});
