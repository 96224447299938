import { connect } from 'react-redux';
import { IStoreState } from './ReportHome.types';
import ReportHome from './ReportHome';
import { IRootState } from '../../../common/types';

function mapStateToProps(state: IRootState): IStoreState {
    const { loggedInUser } = state.auth;

    return {
        permissions: loggedInUser.value.permissions,
    };
}

export default connect(mapStateToProps)(ReportHome);
