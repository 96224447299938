import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { bankAccountService } from '../../service';
import { IBankAccount } from '../../common/types/entities';

export function getBankAccounts(
    bankAccountTypeId?: number,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => bankAccountService.getAll(bankAccountTypeId, filter, page),
        types: [
            actionTypes.GET_BANK_ACCOUNTS,
            actionTypes.GET_BANK_ACCOUNTS_SUCCESS,
            actionTypes.GET_BANK_ACCOUNTS_FAILURE,
        ],
    };
}

export function resetBankAccounts() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_BANK_ACCOUNTS_RESET,
        });
    };
}

export function getBankAccountsByCurrency(currencyId: number): IAPIMiddleware {
    return {
        callAPI: () => bankAccountService.getAllByCurrency(currencyId),
        types: [
            actionTypes.GET_BANK_ACCOUNTS_BY_CURRENCY,
            actionTypes.GET_BANK_ACCOUNTS_BY_CURRENCY_SUCCESS,
            actionTypes.GET_BANK_ACCOUNTS_BY_CURRENCY_FAILURE,
        ],
    };
}

export function resetBankAccountsByCurrency() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_BANK_ACCOUNTS_BY_CURRENCY_RESET,
        });
    };
}

export function getBankAccountTransactions(
    bankAccountId: number,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => bankAccountService.getTransactions(bankAccountId, filter, page),
        types: [
            actionTypes.GET_BANK_ACCOUNT_TRANSACTIONS,
            actionTypes.GET_BANK_ACCOUNT_TRANSACTIONS_SUCCESS,
            actionTypes.GET_BANK_ACCOUNT_TRANSACTIONS_FAILURE,
        ],
    };
}

export function resetBankAccountTransactions() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_BANK_ACCOUNT_TRANSACTIONS_RESET,
        });
    };
}

export function getBankAccount(id: number): IAPIMiddleware {
    return {
        callAPI: () => bankAccountService.get(id),
        types: [
            actionTypes.GET_BANK_ACCOUNT,
            actionTypes.GET_BANK_ACCOUNT_SUCCESS,
            actionTypes.GET_BANK_ACCOUNT_FAILURE,
        ],
    };
}

export function resetBankAccount() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_BANK_ACCOUNT_RESET,
        });
    };
}

export function createBankAccountTransaction(id: number, data: any): IAPIMiddleware {
    return {
        callAPI: () => bankAccountService.createTransaction(id, data),
        types: [
            actionTypes.CREATE_BANK_ACCOUNT_TRANSACTION,
            actionTypes.CREATE_BANK_ACCOUNT_TRANSACTION_SUCCESS,
            actionTypes.CREATE_BANK_ACCOUNT_TRANSACTION_FAILURE,
        ],
    };
}

export function createReadjustBankAccountTransaction() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_BANK_ACCOUNT_TRANSACTION_RESET,
        });
    };
}

export function createBankAccount(data: IBankAccount): IAPIMiddleware {
    return {
        callAPI: () => bankAccountService.create(data),
        types: [
            actionTypes.CREATE_BANK_ACCOUNT,
            actionTypes.CREATE_BANK_ACCOUNT_SUCCESS,
            actionTypes.CREATE_BANK_ACCOUNT_FAILURE,
        ],
    };
}

export function updateBankAccount(id: number, data: IBankAccount): IAPIMiddleware {
    return {
        callAPI: () => bankAccountService.update(id, data),
        types: [
            actionTypes.UPDATE_BANK_ACCOUNT,
            actionTypes.UPDATE_BANK_ACCOUNT_SUCCESS,
            actionTypes.UPDATE_BANK_ACCOUNT_FAILURE,
        ],
    };
}

export function deactivateBankAccount(id: number): IAPIMiddleware {
    return {
        callAPI: () => bankAccountService.deactivate(id),
        types: [
            actionTypes.DEACTIVATE_BANK_ACCOUNT,
            actionTypes.DEACTIVATE_BANK_ACCOUNT_SUCCESS,
            actionTypes.DEACTIVATE_BANK_ACCOUNT_FAILURE,
        ],
    };
}

export function getBankAccountTypes(): IAPIMiddleware {
    return {
        callAPI: () => bankAccountService.getTypes(),
        types: [
            actionTypes.GET_BANK_ACCOUNT_TYPES,
            actionTypes.GET_BANK_ACCOUNT_TYPES_SUCCESS,
            actionTypes.GET_BANK_ACCOUNT_TYPES_FAILURE,
        ],
    };
}

export function resetBankAccountTypes() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_BANK_ACCOUNT_TYPES_RESET,
        });
    };
}
