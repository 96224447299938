import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../../components';
import { IIncomePaymentItem } from '../../../../../../common/types/entities';
import { formatHelper, displayHelper } from '../../../../../../common/helpers';

export const columns: ColumnProps<IIncomePaymentItem>[] = [
    {
        title: 'Concepto',
        dataIndex: 'paymentItem',
        className: 'd-none d-md-table-cell',
        render: paymentItem => <span>{formatHelper.getPaymentItemWithType(paymentItem)}</span>,
    },
    {
        title: 'Precio',
        className: 'd-none d-md-table-cell',
        render: (item: IIncomePaymentItem) => displayHelper.incomePaymentItemPrice(item),
    },
    {
        title: 'Comentario',
        dataIndex: 'comment',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Puesto',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IIncomePaymentItem) => (
            <>
                <TableCellItem
                    label="Concepto"
                    value={formatHelper.getPaymentItemWithType(item.paymentItem)}
                />
                <TableCellItem label="Precio" value={displayHelper.incomePaymentItemPrice(item)} />
                <TableCellItem label="Comentario" value={item.comment} />
            </>
        ),
    },
];
