export const roles = {
    SUPER_ADMIN: 1,
    ADMIN: 2,
    INCOMES_MANAGER: 3,
    LOGISTICS_OPERATOR: 4,
    INCOMES_OPERATOR: 5,
    EXPENSES_OPERATOR: 6,
    SCALE_OPERATOR: 7,
    MANAGEMENT_ASSISTANT: 8,
    PENALTY_FEE_OPERATOR: 9,
    PARKING_OPERATOR : 10
};
