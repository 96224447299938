import React from 'react';
import { IProps } from './ExpenseAnswerAddEdit.types';
import { message, Divider, Row, Col, Button } from 'antd';
import { goodsColumns, servicesColumns } from './misc/columns';
import { paths } from '../../../../routes';
import { SectionContainer, DisplayItem, DataTable } from '../../../../components';
import { expenseTypes } from '../../../../common/constants';
import { formatHelper } from '../../../../common/helpers';
import { RejectModal } from './components';

interface IState {
    modalOpen: boolean;
}

export default class ExpenseAnswerAddEdit extends React.Component<IProps> {
    private expenseId = this.props.match.params['id'];

    state: IState = {
        modalOpen: false,
    };

    componentDidMount() {
        this.props.getExpense(this.expenseId);
        this.props.getExpenseDetails(this.expenseId);
    }

    componentWillUnmount() {
        this.props.resetExpense();
        this.props.resetExpenseDetails();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkAnswerExpense(prevProps);
    }

    checkAnswerExpense(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.answerExpenseSuccess && props.answerExpenseSuccess) {
            message.success('Egreso respondido');
            this.props.history.push(paths.ADMIN_EXPENSE_LIST);
            if (this.state.modalOpen) this.setState({ modalOpen: false });
        } else if (!prevProps.answerExpenseError && props.answerExpenseError) {
            message.error(props.answerExpenseError);
            if (this.state.modalOpen) this.setState({ modalOpen: false });
        }
    }

    performAnswerExpense = (answer: boolean, reasonToDelete?: any) => {
        this.props.answerExpense(this.expenseId, { answer, reasonToDelete });
    };

    renderContent = () => {
        const {
                activeExpense,
                expenseDetails,
                expenseDetailsLoading,
                answerExpenseLoading,
            } = this.props,
            isGoodsType = activeExpense.type.id == expenseTypes.GOODS;

        return (
            <>
                <DisplayItem
                    label="Código"
                    value={activeExpense.code}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Estado"
                    value={activeExpense.state.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Responsables</Divider>
                <DisplayItem
                    label="Solicitante"
                    value={formatHelper.getUserFullname(activeExpense.requestUser)}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Información</Divider>
                <DisplayItem
                    label="Tipo"
                    value={activeExpense.type.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Motivo"
                    value={activeExpense.reason}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Precio Total (PEN)"
                    type="money"
                    value={activeExpense.totalPrice}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Precio Total (USD)"
                    type="money"
                    value={activeExpense.totalPriceUSD}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left" className="mt-5">
                    {isGoodsType ? 'Bienes' : 'Servicios'}
                </Divider>
                <DataTable
                    data={expenseDetails}
                    columns={isGoodsType ? goodsColumns() : servicesColumns()}
                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                    loading={expenseDetailsLoading}
                />
                <Row className="mt-4">
                    <Col>
                        <Button
                            type="primary"
                            icon="check"
                            onClick={() => this.performAnswerExpense(true)}
                            loading={answerExpenseLoading}
                        >
                            Aprobar
                        </Button>
                        <Button
                            type="danger"
                            icon="close"
                            className="ml-2"
                            onClick={() => this.setState({ modalOpen: true })}
                            loading={answerExpenseLoading}
                        >
                            Rechazar
                        </Button>
                    </Col>
                </Row>
                <RejectModal
                    isOpen={this.state.modalOpen}
                    onSubmit={this.performAnswerExpense}
                    onCancel={() => this.setState({ modalOpen: false })}
                />
            </>
        );
    };

    render() {
        const { activeExpense, activeExpenseLoading, activeExpenseError } = this.props;

        return (
            <SectionContainer
                title={'Responder Requerimiento de Egreso'}
                onBack={() => this.props.history.push(paths.ADMIN_EXPENSE_LIST)}
                loading={activeExpenseLoading || !activeExpense}
                error={activeExpenseError}
                content={this.renderContent}
            />
        );
    }
}
