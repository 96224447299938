import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './SubzoneList.types';
import SubzoneList from './SubzoneList';
import { IRootState } from '../../../../common/types';
import {
    getSubzones,
    resetSubzones,
    deactivateSubzone,
    createSubzone,
    updateSubzone,
} from '../../../../store/subzone/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { subzoneList, deactivateSubzone, createSubzone, updateSubzone } = state.subzone;
    const { loggedInUser } = state.auth;

    return {
        subzones: subzoneList.data,
        total: subzoneList.total,
        pageSize: subzoneList.pageSize,
        loading: subzoneList.loading,
        error: subzoneList.error,
        createSuccess: createSubzone.success,
        createLoading: createSubzone.loading,
        createError: createSubzone.error,
        updateSuccess: updateSubzone.success,
        updateLoading: updateSubzone.loading,
        updateError: updateSubzone.error,
        deactivateLoading: deactivateSubzone.loading,
        deactivateSuccess: deactivateSubzone.success,
        deactivateError: deactivateSubzone.error,
        permissions: loggedInUser.value.permissions,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getSubzones,
            resetSubzones,
            deactivateSubzone,
            createSubzone,
            updateSubzone,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SubzoneList);
