import { IListStatePaginated, IObjectState, IProcessState } from '../../common/types';
import { IBank } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    bankList: IListStatePaginated<IBank>;
    activeBank: IObjectState<IBank>;
    createBank: IProcessState;
    updateBank: IProcessState;
    deactivateBank: IProcessState;
}

export const initialState: IState = {
    bankList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activeBank: { value: undefined, loading: false, error: undefined },
    createBank: { success: false, loading: false, error: undefined },
    updateBank: { success: false, loading: false, error: undefined },
    deactivateBank: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_BANKS]: caseFunctions.getBanks,
    [actionTypes.GET_BANKS_SUCCESS]: caseFunctions.getBanksSuccess,
    [actionTypes.GET_BANKS_FAILURE]: caseFunctions.getBanksFailure,
    [actionTypes.GET_BANKS_RESET]: caseFunctions.getBanksReset,

    [actionTypes.GET_BANK]: caseFunctions.getBank,
    [actionTypes.GET_BANK_SUCCESS]: caseFunctions.getBankSuccess,
    [actionTypes.GET_BANK_FAILURE]: caseFunctions.getBankFailure,
    [actionTypes.GET_BANK_RESET]: caseFunctions.getBankReset,

    [actionTypes.CREATE_BANK]: caseFunctions.createBank,
    [actionTypes.CREATE_BANK_SUCCESS]: caseFunctions.createBankSuccess,
    [actionTypes.CREATE_BANK_FAILURE]: caseFunctions.createBankFailure,
    [actionTypes.CREATE_BANK_RESET]: caseFunctions.createBankReset,

    [actionTypes.UPDATE_BANK]: caseFunctions.updateBank,
    [actionTypes.UPDATE_BANK_SUCCESS]: caseFunctions.updateBankSuccess,
    [actionTypes.UPDATE_BANK_FAILURE]: caseFunctions.updateBankFailure,
    [actionTypes.UPDATE_BANK_RESET]: caseFunctions.updateBankReset,

    [actionTypes.DEACTIVATE_BANK]: caseFunctions.deactivateBank,
    [actionTypes.DEACTIVATE_BANK_SUCCESS]: caseFunctions.deactivateBankSuccess,
    [actionTypes.DEACTIVATE_BANK_FAILURE]: caseFunctions.deactivateBankFailure,
    [actionTypes.DEACTIVATE_BANK_RESET]: caseFunctions.deactivateBankReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
