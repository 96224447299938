import { IListStatePaginated, IObjectState, IProcessState, IListState } from '../../common/types';
import { IUser, IRole } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    userList: IListStatePaginated<IUser>;
    activeUser: IObjectState<IUser>;
    sessionUser: IObjectState<IUser>;
    createUser: IProcessState;
    updateUser: IProcessState;
    deactivateUser: IProcessState;
    roleList: IListState<IRole>;
    updatePassword: IProcessState;
    updateAuthorizationToken: IProcessState;
}

export const initialState: IState = {
    userList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activeUser: { value: undefined, loading: false, error: undefined },
    sessionUser: { value: undefined, loading: false, error: undefined },
    createUser: { success: false, loading: false, error: undefined },
    updateUser: { success: false, loading: false, error: undefined },
    deactivateUser: { success: false, loading: false, error: undefined },
    roleList: { data: [], loading: false, error: undefined },
    updatePassword: { success: false, loading: false, error: undefined },
    updateAuthorizationToken: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_USERS]: caseFunctions.getUsers,
    [actionTypes.GET_USERS_SUCCESS]: caseFunctions.getUsersSuccess,
    [actionTypes.GET_USERS_FAILURE]: caseFunctions.getUsersFailure,
    [actionTypes.GET_USERS_RESET]: caseFunctions.getUsersReset,

    [actionTypes.GET_USER]: caseFunctions.getUser,
    [actionTypes.GET_USER_SUCCESS]: caseFunctions.getUserSuccess,
    [actionTypes.GET_USER_FAILURE]: caseFunctions.getUserFailure,
    [actionTypes.GET_USER_RESET]: caseFunctions.getUserReset,

    [actionTypes.CREATE_USER]: caseFunctions.createUser,
    [actionTypes.CREATE_USER_SUCCESS]: caseFunctions.createUserSuccess,
    [actionTypes.CREATE_USER_FAILURE]: caseFunctions.createUserFailure,
    [actionTypes.CREATE_USER_RESET]: caseFunctions.createUserReset,

    [actionTypes.UPDATE_USER]: caseFunctions.updateUser,
    [actionTypes.UPDATE_USER_SUCCESS]: caseFunctions.updateUserSuccess,
    [actionTypes.UPDATE_USER_FAILURE]: caseFunctions.updateUserFailure,
    [actionTypes.UPDATE_USER_RESET]: caseFunctions.updateUserReset,

    [actionTypes.DEACTIVATE_USER]: caseFunctions.deactivateUser,
    [actionTypes.DEACTIVATE_USER_SUCCESS]: caseFunctions.deactivateUserSuccess,
    [actionTypes.DEACTIVATE_USER_FAILURE]: caseFunctions.deactivateUserFailure,
    [actionTypes.DEACTIVATE_USER_RESET]: caseFunctions.deactivateUserReset,

    [actionTypes.GET_ROLES]: caseFunctions.getRoles,
    [actionTypes.GET_ROLES_SUCCESS]: caseFunctions.getRolesSuccess,
    [actionTypes.GET_ROLES_FAILURE]: caseFunctions.getRolesFailure,
    [actionTypes.GET_ROLES_RESET]: caseFunctions.getRolesReset,

    [actionTypes.UPDATE_AUTHORIZATION_TOKEN]: caseFunctions.updateAuthorizationToken,
    [actionTypes.UPDATE_AUTHORIZATION_TOKEN_SUCCESS]: caseFunctions.updateAuthorizationTokenSuccess,
    [actionTypes.UPDATE_AUTHORIZATION_TOKEN_FAILURE]: caseFunctions.updateAuthorizationTokenFailure,
    [actionTypes.UPDATE_AUTHORIZATION_TOKEN_RESET]: caseFunctions.updateAuthorizationTokenReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
