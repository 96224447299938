import React, { FC, useRef, useEffect } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal, Col, Row, Icon } from 'antd';
import { messages } from '../../../../../../common/constants';
import {
    getAvailableShareholders,
    resetAvailableShareholders,
} from '../../../../../../store/property/actions';
import { SelectGroupField, InputGroupField, DisplayItem } from '../../../../../../components';
import { IRootState } from '../../../../../../common/types';
import {
    IShareholderProperty,
    IProperty,
    IShareholder,
} from '../../../../../../common/types/entities';
import { connect } from 'react-redux';
import { getShareholderFullnameWithCode } from '../../../../../../common/helpers/format';
import { isNumeric } from 'validator';

interface IOwnProps {
    property: IProperty;
    isOpen: boolean;
    onSubmit: (values: IShareholderProperty) => void;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    initialValues?: IShareholderProperty;
    submitting?: boolean;
    showWarnMessage?: boolean;
}

export interface IAddEditPropertyShareholderValues {
    shareholder: { id: number | string };
    shares: number | string;
}

interface IStoreState {
    shareholders: IProperty[];
    shareholdersLoading: boolean;
}

interface IDispatchProps {
    getAvailableShareholders: (id: number) => void;
    resetAvailableShareholders: () => void;
}

export type IProps = IOwnProps & IStoreState & IDispatchProps;

const PropertyShareholderModalForm: FC<IProps> = props => {
    let form = useRef<Formik<IAddEditPropertyShareholderValues>>(null);

    useEffect(() => {
        if (props.isOpen == false) return;

        props.getAvailableShareholders(props.property.id);
        return () => props.resetAvailableShareholders();
    }, [props.isOpen]);

    const onSubmit = (
        values: IAddEditPropertyShareholderValues,
        actions: FormikActions<IAddEditPropertyShareholderValues>,
    ) => {
        const shareholderProperty: IShareholderProperty = {
            shareholder: values.shareholder as IShareholder,
            property: { id: props.property.id },
            shares: Number(values.shares),
        };

        props.onSubmit(shareholderProperty);
        actions.setSubmitting(false);
    };

    const handleOnCancel = e => {
        form.current.resetForm();
        props.onCancel(e);
    };

    const validate = (values: IAddEditPropertyShareholderValues) => {
        let errors: Partial<IAddEditPropertyShareholderValues> = {};

        if (!values.shareholder || !values.shareholder.id)
            errors.shareholder = { id: messages.MANDATORY_FIELD };

        if (!values.shares) errors.shares = messages.MANDATORY_FIELD;
        else if (!isNumeric(String(values.shares))) errors.shares = 'Cantidad no válida';

        return errors;
    };

    const onOk = () => form.current.submitForm();

    const renderFormik = () => {
        if (!props.isOpen) return null;

        const init = props.initialValues || {};

        return (
            <Formik
                initialValues={{
                    shareholder: init.shareholder ? { id: init.shareholder.id } : undefined,
                    shares: init ? init.shares : undefined,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={(formikBag: FormikProps<IAddEditPropertyShareholderValues>) => (
                    <Form>
                        {init.shareholder ? (
                            <DisplayItem
                                label="Accionista"
                                value={getShareholderFullnameWithCode(init.shareholder)}
                                lxs={24}
                                lsm={24}
                                lmd={24}
                                llg={24}
                                lxl={6}
                                lxxl={6}
                                vmd={24}
                                vxl={18}
                                vlg={24}
                            />
                        ) : (
                            <SelectGroupField
                                name="shareholder.id"
                                label="Accionista"
                                displayText={item => getShareholderFullnameWithCode(item)}
                                required
                                data={props.shareholders}
                                loading={props.shareholdersLoading}
                                lxs={24}
                                lsm={24}
                                lmd={24}
                                llg={24}
                                lxl={6}
                                lxxl={6}
                                imd={24}
                                ixl={18}
                                ilg={24}
                            />
                        )}
                        <InputGroupField
                            name="shares"
                            label="Acciones"
                            // normalize="number"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        {props.showWarnMessage && (
                            <Row>
                                <Col>
                                    <Icon
                                        type="warning"
                                        style={{ color: '#faad14' }}
                                        className="mr-1"
                                    />
                                    <span
                                        className="label"
                                        style={{ color: '#faad14', fontStyle: 'italic' }}
                                    >
                                        Recuerda que los puestos menores o iguales a 13 metros
                                        cuadrados, solo pueden tener un accionista.
                                    </span>
                                </Col>
                            </Row>
                        )}
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            destroyOnClose={true}
            title={props.initialValues ? 'Editar Accionista' : 'Agregar Accionista'}
            visible={props.isOpen}
            okText="Guardar"
            onOk={onOk}
            okButtonProps={{ loading: props.submitting }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.submitting }}
        >
            {renderFormik()}
        </Modal>
    );
};

function mapStateToProps(state: IRootState): IStoreState {
    const { availableShareholderList } = state.property;

    return {
        shareholders: availableShareholderList.data,
        shareholdersLoading: availableShareholderList.loading,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getAvailableShareholders,
            resetAvailableShareholders,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyShareholderModalForm);
