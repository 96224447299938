import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem, TableRowOptionsDropdown } from '../../../../../components';
import { IUser } from '../../../../../common/types/entities';
import { permissions } from '../../../../../common/constants';
import { Button } from 'antd';

export const columns = (
    onClickDetail: (id: number) => void,
    onClickEdit: (id: number) => void,
    onClickDelete: (id: number) => void,
    permissionCodes: string[],
): ColumnProps<IUser>[] => [
    {
        title: 'Usuario',
        dataIndex: 'username',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Nombre',
        dataIndex: 'firstname',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Apellidos',
        dataIndex: 'lastname',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'DNI',
        dataIndex: 'dni',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Correo',
        dataIndex: 'email',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Usuario',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IUser) => (
            <>
                <TableCellItem label="Usuario" value={item.username} />
                <TableCellItem label="Nombre" value={item.firstname} />
                <TableCellItem label="Apellidos" value={item.lastname} />
                <TableCellItem label="DNI" value={item.dni} />
                <TableCellItem label="Correo" value={item.email} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IUser) => {
            if (permissionCodes.includes(permissions.user.WRITE_USERS)) {
                return (
                    <TableRowOptionsDropdown
                        onClickDetail={() => onClickDetail(item.id)}
                        onClickEdit={() => onClickEdit(item.id)}
                        onClickDelete={() => onClickDelete(item.id)}
                    />
                );
            } else if (permissionCodes.includes(permissions.user.READ_USERS)) {
                return (
                    <Button
                        type="primary"
                        shape="round"
                        icon="eye"
                        onClick={() => onClickDetail(item.id)}
                    />
                );
            }
        },
    },
];
