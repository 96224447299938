import React, { FC, isValidElement } from 'react';
import { formatHelper } from '../../../common/helpers';

interface IOwnProps {
    label: string;
    value?: string | React.ReactNode;
    type?: 'text' | 'money' | 'moneyPEN' | 'moneyUSD' | 'date' | 'datetime';
    valueClassname?: string;
}

type IProps = IOwnProps;

const TableCellItem: FC<IProps> = ({ label, value, type, valueClassname }) => {
    const renderValue = () => {
        if (isValidElement(value)) return value;
        if (type === 'text')
            return (
                <span className={valueClassname} style={{ wordBreak: 'break-word' }}>
                    {value}
                </span>
            );
        else if (type === 'money')
            return <span className={valueClassname}>{formatHelper.toMoney(Number(value))}</span>;
        else if (type === 'moneyPEN')
            return (
                <span className={valueClassname}>{formatHelper.toMoney(Number(value), 'PEN')}</span>
            );
        else if (type === 'moneyUSD')
            return (
                <span className={valueClassname}>{formatHelper.toMoney(Number(value), 'USD')}</span>
            );
        else if (type === 'date')
            return <span className={valueClassname}>{formatHelper.toDate(value)}</span>;
        else if (type === 'datetime')
            return <span className={valueClassname}>{formatHelper.toDateTime(value)}</span>;
    };

    return (
        <div className="mb-2">
            <b className="mr-1">{`${label}:`}</b>
            <br />
            {renderValue()}
        </div>
    );
};

TableCellItem.defaultProps = {
    value: '',
    type: 'text',
};

export default TableCellItem;
