import * as React from 'react';
import { FormikActions, FormikProps } from 'formik';
import {
    CustomForm,
    InputGroupField,
    DisplayItem,
    DataTable,
    SelectGroupField,
} from '../../../../../../components';
import {
    IExpense,
    IUser,
    IExpenseDetail,
    IExpenseType,
} from '../../../../../../common/types/entities';
import { Divider, Row, Col, Button, message } from 'antd';
import nanoid from 'nanoid';
import { expenseTypes } from '../../../../../../common/constants';
import { goodsColumns, servicesColumns } from './columns';
import AddItemModal from './AddItemModal';

interface IExpenseRequirementForm {
    reason: string;
    type: { id: number | string };
}

interface IState {
    expenseDetails: IExpenseDetail[];
    modalOpen: boolean;
}

interface IOwnProps {
    initialValues: IExpense;
    loggedUser: IUser;
    expenseDetails: IExpenseDetail[];
    expenseDetailsLoading: boolean;
    expenseTypes: IExpenseType[];
    expenseTypesLoading: boolean;
}

type IProps = IOwnProps;

export default class ExpenseRequirementForm extends CustomForm<
    IExpenseRequirementForm,
    IProps,
    IState
    > {
    state: IState = {
        expenseDetails: [],
        modalOpen: false,
    };

    componentDidMount() {
        const { initialValues, expenseDetails } = this.props;

        if (!initialValues.id) return;

        this.setState({
            expenseDetails: expenseDetails,
        });
    }

    initialValues = (props: IProps): IExpenseRequirementForm => {
        const { initialValues } = props;
        return {
            reason: initialValues.reason || '',
            type: { id: initialValues.type ? initialValues.type.id : expenseTypes.GOODS },
        };
    };

    onSubmit = (
        values: IExpenseRequirementForm,
        actions: FormikActions<IExpenseRequirementForm>,
    ) => {
        const { expenseDetails } = this.state;

        if (expenseDetails.length === 0) {
            message.error('Se debe agregar por lo menos un item');
            return;
        }

        const finalValues: any = {
            ...values,
            expenseDetails,
            requestUser: { id: this.props.loggedUser.id },
        };

        this.props.onSubmit(finalValues);
        actions.setSubmitting(false);
    };

    onCancel = () => this.props.onCancel();

    onRemoveItem = (item: IExpenseDetail) => {
        const { expenseDetails } = this.state;

        const filtered = expenseDetails.filter(x => {
            if (x.stringId != item.stringId || x.id != item.id) return x;
        });

        this.setState({ expenseDetails: filtered });
    };

    addItem = (item: IExpenseDetail) => {
        const { expenseDetails } = this.state;

        item.stringId = nanoid();
        expenseDetails.push(item);

        this.setState({ expenseDetails, modalOpen: false });
    };

    formBody = ({ values }: FormikProps<IExpenseRequirementForm>) => {
        const {
            loggedUser,
            expenseTypes: expenseTypeList,
            expenseTypesLoading,
            editing,
            initialValues,
        } = this.props;
        const { type } = values;

        const userFullname = editing
            ? `${initialValues.requestUser.firstname} ${initialValues.requestUser.lastname}`
            : `${loggedUser.firstname} ${loggedUser.lastname}`;

        return <div>
            {editing && <>
                <DisplayItem
                    label="Código"
                    value={initialValues.code}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Estado"
                    value={initialValues.state.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
            </>
            }
            <DisplayItem
                label="Solicitante"
                value={userFullname}
                lmd={4}
                llg={3}
                lxl={2}
                lxxl={2}
            />
            {editing
                ? <DisplayItem
                    label="Tipo"
                    value={initialValues.type.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                : <SelectGroupField
                    name="type.id"
                    label="Tipo"
                    data={expenseTypeList}
                    displayText="description"
                    loading={expenseTypesLoading}
                    allowClear={false}
                    disabled={this.state.expenseDetails.length > 0}
                    required
                />
            }
            <InputGroupField inputType="textarea" rows={4} name="reason" label="Motivo" />
            <Divider orientation="left" className="mt-5">
                {type.id == expenseTypes.GOODS ? 'Bienes' : 'Servicios'}
            </Divider>
            <Row className="mb-3">
                <Col className="float-right">
                    <Button
                        icon="plus"
                        type="primary"
                        onClick={() => this.setState({ modalOpen: true })}
                    >
                        Agregar
                    </Button>
                </Col>
            </Row>
            <DataTable
                data={this.state.expenseDetails}
                columns={type.id == expenseTypes.GOODS
                    ? goodsColumns(this.onRemoveItem)
                    : servicesColumns(this.onRemoveItem)
                }
                rowKey={record => `${record.stringId || ''}${record.id || ''}`}
            />
            <AddItemModal
                isOpen={this.state.modalOpen}
                onSubmit={this.addItem}
                onCancel={() => this.setState({ modalOpen: false })}
                typeId={Number(type.id)}
            />
        </div>
    };
}
