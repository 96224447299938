import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './ExpenseAnswerAddEdit.types';
import ExpenseAnswerAddEdit from './ExpenseAnswerAddEdit'
import { IRootState } from '../../../../common/types';
import {
  getExpense,
  getExpenseDetails,
  resetExpense,
  resetExpenseDetails,
  answerExpense
} from '../../../../store/expense/actions';

function mapStateToProps(state: IRootState): IStoreState {
  const
    {
      activeExpense,
      expenseDetailList,
      answerExpense,
    } = state.expense;

  return {
    activeExpense: activeExpense.value,
    activeExpenseLoading: activeExpense.loading,
    activeExpenseError: activeExpense.error,
    expenseDetails: expenseDetailList.data,
    expenseDetailsLoading: expenseDetailList.loading,
    answerExpenseSuccess: answerExpense.success,
    answerExpenseLoading: answerExpense.loading,
    answerExpenseError: answerExpense.error,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    getExpense,
    getExpenseDetails,
    answerExpense,
    resetExpense,
    resetExpenseDetails,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseAnswerAddEdit);