import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { IPaymentDetail } from '../../../../../common/types/entities';
import { TableCellItem } from '../../../../../components';
import { formatHelper } from '../../../../../common/helpers';
import { Button } from 'antd';

export const PaymentDetailCols = (
    onClickDetail: (id: number) => void,
    currencyCode: string,
): ColumnProps<IPaymentDetail>[] => [
    {
        title: 'Código de Factura',
        dataIndex: 'invoice.code',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Precio Total',
        dataIndex: 'totalPrice',
        className: 'd-none d-md-table-cell',
        render: (totalPrice: number) => (
            <span>{formatHelper.toMoney(totalPrice, currencyCode)}</span>
        ),
    },
    {
        title: 'Motivo de Anulación',
        dataIndex: 'invoice.reasonToDelete',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Pago',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IPaymentDetail) => (
            <>
                <TableCellItem label="Código de Factura" value={item.invoice.code} />
                <TableCellItem label="Precio Total" value={item.totalPrice} type="money" />
                <TableCellItem label="Motivo de Anulación" value={item.invoice.reasonToDelete} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IPaymentDetail) =>
            item.invoice.active ? (
                <Button
                    icon="eye"
                    shape="round"
                    type="primary"
                    onClick={() => onClickDetail(item.invoice.id)}
                />
            ) : null,
    },
];
