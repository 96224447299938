import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './ConfigureList.types';
import ConfigureList from './ConfigureList';
import { IRootState } from '../../../common/types';
import { getConfigures, resetConfigures, updateConfigures } from '../../../store/configure/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { configureList, updateConfigure } = state.configure;

    return {
        configures: configureList.data,
        total: configureList.total,
        pageSize: configureList.pageSize,
        loading: configureList.loading,
        error: configureList.error,
        updateSuccess: updateConfigure.success,
        updateLoading: updateConfigure.loading,
        updateError: updateConfigure.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getConfigures,
            resetConfigures,
            updateConfigures,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureList);
