import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    objectStateLoading,
    objectStateSuccess,
    objectStateFailure,
} from '../../common/helpers/reducer';

export const incomeDetailedCompletedReportExcelCF = {
    request: (state: IState): IState => {
        return {
            ...state,
            incomeDetailedCompletedReportExcel: objectStateLoading(),
        };
    },

    success: (state: IState, action: IAction): IState => {
        return {
            ...state,
            incomeDetailedCompletedReportExcel: objectStateSuccess(action.payload.fileResult),
        };
    },

    failure: (state: IState, action: IAction): IState => {
        return {
            ...state,
            incomeDetailedCompletedReportExcel: objectStateFailure(action.payload.error),
        };
    },

    reset: (state: IState): IState => {
        return {
            ...state,
            incomeDetailedCompletedReportExcel: initialState.incomeDetailedCompletedReportExcel,
        };
    },
};

export const incomeDetailedPendingPaymentReportExcelCF = {
    request: (state: IState): IState => {
        return {
            ...state,
            incomeDetailedPendingPaymentReportExcel: objectStateLoading(),
        };
    },

    success: (state: IState, action: IAction): IState => {
        return {
            ...state,
            incomeDetailedPendingPaymentReportExcel: objectStateSuccess(action.payload.fileResult),
        };
    },

    failure: (state: IState, action: IAction): IState => {
        return {
            ...state,
            incomeDetailedPendingPaymentReportExcel: objectStateFailure(action.payload.error),
        };
    },

    reset: (state: IState): IState => {
        return {
            ...state,
            incomeDetailedPendingPaymentReportExcel:
                initialState.incomeDetailedPendingPaymentReportExcel,
        };
    },
};

export const incomeDetailedPendingReportExcelCF = {
    request: (state: IState): IState => {
        return {
            ...state,
            incomeDetailedPendingReportExcel: objectStateLoading(),
        };
    },

    success: (state: IState, action: IAction): IState => {
        return {
            ...state,
            incomeDetailedPendingReportExcel: objectStateSuccess(action.payload.fileResult),
        };
    },

    failure: (state: IState, action: IAction): IState => {
        return {
            ...state,
            incomeDetailedPendingReportExcel: objectStateFailure(action.payload.error),
        };
    },

    reset: (state: IState): IState => {
        return {
            ...state,
            incomeDetailedPendingReportExcel: initialState.incomeDetailedPendingReportExcel,
        };
    },
};

export const incomeDetailedAnulledReportExcelCF = {
    request: (state: IState): IState => {
        return {
            ...state,
            incomeDetailedAnulledReportExcel: objectStateLoading(),
        };
    },

    success: (state: IState, action: IAction): IState => {
        return {
            ...state,
            incomeDetailedAnulledReportExcel: objectStateSuccess(action.payload.fileResult),
        };
    },

    failure: (state: IState, action: IAction): IState => {
        return {
            ...state,
            incomeDetailedAnulledReportExcel: objectStateFailure(action.payload.error),
        };
    },

    reset: (state: IState): IState => {
        return {
            ...state,
            incomeDetailedAnulledReportExcel: initialState.incomeDetailedAnulledReportExcel,
        };
    },
};
