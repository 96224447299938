import * as React from 'react';
import { FormikActions, FormikProps } from 'formik';
import { CustomForm, InputGroupField, SelectGroupField } from '../../../../../components';
import { IProperty, IZone, ISubzone, IAttachedFile } from '../../../../../common/types/entities';
import { messages } from '../../../../../common/constants';
import { isEmpty, isNumeric } from 'validator';
import { Divider, Row, Col, Upload, Button, Icon } from 'antd';
import { helper } from '../../../../../common/helpers';

interface IPropertyForm {
    code: string;
    description: string;
    area: string;
    referentialPrice: string;
    shares: string;
    zone: { id: number | string };
    subzone: { id: number | string };
    attachedFiles?: IAttachedFile[];
}

interface IProps {
    initialValues: IProperty;
    zones: IZone[];
    zonesLoading: boolean;
    subzones: ISubzone[];
    subzonesLoading: boolean;
}

interface IState {
    files: IAttachedFile[];
    filesRemoved: string[];
}

export default class PropertyForm extends CustomForm<IPropertyForm, IProps, IState> {
    state: IState = {
        files: [],
        filesRemoved: [],
    };

    initialValues = (props: IProps): IPropertyForm => {
        const { initialValues } = props;

        return {
            code: initialValues.code || '',
            description: initialValues.description || '',
            area: initialValues.area != null ? String(initialValues.area) : '',
            referentialPrice:
                initialValues.referentialPrice != null
                    ? String(initialValues.referentialPrice)
                    : '',
            shares: initialValues.shares != null ? String(initialValues.shares) : '',
            zone: { id: initialValues.zone ? initialValues.zone.id : null },
            subzone: { id: initialValues.subzone ? initialValues.subzone.id : null },
        };
    };

    validate = (values: IPropertyForm) => {
        const errors = {} as Partial<IPropertyForm>;

        if (isEmpty(values.code)) errors.code = messages.MANDATORY_FIELD;

        if (!isEmpty(values.referentialPrice) && !isNumeric(values.referentialPrice))
            errors.referentialPrice = 'El precio debe ser un número';

        if (!isEmpty(values.shares) && !isNumeric(values.shares))
            errors.shares = 'Las acciones deben ser un número';

        if (!isEmpty(values.area) && !isNumeric(values.area))
            errors.area = 'El área debe ser un número';

        return errors;
    };

    onSubmit = (values: IPropertyForm, actions: FormikActions<IPropertyForm>) => {
        if (values.referentialPrice == '') values.referentialPrice = null;
        if (values.shares == '') values.shares = null;

        let files = this.state.files.filter(e => e); // remove undefined files
        const filesRemoved = this.state.filesRemoved;

        if (filesRemoved.length > 0) {
            for (let i = 0; i < filesRemoved.length; i++) {
                const attachedFile = this.props.initialValues.attachedFiles.find(
                    e => e.url == filesRemoved[i],
                );
                const fileName =
                    attachedFile.id.toString() +
                    'idToRemove' +
                    attachedFile.key.replace(/\//g, ':');

                const file: File = new File([''], fileName);
                files.push(file);
            }
        }
        values.attachedFiles = files;

        this.props.onSubmit(values);
        actions.setSubmitting(false);
    };

    formBody = (formikBag: FormikProps<IPropertyForm>) => {
        const { zones, zonesLoading, subzones, subzonesLoading } = this.props;

        return (
            <div>
                <InputGroupField name="code" label="Código" required />
                <InputGroupField name="description" label="Descripción" />
                <InputGroupField name="shares" label="Acciones" />
                <InputGroupField name="referentialPrice" label="Precio Referencial" />
                <InputGroupField name="area" label="Área" />
                <SelectGroupField
                    name="zone.id"
                    label="Zona"
                    data={zones}
                    displayText="name"
                    loading={zonesLoading}
                />
                <SelectGroupField
                    name="subzone.id"
                    label="Giro"
                    data={subzones}
                    displayText="name"
                    loading={subzonesLoading}
                />
                <Divider orientation="left" className="mt-5">
                    Archivos
                </Divider>
                <Row>
                    <Col lg={12} md={12} xs={24} sm={24}>
                        <Upload
                            name="Subir Archivos"
                            accept=".pdf, .csv, .xlsx, .xls, .doc, .docx, .png, .jpg"
                            defaultFileList={helper.getFileList(
                                this.props.initialValues.attachedFiles,
                            )}
                            onChange={info => {
                                this.setState({
                                    files: info.fileList.map(item => item['originFileObj']),
                                });
                            }}
                            onRemove={file => {
                                if (file.url) {
                                    let filesRemoved = this.state.filesRemoved;
                                    filesRemoved.push(file.url);
                                    this.setState({ filesRemoved });
                                }
                            }}
                            // stange behavior.... https://stackoverflow.com/a/56125617
                            beforeUpload={() => false}
                        >
                            <Button>
                                <Icon type="upload" /> Subir Archivos
                            </Button>
                        </Upload>
                    </Col>
                </Row>
            </div>
        );
    };
}
