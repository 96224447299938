import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    getShareholder,
    resetShareholder,
    getShareholderProperties,
    resetShareholderProperties,
    getCoOwners,
    resetCoOwners,
} from '../../../../store/shareholder/actions';
import { IStoreState } from './ShareholderDetail.types';
import { IRootState } from '../../../../common/types';
import CustomerDetail from './ShareholderDetail';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeShareholder, shareholderPropertyList, coOwnerList } = state.shareholder;

    return {
        shareholder: activeShareholder.value,
        loading: activeShareholder.loading,
        error: activeShareholder.error,
        shareholderProperties: shareholderPropertyList.data,
        shareholderPropertiesPageZise: shareholderPropertyList.pageSize,
        shareholderPropertiesTotal: shareholderPropertyList.total,
        shareholderPropertiesLoading: shareholderPropertyList.loading,
        shareholderPropertiesError: shareholderPropertyList.error,
        coOwners: coOwnerList.data,
        coOwnersLoading: coOwnerList.loading,
        coOwnersError: coOwnerList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getShareholder,
            resetShareholder,
            getShareholderProperties,
            resetShareholderProperties,
            getCoOwners,
            resetCoOwners,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
