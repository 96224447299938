import { IState, initialState } from './reducer'
import { IAction } from '../../common/types'
import {
  listStateLoading,
  listStateSuccess,
  listStateFailure,
  objectStateSuccess,
  objectStateFailure,
  objectStateLoading,
  listStatePaginatedLoading,
  listStatePaginatedSuccess,
  listStatePaginatedFailure,
} from '../../common/helpers/reducer'

//#region GET INVOICES BY Request Order
export function getInvoicesByRequestOrder(state: IState): IState {
  return {
    ...state,
    invoiceByRequestOrderList: listStateLoading()
  };
}

export function getInvoicesByRequestOrderSuccess(state: IState, action: IAction): IState {
  return {
    ...state,
    invoiceByRequestOrderList: listStateSuccess(action.payload.data),
  };
}

export function getInvoicesByRequestOrderFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    invoiceByRequestOrderList: listStateFailure(action.payload.error),
  };
}

export function getInvoicesByRequestOrderReset(state: IState): IState {
  return {
    ...state,
    invoiceByRequestOrderList: initialState.invoiceByRequestOrderList,
  };
}
//#endregion

//#region GET INVOICES BY Request Order With DETAILS
export function getInvoicesByRequestOrderDetails(state: IState): IState {
  return {
    ...state,
    invoiceByRequestOrderDetailList: listStateLoading()
  };
}

export function getInvoicesByRequestOrderDetailsSuccess(state: IState, action: IAction): IState {
  return {
    ...state,
    invoiceByRequestOrderDetailList: listStateSuccess(action.payload.data),
  };
}

export function getInvoicesByRequestOrderDetailsFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    invoiceByRequestOrderDetailList: listStateFailure(action.payload.error),
  };
}

export function getInvoicesByRequestOrderDetailsReset(state: IState): IState {
  return {
    ...state,
    invoiceByRequestOrderDetailList: initialState.invoiceByRequestOrderDetailList,
  };
}
//#endregion

//#region GET INVOICES BY PROVIDER
export function getInvoicesByProvider(state: IState): IState {
  return {
    ...state,
    invoiceByProviderList: listStatePaginatedLoading(state.invoiceByProviderList)
  }
}
export function getInvoicesByProviderSuccess(state: IState, action: IAction): IState {
  return {
    ...state,
    invoiceByProviderList: listStatePaginatedSuccess(action.payload)
  }
}
export function getInvoicesByProviderFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    invoiceByProviderList: listStatePaginatedFailure(initialState.invoiceByProviderList, action.payload.error),
  }
}
export function getInvoicesByProviderReset(state: IState): IState {
  return {
    ...state,
    invoiceByProviderList: initialState.invoiceByProviderList,
  }
}
//#endregion 

//#region GET INVOICES BY PROVIDER DETAILS
export function getInvoicesByProviderDetails(state: IState): IState {
  return {
    ...state,
    invoiceByProviderDetailList: listStateLoading()
  }
}
export function getInvoicesByProviderDetailsSuccess(state: IState, action: IAction): IState {
  return {
    ...state,
    invoiceByProviderDetailList: listStateSuccess(action.payload.data)
  }
}
export function getInvoicesByProviderDetailsFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    invoiceByProviderDetailList: listStateFailure(action.payload.error),
  }
}
export function getInvoicesByProviderDetailsReset(state: IState): IState {
  return {
    ...state,
    invoiceByProviderDetailList: initialState.invoiceByProviderDetailList,
  }
}
//#endregion 

//#region GET INVOICE
export function getInvoice(state: IState): IState {
  return {
    ...state,
    activeInvoice: objectStateLoading()
  };
}

export function getInvoiceSuccess(state: IState, action: IAction): IState {
  return {
    ...state,
    activeInvoice: objectStateSuccess(action.payload),
  };
}

export function getInvoiceFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    activeInvoice: objectStateFailure(action.payload.error),
  };
}
//#endregion

//#region COMPLETE INVOICE
export function completeInvoice(state: IState): IState {
  return {
    ...state,
    completeInvoice: { success: false, loading: true, error: undefined },
  };
}

export function completeInvoiceSuccess(state: IState): IState {
  return {
    ...state,
    completeInvoice: { success: true, loading: false, error: undefined },
  };
}

export function completeInvoiceFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    completeInvoice: { success: false, loading: false, error: action.payload.error },
  };
}
//#endregion

//#region COMPLETE INVOICE PROVIDER
export function completeInvoiceProvider(state: IState): IState {
  return {
    ...state,
    completeInvoiceProvider: { success: false, loading: true, error: undefined },
  };
}

export function completeInvoiceProviderSuccess(state: IState): IState {
  return {
    ...state,
    completeInvoiceProvider: { success: true, loading: false, error: undefined },
  };
}

export function completeInvoiceProviderFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    completeInvoiceProvider: { success: false, loading: false, error: action.payload.error },
  };
}
//#endregion

//#region DEACTIVATE INVOICE
export function deactivateInvoice(state: IState): IState {
  return {
    ...state,
    deactivateInvoice: { success: false, loading: true, error: undefined },
  };
}

export function deactivateInvoiceSuccess(state: IState): IState {
  return {
    ...state,
    deactivateInvoice: { success: true, loading: false, error: undefined },
  };
}

export function deactivateInvoiceFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    deactivateInvoice: { success: false, loading: false, error: action.payload.error },
  };
}

export function deactivateInvoiceReset(state: IState): IState {
  return {
    ...state,
    deactivateInvoice: initialState.deactivateInvoice,
  };
}
//#endregion

//#region DEACTIVATE INVOICE PROVIDER
export function deactivateInvoiceProvider(state: IState): IState {
  return {
    ...state,
    deactivateInvoiceProvider: { success: false, loading: true, error: undefined },
  };
}

export function deactivateInvoiceProviderSuccess(state: IState): IState {
  return {
    ...state,
    deactivateInvoiceProvider: { success: true, loading: false, error: undefined },
  };
}

export function deactivateInvoiceProviderFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    deactivateInvoiceProvider: { success: false, loading: false, error: action.payload.error },
  };
}
//#endregion
