import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import { getUser, resetUser } from '../../../../store/user/actions';
import { IStoreState } from './UserDetail.types';
import UserDetail from './UserDetail';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeUser } = state.user;

    return {
        user: activeUser.value,
        loading: activeUser.loading,
        error: activeUser.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getUser,
            resetUser,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserDetail);
