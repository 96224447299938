import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { IInvoice } from '../../../../../common/types/entities';
import { TableCellItem, TableRowOptionsDropdown } from '../../../../../components';
import { formatHelper } from '../../../../../common/helpers';
import { currencyTypes } from '../../../../../common/constants';

export const columns = (
    onClickDetail: (id: number) => void,
    deactivateInvoiceOrPayment: (id: number, type: string) => void,
): ColumnProps<IInvoice>[] => {
    return [
        {
            title: 'Código',
            dataIndex: 'code',
            className: 'd-none d-md-table-cell',
        },
        {
            title: 'Pagado',
            dataIndex: 'paid',
            className: 'd-none d-md-table-cell',
            render: (paid: boolean) => <span>{paid ? 'Sí' : 'No'}</span>,
        },
        {
            title: 'Fecha de Emisión',
            dataIndex: 'emissionDate',
            className: 'd-none d-md-table-cell',
            render: (emissionDate: any) => <span>{formatHelper.toDate(emissionDate)}</span>,
        },
        {
            title: 'Fecha de Vencimiento',
            dataIndex: 'dueDate',
            className: 'd-none d-md-table-cell',
            render: (dueDate: any) => <span>{formatHelper.toDate(dueDate)}</span>,
        },
        {
            title: 'Precio Total',
            className: 'd-none d-md-table-cell',
            render: (invoice: IInvoice) => (
                <span>
                    {formatHelper.toMoney(
                        invoice.totalPrice,
                        invoice.currency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                    )}
                </span>
            ),
        },
        {
            title: 'Monto Restante',
            className: 'd-none d-md-table-cell',
            render: (invoice: IInvoice) => (
                <span>
                    {formatHelper.toMoney(
                        invoice.paymentRest,
                        invoice.currency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                    )}
                </span>
            ),
        },
        {
            key: 'xs',
            title: 'Factura',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (item: IInvoice) => (
                <>
                    <TableCellItem label="Código" value={item.code} />
                    <TableCellItem label="Pagado" value={item.paid ? 'Sí' : 'No'} />
                    <TableCellItem label="Fecha de Emisión" value={item.emissionDate} type="date" />
                    <TableCellItem label="Fecha de Vencimiento" value={item.dueDate} type="date" />
                    <TableCellItem
                        label="Precio Total"
                        value={formatHelper.toMoney(
                            item.totalPrice,
                            item.currency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                        )}
                    />
                    <TableCellItem
                        label="Monto Restante"
                        value={formatHelper.toMoney(
                            item.paymentRest,
                            item.currency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                        )}
                    />
                </>
            ),
        },
        {
            key: 'options',
            dataIndex: '',
            render: (item: IInvoice) => (
                <TableRowOptionsDropdown
                    onClickDetail={() => onClickDetail(item.id)}
                    onClickCancel={
                        item.active ? () => deactivateInvoiceOrPayment(item.id, 'invoice') : null
                    }
                />
            ),
        },
    ];
};
