import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem, TableRowOptionsDropdown, TableRowOptionItem } from '../../../../components';
import { IPettyCashMovement } from '../../../../common/types/entities';
import { formatHelper } from '../../../../common/helpers';
import { permissions } from '../../../../common/constants';
import { Button } from 'antd';

export const columns = (
    onClickShowItems: (id: number) => void,
    onClickDelete: (id: number) => void,
    pettyCashActive: boolean,
): ColumnProps<IPettyCashMovement>[] => [
    {
        title: 'Código',
        dataIndex: 'code',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Monto Total',
        dataIndex: 'totalPrice',
        className: 'd-none d-md-table-cell',
        render: (totalPrice: number) => <span>{formatHelper.toMoney(totalPrice)}</span>,
    },
    {
        title: 'Comentario',
        dataIndex: 'comment',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Fecha de Pago',
        dataIndex: 'paymentDate',
        className: 'd-none d-md-table-cell',
        render: (paymentDate: Date) => <span>{formatHelper.toDate(paymentDate)}</span>,
    },
    {
        key: 'xs',
        title: 'Movimiento',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IPettyCashMovement) => (
            <>
                <TableCellItem label="Código" value={item.code} />
                <TableCellItem label="Monto Total" value={item.totalPrice} type="money" />
                <TableCellItem label="Comentario" value={item.comment} />
                <TableCellItem label="Fecha de Pago" value={item.paymentDate} type="date" />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IPettyCashMovement) => {
            if (pettyCashActive) {
                return (
                    <TableRowOptionsDropdown
                        onClickDelete={() => onClickDelete(item.id)}
                        deletePermission={permissions.pettyCash.WRITE_PETTY_CASH_MOVEMENTS}
                    >
                        <TableRowOptionItem
                            label="Items"
                            icon="unordered-list"
                            onClick={() => onClickShowItems(item.id)}
                            permission={permissions.pettyCash.READ_PETTY_CASH_MOVEMENTS}
                        />
                    </TableRowOptionsDropdown>
                );
            }

            return (
                <Button
                    type="primary"
                    shape="round"
                    icon="unordered-list"
                    onClick={() => onClickShowItems(item.id)}
                />
            );
        },
    },
];
