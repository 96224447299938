import React, { FC, useRef } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal } from 'antd';
import { isEmpty } from 'validator';
import { InputGroupField } from '../../../../../components';
import { messages } from '../../../../../common/constants';
import { IZone } from '../../../../../common/types/entities';

interface IOwnProps {
    isOpen: boolean;
    onSubmit: (values: IAddEditZoneValues) => void;
    disabled?: boolean;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    initialValues?: IZone;
    submitting?: boolean;
}

export interface IAddEditZoneValues {
    name: string;
}

export type IProps = IOwnProps;

const AddEditModal: FC<IProps> = props => {
    let form = useRef<Formik<IAddEditZoneValues>>(null);

    const onSubmit = (values: IAddEditZoneValues, actions: FormikActions<IAddEditZoneValues>) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = e => {
        form.current.resetForm();
        props.onCancel(e);
    };

    const validate = (values: IAddEditZoneValues) => {
        let errors: Partial<IAddEditZoneValues> = {};

        if (isEmpty(values.name)) errors.name = messages.MANDATORY_FIELD;

        return errors;
    };

    const onOk = () => {
        form.current.submitForm();
    };

    const renderFormik = () => {
        if (!props.isOpen) return null;

        const init = props.initialValues || {};

        return (
            <Formik
                initialValues={{
                    name: init.name || '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={(formikBag: FormikProps<IAddEditZoneValues>) => (
                    <Form>
                        <InputGroupField
                            name="name"
                            label="Nombre"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            title={props.initialValues ? 'Editar Giro' : 'Nuevo Giro'}
            visible={props.isOpen}
            okText="Guardar"
            onOk={onOk}
            okButtonProps={{ loading: props.submitting }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.submitting }}
        >
            {renderFormik()}
        </Modal>
    );
};

export default AddEditModal;
