import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './IncomeDetail.types';
import IncomeDetail from './IncomeDetail';
import { IRootState } from '../../../../common/types';
import {
    getIncome,
    resetIncome,
    cancelIncome,
    resetCancelIncome,
    verifyPrintIncome,
    resetVerifyPrintIncome,
    completePendingIncome,
    resetCompletePendingIncome,
} from '../../../../store/income/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeIncome, cancelIncome, verifyPrintIncome, completePendingIncome } = state.income;

    return {
        income: activeIncome.value,
        error: activeIncome.error,
        loading: activeIncome.loading,
        cancelIncomeSuccess: cancelIncome.success,
        cancelIncomeLoading: cancelIncome.loading,
        cancelIncomeError: cancelIncome.error,
        verifyPrintSuccess: verifyPrintIncome.success,
        verifyPrintLoading: verifyPrintIncome.loading,
        verifyPrintError: verifyPrintIncome.error,
        completePendingIncomeSuccess: completePendingIncome.success,
        completePendingIncomeLoading: completePendingIncome.loading,
        completePendingIncomeError: completePendingIncome.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getIncome,
            resetIncome,
            cancelIncome,
            resetCancelIncome,
            verifyPrintIncome,
            resetVerifyPrintIncome,
            completePendingIncome,
            resetCompletePendingIncome,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomeDetail);
