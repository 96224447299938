import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getRucInfo, resetRucInfo } from '../../../../../../store/commonRequest/actions';
import { IRootState } from '../../../../../../common/types';
import { IStoreState } from './IncomeCompletePaymentForm.types';
import IncomeCompletePaymentForm from './IncomeCompletePaymentForm';

function mapStateToProps(state: IRootState): IStoreState {
    const { rucInfo } = state.commonRequest;

    return {
        rucInfo: rucInfo.value,
        rucInfoLoading: rucInfo.loading,
        rucInfoError: rucInfo.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getRucInfo,
            resetRucInfo,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IncomeCompletePaymentForm);
