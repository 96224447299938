import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { providerService } from '../../service';
import { IProvider } from '../../common/types/entities';

export function getProviders(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
  return {
    callAPI: () => providerService.getAll(filter, page),
    types: [
      actionTypes.GET_PROVIDERS,
      actionTypes.GET_PROVIDERS_SUCCESS,
      actionTypes.GET_PROVIDERS_FAILURE,
    ],
  };
}

export function resetProviders() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.GET_PROVIDERS_RESET,
    });
  };
}

export function getProvider(id: number): IAPIMiddleware {
  return {
    callAPI: () => providerService.get(id),
    types: [
      actionTypes.GET_PROVIDER,
      actionTypes.GET_PROVIDER_SUCCESS,
      actionTypes.GET_PROVIDER_FAILURE,
    ],
  };
}

export function resetProvider() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.GET_PROVIDER_RESET,
    });
  };
}

export function createProvider(data: IProvider): IAPIMiddleware {
  return {
    callAPI: () => providerService.create(data),
    types: [
      actionTypes.CREATE_PROVIDER,
      actionTypes.CREATE_PROVIDER_SUCCESS,
      actionTypes.CREATE_PROVIDER_FAILURE,
    ],
  };
}

export function updateProvider(id: number, data: IProvider): IAPIMiddleware {
  return {
    callAPI: () => providerService.update(id, data),
    types: [
      actionTypes.UPDATE_PROVIDER,
      actionTypes.UPDATE_PROVIDER_SUCCESS,
      actionTypes.UPDATE_PROVIDER_FAILURE,
    ],
  };
}

export function deactivateProvider(id: number): IAPIMiddleware {
  return {
    callAPI: () => providerService.deactivate(id),
    types: [
      actionTypes.DEACTIVATE_PROVIDER,
      actionTypes.DEACTIVATE_PROVIDER_SUCCESS,
      actionTypes.DEACTIVATE_PROVIDER_FAILURE,
    ],
  };
}
