import * as React from 'react';
import { IProps } from './ExpenseDetail.types';
import {
    SectionContainer,
    DisplayItem,
    DataTable,
    GroupFieldWrapper,
    PBR,
} from '../../../../components';
import { paths } from '../../../../routes';
import { Divider, Tabs, Modal, message, Select, Upload, Col, Row } from 'antd';
import { goodsColumns, servicesColumns } from './misc/columns';
import { invoiceColumns } from './misc/invoiceColumns';
import { paymentColumns } from './misc/paymentColumns';
import {
    expenseStates,
    expenseTypes,
    currencyTypes,
    permissions,
} from '../../../../common/constants';
import { formatHelper } from '../../../../common/helpers';
import { DeactivateInvoicePaymentModal } from './components';
import { IDeactivateInvoicePaymentForm } from './components/DeactivateInvoicePaymentModal';
import { IInvoice, IPayment } from '../../../../common/types/entities';
import { helper } from '../../../../common/helpers';
import nanoid from 'nanoid';

class ExpenseDetail extends React.Component<IProps> {
    expenseId = this.props.match.params['id'];

    state = {
        modalDeactivateInvoiceVisible: false,
        modalDeactivatePaymentVisible: false,
        activeIdToDeactivate: null,
        requestOrderSelected: null,
        basicDetailView: null,
    };

    componentDidMount() {
        this.props.getExpense(this.expenseId);
    }

    componentWillUnmount() {
        this.props.resetExpense();
        this.props.resetRequestOrderDetails();
        this.props.resetRequestOrdersByExpense();
        this.props.resetInvoicesbyRequestOrderDetails();
        this.props.resetPaymentsByRequestOrder();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkActiveExpense(prevProps);
        this.checkDeleteExpenseDetail(prevProps);
        this.checkDeactivateInvoice(prevProps);
        this.checkDeactivatePayment(prevProps);
    }

    async checkActiveExpense(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.expense && props.expense) {
            const { id: stateId } = props.expense.state;

            if (!this.state.basicDetailView && stateId == expenseStates.REQUESTED) {
                this.props.getRequestOdersByExpense(this.expenseId);
                this.setState({ basicDetailView: false });
            } else {
                this.props.getExpenseDetails(this.expenseId);
                this.setState({ basicDetailView: true });
            }
        }
    }

    async checkDeleteExpenseDetail(prevProps: IProps) {
        if (!prevProps.deleteExpenseDetailSuccess && this.props.deleteExpenseDetailSuccess) {
            message.success('Requerimiento eliminado');
            this.props.getRequestOrderDetails(this.state.requestOrderSelected.id);
        } else if (!prevProps.deleteExpenseDetailError && this.props.deleteExpenseDetailError)
            message.error(this.props.deleteExpenseDetailError);
    }

    async checkDeactivateInvoice(prevProps: IProps) {
        if (!prevProps.deactivateInvoiceSuccess && this.props.deactivateInvoiceSuccess) {
            message.success('Factura anulada');
            this.props.getExpense(this.expenseId);
            this.props.getInvoicesByRequestOrder(this.state.requestOrderSelected.id);
            this.setState({
                activeIdToDeactivate: null,
                modalDeactivateInvoiceVisible: false,
            });
        } else if (!prevProps.deactivateInvoiceError && this.props.deactivateInvoiceError)
            message.error(this.props.deactivateInvoiceError);
    }

    async checkDeactivatePayment(prevProps: IProps) {
        if (!prevProps.deactivatePaymentSuccess && this.props.deactivatePaymentSuccess) {
            message.success('Pago anulado');
            this.props.getExpense(this.expenseId);
            this.props.getPaymentsByRequestOrder(this.state.requestOrderSelected.id);
            this.setState({
                activeIdToDeactivate: null,
                modalDeactivatePaymentVisible: false,
            });
        } else if (!prevProps.deactivatePaymentError && this.props.deactivatePaymentError)
            message.error(this.props.deactivatePaymentError);
    }

    renderUsers = () => {
        const { expense } = this.props;
        const { id: stateId } = expense.state;

        return (
            <>
                <Divider orientation="left">Responsables</Divider>
                <DisplayItem
                    label="Solicitante"
                    value={formatHelper.getUserFullname(expense.requestUser)}
                />
                {(stateId == expenseStates.ACCEPTED || stateId == expenseStates.REJECTED) && (
                    <DisplayItem
                        label="Respuesta"
                        value={formatHelper.getUserFullname(expense.responseUser)}
                    />
                )}
            </>
        );
    };

    onClickDeactivateInvoice = (values: IDeactivateInvoicePaymentForm) => {
        const invoice: IInvoice = {
            reasonToDelete: values.reasonToDeactivate,
        };

        this.props.deactivateInvoice(this.state.activeIdToDeactivate, invoice);
    };

    onChangeRequestOrder = value => {
        const requestOrderSelected = this.props.requestOrdersByExpense.find(i => i.id == value);
        this.setState({ requestOrderSelected });
        this.props.getRequestOrderDetails(value);
        this.props.getInvoicesByRequestOrder(value);
        this.props.getPaymentsByRequestOrder(value);
    };

    onClickDeactivatePayment = (values: IDeactivateInvoicePaymentForm) => {
        const payment: IPayment = {
            reasonToDelete: values.reasonToDeactivate,
        };

        this.props.deactivatePayment(this.state.activeIdToDeactivate, payment);
    };

    toggleModalDeactivateInvoiceVisible = () => {
        this.setState({ modalDeactivateInvoiceVisible: !this.state.modalDeactivateInvoiceVisible });
    };

    toggleModalDeactivatePaymentVisible = () => {
        this.setState({ modalDeactivatePaymentVisible: !this.state.modalDeactivatePaymentVisible });
    };

    deactivateInvoiceOrPayment = (id: number, type: string) => {
        if (type === 'invoice') this.toggleModalDeactivateInvoiceVisible();
        if (type === 'payment') this.toggleModalDeactivatePaymentVisible();
        this.setState({ activeIdToDeactivate: id });
    };

    detailInvoice = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_INVOICE_DETAIL, { id }));
    };

    detailPayment = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_PAYMENT_DETAIL, { id }));
    };

    renderContent = () => {
        const {
                expense,
                requestOrderDetailList,
                requestOrderDetailListLoading,
                invoicesByRequestOrder,
                invoicesByRequestOrderLoading,
                paymentsByRequestOrder,
                paymentsByRequestOrderLoading,
                requestOrdersByExpense,
                requestOrdersByExpenseLoading,
                expenseDetails,
                expenseDetailsLoading,
            } = this.props,
            stateId = expense.state.id,
            isGoodsType = expense.type.id == expenseTypes.GOODS,
            currencyId = this.state.requestOrderSelected
                ? this.state.requestOrderSelected.currency.id
                : null;

        return (
            <>
                <DisplayItem label="Código" value={expense.code} />
                <DisplayItem label="Estado" value={expense.state.description} />
                {this.renderUsers()}
                <Divider orientation="left">Información</Divider>
                <DisplayItem label="Tipo" value={expense.type.description} />
                <DisplayItem label="Motivo" value={expense.reason} />
                <DisplayItem label="Precio Total (PEN)" type="money" value={expense.totalPrice} />
                <DisplayItem
                    label="Precio Total (USD)"
                    type="money"
                    value={expense.totalPriceUSD}
                />
                {stateId == expenseStates.REJECTED && (
                    <DisplayItem
                        label="Motivo de Rechazo"
                        value={expense.reasonToDelete}
                        vStyles={{ fontWeight: 'bold' }}
                    />
                )}
                {this.state.basicDetailView ? (
                    <>
                        <Divider orientation="left" className="mt-5">
                            {isGoodsType ? 'Bienes' : 'Servicios'}
                        </Divider>
                        <DataTable
                            data={expenseDetails}
                            columns={isGoodsType ? goodsColumns(stateId) : servicesColumns(stateId)}
                            rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                            loading={expenseDetailsLoading}
                        />
                    </>
                ) : (
                    <>
                        <GroupFieldWrapper
                            lxs={24}
                            lsm={6}
                            lmd={5}
                            llg={4}
                            lxl={4}
                            lxxl={3}
                            label="Órdenes de Compra"
                        >
                            <Select
                                style={{ width: '100%' }}
                                value={
                                    this.state.requestOrderSelected
                                        ? this.state.requestOrderSelected.id
                                        : null
                                }
                                loading={requestOrdersByExpenseLoading}
                                onChange={this.onChangeRequestOrder}
                            >
                                {requestOrdersByExpense.map(i => (
                                    <Select.Option key={nanoid()} value={i.id}>
                                        Orden N - {i.order}
                                    </Select.Option>
                                ))}
                            </Select>
                        </GroupFieldWrapper>
                        {this.state.requestOrderSelected && (
                            <>
                                <DisplayItem
                                    label="Monto Total de Orden de Compra"
                                    vStyles={{ fontWeight: 'bold' }}
                                    value={formatHelper.toMoney(
                                        this.state.requestOrderSelected.totalPrice,
                                        currencyId == currencyTypes.PEN ? 'PEN' : 'USD',
                                    )}
                                />
                                <DisplayItem
                                    label="Monto Restante"
                                    vStyles={{ fontWeight: 'bold' }}
                                    value={formatHelper.toMoney(
                                        this.state.requestOrderSelected.remainingPayment,
                                        currencyId == currencyTypes.PEN ? 'PEN' : 'USD',
                                    )}
                                />
                            </>
                        )}
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Detalle de Orden de Compra" key="1">
                                <DataTable
                                    data={requestOrderDetailList}
                                    columns={
                                        isGoodsType
                                            ? goodsColumns(stateId, currencyId)
                                            : servicesColumns(stateId, currencyId)
                                    }
                                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                                    loading={requestOrderDetailListLoading}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab="Facturas"
                                key="2"
                                disabled={stateId !== expenseStates.REQUESTED}
                            >
                                <DataTable
                                    data={invoicesByRequestOrder}
                                    columns={invoiceColumns(
                                        this.detailInvoice,
                                        this.deactivateInvoiceOrPayment,
                                        currencyId,
                                    )}
                                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                                    loading={invoicesByRequestOrderLoading}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab="Pagos"
                                key="3"
                                disabled={stateId !== expenseStates.REQUESTED}
                            >
                                <DataTable
                                    data={paymentsByRequestOrder}
                                    columns={paymentColumns(
                                        this.detailPayment,
                                        this.deactivateInvoiceOrPayment,
                                        currencyId,
                                    )}
                                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                                    loading={paymentsByRequestOrderLoading}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                        <Divider orientation="left" className="mt-5">
                            Archivos Orden de Compra
                        </Divider>
                        <Row>
                            <Col lg={12} md={12} xs={24} sm={24}>
                                {this.state.requestOrderSelected && (
                                    <Upload
                                        disabled={true}
                                        defaultFileList={helper.getFileList(
                                            this.state.requestOrderSelected.attachedFiles,
                                        )}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Divider orientation="left" className="mt-5">
                            Archivos de Facturas
                        </Divider>
                        <Row>
                            <Col lg={12} md={12} xs={24} sm={24}>
                                {invoicesByRequestOrder && invoicesByRequestOrder.length > 0 && (
                                    <Upload
                                        disabled={true}
                                        defaultFileList={helper.getArrayFileList(
                                            invoicesByRequestOrder,
                                        )}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Divider orientation="left" className="mt-5">
                            Archivos de Pagos
                        </Divider>
                        <Row>
                            <Col lg={12} md={12} xs={24} sm={24}>
                                {paymentsByRequestOrder && paymentsByRequestOrder.length > 0 && (
                                    <Upload
                                        disabled={true}
                                        defaultFileList={helper.getArrayFileList(
                                            paymentsByRequestOrder,
                                        )}
                                    />
                                )}
                            </Col>
                        </Row>
                        <PBR permission={permissions.expense.WRITE_INVOICES}>
                            <DeactivateInvoicePaymentModal
                                isOpen={this.state.modalDeactivateInvoiceVisible}
                                onSubmit={this.onClickDeactivateInvoice}
                                onCancel={() =>
                                    this.setState({ modalDeactivateInvoiceVisible: false })
                                }
                                initialValues={null}
                                submitting={this.props.deactivateInvoiceLoading}
                                title="Anular Factura"
                            />
                        </PBR>
                        <PBR permission={permissions.expense.WRITE_PAYMENTS}>
                            <DeactivateInvoicePaymentModal
                                isOpen={this.state.modalDeactivatePaymentVisible}
                                onSubmit={this.onClickDeactivatePayment}
                                onCancel={() =>
                                    this.setState({ modalDeactivatePaymentVisible: false })
                                }
                                initialValues={null}
                                submitting={this.props.deactivatePaymentLoading}
                                title="Anular Pago"
                            />
                        </PBR>
                    </>
                )}
            </>
        );
    };

    render() {
        const { expense, loading, error } = this.props;

        return (
            <SectionContainer
                title="Detalle de Egreso"
                onBack={() => this.props.history.push(paths.ADMIN_EXPENSE_LIST)}
                loading={loading || !expense}
                error={error}
                content={this.renderContent}
            />
        );
    }
}

export default ExpenseDetail;
