import React from 'react';
import { SectionContainer } from '../../../../components';
import { IProps } from './ProviderAddEdit.types';
import ProviderForm from './component/providerForm';
import { message } from 'antd';
import { paths } from '../../../../routes';

export default class ProviderAddEdit extends React.Component<IProps> {
    private providerId = this.props.match.params['id'];

    componentDidMount() {
        if (this.providerId) this.props.getProvider(this.providerId);
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkProviderUpdate(prevProps);
        this.checkProviderCreation(prevProps);
    }

    componentWillUnmount() {
        this.props.resetProvider();
    }

    checkProviderCreation(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.createSuccess && props.createSuccess) {
            message.success('Proveedor creado');
            this.props.history.push(paths.ADMIN_PROVIDER_LIST);
        } else if (!prevProps.createError && props.createError) message.error(props.createError);
    }

    checkProviderUpdate(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.updateSuccess && props.updateSuccess) {
            message.success('Proveedor actualizado');
            this.props.history.push(paths.ADMIN_PROVIDER_LIST);
        } else if (!prevProps.updateError && props.updateError) message.error(props.updateError);
    }

    onSubmit = (values: any) => {
        if (this.providerId) return this.props.updateProvider(this.providerId, values);
        return this.props.createProvider(values);
    };

    onCancel = () => {
        this.props.history.push(paths.ADMIN_PROVIDER_LIST);
    };

    renderForm = () => {
        const { activeProvider, activeProviderLoading, createLoading, updateLoading } = this.props;

        return (
            <ProviderForm
                initialValues={activeProvider || {}}
                onSubmit={this.onSubmit}
                disabled={activeProviderLoading}
                submitting={createLoading || updateLoading}
            />
        );
    };

    render() {
        const { activeProviderLoading, activeProviderError } = this.props;

        return (
            <SectionContainer
                title={this.providerId ? 'Editar Proveedor' : 'Nuevo Proveedor'}
                onBack={() => this.props.history.push(paths.ADMIN_PROVIDER_LIST)}
                loading={activeProviderLoading}
                error={activeProviderError}
                content={this.renderForm}
            />
        );
    }
}
