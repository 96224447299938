import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
} from '../../common/helpers/reducer';

//#region GET TAXES
export function getTaxes(state: IState): IState {
    return {
        ...state,
        taxList: listStatePaginatedLoading(state.taxList),
    };
}

export function getTaxesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        taxList: listStatePaginatedSuccess(action.payload),
    };
}

export function getTaxesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        taxList: listStatePaginatedFailure(initialState.taxList, action.payload.error),
    };
}

export function getTaxesReset(state: IState): IState {
    return {
        ...state,
        taxList: initialState.taxList,
    };
}
//#endregion
