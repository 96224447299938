import React, { FunctionComponent } from 'react';
import { SectionContainer } from '../../../components';
import { List, Icon, Typography, Row, Col } from 'antd';
import { incomeItems, IListReportItem } from './misc/listItems';
import { IProps } from './ReportHome.types';

const ReportHome: FunctionComponent<IProps> = props => {
    return (
        <SectionContainer title="Reportes" icon="line-chart">
            <Row>
                <Col lg={11} xl={7} className="mr-3 mb-3">
                    <List
                        header={
                            <div>
                                <Icon type="arrow-up" className="mr-2" />
                                <Typography.Title level={4} className="d-inline">
                                    Ingresos
                                </Typography.Title>
                            </div>
                        }
                        dataSource={incomeItems()}
                        bordered
                        renderItem={(item: IListReportItem) => {
                            if (
                                !item.permission ||
                                (item.permission && props.permissions.includes(item.permission))
                            ) {
                                return (
                                    <>
                                        <List.Item
                                            className="cursor-pointer hover-effect"
                                            onClick={() => props.history.push(item.path)}
                                        >
                                            <span>{item.title}</span>
                                        </List.Item>
                                    </>
                                );
                            }

                            return <></>;
                        }}
                    />
                </Col>
            </Row>
        </SectionContainer>
    );
};

export default ReportHome;
