import React, { FunctionComponent } from 'react';
import { Radio } from 'antd';
import { Field, FieldProps } from 'formik';
import { getPropertyByKeyPath } from '../../../common/utils';
import { RadioGroupProps } from 'antd/lib/radio';
import { FieldMessage } from '../..';

export type IRadioFieldProps = RadioGroupProps;

const InputField: FunctionComponent<IRadioFieldProps> = ({
    name,
    onChange,
    className,
    ...restProps
}) => {
    return (
        <Field name={name}>
            {({
                field: { value },
                form: { setFieldValue, setFieldTouched, errors },
            }: FieldProps) => {
                const error = getPropertyByKeyPath(errors, name);
                const hasError = error != undefined;
                return (
                    <>
                        <Radio.Group
                            value={value}
                            onChange={event => {
                                setFieldValue(name, event.target.value);
                                setFieldTouched(name, true);
                                onChange && onChange(event);
                            }}
                            {...restProps}
                        />
                        <FieldMessage show={hasError} message={error} />
                    </>
                );
            }}
        </Field>
    );
};

export default InputField;
