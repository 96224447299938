import React from 'react';
import { Button, Row, Col, Input, message, Modal } from 'antd';
import { columns } from './misc/columns';
import { IProps, IState } from './UserList.types';
import { SectionContainer, DataTable, PBR } from '../../../../components';
import { permissions } from '../../../../common/constants';
import { paths } from '../../../../routes';
import { helper } from '../../../../common/helpers';

export default class UserList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
    };

    componentDidMount() {
        this.performGetUsers();
    }

    componentWillUnmount() {
        this.props.resetUsers();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkUserDeactivate(prevProps);
    }

    async checkUserDeactivate(prevProps: IProps) {
        if (!prevProps.deactivateSuccess && this.props.deactivateSuccess) {
            message.success('Usuario eliminado');
            await this.setState({ page: 1 });
            this.performGetUsers();
        } else if (!prevProps.deactivateError && this.props.deactivateError)
            message.error(this.props.deactivateError);
    }

    performGetUsers() {
        const { filter, page } = this.state;
        this.props.getUsers(filter, page);
    }

    async filterUsers() {
        await this.setState({ page: 1 });
        this.performGetUsers();
    }

    perfromDeactivateUser = (id: number) => {
        const { deactivateUser } = this.props;

        Modal.confirm({
            title: '¿Deseas eliminar el usuario?',
            content: 'Eliminar usuario',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deactivateUser(id);
            },
            onCancel() {},
        });
    };

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetUsers();
    };

    onClickDetail = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_USER_DETAIL, { id }));
    };

    onClickEdit = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_USER_EDIT, { id }));
    };

    render() {
        const { loading, users, pageSize, total } = this.props;

        return (
            <SectionContainer title="Usuarios" icon="user">
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={e => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterUsers()}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.user.WRITE_USERS}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() => this.props.history.push(paths.ADMIN_USER_CREATE)}
                            >
                                Nuevo
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={users}
                            columns={columns(
                                this.onClickDetail,
                                this.onClickEdit,
                                this.perfromDeactivateUser,
                                this.props.permissions,
                            )}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
            </SectionContainer>
        );
    }
}
