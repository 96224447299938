import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';
import { IPayment } from '../common/types/entities';

const { PAYMENT_URL } = apiUrls;

export function getPayment(id: number) {
    const url = `${PAYMENT_URL}/${id}`;
    return APIManager.get(url);
}

export function getPaymentsByRequestOrder(id: number) {
    const url = `${PAYMENT_URL}/requestOrder/${id}`;
    return APIManager.get(url);
}

export function getPaymentsByProvider(providerId: number, filter: string, page: number) {
    const params = { providerId, filter, page },
        url = `${PAYMENT_URL}/list/provider`;

    return APIManager.get(url, { params });
}

export function completePayment(data: IPayment) {
    const url = `${PAYMENT_URL}`;
    return data.files && data.files.length > 0
        ? APIManager.postWithFiles(url, { data })
        : APIManager.post(url, { data });
}

export function completePaymentProvider(data: IPayment) {
    const url = `${PAYMENT_URL}/provider`;
    return data.files && data.files.length > 0
        ? APIManager.postWithFiles(url, { data })
        : APIManager.post(url, { data });
}

export function deactivatePayment(id: number, data: IPayment) {
    const url = `${PAYMENT_URL}/${id}/deactivate`;
    return APIManager.put(url, { data });
}
