import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStateLoading,
    listStateSuccess,
    listStateFailure,
    objectStateSuccess,
    objectStateFailure,
    objectStateLoading,
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
} from '../../common/helpers/reducer';

//#region GET PAYMENT
export function getPayment(state: IState): IState {
    return {
        ...state,
        activePayment: objectStateLoading(),
    };
}

export function getPaymentSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activePayment: objectStateSuccess(action.payload),
    };
}

export function getPaymentFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activePayment: objectStateFailure(action.payload.error),
    };
}
//#endregion

//#region GET PAYMENTS By Request Order
export function getPaymentsByRequestOrder(state: IState): IState {
    return {
        ...state,
        paymentByRequestOrderList: listStateLoading(),
    };
}

export function getPaymentsByRequestOrderSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        paymentByRequestOrderList: listStateSuccess(action.payload.data),
    };
}

export function getPaymentsByRequestOrderFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        paymentByRequestOrderList: listStateFailure(action.payload.error),
    };
}

export function getPaymentsByRequestOrderReset(state: IState): IState {
    return {
        ...state,
        paymentByRequestOrderList: initialState.paymentByRequestOrderList,
    };
}
//#endregion

//#region GET PAYMENTS BY PROVIDER
export function getPaymentsByProvider(state: IState): IState {
    return {
        ...state,
        paymentByProviderList: listStatePaginatedLoading(state.paymentByProviderList),
    };
}
export function getPaymentsByProviderSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        paymentByProviderList: listStatePaginatedSuccess(action.payload),
    };
}
export function getPaymentsByProviderFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        paymentByProviderList: listStatePaginatedFailure(
            initialState.paymentByProviderList,
            action.payload.error,
        ),
    };
}
export function getPaymentsByProviderReset(state: IState): IState {
    return {
        ...state,
        paymentByProviderList: initialState.paymentByProviderList,
    };
}
//#endregion

//#region COMPLETE PAYMENT
export function completePayment(state: IState): IState {
    return {
        ...state,
        completePayment: { success: false, loading: true, error: undefined },
    };
}

export function completePaymentSuccess(state: IState): IState {
    return {
        ...state,
        completePayment: { success: true, loading: false, error: undefined },
    };
}

export function completePaymentFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        completePayment: { success: false, loading: false, error: action.payload.error },
    };
}
//#endregion

//#region COMPLETE PAYMENT PROVIDER
export function completePaymentProvider(state: IState): IState {
    return {
        ...state,
        completePaymentProvider: { success: false, loading: true, error: undefined },
    };
}

export function completePaymentProviderSuccess(state: IState): IState {
    return {
        ...state,
        completePaymentProvider: { success: true, loading: false, error: undefined },
    };
}

export function completePaymentProviderFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        completePaymentProvider: { success: false, loading: false, error: action.payload.error },
    };
}
//#endregion

//#region DEACTIVATE PAYMENT
export function deactivatePayment(state: IState): IState {
    return {
        ...state,
        deactivatePayment: { success: false, loading: true, error: undefined },
    };
}

export function deactivatePaymentSuccess(state: IState): IState {
    return {
        ...state,
        deactivatePayment: { success: true, loading: false, error: undefined },
    };
}

export function deactivatePaymentFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivatePayment: { success: false, loading: false, error: action.payload.error },
    };
}

export function deactivatePaymentReset(state: IState): IState {
    return {
        ...state,
        deactivatePayment: initialState.deactivatePayment,
    };
}
//#endregion
