import { IListStatePaginated, IObjectState, IProcessState, IListState } from '../../common/types';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';
import {
    IBankAccount,
    IBankAccountType,
    IBankAccountTransaction,
} from '../../common/types/entities';

export interface IState {
    bankAccountList: IListStatePaginated<IBankAccount>;
    activeBankAccount: IObjectState<IBankAccount>;
    createBankAccountTransaction: IProcessState;
    createBankAccount: IProcessState;
    updateBankAccount: IProcessState;
    deactivateBankAccount: IProcessState;
    bankAccountTypeList: IListState<IBankAccountType>;
    bankAccountByCurrencyList: IListState<IBankAccount>;
    bankAccountTransactionList: IListStatePaginated<IBankAccountTransaction>;
}

export const initialState: IState = {
    bankAccountList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activeBankAccount: { value: undefined, loading: false, error: undefined },
    createBankAccountTransaction: { success: false, loading: false, error: undefined },
    createBankAccount: { success: false, loading: false, error: undefined },
    updateBankAccount: { success: false, loading: false, error: undefined },
    deactivateBankAccount: { success: false, loading: false, error: undefined },
    bankAccountTypeList: { data: [], loading: false, error: undefined },
    bankAccountByCurrencyList: { data: [], loading: false, error: undefined },
    bankAccountTransactionList: {
        data: [],
        total: 0,
        pageSize: 0,
        loading: false,
        error: undefined,
    },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_BANK_ACCOUNTS]: caseFunctions.getBankAccounts,
    [actionTypes.GET_BANK_ACCOUNTS_SUCCESS]: caseFunctions.getBankAccountsSuccess,
    [actionTypes.GET_BANK_ACCOUNTS_FAILURE]: caseFunctions.getBankAccountsFailure,
    [actionTypes.GET_BANK_ACCOUNTS_RESET]: caseFunctions.getBankAccountsReset,

    [actionTypes.GET_BANK_ACCOUNTS_BY_CURRENCY]: caseFunctions.getBankAccountsByCurrency,
    [actionTypes.GET_BANK_ACCOUNTS_BY_CURRENCY_SUCCESS]:
        caseFunctions.getBankAccountsByCurrencySuccess,
    [actionTypes.GET_BANK_ACCOUNTS_BY_CURRENCY_FAILURE]:
        caseFunctions.getBankAccountsByCurrencyFailure,
    [actionTypes.GET_BANK_ACCOUNTS_BY_CURRENCY_RESET]: caseFunctions.getBankAccountsByCurrencyReset,

    [actionTypes.GET_BANK_ACCOUNT_TRANSACTIONS]: caseFunctions.getBankAccountTransactions,
    [actionTypes.GET_BANK_ACCOUNT_TRANSACTIONS_SUCCESS]:
        caseFunctions.getBankAccountTransactionsSuccess,
    [actionTypes.GET_BANK_ACCOUNT_TRANSACTIONS_FAILURE]:
        caseFunctions.getBankAccountTransactionsFailure,
    [actionTypes.GET_BANK_ACCOUNT_TRANSACTIONS_RESET]:
        caseFunctions.getBankAccountTransactionsReset,

    [actionTypes.GET_BANK_ACCOUNT]: caseFunctions.getBankAccount,
    [actionTypes.GET_BANK_ACCOUNT_SUCCESS]: caseFunctions.getBankAccountSuccess,
    [actionTypes.GET_BANK_ACCOUNT_FAILURE]: caseFunctions.getBankAccountFailure,
    [actionTypes.GET_BANK_ACCOUNT_RESET]: caseFunctions.getBankAccountReset,

    [actionTypes.CREATE_BANK_ACCOUNT_TRANSACTION]: caseFunctions.createBankAccountTransaction,
    [actionTypes.CREATE_BANK_ACCOUNT_TRANSACTION_SUCCESS]:
        caseFunctions.createBankAccountTransactionSuccess,
    [actionTypes.CREATE_BANK_ACCOUNT_TRANSACTION_FAILURE]:
        caseFunctions.createBankAccountTransactionFailure,
    [actionTypes.CREATE_BANK_ACCOUNT_TRANSACTION_RESET]:
        caseFunctions.createBankAccountTransactionReset,

    [actionTypes.CREATE_BANK_ACCOUNT]: caseFunctions.createBankAccount,
    [actionTypes.CREATE_BANK_ACCOUNT_SUCCESS]: caseFunctions.createBankAccountSuccess,
    [actionTypes.CREATE_BANK_ACCOUNT_FAILURE]: caseFunctions.createBankAccountFailure,
    [actionTypes.CREATE_BANK_ACCOUNT_RESET]: caseFunctions.createBankAccountReset,

    [actionTypes.UPDATE_BANK_ACCOUNT]: caseFunctions.updateBankAccount,
    [actionTypes.UPDATE_BANK_ACCOUNT_SUCCESS]: caseFunctions.updateBankAccountSuccess,
    [actionTypes.UPDATE_BANK_ACCOUNT_FAILURE]: caseFunctions.updateBankAccountFailure,
    [actionTypes.UPDATE_BANK_ACCOUNT_RESET]: caseFunctions.updateBankAccountReset,

    [actionTypes.DEACTIVATE_BANK_ACCOUNT]: caseFunctions.deactivateBankAccount,
    [actionTypes.DEACTIVATE_BANK_ACCOUNT_SUCCESS]: caseFunctions.deactivateBankAccountSuccess,
    [actionTypes.DEACTIVATE_BANK_ACCOUNT_FAILURE]: caseFunctions.deactivateBankAccountFailure,
    [actionTypes.DEACTIVATE_BANK_ACCOUNT_RESET]: caseFunctions.deactivateBankAccountReset,

    [actionTypes.GET_BANK_ACCOUNT_TYPES]: caseFunctions.getBankAccountTypes,
    [actionTypes.GET_BANK_ACCOUNT_TYPES_SUCCESS]: caseFunctions.getBankAccountTypesSuccess,
    [actionTypes.GET_BANK_ACCOUNT_TYPES_FAILURE]: caseFunctions.getBankAccountTypesFailure,
    [actionTypes.GET_BANK_ACCOUNT_TYPES_RESET]: caseFunctions.getBankAccountTypesReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
