import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { paymentService } from '../../service';
import { IPayment } from '../../common/types/entities';
import { GET_ALL_ITEMS } from '../../common/constants';

export function getPayment(id: number): IAPIMiddleware {
    return {
        callAPI: () => paymentService.getPayment(id),
        types: [
            actionTypes.GET_PAYMENT,
            actionTypes.GET_PAYMENT_SUCCESS,
            actionTypes.GET_PAYMENT_FAILURE,
        ],
    };
}

export function getPaymentsByRequestOrder(id: number): IAPIMiddleware {
    return {
        callAPI: () => paymentService.getPaymentsByRequestOrder(id),
        types: [
            actionTypes.GET_PAYMENTS_BY_REQUEST_ORDER,
            actionTypes.GET_PAYMENTS_BY_REQUEST_ORDER_SUCCESS,
            actionTypes.GET_PAYMENTS_BY_REQUEST_ORDER_FAILURE,
        ],
    };
}

export function resetPaymentsByRequestOrder() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PAYMENTS_BY_REQUEST_ORDER_RESET,
        });
    };
}

export function getPaymentsByProvider(
    providerId: number,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => paymentService.getPaymentsByProvider(providerId, filter, page),
        types: [
            actionTypes.GET_PAYMENTS_BY_PROVIDER,
            actionTypes.GET_PAYMENTS_BY_PROVIDER_SUCCESS,
            actionTypes.GET_PAYMENTS_BY_PROVIDER_FAILURE,
        ],
    };
}

export function resetPaymentsByProvider() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PAYMENTS_BY_PROVIDER_RESET,
        });
    };
}

export function completePayment(payment: IPayment): IAPIMiddleware {
    return {
        callAPI: () => paymentService.completePayment(payment),
        types: [
            actionTypes.COMPLETE_PAYMENT,
            actionTypes.COMPLETE_PAYMENT_SUCCESS,
            actionTypes.COMPLETE_PAYMENT_FAILURE,
        ],
    };
}

export function completePaymentProvider(payment: IPayment): IAPIMiddleware {
    return {
        callAPI: () => paymentService.completePaymentProvider(payment),
        types: [
            actionTypes.COMPLETE_PAYMENT_PROVIDER,
            actionTypes.COMPLETE_PAYMENT_PROVIDER_SUCCESS,
            actionTypes.COMPLETE_PAYMENT_PROVIDER_FAILURE,
        ],
    };
}

export function deactivatePayment(id: number, payment: IPayment): IAPIMiddleware {
    return {
        callAPI: () => paymentService.deactivatePayment(id, payment),
        types: [
            actionTypes.DEACTIVATE_PAYMENT,
            actionTypes.DEACTIVATE_PAYMENT_SUCCESS,
            actionTypes.DEACTIVATE_PAYMENT_FAILURE,
        ],
    };
}

export function resetDeactivatePayment() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DEACTIVATE_PAYMENT_RESET,
        });
    };
}
