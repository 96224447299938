import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';
import { IPaymentItem } from '../common/types/entities';

const { PAYMENT_ITEM_URL } = apiUrls;

export function getAll(filter: string, page: number) {
    const params = { filter, page };
    return APIManager.get(PAYMENT_ITEM_URL, { params });
}

export function get(id: number) {
    const url = `${PAYMENT_ITEM_URL}/${id}`;
    return APIManager.get(url);
}

export function create(data: IPaymentItem) {
    return APIManager.post(PAYMENT_ITEM_URL, { data });
}

export function update(id: number, data: IPaymentItem) {
    const url = `${PAYMENT_ITEM_URL}/${id}`;
    return APIManager.put(url, { data });
}

export function deactivate(id: number) {
    const url = `${PAYMENT_ITEM_URL}/${id}/deactivate`;
    return APIManager.put(url);
}

export function getAllTypes(filter: string, page: number) {
    const url = `${PAYMENT_ITEM_URL}/types`;
    const params = { filter, page };

    return APIManager.get(url, { params });
}

export function createType(data: any) {
    const url = `${PAYMENT_ITEM_URL}/types`;
    return APIManager.post(url, { data });
}

export function updateType(id: number, data: any) {
    const url = `${PAYMENT_ITEM_URL}/types/${id}`;
    return APIManager.put(url, { data });
}

export function deactivateType(id: number) {
    const url = `${PAYMENT_ITEM_URL}/types/${id}/deactivate`;
    return APIManager.put(url);
}
