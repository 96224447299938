import React from 'react';
import { IProps, IState } from './ConfigureList.types';
import { SectionContainer, DataTable } from '../../../components';
import { Row, Col, Input, message, Typography } from 'antd';
import { columns } from './misc/columns';
import { IReceiptNumeration } from '../../../common/types/entities';
import { ReceiptNumerationEditModal } from './components';
import { IReceiptNumerationEditForm } from './components/ReceiptNumerationEditModal';

class ConfigureList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        modalEditOpen: false,
        receiptNumerationToEdit: null,
    };

    componentDidMount() {
        this.performGetConfigures();
    }

    componentWillUnmount() {
        this.props.resetConfigures();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkUpdateConfigure(prevProps);
    }

    async checkUpdateConfigure(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.updateSuccess && props.updateSuccess) {
            this.setState({ modalEditOpen: false });
            message.success('Correlativo actualizado');
            this.performGetConfigures();
        } else if (!prevProps.updateError && props.updateError) message.error(props.updateError);
    }

    performGetConfigures() {
        const { filter, page } = this.state;
        this.props.getConfigures(filter, page);
    }

    async filterConfigures() {
        await this.setState({ page: 1 });
        this.performGetConfigures();
    }

    handleSubmit = (values: IReceiptNumerationEditForm) => {
        const { receiptNumerationToEdit } = this.state;
        const { updateConfigures } = this.props;

        if (receiptNumerationToEdit) {
            updateConfigures(receiptNumerationToEdit.id, values);
        }
    };

    onClickEdit = (item: IReceiptNumeration) => {
        this.setState({ modalEditOpen: true, receiptNumerationToEdit: item });
    };

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetConfigures();
    };

    render() {
        const { configures, loading, total, pageSize, updateLoading } = this.props;

        return (
            <SectionContainer title="Configuración" icon="setting">
                <Typography.Title level={4}>Series de comprobantes de pago</Typography.Title>
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={e => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterConfigures()}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            data={configures}
                            columns={columns(this.onClickEdit)}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
                <ReceiptNumerationEditModal
                    isOpen={this.state.modalEditOpen}
                    onSubmit={this.handleSubmit}
                    onCancel={() => this.setState({ modalEditOpen: false })}
                    initialValues={this.state.receiptNumerationToEdit}
                    submitting={updateLoading}
                />
            </SectionContainer>
        );
    }
}

export default ConfigureList;
