import * as React from 'react';
import { FormikActions, FormikProps } from 'formik';
import {
    CustomForm,
    InputGroupField,
    SelectGroupField,
    DisplayItem,
} from '../../../../../components';
import {
    IBankAccount,
    ICurrency,
    IBankAccountType,
    IBank,
} from '../../../../../common/types/entities';
import { messages } from '../../../../../common/constants';
import { isEmpty, isNumeric } from 'validator';

interface IBankAccountForm {
    number: string;
    interbankNumber: string;
    balance: string;
    bank: { id: number | string };
    currency: { id: number | string };
    type: { id: number | string };
}

interface IProps {
    initialValues: IBankAccount;
    currencies: ICurrency[];
    currenciesLoading: boolean;
    bankAccountTypes: IBankAccountType[];
    bankAccountTypesLoading: boolean;
    bankAccountId: number;
    banks: IBank[];
    banksLoading: boolean;
}

export default class BankAccountForm extends CustomForm<IBankAccountForm, IProps> {
    initialValues = (props: IProps): IBankAccountForm => {
        const { initialValues } = props;
        return {
            number: initialValues.number || '',
            interbankNumber: initialValues.interbankNumber || '',
            balance: initialValues.balance != null ? String(initialValues.balance) : '',
            bank: { id: initialValues.bank ? initialValues.bank.id : null },
            currency: { id: initialValues.currency ? initialValues.currency.id : null },
            type: { id: initialValues.type ? initialValues.type.id : null },
        };
    };

    validate = (values: IBankAccountForm) => {
        const errors = {} as Partial<IBankAccountForm>;

        if (isEmpty(values.number)) errors.number = messages.MANDATORY_FIELD;
        if (isEmpty(values.interbankNumber)) errors.interbankNumber = messages.MANDATORY_FIELD;
        if (!this.props.bankAccountId) {
            if (isEmpty(values.balance)) errors.balance = messages.MANDATORY_FIELD;
            else if (!isNumeric(values.balance)) errors.balance = 'El saldo debe ser un número';
            else if (Number(values.balance) < 0) errors.balance = 'El saldo debe ser positivo';
        }
        if (!values.bank.id || values.bank.id == 0) errors.bank = { id: messages.MANDATORY_FIELD };
        if (!values.currency.id || values.currency.id == 0)
            errors.currency = { id: messages.MANDATORY_FIELD };
        if (!values.type.id || values.type.id == 0) errors.type = { id: messages.MANDATORY_FIELD };

        return errors;
    };

    onSubmit = (values: IBankAccountForm, actions: FormikActions<IBankAccountForm>) => {
        this.props.onSubmit(values);
        actions.setSubmitting(false);
    };

    formBody = (formikBag: FormikProps<IBankAccountForm>) => {
        const {
            bankAccountTypes,
            currencies,
            banks,
            banksLoading,
            bankAccountTypesLoading,
            currenciesLoading,
            bankAccountId,
            initialValues,
        } = this.props;

        return (
            <div>
                <InputGroupField name="number" label="N° de cuenta" required />
                <InputGroupField name="interbankNumber" label="N° interbancario" required />
                {!bankAccountId && (
                    <InputGroupField
                        name="balance"
                        label="Saldo"
                        normalize="numbersWithDecimals"
                        required
                    />
                )}
                <SelectGroupField
                    name="bank.id"
                    label="Banco"
                    data={banks}
                    displayText="name"
                    required
                    loading={banksLoading}
                />
                <SelectGroupField
                    name="type.id"
                    label="Tipo"
                    data={bankAccountTypes}
                    displayText="description"
                    required
                    loading={bankAccountTypesLoading}
                />
                {initialValues.hasTransactions ? (
                    <DisplayItem
                        lmd={4}
                        llg={3}
                        lxl={2}
                        lxxl={2}
                        label="Moneda"
                        value={initialValues.currency.code}
                    />
                ) : (
                    <SelectGroupField
                        name="currency.id"
                        label="Moneda"
                        data={currencies}
                        displayText="code"
                        required
                        loading={currenciesLoading}
                    />
                )}
            </div>
        );
    };
}
