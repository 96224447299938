import * as React from 'react';
import { IProps, IState } from './BankAccountTransactionList.types';
import { SectionContainer, DisplayItem, DataTable, PBR } from '../../../../components';
import { paths } from '../../../../routes';
import { columns } from './misc/columns';
import { helper } from '../../../../common/helpers';
import { Row, Col, Button, message } from 'antd';
import { IBankAccountTransaction } from '../../../../common/types/entities';
import { bankAccountTransactionTypes, permissions } from '../../../../common/constants';
import { CreateBankAccountTransactionModal } from './components';
import { ICreateBankAccountTransactionForm } from './components/CreateBankAccountTransactionModal';

export default class BankAccountTransactionList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        bankAccountId: this.props.match.params['id'],
        modalAddEditOpen: false,
        transactionTypeId: null,
    };

    componentDidMount() {
        this.props.getBankAccount(this.props.match.params['id']);
        this.performGetBankAccountTransactions();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkReadjustBankAccount(prevProps);
    }

    componentWillUnmount() {
        this.props.resetBankAccount();
        this.props.resetBankAccountTransactions();
        this.props.createReadjustBankAccountTransaction();
    }

    performGetBankAccountTransactions() {
        const { bankAccountId, filter, page } = this.state;
        this.props.getBankAccountTransactions(bankAccountId, filter, page);
    }

    async checkReadjustBankAccount(prevProps: IProps) {
        const { props } = this;

        if (
            !prevProps.createBankAccountTransactionSuccess &&
            props.createBankAccountTransactionSuccess
        ) {
            this.setState({ modalAddEditOpen: false });
            message.success('Movimiento realizado');
            this.props.getBankAccount(this.props.match.params['id']);
            this.performGetBankAccountTransactions();
        } else if (
            !prevProps.createBankAccountTransactionError &&
            props.createBankAccountTransactionError
        )
            message.error(props.createBankAccountTransactionError);
    }

    async filterBankAccountTransactions() {
        await this.setState({ page: 1 });
        this.performGetBankAccountTransactions();
    }

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetBankAccountTransactions();
    };

    handleSubmit = (values: ICreateBankAccountTransactionForm) => {
        const { createBankAccountTransaction } = this.props;

        const data = {
            amount: Number(values.amount),
            typeId: this.state.transactionTypeId,
        };

        createBankAccountTransaction(this.state.bankAccountId, data);
    };

    onStateChange = async (bankAccountId: number) => {
        await this.setState({ page: 1, bankAccountId });
        this.performGetBankAccountTransactions();
    };

    onClickDetail = (item: IBankAccountTransaction) => {
        if (
            item.type.id == bankAccountTransactionTypes.PAYMENT ||
            item.type.id == bankAccountTransactionTypes.VOID_PAYMENT
        ) {
            this.props.history.push(
                helper.setPathParams(paths.ADMIN_PAYMENT_DETAIL, { id: item.payment.id }),
            );
        } else if (item.type.id == bankAccountTransactionTypes.INCOME) {
            this.props.history.push(
                helper.setPathParams(paths.ADMIN_INCOME_DETAIL, { id: item.income.id }),
            );
        }
    };

    renderContent = () => {
        const {
            bankAccount,
            bankAccountTransaction,
            bankAccountTransactionLoading,
            createBankAccountTransactionLoading: readjustBankAccountTransactionLoading,
            pageSize,
            total,
        } = this.props;

        return (
            <>
                <DisplayItem label="N° de cuenta" value={bankAccount.number} />
                <DisplayItem label="N° interbancario" value={bankAccount.interbankNumber} />
                <DisplayItem label="Saldo" type="money" value={bankAccount.balance} />
                <DisplayItem label="Banco" value={bankAccount.bank.name} />
                <DisplayItem label="Tipo" value={bankAccount.type.description} />
                <DisplayItem label="Moneda" value={bankAccount.currency.code} />
                <PBR permission={permissions.bankAccount.WRITE_READJUSTMENT}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="vertical-align-bottom"
                                type="primary"
                                className="mr-2"
                                onClick={() =>
                                    this.setState({
                                        modalAddEditOpen: true,
                                        transactionTypeId: bankAccountTransactionTypes.WITHDRAW,
                                    })
                                }
                            >
                                Retirar
                            </Button>
                            <Button
                                icon="vertical-align-top"
                                type="primary"
                                className="mr-2"
                                onClick={() =>
                                    this.setState({
                                        modalAddEditOpen: true,
                                        transactionTypeId: bankAccountTransactionTypes.DEPOSIT,
                                    })
                                }
                            >
                                Depositar
                            </Button>
                            <Button
                                icon="vertical-align-middle"
                                type="primary"
                                onClick={() =>
                                    this.setState({
                                        modalAddEditOpen: true,
                                        transactionTypeId: bankAccountTransactionTypes.READJUSTMENT,
                                    })
                                }
                            >
                                Reajustar
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={bankAccountTransaction}
                            columns={columns(this.onClickDetail)}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            loading={bankAccountTransactionLoading}
                            onPageChange={this.onPageChange}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.bankAccount.WRITE_READJUSTMENT}>
                    <CreateBankAccountTransactionModal
                        isOpen={this.state.modalAddEditOpen}
                        onSubmit={this.handleSubmit}
                        onCancel={() =>
                            this.setState({ modalAddEditOpen: false, transactionTypeId: null })
                        }
                        submitting={readjustBankAccountTransactionLoading}
                        typeId={this.state.transactionTypeId}
                    />
                </PBR>
            </>
        );
    };

    render() {
        const { bankAccount, loading, error } = this.props;

        return (
            <SectionContainer
                title="Movimientos de la Cuenta Bancaria"
                onBack={() => this.props.history.push(paths.ADMIN_BANK_ACCOUNT_LIST)}
                loading={loading || !bankAccount}
                error={error}
                content={this.renderContent}
            />
        );
    }
}
