const actionTypes = {
    LOGIN_USER: 'auth/LOGIN_USER',
    LOGIN_USER_FAILURE: 'auth/LOGIN_USER_FAILURE',
    LOGIN_USER_SUCCESS: 'auth/LOGIN_USER_SUCCESS',

    VERIFY_LOGGED_IN_USER: 'auth/VERIFY_LOGGED_IN_USER',
    VERIFY_LOGGED_IN_USER_SUCCESS: 'auth/VERIFY_LOGGED_IN_USER_SUCCESS',
    VERIFY_LOGGED_IN_USER_FAILURE: 'auth/VERIFY_LOGGED_IN_USER_FAILURE',

    LOGOUT_USER: 'auth/LOGOUT_USER',

    FORGOT_PASSWORD: 'auth/FORGOT_PASSWORD',
    FORGOT_PASSWORD_FAILURE: 'auth/FORGOT_PASSWORD_FAILURE',
    FORGOT_PASSWORD_SUCCESS: 'auth/FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_RESET: 'auth/FORGOT_PASSWORD_RESET',

    CHANGE_PASSWORD: 'auth/CHANGE_PASSWORD',
    CHANGE_PASSWORD_FAILURE: 'auth/CHANGE_PASSWORD_FAILURE',
    CHANGE_PASSWORD_SUCCESS: 'auth/CHANGE_PASSWORD_SUCCESS',

    RESET_PASSWORD: 'auth/RESET_PASSWORD',
    RESET_PASSWORD_FAILURE: 'auth/RESET_PASSWORD_FAILURE',
    RESET_PASSWORD_SUCCESS: 'auth/RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_RESET: 'auth/RESET_PASSWORD_RESET',
};

export default actionTypes;
