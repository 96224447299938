import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableRowOptionsDropdown } from '../../../../../../components';
import { IPaymentItemType } from '../../../../../../common/types/entities';
import { paymentItemTypes, permissions } from '../../../../../../common/constants';

export const columns = (
    onClickEdit: (zone: IPaymentItemType) => void,
    onClickDelete: (id: number) => void,
    permissionCodes: string[],
): ColumnProps<IPaymentItemType>[] => [
    {
        title: 'Descripción',
        dataIndex: 'description',
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IPaymentItemType) => {
            if (item.id == paymentItemTypes.GENERAL || item.id == paymentItemTypes.PENALTY_FEE)
                return <div />;

            if (permissionCodes.includes(permissions.paymentItemType.WRITE_PAYMENT_ITEM_TYPES)) {
                return (
                    <TableRowOptionsDropdown
                        onClickEdit={() => onClickEdit(item)}
                        onClickDelete={() => onClickDelete(item.id)}
                    />
                );
            }
        },
    },
];
