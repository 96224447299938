import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Button } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { TableRowOptionItem } from '../..';
import { IRootState } from '../../../common/types';

interface IOwnProps {
    onClickDetail?: (param: ClickParam) => void;
    onClickEdit?: (param: ClickParam) => void;
    onClickDelete?: (param: ClickParam) => void;
    onClickCancel?: (param: ClickParam) => void;
    detailPermission?: string;
    editPermission?: string;
    deletePermission?: string;
    cancelPermission?: string;
    disabled?: boolean;
    children?: React.ReactNode;
}

interface IStoreState {
    permissions?: string[];
}

type IProps = IOwnProps & IStoreState;

const TableRowOptionsDropdown: FC<IProps> = ({
    onClickDetail,
    onClickEdit,
    onClickDelete,
    onClickCancel,
    permissions,
    deletePermission,
    editPermission,
    detailPermission,
    cancelPermission,
    disabled,
    children,
}) => {
    const hasPermission = (permission: string): boolean => {
        return permissions.includes(permission);
    };

    const renderDetailOption = () => {
        if (
            (onClickDetail && !detailPermission) ||
            (onClickDetail && detailPermission && hasPermission(detailPermission))
        ) {
            return <TableRowOptionItem label="Detalle" icon="eye" onClick={onClickDetail} />;
        }
    };

    const renderEditOption = () => {
        if (
            (onClickEdit && !editPermission) ||
            (onClickEdit && editPermission && hasPermission(editPermission))
        ) {
            return <TableRowOptionItem label="Editar" icon="edit" onClick={onClickEdit} />;
        }
    };

    const renderDeleteOption = () => {
        if (
            (onClickDelete && !deletePermission) ||
            (onClickDelete && deletePermission && hasPermission(deletePermission))
        ) {
            return <TableRowOptionItem label="Eliminar" icon="delete" onClick={onClickDelete} />;
        }
    };

    const renderCancelOption = () => {
        if (
            (onClickCancel && !cancelPermission) ||
            (onClickCancel && cancelPermission && hasPermission(cancelPermission))
        ) {
            return <TableRowOptionItem label="Anular" icon="line" onClick={onClickCancel} />;
        }
    };

    return (
        <Dropdown
            trigger={['click']}
            disabled={disabled}
            overlay={() => (
                <Menu>
                    {renderDetailOption()}
                    {renderEditOption()}
                    {children}
                    {renderDeleteOption()}
                    {renderCancelOption()}
                </Menu>
            )}
        >
            <Button type="primary" shape="round" icon="menu" />
        </Dropdown>
    );
};

function mapStateToProps(state: IRootState): IStoreState {
    return {
        permissions: state.auth.loggedInUser.value.permissions,
    };
}

export default connect(mapStateToProps)(TableRowOptionsDropdown);
