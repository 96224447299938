const actionTypes = {
    GET_INCOME_DETAILED_PENDING_REPORT_EXCEL: 'report/GET_INCOME_DETAILED_PENDING_REPORT_EXCEL',
    GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_SUCCESS:
        'report/GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_SUCCESS',
    GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_FAILURE:
        'report/GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_FAILURE',
    GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_RESET:
        'report/GET_INCOME_DETAILED_PENDING_REPORT_EXCEL_RESET',

    GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL:
        'report/GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL',
    GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_SUCCESS:
        'report/GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_SUCCESS',
    GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_FAILURE:
        'report/GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_FAILURE',
    GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_RESET:
        'report/GET_INCOME_DETAILED_PENDING_PAYMENT_REPORT_EXCEL_RESET',

    GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL: 'report/GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL',
    GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_SUCCESS:
        'report/GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_SUCCESS',
    GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_FAILURE:
        'report/GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_FAILURE',
    GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_RESET:
        'report/GET_INCOME_DETAILED_COMPLETED_REPORT_EXCEL_RESET',

    GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL: 'report/GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL',
    GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_SUCCESS:
        'report/GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_SUCCESS',
    GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_FAILURE:
        'report/GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_FAILURE',
    GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_RESET:
        'report/GET_INCOME_DETAILED_ANULLED_REPORT_EXCEL_RESET',
};

export default actionTypes;
