import { IListStatePaginated, IObjectState, IProcessState } from '../../common/types';
import { ISubzone, IProperty } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    subzoneList: IListStatePaginated<ISubzone>;
    activeSubzone: IObjectState<ISubzone>;
    createSubzone: IProcessState;
    updateSubzone: IProcessState;
    deactivateSubzone: IProcessState;
    propertyList: IListStatePaginated<IProperty>;
}

export const initialState: IState = {
    subzoneList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activeSubzone: { value: undefined, loading: false, error: undefined },
    createSubzone: { success: false, loading: false, error: undefined },
    updateSubzone: { success: false, loading: false, error: undefined },
    deactivateSubzone: { success: false, loading: false, error: undefined },
    propertyList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_SUBZONES]: caseFunctions.getSubzones,
    [actionTypes.GET_SUBZONES_SUCCESS]: caseFunctions.getSubzonesSuccess,
    [actionTypes.GET_SUBZONES_FAILURE]: caseFunctions.getSubzonesFailure,
    [actionTypes.GET_SUBZONES_RESET]: caseFunctions.getSubzonesReset,

    [actionTypes.GET_SUBZONE]: caseFunctions.getSubzone,
    [actionTypes.GET_SUBZONE_SUCCESS]: caseFunctions.getSubzoneSuccess,
    [actionTypes.GET_SUBZONE_FAILURE]: caseFunctions.getSubzoneFailure,
    [actionTypes.GET_SUBZONE_RESET]: caseFunctions.getSubzoneReset,

    [actionTypes.CREATE_SUBZONE]: caseFunctions.createSubzone,
    [actionTypes.CREATE_SUBZONE_SUCCESS]: caseFunctions.createSubzoneSuccess,
    [actionTypes.CREATE_SUBZONE_FAILURE]: caseFunctions.createSubzoneFailure,
    [actionTypes.CREATE_SUBZONE_RESET]: caseFunctions.createSubzoneReset,

    [actionTypes.UPDATE_SUBZONE]: caseFunctions.updateSubzone,
    [actionTypes.UPDATE_SUBZONE_SUCCESS]: caseFunctions.updateSubzoneSuccess,
    [actionTypes.UPDATE_SUBZONE_FAILURE]: caseFunctions.updateSubzoneFailure,
    [actionTypes.UPDATE_SUBZONE_RESET]: caseFunctions.updateSubzoneReset,

    [actionTypes.DEACTIVATE_SUBZONE]: caseFunctions.deactivateSubzone,
    [actionTypes.DEACTIVATE_SUBZONE_SUCCESS]: caseFunctions.deactivateSubzoneSuccess,
    [actionTypes.DEACTIVATE_SUBZONE_FAILURE]: caseFunctions.deactivateSubzoneFailure,
    [actionTypes.DEACTIVATE_SUBZONE_RESET]: caseFunctions.deactivateSubzoneReset,

    [actionTypes.GET_SUBZONE_PROPERTIES]: caseFunctions.getSubzoneProperties,
    [actionTypes.GET_SUBZONE_PROPERTIES_SUCCESS]: caseFunctions.getSubzonePropertiesSuccess,
    [actionTypes.GET_SUBZONE_PROPERTIES_FAILURE]: caseFunctions.getSubzonePropertiesFailure,
    [actionTypes.GET_SUBZONE_PROPERTIES_RESET]: caseFunctions.getSubzonePropertiesReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
