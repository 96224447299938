import React from 'react';
import { SectionContainer } from '../../../../components';
import { IProps } from './BankAccountAddEdit.types';
import { message } from 'antd';
import { paths } from '../../../../routes';
import { BankAccountForm } from './components';

export default class BankAccountAddEdit extends React.Component<IProps> {
    private bankAccountId = this.props.match.params['id'];

    componentDidMount() {
        this.props.getBanks();
        this.props.getBankAccountTypes();
        this.props.getCurrencies();
        if (this.bankAccountId) this.props.getBankAccount(this.bankAccountId);
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkUpdateBankAccount(prevProps);
        this.checkCreateBankAccount(prevProps);
    }

    componentWillUnmount() {
        this.props.resetBankAccountTypes();
        this.props.resetCurrencies();
        this.props.resetBanks();
        this.props.resetBankAccount();
    }

    checkCreateBankAccount(prevProps: IProps) {
        if (!prevProps.createSuccess && this.props.createSuccess) {
            message.success('Cuenta bancaria creada');
            this.goToBankAccountList();
        } else if (!prevProps.createError && this.props.createError)
            message.error(this.props.createError);
    }

    checkUpdateBankAccount(prevProps: IProps) {
        if (!prevProps.updateSuccess && this.props.updateSuccess) {
            message.success('Cuenta bancaria actualizada');
            this.goToBankAccountList();
        } else if (!prevProps.updateError && this.props.updateError)
            message.error(this.props.updateError);
    }

    goToBankAccountList = () => {
        this.props.history.push(paths.ADMIN_BANK_ACCOUNT_LIST);
    };

    onSubmit = (values: any) => {
        if (this.bankAccountId) return this.props.updateBankAccount(this.bankAccountId, values);
        return this.props.createBankAccount(values);
    };

    renderForm = () => {
        const {
            activeBankAccount,
            activeBankAccountLoading,
            createLoading,
            updateLoading,
            banks,
            banksLoading,
            currencies,
            currenciesLoading,
            bankAccountTypes,
            bankAccountTypesLoading,
        } = this.props;

        return (
            <BankAccountForm
                initialValues={activeBankAccount || {}}
                bankAccountId={this.bankAccountId}
                banks={banks}
                banksLoading={banksLoading}
                currencies={currencies}
                currenciesLoading={currenciesLoading}
                bankAccountTypes={bankAccountTypes}
                bankAccountTypesLoading={bankAccountTypesLoading}
                onSubmit={this.onSubmit}
                disabled={activeBankAccountLoading}
                submitting={createLoading || updateLoading}
            />
        );
    };

    render() {
        const { activeBankAccountLoading, activeBankAccountError } = this.props;

        return (
            <SectionContainer
                title={this.bankAccountId ? 'Editar Cuenta Bancaria' : 'Nueva Cuenta Bancaria'}
                onBack={this.goToBankAccountList}
                loading={activeBankAccountLoading}
                error={activeBankAccountError}
                content={this.renderForm}
            />
        );
    }
}
