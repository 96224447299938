import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableRowOptionsDropdown, PBR } from '../../../../../components';
import { IUser, IZone } from '../../../../../common/types/entities';
import { permissions } from '../../../../../common/constants';

export const columns = (
    onClickEdit: (zone: IZone) => void,
    onClickDelete: (id: number) => void,
): ColumnProps<IUser>[] => [
    {
        title: 'Nombre',
        dataIndex: 'name',
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IZone) => (
            <PBR permission={permissions.bank.WRITE_BANKS}>
                <TableRowOptionsDropdown
                    onClickEdit={() => onClickEdit(item)}
                    onClickDelete={() => onClickDelete(item.id)}
                />
            </PBR>
        ),
    },
];
