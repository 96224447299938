import * as React from 'react';
import { FormikActions } from 'formik';
import {
    CustomForm,
    InputGroupField,
    SelectGroupField,
    DatePickerGroupField,
} from '../../../../../../components';
import { messages } from '../../../../../../common/constants';
import {
    IProvider,
    IRequestOrderDetail,
    IExpense,
    ICurrency,
} from '../../../../../../common/types/entities';
import { formatHelper } from '../../../../../../common/helpers';

interface IOwnProps {
    stateId: number;
    providers: IProvider[];
    providersLoading: boolean;
    currencies: ICurrency[];
    currenciesLoading: boolean;
    setCurrencySelected: (currencyId: number) => void;
}

type IProps = IOwnProps;

export interface IRequestOrderFormValues {
    startDate: Date | string;
    endDate: Date | string;
    comment: string;
    provider: { id: number | string };
    currency: { id: number | string };
    expense?: IExpense;
    requestOrderDetails?: IRequestOrderDetail[];
}

export default class RequestOrderForm extends CustomForm<IRequestOrderFormValues, IProps> {
    initialValues = (): IRequestOrderFormValues => {
        return {
            startDate: undefined,
            endDate: undefined,
            comment: '',
            provider: { id: undefined },
            currency: { id: undefined },
        };
    };

    onSubmit = (
        values: IRequestOrderFormValues,
        actions: FormikActions<IRequestOrderFormValues>,
    ) => {
        this.props.onSubmit(values);
        actions.setSubmitting(false);
    };

    validate = (values: IRequestOrderFormValues) => {
        const errors: Partial<IRequestOrderFormValues> = {};

        if (!values.provider.id || values.provider.id == 0)
            errors.provider = { id: messages.MANDATORY_FIELD };
        if (!values.currency.id || values.currency.id == 0)
            errors.currency = { id: messages.MANDATORY_FIELD };
        if (!values.startDate) errors.startDate = messages.MANDATORY_FIELD;
        if (!values.endDate) errors.endDate = messages.MANDATORY_FIELD;
        let d1 = new Date(values.startDate);
        let d2 = new Date(values.endDate);
        if (d2.getTime() < d1.getTime())
            errors.endDate = 'La Fecha de entrega no puede ser menor a la fecha de inicio';
        return errors;
    };

    onCancel = () => this.props.onCancel();

    formBody = () => {
        const {
            providers,
            providersLoading,
            currencies,
            currenciesLoading,
            setCurrencySelected,
        } = this.props;

        return (
            <>
                <SelectGroupField
                    name="provider.id"
                    label="Proveedor"
                    data={providers}
                    displayText={(item: IProvider) => formatHelper.getProviderDescription(item)}
                    required
                    loading={providersLoading}
                />
                <SelectGroupField
                    name="currency.id"
                    label="Moneda"
                    data={currencies}
                    displayText="code"
                    onChange={value => setCurrencySelected(Number(value))}
                    required
                    loading={currenciesLoading}
                />
                <InputGroupField name="comment" label="Comentario" inputType="textarea" />
                <DatePickerGroupField name="startDate" label="Fecha de Inicio" required />
                <DatePickerGroupField name="endDate" label="Fecha de Entrega" required />
            </>
        );
    };
}
