import React, { FunctionComponent } from 'react';
import { Tabs, Icon } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { SectionContainer } from '../../../../components';
import { IncomePendingListByProperties, IncomePendingListByShareholders } from './components';

const IncomePendingList: FunctionComponent<RouteComponentProps> = props => {
    return (
        <SectionContainer title="Ingresos Pendientes" icon="clock-circle">
            <Tabs>
                <Tabs.TabPane
                    tab={
                        <span>
                            <Icon type="environment" />
                            Puestos
                        </span>
                    }
                    key="1"
                >
                    <IncomePendingListByProperties {...props} />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        <span>
                            <Icon type="user" />
                            Accionistas
                        </span>
                    }
                    key="2"
                >
                    <IncomePendingListByShareholders {...props} />
                </Tabs.TabPane>
            </Tabs>
        </SectionContainer>
    );
};

export default IncomePendingList;
