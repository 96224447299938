import { IProcessState, IListState } from '../../common/types';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';
import { IRequestOrder, IRequestOrderDetail } from '../../common/types/entities';

export interface IState {
    requestOrderByExpenseList: IListState<IRequestOrder>;
    newRequestOrder: IProcessState;
    accordingRequestOrder: IProcessState;
    requestOrderDetailList: IListState<IRequestOrderDetail>;
}

export const initialState: IState = {
    requestOrderByExpenseList: { data: [], loading: false, error: undefined },
    newRequestOrder: { success: false, loading: false, error: undefined },
    accordingRequestOrder: { success: false, loading: false, error: undefined },
    requestOrderDetailList: { data: [], loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_REQUEST_ORDERS_BY_EXPENSE]: caseFunctions.getRequestOrdersByExpense,
    [actionTypes.GET_REQUEST_ORDERS_BY_EXPENSE_SUCCESS]:
        caseFunctions.getRequestOrdersByExpenseSuccess,
    [actionTypes.GET_REQUEST_ORDERS_BY_EXPENSE_FAILURE]:
        caseFunctions.getRequestOrdersByExpenseFailure,
    [actionTypes.GET_REQUEST_ORDERS_BY_EXPENSE_RESET]: caseFunctions.getRequestOrdersByExpenseReset,

    [actionTypes.CREATE_REQUEST_ORDER]: caseFunctions.createRequestOrder,
    [actionTypes.CREATE_REQUEST_ORDER_SUCCESS]: caseFunctions.createRequestOrderSuccess,
    [actionTypes.CREATE_REQUEST_ORDER_FAILURE]: caseFunctions.createRequestOrderFailure,
    [actionTypes.CREATE_REQUEST_ORDER_RESET]: caseFunctions.createRequestOrderReset,

    [actionTypes.ACCORDING_REQUEST_ORDER]: caseFunctions.accordingRequestOrder,
    [actionTypes.ACCORDING_REQUEST_ORDER_SUCCESS]: caseFunctions.accordingRequestOrderSuccess,
    [actionTypes.ACCORDING_REQUEST_ORDER_FAILURE]: caseFunctions.accordingRequestOrderFailure,

    [actionTypes.GET_REQUEST_ORDER_DETAILS]: caseFunctions.getRequestOrderDetails,
    [actionTypes.GET_REQUEST_ORDER_DETAILS_SUCCESS]: caseFunctions.getRequestOrderDetailsSuccess,
    [actionTypes.GET_REQUEST_ORDER_DETAILS_FAILURE]: caseFunctions.getRequestOrderDetailsFailure,
    [actionTypes.GET_REQUEST_ORDER_DETAILS_RESET]: caseFunctions.getRequestOrderDetailsReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
