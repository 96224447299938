import React from 'react';
import { IProps } from './ExpenseInvoiceAddEdit.types';
import { Formik } from 'formik';
import { IInvoiceFormValues } from './components/invoiceForm/InvoiceForm';
import InvoiceForm from './components/invoiceForm';
import {
    IInvoiceItem,
    IInvoice,
    IPayment,
    IRequestOrder,
    IRequestOrderDetail,
    ICurrency,
} from '../../../../common/types/entities';
import { message, Divider, Button, Select, Row, Col, Upload, Icon } from 'antd';
import { paths } from '../../../../routes';
import { invoiceItemColumns } from './misc/columnsInvoice';
import {
    SectionContainer,
    DisplayItem,
    DataTable,
    GroupFieldWrapper,
} from '../../../../components';
import { formatHelper } from '../../../../common/helpers';
import { expenseTypes } from '../../../../common/constants';
import nanoid from 'nanoid';

interface IState {
    invoiceItemsToEdit: IInvoiceItem[];
    requestOrderSelected: IRequestOrder;
    files: File[];
    bankAccountCurrency: ICurrency;
    exchangeRate: number;
}

export default class ExpenseInvoiceAddEdit extends React.Component<IProps> {
    private expenseId = this.props.match.params['id'];
    private formInvoice: Formik<IInvoiceFormValues> = null;

    state: IState = {
        invoiceItemsToEdit: [],
        requestOrderSelected: null,
        files: [],
        bankAccountCurrency: null,
        exchangeRate: null,
    };

    setExchangeRate = (value: any) => this.setState({ exchangeRate: value });

    resetItems = () => this.setState({ invoiceItemsToEdit: [] });

    setBankAccountCurrency = (value: any) =>
        this.setState({ invoiceItemsToEdit: [], bankAccountCurrency: { id: value } });

    componentDidMount() {
        this.props.getExpense(this.expenseId);
        this.props.getRequestOdersByExpense(this.expenseId);
        this.props.getPaymentTypes();
        this.props.getBankAccounts();
    }

    componentWillUnmount() {
        this.props.resetExpense();
        this.props.resetRequestOrdersByExpense();
        this.props.resetRequestOrderDetails();
        this.props.resetPaymentTypes();
        this.props.resetBankAccounts();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkExpenseInvoice(prevProps);
    }

    addOrUpdateInvoiceItem = (
        requestOrderDetail: IRequestOrderDetail,
        quantity: number,
        totalPrice: number,
    ) => {
        let invoiceItem: IInvoiceItem = {
                name: requestOrderDetail.name,
                unitPrice: requestOrderDetail.unitPrice,
                quantity,
                totalPrice,
                currency: this.state.requestOrderSelected.currency,
                comment: requestOrderDetail.comment,
                requestOrderDetail: {
                    id: requestOrderDetail.id,
                    remainingQuantity: requestOrderDetail.remainingQuantity || 0,
                },
            },
            invoiceItemsToEdit = this.state.invoiceItemsToEdit;

        const index = invoiceItemsToEdit.findIndex(
            e => e.requestOrderDetail.id == requestOrderDetail.id,
        );

        if (index == -1) invoiceItemsToEdit.push(invoiceItem);
        else invoiceItemsToEdit[index] = invoiceItem;

        this.setState({ invoiceItemsToEdit });
    };

    checkExpenseInvoice(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.completeInvoiceSuccess && props.completeInvoiceSuccess) {
            message.success('Factura registrada');
            this.props.history.push(paths.ADMIN_EXPENSE_LIST);
        } else if (!prevProps.completeInvoiceError && props.completeInvoiceError) {
            message.error(props.completeInvoiceError);
            this.setState({ invoiceItemsToEdit: [] });
        }
    }

    onChangeRequestOrder = value => {
        const requestOrderSelected = this.props.requestOrdersByExpense.find(i => i.id == value);
        this.setState({ requestOrderSelected, invoiceItemsToEdit: [] });
        this.props.getRequestOrderDetails(value);
    };

    onSubmitInvoice = (values: IInvoiceFormValues) => {
        if (!this.state.requestOrderSelected) {
            message.warning('Seleccione una orden de compra');
            return;
        }

        let invoiceToSubmit: IInvoice = {
            code: values.code,
            emissionDate: new Date(values.emissionDate),
            paid: values.paid,
            currency: this.state.requestOrderSelected.currency,
            dueDate: new Date(values.dueDate),
            requestOrder: { id: this.state.requestOrderSelected.id },
            invoiceItems: this.state.invoiceItemsToEdit.filter(e => e.quantity != 0),
        };

        if (invoiceToSubmit.invoiceItems.length == 0) {
            message.error('Complete al menos un detalle de factura');
            return;
        }

        const files = this.state.files;
        if (files.length > 0) invoiceToSubmit.files = files;

        let paymentToSubmit: IPayment = values.paid
            ? {
                  paymentDate: new Date(values.paymentDate),
                  bankAccount: { id: Number(values.bankAccount) },
                  paymentType: { id: Number(values.paymentType) },
                  currency: this.state.requestOrderSelected.currency,
                  exchangeRate:
                      this.state.bankAccountCurrency.id !=
                      this.state.requestOrderSelected.currency.id
                          ? Number(this.state.exchangeRate)
                          : null,
              }
            : null;

        this.props.completeInvoice(invoiceToSubmit, paymentToSubmit);
    };

    renderContent = () => {
        const {
                activeExpense,
                paymentTypes,
                paymentTypesLoading,
                requestOrdersByExpense,
                requestOrdersByExpenseLoading,
                requestOrderDetails,
                requestOrderDetailsLoading,
                completeInvoiceLoading,
                bankAccounts,
                bankAccountsLoading,
            } = this.props,
            isGoodsType = activeExpense.type.id == expenseTypes.GOODS;

        return (
            <>
                <DisplayItem
                    label="Código"
                    value={activeExpense.code}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Estado"
                    value={activeExpense.state.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Responsables</Divider>
                <DisplayItem
                    label="Solicitante"
                    value={formatHelper.getUserFullname(activeExpense.requestUser)}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Respuesta"
                    value={formatHelper.getUserFullname(activeExpense.responseUser)}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Información</Divider>
                <DisplayItem
                    label="Tipo"
                    value={activeExpense.type.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Motivo"
                    value={activeExpense.reason}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <InvoiceForm
                    onSubmit={this.onSubmitInvoice}
                    formRef={ref => (this.formInvoice = ref)}
                    paymentTypes={paymentTypes}
                    paymentTypesLoading={paymentTypesLoading}
                    bankAccounts={bankAccounts}
                    bankAccountsLoading={bankAccountsLoading}
                    bankAccountCurrency={this.state.bankAccountCurrency}
                    setBankAccountCurrency={this.setBankAccountCurrency}
                    requestOrderSelected={this.state.requestOrderSelected}
                    setExchangeRate={this.setExchangeRate}
                    resetItems={this.resetItems}
                    hideFooter
                />
                <GroupFieldWrapper label="Órdenes de Compra">
                    <Select
                        style={{ width: '100%' }}
                        onChange={this.onChangeRequestOrder}
                        value={
                            this.state.requestOrderSelected
                                ? this.state.requestOrderSelected.id
                                : null
                        }
                        loading={requestOrdersByExpenseLoading}
                    >
                        {requestOrdersByExpense.map(i => (
                            <Select.Option key={nanoid()} value={i.id}>
                                Orden N - {i.order}
                            </Select.Option>
                        ))}
                    </Select>
                </GroupFieldWrapper>
                {this.state.requestOrderSelected && (
                    <>
                        <DisplayItem
                            label="Proveedor"
                            value={formatHelper.getProviderDescription(
                                this.state.requestOrderSelected.provider,
                            )}
                            lmd={4}
                            llg={3}
                            lxl={2}
                            lxxl={2}
                        />
                        <DisplayItem
                            label="Monto Total de Orden de Compra"
                            vStyles={{ fontWeight: 'bold' }}
                            value={formatHelper.toMoney(
                                this.state.requestOrderSelected.totalPrice,
                                this.state.requestOrderSelected.currency.code,
                            )}
                            lmd={4}
                            llg={3}
                            lxl={2}
                            lxxl={2}
                        />
                        <DisplayItem
                            label="Monto Restante"
                            vStyles={{ fontWeight: 'bold' }}
                            value={formatHelper.toMoney(
                                this.state.requestOrderSelected.remainingPayment,
                                this.state.requestOrderSelected.currency.code,
                            )}
                            lmd={4}
                            llg={3}
                            lxl={2}
                            lxxl={2}
                        />
                    </>
                )}
                <Divider orientation="left" className="mt-5">
                    {isGoodsType ? 'Bienes' : 'Servicios'}
                </Divider>
                <DataTable
                    data={requestOrderDetails}
                    columns={invoiceItemColumns(
                        this.addOrUpdateInvoiceItem,
                        this.state.invoiceItemsToEdit,
                        this.state.bankAccountCurrency,
                        this.state.exchangeRate,
                        this.state.requestOrderSelected
                            ? this.state.requestOrderSelected.currency.id
                            : null,
                    )}
                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                    loading={requestOrderDetailsLoading}
                />
                <Divider orientation="left" className="mt-5">
                    Archivos
                </Divider>
                <Row>
                    <Col lg={12} md={12} xs={24} sm={24}>
                        <Upload
                            accept=".pdf, .csv, .xlsx, .xls, .doc, .docx, .png, .jpg"
                            onChange={info => {
                                this.setState({
                                    files: info.fileList.map(item => item['originFileObj']),
                                });
                            }}
                            // stange behavior.... https://stackoverflow.com/a/56125617
                            beforeUpload={() => false}
                        >
                            <Button>
                                <Icon type="upload" /> Subir Archivos
                            </Button>
                        </Upload>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Button
                            type="primary"
                            icon="audit"
                            onClick={() => this.formInvoice.submitForm()}
                            loading={completeInvoiceLoading}
                        >
                            Registrar Factura
                        </Button>
                    </Col>
                </Row>
            </>
        );
    };

    render() {
        const { activeExpense, activeExpenseLoading, activeExpenseError } = this.props;

        return (
            <SectionContainer
                title={'Ingreso de Factura'}
                onBack={() => this.props.history.push(paths.ADMIN_EXPENSE_LIST)}
                loading={activeExpenseLoading || !activeExpense}
                error={activeExpenseError}
                content={this.renderContent}
            />
        );
    }
}
