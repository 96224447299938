import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { subzoneService } from '../../service';
import { IZone } from '../../common/types/entities';

export function getSubzones(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => subzoneService.getAll(filter, page),
        types: [
            actionTypes.GET_SUBZONES,
            actionTypes.GET_SUBZONES_SUCCESS,
            actionTypes.GET_SUBZONES_FAILURE,
        ],
    };
}

export function resetSubzones() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SUBZONES_RESET,
        });
    };
}

export function getSubzone(id: number): IAPIMiddleware {
    return {
        callAPI: () => subzoneService.get(id),
        types: [
            actionTypes.GET_SUBZONE,
            actionTypes.GET_SUBZONE_SUCCESS,
            actionTypes.GET_SUBZONE_FAILURE,
        ],
    };
}

export function resetSubzone() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SUBZONE_RESET,
        });
    };
}

export function createSubzone(data: IZone): IAPIMiddleware {
    return {
        callAPI: () => subzoneService.create(data),
        types: [
            actionTypes.CREATE_SUBZONE,
            actionTypes.CREATE_SUBZONE_SUCCESS,
            actionTypes.CREATE_SUBZONE_FAILURE,
        ],
    };
}

export function updateSubzone(id: number, data: IZone): IAPIMiddleware {
    return {
        callAPI: () => subzoneService.update(id, data),
        types: [
            actionTypes.UPDATE_SUBZONE,
            actionTypes.UPDATE_SUBZONE_SUCCESS,
            actionTypes.UPDATE_SUBZONE_FAILURE,
        ],
    };
}

export function deactivateSubzone(id: number): IAPIMiddleware {
    return {
        callAPI: () => subzoneService.deactivate(id),
        types: [
            actionTypes.DEACTIVATE_SUBZONE,
            actionTypes.DEACTIVATE_SUBZONE_SUCCESS,
            actionTypes.DEACTIVATE_SUBZONE_FAILURE,
        ],
    };
}

export function getSubzoneProperties(
    subzoneId: number,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => subzoneService.getProperties(subzoneId, filter, page),
        types: [
            actionTypes.GET_SUBZONE_PROPERTIES,
            actionTypes.GET_SUBZONE_PROPERTIES_SUCCESS,
            actionTypes.GET_SUBZONE_PROPERTIES_FAILURE,
        ],
    };
}

export function resetSubzoneProperties() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SUBZONE_PROPERTIES_RESET,
        });
    };
}
