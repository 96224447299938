import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import PaymentCreate from './PaymentCreate';
import { getProviders, resetProviders } from '../../../../store/provider/actions';
import {
    completePaymentProvider,
    resetPaymentsByProvider,
} from '../../../../store/payment/actions';
import {
    getInvoicesByProviderDetails,
    resetInvoicesByProviderDetails,
} from '../../../../store/invoice/actions';
import { IRootState } from '../../../../common/types';
import { IStoreState } from './PaymentCreate.types';
import { getBankAccounts, resetBankAccounts } from '../../../../store/bankAccount/actions';
import { getPaymentTypes, resetPaymentTypes } from '../../../../store/income/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { completePaymentProvider } = state.payment,
        { providerList } = state.provider,
        { invoiceByProviderDetailList } = state.invoice,
        { bankAccountList } = state.bankAccount,
        { paymentTypeList } = state.income;

    return {
        bankAccounts: bankAccountList.data,
        bankAccountsLoading: bankAccountList.loading,
        paymentTypes: paymentTypeList.data,
        paymentTypesLoading: paymentTypeList.loading,
        completePaymentProviderSuccess: completePaymentProvider.success,
        completePaymentProviderLoading: completePaymentProvider.loading,
        completePaymentProviderError: completePaymentProvider.error,
        providers: providerList.data,
        providersLoading: providerList.loading,
        providersError: providerList.error,
        invoicesByProviderDetails: invoiceByProviderDetailList.data,
        invoicesByProviderDetailsLoading: invoiceByProviderDetailList.loading,
        invoicesByProviderDetailsError: invoiceByProviderDetailList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getProviders,
            getBankAccounts,
            getPaymentTypes,
            getInvoicesByProviderDetails,
            completePaymentProvider,
            resetProviders,
            resetPaymentsByProvider,
            resetBankAccounts,
            resetPaymentTypes,
            resetInvoicesByProviderDetails,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCreate);
