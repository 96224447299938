import React from 'react';
import { IProps } from './InvoiceCreate.types';
import { message } from 'antd';
import { paths } from '../../../../routes';
import { SectionContainer } from '../../../../components';
import InvoiceForm, { IInvoiceForm } from './components/InvoiceForm';
import { Formik } from 'formik';
import { IPayment, IInvoice } from '../../../../common/types/entities';

export default class InvoiceCreate extends React.Component<IProps> {
    private form: Formik<IInvoiceForm> = null;

    componentDidMount() {
        this.props.getProviders();
        this.props.getBankAccounts();
        this.props.getPaymentTypes();
        this.props.getCurrencies();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkInvoiceCreation(prevProps);
    }

    componentWillUnmount() {
        this.props.resetProviders();
        this.props.resetBankAccounts();
        this.props.resetPaymentTypes();
        this.props.resetCurrencies();
    }

    checkInvoiceCreation(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.completeInvoiceProviderSuccess && props.completeInvoiceProviderSuccess) {
            message.success('Factura registrada');
            this.props.history.push(paths.ADMIN_INVOICE_LIST);
        } else if (!prevProps.completeInvoiceProviderError && props.completeInvoiceProviderError)
            message.error(props.completeInvoiceProviderError);
    }

    onSubmit = (values: any) => {
        let invoiceToSubmit: IInvoice = {
            code: values.code,
            emissionDate: new Date(values.emissionDate),
            paid: values.paid,
            dueDate: new Date(values.dueDate),
            currency: { id: values.currency.id },
            provider: { id: values.provider.id },
            invoiceItems: values.invoiceItems,
        };

        if (values.files && values.files.length > 0) invoiceToSubmit.files = values.files;

        let paymentToSubmit: IPayment = values.paid
            ? {
                  paymentDate: new Date(values.paymentDate),
                  bankAccount: { id: Number(values.bankAccount) },
                  currency: { id: values.currency.id },
                  provider: { id: values.provider.id },
                  paymentType: { id: Number(values.paymentType) },
                  exchangeRate: values.exchangeRate ? Number(values.exchangeRate) : null,
              }
            : null;

        this.props.completeInvoiceProvider(invoiceToSubmit, paymentToSubmit);
    };

    onCancel = () => {
        this.props.history.push(paths.ADMIN_INVOICE_LIST);
    };

    renderForm = () => {
        const {
            providers,
            providersLoading,
            completeInvoiceProviderLoading,
            bankAccounts,
            bankAccountsLoading,
            paymentTypes,
            paymentTypesLoading,
            currencies,
            currenciesLoading,
        } = this.props;

        return (
            <InvoiceForm
                initialValues={{}}
                onSubmit={this.onSubmit}
                formRef={ref => (this.form = ref)}
                submitting={completeInvoiceProviderLoading}
                providers={providers}
                providersLoading={providersLoading}
                bankAccounts={bankAccounts}
                bankAccountsLoading={bankAccountsLoading}
                paymentTypes={paymentTypes}
                paymentTypesLoading={paymentTypesLoading}
                currencies={currencies}
                currenciesLoading={currenciesLoading}
            />
        );
    };

    render() {
        return (
            <SectionContainer
                title={'Nueva Factura'}
                onBack={() => this.props.history.push(paths.ADMIN_INVOICE_LIST)}
                content={this.renderForm}
            />
        );
    }
}
