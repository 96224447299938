import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';

//#region SET PROVIDERS
export function getProviders(state: IState): IState {
  return {
    ...state,
    providerList: { ...state.providerList, loading: true, error: null },
  };
}

export function getProvidersSuccess(state: IState, action: IAction): IState {
  return {
    ...state,
    providerList: {
      data: action.payload.data,
      total: action.payload.total,
      pageSize: action.payload.pageSize,
      loading: false,
      error: null,
    },
  };
}

export function getProvidersFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    providerList: {
      ...initialState.providerList,
      error: action.payload.error,
    },
  };
}

export function resetGetProviders(state: IState): IState {
  return {
    ...state,
    providerList: initialState.providerList,
  };
}
//#endregion

//#region SET PROVIDERS
export function getProvider(state: IState): IState {
  return {
    ...state,
    activeProvider: { value: undefined, loading: true, error: undefined },
  };
}

export function getProviderSuccess(state: IState, action: IAction): IState {
  return {
    ...state,
    activeProvider: { value: action.payload, loading: false, error: undefined },
  };
}

export function getProviderFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    activeProvider: { value: undefined, loading: false, error: action.payload.error },
  };
}

export function getProviderReset(state: IState): IState {
  return {
    ...state,
    activeProvider: initialState.activeProvider,
  };
}
//#endregion

//#region CREATE PROVIDER
export function createProvider(state: IState): IState {
  return {
    ...state,
    createProvider: { success: false, loading: true, error: undefined },
  };
}

export function createProviderSuccess(state: IState): IState {
  return {
    ...state,
    createProvider: { success: true, loading: false, error: undefined },
  };
}

export function createProviderFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    createProvider: { success: false, loading: false, error: action.payload.error },
  };
}

export function createProviderReset(state: IState): IState {
  return {
    ...state,
    createProvider: initialState.createProvider,
  };
}
//#endregion

//#region UPDATE PROVIDER
export function updateProvider(state: IState): IState {
  return {
    ...state,
    updateProvider: { success: false, loading: true, error: undefined },
  };
}

export function updateProviderSuccess(state: IState): IState {
  return {
    ...state,
    updateProvider: { success: true, loading: false, error: undefined },
  };
}

export function updateProviderFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    updateProvider: { success: false, loading: false, error: action.payload.error },
  };
}

export function updateProviderReset(state: IState): IState {
  return {
    ...state,
    updateProvider: initialState.updateProvider,
  };
}
//#endregion

//#region DEACTIVATE PROVIDER
export function deactivateProvider(state: IState): IState {
  return {
    ...state,
    deactivateProvider: { success: false, loading: true, error: undefined },
  };
}

export function deactivateProviderSuccess(state: IState): IState {
  return {
    ...state,
    deactivateProvider: { success: true, loading: false, error: undefined },
  };
}

export function deactivateProviderFailure(state: IState, action: IAction): IState {
  return {
    ...state,
    deactivateProvider: { success: false, loading: false, error: action.payload.error },
  };
}

export function deactivateProviderReset(state: IState): IState {
  return {
    ...state,
    deactivateProvider: initialState.deactivateProvider,
  };
}
//#endregion
