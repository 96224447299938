import * as React from 'react';
import { FormikActions, FormikProps } from 'formik';
import {
    CustomForm,
    InputGroupField,
    SelectGroupField,
    DisplayItem,
    DataTable,
    SwitchGroupField,
} from '../../../../../../components';
import {
    messages,
    receiptTypes,
    paymentTypes,
    ebilling,
    currencies,
} from '../../../../../../common/constants';
import { isEmpty, isEmail, isNumeric } from 'validator';
import { Divider, Icon, message, Collapse, Tooltip } from 'antd';
import { IState, IProps, IIncomeCompletePaymentForm } from './IncomeCompletePaymentForm.types';
import { columns } from './columns';
import { formatHelper } from '../../../../../../common/helpers';
import { IBankAccount } from '../../../../../../common/types/entities';

export default class IncomeCompletePaymentForm extends CustomForm<
    IIncomeCompletePaymentForm,
    IProps,
    IState
> {
    state: IState = {
        bankAccounts: [],
        isSameCurrency: false,
        bankAccountCurrency: undefined,
    };

    componentDidUpdate(prevProps: IProps) {
        this.checkRucInfo(prevProps);
    }

    checkRucInfo(prevProps: IProps) {
        const { props } = this;
        if (prevProps.rucInfo != props.rucInfo && props.rucInfo != null) {
            this.form.setFieldValue('businessName', props.rucInfo.businessName);
            this.form.setFieldValue('fiscalAddress', props.rucInfo.fullAddress);
        } else if (!prevProps.rucInfoError && props.rucInfoError) message.error(props.rucInfoError);
    }

    initialValues = (props: IProps): IIncomeCompletePaymentForm => {
        return {
            receiptType: { id: receiptTypes.BILL },
            ruc: '',
            businessName: '',
            fiscalAddress: '',
            dni: ebilling.genericCustomer.DNI,
            customerEmail: '',
            customerFullname: ebilling.genericCustomer.FULLNAME,
            paymentType: { id: paymentTypes.CASH },
            // type: { id: undefined },
            bankAccount: { id: undefined },
            amountTransferred: '',
            isPaymentPending: false,
        };
    };

    validate = (values: IIncomeCompletePaymentForm) => {
        const errors = {} as Partial<IIncomeCompletePaymentForm>;

        if (!values.receiptType || !values.receiptType.id)
            errors.receiptType = { id: messages.MANDATORY_FIELD };
        else {
            if (values.receiptType.id == receiptTypes.VOUCHER) {
                if (isEmpty(values.customerFullname))
                    errors.customerFullname = messages.MANDATORY_FIELD;
            } else if (values.receiptType.id == receiptTypes.INVOICE) {
                if (isEmpty(values.ruc)) errors.ruc = messages.MANDATORY_FIELD;
                if (isEmpty(values.businessName)) errors.businessName = messages.MANDATORY_FIELD;
                if (isEmpty(values.fiscalAddress)) errors.fiscalAddress = messages.MANDATORY_FIELD;
            } else if (values.receiptType.id == receiptTypes.BILL) {
                if (isEmpty(values.customerFullname))
                    errors.customerFullname = messages.MANDATORY_FIELD;
                if (isEmpty(values.dni)) errors.dni = messages.MANDATORY_FIELD;
            }
        }

        if (!isEmpty(values.customerEmail) && !isEmail(values.customerEmail))
            errors.customerEmail = 'El valor ingresado no es un correo';

        if (!values.paymentType || !values.paymentType.id)
            errors.paymentType = { id: messages.MANDATORY_FIELD };
        else if (values.paymentType.id == paymentTypes.WIRE_TRANSFER) {
            if (!values.bankAccount || !values.bankAccount.id)
                errors.bankAccount = { id: messages.MANDATORY_FIELD };
            else {
                if (!this.state.isSameCurrency) {
                    if (isEmpty(String(values.amountTransferred)))
                        errors.amountTransferred = messages.MANDATORY_FIELD;
                    else if (!isNumeric(String(values.amountTransferred)))
                        errors.amountTransferred = 'Monto no válido';
                }
            }
        }

        // if (!values.type || !values.type.id) errors.type = { id: messages.MANDATORY_FIELD };

        return errors;
    };

    onSubmit = (
        values: IIncomeCompletePaymentForm,
        actions: FormikActions<IIncomeCompletePaymentForm>,
    ) => {
        if (values.amountTransferred) values.amountTransferred = Number(values.amountTransferred);
        this.props.onSubmit(values);
        actions.setSubmitting(false);
    };

    onBankAccountChange = (id: any) => {
        const bankAccount = this.props.bankAccounts.find((x) => x.id == id);
        if (!bankAccount) {
            this.form.setFieldValue('amountTransferred', '');
            this.setState({ bankAccountCurrency: undefined });
            return;
        }

        const currencyId = bankAccount.currency.id;
        const { completePaymentData } = this.props;

        if (currencyId != completePaymentData.currency.id) {
            this.form.setFieldValue('amountTransferred', '');
            this.setState({ isSameCurrency: false, bankAccountCurrency: bankAccount.currency });
        } else {
            this.form.setFieldValue('amountTransferred', completePaymentData.totalPrice);
            this.setState({ isSameCurrency: true, bankAccountCurrency: bankAccount.currency });
        }
    };

    getRucInfo = () => {
        const { rucInfoLoading, getRucInfo } = this.props;
        if (rucInfoLoading || !this.form.state.values.ruc) return;
        getRucInfo(this.form.state.values.ruc);
    };

    setGenericCustomer = () => {
        const { DNI, FULLNAME } = ebilling.genericCustomer;
        this.form.setFieldValue('dni', DNI);
        this.form.setFieldValue('customerFullname', FULLNAME);
    };

    isSelectedBankAccountInDollars = (id: number): boolean => {
        if (!id) return false;
        const bankAccount = this.props.bankAccounts.find((x) => x.id == id);
        return bankAccount.currency.id == currencies.USD;
    };

    formBody = ({ values }: FormikProps<IIncomeCompletePaymentForm>) => {
        const {
            completePaymentData,
            // incomeTypes,
            // incomeTypesLoading,
            paymentTypes: paymentTypesList,
            paymentTypesLoading,
            // currencies,
            // currenciesLoading,
            receiptTypes: receiptTypesItems,
            receiptTypesLoading,
            bankAccountsLoading,
            rucInfoLoading,
        } = this.props;

        if (!completePaymentData) return <div />;

        const {
            property,
            shareholder,
            totalPrice,
            incomePaymentItems,
            currency,
        } = completePaymentData;

        const { isSameCurrency, bankAccountCurrency } = this.state;

        const isBill = values.receiptType.id == receiptTypes.BILL;
        const isInvoice = values.receiptType.id == receiptTypes.INVOICE;
        const isVoucher = values.receiptType.id == receiptTypes.VOUCHER;
        const isWireTransfer = values.paymentType.id == paymentTypes.WIRE_TRANSFER;

        const bakAcccountInDollars = this.isSelectedBankAccountInDollars(
            Number(values.bankAccount.id),
        );

        return (
            <div>
                {property && <DisplayItem label="Puesto" value={property.code} />}
                {shareholder && (
                    <DisplayItem
                        label="Accionista"
                        value={`${
                            shareholder.code ? shareholder.code + ' - ' : ''
                        }${formatHelper.getShareholderFullname(shareholder)}`}
                    />
                )}
                <DisplayItem label="Precio Total" type="money" value={totalPrice} />
                <Collapse bordered={false} className="mb-3">
                    <Collapse.Panel header="Conceptos de Pago" key="1">
                        <DataTable data={incomePaymentItems} columns={columns} size="small" />
                    </Collapse.Panel>
                </Collapse>
                <SelectGroupField
                    name="receiptType.id"
                    label="Tipo de Recibo"
                    displayText="description"
                    required
                    data={receiptTypesItems}
                    loading={receiptTypesLoading}
                />
                <InputGroupField
                    name="ruc"
                    label="RUC"
                    hidden={isBill || isVoucher}
                    required
                    suffix={
                        <>
                            <Divider type="vertical" />
                            <Icon
                                type={rucInfoLoading ? 'loading' : 'search'}
                                style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
                                onClick={this.getRucInfo}
                            />
                        </>
                    }
                />
                <InputGroupField
                    name="businessName"
                    label="Razón Social"
                    hidden={isBill || isVoucher}
                    required
                />
                <InputGroupField
                    name="fiscalAddress"
                    label="Dirección Fiscal"
                    hidden={isBill || isVoucher}
                    required
                />
                <InputGroupField
                    name="customerFullname"
                    label="Nombre Completo"
                    hidden={isInvoice}
                    required
                    suffix={
                        <>
                            <Divider type="vertical" />
                            <Tooltip title="Cliente Genérico">
                                <Icon
                                    type="user"
                                    style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
                                    onClick={this.setGenericCustomer}
                                />
                            </Tooltip>
                        </>
                    }
                />
                <InputGroupField name="dni" label="DNI" hidden={isInvoice || isVoucher} required />
                <InputGroupField name="customerEmail" label="Email" hidden={isVoucher} />
                <SelectGroupField
                    name="paymentType.id"
                    label="Tipo de Pago"
                    displayText="description"
                    required
                    data={paymentTypesList}
                    loading={paymentTypesLoading}
                />
                <DisplayItem
                    label="Moneda"
                    value={currency.code}
                    lxs={24}
                    lsm={24}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <SelectGroupField
                    name="bankAccount.id"
                    label="Cuenta Bancaria"
                    displayText={(item: IBankAccount) =>
                        item.bank.name + ' - Nº. Cuenta: ' + item.number
                    }
                    required
                    data={this.props.bankAccounts}
                    loading={bankAccountsLoading}
                    hidden={!isWireTransfer}
                    onChange={this.onBankAccountChange}
                />
                {bankAccountCurrency && isWireTransfer && !isSameCurrency && (
                    <InputGroupField
                        name="amountTransferred"
                        label={`Monto Tranferido (${
                            bankAccountCurrency && bankAccountCurrency.code
                        })`}
                        required
                    />
                )}
                {bankAccountCurrency && isWireTransfer && isSameCurrency && (
                    <DisplayItem
                        label={`Monto Tranferido (${
                            bankAccountCurrency && bankAccountCurrency.code
                        })`}
                        value={formatHelper.toMoney(Number(values.amountTransferred))}
                        lxs={24}
                        lsm={24}
                        lmd={4}
                        llg={3}
                        lxl={2}
                        lxxl={2}
                    />
                )}
                <SwitchGroupField name="isPaymentPending" label="Pendiente de Pago" />
            </div>
        );
    };
}
