import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './ZoneList.types';
import ZoneList from './ZoneList';
import { IRootState } from '../../../../common/types';
import {
    getZones,
    resetZones,
    deactivateZone,
    createZone,
    updateZone,
} from '../../../../store/zone/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { zoneList, deactivateZone, createZone, updateZone } = state.zone;
    const { loggedInUser } = state.auth;

    return {
        zones: zoneList.data,
        total: zoneList.total,
        pageSize: zoneList.pageSize,
        loading: zoneList.loading,
        error: zoneList.error,
        createSuccess: createZone.success,
        createLoading: createZone.loading,
        createError: createZone.error,
        updateSuccess: updateZone.success,
        updateLoading: updateZone.loading,
        updateError: updateZone.error,
        deactivateLoading: deactivateZone.loading,
        deactivateSuccess: deactivateZone.success,
        deactivateError: deactivateZone.error,
        permissions: loggedInUser.value.permissions,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getZones,
            resetZones,
            deactivateZone,
            createZone,
            updateZone,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoneList);
