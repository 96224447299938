import React, { FunctionComponent, useState } from 'react';
import { Upload, Icon, Modal } from 'antd';
import { UploadProps } from 'antd/lib/upload';

interface IOwnProps {
    maxLength?: number;
}

export type IImageUploadProps = UploadProps & IOwnProps;

const ImageUpload: FunctionComponent<IImageUploadProps> = ({
    name,
    fileList,
    maxLength,
    onChange,
    beforeUpload,
    onPreview,
    ...restProps
}) => {
    const [previewImage, setPreviewImage] = useState();
    const [previewVisible, setPreviewVisible] = useState();

    const maxLengthFileList = !maxLength ? 1 : maxLength;

    const dummyRequest = options => {
        setTimeout(() => {
            options.onSuccess('ok');
        }, 0);
    };

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        onPreview && onPreview(file);
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
        setPreviewImage(null);
    };

    const getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const uploadButton = (
        <>
            <Icon type="plus" />
            <div className="ant-upload-text">Subir</div>
        </>
    );

    return (
        <>
            <Upload
                listType="picture-card"
                customRequest={dummyRequest}
                fileList={fileList}
                beforeUpload={beforeUpload}
                onChange={onChange}
                onPreview={handlePreview}
                accept="image/*"
                {...restProps}
            >
                {fileList && fileList.length >= maxLengthFileList ? null : uploadButton}
            </Upload>
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancelPreview}
                style={{ textAlign: 'center' }}
                centered
                width={700}
            >
                <img style={{ width: '90%', height: 'auto' }} src={previewImage} />
            </Modal>
        </>
    );
};
export default ImageUpload;
