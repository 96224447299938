import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import { processStateLoading, processStateSuccess, processStateFailure } from '../../common/helpers/reducer';

//#region GET CONFIGURES
export function getConfigures(state: IState): IState {
    return {
        ...state,
        configureList: { ...state.configureList, loading: true, error: null },
    };
}

export function getConfiguresSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        configureList: {
            data: action.payload.data,
            total: action.payload.total,
            pageSize: action.payload.pageSize,
            loading: false,
            error: null,
        },
    };
}

export function getConfiguresFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        configureList: {
            ...initialState.configureList,
            error: action.payload.error,
        },
    };
}

export function resetGetConfigures(state: IState): IState {
    return {
        ...state,
        configureList: initialState.configureList,
    };
}
//#endregion

//#region UPDATE CONFIGURE
export function updateConfigure(state: IState): IState {
    return {
        ...state,
        updateConfigure: processStateLoading(),
    };
}

export function updateConfigureSuccess(state: IState): IState {
    return {
        ...state,
        updateConfigure: processStateSuccess(),
    };
}

export function updateConfigureFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateConfigure: processStateFailure(action.payload.error),
    };
}

export function updateConfigureReset(state: IState): IState {
    return {
        ...state,
        updateConfigure: initialState.updateConfigure,
    };
}
//#endregion