import * as React from 'react';
import { IProps, IState } from './IncomePendingDetailByShareholder.types';
import { SectionContainer, DisplayItem, DataTable, PBR } from '../../../../components';
import { paths } from '../../../../routes';
import { Divider, Row, Col, Button, message, Modal } from 'antd';
import { columns } from './misc/columns';
import { formatHelper, printHelper } from '../../../../common/helpers';
import { AddIncomePaymentItemModal, CondonePaymentItemModal } from './components';
import { IAddIncomePaymetItemForm } from './components/addIncomePaymentItemModal/AddIncomePaymentItemModal.types';
import { IIncomePaymentItem } from '../../../../common/types/entities/incomePaymentItem';
import { ICompletePaymentData } from '../../../../common/types';
import { permissions, currencies } from '../../../../common/constants';

export default class IncomePendingDetailByShareholder extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            addIncomePaymetItemModalOpen: false,
            itemsToPay: [],
            totalToPay: 0,
            condonePaymentItemModalOpen: false,
            incomePaymentItemToCondone: undefined,
            currency: undefined,
        };
    }

    private shareholderId = Number(this.props.match.params['id']);

    componentDidMount() {
        this.props.getShareholder(this.shareholderId);
        this.performGetPaymentItems();
    }

    componentWillUnmount() {
        this.props.resetShareholder();
        this.props.resetShareholderIncomePaymentItems();
        this.props.resetCreateIncomeShareholderPaymentItem();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkCreateIncomePatmentItem(prevProps);
        this.checkDeleteIncomePaymentItem(prevProps);
        this.checkCondoneIncomePaymentItem(prevProps);
    }

    async checkCreateIncomePatmentItem(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.createIncomePaymentItemSuccess && props.createIncomePaymentItemSuccess) {
            message.success('Concepto agregado');
            await this.setState({ addIncomePaymetItemModalOpen: false });
            this.performGetPaymentItems();
        } else if (!prevProps.createIncomePaymentItemError && props.createIncomePaymentItemError)
            message.error(props.createIncomePaymentItemError);
    }

    async checkDeleteIncomePaymentItem(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.deleteIncomePaymentItemSuccess && props.deleteIncomePaymentItemSuccess) {
            message.success('Concepto eliminado');
            this.performGetPaymentItems();
        } else if (!prevProps.deleteIncomePaymentItemError && props.deleteIncomePaymentItemError)
            message.error(props.deleteIncomePaymentItemError);
    }

    async checkCondoneIncomePaymentItem(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.condoneSuccess && props.condoneSuccess) {
            message.success('Multa condonada');
            this.setState({
                condonePaymentItemModalOpen: false,
                incomePaymentItemToCondone: undefined,
            });
            this.performGetPaymentItems();
        } else if (!prevProps.condoneError && props.condoneError) message.error(props.condoneError);
    }

    performGetPaymentItems() {
        this.props.getShareholderIncomePaymentItems(this.shareholderId, this.state.filter);
    }

    performCreateIncomePaymentItem = (values: IAddIncomePaymetItemForm) => {
        const finalValues = {
            shareholder: { id: this.shareholderId },
            ...values,
        };
        const image = values.image && values.image.length > 0 && values.image[0];
        this.props.createIncomeShareholderPaymentItem(finalValues, image);
    };

    goToCompletePayment = () => {
        const data: ICompletePaymentData = {
            shareholder: this.props.shareholder,
            incomePaymentItems: this.state.itemsToPay,
            totalPrice: this.state.totalToPay,
            currency: this.state.currency,
        };

        this.props.setCompletePaymentData(data);
        this.props.history.push(paths.ADMIN_INCOME_COMPLETE_PAYMENT);
    };

    printTicketSales = (currencyId: number) => {
        const { shareholder, incomePaymentItems } = this.props;

        let incomePaymentItemsByCurrency: IIncomePaymentItem[] = [];

        if (this.state.itemsToPay.length > 0) {
            incomePaymentItemsByCurrency = this.state.itemsToPay.filter(
                (x) => x.currency.id == currencyId,
            );
        } else {
            incomePaymentItemsByCurrency = incomePaymentItems.filter(
                (x) => x.currency.id == currencyId,
            );
        }

        if (incomePaymentItemsByCurrency.length == 0) return;

        printHelper.printTicketSales({
            shareholder,
            incomePaymentItems: incomePaymentItemsByCurrency,
        });
    };

    itemsInCurrency = (currencyId: number) => {
        const { incomePaymentItems } = this.props;
        if (!incomePaymentItems) return;
        const item = incomePaymentItems.find((x) => x.currency.id == currencyId);
        return item != undefined;
    };

    onClickCheck = (item: IIncomePaymentItem, checked: boolean) => {
        let { itemsToPay, totalToPay, currency } = this.state;

        if (checked) {
            itemsToPay.push(item);
            totalToPay += item.editedPrice || item.price;
        } else {
            itemsToPay = itemsToPay.filter((x) => x.id != item.id);
            totalToPay -= item.editedPrice || item.price;
        }

        if (itemsToPay.length == 0) currency = undefined;
        else currency = itemsToPay[0].currency;

        this.setState({ itemsToPay, totalToPay, currency });
    };

    onClickDelete = (id: number) => {
        if (this.props.deleteIncomePaymentItemLoading) return;

        const { deleteIncomePaymentItem } = this.props;

        Modal.confirm({
            title: '¿Deseas eliminar el concepto de pago?',
            content: 'Eliminar concepto de pago',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteIncomePaymentItem(id);
            },
            onCancel() {},
        });
    };

    onClickCondone = (item: IIncomePaymentItem) => {
        const { paymentItem } = item;
        if (!paymentItem.type || paymentItem.type.id != 2) return;

        this.setState({ condonePaymentItemModalOpen: true, incomePaymentItemToCondone: item });
    };

    performCondoneIncomePaymentItem = (values) => {
        this.props.condoneIncomePaymentItem(this.state.incomePaymentItemToCondone.id, values);
    };

    renderContent = () => {
        const {
            shareholder,
            incomePaymentItems,
            incomePaymentItemsLoading,
            totalPricePEN,
            totalPriceUSD,
            createIncomePaymentItemLoading,
            condoneLoading,
        } = this.props;

        return (
            <>
                <DisplayItem
                    label="Accionista"
                    value={formatHelper.getShareholderFullnameWithCode(shareholder)}
                />
                <DisplayItem label="Precio Total (PEN)" value={totalPricePEN} type="money" />
                <DisplayItem label="Precio Total (USD)" value={totalPriceUSD} type="money" />
                <Divider orientation="left" className="mt-5">
                    Conceptos de Pago
                </Divider>
                <PBR permission={permissions.income.CREATE_INCOME_PAYMENT_ITEMS}>
                    <Row className="mb-3">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() =>
                                    this.setState({ addIncomePaymetItemModalOpen: true })
                                }
                            >
                                Agregar
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <DataTable
                    data={incomePaymentItems}
                    columns={columns(
                        this.state.itemsToPay,
                        this.state.currency,
                        this.onClickCheck,
                        this.onClickDelete,
                        this.onClickCondone,
                        this.props.loggedInUser,
                    )}
                    loading={incomePaymentItemsLoading}
                />
                <Row className="mt-4">
                    <Col>
                        <PBR permission={permissions.income.COMPLETE_INCOMES}>
                            <Button
                                type="primary"
                                disabled={this.state.itemsToPay.length == 0}
                                onClick={this.goToCompletePayment}
                                className="mr-2 mb-2"
                            >
                                {`Pagar${
                                    this.state.totalToPay > 0
                                        ? ': ' +
                                          `${
                                              this.state.currency ? this.state.currency.symbol : ''
                                          } ${formatHelper.toMoney(this.state.totalToPay)}`
                                        : ''
                                }`}
                            </Button>
                        </PBR>
                        {this.itemsInCurrency(currencies.PEN) && (
                            <Button
                                type="default"
                                icon="printer"
                                disabled={incomePaymentItems.length == 0}
                                onClick={() => this.printTicketSales(currencies.PEN)}
                                className="mr-2 mb-2"
                            >
                                Imprimir (PEN)
                            </Button>
                        )}
                        {this.itemsInCurrency(currencies.USD) && (
                            <Button
                                type="default"
                                icon="printer"
                                disabled={incomePaymentItems.length == 0}
                                onClick={() => this.printTicketSales(currencies.USD)}
                                className="mr-2 mb-2"
                            >
                                Imprimir (USD)
                            </Button>
                        )}
                    </Col>
                </Row>
                <PBR permission={permissions.income.CREATE_INCOME_PAYMENT_ITEMS}>
                    <AddIncomePaymentItemModal
                        isOpen={this.state.addIncomePaymetItemModalOpen}
                        onSubmit={this.performCreateIncomePaymentItem}
                        onCancel={() => this.setState({ addIncomePaymetItemModalOpen: false })}
                        submitting={createIncomePaymentItemLoading}
                    />
                </PBR>
                <PBR permission={permissions.income.CONDONE_INCOME_PAYMENT_ITEMS}>
                    <CondonePaymentItemModal
                        isOpen={this.state.condonePaymentItemModalOpen}
                        onSubmit={this.performCondoneIncomePaymentItem}
                        incomePaymentItem={this.state.incomePaymentItemToCondone}
                        onCancel={() =>
                            this.setState({
                                condonePaymentItemModalOpen: false,
                                incomePaymentItemToCondone: undefined,
                            })
                        }
                        submitting={condoneLoading}
                        loggedInUser={this.props.loggedInUser}
                    />
                </PBR>
            </>
        );
    };

    render() {
        const { shareholder, loading, error } = this.props;

        return (
            <SectionContainer
                title="Detalle de ingresos pendientes"
                onBack={() => this.props.history.push(paths.ADMIN_INCOME_PENDING_LIST)}
                loading={loading || !shareholder}
                error={error}
                content={this.renderContent}
            />
        );
    }
}
