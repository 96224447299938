import { IListStatePaginated, IObjectState, IProcessState, IListState } from '../../common/types';
import { IExpense, IExpenseType, IExpenseDetail } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    expenseList: IListStatePaginated<IExpense>;
    activeExpense: IObjectState<IExpense>;
    expenseDetailList: IListState<IExpenseDetail>;
    createExpense: IProcessState;
    updateExpense: IProcessState;
    completeLogistic: IProcessState;
    deactivateExpense: IProcessState;
    deleteExpense: IProcessState;
    deleteExpenseDetail: IProcessState;
    typeList: IListState<IExpenseType>;
    answerExpense: IProcessState;
    completeExpense: IProcessState;
}

export const initialState: IState = {
    expenseList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activeExpense: { value: undefined, loading: false, error: undefined },
    expenseDetailList: { data: [], loading: false, error: undefined },
    createExpense: { success: false, loading: false, error: undefined },
    updateExpense: { success: false, loading: false, error: undefined },
    completeLogistic: { success: false, loading: false, error: undefined },
    deactivateExpense: { success: false, loading: false, error: undefined },
    deleteExpense: { success: false, loading: false, error: undefined },
    deleteExpenseDetail: { success: false, loading: false, error: undefined },
    typeList: { data: [], loading: false, error: undefined },
    answerExpense: { success: false, loading: false, error: undefined },
    completeExpense: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_EXPENSES]: caseFunctions.getExpenses,
    [actionTypes.GET_EXPENSES_SUCCESS]: caseFunctions.getExpensesSuccess,
    [actionTypes.GET_EXPENSES_FAILURE]: caseFunctions.getExpensesFailure,
    [actionTypes.GET_EXPENSES_RESET]: caseFunctions.resetGetExpenses,

    [actionTypes.GET_EXPENSE]: caseFunctions.getExpense,
    [actionTypes.GET_EXPENSE_SUCCESS]: caseFunctions.getExpenseSuccess,
    [actionTypes.GET_EXPENSE_FAILURE]: caseFunctions.getExpenseFailure,
    [actionTypes.GET_EXPENSE_RESET]: caseFunctions.getExpenseReset,

    [actionTypes.GET_EXPENSE_DETAILS]: caseFunctions.getExpenseDetails,
    [actionTypes.GET_EXPENSE_DETAILS_SUCCESS]: caseFunctions.getExpenseDetailsSuccess,
    [actionTypes.GET_EXPENSE_DETAILS_FAILURE]: caseFunctions.getExpenseDetailsFailure,
    [actionTypes.GET_EXPENSE_DETAILS_RESET]: caseFunctions.getExpenseDetailsReset,

    [actionTypes.CREATE_EXPENSE]: caseFunctions.createExpense,
    [actionTypes.CREATE_EXPENSE_SUCCESS]: caseFunctions.createExpenseSuccess,
    [actionTypes.CREATE_EXPENSE_FAILURE]: caseFunctions.createExpenseFailure,
    [actionTypes.CREATE_EXPENSE_RESET]: caseFunctions.createExpenseReset,

    [actionTypes.UPDATE_EXPENSE]: caseFunctions.updateExpense,
    [actionTypes.UPDATE_EXPENSE_SUCCESS]: caseFunctions.updateExpenseSuccess,
    [actionTypes.UPDATE_EXPENSE_FAILURE]: caseFunctions.updateExpenseFailure,
    [actionTypes.UPDATE_EXPENSE_RESET]: caseFunctions.updateExpenseReset,

    [actionTypes.COMPLETE_LOGISTIC]: caseFunctions.completeLogistic,
    [actionTypes.COMPLETE_LOGISTIC_SUCCESS]: caseFunctions.completeLogisticSuccess,
    [actionTypes.COMPLETE_LOGISTIC_FAILURE]: caseFunctions.completeLogisticFailure,

    [actionTypes.DEACTIVATE_EXPENSE]: caseFunctions.deactivateExpense,
    [actionTypes.DEACTIVATE_EXPENSE_SUCCESS]: caseFunctions.deactivateExpenseSuccess,
    [actionTypes.DEACTIVATE_EXPENSE_FAILURE]: caseFunctions.deactivateExpenseFailure,
    [actionTypes.DEACTIVATE_EXPENSE_RESET]: caseFunctions.deactivateExpenseReset,

    [actionTypes.GET_EXPENSE_TYPES]: caseFunctions.getExpenseTypes,
    [actionTypes.GET_EXPENSE_TYPES_SUCCESS]: caseFunctions.getExpenseTypesSuccess,
    [actionTypes.GET_EXPENSE_TYPES_FAILURE]: caseFunctions.getExpenseTypesFailure,
    [actionTypes.GET_EXPENSE_TYPES_RESET]: caseFunctions.getExpenseTypesReset,

    [actionTypes.DELETE_EXPENSE]: caseFunctions.deleteExpense,
    [actionTypes.DELETE_EXPENSE_SUCCESS]: caseFunctions.deleteExpenseSuccess,
    [actionTypes.DELETE_EXPENSE_FAILURE]: caseFunctions.deleteExpenseFailure,
    [actionTypes.DELETE_EXPENSE_RESET]: caseFunctions.deleteExpenseReset,

    [actionTypes.DELETE_EXPENSE_DETAIL]: caseFunctions.deleteExpenseDetail,
    [actionTypes.DELETE_EXPENSE_DETAIL_SUCCESS]: caseFunctions.deleteExpenseDetailSuccess,
    [actionTypes.DELETE_EXPENSE_DETAIL_FAILURE]: caseFunctions.deleteExpenseDetailFailure,
    [actionTypes.DELETE_EXPENSE_DETAIL_RESET]: caseFunctions.deleteExpenseDetailReset,

    [actionTypes.ANSWER_EXPENSE]: caseFunctions.answerExpense,
    [actionTypes.ANSWER_EXPENSE_SUCCESS]: caseFunctions.answerExpenseSuccess,
    [actionTypes.ANSWER_EXPENSE_FAILURE]: caseFunctions.answerExpenseFailure,
    [actionTypes.ANSWER_EXPENSE_RESET]: caseFunctions.answerExpenseReset,

    [actionTypes.COMPLETE_EXPENSE]: caseFunctions.completeExpense,
    [actionTypes.COMPLETE_EXPENSE_SUCCESS]: caseFunctions.completeExpenseSuccess,
    [actionTypes.COMPLETE_EXPENSE_FAILURE]: caseFunctions.completeExpenseFailure,
    [actionTypes.COMPLETE_EXPENSE_RESET]: caseFunctions.completeExpenseReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
