import React, { FunctionComponent, useState, useEffect } from 'react';
import { IProps } from './ZoneDetail.types';
import { DisplayItem, SectionContainer, DataTable } from '../../../../components';
import { paths } from '../../../../routes';
import { Divider, Row, Col, Input } from 'antd';
import { columns } from './misc/columns';

const ZoneDetail: FunctionComponent<IProps> = ({ zone, loading, error, ...props }) => {
    const zoneId = props.match.params['id'];

    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        props.getZone(zoneId);

        return () => {
            props.resetZone();
            props.resetZoneProperties();
        };
    }, []);

    useEffect(() => {
        performGetProperties();
    }, [page]);

    const refreshProperties = () => (page === 1 ? performGetProperties() : setPage(1));

    const performGetProperties = () => props.getZoneProperties(zoneId, filter, page);

    const onPageChange = (page: number) => setPage(page);

    const renderContent = () => {
        return (
            <>
                <DisplayItem label="Nombre" value={zone.name} />
                <Divider orientation="left" className="mt-4">
                    Puestos
                </Divider>
                <Row className="mb-3">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={filter}
                            onChange={e => setFilter(e.target.value)}
                            onSearch={refreshProperties}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            data={props.properties}
                            columns={columns()}
                            pageSize={props.propertiesPageSize}
                            total={props.propertiesTotal}
                            currentPage={page}
                            onPageChange={onPageChange}
                            loading={props.propertiesLoading}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <SectionContainer
            title="Detalle Zona"
            onBack={() => props.history.push(paths.ADMIN_ZONE_LIST)}
            loading={loading || !zone}
            error={error}
            content={renderContent}
        />
    );
};

export default ZoneDetail;
