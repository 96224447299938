import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { IInvoiceItem } from '../../../../../common/types/entities';
import { TableCellItem } from '../../../../../components';
import { formatHelper } from '../../../../../common/helpers';
import { currencyTypes } from '../../../../../common/constants';

export const InvoiceDetailCols = (): ColumnProps<IInvoiceItem>[] => [
    {
        title: 'Nombre',
        dataIndex: 'name',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Precio Unitario',
        className: 'd-none d-md-table-cell',
        render: (invoiceItem: IInvoiceItem) => (
            <span>
                {formatHelper.toMoney(
                    invoiceItem.unitPrice,
                    invoiceItem.currency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                )}
            </span>
        ),
    },
    {
        title: 'Cantidad',
        dataIndex: 'quantity',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Precio Total',
        className: 'd-none d-md-table-cell',
        render: (invoiceItem: IInvoiceItem) => (
            <span>
                {formatHelper.toMoney(
                    invoiceItem.totalPrice,
                    invoiceItem.currency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                )}
            </span>
        ),
    },
    {
        key: 'xs',
        title: 'Factura',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IInvoiceItem) => (
            <>
                <TableCellItem label="Nombre" value={item.name} />
                <TableCellItem label="Precio Unitario" value={item.unitPrice} type="money" />
                <TableCellItem label="Cantidad" value={item.quantity} />
                <TableCellItem label="Precio Total" value={item.totalPrice} type="money" />
            </>
        ),
    },
];
