import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem, TableRowOptionsDropdown } from '../../../../../components';
import { IShareholderProperty } from '../../../../../common/types/entities';
import { getShareholderFullname } from '../../../../../common/helpers/format';

export const columns = (
    onClickEdit: (item: IShareholderProperty) => void,
    onClickDelete: (id: number) => void,
): ColumnProps<IShareholderProperty>[] => [
    {
        title: 'Accionista',
        dataIndex: '',
        className: 'd-none d-md-table-cell',
        render: (item: IShareholderProperty) => (
            <span>{getShareholderFullname(item.shareholder)}</span>
        ),
    },
    {
        title: 'Acciones',
        dataIndex: 'shares',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Accionista',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IShareholderProperty) => (
            <>
                <TableCellItem
                    label="Accionista"
                    value={getShareholderFullname(item.shareholder)}
                />
                <TableCellItem label="Acciones" value={item.shares} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IShareholderProperty) => (
            <TableRowOptionsDropdown
                onClickEdit={() => onClickEdit(item)}
                onClickDelete={() => onClickDelete(item.id)}
            />
        ),
    },
];
