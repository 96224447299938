import React from 'react';
import { Button, Row, Col, Input, message, Modal, Select } from 'antd';
import { columns } from './misc/columns';
import { IProps, IState } from './BankAccountList.types';
import { SectionContainer, DataTable, PBR } from '../../../../components';
import { paths } from '../../../../routes';
import { helper } from '../../../../common/helpers';
import { permissions } from '../../../../common/constants';

export default class BankAccountList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        bankAccountTypeId: null,
    };

    componentDidMount() {
        // this.props.getBankAccountTypes();
        this.performGetBankAccounts();
    }

    componentWillUnmount() {
        // this.props.resetBankAccountTypes();
        this.props.resetBankAccounts();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkDeactivateBankAccount(prevProps);
    }

    async checkDeactivateBankAccount(prevProps: IProps) {
        if (!prevProps.deactivateSuccess && this.props.deactivateSuccess) {
            message.success('Cuenta bancaria eliminada');
            await this.setState({ page: 1 });
            this.performGetBankAccounts();
        } else if (!prevProps.deactivateError && this.props.deactivateError)
            message.error(this.props.deactivateError);
    }

    performGetBankAccounts() {
        const { bankAccountTypeId, filter, page } = this.state;
        this.props.getBankAccounts(bankAccountTypeId, filter, page);
    }

    async filterBankAccounts() {
        await this.setState({ page: 1 });
        this.performGetBankAccounts();
    }

    perfromDeactivateBankAccount = (id: number) => {
        const { deactivateBankAccount } = this.props;

        Modal.confirm({
            title: '¿Deseas eliminar la cuenta bancaria?',
            content: 'Eliminar cuenta bancaria',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deactivateBankAccount(id);
            },
            onCancel() {},
        });
    };

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetBankAccounts();
    };

    onChangeBankAccountType = async (bankAccountTypeId: number) => {
        await this.setState({ page: 1, bankAccountTypeId });
        this.performGetBankAccounts();
    };

    onClickDetail = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_BANK_ACCOUNT_DETAIL, { id }));
    };

    onClickEdit = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_BANK_ACCOUNT_EDIT, { id }));
    };

    onClickTransactionList = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_BANK_ACCOUNT_TRANSACTION, { id }));
    };

    render() {
        const {
            loading,
            bankAccounts,
            pageSize,
            total,
            // bankAccountTypes,
            // bankAccountTypesLoading,
        } = this.props;

        return (
            <SectionContainer title="Cuentas Bancarias" icon="credit-card">
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={(e) => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterBankAccounts()}
                        />
                    </Col>
                    {/* <Col className="ml-md-3 ml-lg-4" md={11} lg={6}>
                        <Row>
                            <Col className="mt-2 mt-md-1" md={5} lg={6}>
                                Tipo:
                            </Col>
                            <Col md={18} lg={18}>
                                {bankAccountTypes && (
                                    <Select
                                        className="w-100"
                                        value={this.state.bankAccountTypeId}
                                        onChange={this.onChangeBankAccountType}
                                        loading={bankAccountTypesLoading}
                                        allowClear={true}
                                    >
                                        {bankAccountTypes.map(i => (
                                            <Select.Option key={nanoid()} value={i.id}>
                                                {i.description}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Col>
                        </Row>
                    </Col> */}
                </Row>
                <PBR permission={permissions.bankAccount.WRITE_BANK_ACCOUNTS}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() =>
                                    this.props.history.push(paths.ADMIN_BANK_ACCOUNT_CREATE)
                                }
                            >
                                Nuevo
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={bankAccounts}
                            columns={columns(
                                this.onClickDetail,
                                this.onClickEdit,
                                this.onClickTransactionList,
                                this.perfromDeactivateBankAccount,
                            )}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
            </SectionContainer>
        );
    }
}
