import React from 'react';
import { Checkbox, Button, Icon } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { IIncomePaymentItem, IUser, ICurrency } from '../../../../../common/types/entities';
import { formatHelper, displayHelper } from '../../../../../common/helpers';
import {
    TableCellItem,
    TableRowOptionsDropdown,
    TableRowOptionItem,
    PBR,
} from '../../../../../components';
import { paymentItemTypes, roles, permissions } from '../../../../../common/constants';

export const columns = (
    itemsToPay: IIncomePaymentItem[],
    currency: ICurrency,
    onClickCheck: (item: IIncomePaymentItem, checked: boolean) => void,
    onClickDelete: (id: number) => void,
    onClickCondone: (item: IIncomePaymentItem) => void,
    loggedInUser: IUser,
): ColumnProps<IIncomePaymentItem>[] => [
    {
        title: 'Concepto',
        key: 'Concepto',
        className: 'd-none d-md-table-cell',
        render: (item: IIncomePaymentItem) => {
            if (item.attachedFiles && item.attachedFiles.length > 0)
                return (
                    <a href={item.attachedFiles[0].url} target="_blank">
                        {formatHelper.getPaymentItemWithType(item.paymentItem)}
                    </a>
                );

            return <span>{formatHelper.getPaymentItemWithType(item.paymentItem)}</span>;
        },
    },
    {
        title: 'Precio',
        className: 'd-none d-md-table-cell',
        render: (item: IIncomePaymentItem) => displayHelper.incomePaymentItemPrice(item),
    },
    {
        title: 'Comentario',
        dataIndex: 'comment',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Fecha de Registro',
        dataIndex: 'createdAt',
        className: 'd-none d-md-table-cell',
        render: (createdAt: Date) => <span>{formatHelper.toDate(createdAt)}</span>,
    },

    {
        key: 'xs',
        title: 'Puesto',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IIncomePaymentItem) => (
            <>
                <TableCellItem
                    label="Concepto"
                    value={formatHelper.getPaymentItemWithType(item.paymentItem)}
                />
                <TableCellItem label="Precio" value={displayHelper.incomePaymentItemPrice(item)} />
                <TableCellItem label="Comentario" value={item.comment} />
            </>
        ),
    },
    loggedInUser.permissions.includes(permissions.income.COMPLETE_INCOMES)
        ? {
              title: () => (
                  <>
                      <span className="mr-1 ant-table-column-title">
                          Pagar / <Icon type="printer" />
                      </span>
                  </>
              ),
              //   title: 'Pagar',
              // className: 'd-none d-md-table-cell',
              render: (item: IIncomePaymentItem) => {
                  const disabled = currency !== undefined && item.currency.id != currency.id;

                  return (
                      <div>
                          <Checkbox
                              checked={itemsToPay.find((x) => x.id == item.id) != null}
                              onChange={(e) => onClickCheck(item, e.target.checked)}
                              disabled={disabled}
                          />
                      </div>
                  );
              },
          }
        : {},
    {
        key: 'options',
        dataIndex: '',
        render: (item: IIncomePaymentItem) => {
            if (
                item.paymentItem.type.id != paymentItemTypes.PENALTY_FEE &&
                loggedInUser.role.id == roles.PENALTY_FEE_OPERATOR
            ) {
                return null;
            }

            if (
                loggedInUser.permissions.includes(
                    permissions.income.CONDONE_INCOME_PAYMENT_ITEMS,
                ) &&
                item.paymentItem.type &&
                item.paymentItem.type.id == paymentItemTypes.PENALTY_FEE
            ) {
                return (
                    <TableRowOptionsDropdown
                        onClickDelete={() => onClickDelete(item.id)}
                        disabled={itemsToPay.find((x) => x.id == item.id) != null}
                    >
                        <TableRowOptionItem
                            label="Condonar"
                            icon="down-square"
                            onClick={() => onClickCondone(item)}
                        />
                    </TableRowOptionsDropdown>
                );
            }

            return (
                <PBR permission={permissions.income.DELETE_INCOME_PAYMENT_ITEMS}>
                    <Button
                        type="primary"
                        shape="round"
                        icon="delete"
                        onClick={() => onClickDelete(item.id)}
                        disabled={itemsToPay.find((x) => x.id == item.id) != null}
                    />
                </PBR>
            );
        },
    },
];
