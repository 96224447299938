import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';

const { PETTY_CASH_URL } = apiUrls;

export function getAll(filter: string, page: number) {
    const params = { filter, page };
    return APIManager.get(PETTY_CASH_URL, { params });
}

export function get(id: number) {
    const url = `${PETTY_CASH_URL}/${id}`;
    return APIManager.get(url);
}

export function getActive() {
    const url = `${PETTY_CASH_URL}/active`;
    return APIManager.get(url);
}

export function create(data: any) {
    return APIManager.post(PETTY_CASH_URL, { data });
}

export function getMovements(id: number, filter: string, page: number) {
    const params = { filter, page };

    const url = `${PETTY_CASH_URL}/${id}/movements`;
    return APIManager.get(url, { params });
}

export function getMovementItems(movementId: number, filter: string, page: number) {
    const params = { filter, page };
    const url = `${PETTY_CASH_URL}/movements/${movementId}/items`;

    return APIManager.get(url, { params });
}

export function createMovement(id: number, data: any) {
    const url = `${PETTY_CASH_URL}/${id}/movements`;
    return APIManager.post(url, { data });
}

export function deleteMovement(pettyCashId: number, movementId: number) {
    const url = `${PETTY_CASH_URL}/${pettyCashId}/movements/${movementId}`;
    return APIManager.delete(url);
}
