import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../components';
import { IShareholderProperty } from '../../../../../common/types/entities';
import { formatHelper } from '../../../../../common/helpers';

export const columns = (): ColumnProps<IShareholderProperty>[] => [
    {
        title: 'Código',
        dataIndex: 'shareholder.code',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Nombre Completo',
        dataIndex: '',
        className: 'd-none d-md-table-cell',
        render: (item: IShareholderProperty) =>
            formatHelper.getShareholderFullname(item.shareholder),
    },
    {
        title: 'DNI',
        dataIndex: '',
        className: 'd-none d-md-table-cell',
        render: (item: IShareholderProperty) => <span>{item.shareholder.dni}</span>,
    },
    {
        title: 'RUC',
        dataIndex: '',
        className: 'd-none d-md-table-cell',
        render: (item: IShareholderProperty) => <span>{item.shareholder.ruc}</span>,
    },
    {
        title: 'Razón Social',
        dataIndex: '',
        className: 'd-none d-md-table-cell',
        render: (item: IShareholderProperty) => <span>{item.shareholder.businessName}</span>,
    },
    {
        key: 'xs',
        title: 'Accionista',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IShareholderProperty) => (
            <>
                <TableCellItem label="Código" value={item.shareholder.code} />
                <TableCellItem label="Nombre" value={item.shareholder.firstname} />
                <TableCellItem label="Apellidos" value={item.shareholder.lastname} />
                <TableCellItem label="DNI" value={item.shareholder.dni} />
                <TableCellItem label="RUC" value={item.shareholder.ruc} />
                <TableCellItem label="Razón Social" value={item.shareholder.businessName} />
            </>
        ),
    },
];
