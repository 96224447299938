import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button, Row, Col, Input, message, Modal } from 'antd';
import { columns } from './misc/columns';
import { IProps } from './ShareholderList.types';
import { SectionContainer, DataTable, PBR } from '../../../../components';
import { paths } from '../../../../routes';
import { helper } from '../../../../common/helpers';
import { permissions } from '../../../../common/constants';

const ShareholderList: FunctionComponent<IProps> = props => {
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        return () => props.resetShareholders();
    }, []);

    useEffect(() => {
        if (props.deactivateSuccess) {
            message.success('Accionista eliminado');
            refreshShareholders();
            props.resetDeactivateShareholder();
        }
        if (props.deactivateError) {
            message.error(props.deactivateError);
            props.resetDeactivateShareholder();
        }
    }, [props.deactivateSuccess, props.deactivateError]);

    useEffect(() => {
        performGetShareholders();
    }, [page]);

    const refreshShareholders = () => (page === 1 ? performGetShareholders() : setPage(1));

    const performGetShareholders = () => props.getShareholders(filter, page);

    const onSearch = () => refreshShareholders();

    const performDeactivateShareholder = (id: number) => {
        const { deactivateShareholder } = props;

        Modal.confirm({
            title: '¿Deseas eliminar al accionista?',
            content: 'Eliminar accionista',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deactivateShareholder(id);
            },
            onCancel() { },
        });
    };

    const onPageChange = (page: number) => setPage(page);

    const onClickDetail = (id: number) =>
        props.history.push(helper.setPathParams(paths.ADMIN_SHAREHOLDER_DETAIL, { id }));

    const onClickEdit = (id: number) =>
        props.history.push(helper.setPathParams(paths.ADMIN_SHAREHOLDER_EDIT, { id }));

    const onClickCoOwners = (id: number) =>
        props.history.push(helper.setPathParams(paths.ADMIN_SHAREHOLDER_COOWNER_LIST, { id }));

    const { loading, shareholders, pageSize, total } = props;

    return (
        <SectionContainer title="Accionistas" icon="user">
            <Row className="mb-2">
                <Col md={12} lg={6}>
                    <Input.Search
                        placeholder="Buscar"
                        enterButton
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                        onSearch={onSearch}
                    />
                </Col>
            </Row>
            <PBR permission={permissions.shareholder.WRITE_SHAREHOLDERS}>
                <Row className="mb-2">
                    <Col className="float-right">
                        <Button
                            icon="plus"
                            type="primary"
                            onClick={() => props.history.push(paths.ADMIN_SHAREHOLDER_CREATE)}
                        >
                            Nuevo
                        </Button>
                    </Col>
                </Row>
            </PBR>
            <Row>
                <Col>
                    <DataTable
                        data={shareholders}
                        columns={columns(
                            onClickDetail,
                            onClickEdit,
                            onClickCoOwners,
                            performDeactivateShareholder,
                        )}
                        pageSize={pageSize}
                        total={total}
                        currentPage={page}
                        onPageChange={onPageChange}
                        loading={loading}
                    />
                </Col>
            </Row>
        </SectionContainer>
    );
};

export default ShareholderList;
