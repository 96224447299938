import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './ZoneDetail.types';
import ZoneDetail from './ZoneDetail';
import { IRootState } from '../../../../common/types';
import {
    getZone,
    resetZone,
    getZoneProperties,
    resetZoneProperties,
} from '../../../../store/zone/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeZone, propertyList } = state.zone;

    return {
        zone: activeZone.value,
        loading: activeZone.loading,
        error: activeZone.error,
        properties: propertyList.data,
        propertiesTotal: propertyList.total,
        propertiesPageSize: propertyList.pageSize,
        propertiesLoading: propertyList.loading,
        propertiesError: propertyList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getZone,
            resetZone,
            getZoneProperties,
            resetZoneProperties,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ZoneDetail);
