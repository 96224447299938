import React from 'react';
import { Button, Row, Col, Input, message, Modal } from 'antd';
import { columns } from './misc/columns';
import { IProps, IState } from './PaymentItemList.types';
import { SectionContainer, DataTable, PBR } from '../../../../../components';
import { paths } from '../../../../../routes';
import { helper } from '../../../../../common/helpers';
import { permissions } from '../../../../../common/constants';

export default class PaymentItemList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
    };

    componentDidMount() {
        this.performGetPaymentItems();
    }

    componentWillUnmount() {
        this.props.resetPaymentItems();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkDeactivateProperty(prevProps);
    }

    async checkDeactivateProperty(prevProps: IProps) {
        if (!prevProps.deactivateSuccess && this.props.deactivateSuccess) {
            message.success('Concepto de pago eliminado');
            await this.setState({ page: 1 });
            this.performGetPaymentItems();
        } else if (!prevProps.deactivateError && this.props.deactivateError)
            message.error(this.props.deactivateError);
    }

    performGetPaymentItems() {
        const { filter, page } = this.state;
        this.props.getPaymentItems(filter, page);
    }

    async filterProperties() {
        await this.setState({ page: 1 });
        this.performGetPaymentItems();
    }

    perfromDeactivateProperties = (id: number) => {
        const { deactivatePaymentItem } = this.props;

        Modal.confirm({
            title: '¿Deseas eliminar el concepto de pago?',
            content: 'Eliminar concepto de pago',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deactivatePaymentItem(id);
            },
            onCancel() { },
        });
    };

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetPaymentItems();
    };

    onClickEdit = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_PAYMENT_ITEM_EDIT, { id }));
    };

    render() {
        const { loading, paymentItems, pageSize, total } = this.props;

        return (
            <SectionContainer title="Conceptos de Pago" icon="tags">
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={e => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterProperties()}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.paymentItem.WRITE_PAYMENT_ITEMS}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() =>
                                    this.props.history.push(paths.ADMIN_PAYMENT_ITEM_CREATE)
                                }
                            >
                                Nuevo
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={paymentItems}
                            columns={columns(this.onClickEdit, this.perfromDeactivateProperties)}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
            </SectionContainer>
        );
    }
}
