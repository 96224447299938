import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import ExpensePaymentAddEdit from './ExpensePaymentAddEdit';
import { resetExpense, getExpense } from '../../../../store/expense/actions';
import {
    getInvoicesByRequestOrderDetails,
    resetInvoicesbyRequestOrderDetails,
} from '../../../../store/invoice/actions';
import {
    getRequestOdersByExpense,
    resetRequestOrdersByExpense,
} from '../../../../store/requestOrder/actions';
import { completePayment } from '../../../../store/payment/actions';
import { getBankAccounts, resetBankAccounts } from '../../../../store/bankAccount/actions';
import { getPaymentTypes, resetPaymentTypes } from '../../../../store/income/actions';
import { IRootState } from '../../../../common/types';
import { IStoreState } from './ExpensePaymentAddEdit.types';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeExpense } = state.expense,
        { invoiceByRequestOrderList } = state.invoice,
        { completePayment } = state.payment,
        { requestOrderByExpenseList } = state.requestOrder,
        { bankAccountList } = state.bankAccount,
        { paymentTypeList } = state.income;

    return {
        activeExpense: activeExpense.value,
        activeExpenseLoading: activeExpense.loading,
        activeExpenseError: activeExpense.error,
        bankAccounts: bankAccountList.data,
        bankAccountsLoading: bankAccountList.loading,
        paymentTypes: paymentTypeList.data,
        paymentTypesLoading: paymentTypeList.loading,
        requestOrdersByExpense: requestOrderByExpenseList.data,
        requestOrdersByExpenseLoading: requestOrderByExpenseList.loading,
        invoicesByRequestOrder: invoiceByRequestOrderList.data,
        invoicesByRequestOrderLoading: invoiceByRequestOrderList.loading,
        completePaymentSuccess: completePayment.success,
        completePaymentLoading: completePayment.loading,
        completePaymentError: completePayment.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getBankAccounts,
            getPaymentTypes,
            getExpense,
            getInvoicesByRequestOrderDetails,
            getRequestOdersByExpense,
            completePayment,
            resetBankAccounts,
            resetPaymentTypes,
            resetExpense,
            resetInvoicesbyRequestOrderDetails,
            resetRequestOrdersByExpense,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpensePaymentAddEdit);
