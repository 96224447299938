const actionTypes = {
    GET_USERS: 'user/GET_USERS',
    GET_USERS_SUCCESS: 'user/GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'user/GET_USERS_FAILURE',
    GET_USERS_RESET: 'user/GET_USERS_RESET',

    GET_USER: 'user/GET_USER',
    GET_USER_SUCCESS: 'user/GET_USER_SUCCESS',
    GET_USER_FAILURE: 'user/GET_USER_FAILURE',
    GET_USER_RESET: 'user/GET_USER_RESET',

    CREATE_USER: 'user/CREATE_USER',
    CREATE_USER_SUCCESS: 'user/CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'user/CREATE_USER_FAILURE',
    CREATE_USER_RESET: 'user/CREATE_USER_RESET',

    UPDATE_USER: 'user/UPDATE_USER',
    UPDATE_USER_SUCCESS: 'user/UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'user/UPDATE_USER_FAILURE',
    UPDATE_USER_RESET: 'user/UPDATE_USER_RESET',

    DEACTIVATE_USER: 'user/DEACTIVATE_USER',
    DEACTIVATE_USER_SUCCESS: 'user/DEACTIVATE_USER_SUCCESS',
    DEACTIVATE_USER_FAILURE: 'user/DEACTIVATE_USER_FAILURE',
    DEACTIVATE_USER_RESET: 'user/DEACTIVATE_USER_RESET',

    GET_ROLES: 'user/GET_ROLES',
    GET_ROLES_SUCCESS: 'user/GET_ROLES_SUCCESS',
    GET_ROLES_FAILURE: 'user/GET_ROLES_FAILURE',
    GET_ROLES_RESET: 'user/GET_ROLES_RESET',

    UPDATE_PASSWORD: 'user/UPDATE_PASSWORD',
    UPDATE_PASSWORD_SUCCESS: 'user/UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'user/UPDATE_PASSWORD_FAILURE',
    UPDATE_PASSWORD_RESET: 'user/UPDATE_PASSWORD_RESET',

    UPDATE_AUTHORIZATION_TOKEN: 'user/UPDATE_AUTHORIZATION_TOKEN',
    UPDATE_AUTHORIZATION_TOKEN_SUCCESS: 'user/UPDATE_AUTHORIZATION_TOKEN_SUCCESS',
    UPDATE_AUTHORIZATION_TOKEN_FAILURE: 'user/UPDATE_AUTHORIZATION_TOKEN_FAILURE',
    UPDATE_AUTHORIZATION_TOKEN_RESET: 'user/UPDATE_AUTHORIZATION_TOKEN_RESET',
};

export default actionTypes;
