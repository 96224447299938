const actionTypes = {
  GET_INVOICES_BY_REQUEST_ORDER: 'invoice/GET_INVOICES_BY_REQUEST_ORDER',
  GET_INVOICES_BY_REQUEST_ORDER_SUCCESS: 'invoice/GET_INVOICES_BY_REQUEST_ORDER_SUCCESS',
  GET_INVOICES_BY_REQUEST_ORDER_FAILURE: 'invoice/GET_INVOICES_BY_REQUEST_ORDER_FAILURE',
  GET_INVOICES_BY_REQUEST_ORDER_RESET: 'invoice/GET_INVOICES_BY_REQUEST_ORDER_RESET',

  GET_INVOICES_BY_PROVIDER: 'invoice/GET_INVOICES_BY_PROVIDER',
  GET_INVOICES_BY_PROVIDER_SUCCESS: 'invoice/GET_INVOICES_BY_PROVIDER_SUCCESS',
  GET_INVOICES_BY_PROVIDER_FAILURE: 'invoice/GET_INVOICES_BY_PROVIDER_FAILURE',
  GET_INVOICES_BY_PROVIDER_RESET: 'invoice/GET_INVOICES_BY_PROVIDER_RESET',

  GET_INVOICES_BY_PROVIDER_DETAILS: 'invoice/GET_INVOICES_BY_PROVIDER_DETAILS',
  GET_INVOICES_BY_PROVIDER_DETAILS_SUCCESS: 'invoice/GET_INVOICES_BY_PROVIDER_DETAILS_SUCCESS',
  GET_INVOICES_BY_PROVIDER_DETAILS_FAILURE: 'invoice/GET_INVOICES_BY_PROVIDER_DETAILS_FAILURE',
  GET_INVOICES_BY_PROVIDER_DETAILS_RESET: 'invoice/GET_INVOICES_BY_PROVIDER_DETAILS_RESET',

  GET_INVOICES_BY_REQUEST_ORDER_DETAILS: 'invoice/GET_INVOICES_BY_REQUEST_ORDER_DETAILS',
  GET_INVOICES_BY_REQUEST_ORDER_DETAILS_SUCCESS: 'invoice/GET_INVOICES_BY_REQUEST_ORDER_DETAILS_SUCCESS',
  GET_INVOICES_BY_REQUEST_ORDER_DETAILS_FAILURE: 'invoice/GET_INVOICES_BY_REQUEST_ORDER_DETAILS_FAILURE',
  GET_INVOICES_BY_REQUEST_ORDER_DETAILS_RESET: 'invoice/GET_INVOICES_BY_REQUEST_ORDER_DETAILS_RESET',

  GET_INVOICE: 'invoice/GET_INVOICE',
  GET_INVOICE_SUCCESS: 'invoice/GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILURE: 'invoice/GET_INVOICE_FAILURE',

  COMPLETE_INVOICE: 'invoice/COMPLETE_INVOICE',
  COMPLETE_INVOICE_SUCCESS: 'invoice/COMPLETE_INVOICE_SUCCESS',
  COMPLETE_INVOICE_FAILURE: 'invoice/COMPLETE_INVOICE_FAILURE',

  COMPLETE_INVOICE_PROVIDER: 'invoice/COMPLETE_INVOICE_PROVIDER',
  COMPLETE_INVOICE_PROVIDER_SUCCESS: 'invoice/COMPLETE_INVOICE_PROVIDER_SUCCESS',
  COMPLETE_INVOICE_PROVIDER_FAILURE: 'invoice/COMPLETE_INVOICE_PROVIDER_FAILURE',

  DEACTIVATE_INVOICE: 'invoice/DEACTIVATE_INVOICE',
  DEACTIVATE_INVOICE_SUCCESS: 'invoice/DEACTIVATE_INVOICE_SUCCESS',
  DEACTIVATE_INVOICE_FAILURE: 'invoice/DEACTIVATE_INVOICE_FAILURE',
  DEACTIVATE_INVOICE_RESET: 'invoice/DEACTIVATE_INVOICE_RESET',

  DEACTIVATE_INVOICE_PROVIDER: 'invoice/DEACTIVATE_INVOICE_PROVIDER',
  DEACTIVATE_INVOICE_PROVIDER_SUCCESS: 'invoice/DEACTIVATE_INVOICE_PROVIDER_SUCCESS',
  DEACTIVATE_INVOICE_PROVIDER_FAILURE: 'invoice/DEACTIVATE_INVOICE_PROVIDER_FAILURE',
}

export default actionTypes;