import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import InvoiceCreate from './InvoiceCreate';
import { getProviders, resetProviders } from '../../../../store/provider/actions';
import { completeInvoiceProvider } from '../../../../store/invoice/actions';
import { IRootState } from '../../../../common/types';
import { IStoreState } from './InvoiceCreate.types';
import { getBankAccounts, resetBankAccounts } from '../../../../store/bankAccount/actions';
import { getPaymentTypes, resetPaymentTypes } from '../../../../store/income/actions';
import { getCurrencies, resetCurrencies } from '../../../../store/currency/actions';
import { IPaymentType } from '../../../../common/types/entities';
import { paymentTypes } from '../../../../common/constants';

function mapStateToProps(state: IRootState): IStoreState {
    const { completeInvoiceProvider } = state.invoice,
        { providerList } = state.provider,
        { bankAccountList } = state.bankAccount,
        { currencyList } = state.currency,
        { paymentTypeList } = state.income;

    return {
        completeInvoiceProviderSuccess: completeInvoiceProvider.success,
        completeInvoiceProviderLoading: completeInvoiceProvider.loading,
        completeInvoiceProviderError: completeInvoiceProvider.error,
        currencies: currencyList.data,
        currenciesLoading: currencyList.loading,
        bankAccounts: bankAccountList.data,
        bankAccountsLoading: bankAccountList.loading,
        paymentTypes: paymentTypeList.data,
        paymentTypesLoading: paymentTypeList.loading,
        providers: providerList.data,
        providersLoading: providerList.loading,
        providersError: providerList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getProviders,
            getBankAccounts,
            getPaymentTypes,
            getCurrencies,
            resetCurrencies,
            completeInvoiceProvider,
            resetProviders,
            resetBankAccounts,
            resetPaymentTypes,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCreate);
