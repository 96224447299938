import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import { IStoreState } from './PaymentList.types';
import PaymentList from './PaymentList';
import { getProviders, resetProviders } from '../../../../store/provider/actions';
import {
    getPaymentsByProvider,
    resetPaymentsByProvider,
    deactivatePayment,
} from '../../../../store/payment/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { providerList } = state.provider,
        { paymentByProviderList, deactivatePayment } = state.payment;

    return {
        providers: providerList.data,
        providersLoading: providerList.loading,
        providersError: providerList.error,
        deactivatePaymentSuccess: deactivatePayment.success,
        deactivatePaymentLoading: deactivatePayment.loading,
        deactivatePaymentError: deactivatePayment.error,
        total: paymentByProviderList.total,
        pageSize: paymentByProviderList.pageSize,
        paymentsByProvider: paymentByProviderList.data,
        paymentsByProviderLoading: paymentByProviderList.loading,
        paymentsByProviderError: paymentByProviderList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getProviders,
            getPaymentsByProvider,
            deactivatePayment,
            resetPaymentsByProvider,
            resetProviders,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentList);
