import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    createExpense,
    resetExpense,
    updateExpense,
    getExpense,
    getExpenseTypes,
    resetExpenseTypes,
    getExpenseDetails,
    resetExpenseDetails,
} from '../../../../store/expense/actions';
import { IStoreState } from './ExpenseRequirementAddEdit.types';
import ExpenseRequirementAdd from './ExpenseRequirementAddEdit';
import { IRootState } from '../../../../common/types';

function mapStateToProps(state: IRootState): IStoreState {
    const {
        activeExpense,
        updateExpense,
        createExpense,
        expenseDetailList,
        typeList,
    } = state.expense;

    return {
        user: state.auth.loggedInUser.value,
        activeExpense: activeExpense.value,
        activeExpenseLoading: activeExpense.loading,
        activeExpenseError: activeExpense.error,
        expenseDetails: expenseDetailList.data,
        expenseDetailsLoading: expenseDetailList.loading,
        createSuccess: createExpense.success,
        createLoading: createExpense.loading,
        createError: createExpense.error,
        updateSuccess: updateExpense.success,
        updateLoading: updateExpense.loading,
        updateError: updateExpense.error,
        expenseTypes: typeList.data,
        expenseTypesLoading: typeList.loading,
        expenseTypesError: typeList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            createExpense,
            resetExpense,
            updateExpense,
            getExpense,
            getExpenseTypes,
            resetExpenseTypes,
            getExpenseDetails,
            resetExpenseDetails,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ExpenseRequirementAdd);
