import React from 'react';
import { SectionContainer } from '../../../../components';
import { IProps } from './ExpenseRequirementAddEdit.types';
import ExpenseRequirementForm from './components/expenseRequirementForm';
import { message } from 'antd';
import { paths } from '../../../../routes';
import { expenseStates } from '../../../../common/constants';

export default class ExpenseRequirementAdd extends React.Component<IProps> {
    private expenseId = this.props.match.params['id'];

    componentDidMount() {
        if (this.expenseId) {
            this.props.getExpense(this.expenseId);
            this.props.getExpenseDetails(this.expenseId);
        } else this.props.getExpenseTypes();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkActiveExpense(prevProps);
        this.checkExpenseUpdate(prevProps);
        this.checkExpenseCreation(prevProps);
    }

    componentWillUnmount() {
        this.props.resetExpense();
        this.props.resetExpenseDetails();
        this.props.resetExpenseTypes();
    }

    async checkActiveExpense(prevProps: IProps) {
        const { props } = this;

        if (props.activeExpense && props.activeExpense.state.id != expenseStates.PENDING)
            this.props.history.push(paths.ADMIN_EXPENSE_LIST);
    }

    checkExpenseCreation(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.createSuccess && props.createSuccess) {
            message.success('Egreso creado');
            this.props.history.push(paths.ADMIN_EXPENSE_LIST);
        } else if (!prevProps.createError && props.createError) message.error(props.createError);
    }

    checkExpenseUpdate(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.updateSuccess && props.updateSuccess) {
            message.success('Egreso actualizado');
            this.props.history.push(paths.ADMIN_EXPENSE_LIST);
        } else if (!prevProps.updateError && props.updateError) message.error(props.updateError);
    }

    onSubmit = (values: any) => {
        if (!this.expenseId) this.props.createExpense(values);
        else this.props.updateExpense(this.expenseId, values);
    };

    onCancel = () => {
        this.props.history.push(paths.ADMIN_EXPENSE_LIST);
    };

    renderForm = () => {
        const {
            user,
            activeExpense,
            activeExpenseLoading,
            createLoading,
            updateLoading,
            expenseDetails,
            expenseDetailsLoading,
            expenseTypes,
            expenseTypesLoading,
        } = this.props;

        if (this.expenseId && (!activeExpense || expenseDetails.length == 0)) return <div />;

        return (
            <ExpenseRequirementForm
                initialValues={activeExpense || {}}
                loggedUser={user}
                onSubmit={this.onSubmit}
                disabled={activeExpenseLoading}
                submitting={createLoading || updateLoading}
                expenseDetails={expenseDetails}
                expenseDetailsLoading={expenseDetailsLoading}
                expenseTypes={expenseTypes}
                expenseTypesLoading={expenseTypesLoading}
                editing={this.expenseId != null}
                preventSubmitOnEnter={true}
            />
        );
    };

    render() {
        const { activeExpenseLoading, activeExpenseError } = this.props;

        return (
            <SectionContainer
                title={
                    this.expenseId
                        ? 'Editar Requerimiento de Egreso'
                        : 'Nuevo Requerimiento de Egreso'
                }
                onBack={() => this.props.history.push(paths.ADMIN_EXPENSE_LIST)}
                loading={activeExpenseLoading}
                error={activeExpenseError}
                content={this.renderForm}
            />
        );
    }
}
