import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    createShareholder,
    resetCreateShareholder,
    getShareholder,
    resetShareholder,
    updateShareholder,
    resetUpdateShareholder,
} from '../../../../store/shareholder/actions';
import { IStoreState } from './ShareholderAddEdit.types';
import ShareholderAddEdit from './ShareholderAddEdit';
import { IRootState } from '../../../../common/types';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeShareholder, updateShareholder, createShareholder } = state.shareholder;

    return {
        activeShareholder: activeShareholder.value,
        activeShareholderLoading: activeShareholder.loading,
        activeShareholderError: activeShareholder.error,
        createSuccess: createShareholder.success,
        createLoading: createShareholder.loading,
        createError: createShareholder.error,
        updateSuccess: updateShareholder.success,
        updateLoading: updateShareholder.loading,
        updateError: updateShareholder.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            createShareholder,
            resetCreateShareholder,
            getShareholder,
            resetShareholder,
            updateShareholder,
            resetUpdateShareholder,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShareholderAddEdit);
