import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { propertyService } from '../../service';
import { IProperty, IShareholder } from '../../common/types/entities';

export function getProperties(
    filter = '',
    page = GET_ALL_ITEMS,
    zoneId = GET_ALL_ITEMS,
    subzoneId = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => propertyService.getAll(filter, page, zoneId, subzoneId),
        types: [
            actionTypes.GET_PROPERTIES,
            actionTypes.GET_PROPERTIES_SUCCESS,
            actionTypes.GET_PROPERTIES_FAILURE,
        ],
    };
}

export function resetProperties() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PROPERTIES_RESET,
        });
    };
}

export function getProperty(id: number): IAPIMiddleware {
    return {
        callAPI: () => propertyService.get(id),
        types: [
            actionTypes.GET_PROPERTY,
            actionTypes.GET_PROPERTY_SUCCESS,
            actionTypes.GET_PROPERTY_FAILURE,
        ],
    };
}

export function resetProperty() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PROPERTY_RESET,
        });
    };
}

export function createProperty(data: IProperty): IAPIMiddleware {
    return {
        callAPI: () => propertyService.create(data),
        types: [
            actionTypes.CREATE_PROPERTY,
            actionTypes.CREATE_PROPERTY_SUCCESS,
            actionTypes.CREATE_PROPERTY_FAILURE,
        ],
    };
}

export function updateProperty(id: number, data: IProperty): IAPIMiddleware {
    return {
        callAPI: () => propertyService.update(id, data),
        types: [
            actionTypes.UPDATE_PROPERTY,
            actionTypes.UPDATE_PROPERTY_SUCCESS,
            actionTypes.UPDATE_PROPERTY_FAILURE,
        ],
    };
}

export function deactivateProperty(id: number): IAPIMiddleware {
    return {
        callAPI: () => propertyService.deactivate(id),
        types: [
            actionTypes.DEACTIVATE_PROPERTY,
            actionTypes.DEACTIVATE_PROPERTY_SUCCESS,
            actionTypes.DEACTIVATE_PROPERTY_FAILURE,
        ],
    };
}

export function getPropertyShareholders(
    id: number,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => propertyService.getShareholders(id, filter, page),
        types: [
            actionTypes.GET_PROPERTY_SHAREHOLDERS,
            actionTypes.GET_PROPERTY_SHAREHOLDERS_SUCCESS,
            actionTypes.GET_PROPERTY_SHAREHOLDERS_FAILURE,
        ],
    };
}

export function resetPropertyShareholders() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PROPERTY_SHAREHOLDERS_RESET,
        });
    };
}

export function createPropertyShareholder(id: number, data: IShareholder): IAPIMiddleware {
    return {
        callAPI: () => propertyService.createPropertyShareholder(id, data),
        types: [
            actionTypes.CREATE_PROPERTY_SHAREHOLDER,
            actionTypes.CREATE_PROPERTY_SHAREHOLDER_SUCCESS,
            actionTypes.CREATE_PROPERTY_SHAREHOLDER_FAILURE,
        ],
    };
}

export function resetCreatePropertyShareholder() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_PROPERTY_SHAREHOLDER_RESET,
        });
    };
}

export function updatePropertyShareholder(id: number, data: IShareholder): IAPIMiddleware {
    return {
        callAPI: () => propertyService.updatePropertyShareholder(id, data),
        types: [
            actionTypes.UPDATE_PROPERTY_SHAREHOLDER,
            actionTypes.UPDATE_PROPERTY_SHAREHOLDER_SUCCESS,
            actionTypes.UPDATE_PROPERTY_SHAREHOLDER_FAILURE,
        ],
    };
}

export function resetUpdatePropertyShareholder() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_PROPERTY_SHAREHOLDER_RESET,
        });
    };
}

export function getAvailableShareholders(id: number): IAPIMiddleware {
    return {
        callAPI: () => propertyService.getAvailableShareholders(id),
        types: [
            actionTypes.GET_AVAILABLE_SHAREHOLDERS,
            actionTypes.GET_AVAILABLE_SHAREHOLDERS_SUCCESS,
            actionTypes.GET_AVAILABLE_SHAREHOLDERS_FAILURE,
        ],
    };
}

export function resetAvailableShareholders() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_AVAILABLE_SHAREHOLDERS_RESET,
        });
    };
}

export function deletePropertyShareholder(shareholderPropertyId: number): IAPIMiddleware {
    return {
        callAPI: () => propertyService.deletePropertyShareholder(shareholderPropertyId),
        types: [
            actionTypes.DELETE_PROPERTY_SHAREHOLDER,
            actionTypes.DELETE_PROPERTY_SHAREHOLDER_SUCCESS,
            actionTypes.DELETE_PROPERTY_SHAREHOLDER_FAILURE,
        ],
    };
}

export function resetDeletePropertyShareholder() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_PROPERTY_SHAREHOLDER_RESET,
        });
    };
}
