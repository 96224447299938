import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { paymentItemService } from '../../service';
import { IPaymentItem } from '../../common/types/entities';

export function getPaymentItems(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => paymentItemService.getAll(filter, page),
        types: [
            actionTypes.GET_PAYMENT_ITEMS,
            actionTypes.GET_PAYMENT_ITEMS_SUCCESS,
            actionTypes.GET_PAYMENT_ITEMS_FAILURE,
        ],
    };
}

export function resetPaymentItems() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PAYMENT_ITEMS_RESET,
        });
    };
}

export function getPaymentItem(id: number): IAPIMiddleware {
    return {
        callAPI: () => paymentItemService.get(id),
        types: [
            actionTypes.GET_PAYMENT_ITEM,
            actionTypes.GET_PAYMENT_ITEM_SUCCESS,
            actionTypes.GET_PAYMENT_ITEM_FAILURE,
        ],
    };
}

export function resetPaymentItem() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PAYMENT_ITEM_RESET,
        });
    };
}

export function createPaymentItem(data: IPaymentItem): IAPIMiddleware {
    return {
        callAPI: () => paymentItemService.create(data),
        types: [
            actionTypes.CREATE_PAYMENT_ITEM,
            actionTypes.CREATE_PAYMENT_ITEM_SUCCESS,
            actionTypes.CREATE_PAYMENT_ITEM_FAILURE,
        ],
    };
}

export function resetCreatePaymentItem() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_PAYMENT_ITEM_RESET,
        });
    };
}

export function updatePaymentItem(id: number, data: IPaymentItem): IAPIMiddleware {
    return {
        callAPI: () => paymentItemService.update(id, data),
        types: [
            actionTypes.UPDATE_PAYMENT_ITEM,
            actionTypes.UPDATE_PAYMENT_ITEM_SUCCESS,
            actionTypes.UPDATE_PAYMENT_ITEM_FAILURE,
        ],
    };
}

export function resetUpdatePaymentItem() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_PAYMENT_ITEM_RESET,
        });
    };
}

export function deactivatePaymentItem(id: number): IAPIMiddleware {
    return {
        callAPI: () => paymentItemService.deactivate(id),
        types: [
            actionTypes.DEACTIVATE_PAYMENT_ITEM,
            actionTypes.DEACTIVATE_PAYMENT_ITEM_SUCCESS,
            actionTypes.DEACTIVATE_PAYMENT_ITEM_FAILURE,
        ],
    };
}

export function resetDeactivatePaymentItem() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DEACTIVATE_PAYMENT_ITEM_RESET,
        });
    };
}

export function getPaymentItemTypes(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => paymentItemService.getAllTypes(filter, page),
        types: [
            actionTypes.GET_PAYMENT_ITEM_TYPES,
            actionTypes.GET_PAYMENT_ITEM_TYPES_SUCCESS,
            actionTypes.GET_PAYMENT_ITEM_TYPES_FAILURE,
        ],
    };
}

export function resetPaymentItemTypes() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PAYMENT_ITEM_TYPES_RESET,
        });
    };
}

export function createPaymentItemType(data: any): IAPIMiddleware {
    return {
        callAPI: () => paymentItemService.createType(data),
        types: [
            actionTypes.CREATE_PAYMENT_ITEM_TYPE,
            actionTypes.CREATE_PAYMENT_ITEM_TYPE_SUCCESS,
            actionTypes.CREATE_PAYMENT_ITEM_TYPE_FAILURE,
        ],
    };
}

export function resetCreatePaymentItemType() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_PAYMENT_ITEM_TYPE_RESET,
        });
    };
}

export function updatePaymentItemType(id: number, data: any): IAPIMiddleware {
    return {
        callAPI: () => paymentItemService.updateType(id, data),
        types: [
            actionTypes.UPDATE_PAYMENT_ITEM_TYPE,
            actionTypes.UPDATE_PAYMENT_ITEM_TYPE_SUCCESS,
            actionTypes.UPDATE_PAYMENT_ITEM_TYPE_FAILURE,
        ],
    };
}

export function resetUpdatePaymentItemType() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_PAYMENT_ITEM_TYPE_RESET,
        });
    };
}

export function deactivatePaymentItemType(id: number): IAPIMiddleware {
    return {
        callAPI: () => paymentItemService.deactivateType(id),
        types: [
            actionTypes.DEACTIVATE_PAYMENT_ITEM_TYPE,
            actionTypes.DEACTIVATE_PAYMENT_ITEM_TYPE_SUCCESS,
            actionTypes.DEACTIVATE_PAYMENT_ITEM_TYPE_FAILURE,
        ],
    };
}

export function resetDeactivatePaymentItemType() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DEACTIVATE_PAYMENT_ITEM_TYPE_RESET,
        });
    };
}
