import React, { FC, useRef, useEffect, useState } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal, Divider, Row, Col, Button, Alert } from 'antd';
import { isEmpty } from 'validator';
import {
    InputGroupField,
    DatePickerGroupField,
    DisplayItem,
    DataTable,
} from '../../../../../../components';
import { messages } from '../../../../../../common/constants';
import { IPettyCashMovementItem } from '../../../../../../common/types/entities';
import { columns } from './columns';
import PettyCashMovementItemAdd from './PettyCashMovementItemAdd.view';
import nanoid from 'nanoid';

type IOwnProps = {
    isOpen: boolean;
    onSubmit: (values: IPettyCashMovementAddForm) => void;
    disabled?: boolean;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    submitting?: boolean;
};

export interface IPettyCashMovementAddForm {
    code: string;
    comment: string;
    paymentDate: Date | string;
    items?: IPettyCashMovementItem[];
    totalPrice?: number;
}

type IProps = IOwnProps;

const sharedFieldProps = {
    lxs: 24,
    lsm: 24,
    lmd: 24,
    llg: 24,
    lxl: 8,
    lxxl: 6,
    imd: 24,
    ixl: 16,
    ilg: 24,
};

const PettyCashMovementAddModal: FC<IProps> = (props) => {
    let form = useRef<Formik<IPettyCashMovementAddForm>>(null);

    const [totalPrice, setTotalPrice] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [items, setItems] = useState<IPettyCashMovementItem[]>([]);

    const onSubmit = (
        values: IPettyCashMovementAddForm,
        actions: FormikActions<IPettyCashMovementAddForm>,
    ) => {
        values.items = items;
        values.totalPrice = totalPrice;
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = (e) => {
        form.current.resetForm();
        props.onCancel(e);
    };

    useEffect(() => {
        if (!props.isOpen) return;

        return () => {
            setItems([]);
            setTotalPrice(0);
        };
    }, [props.isOpen]);

    const validate = (values: IPettyCashMovementAddForm) => {
        let errors: Partial<IPettyCashMovementAddForm> = {};

        if (isEmpty(values.code)) errors.code = messages.MANDATORY_FIELD;
        if (!values.paymentDate) errors.paymentDate = messages.MANDATORY_FIELD;

        return errors;
    };

    const onClickDeleteItem = (stringId: string) => {
        let newTotalPrice = totalPrice;

        const newItems = items.filter((x) => {
            if (x.stringId == stringId) {
                newTotalPrice -= x.totalPrice;
                return false;
            }

            return true;
        });

        setTotalPrice(newTotalPrice);
        setItems(newItems);
    };

    const onSubmitAddItem = (values) => {
        values.stringId = nanoid();
        setTotalPrice(totalPrice + values.totalPrice);
        setItems([...items, values]);
        setModalOpen(false);
    };

    const onOk = () => {
        form.current.submitForm();
    };

    const renderForm = () => {
        if (!props.isOpen) return null;

        return (
            <>
                <Formik
                    initialValues={{
                        code: '',
                        comment: '',
                        paymentDate: new Date(),
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={validate}
                    onSubmit={onSubmit}
                    ref={form}
                    render={({ values }: FormikProps<IPettyCashMovementAddForm>) => (
                        <Form>
                            <InputGroupField
                                name="code"
                                label="Código"
                                required
                                {...sharedFieldProps}
                            />
                            <InputGroupField
                                name="comment"
                                label="Comentario"
                                {...sharedFieldProps}
                            />
                            <DatePickerGroupField
                                name="paymentDate"
                                label="Fecha de Pago"
                                required
                                {...sharedFieldProps}
                            />
                            <DisplayItem
                                label="Monto Total"
                                value={totalPrice}
                                type="money"
                                {...sharedFieldProps}
                            />
                            {totalPrice >= 300 && (
                                <Alert
                                    showIcon
                                    type="warning"
                                    message="Movimientos a partir de 300 soles deben ser aprobados por administración"
                                />
                            )}
                        </Form>
                    )}
                />
                <Divider orientation="left" className="mt-4">
                    Items
                </Divider>
                <Row className="mb-2">
                    <Col className="float-right">
                        <Button icon="plus" type="primary" onClick={() => setModalOpen(true)}>
                            Agregar
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            data={items}
                            columns={columns(onClickDeleteItem)}
                            rowKey={(record) => record.stringId}
                        />
                    </Col>
                </Row>
                <PettyCashMovementItemAdd
                    isOpen={modalOpen}
                    onSubmit={onSubmitAddItem}
                    onCancel={() => setModalOpen(false)}
                />
            </>
        );
    };

    return (
        <Modal
            title="Agregar Movimiento"
            visible={props.isOpen}
            okText="Guardar"
            onOk={onOk}
            okButtonProps={{ loading: props.submitting }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.submitting }}
            maskClosable={false}
            width={650}
        >
            {renderForm()}
        </Modal>
    );
};

export default PettyCashMovementAddModal;
