import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { getPropertyByKeyPath } from '../../../common/utils';
import { FieldMessage } from '../..';
import { IImageUploadProps } from '../../upload/imageUpload/ImageUpload';
import { ImageUpload } from '../..';

export type IImageUploadFieldProps = IImageUploadProps;

const ImageUploadField: FC<IImageUploadFieldProps> = ({ name, onChange, ...restProps }) => {
    return (
        <Field name={name}>
            {({
                field: { value },
                form: { setFieldValue, setFieldTouched, errors },
            }: FieldProps) => {
                const error = getPropertyByKeyPath(errors, name);
                const hasError = error != undefined;

                return (
                    <>
                        <ImageUpload
                            fileList={value}
                            onChange={info => {
                                setFieldValue(name, info.fileList);
                                setFieldTouched(name, true);
                                onChange && onChange(info);
                            }}
                            {...restProps}
                        />
                        <FieldMessage show={hasError} message={error} />
                    </>
                );
            }}
        </Field>
    );
};
export default ImageUploadField;
