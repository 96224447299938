import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import {
    getProviders,
    resetProviders,
    deactivateProvider,
} from '../../../../store/provider/actions';
import { IStoreState } from './ProviderList.types';
import ProviderList from './ProviderList';

function mapStateToProps(state: IRootState): IStoreState {
    const { providerList, deactivateProvider } = state.provider;
    const { loggedInUser } = state.auth;

    return {
        providers: providerList.data,
        total: providerList.total,
        pageSize: providerList.pageSize,
        loading: providerList.loading,
        error: providerList.error,
        deactivateError: deactivateProvider.error,
        deactivateLoading: deactivateProvider.loading,
        deactivateSuccess: deactivateProvider.success,
        permissions: loggedInUser.value.permissions,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getProviders,
            resetProviders,
            deactivateProvider,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderList);
