import React, { FC } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { Field, FieldProps } from 'formik';
import { getPropertyByKeyPath } from '../../../common/utils';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { FieldMessage } from '../..';

export type IDatePickerFieldProps = DatePickerProps;

const DatePickerField: FC<IDatePickerFieldProps> = ({
    name,
    onChange,
    className,
    ...restProps
}) => {
    return (
        <Field name={name}>
            {({
                field: { value },
                form: { setFieldValue, setFieldTouched, errors },
            }: FieldProps) => {
                const error = getPropertyByKeyPath(errors, name);
                const hasError = error != undefined;

                return (
                    <>
                        <DatePicker
                            name={name}
                            value={value ? moment(value) : undefined}
                            onChange={(date, dateString) => {
                                setFieldValue(name, date ? date.toISOString() : null);
                                setFieldTouched(name, true);
                                onChange && onChange(date, dateString);
                            }}
                            className={`w-100 form-input ${
                                hasError ? 'has-error' : ''
                            } ${className}`}
                            {...restProps}
                        />
                        <FieldMessage show={hasError} message={error} />
                    </>
                );
            }}
        </Field>
    );
};

DatePickerField.defaultProps = {
    format: 'DD/MM/YYYY',
};

export default DatePickerField;
