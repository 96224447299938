import React from 'react';
import { Button, Row, Col, Input, message, Modal } from 'antd';
import { columns } from './misc/columns';
import { IProps, IState } from './PaymentItemTypeList.types';
import { SectionContainer, DataTable, PBR } from '../../../../../components';
import { IZone } from '../../../../../common/types/entities';
import { AddEditModal } from './components';
import { IAddEditZoneValues } from './components/AddEditModal';
import { helper } from '../../../../../common/helpers';
import { paths } from '../../../../../routes';
import { permissions } from '../../../../../common/constants';

export default class PaymentItemTypeList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        modalAddEditOpen: false,
    };

    componentDidMount() {
        this.performGetPaymentItemTypes();
    }

    componentWillUnmount() {
        this.props.resetPaymentItemTypes();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkCreatePaymentItemType(prevProps);
        this.checkUpdatePaymentItemType(prevProps);
        this.checkDeactivatePaymentItemType(prevProps);
    }

    async checkDeactivatePaymentItemType(prevProps: IProps) {
        if (!prevProps.deactivateSuccess && this.props.deactivateSuccess) {
            message.success('Tipo de concepto eliminado');
            await this.setState({ page: 1 });
            this.performGetPaymentItemTypes();
        } else if (!prevProps.deactivateError && this.props.deactivateError)
            message.error(this.props.deactivateError);
    }

    async checkCreatePaymentItemType(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.createSuccess && props.createSuccess) {
            message.success('Tipo de concepto creado');
            await this.setState({ page: 1, modalAddEditOpen: false });
            this.performGetPaymentItemTypes();
        } else if (!prevProps.createError && props.createError) message.error(props.createError);
    }

    async checkUpdatePaymentItemType(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.updateSuccess && props.updateSuccess) {
            message.success('Tipo de concepto actualizado');
            await this.setState({ page: 1, modalAddEditOpen: false, typeToEdit: undefined });
            this.performGetPaymentItemTypes();
        } else if (!prevProps.updateError && props.updateError) message.error(props.updateError);
    }

    performGetPaymentItemTypes() {
        const { filter, page } = this.state;
        this.props.getPaymentItemTypes(filter, page);
    }

    async filterPaymentItemTypes() {
        await this.setState({ page: 1 });
        this.performGetPaymentItemTypes();
    }

    perfromDeactivatePaymentItemType = (id: number) => {
        const { deactivatePaymentItemType } = this.props;

        Modal.confirm({
            title: '¿Deseas eliminar el tipo de concepto?',
            content: 'Eliminar tipo de concepto',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deactivatePaymentItemType(id);
            },
            onCancel() {},
        });
    };

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetPaymentItemTypes();
    };

    onClickEdit = (zone: IZone) => {
        this.setState({ modalAddEditOpen: true, typeToEdit: zone });
    };

    handleSubmit = (values: IAddEditZoneValues) => {
        const { typeToEdit } = this.state;
        const { createPaymentItemType, updatePaymentItemType } = this.props;

        if (typeToEdit) {
            updatePaymentItemType(typeToEdit.id, values);
            return;
        }

        createPaymentItemType(values);
    };

    render() {
        const { loading, paymentItemTypes, pageSize, total } = this.props;

        return (
            <SectionContainer title="Tipos de Concepto" icon="unordered-list">
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={e => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterPaymentItemTypes()}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.paymentItemType.WRITE_PAYMENT_ITEM_TYPES}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() =>
                                    this.setState({ modalAddEditOpen: true, typeToEdit: undefined })
                                }
                            >
                                Nuevo
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={paymentItemTypes}
                            columns={columns(
                                this.onClickEdit,
                                this.perfromDeactivatePaymentItemType,
                                this.props.permissions,
                            )}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.paymentItemType.WRITE_PAYMENT_ITEM_TYPES}>
                    <AddEditModal
                        isOpen={this.state.modalAddEditOpen}
                        onSubmit={this.handleSubmit}
                        onCancel={() => this.setState({ modalAddEditOpen: false })}
                        initialValues={this.state.typeToEdit}
                        submitting={this.props.createLoading || this.props.updateLoading}
                    />
                </PBR>
            </SectionContainer>
        );
    }
}
