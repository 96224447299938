import React from 'react';
import { Result, Button } from 'antd';

const Page500: React.FC = () => {
    const reloadPage = () => {
        document.location.reload(true);
    };

    return (
        <Result
            status="500"
            title="500"
            subTitle="Lo sentimos, la página que estás buscando no está disponible en estos
                momentos."
            extra={
                <Button type="primary" onClick={reloadPage}>
                    Volver a intentar
                </Button>
            }
        />
    );
};

export default Page500;
