import React, { FC, useRef } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal, message } from 'antd';
import { isEmpty, isNumeric, isInt } from 'validator';
import { IExpenseDetail } from '../../../../../common/types/entities';
import { messages } from '../../../../../common/constants';
import { InputGroupField } from '../../../../../components';

interface IOwnProps {
  isOpen: boolean;
  onSubmit: (action: boolean, values: IExpenseDetail) => void;
  onCancel: () => void;
}

export interface IRejectExpenseValues {
  reasonToDelete: string;
}

export type IProps = IOwnProps;

const RejectModal: FC<IProps> = props => {
  let form = useRef<Formik<IRejectExpenseValues>>(null);

  const onSubmit = (values: any, actions: FormikActions<IRejectExpenseValues>) => {
    props.onSubmit(false, values.reasonToDelete);
    actions.setSubmitting(false)
  }

  const handleOnCancel = e => {
    if (form && form.current) form.current.resetForm();
    props.onCancel();
  }

  const validate = (values: IRejectExpenseValues) => {
    const errors: Partial<IRejectExpenseValues> = {};
    if (isEmpty(values.reasonToDelete)) errors.reasonToDelete = messages.MANDATORY_FIELD;

    return errors;
  }

  const onOk = () => {
    if (form && form.current) form.current.submitForm();
  }

  const renderFormik = () => {
    return props.isOpen
      ? <Formik
        initialValues={{
          reasonToDelete: '',
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={validate}
        onSubmit={onSubmit}
        ref={form}
        render={(formikBag: FormikProps<IRejectExpenseValues>) => (
          <Form>
            <InputGroupField
              name="reasonToDelete"
              label="Razón"
              required
              lxs={24}
              lsm={24}
              lmd={24}
              llg={24}
              lxl={6}
              lxxl={6}
              imd={24}
              ixl={18}
              ilg={24}
            />
          </Form>
        )}
      />
      : null
  };

  return <Modal
    title={"Rechazar Requerimiento"}
    visible={props.isOpen}
    okText="Rechazar"
    onOk={onOk}
    cancelText="Cancelar"
    onCancel={handleOnCancel}
  >
    {renderFormik()}
  </Modal>
}

export default RejectModal