import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './PaymentItemList.types';
import PaymentItemList from './PaymentItemList';
import { IRootState } from '../../../../../common/types';
import {
    getPaymentItems,
    resetPaymentItems,
    deactivatePaymentItem,
} from '../../../../../store/paymentItem/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { paymentItemList, deactivatePaymentItem } = state.paymentItem;

    return {
        paymentItems: paymentItemList.data,
        total: paymentItemList.total,
        pageSize: paymentItemList.pageSize,
        loading: paymentItemList.loading,
        error: paymentItemList.error,
        deactivateLoading: deactivatePaymentItem.loading,
        deactivateSuccess: deactivatePaymentItem.success,
        deactivateError: deactivatePaymentItem.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getPaymentItems,
            resetPaymentItems,
            deactivatePaymentItem,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentItemList);
