import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../../components';
import { IExpenseDetail } from '../../../../../../common/types/entities';
import { Button } from 'antd';

export const goodsColumns = (
    onClickDelete: (item: IExpenseDetail) => void,
): ColumnProps<IExpenseDetail>[] => [
        {
            title: 'Nombre',
            dataIndex: 'name',
            className: 'd-none d-md-table-cell',
        },
        {
            key: 'xs',
            title: 'Egreso',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (item: IExpenseDetail) => <TableCellItem label="Nombre" value={item.name} />
        },
        {
            key: 'options',
            dataIndex: '',
            render: (item: IExpenseDetail) =>
                <Button icon="delete" type="primary" onClick={() => onClickDelete(item)} />
        },
    ];

export const servicesColumns = (
    onClickDelete: (item: IExpenseDetail) => void,
): ColumnProps<IExpenseDetail>[] => [
        {
            title: 'Nombre',
            dataIndex: 'name',
            className: 'd-none d-md-table-cell'
        },
        {
            key: 'xs',
            title: 'Egreso',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (item: IExpenseDetail) => <TableCellItem label="Nombre" value={item.name} />
        },
        {
            key: 'options',
            dataIndex: '',
            render: (item: IExpenseDetail) =>
                <Button icon="delete" type="primary" onClick={() => onClickDelete(item)} />
        },
    ];
