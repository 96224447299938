import React, { Component } from 'react';
import { SectionContainer, PBR, DataTable } from '../../../../components';
import { Row, Col, Input, message, Button } from 'antd';
import { IState, IProps } from './PettyCashList.types';
import { columns } from './columns';
import { helper } from '../../../../common/helpers';
import { paths } from '../../../../routes';
import { permissions } from '../../../../common/constants';
import { PettyCashAddModal } from './components';

export default class PettyCashList extends Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        modalVisible: false,
    };

    componentDidMount() {
        this.performGetPettyCash();
    }

    componentWillUnmount() {
        this.props.resetPettyCashList();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkCreatePettyCash(prevProps);
    }

    async checkCreatePettyCash(prevProps: IProps) {
        if (!prevProps.createSuccess && this.props.createSuccess) {
            message.success('Caja chica aperturada');
            await this.setState({ page: 1, modalVisible: false });
            this.performGetPettyCash();
        } else if (!prevProps.createError && this.props.createError)
            message.error(this.props.createError);
    }

    performGetPettyCash() {
        const { filter, page } = this.state;
        this.props.getPettyCashList(filter, page);
    }

    filterPettyCash = async () => {
        await this.setState({ page: 1 });
        this.performGetPettyCash();
    };

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetPettyCash();
    };

    onClickDetail = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_PETTY_CASH_DETAIL, { id }));
    };

    render() {
        const { loading, pettyCashList, pageSize, total, error } = this.props;

        return (
            <SectionContainer title="Caja Chica" icon="container" error={error}>
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={(e) => this.setState({ filter: e.target.value })}
                            onSearch={this.filterPettyCash}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.pettyCash.WRITE_PETTY_CASH}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() => this.setState({ modalVisible: true })}
                            >
                                Aperturar
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={pettyCashList}
                            columns={columns(this.onClickDetail)}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
                <PettyCashAddModal
                    isOpen={this.state.modalVisible}
                    onCancel={() => this.setState({ modalVisible: false })}
                    onSubmit={(values) => this.props.createPettyCash(values)}
                    submitting={this.props.createLoading}
                />
            </SectionContainer>
        );
    }
}
