import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    createUser,
    resetUser,
    updateUser,
    getUser,
    getRoles,
} from '../../../../store/user/actions';
import { IStoreState } from './UserAddEdit.types';
import UserAddEdit from './UserAddEdit';
import { IRootState } from '../../../../common/types';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeUser, updateUser, createUser, roleList } = state.user;
    return {
        activeUser: activeUser.value,
        activeUserLoading: activeUser.loading,
        activeUserError: activeUser.error,
        roles: roleList.data,
        updateSuccess: updateUser.success,
        updateLoading: updateUser.loading,
        updateError: updateUser.error,
        createSuccess: createUser.success,
        createLoading: createUser.loading,
        createError: createUser.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            createUser,
            resetUser,
            updateUser,
            getUser,
            getRoles,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserAddEdit);
