export function printLeftAndRight(leftValue: string, rightValue: string) {
    const columns = 64;

    const leftLength = leftValue.length ? leftValue.length : 0;
    const rightLength = rightValue ? rightValue.length : 0;

    let n = columns - leftLength - rightLength;
    if (n <= 0) n = 1;
    const space = ' '.repeat(n);

    leftValue = leftValue || '';
    rightValue = rightValue || '';

    const row = leftValue + space + rightValue;
    return row;
}

export const centerAlign = '\x1B' + '\x61' + '\x31';
export const smallFont = '\x1B' + '\x4D' + '\x31';
export const lineBreak = '\x0A';
export const leftAlign = '\x1B' + '\x61' + '\x30';
export const cutPaper = '\x1B' + '\x69';
export const kickOutCashDrawer = '\x10' + '\x14' + '\x01' + '\x00' + '\x05';

export const printQR = (value: string) => {
    const qrLength = value.length + 3;
    const size1 = String.fromCharCode(qrLength % 256);
    const size0 = String.fromCharCode(Math.floor(qrLength / 256));
    const dots = '\x04';

    return [
        '\x1D' + '\x28' + '\x6B' + '\x04' + '\x00' + '\x31' + '\x41' + '\x32' + '\x00', // <Function 165> select the model (model 2 is widely supported)
        '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x43' + dots, // <Function 167> set the size of the module
        '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x45' + '\x30', // <Function 169> select level of error correction (48,49,50,51) printer-dependent
        '\x1D' + '\x28' + '\x6B' + size1 + size0 + '\x31' + '\x50' + '\x30' + value, // <Function 080> send your data (testing 123) to the image storage area in the printer
        '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x51' + '\x30', // <Function 081> print the symbol data in the symbol storage area
        '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x52' + '\x30', // <Function 082> Transmit the size information of the symbol data in the symbol storage area
    ];
};

export function printNumberWords(numero: number, moneda: string) {
    var monedaUtlizada = moneda == 'PEN' ? 'SOLES' : 'DOLARES AMERICANOS';
    return NumeroALetras(numero);

    function Unidades(num) {
        switch (num) {
            case 1:
                return 'UN';
            case 2:
                return 'DOS';
            case 3:
                return 'TRES';
            case 4:
                return 'CUATRO';
            case 5:
                return 'CINCO';
            case 6:
                return 'SEIS';
            case 7:
                return 'SIETE';
            case 8:
                return 'OCHO';
            case 9:
                return 'NUEVE';
        }

        return '';
    }

    function Decenas(num) {
        const decena = Math.floor(num / 10);
        const unidad = num - decena * 10;

        switch (decena) {
            case 1:
                switch (unidad) {
                    case 0:
                        return 'DIEZ';
                    case 1:
                        return 'ONCE';
                    case 2:
                        return 'DOCE';
                    case 3:
                        return 'TRECE';
                    case 4:
                        return 'CATORCE';
                    case 5:
                        return 'QUINCE';
                    default:
                        return 'DIECI' + Unidades(unidad);
                }
            case 2:
                switch (unidad) {
                    case 0:
                        return 'VEINTE';
                    default:
                        return 'VEINTI' + Unidades(unidad);
                }
            case 3:
                return DecenasY('TREINTA', unidad);
            case 4:
                return DecenasY('CUARENTA', unidad);
            case 5:
                return DecenasY('CINCUENTA', unidad);
            case 6:
                return DecenasY('SESENTA', unidad);
            case 7:
                return DecenasY('SETENTA', unidad);
            case 8:
                return DecenasY('OCHENTA', unidad);
            case 9:
                return DecenasY('NOVENTA', unidad);
            case 0:
                return Unidades(unidad);
        }
    } //Unidades()

    function DecenasY(strSin, numUnidades) {
        if (numUnidades > 0) return strSin + ' Y ' + Unidades(numUnidades);

        return strSin;
    } //DecenasY()

    function Centenas(num) {
        const centenas = Math.floor(num / 100);
        const decenas = num - centenas * 100;

        switch (centenas) {
            case 1:
                if (decenas > 0) return 'CIENTO ' + Decenas(decenas);
                return 'CIEN';
            case 2:
                return 'DOSCIENTOS ' + Decenas(decenas);
            case 3:
                return 'TRESCIENTOS ' + Decenas(decenas);
            case 4:
                return 'CUATROCIENTOS ' + Decenas(decenas);
            case 5:
                return 'QUINIENTOS ' + Decenas(decenas);
            case 6:
                return 'SEISCIENTOS ' + Decenas(decenas);
            case 7:
                return 'SETECIENTOS ' + Decenas(decenas);
            case 8:
                return 'OCHOCIENTOS ' + Decenas(decenas);
            case 9:
                return 'NOVECIENTOS ' + Decenas(decenas);
        }

        return Decenas(decenas);
    } //Centenas()

    function Seccion(num, divisor, strSingular, strPlural) {
        const cientos = Math.floor(num / divisor);
        const resto = num - cientos * divisor;

        let letras = '';

        if (cientos > 0)
            if (cientos > 1) letras = Centenas(cientos) + ' ' + strPlural;
            else letras = strSingular;

        if (resto > 0) letras += '';

        return letras;
    } //Seccion()

    function Miles(num) {
        const divisor = 1000;
        const cientos = Math.floor(num / divisor);
        const resto = num - cientos * divisor;

        const strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
        const strCentenas = Centenas(resto);

        if (strMiles == '') return strCentenas;

        return strMiles + ' ' + strCentenas;

        //return Seccion(num, divisor, "UN MIL", "MIL") + " " + Centenas(resto);
    } //Miles()

    function Millones(num) {
        const divisor = 1000000;
        const cientos = Math.floor(num / divisor);
        const resto = num - cientos * divisor;

        const strMillones = Seccion(num, divisor, 'UN MILLON', 'MILLONES');
        const strMiles = Miles(resto);

        if (strMillones == '') return strMiles;

        return strMillones + ' ' + strMiles;

        //return Seccion(num, divisor, "UN MILLON", "MILLONES") + " " + Miles(resto);
    } //Millones()

    function NumeroALetras(num) {
        var data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: Math.round(num * 100) - Math.floor(num) * 100,
            letrasCentavos: '',
            letrasMonedaPlural: monedaUtlizada,
            letrasMonedaSingular: monedaUtlizada,
        };
        if (data.centavos == 0) data.letrasCentavos = 'CON 00/100';
        if (data.centavos > 0) data.letrasCentavos = 'CON ' + data.centavos + '/100';

        if (data.enteros == 0) {
            return 'CERO ' + data.letrasCentavos + ' ' + data.letrasMonedaPlural;
        }
        if (data.enteros == 1)
            return (
                Millones(data.enteros) + ' ' + data.letrasCentavos + ' ' + data.letrasMonedaSingular
            );
        else
            return (
                Millones(data.enteros) + ' ' + data.letrasCentavos + ' ' + data.letrasMonedaPlural
            );
    } //NumeroALetras()
}
