import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    getExpense,
    getExpenseDetails,
    resetExpense,
    deleteExpenseDetail,
    resetExpenseDetails,
} from '../../../../store/expense/actions';
import {
    getInvoicesByRequestOrder,
    deactivateInvoice,
    resetInvoicesbyRequestOrderDetails,
} from '../../../../store/invoice/actions'
import {
    getPaymentsByRequestOrder,
    resetPaymentsByRequestOrder,
    deactivatePayment
} from '../../../../store/payment/actions'
import {
    getRequestOdersByExpense,
    getRequestOrderDetails,
    resetRequestOrdersByExpense,
    resetRequestOrderDetails,
} from '../../../../store/requestOrder/actions'
import { IStoreState } from './ExpenseDetail.types';
import { IRootState } from '../../../../common/types';
import ExpenseDetail from './ExpenseDetail';

function mapStateToProps(state: IRootState): IStoreState {
    const
        {
            activeExpense,
            expenseDetailList,
            deleteExpenseDetail,
        } = state.expense,
        {
            invoiceByRequestOrderList,
            deactivateInvoice
        } = state.invoice,
        {
            requestOrderByExpenseList,
            requestOrderDetailList,
        } = state.requestOrder,
        {
            paymentByRequestOrderList,
            deactivatePayment
        } = state.payment;


    return {
        expense: activeExpense.value,
        loading: activeExpense.loading,
        error: activeExpense.error,
        expenseDetails: expenseDetailList.data,
        expenseDetailsLoading: expenseDetailList.loading,
        requestOrderDetailList: requestOrderDetailList.data,
        requestOrderDetailListLoading: requestOrderDetailList.loading,
        requestOrderDetailListError: requestOrderDetailList.error,
        deactivateInvoiceLoading: deactivateInvoice.loading,
        deactivatePaymentLoading: deactivatePayment.loading,
        deleteExpenseDetailSuccess: deleteExpenseDetail.success,
        deleteExpenseDetailError: deleteExpenseDetail.error,
        deactivateInvoiceSuccess: deactivateInvoice.success,
        deactivateInvoiceError: deactivateInvoice.error,
        deactivatePaymentSuccess: deactivatePayment.success,
        deactivatePaymentError: deactivatePayment.error,
        requestOrdersByExpense: requestOrderByExpenseList.data,
        requestOrdersByExpenseLoading: requestOrderByExpenseList.loading,
        invoicesByRequestOrder: invoiceByRequestOrderList.data,
        invoicesByRequestOrderLoading: invoiceByRequestOrderList.loading,
        invoicesByRequestOrderError: invoiceByRequestOrderList.error,
        paymentsByRequestOrder: paymentByRequestOrderList.data,
        paymentsByRequestOrderLoading: paymentByRequestOrderList.loading,
        paymentsByRequestOrderError: paymentByRequestOrderList.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getExpense,
            resetExpense,
            getRequestOrderDetails,
            resetRequestOrderDetails,
            getInvoicesByRequestOrder,
            resetInvoicesbyRequestOrderDetails,
            getPaymentsByRequestOrder,
            resetPaymentsByRequestOrder,
            getRequestOdersByExpense,
            resetRequestOrdersByExpense,
            deleteExpenseDetail,
            deactivateInvoice,
            deactivatePayment,
            getExpenseDetails,
            resetExpenseDetails,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ExpenseDetail);
