import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Menu, Icon } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { IRootState } from '../../../common/types';

interface IOwnProps {
    label: string;
    icon: string;
    onClick?: (param: ClickParam) => void;
    permission?: string;
}

interface IStoreState {
    permissions?: string[];
}

type IProps = IOwnProps & IStoreState;

const TableRowOptionItem: FC<IProps> = props => {
    const handleClick = (param: ClickParam) => {
        if (props.onClick) props.onClick(param);
    };

    const { icon, label, onClick, ...rest } = props;

    const renderContent = () => {
        return (
            <Menu.Item {...rest} onClick={handleClick}>
                <Icon type={props.icon}></Icon>
                <span>{props.label}</span>
            </Menu.Item>
        );
    };

    return !props.permission ||
        (props.permission && props.permissions.includes(props.permission)) ? (
        renderContent()
    ) : (
        <></>
    );
};

function mapStateToProps(state: IRootState): IStoreState {
    return {
        permissions: state.auth.loggedInUser.value.permissions,
    };
}

export default connect(mapStateToProps)(TableRowOptionItem);
