import { IListStatePaginated, IObjectState, IProcessState } from '../../common/types';
import {
    IPettyCash,
    IPettyCashMovement,
    IPettyCashMovementItem,
} from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    pettyCashList: IListStatePaginated<IPettyCash>;
    activePettyCash: IObjectState<IPettyCash>;
    activePettyCashToClose: IObjectState<IPettyCash>;
    createPettyCash: IProcessState;
    pettyCashMovementList: IListStatePaginated<IPettyCashMovement>;
    pettyCashMovementItemList: IListStatePaginated<IPettyCashMovementItem>;
    createPettyCashMovement: IProcessState;
    deletePettyCashMovement: IProcessState;
}

export const initialState: IState = {
    pettyCashList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activePettyCash: { value: undefined, loading: false, error: undefined },
    activePettyCashToClose: { value: undefined, loading: false, error: undefined },
    createPettyCash: { success: false, loading: false, error: undefined },
    pettyCashMovementList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    pettyCashMovementItemList: {
        data: [],
        total: 0,
        pageSize: 0,
        loading: false,
        error: undefined,
    },
    createPettyCashMovement: { success: false, loading: false, error: undefined },
    deletePettyCashMovement: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_PETTY_CASH_LIST]: caseFunctions.getPettyCashList,
    [actionTypes.GET_PETTY_CASH_LIST_SUCCESS]: caseFunctions.getPettyCashListSuccess,
    [actionTypes.GET_PETTY_CASH_LIST_FAILURE]: caseFunctions.getPettyCashListFailure,
    [actionTypes.GET_PETTY_CASH_LIST_RESET]: caseFunctions.getPettyCashListReset,

    [actionTypes.GET_PETTY_CASH]: caseFunctions.getPettyCash,
    [actionTypes.GET_PETTY_CASH_SUCCESS]: caseFunctions.getPettyCashSuccess,
    [actionTypes.GET_PETTY_CASH_FAILURE]: caseFunctions.getPettyCashFailure,
    [actionTypes.GET_PETTY_CASH_RESET]: caseFunctions.getPettyCashReset,

    [actionTypes.GET_PETTY_CASH_TO_CLOSE]: caseFunctions.getPettyCashToClose,
    [actionTypes.GET_PETTY_CASH_TO_CLOSE_SUCCESS]: caseFunctions.getPettyCashToCloseSuccess,
    [actionTypes.GET_PETTY_CASH_TO_CLOSE_FAILURE]: caseFunctions.getPettyCashToCloseFailure,
    [actionTypes.GET_PETTY_CASH_TO_CLOSE_RESET]: caseFunctions.getPettyCashToCloseReset,

    [actionTypes.CREATE_PETTY_CASH]: caseFunctions.createPettyCash,
    [actionTypes.CREATE_PETTY_CASH_SUCCESS]: caseFunctions.createPettyCashSuccess,
    [actionTypes.CREATE_PETTY_CASH_FAILURE]: caseFunctions.createPettyCashFailure,
    [actionTypes.CREATE_PETTY_CASH_RESET]: caseFunctions.createPettyCashReset,

    [actionTypes.GET_PETTY_CASH_MOVEMENTS]: caseFunctions.getPettyCashMovements,
    [actionTypes.GET_PETTY_CASH_MOVEMENTS_SUCCESS]: caseFunctions.getPettyCashMovementsSuccess,
    [actionTypes.GET_PETTY_CASH_MOVEMENTS_FAILURE]: caseFunctions.getPettyCashMovementsFailure,
    [actionTypes.GET_PETTY_CASH_MOVEMENTS_RESET]: caseFunctions.getPettyCashMovementsReset,

    [actionTypes.GET_PETTY_CASH_MOVEMENTS_ITEMS]: caseFunctions.getPettyCashMovementItems,
    [actionTypes.GET_PETTY_CASH_MOVEMENTS_ITEMS_SUCCESS]:
        caseFunctions.getPettyCashMovementItemsSuccess,
    [actionTypes.GET_PETTY_CASH_MOVEMENTS_ITEMS_FAILURE]:
        caseFunctions.getPettyCashMovementItemsFailure,
    [actionTypes.GET_PETTY_CASH_MOVEMENTS_ITEMS_RESET]:
        caseFunctions.getPettyCashMovementItemsReset,

    [actionTypes.CREATE_PETTY_CASH_MOVEMENT]: caseFunctions.createPettyCashMovement,
    [actionTypes.CREATE_PETTY_CASH_MOVEMENT_SUCCESS]: caseFunctions.createPettyCashMovementSuccess,
    [actionTypes.CREATE_PETTY_CASH_MOVEMENT_FAILURE]: caseFunctions.createPettyCashMovementFailure,
    [actionTypes.CREATE_PETTY_CASH_MOVEMENT_RESET]: caseFunctions.createPettyCashMovementReset,

    [actionTypes.DELETE_PETTY_CASH_MOVEMENT]: caseFunctions.deletePettyCashMovement,
    [actionTypes.DELETE_PETTY_CASH_MOVEMENT_SUCCESS]: caseFunctions.deletePettyCashMovementSuccess,
    [actionTypes.DELETE_PETTY_CASH_MOVEMENT_FAILURE]: caseFunctions.deletePettyCashMovementFailure,
    [actionTypes.DELETE_PETTY_CASH_MOVEMENT_RESET]: caseFunctions.deletePettyCashMovementReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
