import * as React from 'react';
import { IProps } from './BankAccountDetail.types';
import { SectionContainer, DisplayItem } from '../../../../components';
import { paths } from '../../../../routes';

export default class PropertyDetail extends React.Component<IProps> {
    componentDidMount() {
        this.props.getBankAccount(this.props.match.params['id']);
    }

    componentWillUnmount() {
        this.props.resetBankAccount();
    }

    renderContent = () => {
        const { bankAccount } = this.props;

        return (
            <>
                <DisplayItem label="N° de cuenta" value={bankAccount.number} />
                <DisplayItem label="N° interbancario" value={bankAccount.interbankNumber} />
                <DisplayItem label="Saldo" type="money" value={bankAccount.balance} />
                <DisplayItem label="Banco" value={bankAccount.bank.name} />
                <DisplayItem label="Tipo" value={bankAccount.type.description} />
                <DisplayItem label="Moneda" value={bankAccount.currency.code} />
            </>
        );
    };

    render() {
        const { bankAccount, loading, error } = this.props;

        return (
            <SectionContainer
                title="Detalle Cuenta Bancaria"
                onBack={() => this.props.history.push(paths.ADMIN_BANK_ACCOUNT_LIST)}
                loading={loading || !bankAccount}
                error={error}
                content={this.renderContent}
            />
        );
    }
}
