import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import { getExpenses, resetExpenses, deleteExpense } from '../../../../store/expense/actions';
import { IStoreState } from './ExpenseList.types';
import ExpenseList from './ExpenseList';

function mapStateToProps(state: IRootState): IStoreState {
    const { expenseList, deleteExpense } = state.expense;

    return {
        expenses: expenseList.data,
        total: expenseList.total,
        pageSize: expenseList.pageSize,
        loading: expenseList.loading,
        error: expenseList.error,
        deleteError: deleteExpense.error,
        deleteLoading: deleteExpense.loading,
        deleteSuccess: deleteExpense.success,
        permissions: state.auth.loggedInUser.value.permissions,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getExpenses,
            resetExpenses,
            deleteExpense,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseList);
