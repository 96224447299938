import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import { listStateLoading, listStateSuccess, listStateFailure } from '../../common/helpers/reducer';

//#region SET CUSTOMERS
export function getCustomers(state: IState): IState {
    return {
        ...state,
        customerList: { ...state.customerList, loading: true, error: null },
    };
}

export function getCustomersSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        customerList: {
            data: action.payload.data,
            total: action.payload.total,
            pageSize: action.payload.pageSize,
            loading: false,
            error: null,
        },
    };
}

export function getCustomersFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        customerList: {
            ...initialState.customerList,
            error: action.payload.error,
        },
    };
}

export function resetGetCustomers(state: IState): IState {
    return {
        ...state,
        customerList: initialState.customerList,
    };
}
//#endregion

//#region SET CUSTOMER
export function getCustomer(state: IState): IState {
    return {
        ...state,
        activeCustomer: { value: undefined, loading: true, error: undefined },
    };
}

export function getCustomerSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activeCustomer: { value: action.payload, loading: false, error: undefined },
    };
}

export function getCustomerFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activeCustomer: { value: undefined, loading: false, error: action.payload.error },
    };
}

export function getCustomerReset(state: IState): IState {
    return {
        ...state,
        activeCustomer: initialState.activeCustomer,
    };
}
//#endregion

//#region CREATE CUSTOMER
export function createCustomer(state: IState): IState {
    return {
        ...state,
        createCustomer: { success: false, loading: true, error: undefined },
    };
}

export function createCustomerSuccess(state: IState): IState {
    return {
        ...state,
        createCustomer: { success: true, loading: false, error: undefined },
    };
}

export function createCustomerFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createCustomer: { success: false, loading: false, error: action.payload.error },
    };
}

export function createCustomerReset(state: IState): IState {
    return {
        ...state,
        createCustomer: initialState.createCustomer,
    };
}
//#endregion

//#region UPDATE CUSTOMER
export function updateCustomer(state: IState): IState {
    return {
        ...state,
        updateCustomer: { success: false, loading: true, error: undefined },
    };
}

export function updateCustomerSuccess(state: IState): IState {
    return {
        ...state,
        updateCustomer: { success: true, loading: false, error: undefined },
    };
}

export function updateCustomerFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateCustomer: { success: false, loading: false, error: action.payload.error },
    };
}

export function updateCustomerReset(state: IState): IState {
    return {
        ...state,
        updateCustomer: initialState.updateCustomer,
    };
}
//#endregion

//#region DEACTIVATE CUSTOMER
export function deactivateCustomer(state: IState): IState {
    return {
        ...state,
        deactivateCustomer: { success: false, loading: true, error: undefined },
    };
}

export function deactivateCustomerSuccess(state: IState): IState {
    return {
        ...state,
        deactivateCustomer: { success: true, loading: false, error: undefined },
    };
}

export function deactivateCustomerFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateCustomer: { success: false, loading: false, error: action.payload.error },
    };
}

export function deactivateCustomerReset(state: IState): IState {
    return {
        ...state,
        deactivateCustomer: initialState.deactivateCustomer,
    };
}
//#endregion

//#region GET PROPERTIES
export function getProperties(state: IState): IState {
    return {
        ...state,
        propertyList: listStateLoading(),
    };
}

export function getPropertiesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        propertyList: listStateSuccess(action.payload.data),
    };
}

export function getPropertiesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        propertyList: listStateFailure(action.payload.error),
    };
}

export function getPropertiesReset(state: IState): IState {
    return {
        ...state,
        propertyList: initialState.propertyList,
    };
}
//#endregion
