import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import { IStoreState } from './InvoiceList.types'
import InvoiceList from './InvoiceList';
import { getProviders, resetProviders } from '../../../../store/provider/actions';
import {
  getInvoicesByProvider,
  resetInvoicesByProvider,
  deactivateInvoiceProvider,
} from '../../../../store/invoice/actions'

function mapStateToProps(state: IRootState): IStoreState {
  const
    { providerList } = state.provider,
    { invoiceByProviderList, deactivateInvoiceProvider } = state.invoice;

  return {
    providers: providerList.data,
    providersLoading: providerList.loading,
    providersError: providerList.error,
    deactivateInvoiceProviderSuccess: deactivateInvoiceProvider.success,
    deactivateInvoiceProviderLoading: deactivateInvoiceProvider.loading,
    deactivateInvoiceProviderError: deactivateInvoiceProvider.error,
    invoicesByProvider: invoiceByProviderList.data,
    total: invoiceByProviderList.total,
    pageSize: invoiceByProviderList.pageSize,
    invoicesByProviderLoading: invoiceByProviderList.loading,
    invoicesByProviderError: invoiceByProviderList.error,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    getProviders,
    getInvoicesByProvider,
    deactivateInvoiceProvider,
    resetInvoicesByProvider,
    resetProviders,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);