import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { formatHelper } from '../../common/helpers';

interface IOwnProps {
    hidden?: boolean;
    label?: string;
    noRow?: boolean;
    value: string | number | JSX.Element | Date;
    lStyles?: React.CSSProperties;
    vStyles?: React.CSSProperties;
    lxs?: number;
    lsm?: number;
    lmd?: number;
    llg?: number;
    lxl?: number;
    lxxl?: number;
    vxs?: number;
    vsm?: number;
    vmd?: number;
    vlg?: number;
    vxl?: number;
    vxxl?: number;
    type?: 'text' | 'image' | 'money' | 'date' | 'datetime';
}

type IProps = IOwnProps;

const DisplayItem: FC<IProps> = props => {
    const {
        value,
        lStyles,
        vStyles,
        hidden,
        label,
        noRow,
        lxs,
        lsm,
        lmd,
        llg,
        lxl,
        lxxl,
        vxs,
        vsm,
        vmd,
        vlg,
        vxl,
        vxxl,
        type,
    } = props;

    const renderValue = () => {
        if (type === 'text')
            return <span style={vStyles}>{value}</span>;
        else if (type === 'image' && typeof value === 'string')
            return <img style={vStyles} src={value} alt="" />;
        else if (type === 'money')
            return <span style={vStyles}>{formatHelper.toMoney(Number(value))}</span>;
        else if (type === 'date')
            return <span style={vStyles}>{formatHelper.toDate(value)}</span>;
        else if (type === 'datetime')
            return <span style={vStyles}>{formatHelper.toDateTime(value)}</span>;
    };

    if (hidden === true) return null;

    const renderContent = () => (
        <>
            <Col xs={lxs} sm={lsm} md={lmd} lg={llg} xl={lxl} xxl={lxxl}>
                <span style={lStyles} className="label">{(label && `${label}:`) || ''}</span>
            </Col>
            <Col xs={vxs} sm={vsm} md={vmd} lg={vlg} xl={vxl} xxl={vxxl}>
                {renderValue()}
            </Col>
        </>
    );

    return noRow ? renderContent() : <Row className="mb-3">{renderContent()}</Row>;
};

DisplayItem.defaultProps = {
    noRow: false,
    type: 'text',
    lxs: 24,
    lsm: 6,
    lmd: 5,
    llg: 4,
    lxl: 4,
    lxxl: 3,
};

export default DisplayItem;
