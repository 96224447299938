const actionTypes = {
    GET_BANK_ACCOUNTS: 'bankAccount/GET_BANK_ACCOUNTS',
    GET_BANK_ACCOUNTS_SUCCESS: 'bankAccount/GET_BANK_ACCOUNTS_SUCCESS',
    GET_BANK_ACCOUNTS_FAILURE: 'bankAccount/GET_BANK_ACCOUNTS_FAILURE',
    GET_BANK_ACCOUNTS_RESET: 'bankAccount/GET_BANK_ACCOUNTS_RESET',

    GET_BANK_ACCOUNTS_BY_CURRENCY: 'bankAccount/GET_BANK_ACCOUNTS_BY_CURRENCY',
    GET_BANK_ACCOUNTS_BY_CURRENCY_SUCCESS: 'bankAccount/GET_BANK_ACCOUNTS_BY_CURRENCY_SUCCESS',
    GET_BANK_ACCOUNTS_BY_CURRENCY_FAILURE: 'bankAccount/GET_BANK_ACCOUNTS_BY_CURRENCY_FAILURE',
    GET_BANK_ACCOUNTS_BY_CURRENCY_RESET: 'bankAccount/GET_BANK_ACCOUNTS_BY_CURRENCY_RESET',

    GET_BANK_ACCOUNT_TRANSACTIONS: 'bankAccount/GET_BANK_ACCOUNT_TRANSACTIONS',
    GET_BANK_ACCOUNT_TRANSACTIONS_SUCCESS: 'bankAccount/GET_BANK_ACCOUNT_TRANSACTIONS_SUCCESS',
    GET_BANK_ACCOUNT_TRANSACTIONS_FAILURE: 'bankAccount/GET_BANK_ACCOUNT_TRANSACTIONS_FAILURE',
    GET_BANK_ACCOUNT_TRANSACTIONS_RESET: 'bankAccount/GET_BANK_ACCOUNT_TRANSACTIONS_RESET',

    GET_BANK_ACCOUNT: 'bankAccount/GET_BANK_ACCOUNT',
    GET_BANK_ACCOUNT_SUCCESS: 'bankAccount/GET_BANK_ACCOUNT_SUCCESS',
    GET_BANK_ACCOUNT_FAILURE: 'bankAccount/GET_BANK_ACCOUNT_FAILURE',
    GET_BANK_ACCOUNT_RESET: 'bankAccount/GET_BANK_ACCOUNT_RESET',

    CREATE_BANK_ACCOUNT_TRANSACTION: 'bankAccount/CREATE_BANK_ACCOUNT_TRANSACTION',
    CREATE_BANK_ACCOUNT_TRANSACTION_SUCCESS: 'bankAccount/CREATE_BANK_ACCOUNT_TRANSACTION_SUCCESS',
    CREATE_BANK_ACCOUNT_TRANSACTION_FAILURE: 'bankAccount/CREATE_BANK_ACCOUNT_TRANSACTION_FAILURE',
    CREATE_BANK_ACCOUNT_TRANSACTION_RESET: 'bankAccount/CREATE_BANK_ACCOUNT_TRANSACTION_RESET',

    CREATE_BANK_ACCOUNT: 'bankAccount/CREATE_BANK_ACCOUNT',
    CREATE_BANK_ACCOUNT_SUCCESS: 'bankAccount/CREATE_BANK_ACCOUNT_SUCCESS',
    CREATE_BANK_ACCOUNT_FAILURE: 'bankAccount/CREATE_BANK_ACCOUNT_FAILURE',
    CREATE_BANK_ACCOUNT_RESET: 'bankAccount/CREATE_BANK_ACCOUNT_RESET',

    UPDATE_BANK_ACCOUNT: 'bankAccount/UPDATE_BANK_ACCOUNT',
    UPDATE_BANK_ACCOUNT_SUCCESS: 'bankAccount/UPDATE_BANK_ACCOUNT_SUCCESS',
    UPDATE_BANK_ACCOUNT_FAILURE: 'bankAccount/UPDATE_BANK_ACCOUNT_FAILURE',
    UPDATE_BANK_ACCOUNT_RESET: 'bankAccount/UPDATE_BANK_ACCOUNT_RESET',

    DEACTIVATE_BANK_ACCOUNT: 'bankAccount/DEACTIVATE_BANK_ACCOUNT',
    DEACTIVATE_BANK_ACCOUNT_SUCCESS: 'bankAccount/DEACTIVATE_BANK_ACCOUNT_SUCCESS',
    DEACTIVATE_BANK_ACCOUNT_FAILURE: 'bankAccount/DEACTIVATE_BANK_ACCOUNT_FAILURE',
    DEACTIVATE_BANK_ACCOUNT_RESET: 'bankAccount/DEACTIVATE_BANK_ACCOUNT_RESET',

    GET_BANK_ACCOUNT_TYPES: 'bankAccount/GET_BANK_ACCOUNT_TYPES',
    GET_BANK_ACCOUNT_TYPES_SUCCESS: 'bankAccount/GET_BANK_ACCOUNT_TYPES_SUCCESS',
    GET_BANK_ACCOUNT_TYPES_FAILURE: 'bankAccount/GET_BANK_ACCOUNT_TYPES_FAILURE',
    GET_BANK_ACCOUNT_TYPES_RESET: 'bankAccount/GET_BANK_ACCOUNT_TYPES_RESET',
};

export default actionTypes;
