import * as React from 'react';
import { IProps, IState } from './IncomeDetail.types';
import { SectionContainer, DisplayItem, DataTable, PBR } from '../../../../components';
import { paths } from '../../../../routes';
import { receiptTypes, incomeStates, permissions } from '../../../../common/constants';
import { Divider, Row, Col, Button, message, Modal } from 'antd';
import { CancelIncomeModal } from './components';
import { columns } from './misc/columns';
import { printHelper, formatHelper } from '../../../../common/helpers';

export default class IncomeDetail extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            cancelModalVisible: false,
        };
    }

    componentDidMount() {
        this.performGetIncome();
    }

    componentWillUnmount() {
        this.props.resetIncome();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkCancelIncome(prevProps);
        this.checkPrintIncome(prevProps);
        this.checkCompletePendingIncome(prevProps);
    }

    checkCancelIncome(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.cancelIncomeSuccess && props.cancelIncomeSuccess) {
            message.success('Ingreso Anulado');
            this.setState({ cancelModalVisible: false });
            this.performGetIncome();
        } else if (!prevProps.cancelIncomeError && props.cancelIncomeError)
            message.error(props.cancelIncomeError);
    }

    checkPrintIncome(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.verifyPrintSuccess && props.verifyPrintSuccess) {
            this.printElectronicBilling();
        } else if (!prevProps.verifyPrintError && props.verifyPrintError)
            message.error(props.verifyPrintError);
    }

    checkCompletePendingIncome(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.completePendingIncomeSuccess && props.completePendingIncomeSuccess) {
            message.success('Ingreso completado');
            this.performGetIncome();
        } else if (!prevProps.completePendingIncomeError && props.completePendingIncomeError)
            message.error(props.completePendingIncomeError);
    }

    performGetIncome() {
        this.props.getIncome(this.props.match.params['id']);
    }

    goToCompletePayment = () => {
        this.props.history.push(paths.ADMIN_INCOME_COMPLETE_PAYMENT);
    };

    printElectronicBilling = () => {
        const { income } = this.props;
        if (income.state.id == incomeStates.COMPLETED) {
            if (income.receiptType.id == receiptTypes.VOUCHER)
                printHelper.printVoucherSales(income);
            else printHelper.printElectronicBilling(income);
        }
    };

    verifyPrintIncome = () => {
        this.props.verifyPrintIncome(this.props.income.id);
    };

    performCancelIncome = (values: any) => {
        const { cancelIncome, match } = this.props;
        cancelIncome(Number(match.params['id']), values);
    };

    performCompletePendingIncome = () => {
        const { completePendingIncomeLoading, income, completePendingIncome } = this.props;

        if (completePendingIncomeLoading) return;

        Modal.confirm({
            title: '¿Deseas completar el ingreso?',
            content: 'Completar ingreso',
            okText: 'Sí',
            okType: 'primary',
            cancelText: 'No',
            onOk() {
                completePendingIncome(income.id);
            },
            onCancel() {},
        });
    };

    renderContent = () => {
        const { income } = this.props;

        const { property, shareholder } = income;
        const isInvoice = income.receiptType.id == receiptTypes.INVOICE;
        // const isBill = income.receiptType.id == receiptTypes.BILL;
        const isVoucher = income.receiptType.id == receiptTypes.VOUCHER;

        return (
            <>
                <DisplayItem
                    label="Código"
                    value={
                        income.electronicBillingCode ? income.electronicBillingCode : income.code
                    }
                />
                <DisplayItem label="Estado" value={income.state.description} />
                {property && <DisplayItem label="Puesto" value={income.property.code} />}
                {shareholder && (
                    <DisplayItem
                        label="Accionista"
                        value={formatHelper.getShareholderFullnameWithCode(shareholder)}
                    />
                )}

                <DisplayItem label="Tipo de Recibo" value={income.receiptType.description} />
                <DisplayItem label="Cliente" value={income.customerFullname} hidden={isInvoice} />
                {/* <DisplayItem
                    label="Comerciante"
                    value={formatHelper.getCustomerDescription(income.customer)}
                /> */}
                <DisplayItem label="RUC" value={income.ruc} hidden={!isInvoice} />
                <DisplayItem label="Razón Social" value={income.businessName} hidden={!isInvoice} />
                <DisplayItem
                    label="Dirección Fiscal"
                    value={income.fiscalAddress}
                    hidden={!isInvoice}
                />
                <DisplayItem label="DNI" value={income.dni} hidden={isInvoice || isVoucher} />
                <DisplayItem label="Tipo de Pago" value={income.paymentType.description} />
                <DisplayItem label="Moneda" value={income.currency.code} />
                {income.bankAccount && (
                    <>
                        <DisplayItem label="Banco" value={income.bankAccount.bank.name} />
                        <DisplayItem label="Nº Cuenta Bancaria" value={income.bankAccount.number} />
                        <DisplayItem
                            label="Nº Cuenta Interbancario"
                            value={income.bankAccount.interbankNumber}
                        />
                    </>
                )}
                {/* <DisplayItem label="Tipo" value={income.type.description} /> */}
                {/* <DisplayItem label="Fecha Expiración" value={income.expirationDate} type="date" /> */}
                <DisplayItem label="Fecha de Pago" value={income.paymentDate} type="datetime" />
                {income.userRegistration && (
                    <DisplayItem
                        label="Usuario Registro"
                        value={`${income.userRegistration.firstname} ${income.userRegistration.lastname}`}
                    />
                )}
                {income.userAnullation && (
                    <DisplayItem
                        label="Usuario Anulación"
                        value={`${income.userAnullation.firstname} ${income.userAnullation.lastname}`}
                    />
                )}
                <DisplayItem label="Precio Total" value={income.totalPrice} type="money" />
                <Divider orientation="left" className="mt-5">
                    Conceptos de Pago
                </Divider>
                <DataTable data={income.incomePaymentItems} columns={columns()} />
                <Row className="mt-5">
                    <Col>
                        <Button
                            type="default"
                            icon="printer"
                            className="mr-2"
                            onClick={this.verifyPrintIncome}
                            loading={this.props.verifyPrintLoading}
                        >
                            Imprimir
                        </Button>
                        {(income.state.id == incomeStates.COMPLETED ||
                            income.state.id === incomeStates.PENDING) && (
                            <PBR permission={permissions.income.ANULL_INCOMES}>
                                <Button
                                    type="danger"
                                    icon="close-circle"
                                    className="mr-2"
                                    onClick={() => this.setState({ cancelModalVisible: true })}
                                    loading={this.props.cancelIncomeLoading}
                                >
                                    Anular
                                </Button>
                            </PBR>
                        )}
                        {income.state.id === incomeStates.PENDING && (
                            <PBR permission={permissions.income.COMPLETE_INCOMES}>
                                <Button
                                    type="primary"
                                    icon="check"
                                    onClick={this.performCompletePendingIncome}
                                    loading={this.props.completePendingIncomeLoading}
                                >
                                    Completar
                                </Button>
                            </PBR>
                        )}
                    </Col>
                </Row>
                <PBR permission={permissions.income.ANULL_INCOMES}>
                    <CancelIncomeModal
                        isOpen={this.state.cancelModalVisible}
                        onCancel={() => this.setState({ cancelModalVisible: false })}
                        onSubmit={this.performCancelIncome}
                        submitting={this.props.cancelIncomeLoading}
                    />
                </PBR>
            </>
        );
    };

    render() {
        const { income, loading, error } = this.props;

        return (
            <SectionContainer
                title="Detalle Ingreso"
                onBack={() => this.props.history.goBack()}
                loading={loading || !income}
                error={error}
                content={this.renderContent}
            />
        );
    }
}
