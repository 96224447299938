import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import {
    getShareholders,
    resetShareholders,
    deactivateShareholder,
    resetDeactivateShareholder,
} from '../../../../store/shareholder/actions';
import { IStoreState } from './ShareholderList.types';
import ShareholderList from './ShareholderList';

function mapStateToProps(state: IRootState): IStoreState {
    const { shareholderList, deactivateShareholder } = state.shareholder;

    return {
        shareholders: shareholderList.data,
        total: shareholderList.total,
        pageSize: shareholderList.pageSize,
        loading: shareholderList.loading,
        error: shareholderList.error,
        deactivateError: deactivateShareholder.error,
        deactivateLoading: deactivateShareholder.loading,
        deactivateSuccess: deactivateShareholder.success,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getShareholders,
            resetShareholders,
            deactivateShareholder,
            resetDeactivateShareholder,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShareholderList);
