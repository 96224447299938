const actionTypes = {
    GET_PAYMENT: 'payment/GET_PAYMENT',
    GET_PAYMENT_SUCCESS: 'payment/GET_PAYMENT_SUCCESS',
    GET_PAYMENT_FAILURE: 'payment/GET_PAYMENT_FAILURE',

    GET_PAYMENTS_BY_PROVIDER: 'payment/GET_PAYMENTS_BY_PROVIDER',
    GET_PAYMENTS_BY_PROVIDER_SUCCESS: 'payment/GET_PAYMENTS_BY_PROVIDER_SUCCESS',
    GET_PAYMENTS_BY_PROVIDER_FAILURE: 'payment/GET_PAYMENTS_BY_PROVIDER_FAILURE',
    GET_PAYMENTS_BY_PROVIDER_RESET: 'payment/GET_PAYMENTS_BY_PROVIDER_RESET',

    GET_PAYMENTS_BY_REQUEST_ORDER: 'payment/GET_PAYMENTS_BY_REQUEST_ORDER',
    GET_PAYMENTS_BY_REQUEST_ORDER_SUCCESS: 'payment/GET_PAYMENTS_BY_REQUEST_ORDER_SUCCESS',
    GET_PAYMENTS_BY_REQUEST_ORDER_FAILURE: 'payment/GET_PAYMENTS_BY_REQUEST_ORDER_FAILURE',
    GET_PAYMENTS_BY_REQUEST_ORDER_RESET: 'payment/GET_PAYMENTS_BY_REQUEST_ORDER_RESET',

    COMPLETE_PAYMENT: 'payment/COMPLETE_PAYMENT',
    COMPLETE_PAYMENT_SUCCESS: 'payment/COMPLETE_PAYMENT_SUCCESS',
    COMPLETE_PAYMENT_FAILURE: 'payment/COMPLETE_PAYMENT_FAILURE',

    COMPLETE_PAYMENT_PROVIDER: 'payment/COMPLETE_PAYMENT_PROVIDER',
    COMPLETE_PAYMENT_PROVIDER_SUCCESS: 'payment/COMPLETE_PAYMENT_PROVIDER_SUCCESS',
    COMPLETE_PAYMENT_PROVIDER_FAILURE: 'payment/COMPLETE_PAYMENT_PROVIDER_FAILURE',

    DEACTIVATE_PAYMENT: 'payment/DEACTIVATE_PAYMENT',
    DEACTIVATE_PAYMENT_SUCCESS: 'payment/DEACTIVATE_PAYMENT_SUCCESS',
    DEACTIVATE_PAYMENT_FAILURE: 'payment/DEACTIVATE_PAYMENT_FAILURE',
    DEACTIVATE_PAYMENT_RESET: 'payment/DEACTIVATE_PAYMENT_RESET',
};

export default actionTypes;
