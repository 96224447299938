import React from 'react';
import { Button, Row, Col, Input, message, Modal } from 'antd';
import { columns } from './misc/columns';
import { IProps, IState } from './BankList.types';
import { SectionContainer, DataTable, PBR } from '../../../../components';
import { IBank } from '../../../../common/types/entities';
import { BankAddEditModal } from './components';
import { IBankAddEditForm } from './components/BankAddEditModal';
import { permissions } from '../../../../common/constants';

export default class UserList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        modalAddEditOpen: false,
    };

    componentDidMount() {
        this.performGetBanks();
    }

    componentWillUnmount() {
        this.props.resetBanks();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkCreactBank(prevProps);
        this.checkUpdateBank(prevProps);
        this.checkDeactivateBank(prevProps);
    }

    async checkDeactivateBank(prevProps: IProps) {
        if (!prevProps.deactivateSuccess && this.props.deactivateSuccess) {
            message.success('Banco eliminado');
            await this.setState({ page: 1 });
            this.performGetBanks();
        } else if (!prevProps.deactivateError && this.props.deactivateError)
            message.error(this.props.deactivateError);
    }

    async checkCreactBank(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.createSuccess && props.createSuccess) {
            message.success('Banco creado');
            await this.setState({ page: 1, modalAddEditOpen: false });
            this.performGetBanks();
        } else if (!prevProps.createError && props.createError) message.error(props.createError);
    }

    async checkUpdateBank(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.updateSuccess && props.updateSuccess) {
            message.success('Banco actualizado');
            await this.setState({ page: 1, modalAddEditOpen: false, bankToEdit: undefined });
            this.performGetBanks();
        } else if (!prevProps.updateError && props.updateError) message.error(props.updateError);
    }

    performGetBanks() {
        const { filter, page } = this.state;
        this.props.getBanks(filter, page);
    }

    async filterBanks() {
        await this.setState({ page: 1 });
        this.performGetBanks();
    }

    perfromDeactivateBank = (id: number) => {
        const { deactivateBank } = this.props;

        Modal.confirm({
            title: '¿Deseas eliminar el banco?',
            content: 'Eliminar banco',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deactivateBank(id);
            },
            onCancel() {},
        });
    };

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetBanks();
    };

    onClickEdit = (bank: IBank) => {
        this.setState({ modalAddEditOpen: true, bankToEdit: bank });
    };

    handleSubmit = (values: IBankAddEditForm) => {
        const { bankToEdit } = this.state;
        const { createBank, updateBank } = this.props;

        if (bankToEdit) {
            updateBank(bankToEdit.id, values);
            return;
        }

        createBank(values);
    };

    render() {
        const { loading, banks, pageSize, total } = this.props;

        return (
            <SectionContainer title="Bancos" icon="bank">
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={e => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterBanks()}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.bank.WRITE_BANKS}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() =>
                                    this.setState({ modalAddEditOpen: true, bankToEdit: undefined })
                                }
                            >
                                Nuevo
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={banks}
                            columns={columns(this.onClickEdit, this.perfromDeactivateBank)}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.bank.WRITE_BANKS}>
                    <BankAddEditModal
                        isOpen={this.state.modalAddEditOpen}
                        onSubmit={this.handleSubmit}
                        onCancel={() => this.setState({ modalAddEditOpen: false })}
                        initialValues={this.state.bankToEdit}
                        submitting={this.props.createLoading || this.props.updateLoading}
                    />
                </PBR>
            </SectionContainer>
        );
    }
}
