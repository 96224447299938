import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStateLoading,
    listStateSuccess,
    listStateFailure,
    processStateLoading,
    processStateSuccess,
    processStateFailure,
} from '../../common/helpers/reducer';

//#region GET EXPENSES
export function getExpenses(state: IState): IState {
    return {
        ...state,
        expenseList: { ...state.expenseList, loading: true, error: null },
    };
}

export function getExpensesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        expenseList: {
            data: action.payload.data,
            total: action.payload.total,
            pageSize: action.payload.pageSize,
            loading: false,
            error: null,
        },
    };
}

export function getExpensesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        expenseList: {
            ...initialState.expenseList,
            error: action.payload.error,
        },
    };
}

export function resetGetExpenses(state: IState): IState {
    return {
        ...state,
        expenseList: initialState.expenseList,
    };
}
//#endregion

//#region SET EXPENSES
export function getExpense(state: IState): IState {
    return {
        ...state,
        activeExpense: { value: undefined, loading: true, error: undefined },
    };
}

export function getExpenseSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activeExpense: { value: action.payload, loading: false, error: undefined },
    };
}

export function getExpenseFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activeExpense: { value: undefined, loading: false, error: action.payload.error },
    };
}

export function getExpenseReset(state: IState): IState {
    return {
        ...state,
        activeExpense: initialState.activeExpense,
    };
}
//#endregion

//#region GET EXPENSE DETAILS
export function getExpenseDetails(state: IState): IState {
    return {
        ...state,
        expenseDetailList: listStateLoading(),
    };
}

export function getExpenseDetailsSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        expenseDetailList: listStateSuccess(action.payload.data),
    };
}

export function getExpenseDetailsFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        expenseDetailList: listStateFailure(action.payload.error),
    };
}

export function getExpenseDetailsReset(state: IState): IState {
    return {
        ...state,
        expenseDetailList: initialState.expenseDetailList,
    };
}
//#endregion

//#region CREATE EXPENSE
export function createExpense(state: IState): IState {
    return {
        ...state,
        createExpense: { success: false, loading: true, error: undefined },
    };
}

export function createExpenseSuccess(state: IState): IState {
    return {
        ...state,
        createExpense: { success: true, loading: false, error: undefined },
    };
}

export function createExpenseFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createExpense: { success: false, loading: false, error: action.payload.error },
    };
}

export function createExpenseReset(state: IState): IState {
    return {
        ...state,
        createExpense: initialState.createExpense,
    };
}
//#endregion

//#region UPDATE EXPENSE
export function updateExpense(state: IState): IState {
    return {
        ...state,
        updateExpense: { success: false, loading: true, error: undefined },
    };
}

export function updateExpenseSuccess(state: IState): IState {
    return {
        ...state,
        updateExpense: { success: true, loading: false, error: undefined },
    };
}

export function updateExpenseFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateExpense: { success: false, loading: false, error: action.payload.error },
    };
}

export function updateExpenseReset(state: IState): IState {
    return {
        ...state,
        updateExpense: initialState.updateExpense,
    };
}
//#endregion

//#region COMPLETE LOGISTIC
export function completeLogistic(state: IState): IState {
    return {
        ...state,
        completeLogistic: { success: false, loading: true, error: undefined },
    };
}

export function completeLogisticSuccess(state: IState): IState {
    return {
        ...state,
        completeLogistic: { success: true, loading: false, error: undefined },
    };
}

export function completeLogisticFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        completeLogistic: { success: false, loading: false, error: action.payload.error },
    };
}
//#endregion

//#region DEACTIVATE EXPENSE
export function deactivateExpense(state: IState): IState {
    return {
        ...state,
        deactivateExpense: { success: false, loading: true, error: undefined },
    };
}

export function deactivateExpenseSuccess(state: IState): IState {
    return {
        ...state,
        deactivateExpense: { success: true, loading: false, error: undefined },
    };
}

export function deactivateExpenseFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateExpense: { success: false, loading: false, error: action.payload.error },
    };
}

export function deactivateExpenseReset(state: IState): IState {
    return {
        ...state,
        deactivateExpense: initialState.deactivateExpense,
    };
}
//#endregion

//#region DELETE EXPENSE
export function deleteExpense(state: IState): IState {
    return {
        ...state,
        deleteExpense: { success: false, loading: true, error: undefined },
    };
}

export function deleteExpenseSuccess(state: IState): IState {
    return {
        ...state,
        deleteExpense: { success: true, loading: false, error: undefined },
    };
}

export function deleteExpenseFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deleteExpense: { success: false, loading: false, error: action.payload.error },
    };
}

export function deleteExpenseReset(state: IState): IState {
    return {
        ...state,
        deleteExpense: initialState.deleteExpense,
    };
}
//#endregion

//#region DELETE EXPENSE DETAIL
export function deleteExpenseDetail(state: IState): IState {
    return {
        ...state,
        deleteExpenseDetail: { success: false, loading: true, error: undefined },
    };
}

export function deleteExpenseDetailSuccess(state: IState): IState {
    return {
        ...state,
        deleteExpenseDetail: { success: true, loading: false, error: undefined },
    };
}

export function deleteExpenseDetailFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deleteExpenseDetail: { success: false, loading: false, error: action.payload.error },
    };
}

export function deleteExpenseDetailReset(state: IState): IState {
    return {
        ...state,
        deleteExpenseDetail: initialState.deleteExpenseDetail,
    };
}
//#endregion

//#region GET EXPENSE TYPES
export function getExpenseTypes(state: IState): IState {
    return {
        ...state,
        typeList: listStateLoading(),
    };
}

export function getExpenseTypesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        typeList: listStateSuccess(action.payload.data),
    };
}

export function getExpenseTypesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        typeList: listStateFailure(action.payload.error),
    };
}

export function getExpenseTypesReset(state: IState): IState {
    return {
        ...state,
        typeList: initialState.typeList,
    };
}
//#endregion

//#region ANSWER EXPENSE
export function answerExpense(state: IState): IState {
    return {
        ...state,
        answerExpense: processStateLoading(),
    };
}

export function answerExpenseSuccess(state: IState): IState {
    return {
        ...state,
        answerExpense: processStateSuccess(),
    };
}

export function answerExpenseFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        answerExpense: processStateFailure(action.payload.error),
    };
}

export function answerExpenseReset(state: IState): IState {
    return {
        ...state,
        answerExpense: initialState.answerExpense,
    };
}
//#endregion

//#region COMPLETE EXPENSE
export function completeExpense(state: IState): IState {
    return {
        ...state,
        completeExpense: processStateLoading(),
    };
}

export function completeExpenseSuccess(state: IState): IState {
    return {
        ...state,
        completeExpense: processStateSuccess(),
    };
}

export function completeExpenseFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        completeExpense: processStateFailure(action.payload.error),
    };
}

export function completeExpenseReset(state: IState): IState {
    return {
        ...state,
        completeExpense: initialState.completeExpense,
    };
}
//#endregion
