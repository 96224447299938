import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { IPayment } from '../../../../../common/types/entities';
import { TableCellItem, TableRowOptionsDropdown } from '../../../../../components';
import { formatHelper } from '../../../../../common/helpers';
import { expenseStates, currencyTypes, permissions } from '../../../../../common/constants';
import { Button } from 'antd';

export const paymentColumns = (
    onClickDetail: (id: number) => void,
    deactivateInvoiceOrPayment: (id: number, type: string) => void,
    currencyId: number,
): ColumnProps<IPayment>[] => {
    return [
        {
            title: 'Cuenta Bancaria',
            dataIndex: 'bankAccount.number',
            className: 'd-none d-md-table-cell',
        },
        {
            title: 'Tipo de Pago',
            dataIndex: 'paymentType.description',
            className: 'd-none d-md-table-cell',
        },
        {
            title: 'Fecha de Pago',
            dataIndex: 'paymentDate',
            className: 'd-none d-md-table-cell',
            render: (paymentDate: any) => <span>{formatHelper.toDate(paymentDate)}</span>,
        },
        {
            title: 'Precio Total',
            // dataIndex: 'totalPrice',
            className: 'd-none d-md-table-cell',
            render: (payment: IPayment) => (
                <span>
                    {payment.totalPrice
                        ? currencyId == currencyTypes.PEN
                            ? formatHelper.toMoney(payment.totalPrice, 'PEN')
                            : formatHelper.toMoney(payment.totalPrice, 'USD')
                        : ''}
                </span>
            ),
        },
        {
            title: 'Motivo de Anulación',
            dataIndex: 'reasonToDelete',
            className: 'd-none d-md-table-cell',
        },
        {
            key: 'xs',
            title: 'Pago',
            dataIndex: '',
            className: 'd-table-cell d-md-none',
            render: (item: IPayment) => (
                <>
                    <TableCellItem label="Cuenta Bancaria" value={item.bankAccount.number} />
                    <TableCellItem label="Tipo de Pago" value={item.paymentType.description} />
                    <TableCellItem label="Fecha de Pago" value={item.paymentDate} type="date" />
                    <TableCellItem
                        label="Precio Total"
                        value={
                            item.totalPrice
                                ? currencyId == currencyTypes.PEN
                                    ? formatHelper.toMoney(item.totalPrice, 'PEN')
                                    : formatHelper.toMoney(item.totalPrice, 'USD')
                                : ''
                        }
                    />
                    <TableCellItem label="Motivo de Anulación" value={item.reasonToDelete} />
                </>
            ),
        },
        {
            key: 'options',
            dataIndex: '',
            render: (item: IPayment) => {
                if (item.active) {
                    return (
                        <TableRowOptionsDropdown
                            onClickDetail={() => onClickDetail(item.id)}
                            onClickCancel={() => deactivateInvoiceOrPayment(item.id, 'payment')}
                            cancelPermission={permissions.expense.WRITE_PAYMENTS}
                        />
                    );
                }

                return (
                    <Button
                        type="primary"
                        shape="round"
                        icon="eye"
                        onClick={() => onClickDetail(item.id)}
                    />
                );
            },
        },
    ];
};
