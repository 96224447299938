import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';
import { IAttachedFile } from '../common/types/entities';

const { STORAGE_URL } = apiUrls;

export function incomePaymentItemImage(file: any) {
    const data = new FormData();
    data.append('file', file);

    return APIManager.post<IAttachedFile>(`${STORAGE_URL}/income-payment-items/image`, { data });
}
