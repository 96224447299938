import React, { FC } from 'react';
import { Select } from 'antd';
import { Field, FieldProps } from 'formik';
import { getPropertyByKeyPath } from '../../../common/utils';
import { SelectProps } from 'antd/lib/select';
import { FieldMessage } from '../..';

interface IOwnProps {
    name: string;
    data: any[];
    displayValue?: string;
    displayText: string | ((item) => string);
}

export type ISelectFieldProps = IOwnProps & SelectProps;

const SelectField: FC<ISelectFieldProps> = ({
    name,
    data,
    displayValue,
    displayText,
    onChange,
    onBlur,
    className,
    filterOption,
    ...restProps
}) => {
    const renderOptions = () => {
        if (!data) return;

        return data.map(item => {
            const identifier = getPropertyByKeyPath(item, displayValue);

            return (
                <Select.Option key={identifier} value={identifier}>
                    {typeof displayText === 'string'
                        ? getPropertyByKeyPath(item, displayText)
                        : displayText(item)}
                </Select.Option>
            );
        });
    };

    const filterOptionDefault = (input, option) =>
        option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0;

    return (
        <Field name={name}>
            {({
                field: { value },
                form: { errors, setFieldValue, setFieldTouched },
            }: FieldProps) => {
                const error = getPropertyByKeyPath(errors, name);
                const hasError = error != undefined;
                return (
                    <>
                        <Select
                            value={value}
                            onChange={(value, option) => {
                                setFieldValue(name, value);
                                onChange && onChange(value, option);
                            }}
                            onBlur={value => {
                                setFieldTouched(name);
                                onBlur && onBlur(value);
                            }}
                            className={`form-input ${hasError ? 'has-error' : ''} ${className}`}
                            filterOption={filterOption || filterOptionDefault}
                            {...restProps}
                        >
                            {renderOptions()}
                        </Select>
                        <FieldMessage show={hasError} message={error} />
                    </>
                );
            }}
        </Field>
    );
};

SelectField.defaultProps = {
    displayValue: 'id',
    showSearch: true,
    allowClear: true,
};

export default SelectField;
