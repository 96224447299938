import { IObjectState, IProcessState, IListState, IListStatePaginated } from '../../common/types';
import { IPayment } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    activePayment: IObjectState<IPayment>;
    paymentByRequestOrderList: IListState<IPayment>;
    paymentByProviderList: IListStatePaginated<IPayment>;
    completePayment: IProcessState;
    completePaymentProvider: IProcessState;
    deactivatePayment: IProcessState;
}

export const initialState: IState = {
    activePayment: { value: undefined, loading: false, error: undefined },
    paymentByRequestOrderList: { data: [], loading: false, error: undefined },
    paymentByProviderList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    completePayment: { success: false, loading: false, error: undefined },
    completePaymentProvider: { success: false, loading: false, error: undefined },
    deactivatePayment: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_PAYMENT]: caseFunctions.getPayment,
    [actionTypes.GET_PAYMENT_SUCCESS]: caseFunctions.getPaymentSuccess,
    [actionTypes.GET_PAYMENT_FAILURE]: caseFunctions.getPaymentFailure,

    [actionTypes.GET_PAYMENTS_BY_REQUEST_ORDER]: caseFunctions.getPaymentsByRequestOrder,
    [actionTypes.GET_PAYMENTS_BY_REQUEST_ORDER_SUCCESS]:
        caseFunctions.getPaymentsByRequestOrderSuccess,
    [actionTypes.GET_PAYMENTS_BY_REQUEST_ORDER_FAILURE]:
        caseFunctions.getPaymentsByRequestOrderFailure,
    [actionTypes.GET_PAYMENTS_BY_REQUEST_ORDER_RESET]: caseFunctions.getPaymentsByRequestOrderReset,

    [actionTypes.GET_PAYMENTS_BY_PROVIDER]: caseFunctions.getPaymentsByProvider,
    [actionTypes.GET_PAYMENTS_BY_PROVIDER_SUCCESS]: caseFunctions.getPaymentsByProviderSuccess,
    [actionTypes.GET_PAYMENTS_BY_PROVIDER_FAILURE]: caseFunctions.getPaymentsByProviderFailure,
    [actionTypes.GET_PAYMENTS_BY_PROVIDER_RESET]: caseFunctions.getPaymentsByProviderReset,

    [actionTypes.COMPLETE_PAYMENT]: caseFunctions.completePayment,
    [actionTypes.COMPLETE_PAYMENT_SUCCESS]: caseFunctions.completePaymentSuccess,
    [actionTypes.COMPLETE_PAYMENT_FAILURE]: caseFunctions.completePaymentFailure,

    [actionTypes.COMPLETE_PAYMENT_PROVIDER]: caseFunctions.completePaymentProvider,
    [actionTypes.COMPLETE_PAYMENT_PROVIDER_SUCCESS]: caseFunctions.completePaymentProviderSuccess,
    [actionTypes.COMPLETE_PAYMENT_PROVIDER_FAILURE]: caseFunctions.completePaymentProviderFailure,

    [actionTypes.DEACTIVATE_PAYMENT]: caseFunctions.deactivatePayment,
    [actionTypes.DEACTIVATE_PAYMENT_SUCCESS]: caseFunctions.deactivatePaymentSuccess,
    [actionTypes.DEACTIVATE_PAYMENT_FAILURE]: caseFunctions.deactivatePaymentFailure,
    [actionTypes.DEACTIVATE_PAYMENT_RESET]: caseFunctions.deactivatePaymentReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
