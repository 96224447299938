import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
    listStateLoading,
    listStateSuccess,
    listStateFailure,
    processStateLoading,
    processStateSuccess,
    processStateFailure,
} from '../../common/helpers/reducer';

//#region SET SHAREHOLDERS
export function getShareholders(state: IState): IState {
    return {
        ...state,
        shareholderList: listStatePaginatedLoading(state.shareholderList),
    };
}

export function getShareholdersSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        shareholderList: listStatePaginatedSuccess(action.payload),
    };
}

export function getShareholdersFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        shareholderList: listStatePaginatedFailure(
            initialState.shareholderList,
            action.payload.error,
        ),
    };
}

export function getShareholdersReset(state: IState): IState {
    return {
        ...state,
        shareholderList: initialState.shareholderList,
    };
}
//#endregion

//#region SET SHAREHOLDER
export function getShareholder(state: IState): IState {
    return {
        ...state,
        activeShareholder: { value: undefined, loading: true, error: undefined },
    };
}

export function getShareholderSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activeShareholder: { value: action.payload, loading: false, error: undefined },
    };
}

export function getShareholderFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activeShareholder: { value: undefined, loading: false, error: action.payload.error },
    };
}

export function getShareholderReset(state: IState): IState {
    return {
        ...state,
        activeShareholder: initialState.activeShareholder,
    };
}
//#endregion

//#region CREATE SHAREHOLDER
export function createShareholder(state: IState): IState {
    return {
        ...state,
        createShareholder: { success: false, loading: true, error: undefined },
    };
}

export function createShareholderSuccess(state: IState): IState {
    return {
        ...state,
        createShareholder: { success: true, loading: false, error: undefined },
    };
}

export function createShareholderFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createShareholder: { success: false, loading: false, error: action.payload.error },
    };
}

export function createShareholderReset(state: IState): IState {
    return {
        ...state,
        createShareholder: initialState.createShareholder,
    };
}
//#endregion

//#region UPDATE SHAREHOLDER
export function updateShareholder(state: IState): IState {
    return {
        ...state,
        updateShareholder: { success: false, loading: true, error: undefined },
    };
}

export function updateShareholderSuccess(state: IState): IState {
    return {
        ...state,
        updateShareholder: { success: true, loading: false, error: undefined },
    };
}

export function updateShareholderFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateShareholder: { success: false, loading: false, error: action.payload.error },
    };
}

export function updateShareholderReset(state: IState): IState {
    return {
        ...state,
        updateShareholder: initialState.updateShareholder,
    };
}
//#endregion

//#region DEACTIVATE SHAREHOLDER
export function deactivateShareholder(state: IState): IState {
    return {
        ...state,
        deactivateShareholder: { success: false, loading: true, error: undefined },
    };
}

export function deactivateShareholderSuccess(state: IState): IState {
    return {
        ...state,
        deactivateShareholder: { success: true, loading: false, error: undefined },
    };
}

export function deactivateShareholderFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateShareholder: { success: false, loading: false, error: action.payload.error },
    };
}

export function deactivateShareholderReset(state: IState): IState {
    return {
        ...state,
        deactivateShareholder: initialState.deactivateShareholder,
    };
}
//#endregion

//#region GET SHAREHOLDER PROPERTIES
export function getProperties(state: IState): IState {
    return {
        ...state,
        shareholderPropertyList: listStatePaginatedLoading(state.shareholderPropertyList),
    };
}

export function getPropertiesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        shareholderPropertyList: listStatePaginatedSuccess(action.payload),
    };
}

export function getPropertiesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        shareholderPropertyList: listStatePaginatedFailure(
            initialState.shareholderPropertyList,
            action.payload.error,
        ),
    };
}

export function getPropertiesReset(state: IState): IState {
    return {
        ...state,
        shareholderPropertyList: initialState.shareholderPropertyList,
    };
}
//#endregion

//#region GET COOWNERS
export function getCoOwners(state: IState): IState {
    return {
        ...state,
        coOwnerList: listStateLoading(),
    };
}

export function getCoOwnersSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        coOwnerList: listStateSuccess(action.payload.data),
    };
}

export function getCoOwnersFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        coOwnerList: listStateFailure(action.payload.error),
    };
}

export function getCoOwnersReset(state: IState): IState {
    return {
        ...state,
        coOwnerList: initialState.coOwnerList,
    };
}
//#endregion

//#region CREATE COOWNER
export function createCoOwner(state: IState): IState {
    return {
        ...state,
        createCoOwner: processStateLoading(),
    };
}

export function createCoOwnerSuccess(state: IState): IState {
    return {
        ...state,
        createCoOwner: processStateSuccess(),
    };
}

export function createCoOwnerFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createCoOwner: processStateFailure(action.payload.error),
    };
}

export function createCoOwnerReset(state: IState): IState {
    return {
        ...state,
        createCoOwner: initialState.createCoOwner,
    };
}
//#endregion

//#region CREATE COOWNER
export function updateCoOwner(state: IState): IState {
    return {
        ...state,
        updateCoOwner: processStateLoading(),
    };
}

export function updateCoOwnerSuccess(state: IState): IState {
    return {
        ...state,
        updateCoOwner: processStateSuccess(),
    };
}

export function updateCoOwnerFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateCoOwner: processStateFailure(action.payload.error),
    };
}

export function updateCoOwnerReset(state: IState): IState {
    return {
        ...state,
        updateCoOwner: initialState.updateCoOwner,
    };
}
//#endregion

//#region DEACTIVATE COOWNER
export function deactivateCoOwner(state: IState): IState {
    return {
        ...state,
        deactivateCoOwner: processStateLoading(),
    };
}

export function deactivateCoOwnerSuccess(state: IState): IState {
    return {
        ...state,
        deactivateCoOwner: processStateSuccess(),
    };
}

export function deactivateCoOwnerFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateCoOwner: processStateFailure(action.payload.error),
    };
}

export function deactivateCoOwnerReset(state: IState): IState {
    return {
        ...state,
        deactivateCoOwner: initialState.deactivateCoOwner,
    };
}
//#endregion
