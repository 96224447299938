const BASE_URL = 'https://sistema-unicachi.ccunicachi.com.pe/api';

export const apiUrls = {
    AUTH_URL: `${BASE_URL}/auth`,
    USER_URL: `${BASE_URL}/users`,
    CUSTOMER_URL: `${BASE_URL}/customers`,
    ZONE_URL: `${BASE_URL}/zones`,
    SUBZONE_URL: `${BASE_URL}/subzones`,
    PROPERTY_URL: `${BASE_URL}/properties`,
    CONFIGURE_URL: `${BASE_URL}/configures`,
    BANK_URL: `${BASE_URL}/banks`,
    BANK_ACCOUNT_URL: `${BASE_URL}/bank-accounts`,
    BANK_ACCOUNT_TRANSACTION_URL: `${BASE_URL}/bank-account-transactions`,
    CURRENCY_URL: `${BASE_URL}/currencies`,
    PROVIDER_URL: `${BASE_URL}/providers`,
    INCOME_URL: `${BASE_URL}/incomes`,
    PAYMENT_ITEM_URL: `${BASE_URL}/payment-items`,
    COMMON_REQUEST_URL: `${BASE_URL}/common-requests`,
    SHAREHOLDER_URL: `${BASE_URL}/shareholders`,
    EXPENSE_URL: `${BASE_URL}/expenses`,
    INVOICE_URL: `${BASE_URL}/invoices`,
    PAYMENT_URL: `${BASE_URL}/payments`,
    REQUEST_ORDER_URL: `${BASE_URL}/request-order`,
    TAX_URL: `${BASE_URL}/taxes`,
    REPORT_URL: `${BASE_URL}/reports`,
    STORAGE_URL: `${BASE_URL}/storage`,
    PETTY_CASH_URL: `${BASE_URL}/petty-cash`,
};
