import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './AddIncomePaymentItemModal.types';
import AddIncomePaymentItemModal from './AddIncomePaymentItemModal';
import { IRootState } from '../../../../../../common/types';
import { getPaymentItems, resetPaymentItems } from '../../../../../../store/paymentItem/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { paymentItemList } = state.paymentItem;

    return {
        paymentItems: paymentItemList.data,
        paymentItemsLoading: paymentItemList.loading,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getPaymentItems,
            resetPaymentItems,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddIncomePaymentItemModal);
