import React from 'react';
import { SectionContainer } from '../../../../components';
import { IProps } from './PropertyAddEdit.types';
import { message } from 'antd';
import { paths } from '../../../../routes';
import { PropertyForm } from './components';

export default class PropertyAddEdit extends React.Component<IProps> {
    private propertyId = this.props.match.params['id'];

    componentDidMount() {
        this.props.getZones();
        this.props.getSubzones();
        if (this.propertyId) this.props.getProperty(this.propertyId);
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkUpdateBankAccount(prevProps);
        this.checkCreateProperty(prevProps);
    }

    componentWillUnmount() {
        this.props.resetZones();
        this.props.resetSubzones();
        this.props.resetProperty();
    }

    checkCreateProperty(prevProps: IProps) {
        if (!prevProps.createSuccess && this.props.createSuccess) {
            message.success('Puesto creado');
            this.goToPropertyList();
        } else if (!prevProps.createError && this.props.createError)
            message.error(this.props.createError);
    }

    checkUpdateBankAccount(prevProps: IProps) {
        if (!prevProps.updateSuccess && this.props.updateSuccess) {
            message.success('Puesto actualizado');
            this.goToPropertyList();
        } else if (!prevProps.updateError && this.props.updateError)
            message.error(this.props.updateError);
    }

    goToPropertyList = () => {
        this.props.history.push(paths.ADMIN_PROPERTY_LIST);
    };

    onSubmit = (values: any) => {
        if (this.propertyId) return this.props.updateProperty(this.propertyId, values);
        return this.props.createProperty(values);
    };

    renderForm = () => {
        const {
            activeProperty,
            activePropertyLoading,
            createLoading,
            updateLoading,
            zones,
            zonesLoading,
            subzones,
            subzonesLoading,
        } = this.props;

        return (
            <PropertyForm
                initialValues={activeProperty || {}}
                zones={zones}
                zonesLoading={zonesLoading}
                subzones={subzones}
                subzonesLoading={subzonesLoading}
                onSubmit={this.onSubmit}
                disabled={activePropertyLoading}
                submitting={createLoading || updateLoading}
            />
        );
    };

    render() {
        const { activePropertyLoading, activePropertyError } = this.props;

        return (
            <SectionContainer
                title={this.propertyId ? 'Editar Puesto' : 'Nuevo Puesto'}
                onBack={this.goToPropertyList}
                loading={activePropertyLoading}
                error={activePropertyError}
                content={this.renderForm}
            />
        );
    }
}
