import { IObjectState, IProcessState, IListState, IListStatePaginated } from '../../common/types';
import { IInvoice } from '../../common/types/entities';
import actionTypes from './actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';
import authActionTypes from '../auth/actionTypes';

export interface IState {
    activeInvoice: IObjectState<IInvoice>;
    invoiceByRequestOrderList: IListState<IInvoice>;
    invoiceByRequestOrderDetailList: IListState<IInvoice>;
    invoiceByProviderList: IListStatePaginated<IInvoice>;
    invoiceByProviderDetailList: IListState<IInvoice>;
    completeInvoice: IProcessState;
    completeInvoiceProvider: IProcessState;
    deactivateInvoice: IProcessState;
    deactivateInvoiceProvider: IProcessState;
}

export const initialState: IState = {
    activeInvoice: { value: undefined, loading: false, error: undefined },
    invoiceByRequestOrderList: { data: [], loading: false, error: undefined },
    invoiceByRequestOrderDetailList: { data: [], loading: false, error: undefined },
    invoiceByProviderList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    invoiceByProviderDetailList: { data: [], loading: false, error: undefined },
    completeInvoice: { success: false, loading: false, error: undefined },
    completeInvoiceProvider: { success: false, loading: false, error: undefined },
    deactivateInvoice: { success: false, loading: false, error: undefined },
    deactivateInvoiceProvider: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_INVOICES_BY_REQUEST_ORDER]: caseFunctions.getInvoicesByRequestOrder,
    [actionTypes.GET_INVOICES_BY_REQUEST_ORDER_SUCCESS]:
        caseFunctions.getInvoicesByRequestOrderSuccess,
    [actionTypes.GET_INVOICES_BY_REQUEST_ORDER_FAILURE]:
        caseFunctions.getInvoicesByRequestOrderFailure,
    [actionTypes.GET_INVOICES_BY_REQUEST_ORDER_RESET]: caseFunctions.getInvoicesByRequestOrderReset,

    [actionTypes.GET_INVOICES_BY_REQUEST_ORDER_DETAILS]: caseFunctions.getInvoicesByRequestOrder,
    [actionTypes.GET_INVOICES_BY_REQUEST_ORDER_DETAILS_SUCCESS]:
        caseFunctions.getInvoicesByRequestOrderSuccess,
    [actionTypes.GET_INVOICES_BY_REQUEST_ORDER_DETAILS_FAILURE]:
        caseFunctions.getInvoicesByRequestOrderFailure,
    [actionTypes.GET_INVOICES_BY_REQUEST_ORDER_DETAILS_RESET]:
        caseFunctions.getInvoicesByRequestOrderReset,

    [actionTypes.GET_INVOICES_BY_PROVIDER]: caseFunctions.getInvoicesByProvider,
    [actionTypes.GET_INVOICES_BY_PROVIDER_SUCCESS]: caseFunctions.getInvoicesByProviderSuccess,
    [actionTypes.GET_INVOICES_BY_PROVIDER_FAILURE]: caseFunctions.getInvoicesByProviderFailure,
    [actionTypes.GET_INVOICES_BY_PROVIDER_RESET]: caseFunctions.getInvoicesByProviderReset,

    [actionTypes.GET_INVOICES_BY_PROVIDER_DETAILS]: caseFunctions.getInvoicesByProviderDetails,
    [actionTypes.GET_INVOICES_BY_PROVIDER_DETAILS_SUCCESS]:
        caseFunctions.getInvoicesByProviderDetailsSuccess,
    [actionTypes.GET_INVOICES_BY_PROVIDER_DETAILS_FAILURE]:
        caseFunctions.getInvoicesByProviderDetailsFailure,
    [actionTypes.GET_INVOICES_BY_PROVIDER_DETAILS_RESET]:
        caseFunctions.getInvoicesByProviderDetailsReset,

    [actionTypes.GET_INVOICE]: caseFunctions.getInvoice,
    [actionTypes.GET_INVOICE_SUCCESS]: caseFunctions.getInvoiceSuccess,
    [actionTypes.GET_INVOICE_FAILURE]: caseFunctions.getInvoiceFailure,

    [actionTypes.COMPLETE_INVOICE]: caseFunctions.completeInvoice,
    [actionTypes.COMPLETE_INVOICE_SUCCESS]: caseFunctions.completeInvoiceSuccess,
    [actionTypes.COMPLETE_INVOICE_FAILURE]: caseFunctions.completeInvoiceFailure,

    [actionTypes.COMPLETE_INVOICE_PROVIDER]: caseFunctions.completeInvoiceProvider,
    [actionTypes.COMPLETE_INVOICE_PROVIDER_SUCCESS]: caseFunctions.completeInvoiceProviderSuccess,
    [actionTypes.COMPLETE_INVOICE_PROVIDER_FAILURE]: caseFunctions.completeInvoiceProviderFailure,

    [actionTypes.DEACTIVATE_INVOICE]: caseFunctions.deactivateInvoice,
    [actionTypes.DEACTIVATE_INVOICE_SUCCESS]: caseFunctions.deactivateInvoiceSuccess,
    [actionTypes.DEACTIVATE_INVOICE_FAILURE]: caseFunctions.deactivateInvoiceFailure,
    [actionTypes.DEACTIVATE_INVOICE_RESET]: caseFunctions.deactivateInvoiceReset,

    [actionTypes.DEACTIVATE_INVOICE_PROVIDER]: caseFunctions.deactivateInvoiceProvider,
    [actionTypes.DEACTIVATE_INVOICE_PROVIDER_SUCCESS]:
        caseFunctions.deactivateInvoiceProviderSuccess,
    [actionTypes.DEACTIVATE_INVOICE_PROVIDER_FAILURE]:
        caseFunctions.deactivateInvoiceProviderFailure,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
