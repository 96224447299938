import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Modal, Radio } from 'antd';
import { Formik, Form, FormikProps, FormikActions } from 'formik';
import { isEmpty, isNumeric } from 'validator';
import { messages, discountTypes, roles } from '../../../../../../common/constants';
import { InputGroupField, DisplayItem, RadioGroupField } from '../../../../../../components';
import { IProps, IAddIncomePaymetItemForm } from './CondonePaymentItemModal.types';

const CondonePaymentItemModal: FunctionComponent<IProps> = props => {
    let form = useRef<Formik<IAddIncomePaymetItemForm>>(null);
    const [previewCondoned, setPreviewCondoned] = useState<number>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (!props.isOpen) return;

        return () => {
            setPreviewCondoned(null);
        };
    }, [props.isOpen]);

    const validate = (values: IAddIncomePaymetItemForm) => {
        const { id: roleID } = props.loggedInUser.role;

        let errors: Partial<IAddIncomePaymetItemForm> = {};

        if (!values.condonedDiscountType.id)
            errors.condonedDiscountType = { id: messages.MANDATORY_FIELD };

        if (isEmpty(values.totalCodoned)) errors.totalCodoned = messages.MANDATORY_FIELD;
        else if (!isNumeric(values.totalCodoned))
            errors.totalCodoned = 'El monto debe ser un número';
        else if (Number(values.totalCodoned) < 0)
            errors.totalCodoned = 'El monto debe ser mayor a cero';

        if (roleID != roles.SUPER_ADMIN && roleID != roles.ADMIN) {
            if (isEmpty(values.authorizationToken))
                errors.authorizationToken = messages.MANDATORY_FIELD;
        }

        return errors;
    };

    const onOk = () => {
        form.current.submitForm();
    };

    const handleOnCancel = e => {
        form.current.resetForm();
        props.onCancel(e);
    };

    const onSubmit = (
        values: IAddIncomePaymetItemForm,
        actions: FormikActions<IAddIncomePaymetItemForm>,
    ) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const onChangeType = () => {
        form.current.setFieldValue('totalCodoned', '');
        setPreviewCondoned(null);
    };

    const onChangeTotalCondoned = e => {
        const total = Number(e.target.value);
        const typeId = form.current.state.values.condonedDiscountType.id;
        const { price } = props.incomePaymentItem;

        let result = 0;

        if (typeId == discountTypes.MONEY) result = price - total;
        else if (typeId == discountTypes.PERCENT) result = price * (1 - total / 100);

        setPreviewCondoned(result);
        setCanSubmit(result > 0 && result <= props.incomePaymentItem.price);
    };

    const renderForm = () => {
        if (!props.isOpen) return null;
        const { id: roleID } = props.loggedInUser.role;

        return (
            <Formik
                initialValues={{
                    condonedDiscountType: { id: discountTypes.MONEY },
                    totalCodoned: '',
                    condoneComment: '',
                    authorizationToken: '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={(formikBag: FormikProps<IAddIncomePaymetItemForm>) => (
                    <Form>
                        <DisplayItem
                            label="Concepto"
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            value={props.incomePaymentItem.paymentItem.description}
                        />
                        <DisplayItem
                            label="Precio"
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            value={props.incomePaymentItem.price}
                            type="money"
                        />
                        <RadioGroupField
                            name="condonedDiscountType.id"
                            label="Tipo"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                            onChange={onChangeType}
                        >
                            <Radio value={discountTypes.MONEY}>Dinero</Radio>
                            <Radio value={discountTypes.PERCENT}>Porcentaje</Radio>
                        </RadioGroupField>
                        <InputGroupField
                            name="totalCodoned"
                            label="Valor"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                            onChange={onChangeTotalCondoned}
                        />
                        <InputGroupField
                            name="condoneComment"
                            label="Comentario"
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        {roleID != roles.SUPER_ADMIN && roleID != roles.ADMIN && (
                            <InputGroupField
                                name="authorizationToken"
                                label="Token de autorización"
                                required
                                lxs={24}
                                lsm={24}
                                lmd={24}
                                llg={24}
                                lxl={6}
                                lxxl={6}
                                imd={24}
                                ixl={18}
                                ilg={24}
                            />
                        )}
                        <DisplayItem
                            label="Precio final"
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            value={previewCondoned || ''}
                            type="money"
                        />
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            title="Condonar Multa"
            visible={props.isOpen}
            okText="Guardar"
            onOk={onOk}
            okButtonProps={{ loading: props.submitting, disabled: !canSubmit }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.submitting }}
        >
            {renderForm()}
        </Modal>
    );
};

export default CondonePaymentItemModal;
