import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import { IMenuItem, IRootState } from '../../../common/types';
import { menuItems } from './misc/menuItems';
import MainHeader from './components/MainHeader';

const { SubMenu } = Menu;
const { Content, Sider, Footer } = Layout;

interface IStoreState {
    permissions: string[];
}

type IProps = IStoreState & RouteComponentProps;

const MainLayout: React.FC<IProps> = props => {
    const [collapsed, setCollapsed] = useState(false);
    const [collapsedWidth, setCollapsedWidth] = useState(80);
    const [broken, setBroken] = useState(false);

    const toogleSidebar = () => {
        setCollapsed(!collapsed);
    };

    const onSiderBreakpoint = (value: boolean) => {
        if (value) {
            setCollapsed(true);
            setCollapsedWidth(0);
        } else {
            setCollapsed(false);
            setCollapsedWidth(80);
        }

        setBroken(value);
    };

    const isSelected = (pathname: string, basepath: string) => {
        return pathname.includes(basepath);
    };

    const getSelectedKeys = (items: IMenuItem[], pathname: string) => {
        let selectedKeys: string[] = [];

        items.map(item => {
            if (item.basepath) {
                if (isSelected(pathname, item.basepath)) {
                    selectedKeys.push(item.key ? item.key : item.path);
                }
            } else {
                if (item.path === pathname || item.key === pathname) {
                    selectedKeys.push(item.key ? item.key : item.path);
                }
            }
            if (item.items) {
                const itemKeys = getSelectedKeys(item.items, pathname);
                selectedKeys.push(...itemKeys);
            }
        });

        return selectedKeys;
    };

    const renderMenuItem = (item: IMenuItem) => {
        const { label, path, items, icon, key, ...rest } = item;

        if (
            (!item.permission && !item.permissions) ||
            (item.permission && props.permissions.includes(item.permission)) ||
            (item.permissions && props.permissions.some(p => item.permissions.includes(p)))
        ) {
            if (item.items) {
                const title = (
                    <span>
                        {icon && <Icon type={icon} />}
                        <span>{label}</span>
                    </span>
                );
                return (
                    <SubMenu key={key || path} title={title}>
                        {items.map(renderMenuItem)}
                    </SubMenu>
                );
            }

            return (
                <Menu.Item key={key || path} {...rest} onClick={() => goToPath(path)}>
                    {icon && <Icon type={icon} />}
                    <span>{label}</span>
                </Menu.Item>
            );
        }
    };

    const renderMenu = () => {
        const { pathname } = props.location;
        const selectedOrOpenKeys = getSelectedKeys(menuItems, pathname);

        return (
            <Menu
                theme="dark"
                defaultOpenKeys={selectedOrOpenKeys}
                defaultSelectedKeys={selectedOrOpenKeys}
                selectedKeys={selectedOrOpenKeys}
                mode="inline"
                // style={{ overflowY: 'scroll', height: '88vh' }}
            >
                {menuItems.map(renderMenuItem)}
            </Menu>
        );
    };

    const goToPath = (path: string) => {
        props.history.push(path);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                theme="dark"
                trigger={null}
                collapsible
                collapsed={collapsed}
                breakpoint="lg"
                collapsedWidth={collapsedWidth}
                onBreakpoint={onSiderBreakpoint}
                className={broken ? 'fixed-sidebar' : ''}
                defaultCollapsed={true}
                width={256}
            >
                <div className="logo" onClick={() => props.history.push('/')}>
                    <span>{collapsed ? 'U' : 'Unicachi'}</span>
                </div>
                {renderMenu()}
            </Sider>
            {broken && !collapsed && (
                <div className="sidebar-content-closer" onClick={toogleSidebar} />
            )}
            <Layout>
                <MainHeader onClickMenu={toogleSidebar} collapsed={collapsed} />
                <Content className="mx-3">
                    {/* <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Administración</Breadcrumb.Item>
            <Breadcrumb.Item>Productos</Breadcrumb.Item>
          </Breadcrumb> */}

                    {props.children}
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Powered by{' '}
                    <a target="_blank" href="https://www.blazarlabs.io/">
                        Blazar Labs
                    </a>
                </Footer>
            </Layout>
        </Layout>
    );
};

function mapStateToProps(state: IRootState): IStoreState {
    return {
        permissions: state.auth.loggedInUser.value.permissions,
    };
}

export default connect(mapStateToProps)(withRouter(MainLayout));
