import shajs from 'sha';
import * as qz from 'qz-tray';
import { IProperty, IIncomePaymentItem, IShareholder } from '../../types/entities';
import { formatHelper } from '..';
import {
    printLeftAndRight,
    centerAlign,
    smallFont,
    lineBreak,
    leftAlign,
    kickOutCashDrawer,
    cutPaper,
} from './base';

export function printTicketSales({
    property,
    shareholder,
    incomePaymentItems,
}: {
    incomePaymentItems: IIncomePaymentItem[];
    property?: IProperty;
    shareholder?: IShareholder;
}) {
    qz.api.setPromiseType(function promise(resolver) {
        return new Promise(resolver);
    });

    qz.api.setSha256Type(function (data) {
        return shajs.stream(data, { algorithm: 'sha256' });
    });

    qz.websocket
        .connect()
        .then(function () {
            return qz.printers.find('POS-80'); // Pass the printer name into the next Promise
        })
        .then(function (printer) {
            const config = qz.configs.create(printer, { encoding: 'Cp850' }); // Create a default config for the found printer

            const { currency } = incomePaymentItems[0];

            let subject = '';

            if (property) {
                subject = printLeftAndRight('Puesto:', property.code);
            } else if (shareholder) {
                subject = printLeftAndRight(
                    'Accionista:',
                    formatHelper.getShareholderFullname(shareholder),
                );
            }

            const items = [];
            let totalPrice = 0;

            for (const paymentItem of incomePaymentItems) {
                const value = printLeftAndRight(
                    paymentItem.paymentItem.description,
                    formatHelper.toMoney(paymentItem.editedPrice || paymentItem.price),
                );

                totalPrice += Number(paymentItem.editedPrice || paymentItem.price);
                items.push(value + lineBreak);
                if (paymentItem.comment) items.push(`(${paymentItem.comment})${lineBreak}`);
            }

            const data = [
                '\x1B' + '\x74' + '\x02', // ver https://qz.io/wiki/Raw-Encoding#escpos
                centerAlign,
                {
                    type: 'raw',
                    format: 'image',
                    data: require('./unicachi-logo.jpg'),
                    options: { language: 'ESCPOS', dotDensity: 'double' },
                },
                '\x1B' + '\x40', // init
                centerAlign,
                'UNICACHI' + lineBreak,
                'RUC 20504868428' + lineBreak,
                'TICKET DE PAGO' + lineBreak,
                lineBreak,
                smallFont,
                lineBreak,
                leftAlign,
                subject,
                lineBreak,
                lineBreak,
                '----------------------------------------------------------------' + lineBreak,
                'Detalle                                                    Total' + lineBreak,
                '----------------------------------------------------------------' + lineBreak,
                ...items,
                '----------------------------------------------------------------' + lineBreak,
                printLeftAndRight(
                    `Total Venta (${currency.symbol}):`,
                    formatHelper.toMoney(totalPrice),
                ),
                lineBreak,
                leftAlign,
                lineBreak + lineBreak + lineBreak + lineBreak + lineBreak + lineBreak + lineBreak,
                cutPaper,
                kickOutCashDrawer,
            ];

            return qz.print(config, data);
        })
        .then(function () {
            qz.websocket.disconnect();
            return;
        })
        .catch(function (e) {
            if (qz.websocket.isActive()) qz.websocket.disconnect();
        });
}
