import { IListStatePaginated, IObjectState, IProcessState } from '../../common/types';
import { IZone, IProperty } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    zoneList: IListStatePaginated<IZone>;
    activeZone: IObjectState<IZone>;
    createZone: IProcessState;
    updateZone: IProcessState;
    deactivateZone: IProcessState;
    propertyList: IListStatePaginated<IProperty>;
}

export const initialState: IState = {
    zoneList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activeZone: { value: undefined, loading: false, error: undefined },
    createZone: { success: false, loading: false, error: undefined },
    updateZone: { success: false, loading: false, error: undefined },
    deactivateZone: { success: false, loading: false, error: undefined },
    propertyList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_ZONES]: caseFunctions.getZones,
    [actionTypes.GET_ZONES_SUCCESS]: caseFunctions.getZonesSuccess,
    [actionTypes.GET_ZONES_FAILURE]: caseFunctions.getZonesFailure,
    [actionTypes.GET_ZONES_RESET]: caseFunctions.getZonesReset,

    [actionTypes.GET_ZONE]: caseFunctions.getZone,
    [actionTypes.GET_ZONE_SUCCESS]: caseFunctions.getZoneSuccess,
    [actionTypes.GET_ZONE_FAILURE]: caseFunctions.getZoneFailure,
    [actionTypes.GET_ZONE_RESET]: caseFunctions.getZoneReset,

    [actionTypes.CREATE_ZONE]: caseFunctions.createZone,
    [actionTypes.CREATE_ZONE_SUCCESS]: caseFunctions.createZoneSuccess,
    [actionTypes.CREATE_ZONE_FAILURE]: caseFunctions.createZoneFailure,
    [actionTypes.CREATE_ZONE_RESET]: caseFunctions.createZoneReset,

    [actionTypes.UPDATE_ZONE]: caseFunctions.updateZone,
    [actionTypes.UPDATE_ZONE_SUCCESS]: caseFunctions.updateZoneSuccess,
    [actionTypes.UPDATE_ZONE_FAILURE]: caseFunctions.updateZoneFailure,
    [actionTypes.UPDATE_ZONE_RESET]: caseFunctions.updateZoneReset,

    [actionTypes.DEACTIVATE_ZONE]: caseFunctions.deactivateZone,
    [actionTypes.DEACTIVATE_ZONE_SUCCESS]: caseFunctions.deactivateZoneSuccess,
    [actionTypes.DEACTIVATE_ZONE_FAILURE]: caseFunctions.deactivateZoneFailure,
    [actionTypes.DEACTIVATE_ZONE_RESET]: caseFunctions.deactivateZoneReset,

    [actionTypes.GET_ZONE_PROPERTIES]: caseFunctions.getZoneProperties,
    [actionTypes.GET_ZONE_PROPERTIES_SUCCESS]: caseFunctions.getZonePropertiesSuccess,
    [actionTypes.GET_ZONE_PROPERTIES_FAILURE]: caseFunctions.getZonePropertiesFailure,
    [actionTypes.GET_ZONE_PROPERTIES_RESET]: caseFunctions.getZonePropertiesReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
