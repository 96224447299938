import * as React from 'react';
import { FormikActions, FormikProps } from 'formik';
import {
    CustomForm,
    InputGroupField,
    SelectGroupField,
    DataTable,
    DisplayItem,
    SwitchGroupField,
} from '../../../../../../components';
import {
    IIncomePaymentItem,
    IBankAccount,
    IShareholder,
} from '../../../../../../common/types/entities';
import {
    messages,
    receiptTypes,
    paymentTypes,
    ebilling,
    currencies,
    roles,
} from '../../../../../../common/constants';
import { isEmpty, isEmail, isNumeric } from 'validator';
import { Divider, Row, Col, Button, message, Collapse, Icon, Tooltip, Radio } from 'antd';
import { columns } from './columns';
import IncomePaymentItemFormModal from './IncomePaymentItemFormModal';
import { IState, IProps, IIncomeAddEditForm } from './IncomeAddForm.types';
import { formatHelper } from '../../../../../../common/helpers';
import { RadioChangeEvent } from 'antd/lib/radio';

const GENERAL_PROPERTY = 630;
const CHARGE_PROPERTY = '1';
const CHARGE_SHAREHOLDER = '2';

export default class IncomeAddEditForm extends CustomForm<IIncomeAddEditForm, IProps, IState> {
    state: IState = {
        incomePaymentItems: [],
        incomePaymentItemModalOpen: false,
        totalPrice: 0,
        bankAccounts: [],
        chargeTo: CHARGE_PROPERTY,
        currency: undefined,
        isSameCurrency: false,
        bankAccountCurrency: undefined,
    };

    componentDidMount() {
        this.props.getProperties();
    }

    componentWillUnmount() {
        this.props.resetProperties();
        this.props.resetShareholders();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkRucInfo(prevProps);
    }

    checkRucInfo(prevProps: IProps) {
        const { props } = this;
        if (prevProps.rucInfo != props.rucInfo && props.rucInfo != null) {
            this.form.setFieldValue('businessName', props.rucInfo.businessName);
            this.form.setFieldValue('fiscalAddress', props.rucInfo.fullAddress);
        } else if (!prevProps.rucInfoError && props.rucInfoError) message.error(props.rucInfoError);
    }

    initialValues = (props: IProps): IIncomeAddEditForm => {
        return {
            property: {
                id:
                    props.loggedInUser.role.id == roles.SCALE_OPERATOR ||
                    props.loggedInUser.role.id == roles.PARKING_OPERATOR
                        ? GENERAL_PROPERTY
                        : null,
            },
            shareholder: { id: null },
            receiptType: { id: receiptTypes.BILL },
            ruc: '',
            businessName: '',
            fiscalAddress: '',
            dni: ebilling.genericCustomer.DNI,
            customerEmail: '',
            customerFullname: ebilling.genericCustomer.FULLNAME,
            paymentType: { id: paymentTypes.CASH },
            // type: { id: undefined },
            currency: { id: currencies.PEN },
            bankAccount: { id: undefined },
            amountTransferred: '',
            isPaymentPending: false,
        };
    };

    validate = (values: IIncomeAddEditForm) => {
        const errors = {} as Partial<IIncomeAddEditForm>;

        if (this.state.chargeTo == CHARGE_PROPERTY && (!values.property || !values.property.id))
            errors.property = { id: messages.MANDATORY_FIELD };

        if (
            this.state.chargeTo == CHARGE_SHAREHOLDER &&
            (!values.shareholder || !values.shareholder.id)
        )
            errors.shareholder = { id: messages.MANDATORY_FIELD };

        if (!values.receiptType || !values.receiptType.id)
            errors.receiptType = { id: messages.MANDATORY_FIELD };
        else {
            if (values.receiptType.id == receiptTypes.VOUCHER) {
                if (isEmpty(values.customerFullname))
                    errors.customerFullname = messages.MANDATORY_FIELD;
            } else if (values.receiptType.id == receiptTypes.INVOICE) {
                if (isEmpty(values.ruc)) errors.ruc = messages.MANDATORY_FIELD;
                if (isEmpty(values.businessName)) errors.businessName = messages.MANDATORY_FIELD;
                if (isEmpty(values.fiscalAddress)) errors.fiscalAddress = messages.MANDATORY_FIELD;
            } else if (values.receiptType.id == receiptTypes.BILL) {
                if (isEmpty(values.customerFullname))
                    errors.customerFullname = messages.MANDATORY_FIELD;
                if (isEmpty(values.dni)) errors.dni = messages.MANDATORY_FIELD;
            }
        }

        if (!isEmpty(values.customerEmail) && !isEmail(values.customerEmail))
            errors.customerEmail = 'El valor ingresado no es un correo';

        if (!values.currency || !values.currency.id)
            errors.currency = { id: messages.MANDATORY_FIELD };

        if (!values.paymentType || !values.paymentType.id)
            errors.paymentType = { id: messages.MANDATORY_FIELD };
        else if (values.paymentType.id == paymentTypes.WIRE_TRANSFER) {
            if (!values.bankAccount || !values.bankAccount.id)
                errors.bankAccount = { id: messages.MANDATORY_FIELD };
            else {
                if (!this.state.isSameCurrency) {
                    if (isEmpty(String(values.amountTransferred)))
                        errors.amountTransferred = messages.MANDATORY_FIELD;
                    else if (!isNumeric(String(values.amountTransferred)))
                        errors.amountTransferred = 'Monto no válido';
                }
            }
        }

        return errors;
    };

    onSubmit = (values: IIncomeAddEditForm, actions: FormikActions<IIncomeAddEditForm>) => {
        const { incomePaymentItems, totalPrice, bankAccountCurrency, isSameCurrency } = this.state;

        if (incomePaymentItems.length === 0) {
            message.error('Se debe agregar por lo menos un concepto de pago');
            return;
        }

        if (values.amountTransferred) values.amountTransferred = Number(values.amountTransferred);
        values.currency = this.state.currency as any;

        const isWireTransfer = values.paymentType.id == paymentTypes.WIRE_TRANSFER;

        if (bankAccountCurrency && isWireTransfer && isSameCurrency) {
            values.amountTransferred = totalPrice;
        }

        const finalValues = {
            ...values,
            incomePaymentItems,
            totalPrice,
        };

        this.props.onSubmit(finalValues);
        actions.setSubmitting(false);
    };

    onDeleteIncomePaymentItem = (item: IIncomePaymentItem) => {
        const { incomePaymentItems } = this.state;
        let { totalPrice, currency } = this.state;

        const filtered = incomePaymentItems.filter((x) => {
            if (x.paymentItem.id != item.paymentItem.id) return x;
            totalPrice -= Number(x.price);
        });

        if (filtered.length > 0) currency = filtered[0].currency;
        else currency = undefined;

        this.setState({ incomePaymentItems: filtered, totalPrice, currency });
    };

    onSubmitIncomePaymentItem = (values: any) => {
        const { incomePaymentItems } = this.state;
        let { totalPrice, currency } = this.state;

        incomePaymentItems.push(values);
        totalPrice += Number(values.price);

        if (incomePaymentItems.length > 0) currency = incomePaymentItems[0].currency;
        else currency = undefined;

        this.setState({
            incomePaymentItems,
            incomePaymentItemModalOpen: false,
            totalPrice,
            currency,
        });
    };

    onChangeCurrency = (value: any) => {
        this.form.setFieldValue('bankAccount.id', undefined);

        if (!value) {
            this.setState({ bankAccounts: [] });
            return;
        }

        const bankAccounts = this.props.bankAccounts.filter((x) => x.currency.id == value);
        this.setState({ bankAccounts });
    };

    getRucInfo = () => {
        const { rucInfoLoading, getRucInfo } = this.props;
        if (rucInfoLoading || !this.form.state.values.ruc) return;
        getRucInfo(this.form.state.values.ruc);
    };

    setGenericCustomer = () => {
        const { DNI, FULLNAME } = ebilling.genericCustomer;
        this.form.setFieldValue('dni', DNI);
        this.form.setFieldValue('customerFullname', FULLNAME);
    };

    onBankAccountChange = (id: any) => {
        const bankAccount = this.props.bankAccounts.find((x) => x.id == id);
        if (!bankAccount) {
            this.form.setFieldValue('amountTransferred', '');
            this.setState({ bankAccountCurrency: undefined });
            return;
        }

        const currencyId = bankAccount.currency.id;

        if (currencyId != this.state.currency.id) {
            this.form.setFieldValue('amountTransferred', '');
            this.setState({ isSameCurrency: false, bankAccountCurrency: bankAccount.currency });
        } else {
            this.form.setFieldValue('amountTransferred', this.state.totalPrice);
            this.setState({ isSameCurrency: true, bankAccountCurrency: bankAccount.currency });
        }
    };

    isSelectedBankAccountInDollars = (id: number): boolean => {
        if (!id) return false;
        const bankAccount = this.props.bankAccounts.find((x) => x.id == id);
        return bankAccount.currency.id == currencies.USD;
    };

    onChargeToChange = (e: RadioChangeEvent) => {
        const chargeTo = e.target.value;

        if (chargeTo == CHARGE_PROPERTY) {
            this.props.getProperties();
        } else if (chargeTo == CHARGE_SHAREHOLDER) {
            this.props.getShareholders();
        }

        this.form.setFieldValue('property.id', null);
        this.form.setFieldValue('shareholder.id', null);

        this.setState({ chargeTo });
    };

    formBody = ({ values }: FormikProps<IIncomeAddEditForm>) => {
        const {
            properties,
            propertiesLoading,
            shareholders,
            shareholdersLoading,
            paymentTypes: paymentTypesList,
            paymentTypesLoading,
            receiptTypes: receiptTypesItems,
            receiptTypesLoading,
            bankAccountsLoading,
            rucInfoLoading,
        } = this.props;

        const { incomePaymentItemModalOpen, bankAccountCurrency, isSameCurrency } = this.state;

        const isBill = values.receiptType.id == receiptTypes.BILL;
        const isInvoice = values.receiptType.id == receiptTypes.INVOICE;
        const isVoucher = values.receiptType.id == receiptTypes.VOUCHER;
        const isWireTransfer = values.paymentType.id == paymentTypes.WIRE_TRANSFER;

        return (
            <div>
                {this.props.loggedInUser.role.id != roles.SCALE_OPERATOR &&
                    this.props.loggedInUser.role.id != roles.PARKING_OPERATOR && (
                        <Row className="mb-4">
                            <Col sm={5} md={4} lg={3} xl={2}>
                                <span className="form-label">Cobrar a:</span>
                            </Col>
                            <Col sm={19} md={20} lg={21} xl={22}>
                                <Radio.Group
                                    onChange={this.onChargeToChange}
                                    value={this.state.chargeTo}
                                >
                                    <Radio.Button value={CHARGE_PROPERTY}>Puesto</Radio.Button>
                                    <Radio.Button value={CHARGE_SHAREHOLDER}>
                                        Accionista
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                    )}
                {this.state.chargeTo == CHARGE_PROPERTY && (
                    <SelectGroupField
                        name="property.id"
                        label="Puesto"
                        displayText="code"
                        required
                        data={properties}
                        loading={propertiesLoading}
                    />
                )}
                {this.state.chargeTo == CHARGE_SHAREHOLDER && (
                    <SelectGroupField
                        name="shareholder.id"
                        label="Accionista"
                        displayText={(item: IShareholder) =>
                            formatHelper.getShareholderFullnameWithCode(item)
                        }
                        required
                        data={shareholders}
                        loading={shareholdersLoading}
                    />
                )}
                <DisplayItem
                    label="Precio Total"
                    type="money"
                    value={this.state.totalPrice}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Collapse bordered={false} className="mb-5" defaultActiveKey="1">
                    <Collapse.Panel header="Conceptos de Pago" key="1">
                        <>
                            <Row className="mb-3">
                                <Col className="float-right">
                                    <Button
                                        icon="plus"
                                        type="primary"
                                        onClick={() =>
                                            this.setState({ incomePaymentItemModalOpen: true })
                                        }
                                    >
                                        Agregar
                                    </Button>
                                </Col>
                            </Row>

                            <DataTable
                                data={this.state.incomePaymentItems}
                                columns={columns(this.onDeleteIncomePaymentItem)}
                                rowKey={(record) => record.paymentItem.id}
                            />
                        </>
                    </Collapse.Panel>
                </Collapse>

                <SelectGroupField
                    name="receiptType.id"
                    label="Tipo de Recibo"
                    displayText="description"
                    required
                    data={receiptTypesItems}
                    loading={receiptTypesLoading}
                />
                <InputGroupField
                    name="ruc"
                    label="RUC"
                    hidden={isBill || isVoucher}
                    required
                    suffix={
                        <>
                            <Divider type="vertical" />
                            <Icon
                                type={rucInfoLoading ? 'loading' : 'search'}
                                style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
                                onClick={this.getRucInfo}
                            />
                        </>
                    }
                />
                <InputGroupField
                    name="businessName"
                    label="Razón Social"
                    hidden={isBill || isVoucher}
                    required
                />
                <InputGroupField
                    name="fiscalAddress"
                    label="Dirección Fiscal"
                    hidden={isBill || isVoucher}
                    required
                />
                <InputGroupField
                    name="customerFullname"
                    label="Nombre Completo"
                    hidden={isInvoice}
                    required
                    suffix={
                        <>
                            <Divider type="vertical" />
                            <Tooltip title="Cliente Genérico">
                                <Icon
                                    type="user"
                                    style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
                                    onClick={this.setGenericCustomer}
                                />
                            </Tooltip>
                        </>
                    }
                />
                <InputGroupField name="dni" label="DNI" hidden={isInvoice || isVoucher} required />
                <InputGroupField name="customerEmail" label="Email" hidden={isVoucher} />
                <SelectGroupField
                    name="paymentType.id"
                    label="Tipo de Pago"
                    displayText="description"
                    required
                    data={paymentTypesList}
                    loading={paymentTypesLoading}
                />
                <DisplayItem
                    label="Moneda"
                    value={this.state.currency ? this.state.currency.code : ''}
                    lxs={24}
                    lsm={24}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <SelectGroupField
                    name="bankAccount.id"
                    label="Cuenta Bancaria"
                    displayText={(item: IBankAccount) =>
                        item.bank.name + ' - Nº. Cuenta: ' + item.number
                    }
                    required
                    data={this.props.bankAccounts}
                    loading={bankAccountsLoading}
                    // disabled={!values.currency.id}
                    hidden={!isWireTransfer}
                    onChange={this.onBankAccountChange}
                    disabled={!this.state.currency}
                />
                {bankAccountCurrency && isWireTransfer && !isSameCurrency && (
                    <InputGroupField
                        name="amountTransferred"
                        label={`Monto Tranferido (${
                            bankAccountCurrency && bankAccountCurrency.code
                        })`}
                        required
                    />
                )}
                {bankAccountCurrency && isWireTransfer && isSameCurrency && (
                    <DisplayItem
                        label={`Monto Tranferido (${
                            bankAccountCurrency && bankAccountCurrency.code
                        })`}
                        value={formatHelper.toMoney(Number(this.state.totalPrice))}
                        lxs={24}
                        lsm={24}
                        lmd={4}
                        llg={3}
                        lxl={2}
                        lxxl={2}
                    />
                )}
                {this.props.loggedInUser.role.id != roles.SCALE_OPERATOR &&
                    this.props.loggedInUser.role.id != roles.PARKING_OPERATOR && (
                        <SwitchGroupField name="isPaymentPending" label="Pendiente de Pago" />
                    )}

                <IncomePaymentItemFormModal
                    isOpen={incomePaymentItemModalOpen}
                    onSubmit={this.onSubmitIncomePaymentItem}
                    onCancel={() => this.setState({ incomePaymentItemModalOpen: false })}
                    incomePaymentItems={this.state.incomePaymentItems}
                    loggedInUser={this.props.loggedInUser}
                    currencies={this.props.currencies}
                    currency={this.state.currency}
                />
            </div>
        );
    };
}
