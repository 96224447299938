import { IListStatePaginated, IProcessState, IObjectState } from '../../common/types';
import { IPaymentItem, IPaymentItemType } from '../../common/types/entities';
import actionTypes from './actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    paymentItemList: IListStatePaginated<IPaymentItem>;
    activePaymentItem: IObjectState<IPaymentItem>;
    createPaymentItem: IProcessState;
    updatePaymentItem: IProcessState;
    deactivatePaymentItem: IProcessState;
    paymentItemTypeList: IListStatePaginated<IPaymentItemType>;
    createPaymentItemType: IProcessState;
    updatePaymentItemType: IProcessState;
    deactivatePaymentItemType: IProcessState;
}

export const initialState: IState = {
    paymentItemList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    activePaymentItem: { value: undefined, loading: false, error: undefined },
    createPaymentItem: { success: false, loading: false, error: undefined },
    updatePaymentItem: { success: false, loading: false, error: undefined },
    deactivatePaymentItem: { success: false, loading: false, error: undefined },
    paymentItemTypeList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
    createPaymentItemType: { success: false, loading: false, error: undefined },
    updatePaymentItemType: { success: false, loading: false, error: undefined },
    deactivatePaymentItemType: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_PAYMENT_ITEMS]: caseFunctions.getPaymentItems,
    [actionTypes.GET_PAYMENT_ITEMS_SUCCESS]: caseFunctions.getPaymentItemsSuccess,
    [actionTypes.GET_PAYMENT_ITEMS_FAILURE]: caseFunctions.getPaymentItemsFailure,
    [actionTypes.GET_PAYMENT_ITEMS_RESET]: caseFunctions.getPaymentItemsReset,

    [actionTypes.GET_PAYMENT_ITEM]: caseFunctions.getPaymentItem,
    [actionTypes.GET_PAYMENT_ITEM_SUCCESS]: caseFunctions.getPaymentItemSuccess,
    [actionTypes.GET_PAYMENT_ITEM_FAILURE]: caseFunctions.getPaymentItemFailure,
    [actionTypes.GET_PAYMENT_ITEM_RESET]: caseFunctions.getPaymentItemReset,

    [actionTypes.CREATE_PAYMENT_ITEM]: caseFunctions.createPaymentItem,
    [actionTypes.CREATE_PAYMENT_ITEM_SUCCESS]: caseFunctions.createPaymentItemSuccess,
    [actionTypes.CREATE_PAYMENT_ITEM_FAILURE]: caseFunctions.createPaymentItemFailure,
    [actionTypes.CREATE_PAYMENT_ITEM_RESET]: caseFunctions.createPaymentItemReset,

    [actionTypes.UPDATE_PAYMENT_ITEM]: caseFunctions.updatePaymentItem,
    [actionTypes.UPDATE_PAYMENT_ITEM_SUCCESS]: caseFunctions.updatePaymentItemSuccess,
    [actionTypes.UPDATE_PAYMENT_ITEM_FAILURE]: caseFunctions.updatePaymentItemFailure,
    [actionTypes.UPDATE_PAYMENT_ITEM_RESET]: caseFunctions.updatePaymentItemReset,

    [actionTypes.DEACTIVATE_PAYMENT_ITEM]: caseFunctions.deactivatePaymentItem,
    [actionTypes.DEACTIVATE_PAYMENT_ITEM_SUCCESS]: caseFunctions.deactivatePaymentItemSuccess,
    [actionTypes.DEACTIVATE_PAYMENT_ITEM_FAILURE]: caseFunctions.deactivatePaymentItemFailure,
    [actionTypes.DEACTIVATE_PAYMENT_ITEM_RESET]: caseFunctions.deactivatePaymentItemReset,

    [actionTypes.GET_PAYMENT_ITEM_TYPES]: caseFunctions.getPaymentItemTypes,
    [actionTypes.GET_PAYMENT_ITEM_TYPES_SUCCESS]: caseFunctions.getPaymentItemTypesSuccess,
    [actionTypes.GET_PAYMENT_ITEM_TYPES_FAILURE]: caseFunctions.getPaymentItemTypesFailure,
    [actionTypes.GET_PAYMENT_ITEM_TYPES_RESET]: caseFunctions.getPaymentItemTypesReset,

    [actionTypes.CREATE_PAYMENT_ITEM_TYPE]: caseFunctions.createPaymentItemType,
    [actionTypes.CREATE_PAYMENT_ITEM_TYPE_SUCCESS]: caseFunctions.createPaymentItemTypeSuccess,
    [actionTypes.CREATE_PAYMENT_ITEM_TYPE_FAILURE]: caseFunctions.createPaymentItemTypeFailure,
    [actionTypes.CREATE_PAYMENT_ITEM_TYPE_RESET]: caseFunctions.createPaymentItemTypeReset,

    [actionTypes.UPDATE_PAYMENT_ITEM_TYPE]: caseFunctions.updatePaymentItemType,
    [actionTypes.UPDATE_PAYMENT_ITEM_TYPE_SUCCESS]: caseFunctions.updatePaymentItemTypeSuccess,
    [actionTypes.UPDATE_PAYMENT_ITEM_TYPE_FAILURE]: caseFunctions.updatePaymentItemTypeFailure,
    [actionTypes.UPDATE_PAYMENT_ITEM_TYPE_RESET]: caseFunctions.updatePaymentItemTypeReset,

    [actionTypes.DEACTIVATE_PAYMENT_ITEM_TYPE]: caseFunctions.deactivatePaymentItemType,
    [actionTypes.DEACTIVATE_PAYMENT_ITEM_TYPE_SUCCESS]:
        caseFunctions.deactivatePaymentItemTypeSuccess,
    [actionTypes.DEACTIVATE_PAYMENT_ITEM_TYPE_FAILURE]:
        caseFunctions.deactivatePaymentItemTypeFailure,
    [actionTypes.DEACTIVATE_PAYMENT_ITEM_TYPE_RESET]: caseFunctions.deactivatePaymentItemTypeReset,
});
