import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../../components';
import { IPettyCashMovementItem } from '../../../../../../common/types/entities';
import { Button } from 'antd';
import { formatHelper } from '../../../../../../common/helpers';

export const columns = (
    onClickDelete: (stringId: string) => void,
): ColumnProps<IPettyCashMovementItem>[] => [
    {
        title: 'Descripción',
        dataIndex: 'description',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Cantidad',
        dataIndex: 'quantity',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Precio Unitario',
        dataIndex: 'unitPrice',
        className: 'd-none d-md-table-cell',
        render: (unitPrice: number) => <span>{formatHelper.toMoney(unitPrice)}</span>,
    },
    {
        title: 'Precio Total',
        dataIndex: 'totalPrice',
        className: 'd-none d-md-table-cell',
        render: (totalPrice: number) => <span>{formatHelper.toMoney(totalPrice)}</span>,
    },
    {
        key: 'xs',
        title: 'Item',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IPettyCashMovementItem) => (
            <>
                <TableCellItem label="Descripción" value={item.description} />
                <TableCellItem label="Cantidad" value={item.quantity} />
                <TableCellItem label="Precio Unitario" value={item.unitPrice} type="money" />
                <TableCellItem label="Precio Total" value={item.totalPrice} type="money" />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IPettyCashMovementItem) => (
            <Button
                type="primary"
                shape="round"
                icon="delete"
                onClick={() => onClickDelete(item.stringId)}
            />
        ),
    },
];
