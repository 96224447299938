import React from 'react';
import { Result, Button } from 'antd';
import { RouteComponentProps } from 'react-router-dom';

type IProps = RouteComponentProps;

const Page403: React.FC<IProps> = ({ history }) => {
    const goToHome = () => {
        history.push('/');
    };

    return (
        <Result
            status="403"
            title="403"
            subTitle="Lo sentimos, no tienes autorización para acceder a esta página."
            extra={
                <Button type="primary" onClick={goToHome}>
                    Volver al inicio
                </Button>
            }
        />
    );
};

export default Page403;
