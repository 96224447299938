const actionTypes = {
    GET_ZONES: 'zone/GET_ZONES',
    GET_ZONES_SUCCESS: 'zone/GET_ZONES_SUCCESS',
    GET_ZONES_FAILURE: 'zone/GET_ZONES_FAILURE',
    GET_ZONES_RESET: 'zone/GET_ZONES_RESET',

    GET_ZONE: 'zone/GET_ZONE',
    GET_ZONE_SUCCESS: 'zone/GET_ZONE_SUCCESS',
    GET_ZONE_FAILURE: 'zone/GET_ZONE_FAILURE',
    GET_ZONE_RESET: 'zone/GET_ZONE_RESET',

    CREATE_ZONE: 'zone/CREATE_ZONE',
    CREATE_ZONE_SUCCESS: 'zone/CREATE_ZONE_SUCCESS',
    CREATE_ZONE_FAILURE: 'zone/CREATE_ZONE_FAILURE',
    CREATE_ZONE_RESET: 'zone/CREATE_ZONE_RESET',

    UPDATE_ZONE: 'zone/UPDATE_ZONE',
    UPDATE_ZONE_SUCCESS: 'zone/UPDATE_ZONE_SUCCESS',
    UPDATE_ZONE_FAILURE: 'zone/UPDATE_ZONE_FAILURE',
    UPDATE_ZONE_RESET: 'zone/UPDATE_ZONE_RESET',

    DEACTIVATE_ZONE: 'zone/DEACTIVATE_ZONE',
    DEACTIVATE_ZONE_SUCCESS: 'zone/DEACTIVATE_ZONE_SUCCESS',
    DEACTIVATE_ZONE_FAILURE: 'zone/DEACTIVATE_ZONE_FAILURE',
    DEACTIVATE_ZONE_RESET: 'zone/DEACTIVATE_ZONE_RESET',

    GET_ZONE_PROPERTIES: 'zone/GET_ZONE_PROPERTIES',
    GET_ZONE_PROPERTIES_SUCCESS: 'zone/GET_ZONE_PROPERTIES_SUCCESS',
    GET_ZONE_PROPERTIES_FAILURE: 'zone/GET_ZONE_PROPERTIES_FAILURE',
    GET_ZONE_PROPERTIES_RESET: 'zone/GET_ZONE_PROPERTIES_RESET',
};

export default actionTypes;
