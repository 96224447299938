import { IListStatePaginated } from '../../common/types';
import { ITax } from '../../common/types/entities';
import actionTypes from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    taxList: IListStatePaginated<ITax>;
}

export const initialState: IState = {
    taxList: { data: [], total: 0, pageSize: 0, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.GET_TAXES]: caseFunctions.getTaxes,
    [actionTypes.GET_TAXES_SUCCESS]: caseFunctions.getTaxesSuccess,
    [actionTypes.GET_TAXES_FAILURE]: caseFunctions.getTaxesFailure,
    [actionTypes.GET_TAXES_RESET]: caseFunctions.getTaxesReset,

    [authActionTypes.LOGOUT_USER]: () => initialState,
});
