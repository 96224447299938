import React from 'react';
import { IProps } from './ExpenseLogisticAddEdit.types';
import { SectionContainer, DisplayItem, DataTable } from '../../../../components';
import { paths } from '../../../../routes';
import { Divider, Row, Col, Button, message } from 'antd';
import { formatHelper } from '../../../../common/helpers';
import { expenseStates, expenseTypes, currencyTypes } from '../../../../common/constants';
import { IExpenseDetail } from '../../../../common/types/entities';
import { goodsColumns, servicesColumns } from './misc/columns';
import { AddEditModal, IAddEditExpenseDetailValues } from './components/addItemModal';

interface IState {
    totalPrice: number;
    totalPriceUSD: number;
    expenseDetails: IExpenseDetail[];
    modalOpen: boolean;
    expenseDetailToEdit: IExpenseDetail;
}

export default class ExpenseAddEdit extends React.Component<IProps> {
    private expenseId = this.props.match.params['id'];

    state: IState = {
        totalPrice: 0,
        totalPriceUSD: 0,
        expenseDetails: [],
        modalOpen: false,
        expenseDetailToEdit: null,
    };

    componentDidMount() {
        this.props.getExpense(this.expenseId);
        this.props.getExpenseDetails(this.expenseId);
        this.props.getCurrencies();
    }

    componentWillUnmount() {
        this.props.resetExpense();
        this.props.resetExpenseDetails();
        this.props.resetCurrencies();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkActiveExpense(prevProps);
        this.checkExpenseLogistic(prevProps);
    }

    checkActiveExpense(prevProps: IProps) {
        const { props } = this;

        if (this.state.expenseDetails.length === 0 && props.expenseDetails.length !== 0)
            this.setState({ expenseDetails: props.expenseDetails });

        if (!prevProps.activeExpense && props.activeExpense) {
            const stateId = props.activeExpense.state.id;

            if (stateId != expenseStates.REJECTED && stateId != expenseStates.PENDING)
                this.props.history.push(paths.ADMIN_EXPENSE_LIST);

            this.setState({ expenseDetails: props.expenseDetails });
        }
    }

    onClickLogisticModal = (item: IExpenseDetail) => {
        this.setState({ modalOpen: true, expenseDetailToEdit: item });
    };

    editLogisticItem = (item: IAddEditExpenseDetailValues) => {
        let expenseDetails = this.state.expenseDetails,
            totalPrice = 0,
            totalPriceUSD = 0;

        for (let i = 0; i < expenseDetails.length; i++) {
            if (expenseDetails[i].id === this.state.expenseDetailToEdit.id) {
                // @ts-ignore
                if (item.currency) expenseDetails[i].currency = item.currency;
                expenseDetails[i].unitPrice = Number(item.unitPrice);
                expenseDetails[i].comment = item.comment;

                // update totalPrice
                if (this.props.activeExpense.type.id == expenseTypes.GOODS) {
                    expenseDetails[i].totalPrice = Number(item.unitPrice) * Number(item.quantity);
                    expenseDetails[i].quantity = Number(item.quantity);
                } else {
                    expenseDetails[i].quantity = Number(1);
                    expenseDetails[i].totalPrice = Number(item.unitPrice);
                }
            }
        }

        expenseDetails.forEach(values => {
            if (values.totalPrice) {
                if (values.currency.id == currencyTypes.PEN) totalPrice += values.totalPrice;
                else totalPriceUSD += values.totalPrice;
            }
        });
        this.setState({
            totalPrice,
            totalPriceUSD,
            expenseDetails,
            modalOpen: false,
            expenseDetailToEdit: null,
        });
    };

    checkExpenseLogistic(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.completeLogisticSuccess && props.completeLogisticSuccess) {
            message.success('Proceso de Logística completada');
            this.props.history.push(paths.ADMIN_EXPENSE_LIST);
        } else if (!prevProps.completeLogisticError && props.completeLogisticError)
            message.error(props.completeLogisticError);
    }

    performLogisticProcess = () => {
        for (const it of this.state.expenseDetails) {
            if (!it.totalPrice || it.totalPrice == 0) {
                message.error('Se debe completar la información del detalle: ' + it.name);
                return;
            }
        }

        let expenseToEdit = { expenseDetails: this.state.expenseDetails };

        this.props.completeLogistic(this.expenseId, expenseToEdit);
    };

    renderContent = () => {
        const {
                activeExpense,
                expenseDetailsLoading,
                completeLogisticLoading,
                currencies,
                currenciesLoading,
            } = this.props,
            isGoodsType = activeExpense.type.id == expenseTypes.GOODS;

        return (
            <>
                <DisplayItem
                    label="Código"
                    value={activeExpense.code}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Estado"
                    value={activeExpense.state.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Responsables</Divider>
                <DisplayItem
                    label="Solicitante"
                    value={formatHelper.getUserFullname(activeExpense.requestUser)}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <Divider orientation="left">Información</Divider>
                <DisplayItem
                    label="Tipo"
                    value={activeExpense.type.description}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Motivo"
                    value={activeExpense.reason}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Precio Total (PEN)"
                    type="money"
                    value={this.state.totalPrice || activeExpense.totalPrice}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <DisplayItem
                    label="Precio Total (USD)"
                    type="money"
                    value={this.state.totalPriceUSD || activeExpense.totalPrice}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                {activeExpense.reasonToDelete && (
                    <DisplayItem
                        label="Motivo de Rechazo"
                        value={activeExpense.reasonToDelete}
                        vStyles={{ fontWeight: 'bold' }}
                        lmd={4}
                        llg={3}
                        lxl={2}
                        lxxl={2}
                    />
                )}
                <Divider orientation="left" className="mt-5">
                    {isGoodsType ? 'Bienes' : 'Servicios'}
                </Divider>
                <DataTable
                    data={this.state.expenseDetails}
                    columns={
                        isGoodsType
                            ? goodsColumns(this.onClickLogisticModal)
                            : servicesColumns(this.onClickLogisticModal)
                    }
                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                    loading={expenseDetailsLoading}
                />
                <AddEditModal
                    activeItem={this.state.expenseDetailToEdit}
                    currencies={currencies}
                    currenciesLoading={currenciesLoading}
                    isOpen={this.state.modalOpen}
                    onSubmit={this.editLogisticItem}
                    onCancel={() => this.setState({ modalOpen: false })}
                    typeId={Number(this.props.activeExpense.type.id)}
                />
                <Row className="mt-4">
                    <Col>
                        <Button
                            type="primary"
                            icon="check"
                            onClick={this.performLogisticProcess}
                            loading={completeLogisticLoading}
                        >
                            Completar Logística
                        </Button>
                    </Col>
                </Row>
            </>
        );
    };

    render() {
        const { activeExpense, activeExpenseLoading, activeExpenseError } = this.props;

        return (
            <SectionContainer
                title="Completar Logística"
                onBack={() => this.props.history.push(paths.ADMIN_EXPENSE_LIST)}
                loading={activeExpenseLoading || !activeExpense}
                error={activeExpenseError}
                content={this.renderContent}
            />
        );
    }
}
