import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { IRequestOrderDetail, IInvoiceItem, ICurrency } from '../../../../../common/types/entities';
import { Input } from 'antd';
import { formatHelper, helper } from '../../../../../common/helpers';
import nanoid from 'nanoid';
import { currencyTypes } from '../../../../../common/constants';

export const invoiceItemColumns = (
    addOrUpdateInvoiceItem: (
        requestOrderDetail: IRequestOrderDetail,
        quantity: number,
        total: number,
    ) => void,
    invoiceItems: IInvoiceItem[],
    bankAccountCurrency: ICurrency,
    exchangeRate: number,
    currencySelected: number,
): ColumnProps<any>[] => {
    let requestOrderDetailCols: ColumnProps<IRequestOrderDetail>[] = [
        {
            key: nanoid(),
            title: 'Nombre',
            dataIndex: 'name',
            // className: 'd-none d-md-table-cell',
        },
        {
            key: nanoid(),
            title: 'Precio Unitario',
            // className: 'd-none d-md-table-cell',
            render: (requestOrderDetail: IRequestOrderDetail) => (
                <span>
                    {currencySelected &&
                        formatHelper.toMoney(
                            requestOrderDetail.unitPrice,
                            currencySelected == currencyTypes.PEN
                                ? 'PEN'
                                : currencySelected == currencyTypes.USD
                                    ? 'USD'
                                    : null,
                        )}
                </span>
            ),
        },
        {
            key: nanoid(),
            title: 'Cantidad',
            dataIndex: 'quantity',
            // className: 'd-none d-md-table-cell',
        },
        {
            key: nanoid(),
            title: 'Cantidad Restante',
            dataIndex: 'remainingQuantity',
            render: (remainingQuantity: number) => <span>{remainingQuantity || 0}</span>,
        },
        {
            key: nanoid(),
            title: 'Precio Total',
            // className: 'd-none d-md-table-cell',
            render: (requestOrderDetail: IRequestOrderDetail) => (
                <span>
                    {currencySelected &&
                        formatHelper.toMoney(
                            requestOrderDetail.totalPrice,
                            currencySelected == currencyTypes.PEN
                                ? 'PEN'
                                : currencySelected == currencyTypes.USD
                                    ? 'USD'
                                    : null,
                        )}
                </span>
            ),
        },
        {
            key: nanoid(),
            title: 'Comentario',
            dataIndex: 'comment',
            // className: 'd-none d-md-table-cell break-row',
        },
    ];

    let invoiceItemCols: ColumnProps<IInvoiceItem>[] = [
        {
            // key: nanoid(), // if u uncomment this, the input component, will lost focus, IDK...
            title: 'Cantidad',
            dataIndex: '',
            // className: 'd-none d-md-table-cell',
            render: (item: IRequestOrderDetail) => {
                let obj =
                    invoiceItems.length > 0 &&
                    invoiceItems.find(obj => obj.requestOrderDetail.id === item.id);

                return (
                    <Input
                        value={obj ? obj.quantity : 0}
                        onChange={e => {
                            if (
                                (e.target.value.length > 0 &&
                                    !helper.onlyNumbers(e.target.value)) ||
                                Number(e.target.value) > Number(item.remainingQuantity)
                            )
                                return;

                            addOrUpdateInvoiceItem(
                                item,
                                Number(e.target.value),
                                Number(item.unitPrice * Number(e.target.value)),
                            );
                        }}
                    />
                );
            },
        },
        {
            key: nanoid(),
            title: 'Precio Total',
            dataIndex: null,
            className: 'd-none d-md-table-cell',
            render: (item: IRequestOrderDetail) => {
                const invoiceItem = invoiceItems.find(obj => obj.requestOrderDetail.id === item.id);
                return (
                    <span>
                        {currencySelected &&
                            invoiceItem &&
                            formatHelper.toMoney(
                                invoiceItem.unitPrice * invoiceItem.quantity,
                                currencySelected == currencyTypes.PEN
                                    ? 'PEN'
                                    : currencySelected == currencyTypes.USD
                                        ? 'USD'
                                        : null,
                            )}
                    </span>
                );
            },
        },
    ];

    const totalPrice =
        invoiceItems.length > 0
            ? invoiceItems.map(item => item.totalPrice).reduce((prev, next) => prev + next, 0)
            : 0;

    let finalTitle = '';
    finalTitle +=
        bankAccountCurrency && bankAccountCurrency.id != currencySelected && exchangeRate
            ? 'Total: ' +
            formatHelper.toMoney(
                currencySelected == currencyTypes.PEN ? (totalPrice / exchangeRate) : (totalPrice * exchangeRate),
                currencySelected ? (currencySelected != currencyTypes.PEN ? 'PEN' : 'USD') : null,
            ) +
            '. '
            : '';

    finalTitle +=
        'Total: ' +
        formatHelper.toMoney(
            totalPrice,
            currencySelected ? (currencySelected == currencyTypes.PEN ? 'PEN' : 'USD') : null,
        );

    return [
        {
            title: 'Detalle',
            children: [
                {
                    title: 'Detalle de Orden de Compra',
                    className: 'bg-th-header',
                    colSpan: 0,
                    children: requestOrderDetailCols,
                },
                {
                    title: finalTitle,
                    className: 'bg-th-header',
                    children: invoiceItemCols,
                },
            ],
        },
    ];
};
