import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';

const { CONFIGURE_URL } = apiUrls;

export function getAll(filter: string, page: number) {
    const params = { filter, page };

    return APIManager.get(CONFIGURE_URL, { params });
}

export function update(id: number, data: any) {
    const url = `${CONFIGURE_URL}/${id}`
    return APIManager.put(url, { data })
}