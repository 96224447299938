import React, { FC } from 'react';
import { PageHeader, Alert } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import { MainLoader } from '../..';

interface IOwnProps {
    title?: string;
    icon?: string;
    onBack?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    loading?: boolean;
    loadingComponent?: React.ReactNode;
    content?: () => JSX.Element;
    error?: string;
}

type IProps = IOwnProps;

const SectionContainer: FC<IProps> = props => {
    const getAvatar = (): AvatarProps => {
        if (!props.icon) return null;
        return {
            icon: props.icon,
            shape: 'square',
            size: 'small',
            style: { marginTop: 4 },
        };
    };

    const renderContent = () => {
        if (props.error)
            return <Alert message="Error" description={props.error} type="error" showIcon />;
        else if (props.loading) return <MainLoader />;
        if (props.children) return props.children;
        else if (props.content) return props.content();
        else return null;
    };

    return (
        <div className="section-container">
            {props.title && (
                <PageHeader
                    avatar={getAvatar()}
                    title={props.title}
                    onBack={props.onBack}
                    className="pl-0 pt-3"
                />
            )}
            {renderContent()}
        </div>
    );
};

SectionContainer.defaultProps = {
    loading: false,
    loadingComponent: null,
};

export default SectionContainer;
