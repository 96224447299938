import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { login } from '../../../store/auth/actions';
import Login from './Login.view';
import { IRootState } from '../../../common/types';
import { IStoreState } from './Login.types';

// export interface IProps extends RouteComponentProps<{}>, IStateProps, IDispatchProps {}

export function mapStateToProps(state: IRootState): IStoreState {
    return {
        authenticated: state.auth.authenticated,
        loading: state.auth.loggedInUser.loading,
        error: state.auth.loggedInUser.error,
    };
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            login,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
