import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { forgotPassword } from '../../../store/auth/actions';
import ForgotPassword from './ForgotPassword';
import { IStoreState } from './ForgotPassword.types';
import { IRootState } from '../../../common/types';

export function mapStateToProps(state: IRootState): IStoreState {
    return {
        success: state.auth.forgotPassword.success,
        loading: state.auth.forgotPassword.loading,
        error: state.auth.forgotPassword.error,
    };
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            forgotPassword,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ForgotPassword);
