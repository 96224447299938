import React, { FC, useRef } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik'
import { Modal } from 'antd'
import { isEmpty } from 'validator'
import { InputGroupField } from '../../../../../components';
import { messages, expenseTypes } from '../../../../../common/constants';
import { IExpenseDetail, IInvoiceItem } from '../../../../../common/types/entities';

interface IOwnProps {
  isOpen: boolean;
  onSubmit: (values: IInvoiceItem) => void;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface IAddInvoiceItemValues {
  name: string;
  unitPrice: number;
  quantity: number;
  // totalPrice: number;
  comment: string;
}

export type IProps = IOwnProps;

const AddModal: FC<IProps> = props => {
  let form = useRef<Formik<IAddInvoiceItemValues>>(null);

  const onSubmit = (values: any, actions: FormikActions<IAddInvoiceItemValues>) => {
    props.onSubmit(values);
    actions.setSubmitting(false)
  }

  const handleOnCancel = e => {
    form.current.resetForm();
    props.onCancel(e);
  }

  const validate = (values: IAddInvoiceItemValues) => {
    const errors: Partial<IAddInvoiceItemValues> = {};
    if (isEmpty(values.name)) errors.name = messages.MANDATORY_FIELD;
    if (!values.unitPrice || values.unitPrice == 0) errors.name = messages.MANDATORY_FIELD;
    if (!values.quantity || values.quantity == 0) errors.name = messages.MANDATORY_FIELD;

    return errors;
  }

  const onOk = () => {
    form.current.submitForm();
  }

  const renderFormik = () => {
    return props.isOpen
      ? <Formik
        initialValues={{
          name: null,
          unitPrice: null,
          quantity: null,
          // totalPrice: null,
          comment: null,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={validate}
        onSubmit={onSubmit}
        ref={form}
        render={(formikBag: FormikProps<IAddInvoiceItemValues>) =>
          <Form>
            <InputGroupField
              name="name"
              label="Nombre"
              required
              lxs={24}
              lsm={24}
              lmd={24}
              llg={24}
              lxl={6}
              lxxl={6}
              imd={24}
              ixl={18}
              ilg={24}
            />
            <InputGroupField
              name="unitPrice"
              label="Precio Unitario"
              normalize="numbersWithDecimals"
              required
              lxs={24}
              lsm={24}
              lmd={24}
              llg={24}
              lxl={6}
              lxxl={6}
              imd={24}
              ixl={18}
              ilg={24}
            />
            <InputGroupField
              name="quantity"
              label="Cantidad"
              normalize="number"
              required
              lxs={24}
              lsm={24}
              lmd={24}
              llg={24}
              lxl={6}
              lxxl={6}
              imd={24}
              ixl={18}
              ilg={24}
            />
            <InputGroupField
              name="comment"
              label="Comentario"
              lxs={24}
              lsm={24}
              lmd={24}
              llg={24}
              lxl={6}
              lxxl={6}
              imd={24}
              ixl={18}
              ilg={24}
            />
          </Form>
        }
      />
      : null
  }

  return <Modal
    title={'Agregar Detalle de Factura'}
    visible={props.isOpen}
    okText="Guardar"
    onOk={onOk}
    cancelText="Cancelar"
    onCancel={handleOnCancel}
  >
    {renderFormik()}
  </Modal>
}

export default AddModal;