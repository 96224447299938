import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import {
    TableCellItem,
    TableRowOptionsDropdown,
    TableRowOptionItem,
} from '../../../../../components';
import { IBankAccount } from '../../../../../common/types/entities';
import { permissions } from '../../../../../common/constants';

export const columns = (
    onClickDetail: (id: number) => void,
    onClickEdit: (id: number) => void,
    onClickTransactionList: (id: number) => void,
    onClickDelete: (id: number) => void,
): ColumnProps<IBankAccount>[] => [
    {
        title: 'N° de cuenta',
        dataIndex: 'number',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'N° interbancario',
        dataIndex: 'interbankNumber',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Moneda',
        dataIndex: 'currency.code',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Banco',
        dataIndex: 'bank.name',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Cuenta Bancaria',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IBankAccount) => (
            <>
                <TableCellItem label="N° de cuenta" value={item.number} />
                <TableCellItem label="N° interbancario" value={item.interbankNumber} />
                <TableCellItem label="Banco" value={item.bank.name} />
                <TableCellItem label="Moneda" value={item.currency.code} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IBankAccount) => (
            <TableRowOptionsDropdown
                onClickDetail={() => onClickDetail(item.id)}
                detailPermission={permissions.bankAccount.READ_BANK_ACCOUNT_DETAIL}
                onClickEdit={() => onClickEdit(item.id)}
                editPermission={permissions.bankAccount.WRITE_BANK_ACCOUNTS}
                onClickDelete={() => onClickDelete(item.id)}
                deletePermission={permissions.bankAccount.WRITE_BANK_ACCOUNTS}
            >
                <TableRowOptionItem
                    label="Movimientos"
                    icon="swap"
                    onClick={() => onClickTransactionList(item.id)}
                    permission={permissions.bankAccount.READ_BANK_ACCOUNT_MOVEMENTS}
                />
            </TableRowOptionsDropdown>
        ),
    },
];
