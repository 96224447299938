import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button, Row, Col, Input, message, Modal } from 'antd';
import { columns } from './misc/columns';
import { IProps } from './ShareholderCoOwnerList.types';
import { SectionContainer, DataTable } from '../../../../components';
import { formatHelper } from '../../../../common/helpers';
import { paths } from '../../../../routes';
import { ICoOwner } from '../../../../common/types/entities';
import { AddEditModal } from './components';
import { IAddEditCoOwnerForm } from './components/AddEditModal';

const ShareholderCoOwnerList: FunctionComponent<IProps> = props => {
    const shareholderId = props.match.params['id'];

    const [filter, setFilter] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [coOwnerToEdit, setCoOwnerToEdit] = useState<ICoOwner>();

    useEffect(() => {
        props.getShareholder(shareholderId);
        performGetCoOwners();

        return () => {
            props.resetShareholder();
            props.resetCoOwners();
        };
    }, []);

    useEffect(() => {
        if (props.deactivateCoOwnerSuccess) {
            message.success('Co-propietario eliminado');
            performGetCoOwners();
            props.resetDeactivateCoOwner();
        }
        if (props.deactivateCoOwnerError) {
            message.error(props.deactivateCoOwnerError);
            props.resetDeactivateCoOwner();
        }
    }, [props.deactivateCoOwnerSuccess, props.deactivateCoOwnerError]);

    useEffect(() => {
        if (props.createCoOwnerSuccess) {
            message.success('Co-propietario creado');
            setModalOpen(false);
            performGetCoOwners();
            props.resetCreateCoOwner();
        }
        if (props.createCoOwnerError) {
            message.error(props.createCoOwnerError);
            props.resetCreateCoOwner();
        }
    }, [props.createCoOwnerSuccess, props.createCoOwnerError]);

    useEffect(() => {
        if (props.updateCoOwnerSuccess) {
            message.success('Co-propietario actualizado');
            setModalOpen(false);
            setCoOwnerToEdit(undefined);
            performGetCoOwners();
            props.resetUpdateCoOwner();
        }
        if (props.updateCoOwnerError) {
            message.error(props.updateCoOwnerError);
            props.resetUpdateCoOwner();
        }
    }, [props.updateCoOwnerSuccess, props.updateCoOwnerError]);

    const performGetCoOwners = () => props.getCoOwners(shareholderId, filter);

    const onSearch = () => performGetCoOwners();

    const onClickAdd = () => {
        setCoOwnerToEdit(undefined);
        setModalOpen(true);
    };

    const onClickEdit = (coOwner: ICoOwner) => {
        setCoOwnerToEdit(coOwner);
        setModalOpen(true);
    };

    const onClickDelete = (id: number) => {
        const { deactivateCoOwner } = props;

        Modal.confirm({
            title: '¿Deseas eliminar el co-propietario del accionista?',
            content: 'Eliminar co-propietario de accionista',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deactivateCoOwner(id);
            },
            onCancel() {},
        });
    };

    const handleSubmit = (values: IAddEditCoOwnerForm) => {
        if (coOwnerToEdit) {
            props.updateCoOwner(coOwnerToEdit.id, values);
            return;
        }

        props.createCoOwner(shareholderId, values);
    };

    return (
        <SectionContainer
            title={`Co-propietarios con ${
                props.shareholder ? formatHelper.getShareholderFullname(props.shareholder) : ''
            }`}
            icon="user"
            onBack={() => props.history.push(paths.ADMIN_SHAREHOLDER_LIST)}
        >
            <Row className="mb-2">
                <Col md={12} lg={6}>
                    <Input.Search
                        placeholder="Buscar"
                        enterButton
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                        onSearch={onSearch}
                    />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="float-right">
                    <Button icon="plus" type="primary" onClick={onClickAdd}>
                        Nuevo
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable
                        data={props.coOwners}
                        columns={columns(onClickEdit, onClickDelete)}
                        loading={props.coOwnersLoading}
                    />
                </Col>
            </Row>
            <AddEditModal
                isOpen={modalOpen}
                onSubmit={handleSubmit}
                onCancel={() => {
                    setModalOpen(false);
                    setCoOwnerToEdit(undefined);
                }}
                initialValues={coOwnerToEdit}
                submitting={props.createCoOwnerLoading || props.updateCoOwnerLoading}
            />
        </SectionContainer>
    );
};

export default ShareholderCoOwnerList;
