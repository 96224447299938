import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { configureService } from '../../service';
import { IReceiptNumeration } from '../../common/types/entities';

export function getConfigures(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => configureService.getAll(filter, page),
        types: [
            actionTypes.GET_CONFIGURES,
            actionTypes.GET_CONFIGURES_SUCCESS,
            actionTypes.GET_CONFIGURES_FAILURE,
        ],
    };
}

export function resetConfigures() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_CONFIGURES_RESET,
        });
    };
}

export function updateConfigures(id: number, data: IReceiptNumeration): IAPIMiddleware {
    return {
        callAPI: () => configureService.update(id, data),
        types: [
            actionTypes.UPDATE_CONFIGURES,
            actionTypes.UPDATE_CONFIGURES_SUCCESS,
            actionTypes.UPDATE_CONFIGURES_FAILURE,
        ]
    }
}
