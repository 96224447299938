import React, { FunctionComponent, useState, useEffect } from 'react';
import { SectionContainer } from '../../../../components';
import { Row, Col, DatePicker, Select, Button, message } from 'antd';
import { paths } from '../../../../routes';
import { Moment } from 'moment';
import { IProps } from './IncomeDetailedAnulledReport.types';
import { fileHelper, formatHelper } from '../../../../common/helpers';
import { roles } from '../../../../common/constants';

const IncomeDetailedAnulledReport: FunctionComponent<IProps> = (props) => {
    const [dates, setDates] = useState<[Moment, Moment]>(null);
    const [selectedConcepts, setSelectedConcepts] = useState<string[]>([]);
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
    const [selectedShareholders, setSelectedShareholders] = useState<string[]>([]);

    useEffect(() => {
        props.getPaymentItems();

        if (
            props.user.role.id != roles.SCALE_OPERATOR &&
            props.user.role.id != roles.PARKING_OPERATOR
        ) {
            props.getProperties();
            props.getShareholders();
        }

        return () => {
            props.resetPaymentItems();
            props.resetProperties();
            props.resetShareholders();
        };
    }, []);

    useEffect(() => {
        if (props.incomeDetailedReportExcel) {
            const { blob, filename } = props.incomeDetailedReportExcel;
            fileHelper.downloadBlob(blob, filename);
            props.resetIncomeDetailedAnulledReportExcel();
        }

        if (props.incomeDetailedReportExcelError) {
            message.error(props.incomeDetailedReportExcelError);
            props.resetIncomeDetailedAnulledReportExcel();
        }
    }, [props.incomeDetailedReportExcel, props.incomeDetailedReportExcelError]);

    const onDatesChange = (date, dateString) => {
        setDates([date[0], date[1]]);
    };

    const onPaymentItemsChange = (value) => {
        setSelectedConcepts(value);
    };

    const onPropertiesChange = (value) => {
        setSelectedProperties(value);
    };

    const onShareholdersChange = (value) => {
        setSelectedShareholders(value);
    };

    const onPressDownload = () => {
        let startDate = null;
        let endDate = null;

        if (dates) {
            startDate = dates[0] ? dates[0].toDate() : null;
            endDate = dates[1] ? dates[1].toDate() : null;
        }

        props.getIncomeDetailedAnulledReportExcel(
            startDate,
            endDate,
            selectedConcepts,
            selectedProperties,
            selectedShareholders,
        );
    };

    const isScaleOperator =
        props.user.role.id == roles.SCALE_OPERATOR || props.user.role.id == roles.PARKING_OPERATOR;

    return (
        <SectionContainer
            title="Reporte de Ingresos Anulados Detallado"
            onBack={() => props.history.push(paths.REPORT_HOME)}
        >
            <Row>
                <Col md={16} lg={13} xl={9} className="mr-lg-3 mb-2">
                    <Row>
                        <Col lg={5}>
                            <span>Fechas:</span>
                        </Col>
                        <Col lg={19}>
                            <DatePicker.RangePicker
                                defaultValue={dates}
                                onChange={onDatesChange}
                                format="DD/MM/YYYY"
                                locale="Es-es"
                            />
                        </Col>
                    </Row>
                </Col>
                {!isScaleOperator && (
                    <Col md={16} lg={13} xl={9} className="mr-lg-3 mb-2">
                        <Row>
                            <Col lg={5}>
                                <span>Puestos:</span>
                            </Col>
                            <Col lg={19}>
                                <Select
                                    mode="multiple"
                                    value={selectedProperties}
                                    className="w-100"
                                    onChange={onPropertiesChange}
                                    allowClear={true}
                                    loading={props.paymentItemsLoading}
                                >
                                    {props.properties.map((item) => (
                                        <Select.Option key={item.id}>{item.code}</Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
            {!isScaleOperator && (
                <Row>
                    <Col md={16} lg={13} xl={9} className="mr-lg-3 mb-2">
                        <Row>
                            <Col lg={5}>
                                <span>Accionistas:</span>
                            </Col>
                            <Col lg={19}>
                                <Select
                                    mode="multiple"
                                    value={selectedShareholders}
                                    className="w-100"
                                    onChange={onShareholdersChange}
                                    allowClear={true}
                                    loading={props.shareholdersLoading}
                                >
                                    {props.shareholders.map((item) => (
                                        <Select.Option key={item.id}>
                                            {formatHelper.getShareholderFullnameWithCode(item)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={16} lg={13} xl={9} className="mr-lg-3 mb-2">
                        <Row>
                            <Col lg={5}>
                                <span>Conceptos:</span>
                            </Col>
                            <Col lg={19}>
                                <Select
                                    mode="multiple"
                                    value={selectedConcepts}
                                    className="w-100"
                                    onChange={onPaymentItemsChange}
                                    allowClear={true}
                                    loading={props.paymentItemsLoading}
                                >
                                    {props.paymentItems.map((item) => (
                                        <Select.Option key={item.id}>
                                            {formatHelper.getPaymentItemWithType(item)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
            <Row className="mt-3">
                <Col md={24} lg={1} xl={1}>
                    <Button
                        icon="download"
                        type="primary"
                        onClick={onPressDownload}
                        loading={props.incomeDetailedReportExcelLoading}
                    >
                        Descargar
                    </Button>
                </Col>
            </Row>
        </SectionContainer>
    );
};

export default IncomeDetailedAnulledReport;
