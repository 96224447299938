import React from 'react';
import { SectionContainer } from '../../../../../components';
import { IProps } from './PaymentItemAddEdit.types';
import { message } from 'antd';
import { paths } from '../../../../../routes';
import { PaymentItemForm } from './components';

export default class PropertyAddEdit extends React.Component<IProps> {
    private propertyId = this.props.match.params['id'];

    componentDidMount() {
        this.props.getPaymentItemTypes();
        this.props.getTaxes();
        if (this.propertyId) this.props.getPaymentItem(this.propertyId);
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkUpdateBankAccount(prevProps);
        this.checkCreateProperty(prevProps);
    }

    componentWillUnmount() {
        this.props.resetPaymentItemTypes();
        this.props.resetTaxes();
        this.props.resetPaymentItem();
    }

    checkCreateProperty(prevProps: IProps) {
        if (!prevProps.createSuccess && this.props.createSuccess) {
            message.success('Concepto de pago creado');
            this.goToPropertyList();
        } else if (!prevProps.createError && this.props.createError)
            message.error(this.props.createError);
    }

    checkUpdateBankAccount(prevProps: IProps) {
        if (!prevProps.updateSuccess && this.props.updateSuccess) {
            message.success('Concepto de pago actualizado');
            this.goToPropertyList();
        } else if (!prevProps.updateError && this.props.updateError)
            message.error(this.props.updateError);
    }

    goToPropertyList = () => {
        this.props.history.push(paths.ADMIN_PAYMENT_ITEM_LIST);
    };

    onSubmit = (values: any) => {
        if (this.propertyId) return this.props.updatePaymentItem(this.propertyId, values);
        return this.props.createPaymentItem(values);
    };

    renderForm = () => {
        const {
            activePaymentItem,
            activePaymentItemLoading,
            createLoading,
            updateLoading,
            paymentItemTypes,
            paymentItemTypesLoading,
            taxes,
            taxesLoading,
        } = this.props;

        return (
            <PaymentItemForm
                initialValues={activePaymentItem || {}}
                paymentItemTypes={paymentItemTypes}
                paymentItemTypesLoading={paymentItemTypesLoading}
                taxes={taxes}
                taxesLoading={taxesLoading}
                onSubmit={this.onSubmit}
                disabled={activePaymentItemLoading}
                submitting={createLoading || updateLoading}
            />
        );
    };

    render() {
        const { activePaymentItemLoading, activePaymentItemError } = this.props;

        return (
            <SectionContainer
                title={this.propertyId ? 'Editar Concepto de Pago' : 'Nuevo Concepto de Pago'}
                onBack={this.goToPropertyList}
                loading={activePaymentItemLoading}
                error={activePaymentItemError}
                content={this.renderForm}
            />
        );
    }
}
