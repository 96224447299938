import shajs from 'sha';
import * as qz from 'qz-tray';
import { IIncome } from '../../types/entities';
import { formatHelper } from '..';
import { receiptTypes, ebilling } from '../../constants';
import {
    printLeftAndRight,
    centerAlign,
    smallFont,
    lineBreak,
    leftAlign,
    kickOutCashDrawer,
    cutPaper,
    printQR,
    printNumberWords,
} from './base';

export function printElectronicBilling(income: IIncome) {
    qz.api.setPromiseType(function promise(resolver) {
        return new Promise(resolver);
    });

    qz.api.setSha256Type(function (data) {
        return shajs.stream(data, { algorithm: 'sha256' });
    });

    qz.websocket
        .connect()
        .then(function () {
            return qz.printers.find('POS-80'); // Pass the printer name into the next Promise
        })
        .then(function (printer) {
            const config = qz.configs.create(printer, { encoding: 'Cp850' }); // Create a default config for the found printer
            const { electronicBilling: eb, property, shareholder, currency } = income;

            let subject = '';

            if (property) {
                subject = printLeftAndRight('Puesto:', property.code);
            } else if (shareholder) {
                subject = printLeftAndRight(
                    'Accionista:',
                    formatHelper.getShareholderFullname(shareholder),
                );
            }

            const items = [];

            for (const item of income.incomePaymentItems) {
                const value = printLeftAndRight(
                    item.paymentItem.description,
                    formatHelper.toMoney(item.editedPrice || item.price),
                );

                items.push(value + lineBreak);

                if (item.comment) items.push(`(${item.comment})${lineBreak}`);
            }

            let voucherTitle = '';
            let docType = '';

            if (eb.tipo_de_comprobante == receiptTypes.BILL) {
                voucherTitle = 'BOLETA ELECTRONICA';

                if (eb.cliente_tipo_de_documento == ebilling.customerDocuments.DNI) docType = 'DNI';
                else if (eb.cliente_tipo_de_documento == ebilling.customerDocuments.SUNDRY)
                    docType = 'VARIOS';
            } else {
                voucherTitle = 'FACTURA ELECTRONICA';
                docType = 'RUC';
            }

            let totalInafecta = '';

            if (eb.total_inafecta != null && eb.total_inafecta > 0) {
                totalInafecta = printLeftAndRight(
                    `OP. Inafecta (${currency.symbol}):`,
                    formatHelper.toMoney(eb.total_inafecta),
                );
            }

            const data = [
                '\x1B' + '\x74' + '\x02', // ver https://qz.io/wiki/Raw-Encoding#escpos
                centerAlign,
                {
                    type: 'raw',
                    format: 'image',
                    data: require('./unicachi-logo.jpg'),
                    options: { language: 'ESCPOS', dotDensity: 'double' },
                },
                '\x1B' + '\x40', // init
                centerAlign,
                'UNICACHI' + lineBreak,
                'RUC 20504868428' + lineBreak,
                voucherTitle + lineBreak,
                `${eb.serie}-${eb.numero}` + lineBreak,
                smallFont,
                lineBreak,
                leftAlign,
                printLeftAndRight('Fecha Emisión:', formatHelper.toDateTime(eb.fecha_de_emision)),
                lineBreak,
                subject,
                lineBreak,
                printLeftAndRight('Cliente:', eb.cliente_denominacion),
                lineBreak,
                printLeftAndRight('Documento:', `${docType} - ${eb.cliente_numero_de_documento}`),
                lineBreak,
                printLeftAndRight('Dirección:', eb.cliente_direccion),
                lineBreak,
                lineBreak,
                '----------------------------------------------------------------' + lineBreak,
                'Detalle                                                    Total' + lineBreak,
                '----------------------------------------------------------------' + lineBreak,
                ...items,
                '----------------------------------------------------------------' + lineBreak,
                totalInafecta,
                printLeftAndRight(
                    `OP. Gravada (${currency.symbol}):`,
                    formatHelper.toMoney(eb.total_gravada),
                ),
                lineBreak,
                printLeftAndRight(
                    `IGV (${eb.porcentaje_de_igv}%):`,
                    formatHelper.toMoney(eb.total_igv),
                ),
                lineBreak,
                printLeftAndRight(
                    `Total Venta (${currency.symbol}):`,
                    formatHelper.toMoney(eb.total),
                ),
                lineBreak,
                lineBreak,
                printNumberWords(eb.total, currency.code),
                lineBreak,
                '----------------------------------------------------------------' + lineBreak,
                'OBSERVACIONES:' + lineBreak,
                'El comprobante electrónico puede ser consultado en' + lineBreak,
                eb.urlVoucher + lineBreak,
                '----------------------------------------------------------------' + lineBreak,
                centerAlign,
                voucherTitle + lineBreak,
                'Venta' + lineBreak,
                ...printQR(eb.qrValue),
                leftAlign,
                lineBreak + lineBreak + lineBreak + lineBreak + lineBreak + lineBreak + lineBreak,
                cutPaper,
                kickOutCashDrawer,
            ];

            return qz.print(config, data);
        })
        .then(function () {
            qz.websocket.disconnect();
            return;
        })
        .catch(function (e) {
            if (qz.websocket.isActive()) qz.websocket.disconnect();
        });
}
