import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import {
    getProperty,
    resetProperty,
    getPropertyShareholders,
    resetPropertyShareholders,
    createPropertyShareholder,
    resetCreatePropertyShareholder,
    updatePropertyShareholder,
    resetUpdatePropertyShareholder,
    deletePropertyShareholder,
    resetDeletePropertyShareholder,
} from '../../../../store/property/actions';
import { IStoreState } from './PropertyShareholderList.types';
import PropertyShareholderList from './PropertyShareholderList';

function mapStateToProps(state: IRootState): IStoreState {
    const {
        activeProperty,
        shareholderList,
        createPropertyShareholder,
        updatePropertyShareholder,
        deletePropertyShareholder,
    } = state.property;

    return {
        property: activeProperty.value,
        loading: activeProperty.loading,
        error: activeProperty.error,
        shareholders: shareholderList.data,
        shareholdersPageSize: shareholderList.pageSize,
        shareholdersTotal: shareholderList.total,
        shareholdersLoading: shareholderList.loading,
        createPropertyShareholderSuccess: createPropertyShareholder.success,
        createPropertyShareholderLoading: createPropertyShareholder.loading,
        createPropertyShareholderError: createPropertyShareholder.error,
        updatePropertyShareholderSuccess: updatePropertyShareholder.success,
        updatePropertyShareholderLoading: updatePropertyShareholder.loading,
        updatePropertyShareholderError: updatePropertyShareholder.error,
        deletePropertyShareholderSuccess: deletePropertyShareholder.success,
        deletePropertyShareholderLoading: deletePropertyShareholder.loading,
        deletePropertyShareholderError: deletePropertyShareholder.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getProperty,
            resetProperty,
            getPropertyShareholders,
            resetPropertyShareholders,
            createPropertyShareholder,
            resetCreatePropertyShareholder,
            deletePropertyShareholder,
            resetDeletePropertyShareholder,
            updatePropertyShareholder,
            resetUpdatePropertyShareholder,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyShareholderList);
