import React, { FC } from 'react';
import { Input } from 'antd';
import { Field, FieldProps } from 'formik';
import { getPropertyByKeyPath } from '../../../common/utils';
import { PasswordProps } from 'antd/lib/input';
import { FieldMessage } from '../..';

export type IInputPasswordFieldProps = PasswordProps;

const InputField: FC<IInputPasswordFieldProps> = ({
    name,
    onChange,
    onBlur,
    className,
    ...restProps
}) => {
    return (
        <Field name={name}>
            {({
                field: { value, onChange: onValueChange, onBlur: onBlurValue },
                form,
            }: FieldProps) => {
                const error = getPropertyByKeyPath(form.errors, name);
                const hasError = error != undefined;
                return (
                    <>
                        <Input.Password
                            name={name}
                            value={value}
                            onChange={e => {
                                onValueChange(e);
                                onChange && onChange(e);
                            }}
                            onBlur={event => {
                                onBlurValue(event);
                                onBlur && onBlur(event);
                            }}
                            className={`form-input ${hasError ? 'has-error' : ''} ${className}`}
                            {...restProps}
                        />
                        <FieldMessage show={hasError} message={error} />
                    </>
                );
            }}
        </Field>
    );
};

export default InputField;
