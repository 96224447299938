import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './IncomeListByShareholders.types';
import IncomeListByProperties from './IncomeListByShareholders';
import { IRootState } from '../../../../../../common/types';
import {
    getIncomesByShareholders,
    resetIncomesByShareholders,
    getIncomeStates,
    resetIncomeStates,
} from '../../../../../../store/income/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { incomeByShareholdersList, incomeStateList } = state.income;

    return {
        incomes: incomeByShareholdersList.data,
        total: incomeByShareholdersList.total,
        pageSize: incomeByShareholdersList.pageSize,
        loading: incomeByShareholdersList.loading,
        error: incomeByShareholdersList.error,
        incomeStates: incomeStateList.data,
        incomeStatesLoading: incomeStateList.loading,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getIncomesByShareholders,
            resetIncomesByShareholders,
            getIncomeStates,
            resetIncomeStates,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomeListByProperties);
