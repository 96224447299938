import { IMenuItem } from '../../../../common/types';
import { paths } from '../../../../routes/paths';
import { permissions } from '../../../../common/constants';

export const menuItems: IMenuItem[] = [
    {
        icon: 'arrow-up',
        label: 'Ingresos',
        basepath: '/ingresos',
        key: 'administracion/ingresos',
        permissions: [permissions.income.READ_PENDING_INCOMES, permissions.income.COMPLETE_INCOMES],
        items: [
            {
                icon: 'clock-circle',
                label: 'Pendientes',
                path: paths.ADMIN_INCOME_PENDING_LIST,
                basepath: 'administracion/ingresos/pendientes',
                permission: permissions.income.READ_PENDING_INCOMES,
            },
            {
                icon: 'plus',
                label: 'Nuevo',
                path: paths.ADMIN_INCOME_CREATE,
                basepath: paths.ADMIN_INCOME_CREATE,
                permission: permissions.income.COMPLETE_INCOMES,
            },
            {
                icon: 'arrow-up',
                label: 'Completados',
                path: paths.ADMIN_INCOME_COMPLETE_LIST,
                basepath: paths.ADMIN_INCOME_COMPLETE_LIST,
                permission: permissions.income.READ_COMPLETED_INCOMES,
            },
            {
                icon: 'tags',
                label: 'Conceptos de Pago',
                path: paths.ADMIN_PAYMENT_ITEM_LIST,
                basepath: paths.ADMIN_PAYMENT_ITEM_LIST,
                permission: permissions.paymentItem.READ_PAYMENT_ITEMS,
            },
            {
                icon: 'unordered-list',
                label: 'Tipos de Concepto',
                path: paths.ADMIN_PAYMENT_ITEM_TYPE_LIST,
                basepath: paths.ADMIN_PAYMENT_ITEM_TYPE_LIST,
                permission: permissions.paymentItemType.READ_PAYMENT_ITEM_TYPES,
            },
        ],
    },
    {
        icon: 'arrow-down',
        label: 'Egresos',
        basepath: '/egresos',
        key: 'administracion/egresos',
        permissions: [permissions.expense.READ_EXPENSES, permissions.pettyCash.READ_PETTY_CASH],
        items: [
            {
                icon: 'arrow-down',
                label: 'Requerimientos',
                path: paths.ADMIN_EXPENSE_LIST,
                basepath: paths.ADMIN_EXPENSE_LIST,
                permission: permissions.expense.READ_EXPENSES,
            },
            {
                icon: 'audit',
                label: 'Facturas',
                path: paths.ADMIN_INVOICE_LIST,
                basepath: paths.ADMIN_INVOICE_LIST,
                permission: permissions.expense.READ_INVOICES,
            },
            {
                icon: 'dollar',
                label: 'Pagos',
                path: paths.ADMIN_PAYMENT_LIST,
                basepath: paths.ADMIN_PAYMENT_LIST,
                permission: permissions.expense.READ_PAYMENTS,
            },
            {
                icon: 'container',
                label: 'Caja Chica',
                path: paths.ADMIN_PETTY_CASH_LIST,
                basepath: paths.ADMIN_PETTY_CASH_LIST,
                permission: permissions.pettyCash.READ_PETTY_CASH,
            },
        ],
    },
    {
        icon: 'environment',
        label: 'Puestos',
        basepath: '/puestos',
        key: 'administracion/puestos',
        permission: permissions.property.READ_PROPERTIES,
        items: [
            {
                icon: 'environment',
                label: 'Listado',
                path: paths.ADMIN_PROPERTY_LIST,
                basepath: 'administracion/puestos',
                permission: permissions.property.READ_PROPERTIES,
            },
            {
                icon: 'environment',
                label: 'Zonas',
                path: paths.ADMIN_ZONE_LIST,
                basepath: '/administracion/zonas',
                permission: permissions.property.READ_ZONES,
            },
            {
                icon: 'environment',
                label: 'Giros',
                path: paths.ADMIN_SUBZONE_LIST,
                basepath: '/administracion/giros',
                permission: permissions.property.READ_SUBZONES,
            },
        ],
    },
    {
        icon: 'user',
        label: 'Accionistas',
        path: paths.ADMIN_SHAREHOLDER_LIST,
        basepath: '/administracion/accionistas',
        permission: permissions.shareholder.READ_SHAREHOLDERS,
    },
    {
        icon: 'audit',
        label: 'Proveedores',
        path: paths.ADMIN_PROVIDER_LIST,
        basepath: '/proveedores',
        permission: permissions.provider.READ_PROVIDERS,
    },
    {
        icon: 'bank',
        label: 'Bancos',
        path: paths.ADMIN_BANK_LIST,
        basepath: '/administracion/bancos',
        permission: permissions.bank.READ_BANKS,
    },
    {
        icon: 'credit-card',
        label: 'Cuentas Bancarias',
        path: paths.ADMIN_BANK_ACCOUNT_LIST,
        basepath: '/administracion/cuentas-bancarias',
        permission: permissions.bankAccount.READ_BANK_ACCOUNTS,
    },
    {
        icon: 'user',
        label: 'Usuarios',
        path: paths.ADMIN_USER_LIST,
        basepath: '/administracion/usuarios',
        permission: permissions.user.READ_USERS,
    },
    {
        icon: 'line-chart',
        label: 'Reportes',
        path: paths.REPORT_HOME,
        basepath: '/reportes',
        permission: permissions.report.READ_REPORTS,
    },
    {
        icon: 'setting',
        label: 'Configuración',
        path: paths.CONFIGURE_HOME,
        basepath: '/configuracion',
        permission: permissions.configure.READ_CONFIGURES,
    },
];
