import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import {
    TableCellItem,
    TableRowOptionsDropdown,
    TableRowOptionItem,
} from '../../../../../components';
import { IProperty } from '../../../../../common/types/entities';
import { permissions } from '../../../../../common/constants';
import { Button } from 'antd';

export const columns = (
    onClickDetail: (id: number) => void,
    onClickEdit: (id: number) => void,
    onClickProperties: (id: number) => void,
    onClickDelete: (id: number) => void,
    permissionCodes: string[],
): ColumnProps<IProperty>[] => [
    {
        title: 'Código',
        dataIndex: 'code',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Zona',
        dataIndex: 'zone.name',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Giro',
        dataIndex: 'subzone.name',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Acciones',
        dataIndex: 'shares',
        className: 'd-none d-md-table-cell',
    },
    {
        title: 'Área',
        dataIndex: 'area',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Puesto',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IProperty) => (
            <>
                <TableCellItem label="Código" value={item.code} />
                <TableCellItem label="Zona" value={item.zone ? item.zone.name : ''} />
                <TableCellItem label="Giro" value={item.subzone ? item.subzone.name : ''} />
                <TableCellItem label="Acciones" value={item.shares} />
                <TableCellItem label="Área" value={item.area} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IProperty) => {
            if (permissionCodes.includes(permissions.property.WRITE_PROPERTIES)) {
                return (
                    <TableRowOptionsDropdown
                        onClickDetail={() => onClickDetail(item.id)}
                        onClickEdit={() => onClickEdit(item.id)}
                        onClickDelete={() => onClickDelete(item.id)}
                    >
                        <TableRowOptionItem
                            icon="user"
                            label="Accionistas"
                            onClick={() => onClickProperties(item.id)}
                        />
                    </TableRowOptionsDropdown>
                );
            } else if (permissionCodes.includes(permissions.property.READ_PROPERTY_DETAIL)) {
                return (
                    <Button
                        type="primary"
                        shape="round"
                        icon="eye"
                        onClick={() => onClickDetail(item.id)}
                    />
                );
            }
        },
    },
];
