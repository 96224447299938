import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { zoneService } from '../../service';
import { IZone } from '../../common/types/entities';

export function getZones(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => zoneService.getAll(filter, page),
        types: [
            actionTypes.GET_ZONES,
            actionTypes.GET_ZONES_SUCCESS,
            actionTypes.GET_ZONES_FAILURE,
        ],
    };
}

export function resetZones() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_ZONES_RESET,
        });
    };
}

export function getZone(id: number): IAPIMiddleware {
    return {
        callAPI: () => zoneService.get(id),
        types: [actionTypes.GET_ZONE, actionTypes.GET_ZONE_SUCCESS, actionTypes.GET_ZONE_FAILURE],
    };
}

export function resetZone() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_ZONE_RESET,
        });
    };
}

export function createZone(data: IZone): IAPIMiddleware {
    return {
        callAPI: () => zoneService.create(data),
        types: [
            actionTypes.CREATE_ZONE,
            actionTypes.CREATE_ZONE_SUCCESS,
            actionTypes.CREATE_ZONE_FAILURE,
        ],
    };
}

export function updateZone(id: number, data: IZone): IAPIMiddleware {
    return {
        callAPI: () => zoneService.update(id, data),
        types: [
            actionTypes.UPDATE_ZONE,
            actionTypes.UPDATE_ZONE_SUCCESS,
            actionTypes.UPDATE_ZONE_FAILURE,
        ],
    };
}

export function deactivateZone(id: number): IAPIMiddleware {
    return {
        callAPI: () => zoneService.deactivate(id),
        types: [
            actionTypes.DEACTIVATE_ZONE,
            actionTypes.DEACTIVATE_ZONE_SUCCESS,
            actionTypes.DEACTIVATE_ZONE_FAILURE,
        ],
    };
}

export function getZoneProperties(
    zoneId: number,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => zoneService.getProperties(zoneId, filter, page),
        types: [
            actionTypes.GET_ZONE_PROPERTIES,
            actionTypes.GET_ZONE_PROPERTIES_SUCCESS,
            actionTypes.GET_ZONE_PROPERTIES_FAILURE,
        ],
    };
}

export function resetZoneProperties() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_ZONE_PROPERTIES_RESET,
        });
    };
}
