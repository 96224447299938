import React from 'react';
import { FormikActions } from 'formik';
import { IBankAccount, IPaymentType, IRequestOrder } from '../../../../../../common/types/entities';
import { messages } from '../../../../../../common/constants';
import {
    CustomForm,
    SelectGroupField,
    DatePickerGroupField,
    DisplayItem,
    InputGroupField,
} from '../../../../../../components';

interface IOwnProps {
    bankAccounts: IBankAccount[];
    bankAccountsLoading: boolean;
    paymentTypes: IPaymentType[];
    paymentTypesLoading: boolean;
    requestOrderSelected: IRequestOrder;
    setExchangeRate: (value: any) => void;
    exchangeRate: number;
    setShowExchangeRate: (value: boolean) => void;
    showExchangeRate: boolean;
    resetPaymentDetailsToEdit: () => void;
}

interface IOwnState {
    currency: string;
}

type IProps = IOwnProps;

export interface IPaymentFormValues {
    paymentDate: Date | string;
    paymentType: IPaymentType | string;
    bankAccount: IBankAccount | string;
    currency: string;
    exchangeRate: number | string;
}

export default class IPaymentForm extends CustomForm<IPaymentFormValues, IProps> {
    state: IOwnState = {
        currency: '',
    };

    onSelectBankAccount = (id: number) => {
        const bankAccountSelected = this.props.bankAccounts.find(e => e.id == id);
        this.setState({ currency: bankAccountSelected.currency.code });
        if (bankAccountSelected.currency.id != this.props.requestOrderSelected.currency.id) {
            this.props.setShowExchangeRate(true);
        } else {
            this.props.setExchangeRate(undefined);
            this.props.setShowExchangeRate(false);
        }

        this.props.resetPaymentDetailsToEdit();
    };

    initialValues = (): IPaymentFormValues => {
        return {
            paymentDate: undefined,
            paymentType: undefined,
            bankAccount: undefined,
            currency: undefined,
            exchangeRate: undefined,
        };
    };

    onSubmit = (values: IPaymentFormValues, actions: FormikActions<IPaymentFormValues>) => {
        this.props.onSubmit(values);
        actions.setSubmitting(false);
    };

    validate = (values: IPaymentFormValues) => {
        const errors: Partial<IPaymentFormValues> = {};

        if (!values.paymentDate) errors.paymentDate = messages.MANDATORY_FIELD;
        if (!values.paymentType) errors.paymentType = messages.MANDATORY_FIELD;
        if (!values.bankAccount) errors.bankAccount = messages.MANDATORY_FIELD;
        if (this.props.showExchangeRate && !values.exchangeRate)
            errors.exchangeRate = messages.MANDATORY_FIELD;

        return errors;
    };

    onCancel = () => this.props.onCancel();

    formBody = () => {
        const {
            setExchangeRate,
            bankAccounts,
            bankAccountsLoading,
            paymentTypes,
            paymentTypesLoading,
        } = this.props;

        return (
            <>
                <SelectGroupField
                    name="bankAccount"
                    label="Cuenta Bancaria"
                    data={bankAccounts}
                    displayText={(item: IBankAccount) =>
                        item.bank.name + ' - Nº. Cuenta: ' + item.number
                    }
                    onChange={e => this.onSelectBankAccount(Number(e))}
                    loading={bankAccountsLoading}
                    required
                />
                <DisplayItem
                    label="Moneda de la Cuenta Bancaria"
                    value={this.state.currency || ''}
                    lmd={4}
                    llg={3}
                    lxl={2}
                    lxxl={2}
                />
                <SelectGroupField
                    name="paymentType"
                    label="Tipo de Pago"
                    data={paymentTypes}
                    displayText="description"
                    loading={paymentTypesLoading}
                    required
                />
                <DatePickerGroupField name="paymentDate" label="Fecha de Pago" required />
                {this.props.showExchangeRate && (
                    <InputGroupField
                        name="exchangeRate"
                        value={this.props.exchangeRate}
                        label="Tipo de Cambio"
                        normalize="numbersWithDecimals"
                        onChange={e => setExchangeRate(e.target.value)}
                        required
                    />
                )}
            </>
        );
    };
}
