import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './IncomePendingListByProperties.types';
import PendingIncomeByPropertiesList from './IncomePendingListByProperties';
import { IRootState } from '../../../../../../common/types';
import {
    getIncomePendingsByProperties,
    resetIncomePendingsByProperties,
    createIncomePropertyPaymentItem,
    resetCreateIncomePropertyPaymentItem,
    bulkLoadIncomePaymentItemsByProperties,
} from '../../../../../../store/income/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const {
        pendingListByProperties,
        deleteIncome,
        bulkLoadIncomePaymentItemsByProperties,
        createIncomePropertyPaymentItem,
    } = state.income;

    return {
        properties: pendingListByProperties.data,
        total: pendingListByProperties.total,
        pageSize: pendingListByProperties.pageSize,
        loading: pendingListByProperties.loading,
        error: pendingListByProperties.error,
        createIncomePaymentItemSuccess: createIncomePropertyPaymentItem.success,
        createIncomePaymentItemLoading: createIncomePropertyPaymentItem.loading,
        createIncomePaymentItemError: createIncomePropertyPaymentItem.error,
        deleteLoading: deleteIncome.loading,
        deleteSuccess: deleteIncome.success,
        deleteError: deleteIncome.error,
        bulkLoadSuccess: bulkLoadIncomePaymentItemsByProperties.success,
        bulkLoadLoading: bulkLoadIncomePaymentItemsByProperties.loading,
        bulkLoadError: bulkLoadIncomePaymentItemsByProperties.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getIncomePendingsByProperties,
            resetIncomePendingsByProperties,
            createIncomePropertyPaymentItem,
            resetCreateIncomePropertyPaymentItem,
            bulkLoadIncomePaymentItemsByProperties,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingIncomeByPropertiesList);
