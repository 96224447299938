import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware, ICompletePaymentData } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { incomeService, storageService } from '../../service';
import { IIncome, IAttachedFile } from '../../common/types/entities';
import { errorHelper } from '../../common/helpers';

export function getIncomesByProperties(
    stateId = 0,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => incomeService.getAllByProperties(stateId, filter, page),
        types: [
            actionTypes.GET_INCOMES_BY_PROPERTIES,
            actionTypes.GET_INCOMES_BY_PROPERTIES_SUCCESS,
            actionTypes.GET_INCOMES_BY_PROPERTIES_FAILURE,
        ],
    };
}

export function resetIncomesByProperties() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_INCOMES_BY_PROPERTIES_RESET,
        });
    };
}

export function getIncomesByShareholders(
    stateId = 0,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => incomeService.getAllByShareholders(stateId, filter, page),
        types: [
            actionTypes.GET_INCOMES_BY_SHAREHOLDERS,
            actionTypes.GET_INCOMES_BY_SHAREHOLDERS_SUCCESS,
            actionTypes.GET_INCOMES_BY_SHAREHOLDERS_FAILURE,
        ],
    };
}

export function resetIncomesByShareholders() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_INCOMES_BY_SHAREHOLDERS_RESET,
        });
    };
}

export function getIncomePendingsByProperties(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => incomeService.getPendingsByProperties(filter, page),
        types: [
            actionTypes.GET_PENDINGS_BY_PROPERTIES,
            actionTypes.GET_PENDINGS_BY_PROPERTIES_SUCCESS,
            actionTypes.GET_PENDINGS_BY_PROPERTIES_FAILURE,
        ],
    };
}

export function resetIncomePendingsByProperties() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PENDINGS_BY_PROPERTIES_RESET,
        });
    };
}

export function getIncomePendingsByShareholders(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => incomeService.getPendingsByShareholders(filter, page),
        types: [
            actionTypes.GET_PENDINGS_BY_SHAREHOLDERS,
            actionTypes.GET_PENDINGS_BY_SHAREHOLDERS_SUCCESS,
            actionTypes.GET_PENDINGS_BY_SHAREHOLDERS_FAILURE,
        ],
    };
}

export function resetIncomePendingsByShareholders() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PENDINGS_BY_SHAREHOLDERS_RESET,
        });
    };
}

export function createIncomePropertyPaymentItem(data: any, file?: any) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_INCOME_PROPERTY_PAYMENT_ITEM,
        });

        try {
            let uploadedFile: IAttachedFile = null;

            if (file) {
                const fileResponse = await storageService.incomePaymentItemImage(
                    file.originFileObj,
                );

                uploadedFile = fileResponse.data;
            }

            data.attachedFiles = uploadedFile ? [uploadedFile] : null;
            delete data.image;

            await incomeService.createIncomePaymentItem(data);

            dispatch({
                type: actionTypes.CREATE_INCOME_PROPERTY_PAYMENT_ITEM_SUCCESS,
            });
        } catch (error) {
            await errorHelper.handle(
                error,
                dispatch,
                actionTypes.CREATE_INCOME_PROPERTY_PAYMENT_ITEM_FAILURE,
            );
        }
    };
}

export function resetCreateIncomePropertyPaymentItem() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_INCOME_PROPERTY_PAYMENT_ITEM_RESET,
        });
    };
}

export function createIncomeShareholderPaymentItem(data: any, file?: any) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM,
        });

        try {
            let uploadedFile: IAttachedFile = null;

            if (file) {
                const fileResponse = await storageService.incomePaymentItemImage(
                    file.originFileObj,
                );

                uploadedFile = fileResponse.data;
            }

            data.attachedFiles = uploadedFile ? [uploadedFile] : null;
            delete data.image;

            await incomeService.createIncomePaymentItem(data);

            dispatch({
                type: actionTypes.CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_SUCCESS,
            });
        } catch (error) {
            await errorHelper.handle(
                error,
                dispatch,
                actionTypes.CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_FAILURE,
            );
        }
    };
}

export function resetCreateIncomeShareholderPaymentItem() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_INCOME_SHAREHOLDER_PAYMENT_ITEM_RESET,
        });
    };
}

export function getIncome(id: number): IAPIMiddleware {
    return {
        callAPI: () => incomeService.get(id),
        types: [
            actionTypes.GET_INCOME,
            actionTypes.GET_INCOME_SUCCESS,
            actionTypes.GET_INCOME_FAILURE,
        ],
    };
}

export function resetIncome() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_INCOME_RESET,
        });
    };
}

export function createIncome(data: IIncome) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_INCOME,
        });

        try {
            for (const item of data.incomePaymentItems) {
                const image = (item as any).image;
                let uploadedFile: IAttachedFile = null;

                if (image && image.length > 0) {
                    const fileResponse = await storageService.incomePaymentItemImage(
                        image[0].originFileObj,
                    );
                    uploadedFile = fileResponse.data;
                }

                item.attachedFiles = uploadedFile ? [uploadedFile] : null;
                delete (item as any).image;
            }

            const response = await incomeService.create(data);

            dispatch({
                type: actionTypes.CREATE_INCOME_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            await errorHelper.handle(error, dispatch, actionTypes.CREATE_INCOME_FAILURE);
        }
    };
}

export function updateIncome(id: number, data: IIncome): IAPIMiddleware {
    return {
        callAPI: () => incomeService.update(id, data),
        types: [
            actionTypes.UPDATE_INCOME,
            actionTypes.UPDATE_INCOME_SUCCESS,
            actionTypes.UPDATE_INCOME_FAILURE,
        ],
    };
}

export function completeIncomePayment(data: any): IAPIMiddleware {
    return {
        callAPI: () => incomeService.completePayment(data),
        types: [
            actionTypes.COMPLETE_PAYMENT,
            actionTypes.COMPLETE_PAYMENT_SUCCESS,
            actionTypes.COMPLETE_PAYMENT_FAILURE,
        ],
    };
}

export function deactivateIncome(id: number): IAPIMiddleware {
    return {
        callAPI: () => incomeService.deactivate(id),
        types: [
            actionTypes.DEACTIVATE_INCOME,
            actionTypes.DEACTIVATE_INCOME_SUCCESS,
            actionTypes.DEACTIVATE_INCOME_FAILURE,
        ],
    };
}

export function deleteIncome(id: number): IAPIMiddleware {
    return {
        callAPI: () => incomeService.deleteIncome(id),
        types: [
            actionTypes.DELETE_INCOME,
            actionTypes.DELETE_INCOME_SUCCESS,
            actionTypes.DELETE_INCOME_FAILURE,
        ],
    };
}

export function getIncomeTypes(): IAPIMiddleware {
    return {
        callAPI: () => incomeService.getTypes(),
        types: [
            actionTypes.GET_INCOME_TYPES,
            actionTypes.GET_INCOME_TYPES_SUCCESS,
            actionTypes.GET_INCOME_TYPES_FAILURE,
        ],
    };
}

export function resetIncomeTypes() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_INCOME_TYPES_RESET,
        });
    };
}

export function getIncomeStates(): IAPIMiddleware {
    return {
        callAPI: () => incomeService.getStates(),
        types: [
            actionTypes.GET_INCOME_STATES,
            actionTypes.GET_INCOME_STATES_SUCCESS,
            actionTypes.GET_INCOME_STATES_FAILURE,
        ],
    };
}

export function resetIncomeStates() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_INCOME_STATES_RESET,
        });
    };
}

export function getPaymentTypes(): IAPIMiddleware {
    return {
        callAPI: () => incomeService.getPaymentTypes(),
        types: [
            actionTypes.GET_PAYMENT_TYPES,
            actionTypes.GET_PAYMENT_TYPES_SUCCESS,
            actionTypes.GET_PAYMENT_TYPES_FAILURE,
        ],
    };
}

export function resetPaymentTypes() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PAYMENT_TYPES_RESET,
        });
    };
}

export function getReceiptTypes(): IAPIMiddleware {
    return {
        callAPI: () => incomeService.getReceiptTypes(),
        types: [
            actionTypes.GET_RECEIPT_TYPES,
            actionTypes.GET_RECEIPT_TYPES_SUCCESS,
            actionTypes.GET_RECEIPT_TYPES_FAILURE,
        ],
    };
}

export function resetReceiptTypes() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_RECEIPT_TYPES_RESET,
        });
    };
}

export function bulkLoadIncomePaymentItemsByProperties(file: any): IAPIMiddleware {
    return {
        callAPI: () => incomeService.bulkLoadPaymentItemsByProperties(file),
        types: [
            actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES,
            actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_SUCCESS,
            actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_FAILURE,
        ],
    };
}

export function resetBulkLoadIncomePaymentItemsByProperties() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_PROPERTIES_RESET,
        });
    };
}

export function bulkLoadIncomePaymentItemsByShareholders(file: any): IAPIMiddleware {
    return {
        callAPI: () => incomeService.bulkLoadPaymentItemsByShareholders(file),
        types: [
            actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS,
            actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_SUCCESS,
            actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_FAILURE,
        ],
    };
}

export function resetBulkLoadIncomePaymentItemsByShareholders() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.BULK_LOAD_INCOME_PAYMENT_ITEMS_BY_SHAREHOLDERS_RESET,
        });
    };
}

export function getPropertyIncomePaymentItems(propertyId: number, filter: string): IAPIMiddleware {
    return {
        callAPI: () => incomeService.getPropertyIncomePaymentItems(propertyId, filter),
        types: [
            actionTypes.GET_PROPERTY_INCOME_PAYMENT_ITEMS,
            actionTypes.GET_PROPERTY_INCOME_PAYMENT_ITEMS_SUCCESS,
            actionTypes.GET_PROPERTY_INCOME_PAYMENT_ITEMS_FAILURE,
        ],
    };
}

export function resetPropertyIncomePaymentItems() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PROPERTY_INCOME_PAYMENT_ITEMS_RESET,
        });
    };
}

export function getShareholderIncomePaymentItems(
    shareholderId: number,
    filter: string,
): IAPIMiddleware {
    return {
        callAPI: () => incomeService.getShareholderIncomePaymentItems(shareholderId, filter),
        types: [
            actionTypes.GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS,
            actionTypes.GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_SUCCESS,
            actionTypes.GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_FAILURE,
        ],
    };
}

export function resetShareholderIncomePaymentItems() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SHAREHOLDER_INCOME_PAYMENT_ITEMS_RESET,
        });
    };
}

export function deleteIncomePaymentItem(id: number): IAPIMiddleware {
    return {
        callAPI: () => incomeService.deleteIncomePaymentItem(id),
        types: [
            actionTypes.DELETE_INCOME_PAYMENT_ITEM,
            actionTypes.DELETE_INCOME_PAYMENT_ITEM_SUCCESS,
            actionTypes.DELETE_INCOME_PAYMENT_ITEM_FAILURE,
        ],
    };
}

export function resetDeleteIncomePaymentItem() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_INCOME_PAYMENT_ITEM_RESET,
        });
    };
}

export function cancelIncome(id: number, data: any): IAPIMiddleware {
    return {
        callAPI: () => incomeService.cancelIncome(id, data),
        types: [
            actionTypes.CANCEL_INCOME,
            actionTypes.CANCEL_INCOME_SUCCESS,
            actionTypes.CANCEL_INCOME_FAILURE,
        ],
    };
}

export function resetCancelIncome() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CANCEL_INCOME_RESET,
        });
    };
}

export function setCompletePaymentData(data: ICompletePaymentData) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.SET_COMPLETE_PAYMENT_DATA,
            payload: data,
        });
    };
}

export function resetCompletePaymentData() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.SET_COMPLETE_PAYMENT_DATA_RESET,
        });
    };
}

export function condoneIncomePaymentItem(id: number, data: any): IAPIMiddleware {
    return {
        callAPI: () => incomeService.condoneIncomePaymentItem(id, data),
        types: [
            actionTypes.CONDONE_INCOME_PAYMENT_ITEM,
            actionTypes.CONDONE_INCOME_PAYMENT_ITEM_SUCCESS,
            actionTypes.CONDONE_INCOME_PAYMENT_ITEM_FAILURE,
        ],
    };
}

export function resetCondoneIncomePaymentItem() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CONDONE_INCOME_PAYMENT_ITEM_RESET,
        });
    };
}

export function verifyPrintIncome(id: number): IAPIMiddleware {
    return {
        callAPI: () => incomeService.verifyPrintIncome(id),
        types: [
            actionTypes.VERIFY_PRINT_INCOME,
            actionTypes.VERIFY_PRINT_INCOME_SUCCESS,
            actionTypes.VERIFY_PRINT_INCOME_FAILURE,
        ],
    };
}

export function resetVerifyPrintIncome() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.VERIFY_PRINT_INCOME_RESET,
        });
    };
}

export function completePendingIncome(id: number): IAPIMiddleware {
    return {
        callAPI: () => incomeService.completePendingIncome(id),
        types: [
            actionTypes.COMPLETE_PENDING_INCOME,
            actionTypes.COMPLETE_PENDING_INCOME_SUCCESS,
            actionTypes.COMPLETE_PENDING_INCOME_FAILURE,
        ],
    };
}

export function resetCompletePendingIncome() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.COMPLETE_PENDING_INCOME_RESET,
        });
    };
}
