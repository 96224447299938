import React from 'react';
import { Result, Button } from 'antd';
import { RouteComponentProps } from 'react-router-dom';

type IProps = RouteComponentProps;

const Page404: React.FC<IProps> = ({ history }) => {
    const goToHome = () => {
        history.push('/');
    };

    return (
        <Result
            status="404"
            title="404"
            subTitle="Oops! Estás perdido. La página que estás buscando no ha sido encontrada."
            extra={
                <Button type="primary" onClick={goToHome}>
                    Volver al inicio
                </Button>
            }
        />
    );
};

export default Page404;
