import React, { FC } from 'react';
import { Switch } from 'antd';
import { Field, FieldProps } from 'formik';
import { SwitchProps } from 'antd/lib/switch';

export type ISwitchFieldProps = SwitchProps & { name: string };

const InputField: FC<ISwitchFieldProps> = ({ name, onChange, ...restProps }) => {
    return (
        <Field name={name}>
            {({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => {
                return (
                    <Switch
                        checked={value}
                        onChange={(checked, event) => {
                            setFieldValue(name, checked);
                            setFieldTouched(name, true);
                            onChange && onChange(checked, event);
                        }}
                        {...restProps}
                    />
                );
            }}
        </Field>
    );
};

export default InputField;
