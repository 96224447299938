import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { changePassword } from '../../store/auth/actions';
import { updateAuthorizationToken, resetUpdateAuthorizationToken } from '../../store/user/actions';
import { IStoreState } from './UserProfile.types';
import UserDetail from './UserProfile';
import { IRootState } from '../../common/types';

function mapStateToProps(state: IRootState): IStoreState {
    const { loggedInUser, changePassword } = state.auth;
    const { updateAuthorizationToken } = state.user;

    return {
        user: loggedInUser.value,
        loading: loggedInUser.loading,
        error: loggedInUser.error,
        changePasswordRespond: {
            success: changePassword.success,
            loading: changePassword.loading,
            error: changePassword.error,
        },
        updateAuthorizationTokenSuccess: updateAuthorizationToken.success,
        updateAuthorizationTokenLoading: updateAuthorizationToken.loading,
        updateAuthorizationTokenError: updateAuthorizationToken.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            changePassword,
            updateAuthorizationToken,
            resetUpdateAuthorizationToken,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
