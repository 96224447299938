import React from 'react';

interface IOwnProps {
    message: string;
    show?: boolean;
}

type IProps = IOwnProps;

const FieldMessage: React.FC<IProps> = ({ message, show }) => {
    return <span className={`input-error-feedback ${show ? 'active' : ''}`}>{message}</span>;
};

FieldMessage.defaultProps = {
    show: false,
};

export default FieldMessage;
