import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
    objectStateLoading,
    objectStateSuccess,
    objectStateFailure,
    processStateLoading,
    processStateSuccess,
    processStateFailure,
    listStateLoading,
    listStateSuccess,
    listStateFailure,
} from '../../common/helpers/reducer';

//#region GET PROPERTIES
export function getProperties(state: IState): IState {
    return {
        ...state,
        propertyList: listStatePaginatedLoading(state.propertyList),
    };
}

export function getPropertiesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        propertyList: listStatePaginatedSuccess(action.payload),
    };
}

export function getPropertiesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        propertyList: listStatePaginatedFailure(initialState.propertyList, action.payload.error),
    };
}

export function getPropertiesReset(state: IState): IState {
    return {
        ...state,
        propertyList: initialState.propertyList,
    };
}
//#endregion

//#region GET PROPERTY
export function getProperty(state: IState): IState {
    return {
        ...state,
        activeProperty: objectStateLoading(),
    };
}

export function getPropertySuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activeProperty: objectStateSuccess(action.payload),
    };
}

export function getPropertyFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activeProperty: objectStateFailure(action.payload.error),
    };
}

export function getPropertyReset(state: IState): IState {
    return {
        ...state,
        activeProperty: initialState.activeProperty,
    };
}
//#endregion

//#region CREATE PROPERTY
export function createProperty(state: IState): IState {
    return {
        ...state,
        createProperty: processStateLoading(),
    };
}

export function createPropertySuccess(state: IState): IState {
    return {
        ...state,
        createProperty: processStateSuccess(),
    };
}

export function createPropertyFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createProperty: processStateFailure(action.payload.error),
    };
}

export function createPropertyReset(state: IState): IState {
    return {
        ...state,
        createProperty: initialState.createProperty,
    };
}
//#endregion

//#region UPDATE PROPERTY
export function updateProperty(state: IState): IState {
    return {
        ...state,
        updateProperty: processStateLoading(),
    };
}

export function updatePropertySuccess(state: IState): IState {
    return {
        ...state,
        updateProperty: processStateSuccess(),
    };
}

export function updatePropertyFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateProperty: processStateFailure(action.payload.error),
    };
}

export function updatePropertyReset(state: IState): IState {
    return {
        ...state,
        updateProperty: initialState.updateProperty,
    };
}
//#endregion

//#region DEACTIVATE PROPERTY
export function deactivateProperty(state: IState): IState {
    return {
        ...state,
        deactivateProperty: processStateLoading(),
    };
}

export function deactivatePropertySuccess(state: IState): IState {
    return {
        ...state,
        deactivateProperty: processStateSuccess(),
    };
}

export function deactivatePropertyFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateProperty: processStateFailure(action.payload.error),
    };
}

export function deactivatePropertyReset(state: IState): IState {
    return {
        ...state,
        deactivateProperty: initialState.deactivateProperty,
    };
}
//#endregion

//#region GET PROPERTY SHAREHOLDERS
export function getPropertyShareholders(state: IState): IState {
    return {
        ...state,
        shareholderList: listStatePaginatedLoading(state.shareholderList),
    };
}

export function getPropertyShareholdersSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        shareholderList: listStatePaginatedSuccess(action.payload),
    };
}

export function getPropertyShareholdersFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        shareholderList: listStatePaginatedFailure(
            initialState.shareholderList,
            action.payload.error,
        ),
    };
}

export function getPropertyShareholdersReset(state: IState): IState {
    return {
        ...state,
        shareholderList: initialState.shareholderList,
    };
}
//#endregion

//#region CREATE PROPERTY SHAREHOLDER
export function createPropertyShareholder(state: IState): IState {
    return {
        ...state,
        createPropertyShareholder: { success: false, loading: true, error: undefined },
    };
}

export function createPropertyShareholderSuccess(state: IState): IState {
    return {
        ...state,
        createPropertyShareholder: { success: true, loading: false, error: undefined },
    };
}

export function createPropertyShareholderFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createPropertyShareholder: { success: false, loading: false, error: action.payload.error },
    };
}

export function createPropertyShareholderReset(state: IState): IState {
    return {
        ...state,
        createPropertyShareholder: initialState.createPropertyShareholder,
    };
}
//#endregion

//#region UPDATE PROPERTY SHAREHOLDER
export function updatePropertyShareholder(state: IState): IState {
    return {
        ...state,
        updatePropertyShareholder: { success: false, loading: true, error: undefined },
    };
}

export function updatePropertyShareholderSuccess(state: IState): IState {
    return {
        ...state,
        updatePropertyShareholder: { success: true, loading: false, error: undefined },
    };
}

export function updatePropertyShareholderFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updatePropertyShareholder: { success: false, loading: false, error: action.payload.error },
    };
}

export function updatePropertyShareholderReset(state: IState): IState {
    return {
        ...state,
        updatePropertyShareholder: initialState.updatePropertyShareholder,
    };
}
//#endregion

//#region GET AVAILABLE SHAREHOLDERS
export function getAvailableShareholders(state: IState): IState {
    return {
        ...state,
        availableShareholderList: listStateLoading(),
    };
}

export function getAvailableShareholdersSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        availableShareholderList: listStateSuccess(action.payload.data),
    };
}

export function getAvailableShareholdersFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        availableShareholderList: listStateFailure(action.payload.error),
    };
}

export function getAvailableShareholdersReset(state: IState): IState {
    return {
        ...state,
        availableShareholderList: initialState.availableShareholderList,
    };
}
//#endregion

//#region DELETE PROPERTY SHAREHOLDER
export function deletePropertyShareholder(state: IState): IState {
    return {
        ...state,
        deletePropertyShareholder: { success: false, loading: true, error: undefined },
    };
}

export function deletePropertyShareholderSuccess(state: IState): IState {
    return {
        ...state,
        deletePropertyShareholder: { success: true, loading: false, error: undefined },
    };
}

export function deletePropertyShareholderFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deletePropertyShareholder: { success: false, loading: false, error: action.payload.error },
    };
}

export function deletePropertyShareholderReset(state: IState): IState {
    return {
        ...state,
        deletePropertyShareholder: initialState.deletePropertyShareholder,
    };
}
//#endregion
