import React from 'react';
import { SectionContainer } from '../../../../components';
import { IProps } from './IncomeAdd.types';
import { message } from 'antd';
import { paths } from '../../../../routes';
import { IncomeAddForm } from './components';
import { printHelper } from '../../../../common/helpers';

export default class IncomeAdd extends React.Component<IProps> {
    componentDidMount() {
        // this.props.getIncomeTypes();
        this.props.getPaymentTypes();
        this.props.getReceiptTypes();
        this.props.getCurrencies();
        this.props.getBankAccounts();
    }

    componentWillUnmount() {
        // this.props.resetIncomeTypes();
        this.props.resetPaymentTypes();
        this.props.resetReceiptTypes();
        this.props.resetCurrencies();
        this.props.resetBankAccounts();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkCreateIncome(prevProps);
    }

    checkCreateIncome(prevProps: IProps) {
        if (!prevProps.createdIncome && this.props.createdIncome) {
            message.success('Ingreso pagado');

            const { createdIncome } = this.props;

            if (!createdIncome.electronicBillingCode) {
                printHelper.printVoucherSales(createdIncome);
            } else {
                printHelper.printElectronicBilling(createdIncome);
            }

            this.goToIncomeList();
        } else if (!prevProps.createError && this.props.createError)
            message.error(this.props.createError);
    }

    goToIncomeList = () => {
        this.props.history.push(paths.ADMIN_INCOME_COMPLETE_LIST);
    };

    onSubmit = (values: any) => {
        this.props.createIncome(values);
    };

    renderForm = () => {
        const {
            createLoading,
            incomeTypes,
            incomeTypesLoading,
            paymentTypes,
            paymentTypesLoading,
            receiptTypes,
            receiptTypesLoading,
            currencies,
            currenciesLoading,
            bankAccounts,
            bankAccountsLoading,
        } = this.props;

        return (
            <IncomeAddForm
                incomeTypes={incomeTypes}
                incomeTypesLoading={incomeTypesLoading}
                paymentTypes={paymentTypes}
                paymentTypesLoading={paymentTypesLoading}
                receiptTypes={receiptTypes}
                receiptTypesLoading={receiptTypesLoading}
                currencies={currencies}
                currenciesLoading={currenciesLoading}
                bankAccounts={bankAccounts}
                bankAccountsLoading={bankAccountsLoading}
                submitting={createLoading}
                loggedInUser={this.props.loggedInUser}
                submitButtonText="Completar Pago"
                onSubmit={this.onSubmit}
            />
        );
    };

    render() {
        return <SectionContainer title="Nuevo Ingreso" icon="plus" content={this.renderForm} />;
    }
}
