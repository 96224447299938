import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getProvider, resetProvider } from '../../../../store/provider/actions';
import { IStoreState } from './ProviderDetail.types';
import { IRootState } from '../../../../common/types';
import ProviderDetail from './ProviderDetail';

function mapStateToProps(state: IRootState): IStoreState {
  const { loading, error, value } = state.provider.activeProvider;

  return {
    provider: value,
    loading,
    error,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getProvider,
      resetProvider,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderDetail);
