import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';
import { IProvider } from '../common/types/entities';

const { PROVIDER_URL } = apiUrls;

export function getAll(filter: string, page: number) {
  const params = { filter, page };

  return APIManager.get(PROVIDER_URL, { params });
}

export function get(id: number) {
  const url = `${PROVIDER_URL}/${id}`;
  return APIManager.get<IProvider>(url);
}

export function create(data: any) {
  return APIManager.post(PROVIDER_URL, { data });
}

export function update(id: number, data: any) {
  const url = `${PROVIDER_URL}/${id}`;
  return APIManager.put(url, { data });
}

export function deactivate(id: number) {
  const url = `${PROVIDER_URL}/${id}/deactivate`;
  return APIManager.put(url);
}
