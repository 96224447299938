import { Formik, FormikActions, Form, FormikProps } from 'formik';
import * as React from 'react';
import { IProps } from './ForgotPassword.types';
import { message, Layout, Card, Row, Col, Alert, Button, Icon, Result } from 'antd';
import { isEmpty, isEmail } from 'validator';
import { messages } from '../../../common/constants';
import { InputField } from '../../../components';
import { paths } from '../../../routes';

interface IForm {
    email: string;
}

export default class ForgotPassword extends React.Component<IProps> {
    componentDidUpdate(prevProps: IProps) {
        this.checkForgotPassword(prevProps);
    }

    checkForgotPassword(prevProps: IProps) {
        if (!prevProps.success && this.props.success) {
            message.success(
                'Se ha envíado a tu correo la información necesaria para restablecer tu contraseña',
                7,
            );

            this.goToLogin();
        }
    }

    validate = (values: IForm) => {
        const errors: Partial<IForm> = {};

        if (isEmpty(values.email)) errors.email = messages.MANDATORY_FIELD;
        else if (!isEmail(values.email)) errors.email = 'Formato de correo no es válido';

        return errors;
    };

    handleSubmit = (values: IForm, { setSubmitting }: FormikActions<IForm>) => {
        this.props.forgotPassword(values.email);
        setSubmitting(false);
    };

    goToLogin = () => {
        this.props.history.push(paths.LOGIN);
    };

    public render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Card
                    style={{
                        width: 350,
                        marginTop: '30vh',
                        marginBottom: '10vh',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <Formik
                        initialValues={{ email: '' }}
                        validate={this.validate}
                        onSubmit={this.handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {(formikBag: FormikProps<IForm>) => (
                            <Form>
                                <Row className="mb-1">
                                    <Col>
                                        <h1>Recuperar contraseña</h1>
                                        <p className="text-muted">
                                            Ingresa el correo asociado a tu cuenta para restablecer
                                            tu contraseña
                                        </p>
                                    </Col>
                                </Row>
                                {this.props.error && (
                                    <Row className="mb-3">
                                        <Col>
                                            <Alert
                                                message={this.props.error}
                                                type="error"
                                                showIcon
                                                closable
                                            />
                                        </Col>
                                    </Row>
                                )}
                                <Row className="mb-4">
                                    <InputField
                                        name="email"
                                        placeholder="Correo electrónico"
                                        prefix={
                                            <Icon
                                                type="mail"
                                                style={{ color: 'rgba(0,0,0,.25)' }}
                                            />
                                        }
                                    />
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="mr-2"
                                            loading={this.props.loading}
                                        >
                                            Enviar
                                        </Button>
                                        <Button
                                            disabled={this.props.loading}
                                            onClick={this.goToLogin}
                                        >
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </Layout>
        );
    }
}
