import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableCellItem } from '../../../../../components';
import { Button } from 'antd';
import { IInvoiceItem } from '../../../../../common/types/entities';
import nanoid from 'nanoid';
import { formatHelper } from '../../../../../common/helpers';
import { currencyTypes } from '../../../../../common/constants';

export const columns = (
    onClickDelete: (item: IInvoiceItem) => void,
    currencyIdSelected: number,
): ColumnProps<IInvoiceItem>[] => [
    {
        key: nanoid(),
        title: 'Nombre',
        dataIndex: 'name',
        className: 'd-none d-md-table-cell',
    },
    {
        key: nanoid(),
        title: 'Precio Unitario',
        dataIndex: 'unitPrice',
        className: 'd-none d-md-table-cell',
        render: (unitPrice: number) => (
            <span>
                {formatHelper.toMoney(
                    unitPrice,
                    currencyIdSelected == currencyTypes.PEN ? 'PEN' : 'USD',
                )}
            </span>
        ),
    },
    {
        key: nanoid(),
        title: 'Cantidad',
        dataIndex: 'quantity',
        className: 'd-none d-md-table-cell',
    },
    {
        key: nanoid(),
        title: 'Precio Total',
        dataIndex: '',
        className: 'd-none d-md-table-cell',
        render: (item: IInvoiceItem) => (
            <span>
                {formatHelper.toMoney(
                    item.totalPrice,
                    currencyIdSelected == currencyTypes.PEN ? 'PEN' : 'USD',
                )}
            </span>
        ),
    },
    {
        key: nanoid(),
        title: 'Comentario',
        dataIndex: 'comment',
        className: 'd-none d-md-table-cell break-row',
    },
    {
        key: 'xs',
        title: 'Factura',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IInvoiceItem) => (
            <>
                <TableCellItem label="Nombre" value={item.name} />
                <TableCellItem
                    label="Precio Unitario"
                    value={formatHelper.toMoney(
                        item.unitPrice,
                        currencyIdSelected == currencyTypes.PEN ? 'PEN' : 'USD',
                    )}
                />
                <TableCellItem label="Cantidad" value={item.quantity} />
                <TableCellItem
                    label="Precio Total"
                    value={formatHelper.toMoney(
                        item.totalPrice,
                        currencyIdSelected == currencyTypes.PEN ? 'PEN' : 'USD',
                    )}
                />
                <TableCellItem label="Comentario" value={item.comment} />
            </>
        ),
    },
    {
        key: 'options',
        dataIndex: '',
        render: (item: IInvoiceItem) => (
            <Button icon="delete" type="primary" onClick={() => onClickDelete(item)} />
        ),
    },
];
