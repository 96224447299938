import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../../common/types';
import { getBankAccount, resetBankAccount } from '../../../../store/bankAccount/actions';
import { IStoreState } from './BankAccountDetail.types';
import BankAccountDetail from './BankAccountDetail';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeBankAccount } = state.bankAccount;

    return {
        bankAccount: activeBankAccount.value,
        loading: activeBankAccount.loading,
        error: activeBankAccount.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getBankAccount,
            resetBankAccount,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BankAccountDetail);
