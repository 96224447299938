import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { shareholderService } from '../../service';
import { IShareholder } from '../../common/types/entities';

export function getShareholders(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => shareholderService.getAll(filter, page),
        types: [
            actionTypes.GET_SHAREHOLDERS,
            actionTypes.GET_SHAREHOLDERS_SUCCESS,
            actionTypes.GET_SHAREHOLDERS_FAILURE,
        ],
    };
}

export function resetShareholders() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SHAREHOLDERS_RESET,
        });
    };
}

export function getShareholder(id: number): IAPIMiddleware {
    return {
        callAPI: () => shareholderService.get(id),
        types: [
            actionTypes.GET_SHAREHOLDER,
            actionTypes.GET_SHAREHOLDER_SUCCESS,
            actionTypes.GET_SHAREHOLDER_FAILURE,
        ],
    };
}

export function resetShareholder() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SHAREHOLDER_RESET,
        });
    };
}

export function createShareholder(data: IShareholder): IAPIMiddleware {
    return {
        callAPI: () => shareholderService.create(data),
        types: [
            actionTypes.CREATE_SHAREHOLDER,
            actionTypes.CREATE_SHAREHOLDER_SUCCESS,
            actionTypes.CREATE_SHAREHOLDER_FAILURE,
        ],
    };
}

export function resetCreateShareholder() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_SHAREHOLDER_RESET,
        });
    };
}

export function updateShareholder(id: number, data: IShareholder): IAPIMiddleware {
    return {
        callAPI: () => shareholderService.update(id, data),
        types: [
            actionTypes.UPDATE_SHAREHOLDER,
            actionTypes.UPDATE_SHAREHOLDER_SUCCESS,
            actionTypes.UPDATE_SHAREHOLDER_FAILURE,
        ],
    };
}

export function resetUpdateShareholder() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_SHAREHOLDER_RESET,
        });
    };
}

export function deactivateShareholder(id: number): IAPIMiddleware {
    return {
        callAPI: () => shareholderService.deactivate(id),
        types: [
            actionTypes.DEACTIVATE_SHAREHOLDER,
            actionTypes.DEACTIVATE_SHAREHOLDER_SUCCESS,
            actionTypes.DEACTIVATE_SHAREHOLDER_FAILURE,
        ],
    };
}

export function resetDeactivateShareholder() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DEACTIVATE_SHAREHOLDER_RESET,
        });
    };
}

export function getShareholderProperties(
    shareholderId: number,
    filter = '',
    page = GET_ALL_ITEMS,
): IAPIMiddleware {
    return {
        callAPI: () => shareholderService.getProperties(shareholderId, filter, page),
        types: [
            actionTypes.GET_SHAREHOLDER_PROPERTIES,
            actionTypes.GET_SHAREHOLDER_PROPERTIES_SUCCESS,
            actionTypes.GET_SHAREHOLDER_PROPERTIES_FAILURE,
        ],
    };
}

export function resetShareholderProperties() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SHAREHOLDER_PROPERTIES_RESET,
        });
    };
}

export function getCoOwners(id: number, filter: string): IAPIMiddleware {
    return {
        callAPI: () => shareholderService.getCoOwners(id, filter),
        types: [
            actionTypes.GET_SHAREHOLDER_COOWNERS,
            actionTypes.GET_SHAREHOLDER_COOWNERS_SUCCESS,
            actionTypes.GET_SHAREHOLDER_COOWNERS_FAILURE,
        ],
    };
}

export function resetCoOwners() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SHAREHOLDER_COOWNERS_RESET,
        });
    };
}

export function createCoOwner(id: number, data: any): IAPIMiddleware {
    return {
        callAPI: () => shareholderService.createCoOwner(id, data),
        types: [
            actionTypes.CREATE_SHAREHOLDER_COOWNER,
            actionTypes.CREATE_SHAREHOLDER_COOWNER_SUCCESS,
            actionTypes.CREATE_SHAREHOLDER_COOWNER_FAILURE,
        ],
    };
}

export function resetCreateCoOwner() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CREATE_SHAREHOLDER_COOWNER_RESET,
        });
    };
}

export function updateCoOwner(coOwnerId: number, data: any): IAPIMiddleware {
    return {
        callAPI: () => shareholderService.updateCoOwner(coOwnerId, data),
        types: [
            actionTypes.UPDATE_SHAREHOLDER_COOWNER,
            actionTypes.UPDATE_SHAREHOLDER_COOWNER_SUCCESS,
            actionTypes.UPDATE_SHAREHOLDER_COOWNER_FAILURE,
        ],
    };
}

export function resetUpdateCoOwner() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_SHAREHOLDER_COOWNER_RESET,
        });
    };
}

export function deactivateCoOwner(coOwnerId: number): IAPIMiddleware {
    return {
        callAPI: () => shareholderService.deactivateCoOwner(coOwnerId),
        types: [
            actionTypes.DEACTIVATE_SHAREHOLDER_COOWNER,
            actionTypes.DEACTIVATE_SHAREHOLDER_COOWNER_SUCCESS,
            actionTypes.DEACTIVATE_SHAREHOLDER_COOWNER_FAILURE,
        ],
    };
}

export function resetDeactivateCoOwner() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DEACTIVATE_SHAREHOLDER_COOWNER_RESET,
        });
    };
}
