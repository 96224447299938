import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';
import { IExpense } from '../common/types/entities';

const { EXPENSE_URL } = apiUrls;

export function getAll(filter: string, page: number) {
    const params = { filter, page };
    return APIManager.get(EXPENSE_URL, { params });
}

export function get(id: number) {
    const url = `${EXPENSE_URL}/${id}`;
    return APIManager.get<IExpense>(url);
}

export function getExpenseDetailsLogistic(id: number) {
    const url = `${EXPENSE_URL}/${id}/expense-details-logistic`;
    return APIManager.get(url);
}

export function getExpenseDetails(id: number) {
    const url = `${EXPENSE_URL}/${id}/expense-details`;
    return APIManager.get(url);
}

export function getTypes() {
    const url = `${EXPENSE_URL}/types`;
    return APIManager.get(url);
}

export function create(data: any) {
    return APIManager.post(EXPENSE_URL, { data });
}

export function update(id: number, data: any) {
    const url = `${EXPENSE_URL}/${id}`;
    return APIManager.put(url, { data });
}

export function completeLogistic(id: number, data: any) {
    const url = `${EXPENSE_URL}/logistic/${id}`;
    return APIManager.put(url, { data });
}

export function deactivate(id: number) {
    const url = `${EXPENSE_URL}/${id}/deactivate`;
    return APIManager.put(url);
}

export function deleteExpense(id: number) {
    const url = `${EXPENSE_URL}/${id}`;
    return APIManager.delete(url);
}

export function deleteExpenseDetail(id: number) {
    const url = `${EXPENSE_URL}/detail/${id}`;
    return APIManager.delete(url);
}

export function answerExpense(id: number, data: any) {
    const url = `${EXPENSE_URL}/${id}/answer`;
    return APIManager.put(url, { data });
}

export function completeExpense(id: number, data: IExpense) {
    const url = `${EXPENSE_URL}/${id}/complete`;
    return APIManager.put(url, { data });
}
