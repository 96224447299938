import { IAttachedFile } from '../types/entities';
import nanoid from 'nanoid';

export function setPathParams(path: string, params: Object): string {
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const value = params[key];
            path = path.replace(`:${key}`, value);
        }
    }

    return path;
}

export function onlyNumbers(input: string): boolean {
    if (/^\d+(\.\d+)*$/.test(input)) return true;
    else return false;
}

export function onlyNumbersWithDecimals(input: string): boolean {
    return /^\d*\.?\d*$/.test(input);
}

export function onlyNumbersWithTwoDecimals(input: string): boolean {
    return /^\d*\.?\d{0,2}$/.test(input);
}

export function removeTempIDsFromArray<T>(arr: T[]): T[] {
    return arr.map(item => {
        // @ts-ignore
        delete item.id;
        return item;
    });
}

export function getFileList(files: IAttachedFile[]) {
    let filesToReturn = [];

    if (files)
        for (let i = 0; i < files.length; i++)
            filesToReturn.push({
                uid: nanoid(),
                name: files[i].filename,
                status: 'done',
                url: files[i].url,
            });

    return filesToReturn;
}

export function getArrayFileList(data: any) {
    let filesToReturn = [];

    for (let i = 0; i < data.length; i++)
        if (data[i].attachedFiles)
            for (let j = 0; j < data[i].attachedFiles.length; j++)
                filesToReturn.push({
                    uid: nanoid(),
                    name: data[i].attachedFiles[j].filename,
                    status: 'done',
                    url: data[i].attachedFiles[j].url,
                });

    return filesToReturn;
}
