import React from 'react';
import { FormikActions, FormikProps } from 'formik';
import {
    CustomForm,
    InputGroupField,
    DisplayItem,
    DataTable,
    SelectGroupField,
    DatePickerGroupField,
    SwitchGroupField,
} from '../../../../../components';
import {
    IInvoiceItem,
    IProvider,
    IInvoice,
    IPaymentType,
    IBankAccount,
    ICurrency,
} from '../../../../../common/types/entities';
import { Divider, Row, Col, Button, message, Upload, Icon } from 'antd';
import nanoid from 'nanoid';
import { messages, currencyTypes } from '../../../../../common/constants';
import { columns } from './columns';
import AddItemModal from './AddItemModal';
import moment from 'moment';
import { formatHelper } from '../../../../../common/helpers';

export interface IInvoiceForm {
    paymentDate?: Date | string;
    paymentType?: IPaymentType | string;
    bankAccount?: IBankAccount | string;
    code: string;
    emissionDate: Date | string;
    dueDate: Date | string;
    paid: boolean;
    provider: { id: number | string };
    currency: { id: number | string };
    files?: (File | Blob)[];
    exchangeRate: number | string;
}

interface IState {
    invoiceItems: IInvoiceItem[];
    modalOpen: boolean;
    paid: boolean;
    totalInvoice: number;
    bankAccountCurrency: ICurrency;
    files: (File | Blob)[];
    currencyIdSelected: number;
    exchangeRate: number;
}

interface IOwnProps {
    initialValues: IInvoice;
    providers: IProvider[];
    providersLoading: boolean;
    bankAccounts: IBankAccount[];
    bankAccountsLoading: boolean;
    paymentTypes: IPaymentType[];
    paymentTypesLoading: boolean;
    currencies: ICurrency[];
    currenciesLoading: boolean;
}

type IProps = IOwnProps;

export default class InvoiceForm extends CustomForm<IInvoiceForm, IProps, IState> {
    state: IState = {
        invoiceItems: [],
        modalOpen: false,
        paid: false,
        totalInvoice: null,
        bankAccountCurrency: null,
        files: [],
        currencyIdSelected: null,
        exchangeRate: null,
    };

    initialValues = (props: IProps): IInvoiceForm => {
        const { initialValues } = props;
        return {
            paymentDate: undefined,
            paymentType: undefined,
            bankAccount: undefined,
            code: undefined,
            emissionDate: undefined,
            dueDate: undefined,
            paid: false,
            currency: undefined,
            provider: { id: undefined },
            exchangeRate: undefined,
        };
    };

    onSubmit = (values: IInvoiceForm, actions: FormikActions<IInvoiceForm>) => {
        const { currencyIdSelected, bankAccountCurrency } = this.state;
        const invoiceItems = this.state.invoiceItems.filter(e => e.totalPrice != 0);

        if (invoiceItems.length === 0) {
            message.error('Se debe agregar por lo menos un detalle de factura');
            return;
        }
        const finalValues: any = {
            ...values,
            invoiceItems,
        };
        const files = this.state.files;
        if (files.length > 0) finalValues.files = files;

        if (
            values.exchangeRate &&
            bankAccountCurrency &&
            currencyIdSelected != bankAccountCurrency.id
        )
            finalValues.exchangeRate = this.state.exchangeRate;
        else finalValues.exchangeRate = null;

        this.props.onSubmit(finalValues);
        actions.setSubmitting(false);
    };

    validate = (values: IInvoiceForm) => {
        const errors: Partial<IInvoiceForm> = {};
        const { currencyIdSelected, bankAccountCurrency } = this.state;

        if (!values.provider.id || values.provider.id == 0)
            errors.provider = { id: messages.MANDATORY_FIELD };
        if (!values.code) errors.code = messages.MANDATORY_FIELD;
        if (!values.currency || values.currency.id == 0)
            errors.currency = { id: messages.MANDATORY_FIELD };
        if (!values.emissionDate) errors.emissionDate = messages.MANDATORY_FIELD;
        if (!values.paid) {
            if (!values.dueDate) errors.dueDate = messages.MANDATORY_FIELD;
            if (moment(values.emissionDate) > moment(values.dueDate))
                errors.emissionDate =
                    'La Fecha de emisión no puede ser menor a la Fecha de vencimiento';
        } else {
            if (!values.paymentDate) errors.paymentDate = messages.MANDATORY_FIELD;
            if (!values.paymentType) errors.paymentType = messages.MANDATORY_FIELD;
            if (!values.bankAccount) errors.bankAccount = messages.MANDATORY_FIELD;
            if (
                !values.exchangeRate &&
                bankAccountCurrency &&
                currencyIdSelected != bankAccountCurrency.id
            )
                errors.exchangeRate = messages.MANDATORY_FIELD;
        }

        return errors;
    };

    onCancel = () => this.props.onCancel();

    onRemoveItem = (item: IInvoiceItem) => {
        const { invoiceItems } = this.state;

        const filtered = invoiceItems.filter(x => {
            if (x.stringId != item.stringId || x.id != item.id) return x;
        });

        this.setState({ invoiceItems: filtered });
    };

    addItem = (item: IInvoiceItem) => {
        const { invoiceItems } = this.state;

        item.stringId = nanoid();
        item.totalPrice = item.quantity * item.unitPrice;
        item.currency = { id: this.state.currencyIdSelected };
        invoiceItems.push(item);

        this.setState({ invoiceItems, modalOpen: false });
    };

    getTotalInvoice = (): string => {
        let totalInvoice = 0;

        for (const item of this.state.invoiceItems) {
            totalInvoice += item.totalPrice;
        }

        return formatHelper.toMoney(Number(totalInvoice));
    };

    onSelectBankAccount = (id: number) => {
        let bankAccountSelected = this.props.bankAccounts.find(e => e.id == id);
        this.setState({ bankAccountCurrency: bankAccountSelected.currency });
    };

    onChangeCurrency = (value: number) => {
        this.setState({ invoiceItems: [], currencyIdSelected: Number(value) });
    };

    getSelectedCurrencyCode = (withBrackets = false) => {
        if (this.state.currencyIdSelected == currencyTypes.PEN)
            return withBrackets ? '(PEN)' : 'PEN';
        else if (this.state.currencyIdSelected == currencyTypes.USD)
            return withBrackets ? '(USD)' : 'USD';
        else return '';
    };

    formBody = ({ values }: FormikProps<IInvoiceForm>) => {
        const {
            providers,
            providersLoading,
            bankAccounts,
            bankAccountsLoading,
            paymentTypes,
            paymentTypesLoading,
            currencies,
            currenciesLoading,
        } = this.props;

        return (
            <div>
                <InputGroupField name="code" label="Código" required />
                <SelectGroupField
                    name="provider.id"
                    label="Proveedor"
                    data={providers}
                    displayText={(item: IProvider) => formatHelper.getProviderDescription(item)}
                    required
                    loading={providersLoading}
                />
                <DatePickerGroupField name="emissionDate" label="Fecha de emisión" required />
                <SelectGroupField
                    name="currency.id"
                    label="Moneda de la Factura"
                    data={currencies}
                    displayText="code"
                    required
                    loading={currenciesLoading}
                    onChange={(value: any) => this.onChangeCurrency(value)}
                />
                <SwitchGroupField
                    name="paid"
                    label="Pagado"
                    onChange={() =>
                        this.setState({
                            paid: !this.state.paid,
                        })
                    }
                />
                {!this.state.paid ? (
                    <DatePickerGroupField
                        name="dueDate"
                        label="Fecha de vencimiento"
                        required={!this.state.paid}
                    />
                ) : (
                    <>
                        <SelectGroupField
                            name="bankAccount"
                            label="Cuenta Bancaria"
                            data={bankAccounts}
                            displayText={(item: IBankAccount) =>
                                item.bank.name + ' - Nº ' + item.number
                            }
                            onChange={e => this.onSelectBankAccount(Number(e))}
                            loading={bankAccountsLoading}
                            required
                        />
                        <DisplayItem
                            label="Moneda de la Cuenta Bancaria"
                            value={
                                this.state.bankAccountCurrency
                                    ? this.state.bankAccountCurrency.code
                                    : ''
                            }
                            lmd={4}
                            llg={3}
                            lxl={2}
                            lxxl={2}
                        />
                        <SelectGroupField
                            name="paymentType"
                            label="Tipo de Pago"
                            data={paymentTypes}
                            displayText="description"
                            loading={paymentTypesLoading}
                            required
                        />
                        <DatePickerGroupField name="paymentDate" label="Fecha de Pago" required />
                        {this.state.bankAccountCurrency &&
                            this.state.bankAccountCurrency.id != this.state.currencyIdSelected && (
                                <InputGroupField
                                    name="exchangeRate"
                                    label="Tipo de Cambio"
                                    value={this.state.exchangeRate}
                                    onChange={(e: any) =>
                                        this.setState({ exchangeRate: e.target.value })
                                    }
                                    required
                                />
                            )}
                    </>
                )}
                {this.state.bankAccountCurrency &&
                this.state.paid &&
                this.state.bankAccountCurrency.id != this.state.currencyIdSelected ? (
                    <>
                        <DisplayItem
                            label={'Precio total ' + this.getSelectedCurrencyCode(true)}
                            vStyles={{ fontWeight: 'bold' }}
                            value={formatHelper.toMoney(
                                Number(this.getTotalInvoice()),
                                this.getSelectedCurrencyCode(),
                            )}
                        />
                        <DisplayItem
                            label={
                                'Precio total ' +
                                (this.state.currencyIdSelected != currencyTypes.PEN
                                    ? '(PEN)'
                                    : '(USD)')
                            }
                            vStyles={{ fontWeight: 'bold' }}
                            value={formatHelper.toMoney(
                                this.state.exchangeRate
                                    ? this.state.currencyIdSelected == currencyTypes.PEN
                                        ? Number(this.getTotalInvoice()) / this.state.exchangeRate
                                        : Number(this.getTotalInvoice()) * this.state.exchangeRate
                                    : 0,
                                this.state.currencyIdSelected != currencyTypes.PEN ? 'PEN' : 'USD',
                            )}
                        />
                    </>
                ) : (
                    <DisplayItem
                        label={'Precio Total ' + this.getSelectedCurrencyCode(true)}
                        vStyles={{ fontWeight: 'bold' }}
                        value={formatHelper.toMoney(
                            Number(this.getTotalInvoice()),
                            this.getSelectedCurrencyCode(),
                        )}
                    />
                )}
                <Divider />
                <Row className="mb-3">
                    <Col className="float-right">
                        <Button
                            icon="plus"
                            type="primary"
                            onClick={() => this.setState({ modalOpen: true })}
                        >
                            Agregar Detalle de Factura
                        </Button>
                    </Col>
                </Row>
                <DataTable
                    data={this.state.invoiceItems}
                    columns={columns(this.onRemoveItem, this.state.currencyIdSelected)}
                    rowKey={record => `${record.stringId || ''}${record.id || ''}`}
                />
                <br />
                <Divider orientation="left" className="mt-5">
                    Archivos
                </Divider>
                <Row>
                    <Col lg={12} md={12} xs={24} sm={24}>
                        <Upload
                            accept=".pdf, .csv, .xlsx, .xls, .doc, .docx, .png, .jpg"
                            onChange={info => {
                                this.setState({
                                    files: info.fileList.map(item => item['originFileObj']),
                                });
                            }}
                            // stange behavior.... https://stackoverflow.com/a/56125617
                            beforeUpload={() => false}
                        >
                            <Button>
                                <Icon type="upload" /> Subir Archivos
                            </Button>
                        </Upload>
                    </Col>
                </Row>
                <AddItemModal
                    isOpen={this.state.modalOpen}
                    onSubmit={this.addItem}
                    onCancel={() => this.setState({ modalOpen: false })}
                />
            </div>
        );
    };
}
