import { authorizationHelper } from '../../common/helpers';
import { IAPIMiddleware } from '../../common/types';
import { authService } from '../../service';
import actionTypes from './actionTypes';
import { Dispatch } from 'redux';

export function login(username: string, password: string): IAPIMiddleware {
    return {
        callAPI: () => authService.login(username, password),
        types: [
            actionTypes.LOGIN_USER,
            actionTypes.LOGIN_USER_SUCCESS,
            actionTypes.LOGIN_USER_FAILURE,
        ],
        successFunction: res => {
            authorizationHelper.saveAuthorizationToken(res.accessToken);
        },
    };
}

export function logout() {
    return async (dispatch: Dispatch) => {
        authorizationHelper.removeAuthorizationToken();
        dispatch({ type: actionTypes.LOGOUT_USER });
    };
}

export function verifyLoggedInUser(): IAPIMiddleware {
    return {
        callAPI: () => authService.getLoggedInUser(),
        types: [
            actionTypes.VERIFY_LOGGED_IN_USER,
            actionTypes.VERIFY_LOGGED_IN_USER_SUCCESS,
            actionTypes.VERIFY_LOGGED_IN_USER_FAILURE,
        ],
    };
}

export function forgotPassword(email: string): IAPIMiddleware {
    return {
        callAPI: () => authService.forgotPassword(email),
        types: [
            actionTypes.FORGOT_PASSWORD,
            actionTypes.FORGOT_PASSWORD_SUCCESS,
            actionTypes.FORGOT_PASSWORD_FAILURE,
        ],
    };
}

export function changePassword(
    currentPassword,
    newPassword,
    confirmNewPassword: string,
): IAPIMiddleware {
    return {
        callAPI: () => authService.changePassword(currentPassword, newPassword, confirmNewPassword),
        types: [
            actionTypes.CHANGE_PASSWORD,
            actionTypes.CHANGE_PASSWORD_SUCCESS,
            actionTypes.CHANGE_PASSWORD_FAILURE,
        ],
    };
}
export function resetForgotPassword() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.FORGOT_PASSWORD_RESET,
        });
    };
}

export function resetPassword(
    password: string,
    confirmPassword: string,
    passwordToken: string,
): IAPIMiddleware {
    return {
        callAPI: () => authService.resetPassword(password, confirmPassword, passwordToken),
        types: [
            actionTypes.RESET_PASSWORD,
            actionTypes.RESET_PASSWORD_SUCCESS,
            actionTypes.RESET_PASSWORD_FAILURE,
        ],
    };
}
export function resetResetPassword() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.RESET_PASSWORD_RESET,
        });
    };
}
