import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { IRootState } from '../../../common/types';
import { IUser } from '../../../common/types/entities';
import { roles } from '../../../common/constants';
import { paths } from '../../../routes';

interface IOwnProps {
    component: React.ComponentType<any>;
    layout?: React.ComponentType<any>;
    permission?: string;
}

interface IStoreState {
    authenticated?: boolean;
    user?: IUser;
}

export type IProps = IOwnProps & RouteProps & IStoreState;
class PrivateRoute extends React.Component<IProps> {
    public render() {
        const { component, layout, permission, user, authenticated, ...rest } = this.props;
        return <Route {...rest} render={this.renderRoute} />;
    }

    private renderRoute = (props: any) => {
        const {
            authenticated,
            user,
            permission,
            component: RenderComponent,
            layout: Layout,
        } = this.props;

        if (!authenticated)
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;

        if (permission && user && user.permissions.indexOf(permission) < 0)
            return <Redirect to={{ pathname: '/403', state: { from: props.location } }} />;

        if (Layout)
            return (
                <Layout>
                    <RenderComponent {...props} />
                </Layout>
            );
        return <RenderComponent {...props} />;
    };
}

function mapStateToProps(state: IRootState): IStoreState {
    return {
        authenticated: state.auth.authenticated,
        user: state.auth.loggedInUser.value,
    };
}

export default connect(mapStateToProps)(PrivateRoute);
