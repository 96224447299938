import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import ExpenseInvoiceAddEdit from './ExpenseInvoiceAddEdit';
import { resetExpense, getExpense } from '../../../../store/expense/actions';
import {
    getRequestOdersByExpense,
    resetRequestOrdersByExpense,
    getRequestOrderDetails,
    resetRequestOrderDetails,
} from '../../../../store/requestOrder/actions';
import { completeInvoice } from '../../../../store/invoice/actions';
import { getPaymentTypes, resetPaymentTypes } from '../../../../store/income/actions';
import { getBankAccounts, resetBankAccounts } from '../../../../store/bankAccount/actions';
import { IRootState } from '../../../../common/types';
import { IStoreState } from './ExpenseInvoiceAddEdit.types';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeExpense } = state.expense,
        { completeInvoice } = state.invoice,
        { bankAccountList } = state.bankAccount,
        { requestOrderDetailList, requestOrderByExpenseList } = state.requestOrder,
        { paymentTypeList } = state.income;

    return {
        activeExpense: activeExpense.value,
        activeExpenseLoading: activeExpense.loading,
        activeExpenseError: activeExpense.error,
        bankAccounts: bankAccountList.data,
        bankAccountsLoading: bankAccountList.loading,
        paymentTypes: paymentTypeList.data,
        paymentTypesLoading: paymentTypeList.loading,
        requestOrderDetails: requestOrderDetailList.data,
        requestOrderDetailsLoading: requestOrderDetailList.loading,
        requestOrdersByExpense: requestOrderByExpenseList.data,
        requestOrdersByExpenseLoading: requestOrderByExpenseList.loading,
        completeInvoiceSuccess: completeInvoice.success,
        completeInvoiceLoading: completeInvoice.loading,
        completeInvoiceError: completeInvoice.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getExpense,
            getPaymentTypes,
            getRequestOrderDetails,
            getRequestOdersByExpense,
            getBankAccounts,
            resetBankAccounts,
            completeInvoice,
            resetExpense,
            resetPaymentTypes,
            resetRequestOrderDetails,
            resetRequestOrdersByExpense,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseInvoiceAddEdit);
