import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { getInvoice } from '../../../../store/invoice/actions'
import { IStoreState } from './InvoiceDetail.types'
import { IRootState } from '../../../../common/types'
import InvoiceDetail from './InvoiceDetail'

function mapStateToProps(state: IRootState): IStoreState {
  const {
    activeInvoice,
  } = state.invoice;

  return {
    invoice: activeInvoice.value,
    loading: activeInvoice.loading,
    error: activeInvoice.error,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    getInvoice,
  }, dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceDetail)