import moment from 'moment';
import { ICustomer, IShareholder, ITax, IPaymentItem, IUser, IProvider } from '../types/entities';
import { customerTypes } from '../constants';

export function toDate(date: any) {
    if (!date) return '';
    return moment(date).format('DD/MM/YYYY');
}

export function toDateTime(date: any) {
    if (!date) return '';
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
}

export function toMoney(amount: number, currency?: string): string {
    if (amount == null || isNaN(amount)) return '0.00';

    try {
        var decimalCount = 2;
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const decimals = '.';
        const thousands = ',';
        const negativeSign = amount < 0 ? '-' : '';
        amount = Math.abs(Number(amount));
        let i = parseInt((amount || 0).toFixed(decimalCount)).toString();
        let j = i.length > 3 ? i.length % 3 : 0;
        return (
            (currency == 'PEN' ? 'S/ ' : currency == 'USD' ? '$ ' : '') +
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimals +
                  Math.abs(amount - parseInt(i))
                      .toFixed(decimalCount)
                      .slice(2)
                : '')
        );
    } catch (error) {
        return '';
    }
}

export function getCustomerDescription(customer: ICustomer): string {
    if (customer.type.id == customerTypes.PERSON) {
        return `${customer.firstname || ''} ${customer.lastname || ''}`;
    } else {
        return `${customer.businessName || ''} ${customer.ruc || ''}`;
    }
}

export function getShareholderFullname(shareholder: IShareholder): string {
    return `${shareholder.firstname || ''} ${shareholder.lastname || ''}`;
}

export function getShareholderFullnameWithCode(shareholder: IShareholder): string {
    return `${shareholder.code || ''} ${shareholder.firstname || ''} ${shareholder.lastname || ''}`;
}

export function getUserFullname(user: IUser) {
    if (!user) return '';
    return `${user.firstname} ${user.lastname}`;
}

export function getProviderDescription(provider: IProvider) {
    if (!provider) return '';
    return `${provider.ruc}${provider.businessName ? ' - ' + provider.businessName : ''}`;
}

export function getTaxWithPercentaje(tax: ITax): string {
    return `${tax.description} (${tax.percentage.toFixed(2)} %)`;
}

export function getPaymentItemWithType(paymentItem: IPaymentItem): string {
    let text = paymentItem.description;
    if (paymentItem.type) text += ` (${paymentItem.type.description})`;
    return text;
}
