import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
} from '../../common/helpers/reducer';

//#region GET CURRENCIES
export function getCurrencies(state: IState): IState {
    return {
        ...state,
        currencyList: listStatePaginatedLoading(state.currencyList),
    };
}

export function getCurrenciesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        currencyList: listStatePaginatedSuccess(action.payload),
    };
}

export function getCurrenciesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        currencyList: listStatePaginatedFailure(initialState.currencyList, action.payload.error),
    };
}

export function getCurrenciesReset(state: IState): IState {
    return {
        ...state,
        currencyList: initialState.currencyList,
    };
}
//#endregion
