import * as React from 'react';
import { IProps } from './PettyCashDetail.types';
import { SectionContainer, DisplayItem, DataTable, PBR } from '../../../../components';
import { paths } from '../../../../routes';
import { Row, Col, Divider, Input, Button, message, Modal } from 'antd';
import { columns } from './columns';
import { PettyCashMovementAddModal, PettyCashMovementItemListModal } from './components';
import { permissions } from '../../../../common/constants';

const mediaQueryProps = {
    lxs: 24,
    lsm: 24,
    lmd: 10,
    llg: 10,
    lxl: 8,
    lxxl: 6,
    vxs: 24,
    vsm: 24,
    vmd: 14,
    vlg: 14,
    vxl: 16,
    vxxl: 18,
};

type IState = {
    filter: string;
    page: number;
    modalOpen: boolean;
    movementId: number;
    itemsModalOpen: boolean;
};

class PettyCashDetail extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        modalOpen: false,
        movementId: null,
        itemsModalOpen: false,
    };

    componentDidMount() {
        this.props.getPettyCash(this.props.match.params['id']);
        this.performGetMovements();
    }

    componentWillUnmount() {
        this.props.resetPettyCash();
        this.props.resetPettyCashMovements();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkCreateMovement(prevProps);
        this.checkDeleteMovement(prevProps);
    }

    checkCreateMovement(prevProps: IProps) {
        if (!prevProps.createMovementSuccess && this.props.createMovementSuccess) {
            message.success('Movimiento agreagado');
            this.setState({ modalOpen: false });
            this.performGetMovements();
        } else if (!prevProps.createMovementError && this.props.createMovementError) {
            message.error(this.props.createMovementError);
        }
    }

    async checkDeleteMovement(prevProps: IProps) {
        if (!prevProps.deleteMovementSuccess && this.props.deleteMovementSuccess) {
            message.success('Movimiento eliminado');
            await this.setState({ page: 1 });
            this.performGetMovements();
        } else if (!prevProps.deleteMovementError && this.props.deleteMovementError) {
            message.error(this.props.deleteMovementError);
        }
    }

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetMovements();
    };

    async filterMovements() {
        await this.setState({ page: 1 });
        this.performGetMovements();
    }

    performGetMovements() {
        const { filter, page } = this.state;
        this.props.getPettyCashMovements(this.props.match.params['id'], filter, page);
    }

    performCreateMovement = (values) => {
        this.props.createPettyCashMovement(this.props.match.params['id'], values);
    };

    onClickShowMovementItems = (id: number) => {
        this.setState({ movementId: id, itemsModalOpen: true });
    };

    onClickDeleteMovement = (id: number) => {
        const { deletePettyCashMovement, match } = this.props;

        Modal.confirm({
            title: '¿Deseas eliminar el movimiento?',
            content: 'Eliminar movimiento',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deletePettyCashMovement(match.params['id'], id);
            },
            onCancel() {},
        });
    };

    renderContent = () => {
        const { pettyCash } = this.props;

        const isActive = pettyCash.active;

        return (
            <>
                <Row>
                    <Col>
                        <DisplayItem label="Activo" value={pettyCash.active ? 'Sí' : 'No'} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <DisplayItem
                            label="Correlativo"
                            value={pettyCash.correlative}
                            {...mediaQueryProps}
                        />
                        <DisplayItem
                            label="Número de Cheque"
                            value={pettyCash.checkNumber}
                            {...mediaQueryProps}
                        />
                        <DisplayItem
                            label="Fecha Apertura"
                            value={pettyCash.createdAt}
                            type="date"
                            {...mediaQueryProps}
                        />
                        {!isActive && (
                            <DisplayItem
                                label="Fecha Cierre"
                                value={pettyCash.closedAt}
                                type="date"
                                {...mediaQueryProps}
                            />
                        )}
                        <DisplayItem
                            label="Usuario Apertura"
                            value={pettyCash.userOpening.username}
                            {...mediaQueryProps}
                        />
                        {!isActive && (
                            <DisplayItem
                                label="Usuario Cierre"
                                value={pettyCash.userClosing.username}
                                {...mediaQueryProps}
                            />
                        )}
                    </Col>
                    <Col lg={12}>
                        <DisplayItem
                            label="Cuenta Bancaria"
                            value={`${pettyCash.bankAccount.bank.name} - ${pettyCash.bankAccount.number}`}
                            {...mediaQueryProps}
                        />
                        <DisplayItem
                            label="Monto Acumulado"
                            value={pettyCash.accumulatedAmount}
                            type="money"
                            {...mediaQueryProps}
                        />
                        <DisplayItem
                            label="Monto Inicial"
                            value={pettyCash.startingAmount}
                            type="money"
                            {...mediaQueryProps}
                        />
                        <DisplayItem
                            label="Monto Actual"
                            value={pettyCash.currentAmount}
                            type="money"
                            {...mediaQueryProps}
                        />
                        {!isActive && (
                            <DisplayItem
                                label="Monto Final"
                                value={pettyCash.finalAmount}
                                type="money"
                                {...mediaQueryProps}
                            />
                        )}
                    </Col>
                </Row>
                <Divider orientation="left" className="mt-4">
                    Movimientos
                </Divider>
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={(e) => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterMovements()}
                        />
                    </Col>
                </Row>
                {isActive && (
                    <PBR permission={permissions.pettyCash.WRITE_PETTY_CASH_MOVEMENTS}>
                        <Row className="mb-2">
                            <Col className="float-right">
                                <Button
                                    icon="plus"
                                    type="primary"
                                    onClick={() => this.setState({ modalOpen: true })}
                                >
                                    Agregar
                                </Button>
                            </Col>
                        </Row>
                    </PBR>
                )}

                <Row>
                    <Col>
                        <DataTable
                            data={this.props.movements}
                            columns={columns(
                                this.onClickShowMovementItems,
                                this.onClickDeleteMovement,
                                isActive,
                            )}
                            pageSize={this.props.movementsPageSize}
                            total={this.props.movementsTotal}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={this.props.movementsLoading}
                        />
                    </Col>
                </Row>
                <PettyCashMovementAddModal
                    isOpen={this.state.modalOpen}
                    onSubmit={this.performCreateMovement}
                    onCancel={() => this.setState({ modalOpen: false })}
                    submitting={this.props.createMovementLoading}
                />
                <PettyCashMovementItemListModal
                    isOpen={this.state.itemsModalOpen}
                    movementId={this.state.movementId}
                    onCancel={() => this.setState({ itemsModalOpen: false, movementId: null })}
                />
            </>
        );
    };

    render() {
        const { pettyCash, loading, error } = this.props;

        return (
            <SectionContainer
                title="Detalle Caja Chica"
                onBack={() => this.props.history.push(paths.ADMIN_PETTY_CASH_LIST)}
                loading={loading || !pettyCash}
                error={error}
                content={this.renderContent}
            />
        );
    }
}

export default PettyCashDetail;
