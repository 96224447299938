import * as React from 'react';
import { IProps, IState } from './UserProfile.types';
import { PasswordForm } from './components';
import { message, Col, Row, Button, Icon } from 'antd';
import { SectionContainer, DisplayItem, PBR } from '../../components';
import { IChangePasswordFormValues } from './components/passwordForm/ChangePasswordForm';
import { permissions } from '../../common/constants';

class UserDetail extends React.Component<IProps, IState> {
    state = {
        changePasswordModalOpen: false,
        authorizationTokenHide: true,
    };

    componentDidUpdate(prevProps: IProps) {
        this.checkUpdatePassword(prevProps);
        this.checkUpdateAthorizationToken(prevProps);
    }

    async checkUpdatePassword(prevProps: IProps) {
        const { changePasswordRespond: prevResult } = prevProps;
        const { changePasswordRespond: currResult } = this.props;
        if (!prevResult.success && currResult.success) {
            message.success('Contraseña actualizada con éxito');
            await this.setState({ changePasswordModalOpen: false });
        } else if (!prevProps.error && currResult.error) {
            message.error(currResult.error);
        }
    }

    async checkUpdateAthorizationToken(prevProps: IProps) {
        if (
            !prevProps.updateAuthorizationTokenSuccess &&
            this.props.updateAuthorizationTokenSuccess
        ) {
            message.success('Nuevo token de autorización generado');
        } else if (
            !prevProps.updateAuthorizationTokenError &&
            this.props.updateAuthorizationTokenError
        ) {
            message.error(this.props.updateAuthorizationTokenError);
        }
    }

    onSubmit = (values: IChangePasswordFormValues) => {
        this.props.changePassword(
            values.currentPassword,
            values.newPassword,
            values.confirmPassword,
        );
    };

    performUpdateAuthorizarionToken = () => {
        if (this.props.updateAuthorizationTokenLoading) return;
        this.props.updateAuthorizationToken();
    };

    renderAuthorizationToken = () => {
        const { authorizationToken } = this.props.user;
        if (this.state.authorizationTokenHide)
            return (
                <span>
                    &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                    <Icon
                        type="eye-invisible"
                        className="ml-2"
                        onClick={() => this.setState({ authorizationTokenHide: false })}
                    />
                </span>
            );
        return (
            <span>
                {authorizationToken}
                <Icon
                    type={this.props.updateAuthorizationTokenLoading ? 'loading' : 'sync'}
                    className="ml-2"
                    onClick={this.performUpdateAuthorizarionToken}
                    spin={this.props.updateAuthorizationTokenLoading}
                />
                <Icon
                    type="eye"
                    className="ml-2"
                    onClick={() => this.setState({ authorizationTokenHide: true })}
                />
            </span>
        );
    };

    renderContent = () => {
        const { user } = this.props;

        return (
            <>
                <DisplayItem label="Usuario" value={user.username} />
                <DisplayItem label="Nombre" value={user.firstname} />
                <DisplayItem label="Apellidos" value={user.lastname} />
                <DisplayItem label="Email" value={user.email} />
                <DisplayItem label="DNI" value={user.dni} />
                <DisplayItem label="Teléfono" value={user.phone} />
                <DisplayItem label="Dirección" value={user.address} />
                <DisplayItem label="Rol" value={user.role.name} />
                <PBR permission={permissions.user.GENERATE_AUTHORIZATION_TOKEN}>
                    <Row>
                        <Col xs={24} sm={6} md={5} lg={4} xl={4} xxl={3}>
                            <span className="label">Token de autorización:</span>
                        </Col>
                        <Col sm={10}>
                            <span>{this.renderAuthorizationToken()}</span>
                        </Col>
                    </Row>
                </PBR>
                <Row className="mt-3">
                    <Col>
                        <Button
                            type="primary"
                            onClick={() => this.setState({ changePasswordModalOpen: true })}
                        >
                            Cambiar contraseña
                        </Button>
                    </Col>
                </Row>
                <PasswordForm
                    isOpen={this.state.changePasswordModalOpen}
                    onSubmit={this.onSubmit}
                    onCancel={() => this.setState({ changePasswordModalOpen: false })}
                />
            </>
        );
    };

    render() {
        return <SectionContainer title="Mi Perfil" content={this.renderContent} />;
    }
}

export default UserDetail;
