const actionTypes = {
    GET_SHAREHOLDERS: 'shareholder/GET_SHAREHOLDERS',
    GET_SHAREHOLDERS_SUCCESS: 'shareholder/GET_SHAREHOLDERS_SUCCESS',
    GET_SHAREHOLDERS_FAILURE: 'shareholder/GET_SHAREHOLDERS_FAILURE',
    GET_SHAREHOLDERS_RESET: 'shareholder/GET_SHAREHOLDERS_RESET',

    GET_SHAREHOLDER: 'shareholder/GET_SHAREHOLDER',
    GET_SHAREHOLDER_SUCCESS: 'shareholder/GET_SHAREHOLDER_SUCCESS',
    GET_SHAREHOLDER_FAILURE: 'shareholder/GET_SHAREHOLDER_FAILURE',
    GET_SHAREHOLDER_RESET: 'shareholder/GET_SHAREHOLDER_RESET',

    CREATE_SHAREHOLDER: 'shareholder/CREATE_SHAREHOLDER',
    CREATE_SHAREHOLDER_SUCCESS: 'shareholder/CREATE_SHAREHOLDER_SUCCESS',
    CREATE_SHAREHOLDER_FAILURE: 'shareholder/CREATE_SHAREHOLDER_FAILURE',
    CREATE_SHAREHOLDER_RESET: 'shareholder/CREATE_SHAREHOLDER_RESET',

    UPDATE_SHAREHOLDER: 'shareholder/UPDATE_SHAREHOLDER',
    UPDATE_SHAREHOLDER_SUCCESS: 'shareholder/UPDATE_SHAREHOLDER_SUCCESS',
    UPDATE_SHAREHOLDER_FAILURE: 'shareholder/UPDATE_SHAREHOLDER_FAILURE',
    UPDATE_SHAREHOLDER_RESET: 'shareholder/UPDATE_SHAREHOLDER_RESET',

    DEACTIVATE_SHAREHOLDER: 'shareholder/DEACTIVATE_SHAREHOLDER',
    DEACTIVATE_SHAREHOLDER_SUCCESS: 'shareholder/DEACTIVATE_SHAREHOLDER_SUCCESS',
    DEACTIVATE_SHAREHOLDER_FAILURE: 'shareholder/DEACTIVATE_SHAREHOLDER_FAILURE',
    DEACTIVATE_SHAREHOLDER_RESET: 'shareholder/DEACTIVATE_SHAREHOLDER_RESET',

    GET_SHAREHOLDER_PROPERTIES: 'shareholder/GET_SHAREHOLDER_PROPERTIES',
    GET_SHAREHOLDER_PROPERTIES_SUCCESS: 'shareholder/GET_SHAREHOLDER_PROPERTIES_SUCCESS',
    GET_SHAREHOLDER_PROPERTIES_FAILURE: 'shareholder/GET_SHAREHOLDER_PROPERTIES_FAILURE',
    GET_SHAREHOLDER_PROPERTIES_RESET: 'shareholder/GET_SHAREHOLDER_PROPERTIES_RESET',

    GET_SHAREHOLDER_COOWNERS: 'shareholder/GET_SHAREHOLDER_COOWNERS',
    GET_SHAREHOLDER_COOWNERS_SUCCESS: 'shareholder/GET_SHAREHOLDER_COOWNERS_SUCCESS',
    GET_SHAREHOLDER_COOWNERS_FAILURE: 'shareholder/GET_SHAREHOLDER_COOWNERS_FAILURE',
    GET_SHAREHOLDER_COOWNERS_RESET: 'shareholder/GET_SHAREHOLDER_COOWNERS_RESET',

    CREATE_SHAREHOLDER_COOWNER: 'shareholder/CREATE_SHAREHOLDER_COOWNER',
    CREATE_SHAREHOLDER_COOWNER_SUCCESS: 'shareholder/CREATE_SHAREHOLDER_COOWNER_SUCCESS',
    CREATE_SHAREHOLDER_COOWNER_FAILURE: 'shareholder/CREATE_SHAREHOLDER_COOWNER_FAILURE',
    CREATE_SHAREHOLDER_COOWNER_RESET: 'shareholder/CREATE_SHAREHOLDER_COOWNER_RESET',

    UPDATE_SHAREHOLDER_COOWNER: 'shareholder/UPDATE_SHAREHOLDER_COOWNER',
    UPDATE_SHAREHOLDER_COOWNER_SUCCESS: 'shareholder/UPDATE_SHAREHOLDER_COOWNER_SUCCESS',
    UPDATE_SHAREHOLDER_COOWNER_FAILURE: 'shareholder/UPDATE_SHAREHOLDER_COOWNER_FAILURE',
    UPDATE_SHAREHOLDER_COOWNER_RESET: 'shareholder/UPDATE_SHAREHOLDER_COOWNER_RESET',

    DEACTIVATE_SHAREHOLDER_COOWNER: 'shareholder/DEACTIVATE_SHAREHOLDER_COOWNER',
    DEACTIVATE_SHAREHOLDER_COOWNER_SUCCESS: 'shareholder/DEACTIVATE_SHAREHOLDER_COOWNER_SUCCESS',
    DEACTIVATE_SHAREHOLDER_COOWNER_FAILURE: 'shareholder/DEACTIVATE_SHAREHOLDER_COOWNER_FAILURE',
    DEACTIVATE_SHAREHOLDER_COOWNER_RESET: 'shareholder/DEACTIVATE_SHAREHOLDER_COOWNER_RESET',
};

export default actionTypes;
