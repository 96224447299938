import React, { FC, useRef } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal } from 'antd';
import { InputGroupField } from '../../../../../components';
import { messages, bankAccountTransactionTypes } from '../../../../../common/constants';
import { IBank } from '../../../../../common/types/entities';

interface IOwnProps {
    isOpen: boolean;
    onSubmit: (values: ICreateBankAccountTransactionForm) => void;
    disabled?: boolean;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    initialValues?: IBank;
    submitting?: boolean;
    typeId: number;
}

export interface ICreateBankAccountTransactionForm {
    amount: number | string;
}

export type IProps = IOwnProps;

const CreateBankAccountTransactionModal: FC<IProps> = (props) => {
    let form = useRef<Formik<ICreateBankAccountTransactionForm>>(null);

    const onSubmit = (
        values: ICreateBankAccountTransactionForm,
        actions: FormikActions<ICreateBankAccountTransactionForm>,
    ) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = (e) => {
        form.current.resetForm();
        props.onCancel(e);
    };

    const validate = (values: ICreateBankAccountTransactionForm) => {
        let errors: Partial<ICreateBankAccountTransactionForm> = {};

        if (!values.amount) errors.amount = messages.MANDATORY_FIELD;

        return errors;
    };

    const onOk = () => {
        form.current.submitForm();
    };

    const getTitle = () => {
        const { typeId } = props;

        if (typeId == bankAccountTransactionTypes.READJUSTMENT) return 'Reajustar';
        else if (typeId == bankAccountTransactionTypes.DEPOSIT) return 'Depositar';
        else if (typeId == bankAccountTransactionTypes.WITHDRAW) return 'Retirar';

        return '';
    };

    const renderFormik = () => {
        if (!props.isOpen) return null;

        const init = props.initialValues || {};

        return (
            <Formik
                initialValues={null}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={(formikBag: FormikProps<ICreateBankAccountTransactionForm>) => (
                    <Form>
                        <InputGroupField
                            name="amount"
                            label="Monto"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            destroyOnClose={true}
            title={getTitle()}
            visible={props.isOpen}
            okText="Guardar"
            onOk={onOk}
            okButtonProps={{ loading: props.submitting }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.submitting }}
        >
            {renderFormik()}
        </Modal>
    );
};

export default CreateBankAccountTransactionModal;
