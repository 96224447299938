const user = {
    READ_USERS: 'b0b09ab6-4897-4db3-8101-2f9a04170f2e',
    WRITE_USERS: 'f7cb401a-89d0-48cd-8cd4-4b2fb59101c9',
    GENERATE_AUTHORIZATION_TOKEN: 'a8216e63-80e2-4f25-b1dc-5ffef3078499',
};

const provider = {
    READ_PROVIDERS: '22fcbe77-f79f-4ca8-9eec-f7214c5f52e2',
    WRITE_PROVIDERS: '822ce423-0aec-464a-bb13-2f8480b36f1f',
};

const report = {
    READ_REPORTS: 'bfad5400-b4fb-4af8-aff2-69bcd2f12e77',
    READ_INCOME_DETAILED_PENDING_REPORT: 'c7b716c6-b806-420a-8d0f-9970f14112ee',
    READ_INCOME_DETAILED_PENDING_PAYMENT_REPORT: 'fabf0d98-01e6-4bac-aac7-63f0a265b1a5',
    READ_INCOME_DETAILED_COMPLETED_REPORT: 'dd19baa4-edcb-49a6-9295-3b0d5856d7f3',
    READ_INCOME_DETAILED_ANULLED_REPORT: 'd8a5d819-eff7-4abb-b470-5a2c6177925c',
};

const bankAccount = {
    READ_BANK_ACCOUNTS: '3683e6a4-bfe8-4811-b978-79ff7ac8ed53',
    READ_BANK_ACCOUNT_DETAIL: '575ed096-2085-448e-8a93-33090651980a',
    WRITE_BANK_ACCOUNTS: '429a5f4c-d1d6-410d-a495-de3499d3ceba',
    WRITE_READJUSTMENT: 'afd1795e-7816-4cb4-9691-becbad349b17',
    READ_BANK_ACCOUNT_MOVEMENTS: '387df3f6-16f2-4021-af5a-9d04f5a6a0ba',
};

const bank = {
    READ_BANKS: 'bce0cfda-29d7-43cb-b5ca-0af8e893233b',
    WRITE_BANKS: 'e07e7e9a-ac30-4ab8-a43f-c54f10ed76dc',
};

const shareholder = {
    READ_SHAREHOLDERS: '31fd6bf6-cac1-4ff6-8207-2f0a43be05ed',
    WRITE_SHAREHOLDERS: 'f3c38720-a27e-4db4-98ba-dd70b6d03118',
};

const property = {
    READ_PROPERTIES: 'eb5196d3-86af-44b5-861d-39a060ae1454',
    READ_PROPERTY_DETAIL: 'cc4a5723-bfa7-4c34-b1dd-ba0599719f90',
    WRITE_PROPERTIES: '8435cc89-01ef-448b-9471-10f2f81dacaf',
    READ_ZONES: '640e4c2c-5d9c-4980-aaac-6bbf0d5f640d',
    WRITE_ZONES: '6db45bbe-e5cd-4bbe-a2b6-d793c629ea1a',
    READ_SUBZONES: 'd8a2edf9-a45e-4dc3-8aaf-0c7411f8f6a2',
    WRITE_SUBZONES: 'bf395323-4e16-4305-8e6c-d4229a8d733d',
};

const configure = {
    READ_CONFIGURES: '9300b0b3-5d23-43e9-9a1a-5e127ebcd04a',
    WRITE_CONFIGURES: '6dca624d-fada-4275-bb88-0ea245878035',
};

const expense = {
    READ_EXPENSES: '16864dc6-a3ac-4e75-9470-b7a9426458bc',
    READ_EXPENSE_DETAIL: '9b47a5f3-6b06-401e-a5e6-62bfcd22b050',
    WRITE_REQUERIMENTS: '499661e0-d437-44c8-b97d-93049b307884',
    WRITE_LOGISTIC_PROCESS: '8b9aefc1-ac14-43bc-a7bd-c94865fdb3c7',
    WRITE_RESPONSE_PROCESS: '36e3de4c-1161-4beb-839d-6beeeecadc45',
    WRITE_ORDERS: '3d7da4ba-f5f3-43a5-9284-d4f2f5f22bcf',
    READ_INVOICES: '62694b12-29ac-4225-b03f-08f342ca8dcb',
    WRITE_INVOICES: '467d631f-ac21-4d0b-aa0e-1fd961012cc7',
    READ_PAYMENTS: '61320ed0-d66e-4212-8c34-f69c27ad6112',
    WRITE_PAYMENTS: 'b72d3fbd-d923-41a9-8eb3-16c8d8265b6f',
    WRITE_ACCORDANCE_PROCESS: '40356e40-07d8-41f6-adac-2ae899f0cd7a',
};

const income = {
    READ_PENDING_INCOMES: 'a56e7394-9dc5-432c-8cfa-e2fb8aba95d1',
    CREATE_INCOME_PAYMENT_ITEMS: 'e28e8acf-b596-4ab7-b967-164f7f941093',
    DELETE_INCOME_PAYMENT_ITEMS: '27c4b685-77dc-4eb1-b004-b806feb68d73',
    CONDONE_INCOME_PAYMENT_ITEMS: 'e2c94544-7419-49fd-8e66-8d4fdde1dd5e',
    COMPLETE_INCOMES: 'd1b6be94-6873-4562-a6c3-2e9c0847b588',
    READ_COMPLETED_INCOMES: 'e1839ae6-7f56-4557-9070-429a3eb712a2',
    READ_COMPLETED_INCOME_DETAIL: '3a32419b-a7cd-4d33-ba27-1eeab64b539a',
    ANULL_INCOMES: '36866b38-8041-4ab9-9256-05568027f16c',
    BULK_LOAD_INCOMES: 'cee34994-f8f2-4e82-9f6c-f7d905d31b89',
};

const paymentItem = {
    READ_PAYMENT_ITEMS: '903c8571-fb37-481d-9b05-a19918efb7e1',
    WRITE_PAYMENT_ITEMS: 'e9f392c3-3c41-4cc0-b3c7-788fe9bddb53',
};

const paymentItemType = {
    READ_PAYMENT_ITEM_TYPES: 'fb7673a0-89e0-49f5-a75c-acb0c9657bc8',
    WRITE_PAYMENT_ITEM_TYPES: '6dad2c82-1060-42a4-9e96-c33bf68835fa',
};

const pettyCash = {
    READ_PETTY_CASH: '8df26224-a2c4-4167-a215-94159f2c8690',
    WRITE_PETTY_CASH: '18e7cab7-1735-490e-8960-64b3d3b71c6f',
    READ_PETTY_CASH_MOVEMENTS: 'b8765761-8cb2-4042-9935-11156da8a1ec',
    WRITE_PETTY_CASH_MOVEMENTS: '954d8889-917a-4a53-91dd-d73a135408f4',
};

export const permissions = {
    user,
    provider,
    report,
    bankAccount,
    bank,
    shareholder,
    property,
    configure,
    expense,
    income,
    paymentItem,
    paymentItemType,
    pettyCash,
};
