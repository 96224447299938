import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    objectStateLoading,
    objectStateSuccess,
    objectStateFailure,
} from '../../common/helpers/reducer';

//#region GET RUC INFO
export function getRucInfo(state: IState): IState {
    return {
        ...state,
        rucInfo: objectStateLoading(),
    };
}

export function getRucInfoSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        rucInfo: objectStateSuccess(action.payload),
    };
}

export function getRucInfoFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        rucInfo: objectStateFailure(action.payload.error),
    };
}

export function getRucInfoReset(state: IState): IState {
    return {
        ...state,
        rucInfo: initialState.rucInfo,
    };
}
//#endregion
