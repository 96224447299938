import React from 'react';
import { Button, Row, Col, Input, message, Modal } from 'antd';
import { columns } from './misc/columns';
import { IProps, IState } from './ZoneList.types';
import { SectionContainer, DataTable, PBR } from '../../../../components';
import { IZone } from '../../../../common/types/entities';
import { AddEditModal } from './components';
import { IAddEditZoneValues } from './components/AddEditModal';
import { helper } from '../../../../common/helpers';
import { paths } from '../../../../routes';
import { permissions } from '../../../../common/constants';

export default class UserList extends React.Component<IProps, IState> {
    state: IState = {
        filter: '',
        page: 1,
        modalAddEditOpen: false,
    };

    componentDidMount() {
        this.performGetZones();
    }

    componentWillUnmount() {
        this.props.resetZones();
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkCreactZone(prevProps);
        this.checkUpdateZone(prevProps);
        this.checkDeactivateZone(prevProps);
    }

    async checkDeactivateZone(prevProps: IProps) {
        if (!prevProps.deactivateSuccess && this.props.deactivateSuccess) {
            message.success('Zona eliminada');
            await this.setState({ page: 1 });
            this.performGetZones();
        } else if (!prevProps.deactivateError && this.props.deactivateError)
            message.error(this.props.deactivateError);
    }

    async checkCreactZone(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.createSuccess && props.createSuccess) {
            message.success('Zona creada');
            await this.setState({ page: 1, modalAddEditOpen: false });
            this.performGetZones();
        } else if (!prevProps.createError && props.createError) message.error(props.createError);
    }

    async checkUpdateZone(prevProps: IProps) {
        const { props } = this;

        if (!prevProps.updateSuccess && props.updateSuccess) {
            message.success('Zona actualizada');
            await this.setState({ page: 1, modalAddEditOpen: false, zoneToEdit: undefined });
            this.performGetZones();
        } else if (!prevProps.updateError && props.updateError) message.error(props.updateError);
    }

    performGetZones() {
        const { filter, page } = this.state;
        this.props.getZones(filter, page);
    }

    async filterZones() {
        await this.setState({ page: 1 });
        this.performGetZones();
    }

    perfromDeactivateZone = (id: number) => {
        const { deactivateZone } = this.props;

        Modal.confirm({
            title: '¿Deseas eliminar la zona?',
            content: 'Eliminar zona',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deactivateZone(id);
            },
            onCancel() {},
        });
    };

    onPageChange = async (page: number) => {
        await this.setState({ page });
        this.performGetZones();
    };

    onClickEdit = (zone: IZone) => {
        this.setState({ modalAddEditOpen: true, zoneToEdit: zone });
    };

    onClickDetail = (id: number) =>
        this.props.history.push(helper.setPathParams(paths.ADMIN_ZONE_DETAIL, { id }));

    handleSubmit = (values: IAddEditZoneValues) => {
        const { zoneToEdit } = this.state;
        const { createZone, updateZone } = this.props;

        if (zoneToEdit) {
            updateZone(zoneToEdit.id, values);
            return;
        }

        createZone(values);
    };

    render() {
        const { loading, zones, pageSize, total } = this.props;

        return (
            <SectionContainer title="Zonas" icon="environment">
                <Row className="mb-2">
                    <Col md={12} lg={6}>
                        <Input.Search
                            placeholder="Buscar"
                            enterButton
                            value={this.state.filter}
                            onChange={e => this.setState({ filter: e.target.value })}
                            onSearch={() => this.filterZones()}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.property.WRITE_ZONES}>
                    <Row className="mb-2">
                        <Col className="float-right">
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={() =>
                                    this.setState({ modalAddEditOpen: true, zoneToEdit: undefined })
                                }
                            >
                                Nuevo
                            </Button>
                        </Col>
                    </Row>
                </PBR>
                <Row>
                    <Col>
                        <DataTable
                            data={zones}
                            columns={columns(
                                this.onClickEdit,
                                this.onClickDetail,
                                this.perfromDeactivateZone,
                                this.props.permissions,
                            )}
                            pageSize={pageSize}
                            total={total}
                            currentPage={this.state.page}
                            onPageChange={this.onPageChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
                <PBR permission={permissions.property.WRITE_ZONES}>
                    <AddEditModal
                        isOpen={this.state.modalAddEditOpen}
                        onSubmit={this.handleSubmit}
                        onCancel={() => this.setState({ modalAddEditOpen: false })}
                        initialValues={this.state.zoneToEdit}
                        submitting={this.props.createLoading || this.props.updateLoading}
                    />
                </PBR>
            </SectionContainer>
        );
    }
}
