import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  createProvider,
  resetProvider,
  updateProvider,
  getProvider,
} from '../../../../store/provider/actions';
import { IStoreState } from './ProviderAddEdit.types';
import ProviderAddEdit from './ProviderAddEdit';
import { IRootState } from '../../../../common/types';

function mapStateToProps(state: IRootState): IStoreState {
  const { activeProvider, updateProvider, createProvider } = state.provider;

  return {
    activeProvider: activeProvider.value,
    activeProviderLoading: activeProvider.loading,
    activeProviderError: activeProvider.error,
    createSuccess: createProvider.success,
    createLoading: createProvider.loading,
    createError: createProvider.error,
    updateSuccess: updateProvider.success,
    updateLoading: updateProvider.loading,
    updateError: updateProvider.error,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      createProvider,
      resetProvider,
      updateProvider,
      getProvider,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderAddEdit);
