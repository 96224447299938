import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './ExpenseRequestOrderAddEdit.types';
import ExpenseRequestOrderAddEdit from './ExpenseRequestOrderAddEdit';
import { IRootState } from '../../../../common/types';
import {
    getExpense,
    getExpenseDetails,
    resetExpense,
    resetExpenseDetails,
} from '../../../../store/expense/actions';
import { createRequestOrder } from '../../../../store/requestOrder/actions';
import { getProviders, resetProviders } from '../../../../store/provider/actions';
import { getCurrencies, resetCurrencies } from '../../../../store/currency/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeExpense, expenseDetailList } = state.expense,
        { newRequestOrder } = state.requestOrder,
        { currencyList } = state.currency,
        { providerList } = state.provider;

    return {
        activeExpense: activeExpense.value,
        activeExpenseLoading: activeExpense.loading,
        activeExpenseError: activeExpense.error,
        currencies: currencyList.data,
        currenciesLoading: currencyList.loading,
        expenseDetails: expenseDetailList.data,
        expenseDetailsLoading: expenseDetailList.loading,
        providers: providerList.data,
        providersLoading: providerList.loading,
        providersError: providerList.error,
        createRequestOrderSuccess: newRequestOrder.success,
        createRequestOrderError: newRequestOrder.error,
        createRequestOrderLoading: newRequestOrder.loading,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getExpense,
            getExpenseDetails,
            getProviders,
            getCurrencies,
            resetCurrencies,
            createRequestOrder,
            resetExpense,
            resetExpenseDetails,
            resetProviders,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseRequestOrderAddEdit);
