import React from 'react';
import { IProps } from './PaymentDetail.types';
import { SectionContainer, DisplayItem, DataTable } from '../../../../components';
import { Divider, Row, Col, Upload } from 'antd';
import { formatHelper, helper } from '../../../../common/helpers';
import { PaymentDetailCols } from './misc/columns';
import { paths } from '../../../../routes';
import { currencyTypes } from '../../../../common/constants';

class PaymentDetail extends React.Component<IProps> {
    expenseId = this.props.match.params['id'];

    componentDidMount() {
        this.props.getPayment(this.expenseId);
    }

    detailInvoice = (id: number) => {
        this.props.history.push(helper.setPathParams(paths.ADMIN_INVOICE_DETAIL, { id }));
    };

    getProvider = () => {
        const { payment } = this.props;

        if (payment.provider) return formatHelper.getProviderDescription(payment.provider);
        else if (payment.requestOrder && payment.requestOrder.provider)
            return formatHelper.getProviderDescription(payment.requestOrder.provider);

        return '';
    };

    renderContent = () => {
        const { payment } = this.props;

        return (
            <>
                <DisplayItem label="Proveedor" value={this.getProvider()} />
                <DisplayItem label="Banco" value={payment.bankAccount.bank.name} />
                <DisplayItem label="Nº Cuenta Bancaria" value={payment.bankAccount.number} />
                <DisplayItem
                    label="Nº Cuenta Interbancario"
                    value={payment.bankAccount.interbankNumber}
                />
                <DisplayItem label="Tipo de Pago" value={payment.paymentType.description} />
                {payment.reasonToDelete ? (
                    <>
                        <DisplayItem label="Anulado" value={'Sí'} />
                        <DisplayItem label="Motivo anulación" value={payment.reasonToDelete} />
                    </>
                ) : (
                    <DisplayItem label="Anulado" value={'No'} />
                )}
                <DisplayItem
                    label="Fecha de Pago"
                    value={formatHelper.toDate(payment.paymentDate)}
                />
                <DisplayItem label="Moneda" value={payment.currency.code} />
                {payment.exchangeRate ? (
                    <>
                        <DisplayItem label="Tipo de Cambio" value={payment.exchangeRate} />
                        <DisplayItem
                            label={
                                'Total ' +
                                (payment.currency.id == currencyTypes.PEN ? '(PEN)' : '(USD)')
                            }
                            value={formatHelper.toMoney(
                                Number(payment.totalPrice),
                                payment.currency.id == currencyTypes.PEN ? 'PEN' : 'USD',
                            )}
                        />
                        <DisplayItem
                            label={
                                'Total ' +
                                (payment.currency.id != currencyTypes.PEN ? '(PEN)' : '(USD)')
                            }
                            value={formatHelper.toMoney(
                                payment.currency.id == currencyTypes.PEN
                                    ? Number(payment.totalPrice / payment.exchangeRate)
                                    : Number(payment.totalPrice * payment.exchangeRate),
                                payment.currency.id != currencyTypes.PEN ? 'PEN' : 'USD',
                            )}
                        />
                    </>
                ) : (
                    <DisplayItem
                        label="Pago Total"
                        value={formatHelper.toMoney(
                            Number(payment.totalPrice),
                            payment.currency.code,
                        )}
                    />
                )}

                <DataTable
                    data={payment.paymentDetails}
                    columns={PaymentDetailCols(this.detailInvoice, payment.currency.code)}
                    rowKey={(record) => `${record.stringId || ''}${record.id || ''}`}
                />
                <Divider orientation="left" className="mt-5">
                    Archivos
                </Divider>
                <Row>
                    <Col lg={12} md={12} xs={24} sm={24}>
                        <Upload
                            disabled={true}
                            defaultFileList={helper.getFileList(payment.attachedFiles)}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    render() {
        const { payment, loading, error } = this.props;

        return (
            <SectionContainer
                title="Detalle De Pago"
                onBack={() => this.props.history.goBack()}
                loading={loading || !payment}
                error={error}
                content={this.renderContent}
            />
        );
    }
}

export default PaymentDetail;
