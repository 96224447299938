import { apiUrls } from '../common/constants';
import { APIManager } from '../common/utils';

const { REPORT_URL } = apiUrls;

export function getIncomeDetailedPendingReportExcel(data: any) {
    const url = `${REPORT_URL}/incomes/detailed-pending/excel`;
    return APIManager.post(url, { data, responseType: 'blob' });
}

export function getIncomeDetailedPendingPaymentReportExcel(data: any) {
    const url = `${REPORT_URL}/incomes/detailed-pending-payment/excel`;
    return APIManager.post(url, { data, responseType: 'blob' });
}

export function getIncomeDetailedCompletedReportExcel(data: any) {
    const url = `${REPORT_URL}/incomes/detailed-completed/excel`;
    return APIManager.post(url, { data, responseType: 'blob' });
}

export function getIncomeDetailedAnulledReportExcel(data: any) {
    const url = `${REPORT_URL}/incomes/detailed-anulled/excel`;
    return APIManager.post(url, { data, responseType: 'blob' });
}
