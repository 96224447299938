import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getProperties, resetProperties } from '../../../../../../store/property/actions';
import { getShareholders, resetShareholders } from '../../../../../../store/shareholder/actions';
import { getRucInfo, resetRucInfo } from '../../../../../../store/commonRequest/actions';
import { IRootState } from '../../../../../../common/types';
import { IStoreState } from './IncomeAddForm.types';
import IncomeAddForm from './IncomeAddForm';

function mapStateToProps(state: IRootState): IStoreState {
    const { rucInfo } = state.commonRequest;
    const { propertyList } = state.property;
    const { shareholderList } = state.shareholder;

    return {
        properties: propertyList.data,
        propertiesLoading: propertyList.loading,
        shareholders: shareholderList.data,
        shareholdersLoading: shareholderList.loading,
        rucInfo: rucInfo.value,
        rucInfoLoading: rucInfo.loading,
        rucInfoError: rucInfo.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getProperties,
            resetProperties,
            getShareholders,
            resetShareholders,
            getRucInfo,
            resetRucInfo,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomeAddForm);
