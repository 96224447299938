import React, { FunctionComponent, useRef } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal } from 'antd';
import { isEmpty } from 'validator';
import { InputGroupField, SwitchGroupField } from '../../../../../components';
import { messages } from '../../../../../common/constants';

interface IOwnProps {
    isOpen: boolean;
    onSubmit: (values: ICancelIncomeForm) => void;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    submitting?: boolean;
}

export interface ICancelIncomeForm {
    reason: string;
    giveBack: string | boolean;
}

export type IProps = IOwnProps;

const CancelIncomeModal: FunctionComponent<IProps> = props => {
    let form = useRef<Formik<ICancelIncomeForm>>(null);

    const onSubmit = (values: ICancelIncomeForm, actions: FormikActions<ICancelIncomeForm>) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = e => {
        form.current.resetForm();
        props.onCancel(e);
    };

    const validate = (values: ICancelIncomeForm) => {
        let errors: Partial<ICancelIncomeForm> = {};

        if (isEmpty(values.reason)) errors.reason = messages.MANDATORY_FIELD;

        return errors;
    };

    const onOk = () => {
        form.current.submitForm();
    };

    const renderFormik = () => {
        if (!props.isOpen) return null;

        return (
            <Formik
                initialValues={{
                    reason: '',
                    giveBack: true,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={(formikBag: FormikProps<ICancelIncomeForm>) => (
                    <Form>
                        <InputGroupField
                            name="reason"
                            label="Motivo"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={6}
                            lxxl={6}
                            imd={24}
                            ixl={18}
                            ilg={24}
                        />
                        <SwitchGroupField
                            name="giveBack"
                            label="Volver conceptos de pago a pendiente"
                            lxs={24}
                            lsm={13}
                            lmd={13}
                            llg={13}
                            lxl={13}
                            lxxl={13}
                            ism={5}
                        />
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            title="Anular Ingreso"
            visible={props.isOpen}
            okText="Anular"
            onOk={onOk}
            okButtonProps={{ loading: props.submitting, type: 'danger' }}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
            cancelButtonProps={{ disabled: props.submitting }}
        >
            {renderFormik()}
        </Modal>
    );
};

export default CancelIncomeModal;
