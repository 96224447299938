import { IState, initialState } from './reducer';
import { IAction } from '../../common/types';
import {
    listStatePaginatedLoading,
    listStatePaginatedSuccess,
    listStatePaginatedFailure,
    objectStateLoading,
    objectStateSuccess,
    objectStateFailure,
    processStateLoading,
    processStateSuccess,
    processStateFailure,
    listStateLoading,
    listStateSuccess,
    listStateFailure,
} from '../../common/helpers/reducer';

//#region GET INCOMES BY PROPERTIES
export function getIncomesByProperties(state: IState): IState {
    return {
        ...state,
        incomeByPropertiesList: listStatePaginatedLoading(state.incomeByPropertiesList),
    };
}

export function getIncomesByPropertiesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        incomeByPropertiesList: listStatePaginatedSuccess(action.payload),
    };
}

export function getIncomesByPropertiesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        incomeByPropertiesList: listStatePaginatedFailure(
            initialState.incomeByPropertiesList,
            action.payload.error,
        ),
    };
}

export function getIncomesByPropertiesReset(state: IState): IState {
    return {
        ...state,
        incomeByPropertiesList: initialState.incomeByPropertiesList,
    };
}
//#endregion

//#region GET INCOMES BY SHAREHOLDERS
export function getIncomesByShareholders(state: IState): IState {
    return {
        ...state,
        incomeByShareholdersList: listStatePaginatedLoading(state.incomeByShareholdersList),
    };
}

export function getIncomesByShareholdersSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        incomeByShareholdersList: listStatePaginatedSuccess(action.payload),
    };
}

export function getIncomesByShareholdersFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        incomeByShareholdersList: listStatePaginatedFailure(
            initialState.incomeByShareholdersList,
            action.payload.error,
        ),
    };
}

export function getIncomesByShareholdersReset(state: IState): IState {
    return {
        ...state,
        incomeByShareholdersList: initialState.incomeByShareholdersList,
    };
}
//#endregion

//#region GET PENDINGS BY PROPERTIES
export function getPendingsByProperties(state: IState): IState {
    return {
        ...state,
        pendingListByProperties: listStatePaginatedLoading(state.pendingListByProperties),
    };
}

export function getPendingsByPropertiesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        pendingListByProperties: listStatePaginatedSuccess(action.payload),
    };
}

export function getPendingsByPropertiesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        pendingListByProperties: listStatePaginatedFailure(
            initialState.pendingListByProperties,
            action.payload.error,
        ),
    };
}

export function getPendingsByPropertiesReset(state: IState): IState {
    return {
        ...state,
        pendingListByProperties: initialState.pendingListByProperties,
    };
}
//#endregion

//#region GET PENDINGS BY SHAREHOLDER
export function getPendingsByShareholders(state: IState): IState {
    return {
        ...state,
        pendingListByShareholders: listStatePaginatedLoading(state.pendingListByShareholders),
    };
}

export function getPendingsByShareholdersSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        pendingListByShareholders: listStatePaginatedSuccess(action.payload),
    };
}

export function getPendingsByShareholdersFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        pendingListByShareholders: listStatePaginatedFailure(
            initialState.pendingListByShareholders,
            action.payload.error,
        ),
    };
}

export function getPendingsByShareholdersReset(state: IState): IState {
    return {
        ...state,
        pendingListByShareholders: initialState.pendingListByShareholders,
    };
}
//#endregion

//#region CREATE INCOME PROPERTY PAYMENT ITEM
export function createIncomePropertyPaymentItem(state: IState): IState {
    return {
        ...state,
        createIncomePropertyPaymentItem: processStateLoading(),
    };
}

export function createIncomePropertyPaymentItemSuccess(state: IState): IState {
    return {
        ...state,
        createIncomePropertyPaymentItem: processStateSuccess(),
    };
}

export function createIncomePropertyPaymentItemFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createIncomePropertyPaymentItem: processStateFailure(action.payload.error),
    };
}

export function createIncomePropertyPaymentItemReset(state: IState): IState {
    return {
        ...state,
        createIncomePropertyPaymentItem: initialState.createIncomePropertyPaymentItem,
    };
}
//#endregion

//#region CREATE INCOME SHAREHOLDER PAYMENT ITEM
export function createIncomeShareholderPaymentItem(state: IState): IState {
    return {
        ...state,
        createIncomeShareholderPaymentItem: processStateLoading(),
    };
}

export function createIncomeShareholderPaymentItemSuccess(state: IState): IState {
    return {
        ...state,
        createIncomeShareholderPaymentItem: processStateSuccess(),
    };
}

export function createIncomeShareholderPaymentItemFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createIncomeShareholderPaymentItem: processStateFailure(action.payload.error),
    };
}

export function createIncomeShareholderPaymentItemReset(state: IState): IState {
    return {
        ...state,
        createIncomeShareholderPaymentItem: initialState.createIncomeShareholderPaymentItem,
    };
}
//#endregion

//#region GET INCOME
export function getIncome(state: IState): IState {
    return {
        ...state,
        activeIncome: objectStateLoading(),
    };
}

export function getIncomeSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        activeIncome: objectStateSuccess(action.payload),
    };
}

export function getIncomeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        activeIncome: objectStateFailure(action.payload.error),
    };
}

export function getIncomeReset(state: IState): IState {
    return {
        ...state,
        activeIncome: initialState.activeIncome,
    };
}
//#endregion

//#region CREATE INCOME
export function createIncome(state: IState): IState {
    return {
        ...state,
        createIncome: objectStateLoading(),
    };
}

export function createIncomeSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        createIncome: objectStateSuccess(action.payload),
    };
}

export function createIncomeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        createIncome: objectStateFailure(action.payload.error),
    };
}

export function createIncomeReset(state: IState): IState {
    return {
        ...state,
        createIncome: initialState.createIncome,
    };
}
//#endregion

//#region UPDATE INCOME
export function updateIncome(state: IState): IState {
    return {
        ...state,
        updateIncome: processStateLoading(),
    };
}

export function updateIncomeSuccess(state: IState): IState {
    return {
        ...state,
        updateIncome: processStateSuccess(),
    };
}

export function updateIncomeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        updateIncome: processStateFailure(action.payload.error),
    };
}

export function updateIncomeReset(state: IState): IState {
    return {
        ...state,
        updateIncome: initialState.updateIncome,
    };
}
//#endregion

//#region COMPLETE PAYMENT
export function completePayment(state: IState): IState {
    return {
        ...state,
        completePayment: objectStateLoading(),
    };
}

export function completePaymentSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        completePayment: objectStateSuccess(action.payload),
    };
}

export function completePaymentFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        completePayment: objectStateFailure(action.payload.error),
    };
}

export function completePaymentReset(state: IState): IState {
    return {
        ...state,
        completePayment: initialState.completePayment,
    };
}
//#endregion

//#region DEACTIVATE INCOME
export function deactivateIncome(state: IState): IState {
    return {
        ...state,
        deactivateIncome: processStateLoading(),
    };
}

export function deactivateIncomeSuccess(state: IState): IState {
    return {
        ...state,
        deactivateIncome: processStateSuccess(),
    };
}

export function deactivateIncomeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deactivateIncome: processStateFailure(action.payload.error),
    };
}

export function deactivateIncomeReset(state: IState): IState {
    return {
        ...state,
        deactivateIncome: initialState.deactivateIncome,
    };
}
//#endregion

//#region DELETE INCOME
export function deleteIncome(state: IState): IState {
    return {
        ...state,
        deleteIncome: processStateLoading(),
    };
}

export function deleteIncomeSuccess(state: IState): IState {
    return {
        ...state,
        deleteIncome: processStateSuccess(),
    };
}

export function deleteIncomeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deleteIncome: processStateFailure(action.payload.error),
    };
}

export function deleteIncomeReset(state: IState): IState {
    return {
        ...state,
        deleteIncome: initialState.deleteIncome,
    };
}
//#endregion

//#region GET INCOME TYPES
export function getIncomeTypes(state: IState): IState {
    return {
        ...state,
        incomeTypeList: listStateLoading(),
    };
}

export function getIncomeTypesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        incomeTypeList: listStateSuccess(action.payload.data),
    };
}

export function getIncomeTypesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        incomeTypeList: listStateFailure(action.payload.error),
    };
}

export function getIncomeTypesReset(state: IState): IState {
    return {
        ...state,
        incomeTypeList: initialState.incomeTypeList,
    };
}
//#endregion

//#region GET PAYMENT TYPES
export function getPaymentTypes(state: IState): IState {
    return {
        ...state,
        paymentTypeList: listStateLoading(),
    };
}

export function getPaymentTypesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        paymentTypeList: listStateSuccess(action.payload.data),
    };
}

export function getPaymentTypesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        paymentTypeList: listStateFailure(action.payload.error),
    };
}

export function getPaymentTypesReset(state: IState): IState {
    return {
        ...state,
        paymentTypeList: initialState.paymentTypeList,
    };
}
//#endregion

//#region GET RECEIPT TYPES
export function getReceiptTypes(state: IState): IState {
    return {
        ...state,
        receiptTypeList: listStateLoading(),
    };
}

export function getReceiptTypesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        receiptTypeList: listStateSuccess(action.payload.data),
    };
}

export function getReceiptTypesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        receiptTypeList: listStateFailure(action.payload.error),
    };
}

export function getReceiptTypesReset(state: IState): IState {
    return {
        ...state,
        receiptTypeList: initialState.receiptTypeList,
    };
}
//#endregion

//#region GET INCOME STATES
export function getIncomeStates(state: IState): IState {
    return {
        ...state,
        incomeStateList: listStateLoading(),
    };
}

export function getIncomeStatesSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        incomeStateList: listStateSuccess(action.payload.data),
    };
}

export function getIncomeStatesFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        incomeStateList: listStateFailure(action.payload.error),
    };
}

export function getIncomeStatesReset(state: IState): IState {
    return {
        ...state,
        incomeStateList: initialState.incomeStateList,
    };
}
//#endregion

//#region BULK LOAD INCOME PAYMENT ITEMS BY PROPERTIES
export function bulkLoadIncomePaymentItemsByProperties(state: IState): IState {
    return {
        ...state,
        bulkLoadIncomePaymentItemsByProperties: processStateLoading(),
    };
}

export function bulkLoadIncomePaymentItemsByPropertiesSuccess(state: IState): IState {
    return {
        ...state,
        bulkLoadIncomePaymentItemsByProperties: processStateSuccess(),
    };
}

export function bulkLoadIncomePaymentItemsByPropertiesFailure(
    state: IState,
    action: IAction,
): IState {
    return {
        ...state,
        bulkLoadIncomePaymentItemsByProperties: processStateFailure(action.payload.error),
    };
}

export function bulkLoadIncomePaymentItemsByPropertiesReset(state: IState): IState {
    return {
        ...state,
        bulkLoadIncomePaymentItemsByProperties: initialState.bulkLoadIncomePaymentItemsByProperties,
    };
}
//#endregion

//#region BULK LOAD INCOME PAYMENT ITEMS BY SHAREHOLDERS
export function bulkLoadIncomePaymentItemsByShareholders(state: IState): IState {
    return {
        ...state,
        bulkLoadIncomePaymentItemsByShareholders: processStateLoading(),
    };
}

export function bulkLoadIncomePaymentItemsByShareholdersSuccess(state: IState): IState {
    return {
        ...state,
        bulkLoadIncomePaymentItemsByShareholders: processStateSuccess(),
    };
}

export function bulkLoadIncomePaymentItemsByShareholdersFailure(
    state: IState,
    action: IAction,
): IState {
    return {
        ...state,
        bulkLoadIncomePaymentItemsByShareholders: processStateFailure(action.payload.error),
    };
}

export function bulkLoadIncomePaymentItemsByShareholdersReset(state: IState): IState {
    return {
        ...state,
        bulkLoadIncomePaymentItemsByShareholders:
            initialState.bulkLoadIncomePaymentItemsByShareholders,
    };
}
//#endregion

//#region GET PROPERTY INCOME PAYMENT ITEMS
export function getPropertyIncomePaymentItems(state: IState): IState {
    return {
        ...state,
        propertyIncomePaymentItemList: listStateLoading(),
    };
}

export function getPropertyIncomePaymentItemsSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        propertyIncomePaymentItemList: listStateSuccess(action.payload.data),
    };
}

export function getPropertyIncomePaymentItemsFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        propertyIncomePaymentItemList: listStateFailure(action.payload.error),
    };
}

export function getPropertyIncomePaymentItemsReset(state: IState): IState {
    return {
        ...state,
        propertyIncomePaymentItemList: initialState.propertyIncomePaymentItemList,
    };
}
//#endregion

//#region GET SHAREHOLDER INCOME PAYMENT ITEMS
export function getShareholderIncomePaymentItems(state: IState): IState {
    return {
        ...state,
        shareholderIncomePaymentItemList: listStateLoading(),
    };
}

export function getShareholderIncomePaymentItemsSuccess(state: IState, action: IAction): IState {
    return {
        ...state,
        shareholderIncomePaymentItemList: listStateSuccess(action.payload.data),
    };
}

export function getShareholderIncomePaymentItemsFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        shareholderIncomePaymentItemList: listStateFailure(action.payload.error),
    };
}

export function getShareholderIncomePaymentItemsReset(state: IState): IState {
    return {
        ...state,
        shareholderIncomePaymentItemList: initialState.shareholderIncomePaymentItemList,
    };
}
//#endregion

//#region DELETE INCOME PAYMENT ITEM
export function deleteIncomePaymentItem(state: IState): IState {
    return {
        ...state,
        deleteIncomePaymentItem: processStateLoading(),
    };
}

export function deleteIncomePaymentItemSuccess(state: IState): IState {
    return {
        ...state,
        deleteIncomePaymentItem: processStateSuccess(),
    };
}

export function deleteIncomePaymentItemFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        deleteIncomePaymentItem: processStateFailure(action.payload.error),
    };
}

export function deleteIncomePaymentItemReset(state: IState): IState {
    return {
        ...state,
        deleteIncomePaymentItem: initialState.deleteIncomePaymentItem,
    };
}
//#endregion

//#region CANCEL INCOME
export function cancelIncome(state: IState): IState {
    return {
        ...state,
        cancelIncome: processStateLoading(),
    };
}

export function cancelIncomeSuccess(state: IState): IState {
    return {
        ...state,
        cancelIncome: processStateSuccess(),
    };
}

export function cancelIncomeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        cancelIncome: processStateFailure(action.payload.error),
    };
}

export function cancelIncomeReset(state: IState): IState {
    return {
        ...state,
        cancelIncome: initialState.cancelIncome,
    };
}
//#endregion

//#region SET COMPLETE PAYMENT DATA
export function setCompletePaymentData(state: IState, action: IAction): IState {
    return {
        ...state,
        completePaymentData: action.payload,
    };
}

export function setCompletePaymentDataReset(state: IState): IState {
    return {
        ...state,
        completePaymentData: initialState.completePaymentData,
    };
}
//#endregion

//#region CONDONE INCOME PAYMENT ITEM
export function condoneIncomePaymentItem(state: IState): IState {
    return {
        ...state,
        condoneIncomePaymentItem: processStateLoading(),
    };
}

export function condoneIncomePaymentItemSuccess(state: IState): IState {
    return {
        ...state,
        condoneIncomePaymentItem: processStateSuccess(),
    };
}

export function condoneIncomePaymentItemFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        condoneIncomePaymentItem: processStateFailure(action.payload.error),
    };
}

export function condoneIncomePaymentItemReset(state: IState): IState {
    return {
        ...state,
        condoneIncomePaymentItem: initialState.condoneIncomePaymentItem,
    };
}
//#endregion

//#region VERIFY PRINT INCOME
export function verifyPrintIncome(state: IState): IState {
    return {
        ...state,
        verifyPrintIncome: processStateLoading(),
    };
}

export function verifyPrintIncomeSuccess(state: IState): IState {
    return {
        ...state,
        verifyPrintIncome: processStateSuccess(),
    };
}

export function verifyPrintIncomeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        verifyPrintIncome: processStateFailure(action.payload.error),
    };
}

export function verifyPrintIncomeReset(state: IState): IState {
    return {
        ...state,
        verifyPrintIncome: initialState.verifyPrintIncome,
    };
}
//#endregion

//#region VERIFY PRINT INCOME
export function completePendingIncome(state: IState): IState {
    return {
        ...state,
        completePendingIncome: processStateLoading(),
    };
}

export function completePendingIncomeSuccess(state: IState): IState {
    return {
        ...state,
        completePendingIncome: processStateSuccess(),
    };
}

export function completePendingIncomeFailure(state: IState, action: IAction): IState {
    return {
        ...state,
        completePendingIncome: processStateFailure(action.payload.error),
    };
}

export function completePendingIncomeReset(state: IState): IState {
    return {
        ...state,
        completePendingIncome: initialState.completePendingIncome,
    };
}
//#endregion
