const actionTypes = {
    GET_SUBZONES: 'subzone/GET_SUBZONES',
    GET_SUBZONES_SUCCESS: 'subzone/GET_SUBZONES_SUCCESS',
    GET_SUBZONES_FAILURE: 'subzone/GET_SUBZONES_FAILURE',
    GET_SUBZONES_RESET: 'subzone/GET_SUBZONES_RESET',

    GET_SUBZONE: 'subzone/GET_SUBZONE',
    GET_SUBZONE_SUCCESS: 'subzone/GET_SUBZONE_SUCCESS',
    GET_SUBZONE_FAILURE: 'subzone/GET_SUBZONE_FAILURE',
    GET_SUBZONE_RESET: 'subzone/GET_SUBZONE_RESET',

    CREATE_SUBZONE: 'subzone/CREATE_SUBZONE',
    CREATE_SUBZONE_SUCCESS: 'subzone/CREATE_SUBZONE_SUCCESS',
    CREATE_SUBZONE_FAILURE: 'subzone/CREATE_SUBZONE_FAILURE',
    CREATE_SUBZONE_RESET: 'subzone/CREATE_SUBZONE_RESET',

    UPDATE_SUBZONE: 'subzone/UPDATE_SUBZONE',
    UPDATE_SUBZONE_SUCCESS: 'subzone/UPDATE_SUBZONE_SUCCESS',
    UPDATE_SUBZONE_FAILURE: 'subzone/UPDATE_SUBZONE_FAILURE',
    UPDATE_SUBZONE_RESET: 'subzone/UPDATE_SUBZONE_RESET',

    DEACTIVATE_SUBZONE: 'subzone/DEACTIVATE_SUBZONE',
    DEACTIVATE_SUBZONE_SUCCESS: 'subzone/DEACTIVATE_SUBZONE_SUCCESS',
    DEACTIVATE_SUBZONE_FAILURE: 'subzone/DEACTIVATE_SUBZONE_FAILURE',
    DEACTIVATE_SUBZONE_RESET: 'subzone/DEACTIVATE_SUBZONE_RESET',

    GET_SUBZONE_PROPERTIES: 'subzone/GET_SUBZONE_PROPERTIES',
    GET_SUBZONE_PROPERTIES_SUCCESS: 'subzone/GET_SUBZONE_PROPERTIES_SUCCESS',
    GET_SUBZONE_PROPERTIES_FAILURE: 'subzone/GET_SUBZONE_PROPERTIES_FAILURE',
    GET_SUBZONE_PROPERTIES_RESET: 'subzone/GET_SUBZONE_PROPERTIES_RESET',
};

export default actionTypes;
