import React, { Component } from 'react';
import { FormikActions, FormikProps, Formik, Form } from 'formik';
import { Row, Col, Button } from 'antd';

/** @type F = IFormValues */
export interface IOwnProps<F = any> {
    onSubmit: (values: F) => void;
    onCancel?: () => void;
    /**Use it when you are editing an entity to control some changes in the form's behavior*/
    editing?: boolean;
    disabled?: boolean;
    submitting?: boolean;
    submitButtonText?: string;
    hideFooter?: boolean;
    formRef?: (ref: Formik<F>) => void;
    preventSubmitOnEnter?: boolean;
}

/**
 * @type F = IFormValues
 * @type P = IProps
 * @type S = IState
 */
export default abstract class CustomForm<F = any, P = IOwnProps, S = any> extends Component<
    P & IOwnProps<F>,
    S
    > {
    /**
     * @param values<F>
     * @param state<S>
     */
    validate: (values: F, state: S) => Partial<F>;
    abstract initialValues: (props: P) => F;
    abstract onSubmit: (values: F, actions: FormikActions<F>) => void;
    onCancel?: (event: React.MouseEvent<any, MouseEvent>) => void;
    abstract formBody: (formikBag: FormikProps<F>) => React.ReactNode;

    validateOnBlur?: boolean = false;
    validateOnChange?: boolean = false;
    form: Formik<F>;

    footer(formikBag: FormikProps<F>) {
        if (this.props.hideFooter) return null;

        const { submitting, disabled, submitButtonText } = this.props;

        return (
            <Row>
                <Col className="mt-3">
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={submitting || disabled}
                        className="mr-2"
                        loading={submitting}
                    >
                        <i className="fa fa-save mr-1" /> {submitButtonText || 'Guardar'}
                    </Button>
                    {this.props.onCancel && this.onCancel && (
                        <Button
                            type="default"
                            onClick={this.onCancel}
                            disabled={submitting || disabled}
                            loading={submitting}
                        >
                            <i className="fa fa-chevron-left mr-1" /> Cancelar
                        </Button>
                    )}
                </Col>
            </Row>
        );
    }

    setFormRef = (ref: Formik<F>) => {
        if (this.props.formRef) this.props.formRef(ref);
        this.form = ref;
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues(this.props)}
                validateOnChange={this.validateOnChange}
                validateOnBlur={this.validateOnBlur}
                validate={vals => {
                    if (this.validate) return this.validate(vals, this.state);
                    return undefined;
                }}
                onSubmit={this.onSubmit}
                ref={this.setFormRef}
                render={(formikBag: FormikProps<F>) => {
                    return (
                        <Form onKeyDown={(KeyEvent) => {
                            if (
                                this.props.preventSubmitOnEnter &&
                                (KeyEvent.charCode || KeyEvent.keyCode) === 13
                            )
                                KeyEvent.preventDefault()
                        }}>
                            {this.formBody && this.formBody(formikBag)}
                            {this.footer && this.footer(formikBag)}
                        </Form>
                    );
                }}
            />
        );
    }
}
