import React, { FC, useRef, useEffect } from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import { Modal } from 'antd';
import { isEmpty } from 'validator';
import { messages } from '../../../../common/constants';
import { InputPasswordGroupField } from '../../../../components';

interface IOwnProps {
    isOpen: boolean;
    onSubmit: (values: IChangePasswordFormValues) => void;
    disabled?: boolean;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface IChangePasswordFormValues {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export type IProps = IOwnProps;

const ChangePasswordForm: FC<IProps> = props => {
    let form = useRef<Formik<IChangePasswordFormValues>>(null);

    const onSubmit = (
        values: IChangePasswordFormValues,
        actions: FormikActions<IChangePasswordFormValues>,
    ) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    const handleOnCancel = e => {
        form.current.resetForm();
        props.onCancel(e);
    };

    const validate = (values: IChangePasswordFormValues) => {
        let errors: Partial<IChangePasswordFormValues> = {};

        if (isEmpty(values.currentPassword)) errors.currentPassword = messages.MANDATORY_FIELD;
        if (isEmpty(values.newPassword)) errors.newPassword = messages.MANDATORY_FIELD;
        if (!isEmpty(values.newPassword) || !isEmpty(values.confirmPassword))
            if (values.newPassword !== values.confirmPassword)
                errors.confirmPassword = 'Las contraseñas no coinciden';

        return errors;
    };

    const onOk = () => {
        form.current.submitForm();
    };

    const renderFormik = () => {
        return (
            <Formik
                initialValues={{
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={onSubmit}
                ref={form}
                render={(formikBag: FormikProps<IChangePasswordFormValues>) => (
                    <Form>
                        <InputPasswordGroupField
                            name="currentPassword"
                            label="Contraseña actual"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={8}
                            lxxl={8}
                            imd={24}
                            ixl={16}
                            ilg={24}
                        />
                        <InputPasswordGroupField
                            name="newPassword"
                            label="Nueva contraseña"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={8}
                            lxxl={8}
                            imd={24}
                            ixl={16}
                            ilg={24}
                        />
                        <InputPasswordGroupField
                            name="confirmPassword"
                            label="Confirmar nueva contraseña"
                            required
                            lxs={24}
                            lsm={24}
                            lmd={24}
                            llg={24}
                            lxl={8}
                            lxxl={8}
                            imd={24}
                            ixl={16}
                            ilg={24}
                        />
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            destroyOnClose={true}
            title="Cambiar contraseña"
            visible={props.isOpen}
            okText="Cambiar"
            onOk={onOk}
            cancelText="Cancelar"
            onCancel={handleOnCancel}
        >
            {renderFormik()}
        </Modal>
    );
};

export default ChangePasswordForm;
