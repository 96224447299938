import actionTypes from './actionTypes';
import userActionTypes from '../user/actionTypes';
import { IProcessState, IObjectState } from '../../common/types';
import { IUser } from '../../common/types/entities';
import * as caseFunctions from './caseFunctions';
import { createReducer } from '../../common/helpers/reducer';

export interface IState {
    authenticated?: boolean;
    verifyLoggedInUser: IProcessState;
    loggedInUser: IObjectState<IUser>;
    forgotPassword: IProcessState;
    changePassword: IProcessState;
    resetPassword: IProcessState;
}

export const initialState: IState = {
    authenticated: undefined,
    verifyLoggedInUser: { success: false, loading: false, error: undefined },
    loggedInUser: { value: undefined, loading: false, error: undefined },
    forgotPassword: { success: false, loading: false, error: undefined },
    changePassword: { success: false, loading: false, error: undefined },
    resetPassword: { success: false, loading: false, error: undefined },
};

export default createReducer<IState>(initialState, {
    [actionTypes.LOGIN_USER]: caseFunctions.loginUser,
    [actionTypes.LOGIN_USER_SUCCESS]: caseFunctions.loginUserSuccess,
    [actionTypes.LOGIN_USER_FAILURE]: caseFunctions.loginUserFailure,

    [actionTypes.VERIFY_LOGGED_IN_USER]: caseFunctions.verifyLoggedInUser,
    [actionTypes.VERIFY_LOGGED_IN_USER_SUCCESS]: caseFunctions.verifyLoggedInUserSuccess,
    [actionTypes.VERIFY_LOGGED_IN_USER_FAILURE]: caseFunctions.verifyLoggedInUserFailure,

    [actionTypes.LOGOUT_USER]: caseFunctions.logoutUser,

    [actionTypes.FORGOT_PASSWORD]: caseFunctions.forgotPassword,
    [actionTypes.FORGOT_PASSWORD_SUCCESS]: caseFunctions.forgotPasswordSuccess,
    [actionTypes.FORGOT_PASSWORD_FAILURE]: caseFunctions.forgotPasswordFailure,
    [actionTypes.FORGOT_PASSWORD_RESET]: caseFunctions.forgotPasswordReset,

    [actionTypes.CHANGE_PASSWORD]: caseFunctions.changePassword,
    [actionTypes.CHANGE_PASSWORD_SUCCESS]: caseFunctions.changePasswordSuccess,
    [actionTypes.CHANGE_PASSWORD_FAILURE]: caseFunctions.changePasswordFailure,

    [actionTypes.RESET_PASSWORD]: caseFunctions.resetPassword,
    [actionTypes.RESET_PASSWORD_SUCCESS]: caseFunctions.resetPasswordSuccess,
    [actionTypes.RESET_PASSWORD_FAILURE]: caseFunctions.resetPasswordFailure,
    [actionTypes.RESET_PASSWORD_RESET]: caseFunctions.resetPasswordReset,

    [userActionTypes.UPDATE_AUTHORIZATION_TOKEN_SUCCESS]:
        caseFunctions.updateAuthorizationTokenSuccess,
});
