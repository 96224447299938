import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './PettyCashList.types';
import PettyCashList from './PettyCashList.view';
import { IRootState } from '../../../../common/types';
import {
    getPettyCashList,
    resetPettyCashList,
    createPettyCash,
} from '../../../../store/pettyCash/actions';

function mapStateToProps(state: IRootState): IStoreState {
    const { pettyCashList, createPettyCash } = state.pettyCash;

    return {
        pettyCashList: pettyCashList.data,
        total: pettyCashList.total,
        pageSize: pettyCashList.pageSize,
        loading: pettyCashList.loading,
        error: pettyCashList.error,
        createLoading: createPettyCash.loading,
        createSuccess: createPettyCash.success,
        createError: createPettyCash.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getPettyCashList,
            resetPettyCashList,
            createPettyCash,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PettyCashList);
