import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStoreState } from './IncomePendingDetailByProperty.types';
import IncomePendingDetailByProperty from './IncomePendingDetailByProperty';
import { IRootState } from '../../../../common/types';
import {
    getPropertyIncomePaymentItems,
    resetPropertyIncomePaymentItems,
    createIncomePropertyPaymentItem,
    resetCreateIncomePropertyPaymentItem,
    deleteIncomePaymentItem,
    resetDeleteIncomePaymentItem,
    setCompletePaymentData,
    condoneIncomePaymentItem,
    resetCondoneIncomePaymentItem,
} from '../../../../store/income/actions';
import { getProperty, resetProperty } from '../../../../store/property/actions';
import { IIncomePaymentItem } from '../../../../common/types/entities';
import { currencies } from '../../../../common/constants';

function mapStateToProps(state: IRootState): IStoreState {
    const { activeProperty } = state.property;
    const { loggedInUser } = state.auth;

    const {
        propertyIncomePaymentItemList,
        createIncomePropertyPaymentItem,
        deleteIncomePaymentItem,
        condoneIncomePaymentItem,
    } = state.income;

    return {
        property: activeProperty.value,
        error: activeProperty.error,
        loading: activeProperty.loading,
        totalPricePEN: selectTotalPricePEN(propertyIncomePaymentItemList.data),
        totalPriceUSD: selectTotalPriceUSD(propertyIncomePaymentItemList.data),
        incomePaymentItems: propertyIncomePaymentItemList.data,
        incomePaymentItemsLoading: propertyIncomePaymentItemList.loading,
        createIncomePaymentItemSuccess: createIncomePropertyPaymentItem.success,
        createIncomePaymentItemLoading: createIncomePropertyPaymentItem.loading,
        createIncomePaymentItemError: createIncomePropertyPaymentItem.error,
        deleteIncomePaymentItemSuccess: deleteIncomePaymentItem.success,
        deleteIncomePaymentItemLoading: deleteIncomePaymentItem.loading,
        deleteIncomePaymentItemError: deleteIncomePaymentItem.error,
        condoneSuccess: condoneIncomePaymentItem.success,
        condoneLoading: condoneIncomePaymentItem.loading,
        condoneError: condoneIncomePaymentItem.error,
        loggedInUser: loggedInUser.value,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            getProperty,
            resetProperty,
            getPropertyIncomePaymentItems,
            resetPropertyIncomePaymentItems,
            createIncomePropertyPaymentItem,
            resetCreateIncomePropertyPaymentItem,
            deleteIncomePaymentItem,
            resetDeleteIncomePaymentItem,
            setCompletePaymentData,
            condoneIncomePaymentItem,
            resetCondoneIncomePaymentItem,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomePendingDetailByProperty);

const selectTotalPricePEN = (data: IIncomePaymentItem[]): number => {
    let totalPrice = 0;
    for (const item of data) {
        if (item.currency.id == currencies.PEN) totalPrice += item.editedPrice || item.price;
    }
    return totalPrice;
};

const selectTotalPriceUSD = (data: IIncomePaymentItem[]): number => {
    let totalPrice = 0;
    for (const item of data) {
        if (item.currency.id == currencies.USD) totalPrice += item.editedPrice || item.price;
    }
    return totalPrice;
};
