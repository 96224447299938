import { Dispatch } from 'redux';
import actionTypes from './actionTypes';
import { IAPIMiddleware } from '../../common/types';
import { GET_ALL_ITEMS } from '../../common/constants';
import { userService } from '../../service';

export function getUsers(filter = '', page = GET_ALL_ITEMS): IAPIMiddleware {
    return {
        callAPI: () => userService.getAll(filter, page),
        types: [
            actionTypes.GET_USERS,
            actionTypes.GET_USERS_SUCCESS,
            actionTypes.GET_USERS_FAILURE,
        ],
    };
}

export function resetUsers() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_USERS_RESET,
        });
    };
}

export function getUser(id: number): IAPIMiddleware {
    return {
        callAPI: () => userService.get(id),
        types: [actionTypes.GET_USER, actionTypes.GET_USER_SUCCESS, actionTypes.GET_USER_FAILURE],
    };
}

export function resetUser() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_USER_RESET,
        });
    };
}

export function createUser(data: any): IAPIMiddleware {
    return {
        callAPI: () => userService.create(data),
        types: [
            actionTypes.CREATE_USER,
            actionTypes.CREATE_USER_SUCCESS,
            actionTypes.CREATE_USER_FAILURE,
        ],
    };
}

export function updateUser(id: number, data: any): IAPIMiddleware {
    return {
        callAPI: () => userService.update(id, data),
        types: [
            actionTypes.UPDATE_USER,
            actionTypes.UPDATE_USER_SUCCESS,
            actionTypes.UPDATE_USER_FAILURE,
        ],
    };
}

export function deactivateUser(id: number): IAPIMiddleware {
    return {
        callAPI: () => userService.deactivate(id),
        types: [
            actionTypes.DEACTIVATE_USER,
            actionTypes.DEACTIVATE_USER_SUCCESS,
            actionTypes.DEACTIVATE_USER_FAILURE,
        ],
    };
}

export function getRoles() {
    return {
        callAPI: () => userService.getRoles(),
        types: [
            actionTypes.GET_ROLES,
            actionTypes.GET_ROLES_SUCCESS,
            actionTypes.GET_ROLES_FAILURE,
        ],
    };
}

export function resetRoles() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_ROLES_RESET,
        });
    };
}

export function updateAuthorizationToken() {
    return {
        callAPI: () => userService.updateAuthorizationToken(),
        types: [
            actionTypes.UPDATE_AUTHORIZATION_TOKEN,
            actionTypes.UPDATE_AUTHORIZATION_TOKEN_SUCCESS,
            actionTypes.UPDATE_AUTHORIZATION_TOKEN_FAILURE,
        ],
    };
}

export function resetUpdateAuthorizationToken() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_AUTHORIZATION_TOKEN_RESET,
        });
    };
}
