import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { IIncomePaymentItem } from '../../../../../common/types/entities';
import { formatHelper, displayHelper } from '../../../../../common/helpers';
import { TableCellItem } from '../../../../../components';

export const columns = (): ColumnProps<IIncomePaymentItem>[] => [
    {
        title: 'Concepto',
        key: 'Concepto',
        className: 'd-none d-md-table-cell',
        render: (item: IIncomePaymentItem) => {
            if (item.attachedFiles && item.attachedFiles.length > 0)
                return (
                    <a href={item.attachedFiles[0].url} target="_blank">
                        {formatHelper.getPaymentItemWithType(item.paymentItem)}
                    </a>
                );

            return <span>{formatHelper.getPaymentItemWithType(item.paymentItem)}</span>;
        },
    },
    {
        title: 'Precio',
        className: 'd-none d-md-table-cell',
        render: (item: IIncomePaymentItem) => displayHelper.incomePaymentItemPrice(item),
    },
    {
        title: 'Comentario',
        dataIndex: 'comment',
        className: 'd-none d-md-table-cell',
    },
    {
        key: 'xs',
        title: 'Puesto',
        dataIndex: '',
        className: 'd-table-cell d-md-none',
        render: (item: IIncomePaymentItem) => (
            <>
                <TableCellItem
                    label="Concepto"
                    value={formatHelper.getPaymentItemWithType(item.paymentItem)}
                />
                <TableCellItem label="Precio" value={displayHelper.incomePaymentItemPrice(item)} />
                <TableCellItem label="Comentario" value={item.comment} />
            </>
        ),
    },
];
